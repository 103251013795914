.notification-container {
	width: 424px;
	height: 624px;
	border: 1px solid $app-border-color;
	border-radius: 4px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
	z-index: 99;
	background-color: white;
	display: flex;
	flex-direction: column;
	text-overflow: ellipsis;
	white-space: nowrap;
	user-select: none;

	&.all-notifications {
		width: 100%;
		height: auto;
		border: none;
		outline: none;
		flex-grow: 1;
		z-index: 1;
		&:focus {
			outline: none;
		}
		box-shadow: none;
		.notification-container-header {
			flex-grow: 0;
		}
		.notification-wrapper {
			outline: none;
			height: auto;
			flex-grow: 1;
			.notification-body {
				outline: none;
				height: auto;
				position: relative;
				display: flex;
				flex-direction: column;
				.notification {
					&.last {
						margin-bottom: 24px;
					}
				}
			}
		}
	}

	.notification-wrapper {
		height: 100%;
		outline: none;
		display: flex;
		flex-direction: column;
		.notification-body {
			outline: none;
		}
	}

	.notification-container-header {
		display: flex;
		padding: 13px 24px 12px 24px;
		border-bottom: 1px solid $app-border-color;
		color: $v2-text-gray;
		align-items: center;
		&.full-page {
			padding: 16px 24px;
		}
		.notification-header-filter {
			margin-right: 24px;
		}
		.notification-header-notifications {
			font-size: 15px;
			font-weight: bold;
			margin-right: auto;
		}
		.notification-header-mark-read {
			margin-right: 14px;
			font-size: 12px;
			cursor: pointer;
			&.empty {
				pointer-events: none;
				text-decoration: none;
				color: $v2-text-light-gray;
			}
		}
		.notification-header-mute {
			margin-right: 14px;
			font-size: 12px;
			cursor: pointer;
		}
		.notification-header-settings-button {
			cursor: pointer;
			.settings-icon {
				display: flex;
			}
		}
	}

	.notification-container-footer {
		height: 23px;
		display: flex;
		border-top: 1px solid $app-border-color;
		align-items: center;
		justify-content: center;
		text-align: center;
		font-size: 13px;
		color: $v2-text-gray;
		font-weight: 500;
		cursor: pointer;
	}

	.notification-body {
		height: 550px;
		.notification-date-splitter {
			height: 30px;
			padding: 7.5px 24px 6.5px 24px;
			border-bottom: 1px solid $app-border-color;

			font-size: 12px;
			font-weight: 500;
			color: $v2-text-gray;
			&.spacing-top {
				margin-top: 16px;
				font-size: 13px;
				padding: 7.5px 0;
			}
		}
		&.full-page {
			padding: 0 24px;
		}
	}

	.generic-empty-state-v2 {
		background-color: white;
		border: none;
	}
}
