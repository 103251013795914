.global-search-results-page {
	margin: 0;
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	@include standard-table-v2;
	.header {
		padding: 16px 66px 3px 66px;
		&.border-bottom {
			border-bottom: 1px solid $app-border-color;
		}
		.header-columns-wrapper {
			display: flex;
			flex-direction: column;
			.header-top-row {
				display: flex;
				flex: 1 1 auto;
				flex-direction: row;
				justify-content: space-between;
				.back-button-input-wrapper {
					height: 30px;
					display: flex;
					align-items: center;
					.back-button {
						@include back-button;
					}
					.header-search-input {
						width: 282px;
						height: 30px;
						border-radius: 4px;
						border: solid 1px $v2-backdrop-gray;
						//background-color: $color-white;
						margin-left: 15px;
						font-size: 13px;
						line-height: 1.38;
						color: $v2-text-gray;
						padding-left: 11px;
						padding-right: 8px;
						outline: none;
						background-size: 15px;
						background-position: 261px 6px;
						background-repeat: no-repeat;
						background-image: url('images/components/scheduling/search-icon.svg');
					}
				}
				.add-search-criteria-controls-wrapper {
					position: relative;
					.add-search-criteria-button {
						text-transform: uppercase;
						padding: 5px 30px;
						border: 1px solid $app-border-color;
						font-size: 11px;
						font-weight: 700;
						line-height: 1.64;
						letter-spacing: 1px;
						color: $v2-text-gray;
						outline: none;
						&:hover,
						&:focus {
							border: 1px solid $v2-button-very-light-grey-hovered;
						}
						&.expanded {
							border: 1px solid $v2-branding-purple;
							border-bottom: 1px solid $color-white;
							&:hover {
								border: 1px solid $v2-branding-purple;
								border-bottom: 1px solid $color-white;
							}
						}
					}
					.new-search-criteria-controls {
						display: flex;
						flex-direction: column;
						position: absolute;
						top: 30px;
						right: 0;
						width: 362px;
						padding: 11px;
						background-color: $color-white;
						border: 1px solid $v2-branding-purple;
						z-index: 2;
						.new-search-criteria-row {
							display: flex;
							justify-content: space-between;
							background-color: $color-white;
							height: 30px;
							position: relative;
							&.bottom {
								margin-top: 6px;
								justify-content: flex-end;
							}
							.drop-down-v2 {
								width: 166px;
							}
							.buttons-wrapper {
								button {
									height: 30px;
									width: 130px;
								}
							}
							.value-input {
								width: 304px;
								border-radius: 4px;
								border: solid 1px $v2-backdrop-gray;
								outline: none;
								padding: 7px 11px;
								font-size: 13px;
								&.invalid {
									border-color: red;
								}
							}
							.save-filter-button {
								margin-left: 6px;
								&:focus {
									border: solid 1px $v2-branding-purple;
								}
							}
						}
					}
				}
				.global-search-clear-icon {
					margin-left: -35px;
					border: none;
					width: 8px;
					height: 8px;
					padding: 0;
					z-index: 2;
					background-repeat: no-repeat;
					background-size: 8px;
					background-position: center;
					background-image: url('images/v2/components/header/x-grey.svg');
					visibility: hidden;
					&.visible {
						visibility: visible;
					}
				}
			}
			.header-bottom-row {
				display: flex;
				flex-wrap: wrap;
				padding-top: 5px;
				padding-left: 41px;
				.added-search-criteria {
					margin-right: 4px;
					margin-bottom: 4px;
					padding: 5px 9px 5px 9px;
					width: 127px;
					height: 34px;
					overflow: hidden;
					border-radius: 4px;
					background-color: $v2-global-search-criteria-background-color;
					display: flex;
					justify-content: space-between;
					align-items: center;
					.text-wrapper {
						display: flex;
						flex-direction: column;
						.field-and-operator {
							width: 100px;
							overflow: hidden;
							font-size: 9px;
							letter-spacing: 0.5px;
							color: $v2-text-gray;
							display: flex;
							flex-wrap: nowrap;
							span {
								text-overflow: ellipsis;
								&.field {
									text-transform: capitalize;
									margin-right: 3px;
								}
							}
						}
						.value {
							font-size: 11px;
							font-weight: 600;
							letter-spacing: 0.5px;
							color: $v2-text-gray;
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
							width: 100px;
							display: inline-block;
						}
					}
					.remove-search-criteria-button {
						height: 9px;
						width: 9px;
						background-repeat: no-repeat;
						background-size: 9px;
						background-position: center;
						background-image: url('images/v2/components/header/x-grey.svg');
						border: none;
					}
				}
			}
		}
	}
	.body {
		padding-left: 66px;
		padding-right: 66px;
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		padding-bottom: 16px;
		width: 100%;
		.global-search-page-empty-state-container,
		.global-search-page-no-results {
			display: flex;
			flex: 1 1 auto;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-top: 90px;
			.info {
				font-family: $heading-font-family;
				font-size: 32px;
			}
			img {
				margin-top: 32px;
				height: 250px;
				width: 200px;
			}
		}
		.body-top-controls {
			display: flex;
			padding-top: 10px;
			padding-bottom: 30px;
			.csv-button {
				@include download-icon;
				width: 30px;
				height: 30px;
				background-size: 12px 15px;
				background-position: center;
				padding: 0;
				margin: 0 12px 0 8px;
				border: 1px solid $v2-backdrop-gray;
			}
			.results-info-message {
				font-size: 16px;
				font-weight: 700;
				line-height: 1.63;
				color: $v2-nested-dropdown-input-color;
			}
		}
	}
}
