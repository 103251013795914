.numeric-tile {
	.numeric-tile-label {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-family: $text-font-family;
		text-transform: uppercase;
		font-size: 9px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.22;
		letter-spacing: 1.1px;
		color: $v2-text-light-gray;
		position: relative;
		padding-bottom: 2px;
	}

	.value-container {
		display: flex;
		flex-direction: row;
		align-items: baseline;
		position: relative;
		.value-tile {
			font-family: $text-font-family;
			font-size: 12px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.92;
			letter-spacing: 1px;
			color: $v2-text-gray;
			text-align: left;
		}
		.unit-tile {
			font-family: $text-font-family;
			font-size: 10px;
			font-weight: normal;
			line-height: 1.6;
			letter-spacing: normal;
			font-style: normal;
			font-stretch: normal;
			color: $v2-text-gray;
			text-align: left;
		}
	}

	.value-container-v2 {
		display: flex;
		flex-direction: row;
		align-items: center;
		position: relative;

		font-family: $text-font-family;
		font-size: 12px;
		font-style: normal;
		font-stretch: normal;
		font-weight: normal;
		line-height: normal;
		letter-spacing: normal;
		color: $v2-text-gray;
		text-align: left;

		.unit-tile {
			padding-left: 3px;
		}
		&.warning {
			color: #d0021b;
		}
	}

	&.v2 {
		justify-content: flex-end;
		svg {
			margin-top: 11px;
			margin-right: 10px;
		}
	}

	&.grouped {
		cursor: help;
	}

	&.big {
		.numeric-tile-label {
			font-size: 10px;
			line-height: normal;
			letter-spacing: 1px;
			color: $v2-text-gray;
		}
		.value-container {
			.value-tile {
				padding-right: 5px;
				font-size: 22px;
				font-weight: 300;
				line-height: normal;
				letter-spacing: -1px;
			}
			.unit-tile {
				//padding-left: 5px;
				font-weight: 300;
				line-height: normal;
				font-size: 16px;
			}
		}
	}
}
