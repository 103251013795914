.project-timeline-insight-component {
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.section-header {
		.forecast-actions {
			justify-content: flex-start;
			.search {
				margin-right: 10px;
			}
			.expand-all-button {
				@include secondary-button;
				margin-left: auto;
				width: auto;
			}
		}
	}

	.section-body {
		display: flex;
		flex: 1;
		flex-direction: column;
		@include scroll-y;

		.resource-front-wrapper {
			&.no-background {
				background-color: white;
			}
			&.out-of-scope {
				.vis-drag-left,
				.vis-drag-center,
				.vis-drag-right {
					cursor: default;
				}
			}
		}

		.scheduling-timeline-wrapper {
			.scheduling-container {
				height: calc(100vh - 180px);
			}
		}

		.vis-nesting-group {
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
			padding-right: 14px;
			width: 268px;
		}

		.vis-inner {
			padding: 0 !important;
			margin-right: auto;
		}

		.task-warning {
			@include warning-icon;
		}

		.front-only {
			&.task {
				display: flex;
				flex-direction: column;
				cursor: pointer;
				line-height: 55px;
				margin-left: 50px;
				padding: 5px;
				.task-name,
				.task-id {
					width: 180px;
					margin: 0;
				}
				.task-id {
					font-size: 0.75em;
					margin-bottom: 3px;
				}
				.task-name-wrapper {
					display: flex;
				}
			}
			&.phase {
				// padding-right: 12px;
				display: flex;
				flex-direction: row;
				.phase-color {
					display: flex;
					flex: 0 0 6px;
					margin: 1px 0 1px 0;
					border-radius: 3px;
					margin-right: 34px;
					flex-shrink: 0;
					min-height: 52px;
				}
				.phase-name-container {
					display: flex;
					flex-direction: column;
					width: 165px;
					line-height: 62px;
					padding: 5px 0;
					.phase-name {
						margin: 0;
					}
				}
			}
			&.project {
				line-height: 50px;
				margin-left: 41px;
				margin-right: 20px;
			}
		}

		.project-timeline-phase-item,
		.project-timeline-project-item {
			.outer-container {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				height: 48px;
				.inner-container {
					flex-grow: 1;
					display: flex;
					flex-direction: column;
					flex-wrap: nowrap;
					text-align: center;
					justify-content: flex-end;
					.text-container {
						display: flex;
						flex-direction: row;
						justify-content: center;
						h3 {
							margin: 0 2px 0 2px;
						}
					}
					.progress-container {
						position: relative;
						display: flex;
						flex-direction: row;
						flex-wrap: nowrap;
						height: 6px;
						.progress {
							height: 100%;
							position: absolute;
							left: 0px;
							top: 0px;
						}

						.fill {
							flex-grow: 1;
							opacity: 0.5;
						}
					}
					.triangle-container {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						.triangle {
							width: 0;
							height: 0;
							border-left: 6px solid transparent;
							border-right: 6px solid transparent;
							border-top: 16px solid #bdbdbd;
						}
					}
					.triangle-placeholder {
						height: 16px;
					}
				}
				&.no-scope {
					.progress {
						width: 100%;
						background-color: #bdbdbd;
					}
				}
			}
		}

		.project-timeline-task-item {
			.warning-icon {
				flex: 1 0 auto;
				width: 28px;
				height: 20px;
				vertical-align: middle;
				display: inline-block;
				background-size: contain;
				background-position: left center;
				background-repeat: no-repeat;
				background-image: url('images/cross-section-icons/warning.svg');
			}

			.info-dropdown {
				display: flex;
				flex-direction: column;
				padding: 8px;
				border-radius: 3px;
				background-color: $color-white;
				border: 1px solid $app-border-color;
				position: fixed;
				z-index: 10;
				-webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
				-moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
				box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
				max-width: 350px;
				.task-name {
					margin-bottom: 4px;
				}
				.task-assigned-container {
					display: flex;
					flex-direction: column;
					flex-wrap: wrap;

					.assigned-person {
						display: flex;
						align-items: center;
						padding-bottom: 0.5em;
					}
				}
				.triangle-container {
					position: relative;
					.triangle {
						position: absolute;
						width: 10px;
						height: 15px;
						top: -23px;
						left: 5px;
						background-image: url('images/cross-section-icons/white-triangle.svg');
						background-size: 10px 15px;
					}
				}
				&.flip {
					.triangle {
						left: auto;
						right: 5px;
					}
				}

				.info-warning {
					display: flex;
					align-items: center;
					.warning-text {
						padding-left: 0.25em;
					}
				}
			}
		}

		.progress-wrapper {
			width: 100%;
			overflow: visible;
			height: 30px;
			text-align: center;
			position: relative;
			color: white;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			&.phase {
				height: 40px;
				line-height: 40px;
				&.no-scope {
					background-color: #bdbdbd;
				}
			}
			&.task {
				line-height: 30px;
				margin-top: 2px;
				.progress-label {
					height: 30px;
					left: 0;
				}
				&.no-scope {
					.fill,
					.progress {
						background-color: #bdbdbd;
					}
				}
			}
			&.project {
				font-weight: 700;
				height: 50px;
				line-height: 50px;
			}
		}

		.progress {
			height: 100%;
			position: absolute;
			left: 0px;
			top: 0px;
		}

		.fill {
			flex-grow: 1;
			opacity: 0.5;
		}

		.progress-label {
			position: absolute;
			z-index: 1;
			overflow: visible;
			pointer-events: none;
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			flex-wrap: nowrap;
			h4 {
				margin: 0;
			}
			.progress-label-done {
				padding-left: 4px;
				font-weight: 700;
			}
			.progress-label-percentage {
				font-weight: 700;
			}
		}
	}
}
