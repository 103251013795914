.task-modal-v3 {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(15, 15, 15, 0.4);

	// General use class for a loader inside the task modal
	.loading-item {
		background-color: #fff;
		background-image: url('images/loaders/loader-32x32-brown.svg');
		background-repeat: no-repeat;
		background-size: 25px;
		background-position: center;
		border-top: 1px solid #e6e6e6;
		height: 57px;
		width: calc(100% - 58px - 58px);
		display: block;
		margin: 0 auto;
	}

	.error-fetching {
		text-align: center;
		padding: 8px 0px;
		font-size: 13px;
		color: #635e5e;
	}

	.task-task-modal-v3-content {
		position: relative;
		background-color: white;
		width: 920px;
		margin-top: 14px;
		display: flex;
		flex-direction: column;
		padding-bottom: 79px;

		&.project-locked {
			padding-bottom: 30px;
		}

		&.footer-section-hidden {
			padding-bottom: 49px;

			&.project-locked {
				padding-bottom: 0;
			}
		}

		&.no-absolute-footer {
			padding-bottom: 0px;
			.task-modal-v3-comments-footer-section {
				position: inherit;
				bottom: 0;
				left: 0;
			}
			.task-modal-v3-footer-section {
				position: inherit;
				margin-top: 0;
			}
		}

		.rich-text-field-container .rich-text-field-emoji .draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
			position: fixed;
			left: initial;
		}

		.drop-file-overlay {
			position: absolute;
			top: -14px;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: white;
			opacity: 0.9;
			z-index: 300;
			clip-path: polygon(0 0, 779px 0, 100% 13px, 100% 100%, 0 100%);

			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.dropdown-icon {
				display: flex;
				flex-direction: column;
				align-items: center;

				.dropdown-arrow {
					animation: dropFile 1s ease-in-out infinite;
				}
			}

			.dropdown-text {
				font-size: 24px;
				font-weight: 700;
				margin: 20px;
				color: #545454;
			}
		}
		.cut-off-corner-box-container {
			position: absolute;
			top: -15px;
			left: 0;
		}
		.custom-scrollbar-div {
			display: block;
		}

		@keyframes dropFile {
			0% {
				transform: translate(0);
			}

			50% {
				transform: translateY(-7px);
			}

			100% {
				transform: translate(0);
			}
		}

		@import './sections/top_section.scss';
		@import './sections/comment_section_comment.scss';
		@import './sections/main_section.scss';
		@import './sections/options_section';
		@import './sections/description_section';
		@import './sections/subtasks_section.scss';
		@import './sections/files_section.scss';
		@import './sections/files_section_file.scss';
		@import './sections/comment_footer_section.scss';
		@import './sections/footer_section.scss';
		@import './sections/dependency_section.scss';
		@import './sections/dependency_container.scss';
		@import './sections/dependency_form.scss';
		@import './sections/label_section.scss';
		@import './sections/label_section_label_picker.scss';
		@import './sections/github_section.scss';
		@import './sections/github_object.scss';
		@import './sections/custom_fields_section.scss';
		@import './upload_button_with_gdrive.scss';
	}
}
