.date-picker-button-container {
	display: flex;
	flex-direction: column;
	.date-picker-button {
		background-image: url('images/v2/components/date-controls/calendar-icon.svg');
		background-size: 15px 15px;
		background-position: left 16px top 11px;
		background-repeat: no-repeat;
		padding-left: 40px;
		padding-right: 16px;
		border: 1px solid $app-border-color;
		transition: 0.1s border-color;
		height: 40px;
		border-radius: 4px;
		outline: 0;
		font-size: 12px;
		font-weight: 500;
		text-align: left;
		color: $v2-text-gray;
		min-width: 120px;
		background-color: white;

		.placeholder {
			text-transform: none;
			color: #535353;
			font-weight: 400;
		}

		&.hide-button-icon {
			background-image: none;
			background-size: 0;
			min-width: none;
			padding-left: 16px;
			width: auto;
		}
		&.date-range {
			min-width: 220px;
		}
		&:hover:enabled {
			border-color: $v2-date-picker-button-border-color;
		}
		&.locked {
			opacity: 0.5;
		}
		&.text-version {
			font-size: 12px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.86;
			letter-spacing: normal;
			color: $v2-text-gray;
			background-image: none;
			border: none;
			text-transform: capitalize;
			.week-number-text {
				margin-left: 10px;
				font-size: 12px;
				font-weight: normal;
			}
		}
		&.icon-version {
			border: none;
			height: 20px;
			min-width: 20px;
			background-size: 20px;
			background-position: center;
			padding: 0;
		}
		.day-month {
			font-weight: 700;
		}
		.year {
			font-weight: 400;
			margin-left: 3px;
		}
	}
	label {
		height: 11px;
		font-size: 9px;
		font-weight: 500;
		line-height: 1.22;
		letter-spacing: 1px;
		color: $v2-text-light-gray;
		text-transform: uppercase;
		margin-bottom: 5px;
	}
}
.date-picker-button-container-v3 {
	background-color: $color-white;
	&.inherit {
		background-color: inherit;
	}
	&.fullWidth {
		width: 100%;
	}
	.date-picker-button {
		border: none;
		outline: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.date-picker-button-label {
			font-size: 9px;
			letter-spacing: 0.9px;
			color: $v2-text-light-gray;
			font-weight: 500;
		}
		.date-text-container {
			font-size: 12px;
			&.baseline {
				font-size: 14px;
			}
			&.leftAlign {
				width: 100%;
				text-align: left;
				margin-left: 16px;
			}

			.date-text-bold {
				font-weight: bold;
				&.large {
					font-size: 13px;
				}
			}

			.italic {
				font-style: italic;
			}

			.year {
				font-size: 10px;
				margin-left: 4px;
			}

			svg {
				transform: translate(-5px, 3px);
			}
		}
		&.task-modal-task-date {
			&.end-date-picker-button {
				border-right: none;
			}

			text-align: left;
			background-image: none;
			padding: 8px 10px;
			height: 50px;
			min-width: 102px;
			width: 102px;
			border-right: 2px solid $app-border-color;
			&:focus {
				background-color: $app-border-color;
			}
		}
		&.date-picker-button-compact {
			border: 1px solid $app-border-color;
			width: 45px;
			height: 40px;
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			.date-picker-icon {
				background-image: url('images/v2/components/date-controls/calendar-icon.svg');
				background-position: center;
				background-repeat: no-repeat;
				background-size: 20px;
				height: 30px;
				width: 30px;
			}
			.date-text-bold {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}
			&.end-date-picker-button {
				border-left: none;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
			}
		}
		&.date-picker-button-standard {
			border: 1px solid $app-border-color;
			height: 40px;
			width: 110px;
			align-items: center;
			justify-content: center;
			border-radius: 4px;

			&.slim {
				height: 29px;
			}
			&.fullWidth {
				width: 100%;
			}

			.empty-text {
				font-size: 13px;
				&.leftAlign {
					width: 100%;
					text-align: left;
					margin-left: 16px;
				}
			}

			.date-picker-icon {
				background-image: url('images/v2/components/date-controls/calendar-icon.svg');
				background-position: center;
				background-repeat: no-repeat;
				background-size: 20px;
				height: 30px;
				width: 30px;
			}
		}
		&.scoping-phase-date {
			border: 1px solid transparent;
			text-align: left;
			align-items: center;
			background-image: none;
			padding: 6px 0;
			margin: 5px 0;
			border-radius: 4px;
			height: 30px;
			min-width: 67px;
			width: 67px;
			&.show-year {
				min-width: 95px;
				width: 95px;
			}
			&.double {
				min-width: 100%;
				width: 100%;
			}
		}
		&.baseline-phase-date {
			border: 1px solid transparent;
			text-align: left;
			align-items: center;
			background-image: none;
			border-radius: 4px;
			min-width: 67px;
			width: 67px;
			&.show-year {
				width: auto;
			}
			&.double {
				min-width: 100%;
				width: 100%;
			}
		}

		&:hover:not(.locked) {
			background-color: rgba(0, 0, 0, 0.1);
		}
		&:focus {
			background-color: rgba(0, 0, 0, 0.15);
		}
		&.active {
			background-color: rgba(0, 0, 0, 0.2);
		}
		&:focus.highlighted,
		&.highlighted.active {
			background-color: $color-white;
			border: 1px solid #6e0fea;
		}
	}
}
