/**
 * Styling for data tiles used throuoght the system.
 */

@mixin data-tile {
	label {
		padding: 0 0 5px 0;
		display: block;
		font-weight: 600;
		text-transform: uppercase;
		color: #4a4a4a;
		font-size: 11px;
		letter-spacing: 0.5px;
	}
	.data-tile-info-wrapper {
		display: flex;
		flex: 1 1 auto;
		flex-direction: row;
		align-items: center;
		.info-component-icon-wrapper {
			height: 16px;
			width: 16px;
			background-size: 16px;
		}
		.tooltip-right,
		.tooltip-left {
			font-size: 12px;
			font-weight: 400px;
		}
		.triangle-right {
			right: 0px;
		}
		.triangle-left {
			left: 0px;
		}
	}
	.data-tile-value,
	.date-input,
	.date-input-focused,
	.date-input-locked {
		font-size: 16px;
		padding-top: 0;
		font-weight: 700;
	}
	.data-tile-value {
		padding-bottom: 10px;
	}
	.date-input,
	.date-input-focused,
	.date-input-locked {
		padding-bottom: 0;
		min-height: 21px;
	}

	.data-tile-diff {
		text-align: center;

		.diff-value {
			margin-bottom: 3px;
			font-size: 16px;
			font-weight: 400;
			text-align: center;
			padding: 0 10px 0 20px;

			&.diff-up {
				background: url('images/components/up.svg') no-repeat left center;
			}

			&.diff-down {
				background: url('images/components/down.svg') no-repeat left center;
			}
		}

		.diff-percentage {
			font-size: 14px;
			font-weight: 600;
			letter-spacing: 0.5px;
			text-transform: uppercase;
			text-align: center;
			color: #4a4a4a;
		}
	}
}
