.side-backlog {
	margin-left: 32px;
	width: 218px;
	position: relative;
	z-index: 2;
	box-shadow: 4px 0 10px -6px rgba(0, 0, 0, 0.2);
	&.collapsed {
		.title {
			left: 50%;
			top: 6px;
			writing-mode: vertical-lr;
			transform: rotate(180deg);
		}
		flex-grow: 1;
		width: 40px;
		border-radius: 4px;
		background: #f3f3f3;
		margin-bottom: 5px;
		cursor: pointer;
	}
	.header-container {
		font-size: 12px;
		padding: 5px;
		margin: 0;
		display: flex;
		flex-direction: row;
		align-items: center;
		flex: 1 100%;
		background: $app-border-color;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		&.collapsed {
			display: flex;
			flex-direction: column;
			height: 100%;
			.control {
				order: 1;
			}
			.title {
				order: 2;
				margin: 0;
				width: 100%;
				padding: 5px;
				align-items: center;
			}
		}

		&:hover {
			.control.dot-options {
				opacity: 1;
				transition: opacity 0.2s ease-in-out;
			}
		}
		.title {
			margin-right: auto;
			margin-left: 16px;
			font-weight: bold;
			display: flex;
			.tasks-number {
				font-weight: 300;
				margin: 2px;
			}
			.information-icon {
				cursor: help;
			}
		}
		.control-search-filter {
			display: flex;
			.search-V2 {
				margin-right: 0;
				height: 30px;
				border: 1px solid transparent;
				&.expanded {
					border-color: $v2-branding-purple;
					background-color: white;
					&:hover {
						background-color: white;
					}
				}
				&:hover {
					background-color: rgba(0, 0, 0, 0.2);
				}
				input {
					height: 100%;
					width: 100%;
				}
				&:not(.expanded):hover {
					background-color: rgba(0, 0, 0, 0.2);
				}
			}
			.filter-v3 {
				.filter-v3-button {
					&:hover {
						background-color: rgba(0, 0, 0, 0.2);
					}
					&.active {
						&:hover {
							background-color: $v2-branding-purple;
						}
					}
					&.noTextButton {
						width: 30px;
					}
					.filter-v3-active-filter-count {
						color: #ffffff;
						font-size: 12px;
						line-height: 28px;
						width: 100%;
						text-align: center;
					}
				}
			}
		}
		.control {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: center;
			&.dot-options {
				opacity: 0;
			}
			.expand-control {
				height: 30px;
				cursor: pointer;
				padding-top: 7px;
				padding-right: 15px;
				padding-left: 15px;
				&:hover {
					background-color: rgba(0, 0, 0, 0.2);
				}
				&.expand {
					margin: 10px;
					background: url('images/v2/components/dropdown/arrow-right.svg') no-repeat center;
					&:hover {
						background: rgba(0, 0, 0, 0.2) url('images/v2/components/dropdown/arrow-right.svg') no-repeat center;
					}
				}
				&.collapse {
					background: url('images/v2/components/dropdown/arrow-left.svg') no-repeat center;
					&:hover {
						background: rgba(0, 0, 0, 0.2) url('images/v2/components/dropdown/arrow-left.svg') no-repeat center;
					}
				}
			}
		}
	}
	.body {
		background-color: #f3f3f3;
		color: #535353;
		.board-card-outer-v3 {
			margin: 5px 10px 0 10px;
			&.locked,
			&.select-mode {
				&:hover {
					.card-contents {
						.project-workflow-task-header {
							.name-wrapper {
								width: 176px;
							}
						}
					}
					.project-indicator-component {
						margin-right: 8px;
					}
				}
			}
			&:hover {
				.card-contents {
					.project-workflow-task-header {
						.name-wrapper {
							width: 160px;
						}
						.new-ui-actions-menu {
							width: 18px;
							display: block;
						}
					}
				}
				.dot-options {
					width: 16px;
					right: 5px;
					opacity: 1;
				}
			}
			.card-top-bar {
				margin-bottom: -9px;
				&.selected {
					.card-top-left,
					.card-top-angle {
						border-width: 1px;
						border-color: $v2-branding-purple;
					}
				}
				.card-top-left {
					width: calc(100% - 15px);
					border-top: 1px solid $app-border-color;
					border-left: 1px solid $app-border-color;
					height: 8px;
				}
				.card-top-angle {
					height: 8px;
					width: 18px;
					right: 1px;
					border-top: 1px solid #e6e6e6;
					top: 3.75px;
				}
			}
			.dot-options {
				transition: width 0.2s ease-in-out;
				width: 0;
			}
			.card-contents {
				padding: 0px 0px 0px 10px;
				margin-top: 5px;
				border: 1px solid $app-border-color;
				border-top: none;
				&.selected {
					border-width: 1px;
					border-color: $v2-branding-purple;
				}
				.project-workflow-task-header {
					margin-right: 0px;
					.id-name-wrapper {
						margin-bottom: 5px;
					}
					.new-ui-actions-menu {
						width: 0;
						display: none;
					}
					.name-wrapper {
						margin-right: 10px;
						padding-bottom: 5px;
						border-bottom: 1px solid $app-border-color;
						height: 27px;
						.task-name {
							font-weight: normal;
							white-space: nowrap;
						}
					}
					.task-project-id {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						z-index: 2;
						align-items: center;
						.task-id {
							flex-grow: 1;
						}
						.task-indicators {
							height: 15px;
							font-weight: bold;
						}
						.project-indicator-component {
							margin-right: 8px;
						}
						.favourite-wrapper {
							margin-right: 8px;
						}
					}
				}
				.remaining-wrapper {
					font-size: 11px;
					font-weight: 500;
					margin-bottom: 8px;
				}
			}
		}

		.phase-bottom-section-wrapper {
			padding: 10px;
			.phase-bottom-section {
				display: flex;
				padding: 00px;
				margin-bottom: 0;
				&.buttonRight {
					.input-value-container {
						border-top-right-radius: 0px;
						border-bottom-right-radius: 0px;
						border-right: none;
						border-right-color: transparent;
					}
				}
				&.connected {
					display: flex;
					.input-container-v2 {
						padding: 0px;
						width: 126px;
					}
				}
				.input-container-v2 {
					flex-grow: 1;
					flex-shrink: 1;
					.input-value-container {
						.input-value {
							width: 0;
						}
						height: 30px;
					}
				}
				.new-task-button {
					border: none;
					outline: none;
					box-shadow: none;
					width: 30px;
					height: 30px;
					flex-shrink: 0;
					background: $v2-create-button-green url('images/v2/components/overview_time/plus-add-white.svg') no-repeat center;
					&:focus {
						outline: none;
					}
					&:hover {
						//background: $v2-create-button-green url('images/v2/components/overview_time/plus-add-white.svg') no-repeat center;
					}
				}
			}
		}
	}
}
