.task-modal-v3-custom-fields-section-container {
	border-top: 1px solid #e6e6e6;
	margin: 16px 58px;
}

.task-modal-v3-custom-fields-section {
	min-height: 57px;
	padding-top: 15px;
	padding-bottom: 11px;
	font-size: 13px;
	font-weight: 500;

	input {
		margin-bottom: 8px;
		margin-top: 8px;
		padding-left: 12px;
		font-weight: 400;
		max-width: 260px;
	}
}