html,
body,
#app {
	box-sizing: border-box;
	width: 100%;
	height: 100%;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

.mark-done {
	text-decoration: line-through;
}

/**
 * Application layout.
 */
.app {
	overflow: hidden;
	background-color: #fff;
	@include flex-parent(column);

	.app-main {
		background-color: $color-light-grey;
		//flex: 1;
		&.project-section {
			overflow: hidden;
		}
		@include flex-parent(column);
		height: initial;
		&.white {
			background-color: $color-white;
		}
		&.header {
			min-height: 34px;
		}
	}

	.hubspot-chat {
		z-index: 1000000;
		position: absolute;
		bottom: 0;
		padding: 16px 16px 16px 32px;
		left: inherit;
		right: 0px;
		.hubspot-chat-inner {
			display: block;
			width: 60px;
			height: 60px;
			background-color: #274f66;
			box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1), 0 2px 24px rgba(0, 0, 0, 0.2);
			border-radius: 50%;
			line-height: 56px;
			transition: background-color 60s ease;
			font-size: 35px;
			text-align: center;
			&:hover {
				box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2), 0 4px 28px rgba(0, 0, 0, 0.3);
				background-color: rgb(0, 0, 0);
				cursor: none;
			}
		}
	}
}

/*
 * Remove text decoration from react router Link
 */
.react-router-link {
	text-decoration: none;
}

.display-none {
	display: none !important;
}

/*
 * Hide the wierd IE input clear 'X' icon
 */
::-ms-clear {
	display: none;
}

.warning-option-filter {
	display: flex;
	.task-warning {
		@include warning-icon;
		display: inline-block;
		margin-left: 0px;
		margin-right: 0.3em;
	}
}
.indicator-option-filter {
	display: flex;
	align-items: center;
	.bug-icon,
	.blocked-icon,
	.dependency-icon,
	.high-priority-icon,
	.non-billable-icon,
	.star-icon {
		width: 18px;
		height: 18px;
		margin-right: 0.3em;
	}
	.bug-icon {
		background: url('images/v2/components/workflow-task/v3/ico-bug.svg') no-repeat;
		background-size: contain;
	}
	.blocked-icon {
		background: url('images/v2/components/workflow-task/v3/ico-blocked.svg') no-repeat;
		background-size: contain;
	}
	.dependency-icon {
		background: url('images/cross-section-icons/dependency.svg') no-repeat;
		background-size: contain;
	}
	.high-priority-icon {
		background: url('images/v2/components/input-field/ico-priority.svg') no-repeat;
		background-size: contain;
		width: 16px;
		height: 16px;
	}
	.non-billable-icon {
		background: url('images/cross-section-icons/not-billable.svg') no-repeat;
		background-size: contain;
	}
	.star-icon {
		background: url('images/v2/components/workflow-task/v3/ico-star.svg') no-repeat;
		background-size: contain;
	}
	.icon-dependency {
		margin-right: 5px;
	}
}
.default-warning-modal {
	width: 572px;
	.warning-part-2 {
		font-size: 14px;
		color: $v2-text-gray;
		margin-top: 16px;
		white-space: break-spaces;
	}

	.warning-message-component {
		margin-top: 8px;
		display: flex;
		flex-direction: column;
	}

	.confirmation-info {
		font-size: 13px;
	}

	&.change-allocation-type-modal, &.change-weekend-visibility-setting-modal-content {
		width: unset;
		color: $v2-text-gray;
		.bold-font-weight {
			font-weight: bold;
		}
		.warning-message-component {
			margin-bottom: 17px;
		}
		.changes-about-to-happen-title {
			margin: 17px 0;
			line-height: 150%;

			.header-tooltip-wrapper {
				margin-left: 10px;
			}

			.change-information-new-option {
				text-transform: lowercase;
			}
		}
		.message {
			font-family: Urbane;
			font-size: 20px;
			font-weight: 500;
			line-height: 1.25;
			color: $v2-text-gray;
		}
		.allocation-type, .new-setting-information {
			font-weight: 700;
			font-size: 14px;
			margin-bottom: 20px;
			text-transform: uppercase;
			line-height: 1.43;
			color: black;
			.tooltip-container {
				height: 18px;
				.information-icon {
					margin-left: 7px;
					margin-bottom: -2px;
				}
			}
		}
		.wrapper {
			margin-top: 20px;
			font-size: 11px;
			margin-bottom: 27px;
			.input-wrapper,
			.input-wrapper-focused {
				border-bottom: 2px rgba(161, 161, 161, 0.6) solid;
				background-size: 0px;
				input {
					padding: 4px 0;
					color: $v2-text-gray;
					&::placeholder {
						opacity: 0.6;
						color: $v2-text-light-gray;
					}
				}
			}
		}
		.transform-disclaimer {
			font-size: 11px;
			line-height: 1.45;
			font-style: italic;
			margin-bottom: 30px;
		}

		.description {
			padding: 16px 0;
			border-top: 1px rgba(161, 161, 161, 0.2) solid;

			.description-title, .description-information {
				margin-bottom: 10px;
				font-size: 12px;
			}

			.description-title {
				font-weight: 600;
			}

			.description-information {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;
				font-weight: 500;
			}

			.description-list {
				font-size: 11px;
				font-style: italic;
				line-height: 1.82;
				margin-bottom: 17px;
				.list-line {
					display: flex;
				}
				.circle {
					width: 4px;
					height: 4px;
					border-radius: 2px;
					background-color: #535353;
					margin-top: 8px;
					margin-right: 7px;
				}
				&.pushed-right {
					padding-left: 20px;
				}
			}
		}

		.checkbox-row {
			display: flex;
			align-items: center;
			margin-bottom: 8px;
			&.checkbox-row-first {
				margin-bottom: 10px;
			}
			.checkbox-label {
				margin-left: 8px;
				font-size: 13px;

				&.locked {
					opacity: 0.5;
				}
			}
			.phase-order-label {
				margin-bottom: 0;
			}
			.check-box {
				min-width: 12px;
				height: 12px;
			}
			.check-mark {
				margin-bottom: 4px;
				&:checked {
					background: #3b88fd;
					border: solid 2px #3b88fd;
				}
				&:after {
					width: 5px;
					height: 7px;
					border-width: 0 2px 2px 0;
				}
				&:focus,
				&:hover {
					border: solid 2px #3b88fd;
				}
			}
		}
	}
}
