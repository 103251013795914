.generic-empty-state-v2 {
	border: solid 1px #dbdbdb;
	background-color: #f3f3f3;
	text-align: center;
	color: #535353;
	white-space: normal;
	flex-grow: 1;
	//margin: auto 0px;
	position: relative;
	height: 100%;
	&.small {
		padding: 24px 0;
		&.clickable {
			cursor: pointer;
		}
		.empty-state-header {
			font-size: 16px;
			font-weight: bold;
			margin-bottom: 9px;
		}
	}
	.empty-state-elements {
		margin: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		width: 360px;
		.empty-state-image {
			height: 232px;
			margin: 0px auto;
			background-image: url('images/empty-states/empty_state.svg');
			background-size: 161px 232px;
			background-position: center center;
			background-repeat: no-repeat;
		}
		.empty-state-header {
			font-weight: 700;
			margin-bottom: 16px;
		}
		.empty-state-text {
			font-size: 14px;
			font-weight: normal;
		}
		.action-button {
			height: 30px;
			padding: 8px 24px;
			margin-top: 16px;
		}
	}
}
