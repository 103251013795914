.settings-app-gitlab {
	a {
		cursor: pointer;
	}
	.section-body {
		button {
			@include primary-button;
		}
	}
	.gitlab-reauthorize-button {
		margin-right: 8px;
	}
}
