/*
* Animations
*/

//Animation displayed when calendar is opened/closed
.appear-enter {
	opacity: 0.01;
}
.appear-enter.appear-enter-active {
	opacity: 1;

	-webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 2s; /* Firefox < 16 */
	-ms-animation: fadein 2s; /* Internet Explorer */
	-o-animation: fadein 2s; /* Opera < 12.1 */
	animation: fadein 2s;
}
.appear-leave {
	opacity: 1;
}
.appear-leave.appear-leave-active {
	opacity: 0.01;

	-webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 2s; /* Firefox < 16 */
	-ms-animation: fadein 2s; /* Internet Explorer */
	-o-animation: fadein 2s; /* Opera < 12.1 */
	animation: fadein 2s;
}

//day span hover animation
.circle {
	color: #fff;
}

/*
* Styling
*/

.date-picker-wrapper {
	position: relative;
	outline: 0;
	&.locked {
		opacity: 0.5;
		cursor: default;
	}
	.label,
	.label-focused {
		font-size: 0.75rem;
		height: 2em;
	}
	.label-focused {
		color: #6f49c5;
	}
	.label {
		color: $material-label-color;
	}

	.input-wrapper {
		.date-input,
		.date-input-focused,
		.date-input-locked {
			outline: 0;
			padding: 8px 0;
			text-overflow: ellipsis;
			position: relative;
			display: inline-block;
			width: 100%;
			min-height: 33px;
			&.placeholder {
				color: #a4a4a4;
			}
		}
		.date-input,
		.date-input-focused {
			border: 0;
			transition: background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
			background: transparent no-repeat;
			background-image: linear-gradient(to bottom, $material-focused-color, $material-focused-color), linear-gradient(to bottom, silver, silver);
			background-size: 0 2px, 100% 1px;
			background-position: 50% 100%, 50% 100%;
			width: 100%;
			position: relative;
			&:hover {
				cursor: pointer;
				background-image: linear-gradient(to bottom, $material-focused-color, $material-focused-color), linear-gradient(to bottom, black, black);
			}
			.clear-icon {
				top: 16px;
				right: 5px;
				position: absolute;
				height: 8px;
				width: 8px;
				padding-right: 10px;
				background-size: 8px;
				background-repeat: no-repeat;
				background-image: url('images/cross-section-icons/actions/remove-3.svg');
				cursor: pointer;
			}
		}
		.date-input-focused {
			background-size: 100% 2px, 100% 1px;
			outline: none;
		}
		.date-input-locked {
			border-bottom: 1px dotted $material-locked-color;
		}
		.week-picker {
			display: flex;
			justify-content: space-between;
			margin: 0 10px;
			.week-value-button,
			.previous-week-control,
			.next-week-control {
				border: none;
				outline: none;
				cursor: pointer;
			}
			.week-value-button {
				padding: 0;
				&:hover {
					color: $color-purple-hover-active;
				}
			}
			.previous-week-control,
			.next-week-control {
				width: 19px;
				padding: 0;
				background-size: 19px 19px;
			}
			.previous-week-control {
				margin-right: 5px;
				background: url('images/sections/overview/previous.svg') no-repeat center;
				&:hover {
					background-image: url('images/sections/overview/previous-active.svg');
				}
			}
			.next-week-control {
				margin-left: 5px;
				background: url('images/sections/overview/next.svg') no-repeat center;
				&:hover {
					background-image: url('images/sections/overview/next-active.svg');
				}
			}
		}
	}

	.absolute {
		position: absolute;
	}

	.calendar-wrapper {
		flex: 1 1 auto;
		height: auto;
		width: 288px;
		z-index: 999;
		top: 55px;
		&.weekPicker {
			top: 30px;
		}
		&.right {
			right: 0;
		}
		&.left {
			left: 0;
		}
		overflow: auto;
		background-color: $color-white;
		box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
		transition: transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
		transform: scaleY(1);
		transform-origin: left top 0px;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

		.calendar-header {
			padding: 8px 15px;
			height: 118px;
			display: flex;
			flex-direction: column;
			background-color: #392664;
			padding: 20px;
			justify-content: space-between;
			color: $color-white;
			.inactive {
				color: #aeaeae;
				cursor: pointer;
			}
			.year-text {
				font-size: 1em;
				font-weight: 700;
			}
			.date-text {
				font-size: 2.25em;
				font-weight: 700;
			}
		}
		.year-view-body,
		.calendar-body {
			display: flex;
			flex-direction: column;
			overflow: hidden;
		}
		.year-view-body {
			flex: 0.85 0.85 auto;
		}
		.inline {
			flex: 1 1 auto;
		}
		.year-view-body {
			height: 200px;
			justify-content: center;
			align-items: center;
			.years-list {
				display: flex;
				flex: 1 1 auto;
				flex-direction: column;
				.year-span {
					padding: 8px;
					font-size: 1em;
					font-weight: 400;
					text-align: center;
					&:hover {
						color: $color-purple-hover-active;
						cursor: pointer;
					}
				}
				.selected {
					font-size: 1.5em;
					font-weight: 700;
					color: $color-purple-hover-active;
				}
			}
		}
		.calendar-body {
			.calendar-controls {
				display: flex;
				flex: 1 1 auto;
				flex-direction: column;
				margin: 0.5em;
				.month-controls {
					display: flex;
					flex: 0 0 26px;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					.previous-control,
					.next-control {
						cursor: pointer;
						height: 1.5em;
						width: 1.5em;
						background-size: contain;
						outline: none;
					}
					.previous-control {
						background: url('images/sections/project/section-sidebar/collapse.svg') no-repeat;
					}
					.next-control {
						background: url('images/sections/project/section-sidebar/expand.svg') no-repeat;
					}
					.month-text {
						font-size: 14px;
						font-weight: 700;
					}
				}
				.week-days {
					margin-top: 5px;
					display: flex;
					flex: 0 0 26px;
					flex-direction: row;
					justify-content: space-between;
					margin-bottom: 5px;
					span {
						font-size: 12px;
						font-weight: 500;
						width: 30px;
						line-height: 30px;
						color: #ababab;
						text-align: center;
					}
				}
				.calendar {
					display: flex;
					flex: 1 1 180px;
					flex-direction: column;
					justify-content: flex-start;
					.week {
						flex: 1 1 auto;
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						.day,
						.day-selected,
						.today {
							font-size: 12px;
							width: 30px;
							line-height: 30px;
							height: 30px;
							border-radius: 15px;
							text-align: center;
							vertical-align: middle;
							cursor: pointer;
						}
						.day-selected {
							background-color: #392664;
							color: white;
						}
						.day,
						.today {
							&:hover {
								color: white;
								background-color: #8875b2;
							}
						}
						.today {
							color: #ed01db;
						}
						.empty {
							width: 30px;
							height: 30px;
						}
					}
				}
			}
		}
	}
	.top {
		top: -320px;
	}
}
