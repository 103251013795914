.action-menu-container {
    cursor: pointer;
    margin-left: 10px;
    &.horizontal-icon,
    &.vertical-icon {
        height: 24px;
        width: 24px;
    }
    &.horizontal-icon {
        background: url('images/sections/project/settings/navigation-show-more-horizontal.svg') no-repeat;
    }
    &.vertical-icon {
        background: url('images/components/overlay/navigation-show-more-vertical.svg') no-repeat;
    }
    &.using-action-label {
        position: relative;
    }
    background-size: contain;
    flex-shrink: 0;
    .action-label {
        color: #48b8ff;
        font-weight: bold;
        cursor: pointer;
        text-align: end;
        width: 100%;
        display: inline-block;
    }
    .action-dropdown {
        @include actions-dropdown;
        &.show-on-top {
            bottom: 16px;
        }
        &.show-on-bottom {
            top: 16px;
        }
    }
    &:focus,
    &:hover {
        outline: 0;
        .action-label {
            color: $color-purple-hover-active;
        }
    }
}
