.all-projects-section {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	$high-font-weight: 700;
	$medium-high-font-weight: 600;
	$medium-font-weight: 500;
	$normal-font-weight: 400;
	margin-top: 8px;
	.progress-container {
		@include completion-bar;
		margin-top: auto;
	}
	.project-status-section-wrapper {
		margin-bottom: 32px;
	}
	.all-projects {
		padding: 1.6875rem 2rem 0px 2rem;
		display: flex;
		flex-direction: column;
		flex: 1;
		overflow: hidden;
		.project-status-section-title {
			color: $v2-text-gray;
			font-size: 16px;
			margin-bottom: 10px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			border: none;
			outline: none;
			&:hover {
				opacity: 0.8;
			}
			.title {
				font-weight: $medium-high-font-weight;
				margin-right: 3px;
			}
			.arrow {
				padding: 0;
				width: 21px;
				height: 21px;
				margin-left: 16px;
				&.expanded {
					background: url('images/v2/components/dropdown/arrow-up.svg') no-repeat center;
				}
				&.collapsed {
					background: url('images/v2/components/dropdown/arrow-down.svg') no-repeat center;
				}
			}
		}
		.project-group-nested-row {
			//this is needed to show correct box shadow on projects which belong to connected project
			box-shadow: 24px 2px 0 0 rgba(0, 0, 0, 0.3);
			.nested-id {
				padding-right: 0;
			}
		}
		&.useNewNavigation {
			padding: 0;
			&.list-view {
				.project-status-section-wrapper {
					padding: 0 66px;
				}
			}
			&.grid-view {
				.project-status-section-wrapper {
					padding: 0 49px 0 66px;
				}
			}
			&.overflowing-list {
				padding-right: 0;
				padding-left: 0;
				table {
					padding-right: 66px;
				}
			}
			//needed to show scroll bars on top of progress color we have for each tr
			.thumb-horizontal,
			.thumb-vertical {
				z-index: 99;
			}
			/* &.overflowing {
				padding: 0 0 0 66px;
			} */
			.progress-indicator {
				position: absolute;
				top: 0;
				right: 0;
				height: 100%;
				width: 100%;
				display: flex;
				z-index: 1;
				.remaining,
				.completed {
					height: 100%;
				}
				.add-right-border {
					border-right: 1px solid $v2-button-very-light-grey;
				}
			}
			@include standard-table-v2;
			.project-group-nested-row {
				box-shadow: none;
			}
			table {
				border-collapse: separate;
				border-spacing: 0 10px;
				.center-text {
					text-align: center;
				}
				tbody {
					tr,
					tr:first-child {
						border-radius: 12px;
						td:first-child {
							border-top-left-radius: 12px;
							border-bottom-left-radius: 12px;
							position: relative;
							overflow: visible;
							.completed-bar {
								display: block;
								position: absolute;
								top: 0;
								left: 0px;
								height: 40px;
								border-top-left-radius: 12px;
								border-bottom-left-radius: 12px;
								&.completed {
									border-top-right-radius: 12px;
									border-bottom-right-radius: 12px;
								}
							}
						}
						td:last-child {
							border-top-right-radius: 12px;
							border-bottom-right-radius: 12px;
						}
						&:hover {
							box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
							td {
								border-right: none;
								border-left: none;
							}
						}
						&.project-group-nested-row {
							&:hover {
								box-shadow: none;
							}
						}
						td,
						td:first-child {
							border-bottom: none;
							border-left: none;
							border-top: none;
							color: inherit;
							a {
								color: inherit;
								text-overflow: ellipsis;
								overflow: hidden;
							}
						}
						td:first-child {
							font-size: 10px;
							font-weight: 700;
							a {
								padding: 12px;
								margin: -12px;
							}
						}
						.month-day,
						.year {
							font-size: 12px;
							text-transform: uppercase;
						}
						.month-day {
							font-weight: 600;
							padding-right: 3px;
						}
						&.white-row {
							td {
								border-top: 1px solid $app-border-color;
								border-bottom: 1px solid $app-border-color;
							}
							td:first-child {
								border-left: 1px solid $app-border-color;
							}
							td:last-child {
								border-right: 1px solid $app-border-color;
							}
						}
					}
					td:first-child {
						border-top-left-radius: 12px;
						border-bottom-left-radius: 12px;
						&.nested-id {
							border-bottom-left-radius: 0;
							border-top-left-radius: 0;
						}
					}
					td:last-child {
						border-top-right-radius: 12px;
						border-bottom-right-radius: 12px;
						padding-right: 16px;
					}
					.completed-indicator-container {
						display: flex;
						justify-content: center;
						.completed-indicator {
							width: 21px;
							height: 18px;
							background-repeat: no-repeat;
							background-position: center;
							background-size: contain;
							background-image: url('images/v2/ok-green.svg');
							&.white {
								background-image: url('images/v2/ok-white.svg');
							}
						}
					}
					.id-link {
						color: inherit;
						a {
							color: inherit;
						}
					}
				}
			}
		}
		.scrollable {
			flex: 1;
			display: flex;
			flex-direction: column;
		}
		.project-cards {
			flex: 1 1 auto;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: flex-start;
			padding: 2px 0 2px 0;
			@include scroll-y;
			.project-card {
				transition: transform 0.25s;
				&:hover {
					transition: transform 0.25s;
					-webkit-transform: scale(1.02);
					-ms-transform: scale(1.02);
					transform: scale(1.02);
				}
				width: 300px;
				height: 250px;
				background-color: white;
				border-radius: 5px;
				margin-bottom: 15px;
				box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
				cursor: pointer;
				margin-left: 10px;
				margin-right: 10px;
				.project-card-data {
					padding: 0px 30px 15px 30px;
					position: relative;
					margin-top: -250px;
					display: flex;
					flex-direction: column;
					height: 250px;
					.title {
						font-size: 24px;
						font-weight: bold;
						color: white;
						max-width: 240px;
						overflow: hidden;
						text-overflow: ellipsis;
						margin-bottom: 0;
					}
					.client {
						color: white;
						margin-top: 0;
					}
				}
			}
			.project-card-outer-wrapper {
				position: relative;
				margin-bottom: 16px;
				margin-right: 16px;
				.full-description-pop-up {
					display: flex;
					align-items: flex-end;
					flex-direction: column;
					position: fixed;
					padding: 0 0 24px 24px;
					z-index: 999;
					background-color: $color-white;
					font-size: 13px;
					width: 322px;
					height: 360px;
					border-radius: 4px;
					box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
					color: $v2-dark-grey;
					border: 1px solid $app-border-color;
					.triangle-arrow {
						position: absolute;
						width: 0;
						height: 0;
						top: 35px;
						border-style: solid;

						&.right {
							left: 320px;
							border-width: 10.5px 0 10.5px 8px;
							border-color: transparent transparent transparent $color-white;
						}
						&.left {
							left: -8px;
							border-width: 10.5px 8px 10.5px 0;
							border-color: transparent $color-white transparent transparent;
						}
					}
					.full-description-title {
						font-weight: 700;
						margin-bottom: 8px;
						width: 100%;
					}
					.full-description-close-button {
						@include close-button;
						height: 13px;
						width: 13px;
						background-size: contain;
						margin: 4px 4px 0 0;
					}
					.full-description-body {
						height: 300px;
						width: 100%;
						overflow: hidden;
					}
				}
			}
			.project-card-v2 {
				width: 249px;
				min-height: 146px;
				border-radius: 16px;
				padding: 24px;
				overflow: hidden;
				position: relative;
				background-color: transparent;
				&.white-project-color {
					border: 1px solid $v2-button-very-light-grey;
				}
				&.extra-margin-v2 {
					margin-top: 58px;
				}
				&:hover {
					box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
				}
				.space {
					width: 8px;
					text-align: center;
				}
				.card-header,
				.card-body {
					position: relative;
					z-index: 2;
					background-color: transparent;
				}
				.card-header {
					display: flex;
					justify-content: space-between;
					margin-bottom: 8px;
					height: 14px;
					.status-id-wrapper {
						display: flex;
						.project-status {
							width: 14px;
							height: 14px;
							background: red;
							border-radius: 50%;
							margin-right: 8px;
						}
						.project-id {
							font-size: 13px;
							font-weight: $high-font-weight;
						}
					}
					.project-dates {
						font-size: 12px;
						display: flex;
						flex-direction: row;
						.date {
							.day-month {
								font-weight: $high-font-weight;
								text-transform: uppercase;
							}
						}
					}
				}
				.card-body {
					font-weight: $high-font-weight;
					.project-name {
						font-size: 16px;
						max-height: 40px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: block;
					}
					.project-client {
						font-weight: $normal-font-weight;
						height: 18px;
						display: flex;
						flex-direction: row;
						align-items: center;
						margin-top: 8px;
						font-size: 14px;
						img {
							margin-right: 8px;
						}
						span {
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
							max-width: 163px;
						}
					}
					.project-contacts,
					.project-description,
					.project-progress {
						margin-top: 16px;
						font-size: 13px;
						display: flex;
						flex-direction: column;
					}
					.project-contacts {
						span:first-of-type {
							font-weight: $normal-font-weight;
						}
						.imgs-wrapper {
							display: flex;
							margin-top: 5px;
							img {
								margin-left: -5px;
								&.first {
									margin-left: 0;
								}
							}
						}
					}
					.project-description {
						.description-text-wrapper {
							font-weight: $normal-font-weight;
							position: relative;
							display: inline-block;
							max-width: 100%;
							margin-top: 5px;
							max-height: 48px;
							overflow: hidden;
							.read-more-button {
								padding: 0 4px;
								font-size: 12px;
								text-transform: uppercase;
								font-weight: $medium-font-weight;
								border: none;
								outline: none;
								&:hover {
									opacity: 0.8;
								}
							}
						}
					}
					.project-progress {
						.progress-value {
							font-weight: $normal-font-weight;
							margin-top: 5px;
						}
					}
				}
			}
			.project-group-v2 {
				border-radius: 16px;
				margin-bottom: 16px;
				margin-right: 16px;
				max-width: 100%;
				display: flex;
				flex-direction: column;
				overflow: hidden;
				a {
					text-decoration: none;
				}
				&:hover {
					box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
				}
				.project-group-header {
					border-top-left-radius: 16px;
					border-top-right-radius: 16px;
					padding: 10px 20px;
					font-weight: $medium-high-font-weight;
					display: flex;
					align-items: center;
					&.add-border {
						border: 1px solid $v2-button-very-light-grey;
					}
					&.only-bottom-border {
						border-bottom: 1px solid $v2-button-very-light-grey;
					}
					.icon {
						margin-right: 8px;
						width: 14px;
						height: 14px;
						background-repeat: no-repeat;
						background-position: center;
						background-size: contain;
						background-image: url('images/sections/scheduling-canvas/project-group-icon.svg');
						&.white {
							background-image: url('images/sections/scheduling-canvas/project-group-icon-white.svg');
						}
					}
					.project-group-id {
						font-size: 13px;
					}
					.project-group-name {
						font-size: 16px;
						margin-left: 16px;
						text-overflow: ellipsis;
					}
				}
				.connected-projects-container {
					//cant use display:grid because IE and Edge dont support it
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					padding: 16px 0 0 16px;
					border: 1px solid $v2-button-very-light-grey;
					border-top: none;
					border-bottom-left-radius: 16px;
					border-bottom-right-radius: 16px;
				}
			}
			.extra-margin {
				margin: 85px 35px 25px 15px;
			}
		}
		.project-list-table {
			width: 100%;
			.id-column {
				width: 6.2em;
			}
			&.not-overflowing {
				td {
					max-width: none;
				}
			}
			.status-color-wrapper {
				display: flex;
				justify-content: center;
			}
			.status-color {
				border-radius: 50%;
				&.v1 {
					width: 20px;
					height: 20px;
				}
				&.v2 {
					width: 14px;
					height: 14px;
					&.add-border {
						border: 1px solid $color-white;
					}
				}
			}
			.id-link {
				color: $color-white;
				a {
					color: $color-white;
				}
			}
			.contacts-wrapper {
				flex: 1 1 auto;
				display: flex;
				flex-direction: row;
				overflow: hidden;
				align-items: center;
				flex-wrap: wrap;
				height: 40px;
				justify-content: flex-start;
			}
			.table-header {
				position: sticky;
				top: 0;
				z-index: 9;
				background-color: $color-white;
			}
		}
		.menu {
			flex: 0 0 auto;
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			.categories {
				@include master-tab;
			}

			.forecast-actions {
				@include list-card-switcher;
			}
		}
	}
}
