@import 'upcoming';
@import 'list';
@import 'all_projects';
@import 'time';
@import 'scheduling_redesign';
@import 'labels_cell';

//empty states
@import 'empty_states/done_projects_empty_state';
@import 'empty_states/overview_upcoming_empty_state';

.overview-section {
	background: $color-light-grey;
	&.useNewNavigation {
		background: $color-white;
	}
	.section-body {
		height: calc(100vh - 200px); // Super hacky solution by subtracting size of header and scrollbar. Not sure how this has ever worked. Should look into changing this.

		.project-total {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;

			padding: 15px;
			background: $color-white;
			border-bottom: 1px solid $app-border-color;
			@include data-tile;

			h2 {
				margin: 0;
				padding: 6px 0 0 0;
			}
		}

		&.show-trial-footer {
			height: calc(100vh - 147px); // Super hacky solution by subtracting size of header and size of buy now footer. Not sure how this has ever worked. Should look into changing this.
		}
	}
}
