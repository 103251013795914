.task-recurrence-modal {
	width: 456px;
	.task-recurrence-modal-content {
		margin-top: 24px;
		.drop-down-v2 {
			margin-bottom: 24px;
			width: 100%;
		}
		.tabs-container {
			margin-bottom: 24px;
			display: flex;
			flex-direction: row;
			letter-spacing: 0.5px;
			text-align: center;
			color: #a1a1a1;
			font-size: 11px;
			line-height: 40px;
			font-weight: bold;
			cursor: pointer;
			.tab {
				height: 40px;
				border-radius: 3px;
				background-color: #f3f3f3;
				flex: 1 0 0px;
				&.selected {
					color: white;
					background-color: $v2-branding-purple;
				}
			}
		}
		.days-checkboxes-container {
			display: flex;
			justify-content: space-between;
			padding: 5px 0;

			.day-container {
				text-align: center;
				.day {
					color: $v2-text-light-gray;
					font-size: 11px;
					text-transform: uppercase;
				}
				.check-box {
					margin-top: 5px;
				}
			}
		}
	}
}
