.text-tile {
	.second-line {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-family: $text-font-family;
		font-size: 12px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.25;
		letter-spacing: normal;
		color: #a5a5a5;
	}
	.first-line {
		padding-right: 4px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-family: $text-font-family;
		font-size: 12px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.25;
		letter-spacing: normal;
		color: $v2-text-gray;
	}
}
