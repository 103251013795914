@mixin period-filter {
	.period-filter {
		flex: 0 0 300px;
		display: flex;

		.button {
			flex: 0 0 auto;
			width: 45px;
			cursor: pointer;
			background-position: center;
			background-repeat: no-repeat;
			&:hover {
				color: $color-purple-hover-active;
			}
			&:active {
				background-position: center 12px;
			}
			&.previous {
				background-image: url('images/sections/project/team/previous.svg');
			}
			&.next {
				background-image: url('images/sections/project/team/next.svg');
			}
		}

		.current {
			flex: 1;
			text-align: center;
			cursor: pointer;
			.period-name {
				padding-right: 5px;
				font-weight: $base-font-medium-weight;
			}
			.period-dates {
				padding-left: 5px;
			}
		}
	}
}
