.canvas-projects-scheduling {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: hidden;
	&.simulation-mode {
		padding-bottom: 80px;

		.bar {
			position: fixed;
			width: 8px;
			height: 100%;
			z-index: 5;
			left: 0;
			top: 0;

			&.right {
				left: auto;
				right: 0;
			}
		}

		.footer-person-tooltip-container {
			position: fixed;
			z-index: 150;
			background-color: #2e2c2c;
			color: #efefef;
			border-radius: 4px;
			padding: 6px 12px 6px 12px;
			font-size: 11px;
			font-weight: 500;

			&:after {
				content: '';
				position: absolute;
				left: 50%;
				bottom: -7px;
				width: 0;
				height: 0;
				border-left: 12px solid transparent;
				border-right: 12px solid transparent;
				transform: translateX(-50%);
				border-top: 7px solid #2e2c2c;
			}
		}

		.scheduling-simulation-add-person {
			width: 34px;
			height: 34px;
			cursor: pointer;
		}

		.person-info-box {
			position: absolute;
			z-index: 6;
			background-color: #2e2c2c;
			color: #efefef;
			border-radius: 4px;
			padding: 6px 12px 6px 12px;
			font-size: 11px;
			font-weight: 500;

			&:before {
				content: '';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: -7px;
				width: 0;
				z-index: 110;
				height: 0;
				border-top: 7px solid transparent;
				border-bottom: 7px solid transparent;

				border-right: 7px solid #2e2c2c;
			}
		}
	}

	.simulation-header {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 70px;
		color: #fff;
		z-index: 100;

		.title {
			font-size: 14px;
			font-weight: 500;
			width: 270px;
			align-self: center;
			text-align: center;
		}

		.project-details {
			display: flex;
			align-items: center;
			flex-grow: 1;

			.project-id {
				font-size: 11px;
				margin-right: 16px;
			}

			.project-name {
				font-size: 14px;
			}
		}

		.close-simulation {
			margin-right: 22px;
			cursor: pointer;
			background-image: url('images/components/overlay/close-white.svg');
			border: 0;
			height: 16px;
			width: 16px;
			background-size: 16px;
		}
	}

	.simulation-footer {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		position: absolute;
		bottom: 0;
		background-color: #fff;
		width: 100%;
		height: auto;
		z-index: 100;
		color: #a1a1a1;
		border-top: 8px solid transparent;

		.footer-warnings {
			height: 100%;
			p {
				color: $v2-workflow-task-warning-red;
			}
		}

		.assign-persons-container {
			min-width: 250px;
			margin: 0 0 0 85px;
		}

		.faded-overlay {
			position: relative;
			height: 55px;

			&.left {
				.fade {
					position: absolute;
					left: 0;
					top: 0;
					width: 35px;
					height: 55px;
					background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #ffffff);
					z-index: 150;
				}
			}

			&.right {
				.fade {
					position: absolute;
					right: 0;
					top: 0;
					width: 35px;
					height: 55px;
					background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff);
					z-index: 150;
				}
			}
		}
		.project-persons {
			display: flex;
			flex-grow: 1;
			min-height: 55px;
			position: relative;
			align-items: center;

			.project-person {
				cursor: grab;
				&.drag * {
					pointer-events: none !important;
				}

				&:hover {
					transform: scale(1.1);
				}
			}
		}

		.actions {
			display: flex;
			margin-right: 85px;

			button {
				padding: 9px 18px 9px 20px;
				margin: 0 5px;
			}
		}
	}
	.hover-effects-container {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 10;
		overflow: hidden;
		pointer-events: none;
		* {
			pointer-events: all;
		}
		.context-menu-v2 {
			position: fixed;
		}
		.project-scheduling-actions-menu {
			position: absolute;
			left: 226px;
		}
		.project-scheduling-actions-menu-new-width {
			position: absolute;
			left: 320px;
		}
		.project-scheduling-arrow-clickable-area {
			position: absolute;
			width: 11px;
			height: 28px;
			border-radius: 4px;
			cursor: pointer;
			&.previous {
				left: 152px;
			}
			&.next {
				left: 163px;
			}
			&:hover {
				background: rgba(0, 0, 0, 0.3);
			}
		}
		.project-scheduling-single-action-button {
			position: absolute;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 4px;

			&:hover {
				background-color: #f7f7fe;
			}

			&:active {
				background-color: #f1f1fd;
			}
		}
		.project-scheduling-detail-box {
			position: fixed;
			z-index: 999;
			background-color: white;
			padding: 17px 14px;
			pointer-events: none;
			border: solid 1px $v2-grey;
			border-radius: 4px;

			.detail-box-inner-container {
				background-color: white;
				position: relative;
				left: 0;
				top: 0;
				width: 180px;
				border: none;
				.detail-box-assigned-person-container {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
				}
			}
		}

		.white-triangle {
			position: absolute;
			width: 10px;
			height: 10px;
			overflow: hidden;
			border-left: solid 1px $v2-grey;
			border-bottom: solid 1px $v2-grey;
			background-color: white;
			&.right {
				left: -6px;
				transform: rotate(45deg);
			}
			&.left {
				right: -6px;
				transform: rotate(225deg);
			}
			&.top {
				top: 16px;
			}
			&.bottom {
				bottom: 16px;
			}
		}

		.heatmap-cell-detail-box {
			position: fixed;
			z-index: 999;
			pointer-events: none;
			display: flex;
			align-items: center;
			justify-content: center;

			.detail-box-inner-container {
				position: absolute;
				height: 100%;
				width: auto;
				min-width: 80px;
				max-width: 100%;
				padding: 0 6px;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;

				background-color: #f7f7fe;
				border: solid 1px #e7e7f3;
				border-radius: 4px;
				box-shadow: 0 0 5px 0px rgba(181, 181, 181, 0.25);
				-webkit-box-shadow: 0 0 5px 0px rgba(181, 181, 181, 0.25);
				-moz-box-shadow: 0 0 5px 0px rgba(181, 181, 181, 0.25);
				font-size: 13px;
				font-weight: 400;

				span {
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}
			}

			.light-triangle {
				position: absolute;
				z-index: 1000;
				width: 10px;
				height: 10px;
				overflow: hidden;
				border-left: solid 1px #e7e7f3;
				border-bottom: solid 1px #e7e7f3;
				background-color: #f7f7fe;

				&.center {
					left: calc(50% - 5px);
				}

				&.right {
					left: -5px;
					top: calc(50% - 5px);
					transform: rotate(45deg);
				}

				&.left {
					right: -5px;
					top: calc(50% - 5px);
					transform: rotate(225deg);
				}

				&.top {
					top: -5px;
					transform: rotate(135deg);
				}

				&.bottom {
					bottom: -5px;
					transform: rotate(-45deg);
				}
			}
		}

		.placeholder-information-tooltip {
			position: fixed;
			z-index: 999;
			pointer-events: none;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: white;
			border-radius: 8px;
			padding: 16px;
			max-width: 270px;
			box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.1);
			-webkit-box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.1);
			-moz-box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.1);

			.placeholder-information-content {
				height: 100%;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		.distribution-box-container {
			position: fixed;
			z-index: 999;
			background-color: white;
			padding: 17px 14px;
			pointer-events: none;
			border: solid 1px $v2-grey;
			border-radius: 4px;

			.inner-container {
				position: relative;
				min-width: 180px;
				.inner-headline {
					font-weight: 600;
					font-size: 12px;
					margin-bottom: 7px;
				}
				.progress-percentage {
					font-weight: 600;
				}
				.capacity-container {
					font-weight: 600;
				}
				.inner-container-item {
					&:not(:last-child) {
						margin-bottom: 10px;
					}
					.label {
						font-size: 8px;
						height: 12px;
						text-transform: uppercase;
						font-weight: 500;
						letter-spacing: 0.5px;
						color: #b4b9c0;
					}

					.content {
						font-size: 11px;
						display: flex;
						letter-spacing: 0.5px;
						&.capacity-content {
							flex-direction: row;
							.capacity-content-container {
								display: flex;
								flex-direction: column;
								&.rectangle-container {
									width: 12px;
									margin-right: 5px;
									.rectangle {
										&.positive {
											background-color: #b2efd1;
										}
										&.negative {
											background-color: #ffcaca;
										}
									}
								}
								&.capacity-container {
									text-align: right;
									margin-right: 9px;
								}
								div {
									height: 12px;
									&:not(:last-child) {
										margin-bottom: 3px;
									}
								}
							}
						}
					}
				}
				/*
				.top-line {
					display: flex;
					flex-direction: row;
					font-size: 12px;
					margin-bottom: 16px;
					&.empty {
						margin-bottom: 2px;
					}
					.name {
						font-weight: bold;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						margin-right: 4px;
					}
					.remaining {
						flex-grow: 1;
						flex-shrink: 0;
						margin-left: 4px;
					}
				}
				.role-container {
					.role-row {
						display: flex;
						flex-direction: row;
						margin-bottom: 2px;
						height: 14px;
						.percentage-container {
							width: 60px;
							margin-right: 8px;
							flex-shrink: 0;
							text-align: right;
							font-size: 12px;
							line-height: 14px;
							font-weight: bold;
							color: white;
							padding: 0 4px;
							&.grey {
								background-color: #d1d3d8;
							}
							&.orange {
								background-color: #faa434;
							}
							&.red {
								background-color: #e21e5b;
							}
							&.green {
								background-color: #00cc66;
							}
						}
						.name-container {
							font-size: 12px;
							line-height: 14px;
							font-weight: 500;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
					}
				}*/
			}
		}
	}
	.project-scheduling-top-left-restyled {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		height: 100%;
		background-color: white;
		font-weight: bold;
		color: $v2-text-light-gray;
		border-right: 1px solid $color-martin-timeline-border;

		.new-project-button-v2 {
			height: 40px;
			width: 95%;
		}

		.box-container {
			cursor: pointer;
			display: flex;
			flex-direction: row;
			.left-box {
				height: 44px;
				width: 44px;
				border: solid 2px #05e298;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				.vertical-line {
					height: 12px;
					width: 2px;
					background-color: #05e298;
					position: relative;
					.horizontal-line {
						position: absolute;
						top: 5px;
						height: 2px;
						width: 12px;
						left: -5px;
						background-color: #05e298;
					}
				}
			}
			.right-box {
				width: 212px;
				border: solid 2px #05e298;
				border-left: none;
				height: 44px;
				display: flex;
				flex-direction: row;
				align-items: center;
				padding-left: 14px;
				.text {
					font-size: 12px;
					font-weight: bold;
					color: #05e298;
				}
			}
		}
	}

	.project-scheduling-top-left {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		height: 100%;
		background-color: white;
		font-weight: bold;
		color: $v2-text-light-gray;
		border-right: 1px solid $color-martin-timeline-border;
		border-bottom: 1px solid $color-martin-timeline-border;

		.new-project-button-v2 {
			height: 40px;
			width: 95%;
		}

		.box-container {
			cursor: pointer;
			display: flex;
			flex-direction: row;
			.left-box {
				height: 44px;
				width: 44px;
				border: solid 2px #05e298;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				.vertical-line {
					height: 12px;
					width: 2px;
					background-color: #05e298;
					position: relative;
					.horizontal-line {
						position: absolute;
						top: 5px;
						height: 2px;
						width: 12px;
						left: -5px;
						background-color: #05e298;
					}
				}
			}
			.right-box {
				width: 212px;
				border: solid 2px #05e298;
				border-left: none;
				height: 44px;
				display: flex;
				flex-direction: row;
				align-items: center;
				padding-left: 14px;
				.text {
					font-size: 12px;
					font-weight: bold;
					color: #05e298;
				}
			}
		}
	}
}

#dragged-person-clone {
	position: fixed;
	display: none;
	width: 30px;
	height: 34px;
	z-index: 9999;
	// transition: transform 0.1s linear;
	will-change: transform;

	&.active {
		display: block;
	}
}

.userpilot-target {
	position: absolute;
	pointer-events: none;
	background: none;
	&.first-project {
		top: 186px;
		left: 2px;
		height: 41px;
		width: 267px;
	}
	&.first-project-progress {
		top: 186px;
		left: 268px;
		height: 41px;
		width: calc(100% - 268px);
	}
	&.first-project-expand-icon {
		top: 200px;
		left: 238px;
		height: 21px;
		width: 22px;
	}
	&.first-project-phases {
		top: 233px;
		left: 11px;
		height: 37px;
		width: 258px;
	}
	&.first-project-phases-expand {
		top: 242px;
		left: 239px;
		height: 20px;
		width: 20px;
	}
	&.first-project-first-phase {
		top: 270px;
		left: 11px;
		height: 37px;
		width: 258px;
	}
	&.first-project-first-phase-expand {
		top: 278px;
		left: 239px;
		height: 20px;
		width: 20px;
	}
	&.first-task {
		top: 306px;
		left: 10px;
		height: 29px;
		width: calc(100% - 10px);
	}
	&.first-project-team {
		top: 270px;
		left: 11px;
		height: 40px;
		width: 258px;
	}
	&.first-project-team-expand {
		top: 280px;
		left: 239px;
		height: 20px;
		width: 20px;
	}
	&.project-team-heatmap {
		top: 308px;
		left: 0;
		height: calc(100vh - 308px);
		width: 100%;
	}
	&.all-projects-timeline {
		top: 187px;
		left: 0;
		height: calc(100vh - 187px);
		width: 100%;
	}
}
