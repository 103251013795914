.canvas-scheduling {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	user-select: none;
	overflow: hidden;
	&.shared {
		height: 100vh;
		padding-top: 96px;
		.shared-timeline-title-wrapper {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			background-color: white;
			.shared-timeline-title {
				color: $v2-text-gray;
				font-size: 20px;
				font-weight: 500;
				margin: 0;
				padding: 36px 42px;
			}
		}
	}
	.back-button {
		cursor: pointer;
		@include back-button;
	}
	.scheduling-browser-message-background {
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 900;
		background: rgba(0, 0, 0, 0.91);
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-align-items: center;
		align-items: center;
		.scheduling-browser-message {
			position: absolute;
			border-radius: 7px;
			left: 40%;
			top: 40%;
			z-index: 99;
			width: 380px;
			height: 310px;
			background: white;
			box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
			padding: 20px;
			border: 1px solid $v2-branding-purple;
			.close-button {
				width: 18px;
				height: 18px;
				background-size: 18px;
				background-image: url('images/components/close-modal.svg');
				background-repeat: no-repeat;
				border: none;
				margin-bottom: 20px;
			}
			.browser-message-text {
				font-size: 18px;
				padding-bottom: 20px;
			}
		}
	}
	.is-loading {
		position: fixed;
		top: 50%;
		left: 50%;
		width: 80px;
		height: 80px;
		margin-top: -40px;
		margin-left: -40px;
		background-size: 80px 80px;
		background-image: url('images/loaders/loader-animation.svg');
		background-repeat: no-repeat;
		background-position: center;
	}
	.is-saving {
		.backdrop {
			position: fixed;
			background-color: white;
			opacity: 0.6;
			z-index: 200;
		}
		.description {
			position: fixed;
			top: 50%;
			left: 50%;
			font-size: 11px;
			background-color: $v2-tooltip-grey;
			padding: 24px;
			color: $v2-text-gray;
			font-weight: 600;
			border-radius: 4px;
			box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
			border: solid 1px $v2-grey;
			z-index: 201;
		}
	}
	.heading {
		text-align: center;
		font-size: 1.8rem;
		padding: 20px 0;
		letter-spacing: 1px;
	}
	.control-bar {
		//height: 46px;
		//padding: 10px 4px 8px 4px;padding-right: 16px;
		background-color: white;
		border-bottom: 1px solid $color-martin-timeline-border;
		align-items: center;
		z-index: 5;
		.header-bar {
			padding-right: 90px;
		}
		.canvas-timeline-expand-all-button-container {
			height: 30px;
			button {
				height: 30px;
				width: 30px;
				border: 1px solid $v2-button-very-light-grey;
				&:active,
				&:focus,
				&:hover {
					border: 1px solid $v2-button-very-light-grey-hovered;
				}
			}
		}
		.tabs {
			display: flex;
			flex-direction: row;
			&.has-tabs {
				padding: 10px 8px 8px 8px;
			}
			&.empty-space {
				min-width: 195px;
			}
			.tab {
				width: 92px;
				height: 40px;
				border: none;
				line-height: 30px;
				text-align: center;
				flex: 1 0 0%;
				font-size: 10px;
				font-weight: bold;
				letter-spacing: 0.5px;
				background-color: #f5f5f5;
				outline: 0;
				&.active {
					color: white;
					background-color: $v2-branding-purple;
				}
				&:not(:first-child) {
					border-left: 2px solid white;
				}
			}
			.canvas-timeline-expand-all-button-container {
				margin-left: auto;
			}
		}
		.date-section {
			display: flex;
			flex-direction: row;
			align-items: center;
			height: 46px;
			margin-left: 35px;
			.scroll-button {
				height: 30px;
				width: 26px;
				background-size: 8px;
				background-position: center;
				background-repeat: no-repeat;
				outline: 0;
				border: 1px solid $color-martin-timeline-border;
				&.scroll-left {
					background-image: url('images/components/scheduling/scroll-left.svg');
				}
				&.scroll-right {
					background-image: url('images/components/scheduling/scroll-right.svg');
				}
				&:hover {
					border: 1px solid $v2-date-picker-button-border-color;
				}
			}
			.today-button {
				border: 1px solid $color-martin-timeline-border;
				font-size: 9px;
				font-weight: bold;
				letter-spacing: 0.5px;
				padding: 0 12px;
				height: 30px;
				line-height: 30px;
				margin: 0;
				outline: 0;
				&:hover {
					border: 1px solid $v2-date-picker-button-border-color;
				}
			}
		}
		#zoom-menu-wrapper {
			margin-left: 8px;
			//margin-right: 8px;
		}
		.dependency-mode-button {
			width: 30px;
			height: 30px;
			border: 1px solid $color-martin-timeline-border;
			background-size: 20px;
			background-position: center;
			background-repeat: no-repeat;
			outline: 0;
			&:hover,
			&:focus,
			&:active {
				border: 1px solid $v2-button-very-light-grey-hovered;
			}
			&.dependency-mode-whole-chain {
				background-color: $v2-branding-purple;
				background-image: url('images/sections/scheduling-canvas/dependency-drag-mode-white.svg');
			}
			&.dependency-mode-single-task {
				background-image: url('images/sections/scheduling-canvas/dependency-drag-mode-grey.svg');
			}
		}
		.help-center-icon {
			width: 30px;
			height: 30px;
			background-image: url('images/components/scheduling/help-center-icon.svg');
			background-size: 22px;
			background-position: center;
			background-repeat: no-repeat;
		}
		.filter-section-v2 {
			margin-left: 8px;
		}
		.right-side-container {
			margin-left: auto;
			margin-right: 90px;
			display: flex;
			flex-direction: row;
			align-items: center;
			.board-card-details-selector {
				margin-left: 8px;
			}
			.search-container {
				height: 30px;
				min-width: 30px;
				border: 1px solid $color-martin-timeline-border;
				background-image: url('images/components/scheduling/search-icon.svg');
				background-size: 16px;
				background-position: right 7px center;
				background-repeat: no-repeat;
				cursor: pointer;
				outline: 0;
				padding: 0 30px 0 0;
				::-webkit-input-placeholder {
					/* Chrome/Opera/Safari */
					font-size: 12px;
				}
				::-moz-placeholder {
					/* Firefox 19+ */
					font-size: 12px;
				}
				:-ms-input-placeholder {
					/* IE 10+ */
					font-size: 12px;
				}
				:-moz-placeholder {
					/* Firefox 18- */
					font-size: 12px;
				}
				&:hover {
					border: 1px solid $v2-date-picker-button-border-color;
				}
				&.expanded {
					box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
					&:hover {
						border: 1px solid $color-martin-timeline-border;
					}
				}
				input {
					border: none;
					outline: 0;
					padding: 8px 8px 8px 8px;
					font-size: 12px;
				}
			}
		}
		.time-off-button {
			margin: 0 6px 0 auto;
			padding: 0 16px;
			height: 38px;
			font-size: 10px;
			font-weight: bold;
			letter-spacing: 1px;
			border-radius: 4px;
			border: 2px solid $color-martin-tertiary-green;
			background-color: white;
			color: $color-martin-tertiary-green;
		}
		.booking-button {
			margin: 0 6px 0 auto;
			//margin-right: 6px;
			padding: 0 16px;
			height: 38px;
			font-size: 10px;
			font-weight: bold;
			letter-spacing: 1px;
			border-radius: 4px;
			border: none;
			background-color: $color-martin-tertiary-green;
			color: white;
		}
	}
}

.canvas-scheduling-action-menu {
	height: 30px;
	position: relative;
	button.actions {
		height: 30px;
		width: 14px;
		background: url('images/components/overlay/navigation-show-more-vertical.svg') center center no-repeat;
		border-radius: 4px;
		border: none;
		outline: 0;
		&.white {
			background-image: url('images/components/overlay/navigation-show-more-vertical-white.svg');
		}

		&:hover,
		&:focus,
		&.expanded {
			background-color: rgba(0, 0, 0, 0.3);
		}
	}
	ul.actions-dropdown {
		position: absolute;
		left: 14px;
		z-index: 999;
		border-radius: 4px;
		background-color: $color-white;
		color: $color-white;
		margin: 0;
		padding: 0;
		list-style: none;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);

		&.expand-up {
			top: unset;
			bottom: 0;
		}

		&.expand-down {
			top: 0;
			bottom: unset;
		}

		li {
			cursor: pointer;
			font-size: 12px;
			font-weight: 500;
			padding: 6px 10px 6px 10px;
			white-space: nowrap;
			outline: none;
			color: $v2-text-gray;

			&:hover, &:focus {
				background-color: $v2-list-hover-purple;
			}
		}

		li:first-child {
			border-top-right-radius: 4px;
		}

		li:last-child {
			border-bottom-right-radius: 4px;
		}

		.action-menu-disabled {
			cursor: default;
			color: $disabled-grey;

			&:hover, &:focus {
				background-color: $color-white;
			}
		}
	}
}

.loading-data-overlay {
	position: absolute;
	left: 0;
	width: 100%;
	background: #ffffffcc;
	backdrop-filter: blur(5px);
	z-index: 9;
	display: grid;
	align-content: space-evenly;
	justify-items: center;
}
