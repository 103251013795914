.export-quickbooks-modal {
	.header-text {
		overflow: visible !important;
		margin-left: auto !important;
		margin-right: auto !important;
		white-space: normal !important;
	}
	.invoicing-payments-note {
		padding-top: 10px;
		width: 240px;
		color: $v2-workflow-task-warning-red;
		overflow: visible !important;
		margin-left: auto !important;
		margin-right: auto !important;
		white-space: normal !important;
	}
	.switch-container-export-option {
		padding-top: 8px;
		padding-bottom: 8px;

		label {
			color: #858585;
			font-size: 0.75rem;
		}

		input {
			width: 22px;
		}
	}
}
