@keyframes flyOver {
	0% {
		display: block;
		transform: translateX(0) translateY(0);
	}
	25% {
		transform: translateX(25vw) translateY(-25vh);
	}
	50% {
		transform: translateX(40vw) translateY(-40vh);
	}
	75% {
		transform: translateX(85vw) translateY(-85vh);
	}
	100% {
		transform: translateX(100vw) translateY(-100vh);
	}
}
@keyframes bounceInLeft {
	from,
	60%,
	75%,
	90%,
	to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	0% {
		opacity: 0;
		-webkit-transform: translate3d(-3000px, 0, 0);
		transform: translate3d(-3000px, 0, 0);
	}

	60% {
		opacity: 1;
		-webkit-transform: translate3d(25px, 0, 0);
		transform: translate3d(25px, 0, 0);
	}

	75% {
		-webkit-transform: translate3d(-10px, 0, 0);
		transform: translate3d(-10px, 0, 0);
	}

	90% {
		-webkit-transform: translate3d(5px, 0, 0);
		transform: translate3d(5px, 0, 0);
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}
@keyframes bounceOutRight {
	20% {
		opacity: 1;
		-webkit-transform: translate3d(-20px, 0, 0);
		transform: translate3d(-20px, 0, 0);
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(2000px, 0, 0);
		transform: translate3d(2000px, 0, 0);
	}
}
.project-sprints-v2 {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	position: relative;
	flex: 1 1 0px;
	background-color: white;
	width: 100%;
	.table-person-grouping {
		.chip {
			display: none;
		}
	}
	.backlog-wrapper {
		display: flex;
		flex-direction: column;
	}
	.sprints-info-container {
		width: 100%;
		.data-tiles {
			min-height: 40px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			.sprints-header-elem {
				position: relative;
				padding-top: 6px;
				padding-bottom: 2px;
				margin-right: 10px;
				display: flex;
				flex-direction: column;
				margin-left: auto;
				.alignment-box {
					&.flexed {
						display: flex;
					}
					.skill-label-container {
						margin-right: 4px;
					}
					.label {
						font-size: 9px;
						text-transform: uppercase;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						text-align: right;
						font-weight: 500;
					}
					.value {
						font-weight: normal;
						text-align: right;
					}
					&:last-child {
						margin-right: 0;
						max-width: 130px;
					}
					.information-icon {
						cursor: help;
					}
				}
				.sprint-header-controls {
					display: flex;
					flex-direction: row;
					height: 100%;
					min-width: 70px;
					max-width: 70px;
				}
			}
		}
	}
	.sprints-info-container {
		font-size: 13px;
		font-weight: 14px;
		font-weight: 700;
		color: white;
	}
	.header-wrapper {
		position: relative;
		.chip {
			display: none !important;
		}
	}

	.phases-header-v2 {
		position: relative;
		&.sprints {
			display: flex;
			flex-direction: row;
			.sprint-date,
			.date-picker {
				height: 40px;
				//min-width: calc(25% - 13px) !important;
			}
			.sprints-header-elem {
				min-width: 90px;
				max-width: 190px;
			}
			.unfinished-work-warning-icon {
				position: absolute;
				left: 8px;
				top: 10.5px;
				width: 16px;
				height: 16px;
				background: url('images/sections/scheduling-canvas/warning-icon-red.svg');
				background-size: 16px;
				background-position: center;
				background-repeat: no-repeat;
			}
			.fill-rectangle {
				position: absolute;
				left: 11.5px;
				top: 14px;
				width: 9px;
				height: 9px;
				background-color: white;
				transform: rotate(45deg);
			}
		}
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
	}
	.sprint-extra-section {
		display: flex;
		justify-content: flex-end;
		margin: 16px 16px 0 0;
		.custom-button-container {
			height: 30px;
		}
	}
	.date-picker-button-container-v3 {
		&.double {
			width: 100%;
		}
	}
	.generic-empty-state-v2 {
		margin: 0 66px 16px 66px;
	}
	.csv-button {
		&:hover {
			border: 1px solid $v2-date-picker-button-border-color;
		}
	}
	.assigned-dropdown-wrapper {
		.assigned-icons {
			top: 7px;
		}
	}
	.search-V2 {
		background-position: right 8px center;
	}
	.board-card-details-selector-list,
	.bulk-edit-options-list {
		z-index: 101 !important;
	}
	.section-project-group-link {
		z-index: 0;
	}
	.project-phases-header {
		.new-phase-button {
			@include primary-button;
			margin-left: 0;
			width: auto;
		}
		.filters {
			margin-left: auto;
			.filter {
				background-position: 20px center;
				background-repeat: no-repeat;
				cursor: pointer;
				font-weight: $base-font-medium-weight;

				color: $color-light-black;

				&:hover {
					color: $color-purple-hover-active;
				}

				&.load-breakdown {
					background-image: url('images/sections/project/phases/load.svg');
					border-left: 1px solid $app-border-color;
					border-right: 1px solid $app-border-color;
				}

				&.save-breakdown {
					background-image: url('images/sections/project/phases/save.svg');
					border-right: 1px solid $app-border-color;
				}

				&.add-phase {
					//margin-left: auto;
					padding: 13px 20px 0px 55px;
					border-left: 1px solid $app-border-color;
					background-image: url('images/sections/project/phases/phase.svg');
				}
			}
		}
	}
	// non-fixed width table elements
	.growable {
		flex-grow: 1;
	}

	.sprint-wrapper {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		margin-bottom: 15px;
	}
	.filter-section-inner {
		z-index: 101;
	}
	.project-total-container {
		position: sticky;
		padding: 0;
		margin: 0 65px 0 66px;
		top: 0;
		background-color: #ffffff;
		z-index: 9;
		cursor: pointer;
		padding: 0 8px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.05);
		&.side-space {
			margin-left: 12px;
		}
		&.hide-shadow {
			box-shadow: none;
		}
		&.expanded {
			height: 50px;
			min-height: 50px;
			max-height: 50px;
			transition: all 0.2s;
		}
		&.collapsed {
			min-height: 35px;
			height: 35px;
			max-height: 35px;
			.items,
			.elem-wrapper {
				height: 35px;
				max-height: 35px;
			}
		}
		&:hover {
			.icon-chevron::after {
				opacity: 1;
			}
		}
		.items {
			height: 100%;
			display: flex;
			flex-direction: row;
			background-color: #ffffff;
			position: relative;
			justify-content: space-between;
			margin: 0 18px;

			.elem-wrapper {
				background: #ffffff;
				position: relative;
				height: 100%;
				div:first-child {
					flex-grow: 1;
				}
			}
			.project-total-header-item {
				margin-top: 10px;
				.multi-line-header {
					display: flex;
					flex-direction: column;
					position: relative;
					.line-1 {
						position: absolute;
						top: -10px;
						right: 0;
					}
				}
				.text {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					font-size: 9px;
					color: $v2-text-gray;
					letter-spacing: 1px;
					text-transform: uppercase;
					font-weight: 500;
					margin-bottom: 4px;
					text-align: right;
				}
				.value {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					font-size: 14px;
					text-align: right;
					color: $v2-text-gray;
				}
			}
		}

		.toggle {
			position: absolute;
			align-self: center;
			left: 118px;
			top: 5px;
			width: 20px;
			height: 20px;
		}
	}

	.icon-chevron {
		font-size: 10px;
		letter-spacing: 0.5px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		cursor: pointer;
		&::after {
			content: '';
			width: 20px;
			height: 20px;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 14px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			display: inline-block;
		}

		&.expanded::after {
			opacity: 0;
			background-image: url('images/v2/components/dropdown/arrow-down.svg');
		}
		&.not-expanded::after {
			opacity: 0;
			top: -2px;
			background-image: url('images/v2/components/dropdown/arrow-up.svg');
		}
	}
	.section-body {
		display: flex;
		flex: 1 1 auto;
		flex-direction: column;
		@include scroll-y;
		&.flex-row {
			flex-direction: row;
		}
		.phase,
		.new-phase {
			@include content-frame;
			border: 0;
			box-shadow: 0 0px;
			//padding: 10px;
			overflow: visible;
			&.hide {
				display: none;
			}
			&.has-phase {
				background-color: white;
				.body {
					background-color: #f3f3f3;
				}
			}
			&.side-space {
				.body {
					margin-left: 12px;
					margin-right: 0;
				}
			}
			.body {
				flex-grow: 1;
				overflow: visible;
				background-color: white;
				margin: 0 32px;
				&.side-backlog {
					margin-left: 10px;
				}
				@include standard-table;
				.input-table-cell {
					padding-left: 4px;
				}
				.select-table-cell {
					.option {
						&:hover {
							color: $color-purple-hover-active;
						}
					}
					.option-selected {
						color: $brand-color-accent;
					}
				}
			}
		}

		.new-phase {
			display: flex;
			flex-flow: row wrap;
			border: 0;
			.controls-container {
				display: flex;
				flex-flow: row wrap;
				flex-direction: row;
			}
			.title {
				margin: 0;
				display: flex;
				justify-content: center;
				flex-direction: column;
			}
			.inputs {
				display: flex;
				flex-flow: row wrap;
				.name {
					input {
						@include generic-text-input;
						margin-left: 15px;
					}
				}
				.deadline {
					width: 190px;
					margin: 0 10px;

					background-color: white;
					padding: 0;
					.date-input,
					.date-input-focused {
						background: none;
						padding: 9px 10px;
						opacity: 0.7;
					}
					.date-input,
					.date-input-focused,
					.date-input-locked {
						border: none;
						@include generic-text-input;
						padding: 9px 10px;
					}
					.date-input-locked {
						background-color: #ebebe4;
					}
					.calendar-wrapper {
						top: 45px;
					}
				}
				.add-phase {
					@include primary-button;
				}
			}
		}

		.phase-bottom-section {
			margin-bottom: 5px;
			padding: 0 26px;
			&.grouped {
				padding: 0 16px;
			}
			.total {
				width: 38.5%;
				padding: 15px 0 0 0;
				margin: 0;
			}
			.data-tiles {
				width: 49%;
				padding: 5px 0 5px 0;
				display: flex;
				text-align: right;
				.data-tile {
					width: 25%;
					padding-right: 8px;
				}
			}
			.approved {
				width: 12.5%;
				text-align: center;
				padding: 5px;
				input {
					margin-left: 20px;
					height: 18px;
					width: 18px;
				}
			}
		}

		.table-container {
			padding: 0 16px;
			table {
				.text-input {
					padding: 6px 5px;
					width: 100%;
				}
				.text-input-group {
					padding: 6px 5px;
					width: 90%;
				}
				.time-group {
					padding-right: 4px;
				}
				.hours-group {
					padding-right: 6px;
				}
				.amount-group {
					padding-left: 5px;
					float: right;
				}
				.align-right {
					text-align: right;
				}

				.group-header {
					margin: 5px;
					cursor: pointer;
					.group-name {
						.expand-arrow {
							padding-left: 21px;
							background-size: 16px;
							background-repeat: no-repeat;
							background-position: left center;
						}
					}
					.select-placeholder {
						height: 36px;
						visibility: hidden;
					}
				}

				.bulk-update-checkbox {
					width: 30px;
					padding-left: 10px !important;
					.md-checkbox {
						width: 13px;
					}
				}
				.drag-handle {
					width: 30px;
					background: url('images/cross-section-icons/table/drag-handle.svg') no-repeat 10px center;
					cursor: move;
				}

				.drag-handle-light {
					min-width: 30px;
					background: url('images/cross-section-icons/table/drag-handle-light.svg') no-repeat 10px center;
					cursor: move;
				}
				.hours,
				.remaining {
					text-align: right;
				}
				.approved {
					text-align: center;
				}
				.assigned {
					.forecast-person {
						display: inline-block;
						margin: 5px 5px 5px 0;
						.image-container {
							display: inline-block;
						}
					}
				}
				.skills {
					.skill-label {
						margin-right: 4px;
						border-radius: 5px;
						height: 12px;
						width: 24px;
						display: inline-block;
					}
					span {
						display: inline-block;
					}
				}
				.input-row {
					background-color: #f3f3f3;
					.task-name-input {
						border: 1px solid #e6e6e6;
						background-color: #fff;
					}

					.forecast.right {
						text-align: right;
					}
					.approved {
						text-align: center;
						padding-right: 0;
					}
					.input-add-button {
						@include primary-button;
						margin: 0 5px;
					}
					.wrong {
						input {
							border-radius: 3px;
							border: 1px solid red;
						}
					}
				}
			}
		}
	}
	.no-margin {
		margin-bottom: 0;
		margin-top: 0;
	}
}
