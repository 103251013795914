.client-page {
	padding-bottom: 75px;
	.top-section {
		@include gear-menu;
		display: flex;
		flex: 0 0 55px;
		flex-direction: row;
		justify-content: space-between;
		padding-top: 15px;
		h2 {
			margin-top: 0;
		}
		.buttons-container {
			.edit-button {
				@include primary-button;
			}
		}
	}
	.client-info {
		.container {
			flex: 1 1 auto;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			.sub-section {
				display: flex;
				flex: 1 1 auto;
				flex-direction: column;
				width: 40%;
			}
			.spacer {
				width: 1px;
				height: 350px;
				margin: 0 50px;
				background-color: $app-border-color;
			}
		}
		.details-container {
			display: flex;
			flex-direction: row;
		}
		.client {
			display: flex;
			flex-direction: row;
			position: relative;
			.upload-overlay,
			.upload-overlay-hidden {
				height: 140px;
				width: 140px;
				background: rgba(0, 0, 0, 0.75);
				border-radius: 50%;
				position: absolute;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.upload-overlay-hidden {
				display: none;
			}
			.uploading-gif,
			.uploading-gif-hidden {
				height: 48px;
				width: 48px;
				background-image: url($app-loader-url);
				background-repeat: no-repeat;
				background-size: 48px 48px;
			}
			.titles-material {
				margin-right: 40px;
			}
			img {
				border-radius: 50%;
			}
			.controls {
				padding-top: 10px;
				.controls-update {
					cursor: pointer;
					height: 21px;
					::-webkit-file-upload-button {
						cursor: pointer;
					}
					&:hover {
						color: $color-purple-hover-active;
					}
					input {
						width: 140px;
						margin-top: -5px;
						cursor: pointer;
						opacity: 0;
						position: absolute;
					}
				}
				.control {
					font-size: 14px;
					background-size: 16px;
					background-repeat: no-repeat;
					background-position: left center;
					padding: 5px 10px 0 21px;
					font-weight: $base-font-medium-weight;

					cursor: pointer;
					visibility: hidden;
					&:hover {
						cursor: pointer;
						text-decoration: underline;
					}
					&.update {
						background-image: url('images/sections/settings/settings-overview/update-photo.svg');
					}
					&.remove {
						background-image: url('images/sections/settings/settings-overview/remove.svg');
					}
				}
			}
			&:hover .control {
				visibility: visible;
			}
		}

		.titles {
			display: flex;
			flex: 1 1 auto;
			flex-direction: column;
			.notes-wrapper {
				width: 85%;
				.notes-label {
					font-weight: 700;
				}
				.editor {
					margin-top: 10px;
					ul,
					ol {
						margin-top: 0;
					}
				}
			}
		}
	}
	.client-contacts {
		.client-user-permission-description {
			display: flex;
			flex-direction: column;
			margin-bottom: 25px;
		}
		@include standard-table;
		.th-center {
			text-align: center;
		}
		tbody {
			tr {
				.controls-new {
					text-align: right;
					span {
						background-size: 16px;
						background-repeat: no-repeat;
						background-position: left center;
						padding: 0 10px 0 21px;
						cursor: pointer;
						&:hover {
							color: $color-purple-hover-active;
						}
						&.add {
							background-image: url('images/sections/project/client/add.svg');
						}
						&.remove {
							background-image: url('images/sections/project/client/remove.svg');
						}
					}
				}
				&.client-row {
					input[type='text'] {
						width: 100%;
					}

					&:hover {
						.select-table-cell {
							//remove hover effect inherited from table row
							color: $color-black;
						}
					}
					&.disabled:hover {
						color: black;
						background-color: none;
						input {
							border-color: transparent;
						}
					}
				}
				.controls {
					text-align: right;
					width: 160px;
					> span {
						background-size: 16px;
						background-repeat: no-repeat;
						background-position: left center;
						padding: 0 10px 0 21px;
						font-weight: 300;
						visibility: hidden;

						&.duplicate {
							cursor: copy;
							background-image: url('images/sections/project/phases/duplicate.svg');
							&:hover {
								text-decoration: underline;
							}
						}

						&.remove {
							cursor: pointer;
							background-image: url('images/sections/project/phases/remove.svg');
							&:hover {
								color: $color-purple-hover-active;
							}
						}

						&.prompt {
							cursor: default;
							padding: 0 5px 0 0;
							span {
								padding: 0 5px 0 0;
								&.confirm {
									cursor: pointer;
									&:hover {
										color: $color-purple-hover-active;
									}
								}
							}
						}
					}
				}
			}
		}
		.div-center {
			display: flex;
			justify-content: center;
			input {
				width: 16px;
			}
		}
	}
	.client-integrations {
		.integration-customer-dropdown {
			width: 300px;
			margin-top: 12px;
		}
	}
	.remove-section {
		button {
			@include primary-button;
			display: flex;
		}
	}
}
