.upcoming-empty-state-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 42px 0 0 0;
	text-align: center;
	.info-container {
		display: flex;
		flex: 1;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		.info {
			font-family: $heading-font-family;
			font-size: 32px;
			margin: 20px;
		}
		.add-task-button {
			@include primary-button;
		}
	}
	img {
		margin-top: 32px;
		height: 250px;
		width: 200px;
	}
}
