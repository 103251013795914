.table-row-v3-margin-wrapper {
	width: 100%;
	.table-row-v3 {
		padding: 0 10px;
		.drag-handle {
			left: 14px;
		}
		.dot-options {
			right: 20px;
		}
		.star-option {
			right: 40px;
		}
	}
}
.table-row-v3 {
	display: flex;
	flex-direction: row;
	position: relative;
	user-select: none;
	height: 100%;
	// Use basis of fullwidth minus left menu + padding
	//width: calc(100vw - 218px);
	// max-width: 100%;
	//z-index: 1;
	.drag-handle {
		position: absolute;
		opacity: 0;
		width: 14px;
		min-width: 14px;
		left: 2px;
		top: 13px;
		height: 14px;
		background: url('images/cross-section-icons/table/drag-handle.svg');
		background-size: 14px;
		background-position: center;
		background-repeat: no-repeat;
		z-index: 1;
	}
	.star-option {
		width: 16px;
		height: 30px;
		position: absolute;
		right: 30px;
		top: 11px;
	}
	.dot-options {
		cursor: pointer;
		opacity: 0;
		width: 16px;
		height: 30px;
		position: absolute;
		right: 10px;
		top: 6px;
		background: url('images/components/overlay/navigation-show-more-vertical.svg');
		background-size: 25px;
		background-position: center;
		background-repeat: no-repeat;
		border-radius: 4px;
		&:hover,
		&:focus,
		&.clicked {
			opacity: 1;
			background-color: rgba(0, 0, 0, 0.2);
		}
	}
	.time-registration-icon {
		&:not(.active) {
			opacity: 0;
		}
		cursor: pointer;
	}
	.elem-wrapper {
		font-size: 12px;
		color: $v2-text-gray;
		box-sizing: border-box;
		position: relative;
		height: 100%;
		width: 100%;
		border-top: solid 1px $v2-table-border-color;
		border-bottom: solid 1px $v2-table-border-color;
		display: flex;
		align-items: center;
		white-space: nowrap;
		overflow: hidden;
		//transition: background-color 0.2s ease-out;
		background-color: white;
		&:nth-child(2) {
			border-left: 1px solid $v2-button-very-light-grey;
		}
		// Chip div controls its own borders
		&.last {
			border-top: none;
			border-bottom: none;
		}
		.tooltip-container {
			width: 100%;
			display: inherit;
		}
		.hours-input {
			//for the new hours in
			padding-left: 8px;
			/* input {
				border-color: transparent;
				background-color: transparent;
				&:hover {
					border-color: $v2-button-very-light-grey-hovered;
				}
				&:disabled,
				&.locked {
					color: $v2-text-light-gray;
					background-color: $v2-input-disabled-grey;
					border-color: $app-border-color;
				}
				&.error {
					border-color: $negative-value;
				}
				&:focus {
					border-color: $v2-branding-purple;
					outline: none;
				}
			} */
		}
		.hours-input-wrapper {
			display: flex;
			padding-left: 8px;
		}
		.input-hours.right input,
		.input-hours-DAN.right input,
		.input-points.right input,
		.input-EUR.right input,
		.input-AUD.right input,
		.input-CAD.right input,
		.input-SGD.right input,
		.input-HKD.right input,
		.input-MXN.right input,
		.input-NZD.right input,
		.input-USD.right input,
		.input-CNY.right input,
		.input-DKK.right input,
		.input-NOK.right input,
		.input-SEK.right input,
		.input-INR.right input,
		.input-JPY.right input,
		.input-TTD.right input,
		.input-GBP.right input,
		.input-RUB.right input,
		.input-ZAR.right input,
		.input-KRW.right input,
		.input-TRY.right input,
		.input-PLN.right input,
		.input-THB.right input {
			padding-right: 10px;
		}
		.input-hours,
		.input-hours-DAN,
		.input-points {
			&::before {
				right: 2px;
			}
			&.right {
				/*&::before {
					top: 6px;
					line-height: 16px;
				}*/
			}
			&.disabled {
				&::before {
					color: $v2-text-gray;
				}
				&.right {
					&::before {
						top: 6px;
						line-height: 16px;
					}
				}
				input {
					border-color: transparent;
					border: none;
					&:hover {
						border: none;
					}
				}
			}
		}
		.date-text {
			font-size: 13px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			color: $v2-text-gray;
			&.overdue {
				color: $negative-value;
			}
			&.today {
				color: $link-text-color;
			}
			&.done {
				color: $insight-component-portfolio-report-positive-value;
			}
		}
		.text-tile {
			width: 100%;
			padding-left: 18px;
			.second-line {
				color: $v2-text-gray;
				font-weight: 400;
			}
		}
		.assigned-table-tile-v2 {
			opacity: 0;
			.dropdown-container {
				border-width: 0px;
				&:hover,
				&:focus {
					border-width: 1px;
				}
				&:not(:focus) {
					background-color: transparent;
				}
				input::placeholder {
					color: $v2-text-light-gray;
				}
			}
			&:hover,
			&:focus,
			&.visible {
				opacity: 100;
			}
		}
		.done-check-mark {
			width: 100%;
			height: 100%;
			background: url('images/v2/ok-green.svg');
			background-size: 20x;
			background-position: right 7px;
			background-repeat: no-repeat;
		}
		div:not(.project-group-content):not(.label-content):not(.person-initials-hexagon-shape) {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		.unfocused-assigned-container {
			.person-initials-hexagon-shape {
				overflow: visible;
			}
		}

		.card-information-text {
			margin: 0;
			min-width: 0;
			max-width: 100%;
		}
		.task-group,
		.project-group {
			align-items: center;
			padding-left: 16px;
			width: 100%;
			max-width: 100%;
			display: flex;
			flex-direction: row;
			&.not-first {
				padding-left: 10px;
			}
			.blocked-icon,
			.warning-icon,
			.bug-icon,
			.high-priority-flag {
				margin-right: 2px;
				width: 15px;
				min-width: 15px;
				max-width: 15px;
				height: 15px;
				background-size: 12px;
				background-position: center;
				background-repeat: no-repeat;
			}
			.high-priority-flag {
				background-image: url('images/v2/components/input-field/ico-priority.svg');
			}
			.blocked-icon {
				background-image: url('images/v2/components/input-field/ico-blocked.svg');
			}
			.bug-icon {
				background-image: url('images/v2/task-modal/ico-bug-grey.svg');
			}
			.task-id {
				cursor: pointer;
				&:hover {
					color: $color-purple-hover-active;
				}
			}
			.task-name,
			.project-name {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				padding-left: 0;
				.td-text {
					text-decoration: none;
					cursor: pointer;
					margin: 0;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					&:hover {
						color: $color-purple-hover-active;
					}
					&.no-click {
						&.project-name {
							color: $v2-text-light-gray;
							font-style: italic;
						}
						&:hover {
							&.project-name {
								color: $v2-text-light-gray;
							}
							color: $v2-text-gray;
							cursor: default;
						}
					}
					&:disabled {
						color: $v2-input-disabled-grey;
						&:hover {
							color: $v2-input-disabled-grey;
						}
					}
				}
			}
		}
		.project-group {
			padding-left: 0;
		}
		.date-picker-button {
			&.locked {
				&:hover {
					background-color: transparent;
				}
			}
		}
		.drop-down-v2 {
			//margin-left: 4px;
			border-radius: 5px;
			height: 30px;
			&:hover {
				input {
					border-color: $v2-button-very-light-grey-hovered;
				}
			}
			&.approved-dropdown {
				margin: 0 4px;
			}
			&.disabled {
				input {
					border-color: transparent;
				}
			}
			input {
				height: 100%;
				background-color: transparent;
				border: solid 1px transparent;
				//border-top-width: 2px !important;
				//border-bottom-width: 2px !important;
				//padding: 16px 24px 16px 8px;
				&:focus {
					border-color: $v2-branding-purple;
				}
			}
			.arrow {
				margin-right: 7px;
			}
		}
		.new-ui-actions-menu {
			width: 28px;
		}
		.list-input-cell {
			font-size: 12px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.33;
			letter-spacing: normal;
			color: $v2-text-gray;
			width: 100%;
		}
		.numeric-tile {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: row;
			position: relative;
			.value-container .value-container-v2 {
				display: flex;
				flex-direction: row;
				position: absolute;
				top: 50%;
				right: 2px;
				transform: translateY(-50%);
				p {
					margin: 0;
				}
			}
			span {
				justify-content: flex-end;
			}
		}
		.check-box {
			margin-right: 18px;
			margin-left: 6px;
			width: 18px;
			height: 18px;
			min-width: 18px;
			display: flex;
			justify-content: center;
			align-items: center;
			.check-mark:after {
				width: 6px;
				height: 10px;
				left: 53%;
				top: 1px;
			}
			&:focus {
				background-color: rgba(0, 0, 0, 0.15);
			}
			&.disabled {
				.check-mark {
					width: 18px;
					height: 18px;
					cursor: default;
				}
				input:after {
					border-color: $v2-button-very-light-grey-hovered;
				}
			}
		}
		.list-number-cell {
			width: 100%;
			height: 100%;
			border-radius: 4px;
			display: flex;
			flex-direction: row;
			border: solid 1px transparent;
			.input-text {
				display: flex;
				flex-direction: row;
				position: absolute;
				top: 50%;
				right: 2px;
				transform: translateY(-50%);
				p {
					margin: 0;
				}
				.list-number-cell-text {
					margin-top: 2px;
					font-size: 12px;
					font-size: 12px;
					font-weight: bold;
					font-style: normal;
					font-stretch: normal;
					line-height: 0.92;
					letter-spacing: 1px;
				}
				.list-number-cell-suffix {
					font-family: $text-font-family;
					font-size: 10px;
					font-weight: normal;
					line-height: 1.6;
					letter-spacing: normal;
					font-style: normal;
					font-stretch: normal;
					color: $v2-text-gray;
					text-align: left;
				}
			}

			input {
				font-size: 12px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.33;
				letter-spacing: normal;
				color: $v2-text-gray;
			}
			.list-number-cell-text {
				padding-left: 2px;
				font-weight: bold;
			}
			&.invalid-input {
				.list-number-cell-text {
					color: $v2-workflow-task-warning-red;
				}
			}
		}
		.list-number-cell {
			&.hoverable:hover,
			&.hoverable:focus {
				border-radius: 4px;
				border: solid 1px $v2-date-picker-button-border-color;
			}
		}
		.input-wrapper-focused {
			input,
			.suffix {
				font-size: 12px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.33;
				letter-spacing: normal;
				text-align: right;
				color: $v2-text-gray;
			}
		}
		.focused-assigned-container {
			padding-right: 0;

			.assigned-dropdown-wrapper {
				input {
					padding: 0px 6px;
				}
				input.expanded {
					border-color: $v2-branding-purple;
					border-bottom-left-radius: 4px;
					border-bottom-right-radius: 4px;
					//border-bottom-color: transparent;
				}
				.arrow,
				.clear-input {
					bottom: 1px;
				}
			}
		}
		.unfocused-assigned-container {
			//width: unset;
			.empty-assigned-container {
				width: 100%;
			}
			padding: 0;
			cursor: pointer;
			.assigned-list {
				padding: 2px 4px;
				height: auto;
				width: auto;
				margin: 4px 0 3px 0;
				border: solid 1px transparent;
				justify-content: left;
				&:hover {
					border-color: $v2-button-very-light-grey-hovered;
				}
				&.disabled {
					.assigned-role-text {
						color: $v2-text-light-gray;
					}
					.name {
						color: $v2-text-light-gray;
					}
					&:hover {
						border-color: transparent;
					}
				}
			}
			.additional-assigned-persons {
				height: 26px;
			}
			.more-people-container {
				color: #535353;
				height: 26px;
				width: 26px;
				background-size: 22px;
				background-position: center;
				background-repeat: no-repeat;
				background-image: url('images/v2/components/white-hexagon.svg');
				font-size: 11px;
				font-weight: 500;
				line-height: 26px;
				text-align: center;
				z-index: 10;
			}
		}
		.last-cell {
			background: white;
			border-left: none;
			width: 18px;
			border-top: none;
			.chip-container {
				height: 100%;
				float: right;
				overflow: hidden;
				position: relative;
				.chip-div {
					width: 18px;
					height: 100%;
					border-right: solid 1px $v2-table-border-color;
					border-bottom: solid 1px $v2-table-border-color;
				}
				.chip {
					border-bottom: solid 1px $v2-table-border-color;
					content: '';
					position: absolute;
					height: 20px;
					width: 22px;
					display: block;
					top: -13px;
					right: -6px;
					background-color: white;
					transform: rotate(30deg);
					&.has-phase {
						background-color: #f3f3f3;
					}
				}
			}
			&.grouped {
				.chip {
					background-color: white !important;
				}
			}
		}
	}
	.project-status-text {
		padding-left: 16px;
	}
	&.favoured {
		.elem-wrapper,
		.task-table-input-field,
		.chip-div {
			background-color: $favoured-task-yellow;
		}
	}
	&.locked {
		//pointer-events: none;
		.elem-wrapper {
			color: $v2-text-light-gray;
			background-color: $v2-input-disabled-grey; //rgba(0, 0, 0, 0.4);
			.task-table-input-field,
			.value-container-v2 {
				color: $v2-text-light-gray;
				background-color: transparent;
			}
		}
		.last-cell {
			.chip-container {
				.chip-div {
					background-color: $v2-input-disabled-grey;
				}
			}
		}
	}

	&.highlight {
		border: none;
		td {
			border-top: solid 1px $v2-workflow-task-warning-red;
			border-bottom: solid 1px $v2-workflow-task-warning-red;
		}
		td:first-child {
			border-left: solid 1px $v2-workflow-task-warning-red;
		}
		td:last-child {
			border-top: none;
			//border-right: solid 2px $v2-workflow-task-warning-red !important;
		}
		.last-cell {
			.chip-container {
				.chip-div {
					border-right: solid 2px $v2-workflow-task-warning-red;
				}
				.chip {
					border-bottom: solid 1px $v2-workflow-task-warning-red;
				}
			}
		}
		.context-menu {
			border: solid 1px #e6e6e6;
		}
	}

	&:hover:not(.no-hover):not(.selected) {
		.drag-handle {
			opacity: 1;
			&.disabled {
				opacity: 0;
			}
		}
		.dot-options,
		.time-registration-icon {
			opacity: 1;
		}
		.elem-wrapper {
			background: $drop-color;
			border-color: $v2-row-highlight-color;
			.last-cell {
				.chip-container {
					.chip-div {
						background: $drop-color;
						border-color: $v2-row-highlight-color;
					}
					.chip {
						border-color: $v2-row-highlight-color;
					}
				}
			}
			&.locked {
				border-color: $v2-table-border-color;
				background-color: $v2-input-disabled-grey;
				.last-cell {
					.chip-container {
						.chip-div {
							border-color: $v2-table-border-color;
							background-color: $v2-input-disabled-grey;
						}
						.chip {
							border-color: $v2-table-border-color;
						}
					}
				}
			}
		}
		.task-table-input-field {
			background: $drop-color;
		}
	}
	&.selected {
		.elem-wrapper:first-child {
			border-color: $v2-branding-purple;
		}
		.elem-wrapper {
			background-color: #f0e7fc;
			border-color: $v2-branding-purple;
			& > div:first-child {
				background-color: #f0e7fc;
			}
			.last-cell {
				.chip-container {
					.chip-div {
						background-color: #f0e7fc;
						border-color: $v2-branding-purple;
					}
					.chip {
						border-color: $v2-branding-purple;
					}
				}
			}
		}
		.context-menu {
			border: solid 1px #e6e6e6;
		}
		&:hover {
			.chip-div {
				background: $v2-workflow-task-selected-color !important;
				border-color: $v2-branding-purple !important;
			}
			.chip {
				border-color: $v2-branding-purple !important;
			}
		}
	}
	.context-menu {
		height: 130px;
		max-height: 130px;
		width: 134px;
		margin: 0;
		z-index: 999;
		border-radius: 4px;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
		border: solid 1px #e6e6e6;
		background-color: #ffffff;
		.context-options {
			margin: 0;
			padding-left: 0;
			list-style: none;
			height: 100%;
			width: 100%;
			background-color: white;
			li {
				padding-left: 15px;
				padding-top: 6px;
				height: 30px;
				width: 100%;
				color: black;
				font-family: $text-font-family-v2;
				font-size: 12px;
				font-weight: 500;
				font-style: normal;
				font-stretch: normal;
				line-height: normal;
				letter-spacing: normal;
				color: $v2-text-gray;
			}
			li:hover,
			li:focus {
				background-color: rgba(110, 15, 234, 0.1);
				color: $v2-branding-purple;
			}
		}
	}
	.phase-deadline-select {
		&.double {
			width: 100%;
		}
	}
	&.sprint {
		.dot-options {
			right: 36px;
		}
		.star-option {
			right: 56px;
		}
	}
	&.time-reg {
		&.locked {
			&:hover {
				.elem-wrapper {
					background-color: rgba(0, 0, 0, 0.4);
				}
				.last-cell {
					.chip-container {
						.chip-div {
							background-color: rgba(0, 0, 0, 0.4);
						}
					}
				}
			}
			.project-name {
				color: white;
			}
		}
		&.retainer-period-conflict {
			&:first-child {
				border-left: 1px solid $v2-retainer-conflict-red;
			}
			.empty-div {
				border-left: 1px solid $v2-retainer-conflict-red;
			}
			.elem-wrapper {
				border-top: 1px solid $v2-retainer-conflict-red;
				border-bottom: 1px solid $v2-retainer-conflict-red;
				&:last-child {
					border-top: none;
				}
			}

			.last-cell {
				.chip-container {
					.chip-div {
						border-right: solid 1px $v2-retainer-conflict-red;
					}
					.chip {
						border-bottom: solid 1px $v2-retainer-conflict-red;
					}
				}
			}
		}
		.elem-wrapper {
			font-size: 13px;
			.harvest-cell {
				display: flex;
				align-items: center;
				justify-content: center;
				.harvest-sync-icon {
					padding-right: 5px;
				}
			}
			.task-id-row-elem {
				margin-left: 16px;
			}
			.phase-deadline-select {
				span {
					position: absolute;
					left: 31px;
				}
			}
			.time-entry {
				margin-left: auto;
				margin-right: 16px;
			}
			.price {
				margin-left: auto;
				margin-right: 16px;
			}
		}
	}
}
.root-portal-container {
	ul.subtask-assigned-dropdown {
		border: 1px solid $v2-branding-purple;
		li {
			.list-header:first-of-type {
				border-bottom: 1px solid $app-border-color;
				// Removing these will make the dropdown look distorted
				// margin-left: 16px;
				// margin-right: 16px;
			}
		}
	}
}
