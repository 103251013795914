.unit4-sync-users-modal-content {
	display: flex;
	flex-direction: column;
	width: 500px;
	min-height: 300px;
	gap: 8px;
	.unit4-sync-users-modal-description {
		margin-bottom: 8px;
	}
	.unit4-users {
		width: 500px;
		.unit4-user {
			width: 100%;
			display: flex;
			align-items: center;
			input[type='checkbox'] {
				margin-right: 8px;
			}
		}
	}
}
