.task-table-header-v3-margin-wrapper {
	height: 20px;
	max-height: 20px; // Necessary to calculate height for framework
	width: 100%;
	background: white;
	//margin: 16px 0 0 0;
	.task-table-header-v3 {
		padding: 0 10px;
	}
}
.task-table-header-v3 {
	height: 36px;
	max-height: 36px; // Necessary to calculate height for framework
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal;
	display: flex;
	flex: 1 1 auto;
	align-items: center;
	flex-direction: row;
	font-size: 9px;
	margin-top: 6px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.22;
	letter-spacing: 1px;
	padding: 10px 26px 8px 26px;
	color: #a1a1a1;
	outline: none;
	&:focus {
		outline: none;
	}
	.multiline-table-header {
		display: flex;
		flex-direction: column;
	}
	.row-dragger,
	.drag-handle-light {
		min-width: 2em;
		width: 2em;
		max-width: 2em;
	}
	.task-id-row-elem {
		width: 50px;
	}
	.task-selector-row-elem {
		padding-left: 7px;
		width: 42px;
	}
	.row-assigned-role,
	.row-assigned-person {
		padding-left: 4px;
	}
	.row-status,
	.row-phase,
	.row-sprint {
		padding-left: 18px;
	}
	.row-assigned {
		// max-width: 10em;
		flex-wrap: wrap;
	}

	.task-group {
		display: flex;
		align-items: center;
		padding-left: 16px;
		&.not-first {
			padding-left: 10px;
		}
	}
	.row-labels {
		padding-left: 8px;
	}
	.sortable {
		cursor: pointer;
		span {
			margin-left: 0.5em;
			padding: 0.2em 0;
			background-size: 12px;
			color: $v2-branding-purple;
		}
		&.ascending {
			span {
				background-image: url('images/v2/sort-ascending.svg');
				background-size: 18px;
				background-position: center;
				color: $v2-branding-purple;
				padding-bottom: 1px;
			}
			color: $v2-branding-purple;
		}
		&.descending {
			span {
				background-image: url('images/v2/sort-descending.svg');
				background-size: 18px;
				background-position: center;
				color: $v2-branding-purple;
			}
			color: $v2-branding-purple;
		}
		&:hover {
			color: $v2-branding-purple;
		}
	}
}
