.settings-idle-times {
	&.section-content {
		margin: 0;
		.section-body {
			margin: 0 24px;
			width: 566px;
		}
	}
	.section-body {
		height: 100%;
		fieldset {
			@include settings-section-fieldset-v2;
			.top-container {
				padding-bottom: 16px;
			}
			.table-wrapper {
				margin-top: 16px;
			}
			table {
				thead {
					th {
						text-transform: uppercase;
						font-size: 9px;
						font-weight: 500;
						font-style: normal;
						font-stretch: normal;
						line-height: normal;
						letter-spacing: 1px;
						color: $v2-text-light-gray;
						&:first-child {
							padding-left: 16px;
						}
					}
				}
				tbody {
					tr {
						height: 48px;
						border: solid 1px $app-border-color;
						border-width: 1px 0;
						&:hover,
						&:focus {
							background-color: rgba(110, 15, 234, 0.05);
						}
						td {
							padding: 9px 16px !important;
							font-size: 13px;
							font-weight: normal;
							font-style: normal;
							font-stretch: normal;
							line-height: normal;
							letter-spacing: normal;
							color: $v2-text-gray;
							.idle-time-input {
								input {
									padding: 7px 8px;
									border-radius: 4px;
									border: solid 1px $app-border-color;
									outline: none;
									min-width: 332px;
									width: auto;
									background-color: white;
									&:disabled {
										color: $v2-text-light-gray;
										background-color: $v2-input-disabled-grey;
									}
								}
							}

							.bamboo-time-off-container {
								min-width: 200px;
								width: 200px;
							}
						}
						.actions-menu-cell {
							width: 40px;
						}
					}
				}
			}
			.newIdleTimeControls {
				padding: 9px 16px;
				border-bottom: 1px solid $app-border-color;
				display: flex;
				align-items: center;
				.newIdleTimeNameInput {
					margin-right: 8px;
					border-radius: 4px;
					border: solid 1px $app-border-color;
					font-size: 13px;
					padding: 7px 16px;
					min-width: 332px;
					width: auto;
					height: 30px;
				}
				.addNewIdleTimeButton {
					height: 28px;
					width: 28px;
					background-size: 14px;
					background: $v2-create-button-green url('images/v2/components/overview_time/plus-add-white.svg') no-repeat center;
					&.lightgrey {
						background-color: $v2-grey;
						&:hover {
							opacity: 1;
							cursor: default;
						}
					}
					&.green {
						background-color: $v2-create-button-green;
					}
				}
			}
		}
	}

	/*td {
		text-align: right;
	}
	.submit-button {
		@include primary-button;
	}
	table {
		width: auto !important;
		tbody {
			tr {
				.controls {
					text-align: right;
					min-width: 150px;
					white-space: nowrap;
					padding-right: 20px;
					span {
						background-size: 16px;
						background-repeat: no-repeat;
						background-position: left center;
						padding: 0 10px 0 21px;
						cursor: pointer;
						&.remove {
							background-image: url('images/cross-section-icons/actions/remove.svg');
							&:hover {
								text-decoration: underline;
							}
						}
						&.prompt {
							cursor: default;
							padding: 0 5px 0 0;
							span {
								padding: 0 5px 0 0;
								&.confirm {
									cursor: pointer;
									&:hover {
										color: $color-purple-hover-active;
									}
								}
							}
						}
					}
				}
				.controls span {
					visibility: hidden;
				}
				&:hover {
					.controls span {
						visibility: visible;
					}
				}
				td {
					.Select {
						min-width: 200px;
						.color-list-option-container {
							padding-left: 20px;
							padding-right: 20px;
							.color-list-option {
								height: 10px;
							}
						}
						.color-list-value-container {
							padding-top: 12px;
							padding-left: 20px;
							padding-right: 20px;
							.color-list-value {
								height: 10px;
							}
						}
					}
				}
			}
		}
	}*/
}
