.board-card-outer-v3 {
	margin: 5px 10px;
	position: relative;
	outline: none;
	display: flex;
	flex-direction: column;

	//border: 2px solid transparent;
	position: relative;
	.dot-options {
		opacity: 0;
		cursor: pointer;
		width: 16px;
		height: 30px;
		position: relative;
		background: url('images/components/overlay/navigation-show-more-vertical.svg');
		background-size: 25px;
		background-position: center;
		background-repeat: no-repeat;
		border-radius: 4px;
		&:hover,
		&:focus {
			background-color: rgba(0, 0, 0, 0.2);
		}
	}
	.card-top-bar {
		// Remove visible line between card and top bar
		margin-bottom: -1px;
		position: relative;
		height: 12px;
		width: 100%;
		background-color: rgba(0, 0, 0, 0);
		.card-top-left {
			border-top: solid 2px transparent;
			border: none;
			outline: none;
			//-webkit-box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.05);
			//-moz-box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.05);
			//box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.05);
			position: absolute;
			height: 12px;
			left: 0;
			top: 0;
			width: calc(100% - 20px);
			background-color: $color-white;
		}
		.card-top-angle {
			border-color: none;
			outline: none;
			//-webkit-box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.05);
			//-moz-box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.05);
			//box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.05);
			position: absolute;
			right: 1.75px;
			top: 4.8px;
			width: 22px;
			height: 10px;
			transform: rotate(30deg);
			background-color: $color-white;
		}
		&.selected {
			.card-top-left {
				border-top: solid 2px $v2-branding-purple;
				border-left: solid 2px $v2-branding-purple;
				background-color: $v2-workflow-task-selected-color;
			}
			.card-top-angle {
				border-top: solid 2px $v2-branding-purple;
				background-color: $v2-workflow-task-selected-color;
			}
		}
	}

	&.favoured {
		.card-top-left,
		.card-top-angle,
		.card-contents {
			background-color: $favoured-task-yellow;
			&.selected {
				background-color: $v2-workflow-task-selected-color;
			}
		}
	}

	&.keyboard-focused {
		.card-contents {
			background: $v2-workflow-task-selected-color;
		}
		.card-top-bar {
			.card-top-left,
			.card-top-angle {
				background: $v2-workflow-task-selected-color;
			}
		}
	}

	&.select-mode {
		.card-contents {
			box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
			&:hover {
				box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
			}
		}
		.date-and-favourite-wrapper {
			transition: none !important;
		}
	}

	&.locked {
		opacity: 1;
		.overlay-div {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			width: 100%;
			height: 100%;
			pointer-events: none;
			background-color: rgba(0, 0, 0, 0.4);
			z-index: 2;
		}
		.card-contents {
			position: relative;
			border-color: #999999;
			background-color: white;
			&:hover {
				-moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
				-webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
				box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
			}
		}
		.overlay-div {
			//border-color: #999999;
			opacity: 1;
		}
		.card-top-bar {
			border-color: #999999;
			.card-top-left,
			.card-top-angle {
				background-color: #999999;
			}
		}
		&.keyboard-focused {
			.overlay-div {
				background-color: rgba(0, 0, 0, 0.25);
			}
			.card-contents {
				border-color: $v2-workflow-halted-task-selected-color;
			}
			.card-top-bar {
				border-color: $v2-workflow-halted-task-selected-color;
				.card-top-left,
				.card-top-angle {
					background-color: $v2-workflow-halted-task-selected-color;
				}
			}
		}
	}
	.card-contents {
		-moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
		-webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
		padding: 0 0 12px 16px;
		background-color: $color-white;
		border-left: solid 2px transparent;
		border-right: solid 2px transparent;
		border-bottom: solid 2px transparent;
		&.selected {
			border-left: solid 2px $v2-branding-purple;
			border-right: solid 2px $v2-branding-purple;
			border-bottom: solid 2px $v2-branding-purple;
			background-color: $v2-workflow-task-selected-color;
			.progress-bar {
				bottom: 2px;
				margin-left: 2px;
				padding-right: 4px;
			}
		}

		&.drag-styling {
			background-color: $v2-workflow-task-selected-color;
		}
		.locked-icon {
			min-width: 28px;
			width: 28px;
			height: 30px;
		}
		.locked-icon {
			&.halted,
			&.done {
				background-image: url('images/v2/overview-projects/lock_dark.svg');
				background-repeat: no-repeat;
				background-position: center;
			}
		}
	}
	.triangle-border-element {
		position: absolute;
		right: 0px;
		top: 0px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 19px 12px 0;
		border-color: transparent $color-white transparent transparent;
	}

	&.bulk-selected {
		border: 2px solid $v2-background-dark-gray;
		.triangle-border-element {
			border-color: transparent $v2-background-dark-gray transparent transparent;
		}
	}
	.project-workflow-task-header {
		display: flex;
		justify-content: space-between;
		margin-right: 8px;
		max-width: 100%;
		.task-indicators {
			display: flex;
			flex-direction: row;
			align-items: center;
			flex-grow: 1;
			span {
				display: flex;
			}
			.task-indicators-item {
				margin-right: 8px;
			}
		}
		.date-and-favourite-wrapper {
			display: flex;
			align-items: center;
			flex-direction: row;
			position: relative;
			transition: right 0.2s ease-in-out;
			.task-dates-wrapper {
				display: flex;
				flex-direction: row;
				align-self: center;
				.task-date {
					font-family: $text-font-family;
					font-size: 10px;
					font-weight: 750;
					font-style: normal;
					font-stretch: normal;
					line-height: 1.1;
					letter-spacing: normal;
					text-align: right;
					color: $v2-text-gray;
					text-transform: uppercase;
					margin-right: 4px;
				}
			}
			.favourite-wrapper {
				margin: 0 5px 0 4px;
			}
		}
		.new-ui-actions-menu {
			width: 0;
			opacity: 0;
			transition: opacity 0.2s ease-in-out;
			&.expanded {
				width: 16px;
				right: 5px;
				opacity: 1;
			}
			.actions {
				right: -3px;
				top: -4px;
				position: absolute;
			}
		}

		.polygon {
			top: -2px;
			right: -2px;
			position: absolute;
		}
		.id-name-wrapper {
			display: flex;
			flex-direction: column;
			margin-bottom: 10px;
			width: 100%;
			.task-id {
				user-select: none;
				font-size: 10px;
				font-weight: 500;
				color: $v2-text-gray;
				display: flex;
				justify-content: space-between;
				.priority-icon,
				.bug-icon,
				.dependency-icon {
					background-size: contain;
					background-repeat: no-repeat;
				}
				.bug-icon {
					height: 12px;
					width: 12px;
				}
				.priority-icon {
					height: 14px;
					width: 11px;
				}
				.dependency-icon {
					width: 12px;
					height: 12px;
					z-index: 1;
				}
			}

			.name-wrapper {
				display: flex;
				margin-top: 4px;
				//align-items: center;

				.warning-red-icon,
				.warning-yellow-icon {
					height: 14px;
					width: 15px;
					min-width: 15px;
					min-height: 14px;
					margin-right: 5px;
					background-size: contain;
					background-repeat: no-repeat;
				}
				.task-name {
					font-size: 12px;
					font-weight: 500;
					text-decoration: none;
					color: $v2-text-gray;
					text-overflow: ellipsis;
					overflow: hidden;
					user-select: none;
					align-self: center;
				}

				.project-indicator-component {
					margin-right: 8px;
				}
			}
			&.white {
				.task-id,
				.task-name {
					color: $color-white;
				}
				.bug-icon {
					background-image: url('images/v2/components/workflow-task/bugs-icon-white.svg');
				}
			}
		}
	}
	.chip-container {
		float: right;
		overflow: hidden;
		position: absolute;
		// Compensation for border
		top: -2px;
		right: -2px;
		.chip-div {
			background-color: rgba(0, 0, 0, 0);
			width: 18px;
			height: 60px;
		}
		.chip {
			content: '';
			position: absolute;
			height: 20px;
			width: 22px;
			display: block;
			top: -13px;
			right: -6px;
			background-color: #f3f3f3;
			transform: rotate(30deg);
		}
	}
	.cover-image {
		max-height: 80px;
		overflow: hidden;
		margin-bottom: 10px;
		margin-right: 16px;
		display: flex;
		align-items: center;
		border-radius: 4px;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
		img {
			max-width: 100%;
			margin-left: auto;
			margin-right: auto;
		}
	}
	.blocked-wrapper {
		display: flex;
		flex: 0 0 auto;
		align-items: center;
		margin-bottom: 4px;
		margin-top: 4px;
		.blocked-icon {
			height: 14px;
			width: 14px;
			background-size: 14px;
			margin-right: 5px;
			background-image: url('images/v2/components/workflow-task/v3/ico-blocked.svg');
		}
		.blocked-text {
			color: $v2-border-invalid;
			text-transform: uppercase;
			font-size: 10px;
			font-weight: bold;
			height: 11px;
		}
	}

	.icons-people-open-modal-button-wrapper {
		display: flex;
		align-items: flex-end;
		margin-right: 18px;
		.icons-people-wrapper {
			display: flex;
			flex: 1 1 auto;
			justify-content: space-between;
			align-items: center;
			// flex-direction: column;
			overflow: hidden;
			.time-registration-icon {
				margin-left: auto;
				margin-right: 5px;
				cursor: pointer;
				transition: opacity 0.2s ease-in-out;

				&:not(.active) {
					opacity: 0;
				}
			}
			.icons-section {
				display: flex;
				font-size: 10px;
				font-weight: 500;
				color: $v2-text-gray;

				.sub-tasks-icon,
				.files-icon,
				.comments-icon {
					height: 13px;
					width: 13px;
					background-size: contain;
					background-repeat: no-repeat;
					margin-right: 4px;
				}
				.count {
					margin-right: 15px;
				}
				.sub-tasks-icon {
					background-image: url('images/v2/components/workflow-task/v3/ico-subtask.svg');
				}
				.files-icon {
					background-image: url('images/v2/components/workflow-task/v3/ico-attachment.svg');
				}
				.comments-icon {
					background-image: url('images/v2/components/workflow-task/v3/ico-comments.svg');
				}
				&.white {
					color: $color-white;
					.comments-icon {
						background-image: url('images/v2/components/workflow-task/comment-icon-white.svg');
					}
					.files-icon {
						background-image: url('images/v2/components/workflow-task/file-icon-white.svg');
					}
					.sub-tasks-icon {
						background-image: url('images/v2/components/workflow-task/task-icon-white.svg');
					}
				}
			}
			.assigned-people-section {
				display: flex;
				flex-wrap: no-wrap;
				overflow: hidden;
				font-size: 11px;
				font-weight: 500;
				color: $color-white;
				letter-spacing: -0.5px;
				text-align: center;
				justify-content: flex-end;
				.remaining-people-indicator {
					position: relative;
					width: 23px;
					height: 13.28px;
					background-color: $v2-negative-dropdown-grey;
					margin: 6.64px 0;
				}

				.remaining-people-indicator:before,
				.remaining-people-indicator:after {
					content: '';
					position: absolute;
					width: 0;
					border-left: 11.5px solid transparent;
					border-right: 11.5px solid transparent;
				}

				.remaining-people-indicator:before {
					bottom: 100%;
					border-bottom: 6.64px solid $v2-negative-dropdown-grey;
					left: 0;
				}

				.remaining-people-indicator:after {
					top: 100%;
					width: 0;
					left: 0;
					border-top: 6.64px solid $v2-negative-dropdown-grey;
				}
			}
			.forecast-person {
				margin-right: -7px;
			}
			.forecast-person:last-child {
				margin-right: 0;
			}
		}
		.open-task-button {
			height: 20px;
			width: 20px;
			border: none;
			background-image: url('images/v2/components/workflow-task/open-task.svg');
			&.white {
				background-image: url('images/v2/components/workflow-task/open-task-white.svg');
			}
		}
	}
	.progress-bar {
		display: flex;
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		//Account for border
		.progress {
			background-color: #e7abab;
			height: 3px;
			&.done {
				background-color: $v2-completed-green;
			}
		}
		.remaining {
			height: 3px;
			background-color: rgba(231, 171, 171, 0.5);
		}
	}
	// styling for completed task
	&.done {
		.progress {
			background-color: $v2-workflow-task-done-state-color;
		}
	}
	.checkmark-checked,
	.checkmark-unchecked {
		min-width: 20px;
		width: 20px;
		height: 20px;
	}

	&.hovered {
		.card-contents {
			box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
		}
		.date-and-favourite-wrapper {
			right: 13px;
			position: relative;
			&.disabled {
				right: 0;
			}
		}
		.new-ui-actions-menu {
			opacity: 1;
		}
		.icons-people-open-modal-button-wrapper .icons-people-wrapper .time-registration-icon {
			opacity: 1;
		}
		.dot-options {
			opacity: 1;
		}
	}
	.card-top-left,
	.card-top-angle,
	.card-contents {
		transition: background-color 0.1s ease;
	}
}
