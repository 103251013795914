.overview-column {
	position: relative;
	max-width: 20%;
	min-width: 20%;
	flex-grow: 0;
	margin: 0 5px 25px 5px;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	.drop-down-v2 {
		width: 100%;
		input {
			font-size: 16px;
			text-align: center;
		}
	}
	.board-card-outer-v3 {
		margin: 5px 10px;
		&:hover {
			cursor: pointer;
			.card-contents {
				box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
			}
		}
	}
	.board-card-outer-v3:last-child {
		margin-bottom: 25px;
	}
	.no-tasks-info {
		margin: 10px;
		font-weight: 500;
		color: $v2-text-light-gray;
		font-family: $text-font-family;
	}
	.overview-tasks-scroll-container {
		background: $color-light-grey;
		margin-top: 4px;
		margin-bottom: 10px;
		height: 100%;
		display: flex;
		flex-direction: column;
		.overview-tasks {
			display: flex;
			flex-direction: column;
		}
	}
}
