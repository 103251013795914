.task-modal-v3-footer-section {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 30px;

	display: flex;
	flex-direction: column;
	border-top: 1px solid #eaeaea;
	border-bottom: 1px solid $app-border-color;
	margin-top: 61px; //Room for new comment section
	background-color: $v2-fixed-element-grey;
	transition: bottom 0.2s;

	.task-info {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 28px;
		margin: 0 16px;

		& > * {
			flex: 1;
		}

		.arrow {
			width: 10px;
			height: 5px;
			margin-left: 5px;
			background-size: 10px;
			background-position: center;
			background-image: url('images/v2/components/dropdown/arrow-up.svg');
			&.expanded {
				background-image: url('images/v2/components/dropdown/arrow-down.svg');
			}
		}
	}

	.client {
		display: flex;
		align-items: center;

		img {
			width: 24px;
		}
		.client-name {
			margin-left: 6px;
			font-size: 11px;
			color: $v2-text-light-gray;
		}
	}

	.update-info {
		font-size: 11px;

		span {
			display: flex;
			justify-content: flex-start;
			color: $v2-text-light-gray;

			.update-name {
				max-width: 150px;
				display: inline-block;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				margin-right: 3px;
			}

			span:nth-child(2) {
				font-weight: bold;
				text-transform: uppercase;
			}
		}
	}

	.activity-log-button {
		display: flex;
		align-items: center;
		font-size: 9px;
		text-transform: uppercase;
		text-align: left;
		cursor: pointer;
		height: 100%;
		line-height: 28px;
		color: $v2-text-gray;
		letter-spacing: 1px;
		width: max-content;
		margin-left: auto;

		&:hover {
			color: $v2-branding-purple;
		}
	}

	.custom-scrollbar-div {
		height: 200px;
	}

	@import './owner_button.scss';
}

.activity-list-container {
	position: absolute;
	width: 100%;
	bottom: 30px; // position on top of the footer bar
	height: 0;
	transition: height 0.2s;
	z-index: 100;
	background-color: $v2-fixed-element-grey;
	display: flex;

	&.activity-list-container-loading {
		background-image: url('images/loaders/loader-32x32-brown.svg');
		background-repeat: no-repeat;
		background-size: 40px 40px;
		background-position: center;
	}

	.custom-scrollbar-div {
		background-color: $v2-fixed-element-grey;

		.wait-text {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			font-size: 13px;
			color: $v2-text-gray;
		}
	}

	.activities {
		display: flex;
		flex-direction: column;
		align-items: center;

		.activity-log-row {
			width: 97%;
			display: flex;
			align-items: center;
			justify-content: space-evenly;
			border-top: 2px solid $app-border-color;
			padding: 10px;

			&:first-child {
				border: none;
			}

			.forecast-person {
				font-weight: bold;
				width: 160px;
			}

			.text {
				padding: 0 50px;
				flex: 3 0px;
				font-size: 14px;
			}

			.date {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				font-size: 12px;
			}
		}
	}
}
