.component-overallVelocity {
	background-image: url('images/sections/insights/components/velocity.svg');
}

.component-projectTimeline {
	background-image: url('images/sections/insights/components/projectTimeline.png');
}

.component-color-cardsDone,
.component-color-workflowStatus,
.component-color-sprintsCompleted {
	background-image: url('images/sections/insights/components/piechart_colour.svg');
}
.component-color-burnUp {
	background-image: url('images/sections/insights/components/burnUp_colour.svg');
}
.component-color-overallVelocity {
	background-image: url('images/sections/insights/components/velocity_colour.svg');
}
.component-color-sprintVelocity {
	background-image: url('images/sections/insights/components/sprintVelocity.jpg');
}
.component-color-plannedVsSpent {
	background-image: url('images/sections/insights/components/horizontalBars_colour.svg');
}
.component-color-cardList,
.component-color-cardListPortfolio,
.component-color-sprintList,
.component-color-reportedTimePeople,
.component-color-reportedTimePortfolio,
.component-color-peopleListProjects,
.component-color-peopleListPortfolio,
.component-color-clientList,
.component-color-cardDelayListProject,
.component-color-cardDelayListPeople,
.component-color-utilizationList,
.component-color-reportedTimeBusiness,
.component-color-reportedTimeProject,
.component-color-expenseReportProject,
.component-color-expenseReportPortfolio,
.component-color-utilizationMonthly,
.component-color-timeRegisteredPerPersonPortfolio {
	background-image: url('images/sections/insights/components/cardList.jpg');
}
.component-color-sprintBurndown {
	background-image: url('images/sections/insights/components/burnDown-color.svg');
}

.component-color-sprintBurndownV2 {
	background-image: url('images/sections/insights/components/burnDown-color.svg');
}

.component-color-cummulativeFlow {
	background-image: url('images/sections/insights/components/cumulativeflow.jpg');
}

.component-color-freeTextField {
	background-image: url('images/sections/insights/components/freeTextField.png');
}

.component-color-utilizationBarChart {
	background-image: url('images/sections/insights/components/utilization-bar-chart.png');
}

.component-color-projectTimeline {
	background-image: url('images/sections/insights/components/projectTimeline.png');
}

// Coming soon components have been assigned random graphics for now. Move out of these buckets as the components are created.
.component-bugRatio,
.component-skillStatsProject,
.component-projectsByStatus,
.component-mostMentionsPeople,
.component-onTime,
.component-blockedRatio,
.component-emojiStatsBusiness,
.component-emojiStatsProject,
.component-emojiStatsPeople {
	background-image: url('images/sections/insights/components/piechart.svg');
}

.component-labelStatsBusiness,
.component-labelStatsProject,
.component-scopeTotals,
.component-timeSplit,
.component-cardProgress,
.component-growthFactor {
	background-image: url('images/sections/insights/components/horizontalBars.svg');
}

.component-labelDelayHistoryProject,
.component-forecastVsActualsCost,
.component-scopeProgressCost,
.component-remainingBudgetCost,
.component-workLeftAtDeadline,
.component-forecastedDelivity,
.component-forecastVSActualsTime,
.component-scopeProgressTime {
	background-image: url('images/sections/insights/components/burnup.svg');
}

.component-mostMentionsProject,
.component-cardDelay,
.component-timeEconomics,
.component-timeTotals,
.component-forecastedGrowth,
.component-timePerClient {
	background-image: url('images/sections/insights/components/velocity.svg');
}
