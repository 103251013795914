.time-reg-on-project-modal {
	@include scrollable-table;
	.scrollable-table {
		margin-top: 32px;

		.row {
			border: 1px solid $v2-table-border-color;
			&:last-child {
				border-bottom: 1px solid $v2-table-border-color !important;
			}
		}

		.column-person {
			width: 286px;
			padding-left: 16px;
			padding-right: 16px;

			.wrapper {
				display: flex;
				flex: 1 1 40px;
				flex-direction: row;
				justify-content: flex-start;
				overflow: hidden;
				align-items: center;

				.image-container {
					width: 22px;
					float: left;
					height: 25px;
					margin-bottom: 0px;
					margin-right: 6px;

					.profile-picture {
						margin: 0;
						position: relative;

						img {
							width: 22px;
							height: 22px;
						}
					}

					.deactivated {
						height: 36px;
						width: 36px;
						position: absolute;
						z-index: 10;
						border-radius: 50%;
						background: rgba(0, 0, 0, 0.75);
					}
				}

				.name-tile {
					h3 {
						padding-top: 0;
						margin: 0;
						font-size: 13px;
						color: $v2-text-gray;
						font-weight: 400;
					}
					p {
						margin-top: 5px;
						font-size: 11px;
					}
				}
			}
		}

		.column-date {
			height: 41px !important;
			width: 143px;
			padding-left: 16px;
			text-align: right;
			padding-right: 16px;
		}

		.column-entry {
			height: 41px !important;
			width: 143px;
			padding-left: 16px;
			text-align: right;
			padding-right: 16px;
		}
	}
}
