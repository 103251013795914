.radio-button-wrapper {
	display: flex;

	&.disabled {
		.info-label {
			color: #a1a1a1;
		}
	}
	.text {
		margin-left: 5px;
	}

	.radio-button {
		width: 22px;
		height: 22px;
		position: relative;
	}

	.radio-button-disabled {
		opacity: 0.5;
	}

	.radio-button:focus {
		outline: none;
	}

	.radio-button:focus .radio-button-container,
	.radio-button:hover .radio-button-container {
		border: 2px solid $v2-branding-purple;
	}

	.radio-button-disabled {
		.radio-button-container {
			border: 2px solid $v2-grey;
		}
		.radio-button-checked {
			border: 2px solid $v2-grey;
			background-color: $v2-grey;
		}
	}

	.radio-button-disabled:focus,
	.radio-button-disabled:hover {
		.radio-button-container {
			border: 2px solid $v2-grey;
			cursor: default;
		}
		.radio-button-checked {
			border: 2px solid $v2-grey;
		}
	}

	.radio-button-container {
		width: 22px;
		height: 22px;
		border-radius: 11px;
		background-color: white;
		border: 2px solid $v2-grey;
		cursor: pointer;
	}

	.radio-button-input {
		position: absolute;
		opacity: 0;
		width: 22px;
		height: 22px;
		bottom: 0;
		cursor: pointer;
	}

	.radio-button-checked {
		background-color: $v2-branding-purple;
		border: 1px solid $v2-branding-purple;
		&::before {
			content: '';
			position: absolute;
			background: white;
			height: 6px;
			width: 6px;
			border-radius: 3px;
			left: 8px;
			top: 8px;
		}
	}
}
