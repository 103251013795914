.with-plus {
	// hide the options if the plus button is visible
	.add-file-gdrive {
		opacity: 0;
	}
	// on hovering the plus button show the options and hide the plus
	&:hover,
	&:focus-within {
		.add-file-gdrive {
			opacity: 1;
		}
		.add-file {
			opacity: 0;
		}
	}
}

.add-file-gdrive {
	margin-bottom: 8px;
	margin-right: 8px;
	border: 1px solid $app-border-color;
	border-radius: 4px;
	background-color: transparent;
	width: 108px;
	height: 77px;
	position: relative;
	transition: all 0.1s linear;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	background: white;

	.upload-file-button {
		height: 43%;
		width: 100%;
		font-size: 12px;

		&:focus-within,
		&:hover {
			color: $v2-branding-purple;
			background-color: $v2-workflow-task-selected-color;

			div {
				display: flex;
			}
			// change the color of the svg icon
			path {
				fill: $v2-branding-purple;
			}
		}

		label {
			height: 100%;
			display: flex;
			align-items: center;
			cursor: pointer;
			padding-left: 10px;

			> div {
				margin-left: -10px;
				padding-left: 10px;
				width: calc(100% + 10px);
				height: 100%;
				display: flex;
				align-items: center;
				outline: none;
			}
			svg {
				margin: 0 6px;
			}
		}

		input[type='file'] {
			width: 100%;
			height: 50%;
			position: absolute;
			opacity: 0;
			font-size: 0;
			top: 0;
			left: 0;
			z-index: 2;
			cursor: pointer;
		}
	}
}

.add-file {
	margin-bottom: 8px;
	margin-right: 8px;
	border: 2px solid $v2-create-button-green;
	background-color: white;
	width: 108px;
	height: 77px;
	position: absolute;
	cursor: pointer;
	transition: all 0.1s linear;

	&:hover {
		border: 2px solid $v2-branding-purple;

		&:after {
			color: $v2-branding-purple;
		}
	}

	input[type='file'] {
		width: 100%;
		height: 100%;
		position: absolute;
		cursor: pointer;
		opacity: 0;
		font-size: 0;
		top: 0;
		left: 0;
		z-index: 2;
	}

	&:after {
		content: '+';
		position: absolute;
		font-size: 28px;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		color: $v2-create-button-green;
		z-index: 1;
		transition: all 0.1s linear;
	}
}
