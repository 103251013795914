.new-file,
.new-file-over {
	padding: 10px;
	border: 1px solid $app-border-color;
	border-radius: 5px;
	position: relative;
	flex: 1;
	color: $input-placeholder-color;
	.upload-overlay,
	.upload-overlay-hidden {
		position: absolute;
		width: 100%;
		height: 100%;
		border-radius: 5px;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 1;
		background: rgba(0, 0, 0, 0.75);
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.upload-overlay-hidden,
	.uploading-gif-hidden {
		display: none;
	}

	.uploading-gif,
	.uploading-gif-hidden {
		height: 40px;
		width: 40px;
		background-image: url($app-loader-url);
		background-repeat: no-repeat;
		background-size: 40px 40px;
	}

	.add-button {
		position: relative;
		::-webkit-file-upload-button {
			cursor: pointer;
		}
		input {
			width: 100%;
			height: 100%;
			position: absolute;
			cursor: pointer;
			opacity: 0;
			top: 0;
			left: 0;
		}
		float: right;
		@include secondary-button;
		overflow: hidden !important;
	}
	&.locked {
		color: #c4c4cc;
		background: #f7f7fe;
		.file-link {
			color: #c4c4cc;
			cursor: default;
		}
	}
}
.new-file-over {
	padding: 9px;
	border: 2px solid #a4c6fd;
	border-style: dashed;
}
