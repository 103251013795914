.filter-v3-content-wrapper {
	z-index: 9999;
	position: absolute;
	.filter-v3-content {
		@import './filter_type_options_content';
		@import './saved_filter';
		@import './applied_filter';
		width: 420px;
		border: 1px solid $v2-branding-purple;
		background-color: white;
		.filter-v3-tabs {
			display: flex;
			flex-direction: row;
			max-width: 417px;
			.filter-v3-tab {
				flex: 1 0 0%;
				height: 40px;
				border: none;
				border-bottom: 1px solid $app-border-color;
				border-right: 1px solid $app-border-color;
				font-size: 12px;
				letter-spacing: 0.5px;
				background-color: $color-light-grey;
				font-weight: bold;
				//max-width: 139px;
				&:hover,
				&:focus {
					background-color: $app-border-color;
				}
				&:active {
					background-color: #d9d9d9;
				}
				&:last-of-type {
					border-right: none;
				}
				&.selected {
					background-color: white;
					border-bottom: 1px solid white;
				}
			}
		}

		.filter-types-container {
			padding: 12px;
			.filter-types {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				border-left: 1px solid $app-border-color;
				margin-bottom: 8px;
				border-top: 1px solid $app-border-color;
				.filter-type {
					border: none;
					border-right: 1px solid $app-border-color;
					border-bottom: 1px solid $app-border-color;
					flex: 0 0 25%;
					height: 30px;
					font-size: 11.5px;
					max-width: 98px;
					overflow: hidden;
					text-overflow: ellipsis;
					&:hover,
					&:focus {
						background-color: $color-light-grey;
					}
					&:active {
						background-color: $app-border-color;
					}
					&.selected {
						background-color: $v2-branding-purple;
						color: white;
					}
				}
			}
			.input-value-container {
				height: 32px;
				.input-value {
					padding: 0 12px;
					font-weight: normal;
				}
			}
		}

		.filter-type-options-content {
			width: 418px;
		}
		.saved-filters-section,
		.applied-filters-section {
			max-width: 417px;
		}
		.saved-filters-section {
			border-top: 1px solid $app-border-color;
			background-color: $v2-fixed-element-grey;
			padding: 12px 0 8px 12px;
			.saved-filters-heading {
				font-size: 11px;
				font-weight: bold;
				letter-spacing: 0.5px;
				margin-bottom: 8px;
				display: flex;
				justify-content: space-between;
				.toggle-saved-filters-section-button {
					@include expand-control;
					border: none;
					height: 13px;
				}
			}
			.saved-filters {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				width: 408px;
				padding-right: 12px;
			}
		}

		.applied-filters-section {
			background-color: $v2-fixed-element-grey;
			padding: 12px 0 8px 12px;
			.applied-filters-header-row {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-bottom: 8px;
				padding-right: 12px;
				.applied-filters-heading {
					font-size: 11px;
					font-weight: bold;
					letter-spacing: 0.5px;
				}
				.clear-filters-button {
					border: none;
					color: $v2-text-light-gray;
					font-weight: 500;
					letter-spacing: 1px;
					font-size: 9px;
					margin-left: 8px;
					text-transform: uppercase;
				}
				.save-filters-button {
					border: none;
					color: $v2-create-button-green;
					font-weight: 500;
					letter-spacing: 1px;
					font-size: 9px;
					text-transform: uppercase;
					margin-left: auto;
				}
			}

			.applied-filters {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				width: 408px;
				padding-right: 12px;
			}
		}
	}
}
