.task-modal-v3-description-section {
	padding: 10px 58px;
	display: flex;
	.description-text {
		width: 100%;

		.hidden-file-input {
			display: none;
		}
	}
	.description-options {
		margin-left: 8px;
		display: flex;
		flex-direction: column;
		flex-shrink: 0;
		.description-option {
			margin-bottom: 8px;
			width: 50px;
			height: 42px;

			&.file-upload {
				display: inline-block;
				position: relative;
				cursor: pointer;
				input[type='file'] {
					width: 46px;
					height: 46px;
					position: absolute;
					cursor: pointer;
					opacity: 1;
					font-size: 0;
					top: 0;
					left: 0;
					z-index: 1;
					border-color: red;
				}
				.button-cover {
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 2;
					background-color: white;
					border: 1px solid $v2-background-dark-gray;
					pointer-events: none;

					&:focus {
						outline: 1px solid $v2-branding-purple;
					}

					.attachment-icon {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
				}
			}

			&.dependency-modal-button {
				display: inline-block;
				position: relative;
				overflow: hidden !important;
				cursor: pointer;
				border: 1px solid $v2-background-dark-gray;

				.dependency-icon {
					transform: translateY(2px);
				}

				&:focus {
					outline: 1px solid $v2-branding-purple;
				}
			}
		}

		.upload-options {
			position: absolute;
			right: 110px;

			.add-file-gdrive {
				margin: 0;
				background-color: white;
			}
		}
	}

	.rich-text-field-editor-wrapper {
		font-size: 13px;
	}

	.rich-text-field-container {
		&.read-only {
			.rich-text-field-input-container,
			.rich-text-field-input-not-empty {
				border: solid 1px $app-border-color;
				.rich-text-field-editor-wrapper {
					margin: 10px 14px 0 14px;
				}
			}
		}
	}

	.rich-text-field-input-container {
		min-height: 102px;
		&:after {
			background-image: url('images/v2/components/rich-text-field/fileplus.svg');
			background-repeat: no-repeat;
			content: '';
			position: absolute;
			height: 53px;
			width: 44px;
			left: calc(50% - 22px);
			top: calc(50% - 26.5px);
			opacity: 0.4;

			transition: opacity 0.1s linear 0.05s;
		}
		&.rich-text-field-input-not-empty {
			&:after {
				opacity: 0;
			}
		}
		&.has-focus {
			&:after {
				opacity: 0;
			}
		}
	}

	&.is-expandable {
		.rich-text-field-input-container {
			// Makes sure the textbox can only hold 4 lines of text
			height: 102px;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			.rich-text-field-editor-wrapper {
				min-height: 45px;
			}
		}
	}

	&.high-resolution {
		.rich-text-field-input-container {
			min-height: 180px;
			height: 180px;

			.rich-text-field-editor-wrapper {
				min-height: 127px;
			}
		}
		&.expanded {
			.rich-text-field-input-container {
				height: 100%;
				overflow: initial;
			}
		}
	}

	&.expanded {
		.rich-text-field-input-container {
			height: 100%;
			overflow: initial;
		}
	}

	.expand-description-text-toggle {
		text-transform: uppercase;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-top: 8px;
		cursor: pointer;
		color: $v2-text-gray;
		font-size: 9px;
		letter-spacing: 0.5px;

		.text-icon {
			display: flex;
			&:hover {
				color: $v2-branding-purple;
				.expand-icon {
					path {
						stroke: $v2-branding-purple;
					}
				}
			}

			.expand-icon {
				margin-left: 5px;
			}
		}
	}
}
