.zoom-menu {
	position: relative;
	&:focus {
		outline: none;
	}
	.jump-to {
		margin: 0;
		width: auto;
		@include secondary-button;
	}
	.zoom-out {
		margin: 0;
		background-image: url('images/components/scheduling/zoom-out.svg');
		background-size: 24px;
		background-position: center;
		background-repeat: no-repeat;
		&:hover {
			background-image: url('images/components/scheduling/zoom-out-active.svg');
		}
	}
	.zoom-in {
		margin: 0;
		background-image: url('images/components/scheduling/zoom-in.svg');
		background-size: 24px;
		background-position: center;
		background-repeat: no-repeat;
		&:hover {
			background-image: url('images/components/scheduling/zoom-in-active.svg');
		}
	}
	.zoom-button-wrapper {
		display: flex;
		.previous {
			width: 45px;
			cursor: pointer;
			background: url('images/sections/overview/previous.svg') no-repeat center;
			&:hover {
				background-image: url('images/sections/overview/previous-active.svg');
			}
			&:focus {
				outline: none;
			}
		}
		.next {
			width: 45px;
			cursor: pointer;
			background: url('images/sections/overview/next.svg') no-repeat center;
			&:hover {
				background-image: url('images/sections/overview/next-active.svg');
			}
			&:focus {
				outline: none;
			}
		}
	}
	.zoom-menu-drop-down {
		position: absolute;
		padding: 16px 20px 16px 20px;
		z-index: 9999;
		ul {
			padding: 0;
			margin: 0;
			list-style: none;
		}

		&.collapsed {
			display: none;
		}
		&.expanded {
			display: block;
		}
		&.zoom-wrapper {
			background-color: $color-white;
			border-radius: 5px;
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
			left: 82px;
			top: 40px;
		}
		&.custom-date-wrapper {
			left: -20px;
			top: 25px;
		}
		.zoom-drop-down {
			.option {
				background-color: $color-white;
				cursor: pointer;
				padding: 6px;
				text-align: center;
				text-transform: uppercase;
				font-size: 16px;
				font-weight: bold;
				letter-spacing: 0.6px;
				color: #212121;
				&:hover {
					color: $color-purple-hover-active;
				}
			}
		}
	}
}
