.budget-table {
	border-collapse: collapse;
	width: 100%;
	color: #535353;

	tr > td {
		padding: 6px 16px;
		min-width: 100px;
		&.expanded-row {
			padding-bottom: 10px;
		}
	}

	td {
		border: 1px solid $app-border-color;
		text-align: end;
		font-size: 13px;
		white-space: nowrap;

		&:first-of-type {
			text-align: start;
			font-weight: 500;
			color: $v2-text-gray;
		}

		&:last-of-type {
			.breakdown-table-row-name {
				font-weight: 500;
			}
		}
		&:first-of-type,
		&.totals-table:last-of-type {
			//make column width relative to its content but not smaller than 75px
			width: 1%;
			min-width: 130px;
			max-width: 130px;
		}

		&.totals-table {
			font-size: 13px;
		}

		&.highlighted {
			background-color: $v2-list-background-purple;

			.row {
				font-weight: 500;
			}
		}

		&.locked {
			color: $v2-text-light-gray;
		}

		&.disabled {
			color: $v2-text-light-gray;
		}

		.row {
			font-weight: 100;

			&.thick-text {
				font-weight: 500;
			}

			&.breakdown-table-row-name {
				padding: 5px 0;
				max-width: 110px;
				margin-left: auto;
			}

			&:not(:first-of-type) {
				padding: 5px 10px;
				padding-right: 0;
				border-top: 1px solid transparent; // added to compensate for the border in the row names column
				&.row-name {
					padding: 5px 10px;
					border: 1px solid $app-border-color;
					border-right: 0;
					background: white;
					width: calc(100% + 16px); //nice hack to take all the space to the right
					&:not(:last-of-type) {
						border-bottom: 0;
					}
					.subtitle {
						width: calc(100% + 10px);
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.role {
						transform: translateX(5px);
						width: calc(100% + 5px);
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
				.role {
					font-size: 10px;
					font-style: italic;
					font-weight: 100;
					margin-top: 5px;
				}
			}
			.name-expansion-wrapper {
				cursor: pointer;
				display: flex;
				justify-content: space-between;
				.name {
					text-overflow: ellipsis;
					overflow: hidden;
				}
				.expand-icon {
					margin-left: 5px;
				}
			}
		}

		&.totals-table:first-of-type {
			.row {
				font-weight: 500;
			}
		}
	}

	th {
		font-size: 9px;
		font-weight: 500;
		text-transform: uppercase;
		letter-spacing: 1px;
		height: 25px; // give the column name container a fixed width to avoid pushing the table down if there are 2 line in the column name
		// width: 1%; // take only the needed space
		width: 1px; // take the minumum space
		padding: 6px 16px;
		background: $v2-fixed-element-grey;
		border: 1px solid $app-border-color;

		&.totals-table {
			background: initial;
			border: 0;

			&:not(.two-lines) {
				.column-name {
					margin-top: 16px;
				}
			}

			.column-name {
				color: $v2-date-picker-button-border-color;
			}

			&.highlighted {
				.column-name {
					color: $v2-branding-purple;
				}
			}
		}

		&.highlighted:not(.totals-table) {
			border-top: 2px solid $v2-branding-purple;
			background-color: $v2-list-background-purple;
		}

		&.locked:not(.totals-table) {
			color: $v2-text-light-gray;
		}

		.locked-icon:not(.totals-table) {
			margin-right: 7px;
		}

		&.disabled {
			color: $v2-text-light-gray;
		}

		.column-name {
			width: max-content;
			margin-left: auto;

			div {
				display: flex;
				align-items: center;
				margin-left: auto;
			}

			.collapse-all {
				cursor: pointer;
			}

			.second-line {
				margin-top: 5px;
			}

			&:last-of-type {
				justify-content: flex-end; // EDGE fix
			}
			span {
				margin-left: 5px;
			}
		}
	}
}
