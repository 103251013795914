.project-files {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	height: 100%;
	flex: auto;
	&.empty {
		padding-bottom: 16px; //padding to make it consistent with other empty states
	}
	.add-button {
		@include white-button;
		width: auto;
		display: flex;
		flex-direction: row;
		align-items: center;
		div {
			height: 14px;
			width: 20px;
			margin-left: 5px;
			&.expanded {
				background: url('images/up-arrow.svg') center no-repeat;
			}

			&.collapsed {
				background: url('images/header/expanded.svg') center no-repeat;
			}
		}
	}
	.add-options-wrapper {
		position: relative;
		.add-options-select {
			max-width: 240px;
			border: 1px solid $app-border-color;
			position: absolute;
			z-index: 99;
			right: 0px;
			top: 35px;
			overflow: auto;
			width: 250px;
			background-color: $color-white;
			padding: 16px 0;
			display: flex;
			flex-direction: column;
			flex: 1 1 auto;
			max-height: 400px;
			overflow: hidden;
			padding: 10px;
			//font-size: 15px;
			//box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
			box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
			transition: transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
			transform: scaleY(1);
			transform-origin: left top 0px;
			-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
			.option {
				text-decoration: none;
				&.has-border {
					border-bottom: 1px solid $app-border-color;
				}
				&.add-padding {
					padding: 15px 0;
				}
				cursor: pointer;
				&:hover {
					color: $color-purple-hover-active;
				}
			}
		}
	}

	.input-file + label {
		//@include primary-button;
		min-width: 218px;
		display: block;
		cursor: pointer;
		padding: 15px 0;
		//margin-left: 32px;
	}
	.input-folder {
		//@include primary-button;
		///width: auto;
		//margin-left: 32px;
	}
	.input-file {
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
	}
	.project-files-header {
		.forecast-actions {
		}
	}

	.section-body {
		.content-container {
			@include content-frame;
			margin-bottom: 15px;
			display: flex;
			flex-direction: row;
			background: $color-white;
			&.empty {
				height: 100%;
			}
			.file-tables,
			.file-tables-over {
				padding: 0 20px 20px 20px;
				display: flex;
				flex: 1;
				flex-direction: column;
				.inner {
					.empty-state-image-container {
						display: flex;
						flex-direction: column;
						align-items: center;
					}
					.navigation-bar {
						display: flex;
						.level-button-wrapper {
							display: flex;
							align-items: center;
							position: relative;
							.separator {
								width: 16px;
								height: 16px;
								margin: 0 10px;
								background: url('images/sections/project/files/folder-level-arrow.svg') center no-repeat;
								background-size: 16px 16px;
							}
							.action-dropdown {
								@include select-list;
							}
						}
						.title {
							padding: 8px;
							border-radius: 3px;
							border: none;
							display: flex;
							align-items: center;
							outline: none;
							&:hover {
								background-color: $color-medium-gray;
								cursor: pointer;
							}
							&.is-over {
								background-color: $bulk-update-selected-color;
							}
							.action-arrow {
								margin-left: 5px;
								width: 16px;
								height: 16px;
								background: url('images/header/expanded-grey.svg') center no-repeat;
								background-size: 16px 16px;
							}
						}
					}
				}
				.flex-container {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					.folder-display {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
						.back-control {
							display: flex;
							flex-direction: row;
							align-items: center;
							margin: 0px 0px 0px -10px;
							.back-arrow {
								background-image: url('images/header/expanded.svg');
								width: 40px;
								height: 40px;
								background-size: 40px 40px;
								padding: 0px 0px 0px 0px;
								background-repeat: no-repeat;
								transform: rotate(90deg);
							}
						}
						.back-control:not(.active) .back-arrow {
							visibility: hidden;
						}
						.back-control.active {
							visibility: visible;
							&:hover {
								cursor: pointer;
								color: $color-purple-hover-active;
								.back-arrow {
									background-image: url('images/header/expanded-active.svg');
								}
							}
						}
					}
					.new-file-info {
						float: right;
						color: #cacaca;
						margin-right: 32px;
					}
				}

				@include standard-table;
				.file-format {
					span {
						display: none;
						visibility: hidden;
					}
					width: 50px;
					background-repeat: no-repeat;
					background-position: center center;
					@include file-format-icons;
				}
				table {
					th {
						text-align: left;
					}
					th.selector {
						width: 50px;
					}
					th.row-format {
						text-align: center;
					}
					th.row-action-menu {
						width: 30px;
					}
				}
				.files-table {
					width: 100%;
					cursor: pointer;
					.name,
					.row-name {
						padding-left: 26px;
					}
					.drag-handle {
						background: url('images/cross-section-icons/table/drag-handle.svg') no-repeat;
						background-size: 20px;
						background-position: center left;
						cursor: move;
					}
					.drag-column {
						width: 20px;
					}
					.bulk-update-checkbox {
						padding-left: 10px !important;
						display: flex;
						flex: 0 0 20px;
						height: 30px;
						align-items: center;
						justify-content: center;
					}
					.table-row {
						height: 55px;
						&.marked {
							background-color: $bulk-update-selected-color;
						}
					}
					.action-menu-container {
						margin-left: 0;
					}
					@include gear-menu;
					.gear-menu {
						margin-top: 8px;
						.gear-menu-drop-down {
							top: 20px;
							right: 80%;
							.settings-drop-down {
								li {
									color: $color-black;
								}
								.option {
									text-decoration: none;
									&.download-link-option {
										padding: 10px;
									}
									a {
										text-decoration: none;
									}
									.download-action {
										width: 100%;
									}
									&:hover {
										a {
											color: $color-purple-hover-active;
										}
									}
								}
							}
						}
					}
				}
			}

			.file-details {
				flex: 0 1 350px;
				display: flex;
				flex-direction: column;
				.details-content {
					flex: 1;
					display: flex;
					flex-direction: column;
					.title-wrapper {
						font-size: 1.5em;
						order: 2;
						padding: 0 15px 15px 15px;
					}
					.preview-wrapper {
						order: 1;
						position: relative;
						margin: 15px;
						padding: 10px;
						@include content-frame;
						.image-wrapper {
							min-height: 200px;
							min-width: 200px;
							max-height: 400px;
							max-width: 400px;
							display: flex;
							align-items: center;
							justify-content: space-around;
							img {
								max-height: 400px;
								max-width: 400px;
							}
						}

						.file-format {
							height: 200px;
							width: 290px;
							span {
								display: none;
								visibility: hidden;
							}
							background-size: 70%;
							background-repeat: no-repeat;
							background-position: center center;
							@include file-format-icons;
						}

						.zoom-button {
							display: none;

							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);

							padding: 10px 15px;
							border: 1px solid $app-border-color;
							border-radius: 50px;
							background-color: rgba(255, 255, 255, 0.9);
							-webkit-backdrop-filter: blur(10px);
							-moz-backdrop-filter: blur(10px);
							-ms-backdrop-filter: blur(10px);
							backdrop-filter: blur(10px);

							font-size: 18px;
						}

						&:hover {
							cursor: pointer;

							img {
								opacity: 0.8;
							}

							.zoom-button {
								display: block;
							}
						}
					}

					.meta-data {
						order: 2;
						flex: 1;
						padding: 0 15px;
						margin-bottom: 1rem;

						.meta-person {
							padding-top: 20px;
							.person {
								display: flex;

								img {
									flex: 0 0 auto;
									margin-top: 3px;
									border-radius: 30px;
									border: 1px solid $app-border-color;
									width: 24px;
									height: 24px;
								}

								.person-info {
									flex: 1;
									padding-left: 15px;
									overflow: hidden;
									span {
										display: block;

										&.name {
											font-size: 16px;
											font-weight: 300;
										}

										&.role {
											font-size: 11px;
											font-weight: $base-font-medium-weight;
										}
									}
								}
							}
						}

						.meta-file_info {
							padding-left: 39px;
							background-repeat: no-repeat;
							background-position: left center;
							@include file-format-icons;

							span {
								display: block;
								height: 25px;
								&.upload-date {
									font-size: 13px;
									padding-top: 5px;
								}
							}
						}

						.meta-belongs_to {
							padding-top: 15px;

							p {
								display: block;
								min-height: 24px;
								padding-top: 1px;
								cursor: pointer;
								&.task {
									background: url('images/sections/project/files/task.svg') no-repeat left top;
									padding-left: 39px;
								}
								&:hover {
									color: $color-purple-hover-active;
								}
							}
						}
					}
				}

				.buttons {
					padding: 10px 15px;
					display: flex;
					.download-action {
						@include secondary-button;
						text-decoration: none;
						margin-right: 10px;
					}
					.delete-link-container {
						@include secondary-button;
					}
				}
			}
		}
	}
}
