.project-integration-section {
	.integration-title-row {
		display: flex;
		flex-direction: row;
		align-items: center;

		h3 {
			text-align: center;
			margin: 0 6px;
		}
	}

	.integration-section-body {
		margin-top: 12px;
		.unlink-button {
			margin-top: 12px;
		}

		.dropdown-container {
			width: 500px;
		}
	}
}
