.toggle-component {
	position: relative;
	display: inline-block;
	width: 35px;
	height: 14px;
	cursor: pointer;
	&.locked {
		cursor: default;
		input[type='checkbox'] + span {
			cursor: default;
		}
	}
}

/*  hide default HTML checbox */
.toggle-component input {
	display: none;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #a8a7a7;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	&:hover {
		background-color: #8a8a8a;
	}
}

.slider:before {
	position: absolute;
	content: '';
	height: 21px;
	width: 21px;
	left: 0px;
	right: 0px;
	bottom: -3px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	box-shadow: 0px 1px 2px 0px #d9d9d9;
	border: 1px solid $app-border-color;
}

input:checked + .slider {
	background-color: #ff77ee;
	&:hover {
		opacity: 0.9;
	}
}
input:focus + .slider {
	box-shadow: 0 0 1px #ff77ee;
	&:hover {
		opacity: 0.9;
	}
}

input:checked + .slider:before {
	background-color: $brand-color-accent;
	border: 1px solid $brand-color-accent;
	&:hover {
		opacity: 0.9;
	}
}

input:focus + .slider:before {
	box-shadow: 0 0 1px $brand-color-accent;
	border: 1px solid $brand-color-accent;
	&:hover {
		opacity: 0.9;
	}
}

input:checked + .slider:before {
	-webkit-transform: translateX(14px);
	-ms-transform: translateX(14px);
	transform: translateX(14px);
}

.slider.round {
	border-radius: 14px;
}

.slider.round:before {
	border-radius: 50%;
}
