.task-modal-v3-label-section {
	min-height: 57px;
	display: flex;
	padding-top: 15px;
	padding-bottom: 11px;
	margin: 0 58px;

	.dropdown-container {
		width: 190px;
		height: 30px;
		margin-bottom: 9px;

		background-size: 16px;
		background-position: 15px 50%;
		background-repeat: no-repeat;
		background-image: url('images/v2/task-modal/label.svg');

		input {
			padding-left: 35px;
		}
	}

	.labels-icon {
		margin-top: 4px;
		margin-left: 58px;
		flex-shrink: 0;
	}

	.labels-text {
		margin-left: 8px;
		margin-right: 40px;
		margin-top: 7px;
		text-transform: uppercase;
		font-size: 12px;
		color: #535353;
		flex-shrink: 0;
		flex-grow: 0;
	}

	.label-picker {
		margin-right: 12px;
		margin-top: 5px;
		height: 100%;
		width: 602px;
	}

	.expansion-button {
		align-self: center;
		right: 27px;
	}
}
