.toaster {
	.toast {
		z-index: 999;
		position: fixed;
		left: 24px;
		bottom: -100px;
		min-width: 288px;
		max-width: 568px;
		border-radius: 2px;
		min-height: 48px;
		color: white;
		background-color: #323232;
		font-size: 14px;
		transition: 0.2s bottom;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		&.displaying {
			bottom: 24px;
		}
		.message {
			padding: 14px 24px 14px 24px;
			span {
				margin-right: 5px;
				position: relative;
				top: 2px;
			}
		}
		.action {
			cursor: pointer;
			padding: 14px 24px 14px 24px;
			color: #4a90e2;
			white-space: nowrap;
			text-decoration: underline;
			text-decoration-color: #4a90e2;
		}
	}
}
