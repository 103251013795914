.expand-icon {
	&:hover {
		path {
			stroke: $color-black;
		}
	}

	&:active {
		path {
			stroke: $color-martin-tertiary-light-purple;
		}
	}

	&.left {
		transform: rotate(90deg);
	}

	&.right {
		transform: rotate(-90deg);
	}
}
