.project-scheduling-modal-v2 {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	z-index: 100;
	background: rgba(0, 0, 0, 0.6);
	display: flex;
	justify-content: center;
	align-items: center;
	.project-scheduling-modal-content {
		background-color: white;
		position: relative;
		padding: 32px 58px;
		.close-button {
			border: none;
			width: 14px;
			height: 14px;
			background-size: 14px;
			background-image: url('images/components/close-modal.svg');
            background-repeat: no-repeat;
			cursor: pointer;
			position: absolute;
			top: 16px;
			right: 16px;
		}
		.top-section {
			display: flex;
			flex-direction: row;
			margin-bottom: 16px;
			.modal-title {
				font-size: 11px;
				font-weight: bold;
				letter-spacing: 0.5px;
			}
			.date-section {
				margin-left: auto;
				margin-top: 4px;
				display: flex;
				flex-direction: row;
				font-size: 11px;
				font-weight: 500;
				color: $v2-text-light-gray;
				.end-date {
					text-align: right;
				}
				.start-date,
				.end-date {
					display: flex;
					flex-direction: column;
				}
				.separator {
					margin: 0 8px;
				}
			}
		}
		.name-section {
			display: flex;
			flex-direction: row;
			width: 500px;
			.project-id-chip {
				height: 20px;
				padding: 0 8px;
				border-radius: 3px;
				flex-shrink: 0;
				font-size: 12px;
				font-weight: 500;
				line-height: 20px;
				margin-right: 16px;
				color: white;
				&.dark-text {
					color: $v2-text-gray;
				}
				&.project-group {
					padding-left: 26px;
					background-image: url('images/components/scheduling/connected-project-icon-white.svg');
					background-size: 14px;
					background-repeat: no-repeat;
					background-position: left 8px center;
				}
			}
			.label-section {
				display: flex;
				flex-direction: column;
				justify-content: center;
				flex-grow: 1;
				.main-label {
					font-size: 16px;
					font-weight: 500;
				}
				.sub-label {
					font-size: 12px;
				}
			}
		}
		table {
			margin-top: 16px;
			width: 500px;
			thead {
				font-size: 10px;
				font-weight: 600;
				letter-spacing: 1px;
				color: #a1a1a1;
				text-transform: uppercase;
				.number-column {
					padding-bottom: 8px;
				}
			}
			.number-column {
				width: 100px;
				padding-right: 16px;
			}
			.role-name-column {
				width: 160px;
				padding-left: 16px;
			}
			.button-column {
				width: 180px;
				padding-left: 20px;
				.custom-button-container {
					height: 40px;
					width: 160px;
					text-transform: uppercase;
				}
			}
			.button-column-placeholder {
				width: 1px;
				.button-placeholder {
					height: 40px;
					width: 1px;
				}
			}
			tbody {
				tr:nth-child(odd) {
					background-color: #f3f3f3;
				}
				td {
					font-size: 14px;
					color: #5e636f;
					padding: 0;
				}
			}
		}
	}
}

.project-group-duplicate-modal {
	margin-top: 24px;
	.date-range-picker-wrapper-new {
		margin-top: 24px;
		margin-bottom: 24px;
	}
	.option-row {
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		margin-bottom: 4px;
		.option-text {
			flex-grow: 1;
		}
		.check-box {
			flex-shrink: 0;
			margin-right: 8px;
		}
	}
}
