.sync-jira-project-modal {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	max-height: calc(90vh - 181px); // Match 90vh of modal, minus the padding, title, and controls of the modal
	.input-container-v2 {
		.input-value-container {
			height: 30px;
			.input-value-unit {
				display: flex;
				align-items: center;
			}
		}
	}
	.inner-sync-jira-project-modal {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding-right: 14px;
	}

	.sync-jira-project-modal-section {
		border-bottom: 1px solid $color-martin-dependency-gray;
		padding-bottom: 24px;
		display: flex;
		width: 920px;
		&.column {
			flex-direction: column;
			.drop-down-v2 {
				width: 60%;
			}
		}
		.column {
			display: flex;
			&.main {
				flex: 0 0 60%;
				margin-right: 8px;
				flex-direction: column;
			}
			.date-picker-label {
				margin-bottom: 5px;
			}
			.input-container-v2 {
				.input-value-container {
					height: 30px;
					.input-value-unit {
						line-height: 28px;
					}
				}
			}
			input[type='text'],
			.input-value-container,
			.buttons-wrapper,
			.date-picker-button {
				height: 30px;
			}
			.drop-down-v2,
			.radio-button-controls,
			.color-picker-controls,
			.dates-controls,
			.input-container-v2 {
				margin-top: 24px;
			}
			.color-picker-controls {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				margin-left: 24px;
			}
		}
		.sync-jira-project-options {
			display: flex;
			margin-top: 16px;
			margin-bottom: 10px;
			.sync-option {
				padding: 8px 16px;
				margin-right: 8px;
				font-size: 13px;
				color: $v2-text-gray;
				background-color: $v2-fixed-element-grey;
				border-radius: 20px;
				display: flex;
				border: none;
				outline: none;
				.selected,
				.not-selected {
					width: 13px;
					height: 13px;
					background-position: center;
					background-repeat: no-repeat;
					margin-right: 8px;
				}
				.selected {
					background-size: 16px;
					background-image: url('images/v2/ok-green.svg');
				}
				.not-selected {
					width: 13px;
					height: 13px;
					background-size: 45px;
					background-image: url('images/loaders/loader-animation.svg');
				}
				.count {
					font-weight: 700;
					padding-right: 3px;
				}
			}
		}
		.sync-jira-project-info {
			font-size: 11px;
			color: $v2-text-light-gray;
		}
		.controls-label {
			margin-bottom: 5px;
		}
		.radio-button-controls {
			.radio-button-wrapper {
				margin-top: 10px;
				align-items: center;
			}
		}
		.sync-epic-worklog-warning {
			font-size: 11px;
			color: $v2-red;
		}
		.dates-controls {
			display: flex;
			gap: 32px;
			align-items: flex-end;
		}
		.cutoff-date-controls {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			flex: 1 1 auto;
			.cutoff-title {
				display: flex;
				align-items: flex-end;
				.controls-label {
					padding-right: 2px;
				}
			}
			.cutoff-date-selector {
				padding-top: 7px;
				.cutoff-date-description {
					font-size: 11px;
					color: $v2-text-light-gray;
				}
				.cutoff-date-error-description {
					font-size: 11px;
					color: $v2-red;
					padding-top: 4px;
				}
				.cutoff-date-picker {
					padding-top: 4px;
				}
			}
		}
	}

	.more-settings {
		display: flex;
		align-items: center;
		font-size: 13px;
		text-align: left;
		cursor: pointer;
		color: $v2-text-gray;
		letter-spacing: 1px;
		margin-left: auto;
		margin-top: 8px;
		margin-bottom: 17px;
		&:hover {
			color: $v2-branding-purple;
		}
		.arrow {
			width: 10px;
			height: 5px;
			margin-left: 5px;
			background-size: 10px;
			background-position: center;
			background-image: url('images/v2/components/dropdown/arrow-down.svg');
			&.expanded {
				background-image: url('images/v2/components/dropdown/arrow-up.svg');
			}
		}
	}
	.info-label,
	.radio-button-wrapper,
	.label {
		font-size: 12px;
		color: $v2-text-gray;
	}
	.controls-label,
	.label {
		font-size: 9px;
		font-weight: 500;
		line-height: 1.11;
		letter-spacing: 1px;
		color: $v2-text-light-gray;
		text-transform: uppercase;
	}
	.project-third-info {
		width: 100%;
	}
	.deep-fetch-boards {
		font-size: 13px;
		text-align: left;
		cursor: pointer;
		color: $v2-text-gray;
		letter-spacing: 1px;
		margin: 4px 0 0 4px;
		&:hover {
			color: $v2-branding-purple;
		}
	}
}
