.allocation-modal-v2 {
	width: 580px;
	.parent-dropdown-container {
		.drop-down-v2 {
			width: 100%;
			margin-top: 16px;
		}
	}
	.person-date-row,
	.total-percentage-row {
		display: flex;
		flex-direction: row;
		margin-top: 24px;
	}
	.person-date-row {
		.drop-down-v2 {
			flex: 1 0 auto;
		}
		.date-range-picker-wrapper-new,
		.fixed-range-picker-wrapper-new {
			margin-left: 8px;
			flex: 0 0 auto;
		}
	}
	.total-percentage-row {
		.input-container-v2 {
			width: 113px;
		}
		.no-working-hours-warning {
			font-size: 12px;
			padding: 10px;
			color: red;
			max-width: 254px;
		}
		.percentage-distribution-container {
			display: flex;
			flex-direction: row;
			width: 220px;
			margin-left: auto;
		}
		.percentage-input-container {
			flex-grow: 1;
			.input-container-v2 {
				width: 100%;
			}
		}
		.distribution-button-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-top: 16px;
			flex-shrink: 0;
			.distribution-toggle-button {
				border: 0;
				background-image: url('images/components/scheduling/expand.svg');
				background-position: right center;
				background-size: 12px;
				background-repeat: no-repeat;
				padding: 0 20px 0 8px;
				font-size: 12px;
				font-weight: 600;
				outline: 0;
				&.expanded {
					background-image: url('images/components/scheduling/collapse.svg');
				}
			}
		}
	}
	.distribution-row {
		margin-top: 24px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		.input-container-v2 {
			width: 78px;
		}
		> div:not(:first-child) {
			margin-left: 8px;
		}
	}
	.time-registration-row {
		margin-top: 24px;

		.time-registration-label {
			height: 11px;
			font-size: 9px;
			font-weight: 500;
			line-height: 1.22;
			text-transform: uppercase;
			letter-spacing: 1px;
			margin-bottom: 5px;
			white-space: nowrap;
			font-stretch: normal;
			font-style: normal;
			color: $v2-text-light-gray;
		}

		.time-registration-description {
			font-size: 13px;
			margin-bottom: 16px;
		}

		.time-registration-choice-yes,
		.time-registration-choice-no {
			font-size: 13px;
			margin-bottom: 10px;

			.radio-button-wrapper {
				align-items: center;
			}
		}
	}
	.notes-container {
		margin-top: 24px;
	}
}
