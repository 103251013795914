.switch-wrapper {
	display: flex;
	flex-direction: column;
	position: relative;
	.switch-container {
		//width: 50px !important;
		height: 26px;
		border: 2px solid transparent;
		border-radius: 13px;
		background-color: $v2-grey;
		position: relative;
		display: inline-block;
		cursor: pointer;
		-webkit-transition: 0.2s;
		transition: 0.2s;
		outline: none;
		display: flex;
		flex-direction: column;
	}
	.switch-label {
		margin-top: -14px;
		margin-bottom: 4px;
		.empty-persons-header {
			font-size: 10px;
			font-weight: 500;
			color: $v2-branding-purple;
			text-transform: uppercase;
		}
		width: 100%;
		white-space: nowrap;
	}
	.locked-switch {
		opacity: 0.5;
	}

	.toggled-on {
		background-color: $v2-branding-purple;
	}

	.switch-box {
		display: none;
	}

	.switch-slider {
		position: absolute;
		cursor: pointer;
		top: 50%;
		transform: translateY(-50%);
		left: 3px;
		right: 0;
		bottom: 0;

		height: 20px;
		width: 20px;

		background-color: #ffffff;
		border-radius: 26px;

		-webkit-transition: 0.2s;
		transition: 0.2s;
	}

	.toggled-on .switch-slider {
		left: 80%;
		transform: translate(-60%, -50%);
	}

	.switch-container:focus .switch-slider {
		background-color: #a9a9a9;
	}
	&.white {
		.switch-container:not(.toggled-on) {
			background-color: white;
			border-color: $v2_backdrop_gray;
			.switch-slider {
				background-color: $v2_backdrop_gray;
			}
		}
	}
}
