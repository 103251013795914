.change-cost-rate-modal-budget {
	width: 688px;
	font-size: 13px;
	color: $v2-text-gray;

	.content > div {
		margin-bottom: 10px;
	}

	.fixed-cost {
		display: flex;
		align-items: center;

		.input-container-v2 {
			width: 108px;
			margin: 0 10px;
		}
	}
}
