.filter-section-v2 {
	outline: none;
	max-width: 108px;
	position: relative;
	.filter-open-section {
		display: flex;
		flex: 0 0 auto;
		.filter-button {
			width: 108px;
			height: 30px;
			border: solid 1px $app-border-color;
			font-size: 10px;
			font-weight: bold;
			letter-spacing: 0.5px;
			color: $v2-text-gray;
			text-transform: uppercase;
			outline: none;
			&:hover {
				border-color: $v2-date-picker-button-border-color;
			}
			&.active {
				border-color: $v2-branding-purple;
			}
			&.no-text {
				width: 56px;
				.filter-icon {
					margin-right: 0px;
				}
				.selected-filter-count {
					width: 16px;
					height: 16px;
					margin-right: 5px;
				}
			}
			&.no-border {
				border-color: transparent;
				border-bottom: none;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				&:hover {
					background-color: rgba(0, 0, 0, 0.2);
				}
			}
			&.expanded {
				border-color: $v2-branding-purple;
				border-bottom: none;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				//box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
			}
			.filter-button-content-wrapper {
				display: flex;
				flex: 1 1 auto;
				align-items: center;
				justify-content: center;
			}
			.filter-icon {
				width: 20px;
				height: 15px;
				background-size: contain;
				background-image: url('images/v2/components/filter/filter-icon.svg');
				margin-right: 10px;
				&.purple {
					background-image: url('images/v2/components/filter/filter-icon-purple.svg');
				}
			}
			.selected-filter-count {
				width: 20px;
				height: 20px;
				border-radius: 3px;
				margin-right: 10px;
				color: white;
				background-color: $v2-branding-purple;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.selected-filter-count-overlay {
				position: absolute;
				right: -3px;
				top: -3px;
				font-size: 10px;
				font-weight: bold;
				letter-spacing: 0.5px;
				min-width: 15px;
				max-width: 24px;
				height: 15px;
				border-radius: 3px;
				color: white;
				background-color: $v2-branding-purple;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
	.filter-section {
		display: flex;
		flex-direction: column;
		background-color: white;
	}

	.filter-section-inner {
		background-color: $color-white;
		position: absolute;
		z-index: 99;
		display: flex;
		flex-direction: column;
		border: solid 1px $v2-branding-purple; // $app-border-color;
		&.expand-right {
			right: 0;
		}
		&.expand-left {
			left: 0;
		}
		.clear-filter-button-container {
			.clear-filter-button {
				border: none;
				font-size: 12px;
				font-weight: 500;
				line-height: 0.92;
				color: $v2-text-gray;
				width: auto;
				padding: 0;
				margin-left: 16px;
				padding: 8px 0;
				&:hover {
					color: $v2-branding-purple;
				}
			}
		}
	}

	.filter-options {
		display: flex;
		flex: 0 0 auto;
		padding: 10px 15px;
		align-items: center;
		justify-items: center;
		/* The below necessary to prevent weird animation issues with z-indexes,
 		 where elements that return after a transition end up below other elements.
  		*/
		-webkit-animation-fill-mode: none !important;
		animation-fill-mode: none !important;
		animation-duration: 1s;
		flex-direction: row;
		button {
			height: 34px;
			text-transform: uppercase;
			margin-left: auto;
			padding: 0 24px;
			flex-shrink: 0;
		}
		.drop-down-v2 {
			&:first-child {
				margin-right: 10px;
			}
		}
	}

	.active-filters {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		padding: 0 15px 10px 15px;
	}

	.active-filter {
		margin: 0 4px 4px 0;
		font-size: 10px;
		max-height: 26px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-items: center;
		border-radius: 13px;
		background-color: $app-border-color;
		max-width: 100%;
		.active-filter-text {
			min-width: 0;
			flex: 1 1 auto;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			font-size: 10px;
			font-weight: 500;
			color: $v2-text-gray;
			margin-left: 10px;
			padding: 5px 0;
		}
		button {
			flex-shrink: 0;
			width: 7px;
			height: 7px;
			background-size: contain;
			margin-left: 8px;
			background-image: url('images/v2/components/header/x-black.svg');
			background-repeat: no-repeat;
			cursor: pointer;
			border: none;
			margin-right: 10px;
		}
	}

	.active-filter-text {
		white-space: nowrap;
		text-align: center;
		color: black;
		font-weight: $base-font-medium-weight;
		font-family: $text-font-family;
	}
	.hidden {
		display: none;
	}
}
