.settings-roles {
	&.section-content {
		margin: 0;
		.section-body {
			margin: 16px 24px;
		}
	}
	.submit-button {
		@include primary-button;
	}
	table {
		width: auto !important;
		tbody {
			tr {
				text-align: right;
				.controls {
					min-width: 150px;
					white-space: nowrap;
					padding-right: 20px;
					span {
						background-size: 16px;
						background-repeat: no-repeat;
						background-position: left center;
						padding: 0 10px 0 21px;
						cursor: pointer;
						&.remove {
							background-image: url('images/cross-section-icons/actions/remove.svg');
							&:hover {
								color: $color-purple-hover-active;
							}
						}
						&.prompt {
							cursor: default;
							padding: 0 5px 0 0;
							span {
								padding: 0 5px 0 0;
								&.confirm {
									cursor: pointer;
									&:hover {
										color: $color-purple-hover-active;
									}
								}
							}
						}
					}
				}
				.controls span {
					visibility: hidden;
				}
				&:hover {
					.controls span {
						visibility: visible;
					}
					.assigned {
						color: $text-base-color;
					}
				}
				input {
					width: 400px;
				}
			}
		}
	}
}
