.new-ui-actions-menu {
	height: 30px;
	position: relative;

	&.hidden {
		opacity: 0;
	}
	&.disabled {
		user-select: none;
	}

	.actions {
		height: 30px;
		width: 18px;
		background: url('images/components/overlay/navigation-show-more-vertical.svg') center center no-repeat;
		border-radius: 4px;
		border: none;
		outline: 0;
		cursor: pointer;
		&.no-border-radius-styling {
			border-radius: 0;
		}
		&.white {
			background-image: url('images/components/overlay/navigation-show-more-vertical-white.svg');
		}
		&.green {
			background-image: url('images/components/overlay/navigation-show-more-vertical-green.svg');
		}
		&.black-icon {
			background-image: url('images/components/overlay/navigation-show-more-vertical-black.svg');
		}
		&.grey-icon {
			background-image: url('images/components/overlay/navigation-show-more-vertical.svg');
		}
		&.disabled-icon {
			background-image: url('images/components/overlay/navigation-show-more-vertical-disabled.svg');
			&:hover,
			&:focus {
				background-color: inherit;
				&.expanded {
					background-color: inherit;
				}
			}
			&.white-inner {
				&:focus,
				&:hover {
					background-color: inherit;
				}
				&.expanded {
					background-color: inherit;
				}
			}
		}
		&.horizontal-icon {
			background-image: url('images/components/overlay/navigation-show-more-horizontal-black.svg');
			padding: 0 3px;
			width: 28px;
			&.white {
				background-image: url('images/components/overlay/navigation-show-more-horizontal-white.svg');
			}
		}

		&:hover,
		&:focus {
			background-color: rgba(0, 0, 0, 0.3);
			&.expanded {
				background-color: $v2-dark-grey;
			}
		}
		&.white-inner {
			&:focus,
			&:hover {
				background-color: $v2-backdrop-gray;
			}
			&.expanded {
				background-color: $v2-backdrop-gray;
			}
		}
		&.expanded {
			background-color: $v2-dark-grey;
			border-radius: 4px 4px 0px 0px;
			background-image: url('images/components/overlay/navigation-show-more-vertical-white.svg');
			&.on-top {
				border-radius: 0px 0px 4px 4px;
			}
			&.white-inner {
				background-color: $v2-backdrop-gray;
				background-image: url('images/components/overlay/navigation-show-more-vertical.svg');
				background-position: center center;
				background-repeat: no-repeat;
				border-radius: 4px;
			}
			&.horizontal-icon {
				background-image: url('images/components/overlay/navigation-show-more-horizontal-black.svg');
				&.white {
					background-image: url('images/components/overlay/navigation-show-more-horizontal-white.svg');
				}
			}
			&.no-border-radius-styling {
				border-radius: 0;
			}
		}
	}
}

.root-portal-container {
	.with-slider {
		display: flex;
		flex-direction: row;
		.text-tooltip-wrapper {
			flex-grow: 1;
			margin-right: 8px;
		}
		.switch-wrapper {
			flex-grow: 0;
		}
	}
	.option-group {
		.opt-hotkey {
			font-style: italic;
			margin-top: 2px;
			font-size: 11px;
			color: $v2-text-light-gray;
		}
	}
	ul.actions-dropdown {
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
		border: 1px solid #e6e6e6;
		position: absolute;
		z-index: 10000;
		display: none;
		border-radius: 4px;
		background-color: $v2-dark-grey;
		color: $color-white;
		margin: 0;
		padding: 0;
		list-style: none;

		&.expanded {
			display: inline;

			&.with-title {
				border-top-left-radius: 0px;
				border-bottom-left-radius: 0%;
				box-shadow: none;
			}
		}
		&.on-top {
			border-radius: 4px 4px 0px 4px;
			li:first-child {
				border-radius: 4px 4px 0px 0px;
			}
			li:last-child {
				border-radius: 0px 0px 0px 4px;
			}
		}
		//styling used in project section header
		&.no-border-radius-styling {
			border-radius: 0;
			box-shadow: none;
			min-width: 110px;
			li {
				font-weight: 400;
			}
			&.on-top {
				border-radius: 0;
				li:first-child {
					border-radius: 0;
				}
				li:last-child {
					border-radius: 0;
				}
			}
		}
		li {
			&:first-child:not(.title-description-wrapper) {
				margin-top: 5px;
			}
			&:last-child {
				margin-bottom: 5px;
			}
			cursor: pointer;
			font-size: 14px;
			font-weight: normal;
			padding: 6px 16px 6px 16px;
			white-space: nowrap;
			outline: none;
			min-width: 190px;

			&.title-description-wrapper {
				border-bottom: 1px solid $app-border-color;
				color: #535353;
				cursor: default;

				.title {
					font-size: 13px;
					font-weight: 500;
				}

				.description {
					font-size: 12px;
					margin: 4px 0;
				}
			}

			&:hover:not(.title-description-wrapper),
			&:focus:not(.title-description-wrapper) {
				color: $color-white;
				background-color: $v2-create-button-green;
			}

			&.locked-option {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;

				span {
					color: $v2-text-light-gray;
				}
			}
		}
		&.white {
			box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
			background-color: $color-white;
			li {
				font-family: $text-font-family;
				font-size: 12px;
				color: $v2-text-gray;
				&:hover:not(.title-description-wrapper),
				&:focus:not(.title-description-wrapper) {
					background-color: $v2-list-hover-purple;
					color: $v2-branding-purple;
					cursor: pointer;
				}
			}
		}
	}

	// style for the nested dropdown
	.action-menu-nested-dropdown {
		z-index: 800; //place it on top of the task modal
		width: 253px;

		.custom-scrollbar-div {
			flex-direction: column;
		}

		.nested-dropdown-options-wrapper {
			border: 1px solid $app-border-color;
			border-radius: 4px;
			box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
		}
	}
}
