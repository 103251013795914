.file {
	margin-bottom: 8px;
	margin-right: 8px;

	&:hover {
		.file-name {
			color: $v2-branding-purple;
			.download .download-icon {
				opacity: 1;
				svg path {
					fill: $v2-branding-purple;
				}
			}
			.new-ui-actions-menu #actions-btn {
				opacity: 1;
			}
			p {
				transform: translateX(15px);
			}
		}
	}

	&:nth-child(7n) {
		margin-right: 0;
	}

	.file-image {
		width: 108px;
		height: 77px;
		position: relative;

		img {
			box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05);
			border-radius: 4px;
		}

		&:hover {
			border-color: $v2-branding-purple;
			.file-options {
				opacity: 1;
			}
		}

		.image-is-cover-icon {
			position: absolute;
			width: 20px;
			height: 20px;
			top: 3px;
			left: 4px;
		}

		.file-format {
			width: 100%;
			height: 100%;
			@include file-format-icons;
			background-position: center;
			background-repeat: no-repeat;
			border: 1px solid #e6e6e6;
			background-color: #f3f3f3;
			box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05);
			border-radius: 4px;
		}

		.file-options {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			background-color: rgba(255, 255, 255, 0.7);
			opacity: 0;
			transition: all 0.1s linear;
			display: flex;

			.file-option-container {
				flex: 1 0 0%;
				cursor: pointer;
				border: 1px solid $v2-branding-purple;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 4px;

				.full-screen-icon {
					background-image: url('images/v2/task-modal/expandpicicon-black.svg');
					width: 16px;
					height: 16px;
					background-size: 16px;
					background-repeat: no-repeat;
				}

				&:hover {
					.full-screen-icon {
						background-image: url('images/v2/task-modal/expandpicicon-purple.svg');
					}
				}
			}
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.file-name {
		font-size: 12px;
		line-height: 1.1;
		color: $v2-text-light-gray;
		width: 108px;
		height: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 2px;
		position: relative;

		input[type='text'] {
			width: 100%;
			color: initial;
			outline: none;
			padding: 0 4px;

			&:focus {
				border-radius: 4px;
				border: 1px solid $v2-branding-purple;
			}
		}

		.download {
			.download-icon {
				width: 9px;
				height: 18px;
				margin: 0 2px;
				opacity: 0;
				transition: opacity 0.2s;

				svg {
					width: 9px;
				}
			}
		}

		.new-ui-actions-menu {
			height: 20px;
			#actions-btn {
				height: inherit;
				width: 20px;
				background-size: contain;
				opacity: 0;
			}
		}

		input {
			border: none;
			width: 75px;
		}

		p {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			margin: 0;
			position: absolute;
			width: 70px;
			transition: all 0.2s;
		}

		.delete-icon {
			width: 12px;
			height: 12px;
			position: absolute;
			right: 0;
			background-image: url('images/components/close-modal.svg');
			cursor: pointer;
		}
	}
}
