@media print {
	.app-header {
		display: none !important;
	}

	.project-section {
		.section-sidebar {
			display: none;
		}

		.project-budget {
			.project-budget-sidebar {
				display: none;
			}
		}
	}

	.vis-item.vis-range,
	.vis-item.vis-background,
	.phase-color,
	.vis-item-content::after {
		-webkit-print-color-adjust: exact;
		color-adjust: exact;
	}

	.zoom-menu {
		visibility: hidden !important;
	}

	.insights-viewer {
		.controls {
			visibility: hidden !important;
		}
		.info {
			visibility: hidden !important;
		}
	}

	.custom-scrollbar-div {
		overflow: visible !important;
		> div {
			overflow: visible !important;
		}
		.track,
		.thumb {
			visibility: hidden !important;
		}
	}
}
