.richtext-item {
	.label-suggestion-new-entry {
		background-image: url('images/cross-section-icons/actions/add.svg');
		background-position: top right;
		background-size: 16px;
		background-repeat: no-repeat;
		padding-right: 20px;
	}
	.editor-textArea {
		.draftJsMentionPlugin__mentionSuggestions__2DWjA {
			padding: 0 !important;
		}
	}

	.place-over {
		[class^='draftJsMentionPlugin__mentionSuggestions__'] {
			transform: translateY(-140%) !important;
		}
	}
}
