.task-table-v3 {
	//padding: 0 16px;
	.drop-active {
		background: $v2-list-hover-purple;
	}
	.expand-arrow {
		width: 24px;
		height: 100%;
		background-size: 22px;
		background-position: center;
		background-repeat: no-repeat;
		margin-right: 16px;
		&.expand {
			background-image: url('images/chevrons/chevron_down_black.svg');
		}
		&.collapse {
			background-image: url('images/chevrons/chevron_up_black.svg');
		}
	}
	.person-header-row {
		display: flex;
		flex-direction: row;
		width: 100%;
		.person-availability-section {
			width: 100%;
		}
		.person {
			flex-grow: 1;
		}
		.time-info {
			margin-top: 5px;
			text-align: right;
			display: flex;
			flex-direction: row;
			flex-grow: 0;
			.time-info-text {
				justify-content: flex-end;
				min-width: 80px;
				display: flex;
				flex-direction: row;
				margin: 6px 12px 0 12px;
				color: $v2-text-gray;
				font-size: 12px;
				font-weight: normal;
			}
			.flex-container {
				margin-right: 24px;
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				.task-warning {
					margin: 5px 4px 0 0;
					width: 16px;
					height: 16px;
					background: url('images/sections/scheduling-canvas/warning-icon-red.svg');
					background-size: 14px;
					background-position: center;
					background-repeat: no-repeat;
					display: inline-block;
				}
				.time-info-header {
					padding-top: 7px;
					font-size: 12px;
					font-weight: 600;
					font-style: normal;
					font-stretch: normal;
					line-height: 1.17;
					letter-spacing: normal;
					text-align: right;
					color: #535353;
					margin: 0;
				}
			}
			.person-availability-second-row {
				display: flex;
				flex-direction: row;
				font-size: 12px;
			}
		}
	}

	.virtualized-scrollbar-inner,
	.no-scroll-container {
		padding: 0 26px;
	}
	.empty {
		height: 0;
		width: 0;
		max-height: 0;
		max-width: 0;
		opacity: 0;
	}
	.drop-active {
		.phases-header-v2 {
			.overlay {
				background-color: #000;
				opacity: 0.1;
			}
		}
	}
	.table {
		.text-input {
			padding: 6px 5px;
			width: 100%;
		}
		.text-input-group {
			padding: 6px 5px;
		}
		.time-group {
			padding-right: 4px;
		}
		.hours-group {
			padding-right: 6px;
		}
		.amount-group {
			padding-left: 5px;
			float: right;
		}
		.align-right {
			text-align: right;
		}
	}
	.header-wrapper {
		cursor: pointer;
		outline: none;
		&:focus {
			outline: none;
		}
		.person-header-row {
			width: 100%;
			display: flex;
			flex-direction: row;
			height: 40px;
			// padding-left: 26px;
			position: relative;
			.person-summation-col {
				align-self: center;
				text-align: right;
			}
			.closed {
				//margin-top: 6px;
			}
			.collapsed-border {
				position: absolute;
				height: 1px;
				background: $v2-grey;
				bottom: 0;
				width: 100%;
			}
			.person-summation-col {
				font-size: 14px;
				white-space: nowrap;
			}
			.person-totals-row {
				display: flex;
				flex-direction: row;
				.person {
					margin-left: 10px;
				}
			}
			.person-header-row {
				.elem-wrapper {
					display: flex;
					&.person {
						margin-left: 10px;
					}
				}
			}
			.person-totals-row {
				.elem-wrapper {
					display: flex;
					.expand-arrow {
						margin-left: 22px;
					}
				}
			}
			.forecast-person {
				.name {
					display: flex;
					font-weight: 600;
					font-size: 13px;
					.name-inner {
						max-width: 330px;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
					.task-amount {
						margin-left: 4px;
						font-weight: 300;
					}
				}
				.role {
					font-size: 12px;
					color: $v2-text-light-gray;
					line-height: 1.17;
					max-width: 330px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
			&.unassigned {
				.forecast-person {
					padding-left: 10px;
				}
			}
		}
		.forecast-person {
			padding-left: 0;
		}
	}
	.person-grouping-wrapper {
		border: solid 1px #e6e6e6;
		background: white;
		margin: 10px 15px;
		.header-wrapper {
			margin-bottom: 8px;
		}

		.time-info {
			padding-right: 16px;
		}
		.virtualized-scrollbar-inner,
		.no-scroll-container {
			padding: 0;
		}
	}
	.timereg-date {
		font-size: 13px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.38;
		letter-spacing: normal;
		text-align: center;
		color: $v2-text-gray;
	}
	.table-person-grouping {
		.phase-bottom-section {
			padding: 0 10px;
		}
		.table-row-v3 .last-cell .chip-container .chip.has-phase {
			background: white;
		}
	}
}
