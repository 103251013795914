.generic-empty-state {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 16px;
	margin-left: 32px;
	margin-right: 32px;

	@include content-frame;
	&.team-empty-state {
		height: calc(100% - 66px);
	}
	&.files {
		//Removing stuff coming from @include content-frame
		border: none;
		padding: 0;
		box-shadow: none;
		margin-top: 1px; //Box shadow of outer container is covered without this and looks ugly
	}
	.empty-state-icon {
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		flex: 0 1 160px;
		width: 160px;
		margin-top: 40px;
		&.client {
			background-image: url('images/sections/project/section-sidebar/client.svg');
		}
		&.phases {
			background-image: url('images/sections/project/section-sidebar/scoping.svg');
		}
		&.team {
			background-image: url('images/sections/project/section-sidebar/team.svg');
		}
		&.rates {
			background-image: url('images/sections/project/section-sidebar/rates.svg');
		}
		&.files {
			background-image: url('images/sections/project/section-sidebar/files.svg');
		}
		&.sprints {
			background-image: url('images/sections/project/section-sidebar/sprint.svg');
		}
		&.timeline {
			background-image: url('images/sections/project/section-sidebar/timeline.svg');
		}
	}
	.empty-state-header {
		font-size: 2rem;
		color: $color-martin-tertiary-green;
		font-weight: bold;
		margin-top: 40px;
	}
	.empty-state-text {
		font-size: 1.3rem;
		max-width: 500px;
		margin: 40px 0 0 0;
		text-align: center;
	}
	.empty-state-button {
		@include julie-green-button;
		margin-top: 40px;
	}
	.empty-state-extra {
		margin-top: 40px;
	}
}
