.detail-box-task-container {
	display: flex;
	flex-direction: column;
	border: solid 1px $v2-grey;
	border-radius: 4px;

	.detail-box-inner-headline {
		display: flex;
		flex-direction: row;
		width: 100%;

		.detail-box-inner-headline-left {
			display: flex;
			flex-direction: row;
			align-items: center;
			font-weight: 600;
			font-size: 12px;
			margin-bottom: 7px;

			.task-icon {
				margin-right: 7px;
			}
		}

		.detail-box-inner-headline-right {
			display: flex;
			flex: 1;
			align-items: center;
			justify-content: right;
			font-weight: 500;
			font-size: 12px;
			margin-bottom: 7px;
		}
	}

	.detail-box-task-item-icon {
		padding-right: 10px;
	}

	.detail-box-task-item {
		&:not(:last-child) {
			margin-bottom: 10px;
		}

		&.detail-box-assigned-person-container {
			.label {
				margin-bottom: 4px;
			}
		}

		&.detail-box-task-remaining-time-per-assigned,
		&.detail-box-task-estimate {
			font-weight: 600;
		}

		.label {
			height: 12px;
			font-size: 8px;
			text-transform: uppercase;
			font-weight: 500;
			letter-spacing: 0.5px;
			color: #b4b9c0;
		}

		.content {
			font-size: 11px;
			display: flex;
			letter-spacing: 0.5px;
		}
	}
}
