/* .calendar-table {

	thead {
		// color: $color-white;
		th {
			width: 40px;
		}
		.month-controls {
			th {
				padding-bottom: 11px;
				color: $color-white;
				&.viewed-month {
					font-size: 16px;
					font-weight: bold;
					letter-spacing: 0.6px;
				}
				&.viewed-year {
					font-size: 12px;
					letter-spacing: 0.5px;
					font-weight: 400;
				}
				.previous-control {
					background: url('images/v2/components/date-controls/arrow-left.svg') no-repeat;
					background-position: bottom left;
				}
				.next-control {
					background: url('images/v2/components/date-controls/arrow-right.svg') no-repeat;
					background-position: bottom right;
				}
				.previous-control,
				.next-control {
					height: 35px;
					width: 35px;
					background-size: 35px;
					border: none;
				}
			}
		}
		.weekdays {
			th {
				width: 7px;
				height: 16px;
				font-size: 11px;
				color: $color-white;
				font-weight: 400;
				padding-bottom: 4px;
			}
		}
	}
	tbody {
		text-align: center;
		.table-body-week-row {
			td {
				border: solid 1px $v2-background-dark-gray;
			}
			button {
				border: none;
				background-color: #5f7081;
				text-align: center;
				font-size: 14px;
				font-weight: bold;
				vertical-align: middle;
			}
			.calendar-day {
				height: 39px;
				width: 39px;
				color: #d7d7d7;
				cursor: pointer;
				&.transparent {
					background-color: transparent;
				}
				&.in-range {
					color: $v2-text-gray;
					background-color: $v2-date-picker-day-in-range-gray;
				}
				&.selected,
				&:hover {
					background-color: $v2-create-button-green;
					color: $color-white;
				}
				&.being-changed {
					box-sizing: border-box;
					-moz-box-sizing: border-box;
					-webkit-box-sizing: border-box;
					border: 2px dashed $v2-create-button-green;
				}
			}
		}
	}
} */

.calendar-table {
	//font size needs to be set on parent component to calculate em correctly
	font-size: 14px;
	thead {
		// color: $color-white;
		th {
			//border: none;
		}
		.month-controls {
			th {
				padding-bottom: 0.6875em;
				color: $v2-text-gray;
				&.viewed-month {
					font-size: 1em;
					font-weight: bold;
					letter-spacing: 0.6px;
				}
				&.viewed-year {
					font-size: 0.75em;
					letter-spacing: 0.5px;
					font-weight: 400;
				}
				.viewed-year {
					font-size: 0.75em;
					letter-spacing: 0.5px;
				}
				.previous-control {
					background: url('images/v2/components/date-controls/arrow-left.svg') no-repeat;
					background-position: bottom left;
				}
				.next-control {
					background: url('images/v2/components/date-controls/arrow-right.svg') no-repeat;
					background-position: bottom right;
				}
				.previous-control,
				.next-control {
					height: 2.188em;
					width: 2.188em;
					background-size: 2.188em;
					border: none;
					&:disabled {
						opacity: 0;
					}
				}
			}
		}
		.weekdays {
			border-bottom: 1px solid $app-border-color;
			th {
				height: 1em;
				font-size: 0.75em;
				color: $v2-text-gray;
				font-weight: 400;
				padding-bottom: 4px;
			}
		}
	}
	tbody {
		text-align: center;

		.table-body-week-row {
			td {
				border: none;
			}
			button {
				border: none;
				background-color: white;
				outline: 0;
				font-weight: bold;
				height: 37px;
				width: 37px;
				padding: 0;
			}
			.calendar-day {
				color: $v2-text-gray;
				cursor: pointer;
				border-left: 1px solid $color-white;
				border-bottom: 1px solid $color-white;
				&.disabled {
					color: $disabled-grey;
					pointer-events: none;
				}
				&.transparent {
					background-color: transparent;
				}
				&.in-range {
					color: $v2-text-gray;
					background-color: $v2-workflow-task-selected-color;
				}
				&.selected,
				&:hover,
				&:focus {
					background-color: $v2-branding-purple;
					color: $color-white;
				}
				&.no-hover {
					&:hover {
						color: $v2-text-gray;
						background-color: $v2-workflow-task-selected-color;
					}
					&.selected {
						&:hover {
							background-color: $v2-branding-purple;
							color: $color-white;
						}
					}
				}
				&.being-changed {
					/* box-sizing: border-box;
					-moz-box-sizing: border-box;
					-webkit-box-sizing: border-box;
					border: 2px dashed $v2-branding-purple; */
					background-color: $color-white;
					color: $v2-text-gray;
					border: 2px dashed $v2-branding-purple;
				}
				&.outside {
					color: $color-white;
					background-color: $color-white;
					&.in-range {
						border-top: 1px solid $v2-workflow-task-selected-color;
						border-bottom: 1px solid $v2-workflow-task-selected-color;
					}
					&.selected,
					&:hover,
					&:focus {
						cursor: default;
						color: $color-white;
						background-color: $color-white;
					}
					&.no-hover {
						&:hover {
							color: #e6e6e6;
							background-color: $color-white;
						}
						&.selected {
							&:hover {
								background-color: $v2-branding-purple;
								color: $color-white;
							}
						}
					}
					&.selected {
						&:hover {
							background-color: $v2-branding-purple;
							color: $color-white;
						}
					}
				}
			}
		}
	}
}
