.project-team {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	height: 100%;
	&.useNewNavigation {
		margin-top: 0;
	}

	.teams-text-label {
		font-size: 14px;
	}

	.project-team-header {
		.forecast-actions {
			.meetin-creator-control {
				button {
					width: auto;
				}
				padding-right: 10px;
			}

			.invite-button {
				@include primary-button;
				width: auto;
				margin-right: 32px;
			}
			.clear-filters {
				margin-left: 40px;
			}
			.person-selector-container {
				width: 300px;
			}
			.teams-multi-level-select-wrapper {
				min-width: 250px;
			}
		}
	}

	.section-body {
		display: flex;
		flex-direction: column;
		.top-container {
			@include data-tile;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;

			.info-component-icon-wrapper {
				margin: 0 5px 0 7px;
			}

			&.collapsed {
				border-bottom: 1px solid #eee;
			}

			h2 {
				font-size: 16px;
				color: $v2-text-gray;
				font-family: inherit;

				span {
					font-weight: 400;
				}
			}

			.controls {
				display: flex;
				flex-direction: column;
				align-items: flex-end;

				.expand-control {
					width: 24px;
					height: 16px;
					background-size: 24px;
					background-repeat: no-repeat;
					background-position: center;
					cursor: pointer;

					&.expand {
						background-image: url('images/header/expanded.svg');
					}

					&.collapse {
						background-image: url('images/up-arrow.svg');
					}
				}
			}
		}

		.menu {
			padding-bottom: 8px;
			.categories {
				@include master-tab;
			}
		}

		.group-header {
			display: flex;
		}

		.seats-info {
			padding: 0px 16px 0px 16px;
			font-size: 13px;
		}

		.controls-new {
			padding-top: 4px;
			padding-right: 16px;
		}

		.member-table {
			min-width: 1080px;
			padding: 20px;
			margin-bottom: 16px;
			cursor: default;
			@include content-frame;
			@include standard-table;
			th {
				&:hover {
					.tooltip {
						color: $color-dark-gray;
					}
				}
			}
			.column-relation-type {
				.header-container {
					.header-text {
						margin-right: 6px;
					}
				}
			}
			.column-contact-person,
			.cell-contact-person {
				.header-container {
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					.header-text {
						margin-right: 6px;
					}
				}
				.contact-person-checkbox-wrapper {
					position: relative;
					display: flex;
					justify-content: center;
					width: 100%;
					.project-group-contacts-list-wrapper {
						@include pop-out-v2;
						top: 21px;
						right: 50%;
						outline: none;
						ul {
							padding-left: 0;
							margin: 0;
							list-style-type: none;
						}
						li {
							padding: 3px 6px;
							&.first {
								padding-top: 6px;
							}
							&.last {
								padding-bottom: 6px;
							}
							&:hover {
								background-color: $v2-list-hover-purple;
							}
							cursor: pointer;
							.list-element-wrapper {
								display: flex;
								flex-direction: row;
								align-items: center;

								.project-indicator {
									@include project-indicator;
								}
								.project-name {
									width: 80px;
									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;
									margin-right: 5px;
									font-size: 12px;
									color: $v2-text-gray;
								}
							}
						}
					}
				}
			}
			.row-member {
				.person,
				.person-admin {
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					.image-container {
						width: 40px;
						height: 40px;
						margin-right: 10px;
					}
					.name-tile {
						display: flex;
						flex-direction: column;
						flex-wrap: nowrap;
						.name {
							font-size: 14px;
							font-weight: bold;
						}
						.email {
							margin-top: 5px;
							font-size: 11px;
						}
					}
				}
				.person-admin {
					cursor: pointer;
				}
				.project-role {
					.label,
					.label-focused {
						padding: 0;
					}
					.input-container {
						input[type='text'] {
							border: none;
						}
					}
					.select {
						margin-top: -30px;
					}
					.select-wrapper {
						width: calc(100% - 20px);
					}
					.icon-wrapper {    
						margin-top: 5px;
						float: left;
					}
				}
				.select-table-cell {
					.option {
						color: $color-black;
						&:hover {
							color: $color-purple-hover-active;
						}
					}
					.option-selected {
						color: $brand-color-accent;
					}
				}
			}
		}
	}
}
