.file-preview {
	display: flex;
	flex-direction: row;
	margin-bottom: 15px;
	.image {
		flex: 0 0 auto;
	}
	.preview {
		max-height: 114px;
		max-width: 114px;
	}
	.image-preview {
		display: flex;
		justify-content: space-around;
		align-items: center;
		width: 124px;
		height: 124px;
		padding: 5px;
		border: 1px solid $app-border-color;
		&.hasPreview {
			cursor: pointer;
		}
	}
	.image-meta {
		flex: 1;
		padding: 0 0 0 10px;
		.timestamp {
			display: block;
			font-size: 10px;
			padding: 5px 0px;
		}
		.file-title {
			input {
				@include generic-text-input;
				min-width: 100px;
				max-width: 230px;
				padding: 5px;
				//font-size: 1.2em;
				cursor: pointer;
				border-radius: 3px;
				border: 1px solid transparent;
				&:hover {
					border: 1px solid $app-border-color;
				}
			}
		}
		.file-actions {
			padding-top: 5px;
			span,
			a {
				background-size: 16px;
				background-repeat: no-repeat;
				cursor: pointer;
				margin-right: 10px;
				padding-left: 18px;
				text-decoration: none;

				&.file-download {
					background-image: url('images/components/overlay/download.svg');
					&:hover {
						color: $color-purple-hover-active;
						background-image: url('images/components/overlay/download-active.svg');
					}
				}
				&.file-cover {
					background-image: url('images/components/overlay/cover.svg');
					&:hover {
						color: $color-purple-hover-active;
						background-image: url('images/components/overlay/cover-active.svg');
					}
				}
				&.file-delete {
					background-image: url('images/components/overlay/delete.svg');
					&:hover {
						color: $color-purple-hover-active;
						background-image: url('images/components/overlay/delete-active.svg');
					}
				}
			}
			.prompt {
				background-image: url('images/components/overlay/delete.svg');
				span {
					cursor: default;
					margin-right: 0px;
					padding-left: 0px;
					&.prompt-yes,
					&.prompt-no {
						cursor: pointer;
						&:hover {
							color: $color-purple-hover-active;
						}
					}
				}
			}
		}
	}

	.file-format {
		background-size: 16px;
		background-repeat: no-repeat;
		background-position: left center;
		padding-left: 24px;
		@include file-format-icons;
	}
	.file-title {
	}
	.file-name {
		display: block;
		font-style: italic;
		padding-top: 5px;
		max-width: 270px;
	}
	.upload-date {
		font-size: 0.625rem;
	}
}
