.task-modal-v3-top-section {
	height: 64px;
	position: relative;
	padding: 0 58px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-top: 5px;

	.info-container {
		flex: 1 1;
		display: flex;
		align-items: center;

		& > *:not(:last-child) {
			margin-right: 15px;
		}

		.nested-dropdown-v2 {
			.arrow:not(.expanded) {
				visibility: hidden;
			}

			.input-arrow-wrapper:not(.expanded) {
				border: 1px solid transparent;
			}

			&:hover {
				.arrow {
					visibility: visible;
				}
				.input-arrow-wrapper:not(.expanded) {
					border: 1px solid #e6e6e6;
					border-radius: 4px;
					box-sizing: border-box;

					.arrow {
						cursor: pointer;
						background: url('images/v2/components/dropdown/arrow-down.svg') no-repeat center;
						width: 16px;
						height: 100%;
						outline: none;
					}
				}
			}

			&.project-dropdown {
				input {
					max-width: 90%; // fix bug in firefox
				}
			}

			&.project-dropdown,
			&.phase-dropdown,
			&.sprint-dropdown,
			&.status-dropdown {
				flex: 1;
				height: 46px;

				.input-arrow-wrapper {
					height: 46px;
					&.expanded {
						.placeholder {
							top: 15px;
						}
					}
					.placeholder {
						margin-right: 7px;
						margin-bottom: 5px;
						margin-left: 14px;
						align-self: flex-end;
						top: 20px;
						height: initial;
					}
				}
			}
		}

		.project-info-container {
			margin-left: 16px;
			width: 174px;
			flex: 1;
			max-width: 174px;
			.client-name-text {
				font-size: 9px;
				letter-spacing: 0.9px;
				color: $v2-text-light-gray;
				text-transform: uppercase;
				margin-top: 3px;
			}
			.project-text {
				display: inline-flex;
				flex-direction: row;
				font-size: 13px;
				flex-grow: 1;
				width: 100%;
				margin-top: 6px;
				.id-label-wrapper {
					margin-right: 7px;
				}
			}
			.project-name-text,
			.client-name-text {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				min-width: 0;
			}
			.project-name-text {
				padding-top: 2px;
			}
		}

		.phase-info-container,
		.sprint-info-container,
		.status-info-container {
			width: 174px;
			flex: 1;

			.phase-header,
			.sprint-header,
			.status-header {
				font-size: 9px;
				letter-spacing: 1px;
				color: $v2-text-light-gray;
				border-radius: 4px;
				text-transform: uppercase;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				min-width: 0;
			}
			.phase-text,
			.sprint-text,
			.status-text {
				font-size: 13px;
				color: $v2-text-gray;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				height: 20px;
			}
		}
	}

	.project-link {
		position: absolute;
		left: 26px;
		top: 23px;
		&:hover {
			cursor: pointer;
			path {
				fill: $v2-branding-purple;
			}
		}
	}

	.action-menu-container {
		position: absolute;
		right: 15px;
		top: 20px;

		.hidden-file-input {
			display: none;
		}
	}

	.close-button {
		border: none;
		width: 12px;
		height: 12px;
		background-size: 13px;
		background-image: url('images/components/close-modal.svg');
		cursor: pointer;
		position: absolute;
		right: 18px;
		top: 1px;
	}
}
