.generic-modal {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 900;
	background: rgba(0, 0, 0, 0.6);
	display: flex;
	justify-content: center;
	align-items: center;
	.generic-modal-content {
		display: flex;
		min-width: 340px;
		max-height: 90%;
		flex-direction: column;
		background-color: white;
		&.has-side-content {
			flex-direction: row;
			.content-container {
				flex-grow: 1;
			}
		}
		.button-wrapper {
			display: flex;
			justify-content: space-between;
			margin-top: 24px;
			button {
				padding: 0 32px;
				height: 36px;
			}
		}
		.button-container {
			height: 40px;
			display: flex;
			flex-direction: row;
			flex-grow: 1;
			justify-content: flex-end;
			button {
				padding: 0 32px;
				height: 36px;
			}
		}
		.content-container {
			@import '../global/unit4_sync_users_modal';
			@import '../global/integration_sync_clients_modal';
			@import '../components/modals/confirm_password_modal';
			@import '../components/modals/create_new_report_modal.scss';
			@import '../components/modals/github_attach_modal.scss';
			padding: 32px 58px;
			overflow: auto;
			&.overflow-visible {
				overflow: visible;
			}
			&.useScrollbars {
				padding: 32px 0 0 58px;
				.button-container {
					padding-right: 58px;
					margin-bottom: 32px;
				}
			}
			position: relative;
			.cover-image {
				display: flex;
				justify-content: center;
				margin-bottom: 20px;
			}
			.header-container {
				//Putting header text in absolute container prevents it from stretching the modal
				position: relative;
				height: 29px;
				margin-bottom: 24px;
				.header-text {
					color: $v2-text-gray;
					font-family: $heading-font-family;
					font-size: 20px;
					font-weight: 500;
					font-style: normal;
					font-stretch: normal;
					line-height: normal;
					letter-spacing: normal;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					&.info-icon-header {
						display: flex;
					}
					&.action-menu-present {
						right: 28px;
					}
					/* span {
						margin-left: 10px;
					} */

					.tooltip-label {
						display: inline-block;
						font-family: 'neue-haas-grotesk-text';
						margin-left: 8px;
						top: -2px;
						span {
							margin: 0;
						}
					}
				}
				&.with-header-buttons {
					display: flex;
					justify-content: space-between;
					.header-text {
						position: static;
					}
					.header-button-container {
						display: flex;
						button {
							margin-left: 8px;
						}
					}
				}
				.actions-menu-container {
					position: absolute;
					right: 0;
				}
			}
			.body-container {
				font-size: 13px;
				color: $v2-text-gray;
			}
			.close-button {
				border: none;
				width: 14px;
				height: 14px;
				background-size: 14px;
				background-image: url('images/components/close-modal.svg');
				background-repeat: no-repeat;
				cursor: pointer;
				position: absolute;
				top: 16px;
				right: 16px;
			}

			.shortcut-table {
				flex: 1 1 auto;
				width: 550px;
				display: flex;
				height: 85vh;
				@include standard-table;
				table {
					.shortcut-modal-sub-title {
						font-weight: 700;
					}
				}
			}
			.whitespace-pre {
				white-space: pre;
			}

			.project-dropdown-wrapper {
				width: 372px;
			}
		}
		&.auto-scheduling-step {
			width: 456px;
			height: 409px;
			.content-container {
				font-size: 13px;
				line-height: 1.23;
				.header-container {
					margin-bottom: 10px;
					.header-text {
						font-family: Urbane;
						font-size: 18px;
						font-weight: 500;
					}
				}
				.auto-scheduling-description {
					margin-bottom: 20px;
				}
				.info-row {
					display: flex;
					font-size: 12px;
					line-height: 1.33;
				}
				.auto-scheduling-button {
					width: 166px;
					height: 40px;
					background-color: $ai-blue;
					padding: 13px 22px 12px 25px;
					.text {
						font-family: Urbane;
						font-size: 11px;
						font-weight: bold;
						font-stretch: normal;
						font-style: normal;
						line-height: normal;
						letter-spacing: 0.5px;
						text-align: center;
						color: #ffffff;
					}
					.ai-icon {
						margin-right: 7px;
					}
				}
			}
		}
		&.auto-schedule-extra-persons {
			white-space: pre-line;
		}
		&#auto-schedule-modal-similar-projects {
			.content-container {
				display: flex;
				flex-direction: column;
			}
			.cover-image {
				flex: 1 1 auto;
				min-height: 0;
				overflow: hidden;
			}
			.header-container {
				flex: 1 0 auto;
			}
			.auto-scheduling-modal {
				flex: 1 0 auto;
			}
			.button-container {
				flex: 1 0 auto;
			}
		}

		&.change-allocation-type-modal, &.change-weekend-visibility-setting-modal {
			.content-container {
				width: 570px;
			}
		}

		.loading {
			margin: 0;
		}

		.loading:after {
			content: '.';
			animation: dots 1s steps(5, end) infinite;
		}

		@keyframes dots {
			0%,
			20% {
				color: rgba(0, 0, 0, 0);
				text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
			}
			40% {
				color: #535353;
				text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
			}
			60% {
				text-shadow: 0.25em 0 0 #535353, 0.5em 0 0 rgba(0, 0, 0, 0);
			}
			80%,
			100% {
				text-shadow: 0.25em 0 0 #535353, 0.5em 0 0;
			}
		}
	}
}
