.forecast-actions-insights {
	flex: 0 0 auto;
	align-items: flex-start;
	padding-right: 20px;
	h3 {
		margin: 5px 0;
	}
}
.forecast-actions {
	flex: 1 1 auto;
	align-items: center;
}
.forecast-actions,
.forecast-actions-insights {
	display: flex;
	justify-content: flex-end;
	min-height: $section-header-height;
	.top-border-container {
		//this should be changed
		right: 100px;
		width: 340px;
	}
	&.left {
		justify-content: flex-start;
		button {
			margin-left: 0;
			margin-right: 8px;
			height: 30px;
			width: 108px;
		}
	}
	> span {
		font-weight: bold;
		color: #535353;
		margin-right: 8px;
	}
	button {
		//margin-right: 8px;
		margin-left: 8px;
		width: 40px;
		height: 40px;
		border: none;
		background-color: transparent;
		background-position: center;
		background-repeat: no-repeat;
		background-size: 36px;
		margin-left: 32px;
		padding: 0;
		&:focus {
			outline: 0;
		}
	}

	.clear-filters {
		color: $brand-color;
		cursor: pointer;
		margin-left: 32px;
		&:hover {
			color: $color-purple-hover-active;
		}
	}

	.action {
		position: relative;
		&:focus {
			outline: 0;
		}
		&.search-V2 {
			margin-left: 8px;
			height: 30px;
			min-width: 30px;
			border: 1px solid $color-martin-timeline-border;
			background-image: url('images/components/scheduling/search-icon.svg');
			background-size: 16px;
			background-position: right 7px center;
			background-repeat: no-repeat;
			cursor: pointer;
			outline: 0;
			padding: 0 30px 0 0;
			::-webkit-input-placeholder {
				/* Chrome/Opera/Safari */
				font-size: 12px;
			}
			::-moz-placeholder {
				/* Firefox 19+ */
				font-size: 12px;
			}
			:-ms-input-placeholder {
				/* IE 10+ */
				font-size: 12px;
			}
			:-moz-placeholder {
				/* Firefox 18- */
				font-size: 12px;
			}
			&:hover {
				border: 1px solid $v2-date-picker-button-border-color;
			}
			&.expanded {
				box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
				&:hover {
					border: 1px solid $color-martin-timeline-border;
				}
			}
			input {
				border: none;
				outline: 0;
				padding: 8px 8px 8px 8px;
				font-size: 12px;
			}
		}
		&.search {
			.forecast-icon-button {
				background-image: url('images/components/search-filter/search.svg');
				&.expanded,
				&:hover,
				&.active {
					background-image: url('images/components/search-filter/search-active.svg');
				}
			}
			.drop-down,
			.drop-down-right-side {
				.input-container {
					display: flex;
					flex-direction: row;
					align-items: center;
					border: 1px solid $app-border-color;
					border-radius: 3px;
					height: 36px;
					.clear-button {
						width: 22px;
						height: 22px;
						border: none;
						background-size: 12px;
						background-repeat: no-repeat;
						background-position: center;
						background-image: url('images/components/overlay/close.svg');
						margin-left: 0;
					}
					input {
						border: none;
						padding: 5px;
						&:focus {
							outline: 0;
						}
					}
				}
			}
		}

		&.filter {
			.select {
				width: auto;
				min-width: 100%;
			}
			.forecast-icon-button {
				position: relative;
				background-image: url('images/components/search-filter/content-filter.svg');
				&.expanded,
				&:hover,
				&.active {
					background-image: url('images/components/search-filter/content-filter-active.svg');
				}
				.active-filter-count {
					background-color: #ea87ff;
					width: 14px;
					height: 14px;
					border-radius: 50%;
					position: absolute;
					top: 0px;
					right: 5px;
					color: white;
					font-size: 12px;
					font-weight: 700;
				}
			}
		}

		.forecast-icon-button {
			position: relative;
			&.expanded {
				z-index: 11;
				background-color: $color-white;
				border-top-left-radius: 50%;
				border-top-right-radius: 50%;
				box-shadow: 0px 4px 0px 0 rgba(255, 255, 255, 1), 0 1px 4px 0 rgba(0, 0, 0, 0.5);
			}
		}
		.drop-down,
		.drop-down-right-side {
			> :not(:last-child) {
				margin-right: 15px;
			}
			.select-wrapper {
				padding-bottom: 0;
			}
			white-space: nowrap;

			z-index: -1;
			&.long {
				width: 890px;
			}
			&.short {
				width: 675px;
			}
			&.long,
			&.short {
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
			}
			&.collapsed {
				display: none;
			}
			&.expanded {
				z-index: 10;
				position: absolute;
				top: 41px;
				background-color: white;
				padding: 15px;
				border-top-left-radius: 5px;
				border-bottom-left-radius: 5px;
				border-bottom-right-radius: 5px;
				box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5);
				> input {
					border: 1px solid $app-border-color;
					border-radius: 3px;
					height: 36px;
					padding: 5px;
					&:focus {
						outline: 0;
					}
				}
			}
		}
		.drop-down {
			&.expanded {
				right: 0px;
			}
		}
		.drop-down-right-side {
			&.expanded {
				left: 0px;
			}
		}
	}
}
