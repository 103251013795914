.client-selector-container {
	position: relative;
	&:focus {
		outline: 0;
	}
	.input-container {
		padding-bottom: 4px;
		display: flex;
		flex-direction: row;
		width: 260px;
		transition: background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
		background: transparent no-repeat;
		background-image: linear-gradient(to bottom, $material-focused-color, $material-focused-color), linear-gradient(to bottom, silver, silver);
		background-size: 0 2px, 100% 1px;
		background-position: 50% 100%, 50% 100%;
		input {
			border: none;
			flex-grow: 1;
			cursor: pointer;
			&:focus {
				outline: 0;
				cursor: auto;
			}
		}
		.arrow {
			flex-shrink: 0;
			height: 21px;
			width: 21px;
			background-size: 21px;
			background-repeat: no-repeat;
			cursor: pointer;
			&.collapsed {
				background-image: url('images/components/select-input/collapsed.svg');
			}
			&.expanded {
				background-image: url('images/components/select-input/expanded.svg');
			}
		}
		&:hover {
			background-image: linear-gradient(to bottom, $material-focused-color, $material-focused-color), linear-gradient(to bottom, black, black);
		}
		&.focused {
			background-size: 100% 2px, 100% 1px;
		}
	}
	.dropdown {
		position: absolute;
		top: 32px;
		width: 260px;
		padding: 16px 0 16px 0;
		box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
		background-color: white;
		z-index: 999;
		&.collapsed {
			display: none;
		}
		.dropdown-inner {
			max-height: 300px;
			@include scroll-y;
			.client {
				padding: 8px 24px 8px 24px;
				cursor: pointer;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				.image-container {
					.no-logo {
						width: 34px;
						height: 34px;
						background-image: url('images/cross-section-icons/no-client.svg');
						background-size: 34px;
					}
				}
				.text-container {
					display: flex;
					flex-direction: column;
					flex-wrap: nowrap;
					margin-left: 8px;
					overflow: hidden;
					justify-content: center;
					.name {
						font-weight: bold;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
				}
				&.marked {
					color: $color-purple-hover-active;
				}
				&:hover {
					color: $color-purple-hover-active;
				}
			}
			.button-container {
				padding: 0 16px 0 16px;
				.button-new {
					@include primary-button;
				}
			}
		}
	}
}
