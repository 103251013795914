.task-bond-container {
	display: flex;
	position: relative;

	.dependency-form {
		position: initial;
	}

	.task-container-wrapper {
		position: relative;
		overflow: hidden;
		margin: 10px 0;

		&.faded {
			opacity: 0.7;
		}

		.task-container {
			border: 1px solid $v2-dark-grey;
			padding: 10px 15px;
			width: 340px;
			font-size: 13px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			flex-wrap: wrap; //used to place edit buttons at the bottom

			&:hover {
				.new-ui-actions-menu #actions-btn {
					opacity: 1;
					background-color: transparent;
				}
			}

			&.done {
				border: 1px solid $v2-workflow-task-done-state-color;
			}

			.buttons-row {
				margin-top: 5px;
				width: 100%;
			}

			.new-ui-actions-menu {
				position: absolute;
				right: 9px;
				top: 9px;
				#actions-btn {
					background-size: contain;
					height: 25px;
					width: 15px;
					opacity: 0;

					&:focus {
						opacity: 1;
					}
				}
			}

			.top-line,
			.bottom-line {
				display: flex;
				align-items: center;
				width: 90%;
				font-size: 12px;
				color: $v2-text-gray;
				font-weight: bold;
				text-transform: uppercase;
				letter-spacing: 1px;

				svg {
					margin-right: 5px;
				}

				.dependency-status {
					color: $v2-text-light-gray;
					font-size: 10px;
					font-weight: initial;
					text-transform: initial;
					margin-left: auto;
				}
				&.clickable {
					cursor: pointer;
					&:hover {
						color: $v2-branding-purple;
					}
				}
			}

			.mid-line {
				margin: 2px 0 8px 0;
			}
		}
		.corner {
			position: absolute;
			left: 308px;
			top: -15px;
			width: 40px;
			height: 20px;
			border: 1px solid $v2-dark-grey;
			background-color: white;
			transform: rotate(30deg);

			&.violated {
				border: 1px solid $v2-workflow-task-warning-red;
			}

			&.done {
				border: 1px solid $v2-workflow-task-done-state-color;
			}
		}
	}

	.bond-right,
	.bond-left {
		width: 1px;
		height: 10px;
		background-color: $v2-dark-grey;
		position: absolute;
		top: 0;
		height: 100%;

		&::before {
			content: '';
			height: 1px;
			width: 14px;
			background-color: inherit;
			top: 50%;
			position: absolute;
			display: inline-block;
		}
	}

	.bond-right {
		left: 353px;

		&::before {
			left: -13px;
		}
	}

	.bond-left {
		left: -13px;

		&::before {
			top: 45%;
		}
	}

	&:last-child {
		.bond-right {
			width: 1px;
			height: 50%;

			&::before {
				top: 100%;
			}
		}

		.bond-left {
			height: 50%;

			&::before {
				top: 100%;
			}
		}
	}
}
