.trial-end-v2-layer {
	overflow: auto;
}

.trial-end-v2 {
	background-color: $color-white;
	&.fullscreen-centered-outer {
      position: relative;
    }
	.fullscreen-centered-inner {
		display: flex;
		.trial-end-wrapper {
			margin: auto;
			display: flex;
			align-items: flex-start;
			.trial-end-card {
				position: relative;
				width: 300px;
				margin: 0 33px;
				padding: 24px;
				border: 1px solid $app-border-color;
				background-color: white;
				margin-bottom: 24px;
				&.large {
					width: 700px;
				}
			}
		}
	}
}

.no-permission {
  margin-top: 10px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  table {
    tr {
      td {
        padding: 5px 25px;
      }
    }
  }
  img {
    width: 100px;
    height: auto;
    cursor: pointer;
    margin-bottom: 24px;
  }
  .logout-button {
    @include julie-green-button;
    margin-top: 20px;
    cursor: pointer;
  }
}