.mission-control-impersonate {
	padding-bottom: 200px;
	table {
		width: 100%;
	}
	.people-list {
		width: 100%;

		.dropdown-container {
			font-family: monospace;
		}

		.name-row {
			width: 1%;
			white-space: nowrap;
		}
		.remove-row {
			width: 200px;
			white-space: nowrap;
			vertical-align: middle;
		}
		h3 {
			padding: 10px;
		}

		.option {
			span {
				display: block;
				font-family: monospace;
				white-space: nowrap;
			}
		}

		.input-container {
			width: 100%;
			max-width: none;
		}

		.select {
			max-width: none;
		}

		.select-wrapper {
			width: 100%;
		}
	}
}

.mission-control-header {
	@include master-tab;
}

.mission-control-features {
	padding: 10px;
	max-height: 100vh;
	overflow: hidden;
	.mission-control-features-header {
		display: flex;
		margin: 20px;
		width: auto;
		justify-content: flex-start;
		gap: 12px;
	}
	.mission-control-list-features {
		max-height: 90vh;
		overflow: scroll;
	}
	.publish-button {
		padding: 0 16px !important;
	}
	.amplitude-icon {
		background-image: url('images/amplitude-icon.png');
		background-position: center;
		background-repeat: no-repeat;
	}
	.input-container-v2 {
		margin-bottom: 16px;
	}
	#release-title {
		padding: 0 8px;
	}
}

.mission-control-list {
	margin-left: 10px;
	margin-right: 10px;

	.min-change-input {
		border: 1px solid black;
	}
	table {
		width: 100%;
		max-width: 100%;
		margin-bottom: 20px;
		background-color: transparent;
		border-spacing: 0;
		border-collapse: collapse;

		th {
			border-top: 0;
			line-height: 1.42857143;
			vertical-align: bottom;
			border-bottom: 2px solid #ddd;
			padding: 8px;
			text-align: left;
		}

		td {
			padding: 8px;
			line-height: 1.42857143;
			vertical-align: top;
			border-top: 1px solid #ddd;
			.ml-checkmark-yes {
				margin-left: 0px;
				width: 30px;
				height: 30px;
				background: url('images/v2/components/checkmark_green.svg');
				background-image: url('images/v2/components/checkmark_green.svg');
				background-repeat: no-repeat;
				background-position: center right 8px;
			}
			.ml-checkmark-no {
				margin-left: 0px;
				width: 30px;
				height: 30px;
				background: url('images/v2/components/warningicon_red.svg');
				background-image: url('images/v2/components/warningicon_red.svg');
				background-repeat: no-repeat;
				background-position: center right 8px;
			}
		}

		tr {
			display: table-row;
			vertical-align: inherit;
			border-color: inherit;
		}

		tr:nth-of-type(odd) {
			background-color: #f9f9f9;
		}
	}
	.mission-control-list-features {
		max-height: 100%;
		overflow: hidden;
	}
}

.bugduty-wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	display: flex;
	flex-direction: column;

	.tabs {
		display: grid;
		grid-template-columns: repeat(8, max-content);
		grid-gap: 22px;
		font-size: 13px;
		color: $v2-text-gray;
		text-transform: capitalize;
		margin-top: 18px;
		margin-left: 2%;
		margin-bottom: 0;

		.tab {
			padding-top: 5px;
			cursor: pointer;
			&:hover {
				color: $v2-branding-purple;
			}
			&.active {
				color: $v2-branding-purple;
				border-bottom: 3px solid $v2-branding-purple;
				padding-bottom: 5px;
				font-weight: 500;
			}
		}
	}
}

.mission-control-bug-duty {
	@include standard-table-v2;

	height: 100vh;

	&.ml-stats {
		overflow: hidden;
	}

	.mission-control-bug-duty-container {
		height: 95%;

		.state-center {
			padding-top: 100px;
			visibility: visible;
			opacity: 1;
			transition: opacity 0.5s linear;
		}

		.mission-control-bug-duty table tbody tr td {
			font-size: 11.5px !important;
		}

		.mission-control-bug-duty-table {
			.hand {
				cursor: pointer;
			}

			.sticky {
				position: sticky;
				top: 0;
				background-color: #fff;
			}

			.visible {
				visibility: visible;
				opacity: 1;
				transition: opacity 0.25s linear;
			}

			.hidden {
				visibility: hidden;
				opacity: 0;
				transition: visibility 0s 0.15s, opacity 0.15s linear;
			}

			tbody tr {
				&:nth-child(even) {
					background-color: #f3f3f3;
				}
				&:hover {
					background: rgb(248, 243, 254);
				}
			}

			width: 96%;
			margin-left: 2%;
			margin-top: 2%;
			font-size: 11.5px !important;

			.extra-padding {
				padding-left: 18px;
				&:hover {
					color: #a1a1a1;
				}
			}

			.extra-width-60 {
				min-width: 60px;
			}

			.extra-width-100 {
				min-width: 100px;
				max-width: 100px;
			}

			.extra-width-150 {
				min-width: 150px;
				max-width: 150px;
			}

			.extra-width-250 {
				min-width: 250px;
			}

			th {
				&:hover {
					color: #a1a1a1;
				}
			}

			.td {
				font-size: 11.5px !important;
			}
			.ml-checkmark-yes {
				margin-left: 0px;
				width: 30px;
				height: 30px;
				background: url('images/v2/components/checkmark_green.svg');
				background-image: url('images/v2/components/checkmark_green.svg');
				background-repeat: no-repeat;
				background-position: center right 8px;
			}

			.ml-checkmark-yes .tooltiptext {
				visibility: hidden;
				width: 220px;
				background-color: rgb(40, 40, 40);
				color: #fff;
				text-align: center;
				padding: 5px 0;
				border-radius: 6px;
				font-size: 11.5px !important;
				font-weight: bold;
				margin-top: 18px;
				margin-left: 18px;

				/* Position the tooltip text - see examples below! */
				position: absolute;
				z-index: 1;
			}

			.ml-checkmark-yes:hover .tooltiptext {
				visibility: visible;
			}
			.ml-checkmark-no {
				margin-left: 0px;
				width: 30px;
				height: 30px;
				background: url('images/v2/components/warningicon_red.svg');
				background-image: url('images/v2/components/warningicon_red.svg');
				background-repeat: no-repeat;
				background-position: center right 8px;
			}

			.id-link {
				a {
					color: $color-white;
				}
			}
			&.not-overflowing {
				td {
					max-width: none;
				}
			}

			.team-dropdown {
				min-width: 170px;
				max-width: 170px;

				.drop-down-v2 {
					input {
						// Fixes overlap when role/permission name is too long
						padding-right: 26px;
					}
				}

				&.deactivated {
					padding-left: 16px;
				}

				&.role-width-header,
				&.permissions-width-header {
					padding-left: 16px;
				}
			}

			.elem {
				font-size: 11.5px !important;
			}

			.elem-checkbox {
				display: flex;
				align-items: center;
				padding-left: 28px;
			}

			.notes-cell {
				padding-left: 9px;
				padding-right: 0;
				min-width: 200px;
				font-size: 11.5px !important;
				overflow: visible;
				input,
				textarea {
					&::placeholder {
						font-size: 11.5px !important;
						font-style: italic;
					}
				}
				&.disabled {
					input {
						cursor: default;
						width: 100%;
						background-color: $brand-color-disabled;
						&:hover {
							border-color: $v2-grey;
						}
					}
				}
				input {
					width: 100%;
					height: 30px;
					padding: 0 8px;
					background-color: transparent;
					border: solid 1px transparent;
					border-radius: 4px;
					cursor: pointer;
					&:hover {
						border: solid 1px $v2-branding-purple;
						background-color: rgb(248, 243, 254);
					}
				}
				textarea {
					width: 100%;
					height: 125px;
					box-shadow: none;
					border: solid 1px $v2-branding-purple;
					border-radius: 4px;
				}
				.textarea-foldout {
					padding-right: 16px;
				}
			}

			tbody {
				tr td:first-child {
					border-left: 0px solid $app-border-color;
				}
			}
			.drop-down-v2 .arrow {
				height: 30px !important;
			}

			.drop-down-v2 input {
				border: solid 1px transparent;
				background-color: transparent;
				font-size: 11.5px;
				font-weight: 200;
				padding: 5px 10px 5px 10px;
				height: 30px;
				max-height: 30px;
				&:hover {
					border: solid 1px $v2-branding-purple;
					background-color: rgb(248, 243, 254);
				}
				.expanded {
					background-color: yellow;
				}
				expanded {
					background-color: yellow;
				}
				&:expanded {
					background-color: yellow;
				}
			}
		}
	}
}

.feature-flag-users-to-add-title {
	padding-top: 20px;
	font-size: 16px;
}

.feature-flag-user-container {
	display: flex;
	align-items: center;
	padding-top: 5px;

	.feature-flag-users-to-add {
		padding-top: 5px;
		font-size: 13px;
	}

	.feature-flag-remove-user {
		margin-left: 10px;
		width: 30px;
		height: 30px;
		cursor: pointer;
		background-image: url('images/cross-section-icons/actions/remove.svg');
		background-repeat: no-repeat;
		background-position: center right 8px;
	}
}
.feature-flag-users-to-add-all-title {
	margin-right: 10px;
	font-family: 'neue-haas-grotesk-text', sans-serif;
	font-size: 9px;
	font-weight: 500;
	line-height: 1.22;
	letter-spacing: 1px;
	color: #a1a1a1;
	text-transform: uppercase;
}

.feature-flag-users-to-add-all {
	height: 20px;
	width: 20px;
	.check-box .check-mark:checked {
		border: solid 2px #dbdbdb;
	}
	.check-box .check-mark {
		border: solid 2px #dbdbdb;
	}
	.check-box .check-mark:hover {
		border: solid 2px #dbdbdb;
	}
	.check-box .check-mark:focus {
		border: solid 2px #dbdbdb;
	}
}

.generic-modal {
	//position: fixed !important;
	.generic-modal-content {
		//position: fixed;
		.feature-flag-modal {
			width: 500px;
			min-height: 360px;
			.description {
				font-size: 13px;
				color: #a1a1a1;
				margin: 8px 0 16px 0;
			}
		}
	}
}

.feature-flag-users-to-add-all-group {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	margin-top: 20px;
}


.company-feature-flag-modal {
	width: 90vw;
}

.company-feature-flag-modal,
.feature-flag-group {
	border-radius: 25px;
	opacity: 0.9;
	margin-bottom: 10px;
	padding: 10px;
	border-top: 1px solid #eaeaea;
	border-left: 1px solid #eaeaea;
	.arrow {
		cursor: pointer;
		width: 10px;
		height: 5px;
		margin-left: 5px;
		background-size: 10px;
		background-position: center;
		background-image: url('images/v2/components/dropdown/arrow-up.svg');
		&.expanded {
			background-image: url('images/v2/components/dropdown/arrow-down.svg');
		}
	}
	.new-company-container {
		display: flex;
		align-items: center;
		.new-company {
			margin-left: 10px;
			width: 30px;
			height: 30px;
			cursor: pointer;
			background-image: url('images/cross-section-icons/actions/add.svg');
			background-repeat: no-repeat;
			background-position: center right 8px;
		}
		.TECHNICAL {
			width: 30px;
			height: 30px;
			margin-right: 10px;
			background-image: url('images/feature-flags/technical.svg');
			background-repeat: no-repeat;
		}
		h3 {
			padding: 5px;
			margin-left: 5px;
			background-color: #ffffff;
			border-radius: 6px;
			&.expired {
				color: #e63946;
			}
			&.good {
				//color: #5ccb66;
			}
		}
	}
	.feature-flag-users-to-add {
		padding: 10px;
		font-size: 13px;
	}

	table {
		width: 100%;
		max-width: 100%;
		margin-bottom: 20px;
		background-color: transparent;
		border-spacing: 0;
		border-collapse: collapse;
		border-left: 0px;
		font-size: 13px;

		.feature-flag-group-table-width {
			width: 45%;
		}

		.feature-flag-group-table-edit {
			text-align: center;
			align-content: center;
		}

		td,
		th {
			padding: 5px;
			border: 1px solid $v2-text-light-gray;
			.edit-company-container {
				display: flex;
				justify-content: center;
				align-items: center;
				.edit-company {
					width: 30px;
					height: 30px;
					cursor: pointer;
					background-image: url('images/cross-section-icons/actions/edit.svg');
					background-repeat: no-repeat;
					background-position: center;
				}
			}
		}

		tr {
			display: table-row;
			vertical-align: inherit;
			border-color: inherit;
		}

		tr {
			background-color: #f9f9f9;
		}
	}
}
