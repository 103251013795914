.assigned-tile {
	display: flex;
	flex-direction: row;
	.assigned-person-container {
		padding-right: 4px;
		.assigned-person-svg {
			width: 24px;
			height: 28px;
			.assigned-person {
				width: 24px;
				height: 28px;
				-webkit-clip-path: url(#hexagon-person-mask);
				clip-path: url(#hexagon-person-mask);
				-moz-clip-path: url(#hexagon-person-mask);
				-ms-clip-path: url(#hexagon-person-mask);
			}
		}
	}

	.role-icon {
		flex: 0 0 26px;
		min-width: 26px;
		width: 26px;
		height: 26px;
		background-color: #6dcbf0;
		border-radius: 50%;
		text-transform: uppercase;
		font-size: 15px;
		line-height: 26px;
		font-weight: bold;
		letter-spacing: -0.5px;
		text-align: center;
		color: $color-white;
	}
	.additional-assigned-persons {
		height: 26px;
		position: relative;
		.assigned-persons-count {
			font-family: $text-font-family;
			font-size: 11px;
			font-weight: 500;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			text-align: center;
			color: #ffffff;
			position: relative;
			bottom: 23px;
		}
	}
}
