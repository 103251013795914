.done-projects-empty-state-container {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	align-items: center;
	padding: 42px 0 0 0;
	text-align: center;
	.info-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-wrap: wrap;
		justify-content: center;
		.info {
			font-family: $heading-font-family;
			font-size: 32px;
			margin: 20px;
		}
		.add-project-button {
			@include primary-button;
		}
	}
	img {
		margin-top: 42px;
		height: 250px;
		width: 200px;
	}
}
