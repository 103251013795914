.settings-holiday-calendar {
	&.section-content {
		margin: 0;
		.section-body {
			margin: 0 24px;
		}
	}
	.top-controls {
		display: flex;
		flex: 1;
		justify-content: space-between;
		.add-holiday-calendar-button {
			margin-left: 12px;
		}
		.import-calendar-button {
			@include primary-button;
			margin-left: auto;
		}
		@include gear-menu;
		.gear-menu {
			margin-left: 10px;
		}
		label {
			padding-top: 0;
		}
	}
	@include standard-table;
	table {
		width: 100%;
	}
	tbody {
		tr {
			@include gear-menu;
			&:hover {
				color: $v2-text-gray !important;
			}
			td {
				.date-picker-wrapper-new {
					width: 160px;
					thead,
					th,
					tr,
					td {
						padding: 0;
						border: none;
						text-align: center;
					}
				}
				.date-picker-button {
					label {
						display: none;
					}
				}
				.button-add {
					@include primary-button;
				}
			}
		}
	}
}
