.filter-v3 {
	position: relative;
	background-color: white;
	.filter-v3-button {
		height: 30px;
		width: 108px;
		border: 1px solid $app-border-color;
		text-transform: uppercase;
		font-size: 10px;
		font-weight: bold;
		letter-spacing: 0.5px;
		padding: 0 16px 0 20px;
		text-align: left;
		background-image: url('images/components/filter/filter-icon.svg');
		background-size: 15px;
		background-position: right 17px center;
		background-repeat: no-repeat;
		display: flex;
		flex-direction: row;
		line-height: 30px;
		&:hover {
			border-color: $v2-date-picker-button-border-color;
		}
		&:active,
		&:focus {
			border-color: $v2-button-very-light-grey-hovered;
		}
		&.active {
			border-color: $v2-branding-purple;
			background-color: $v2-branding-purple;
			color: #ffffff;
			background-image: none;
			&:hover {
				background-color: $v2-dark-purple;
			}
		}
		&.noTextButton {
			width: 21px;
			padding: 0;
			background-position: center;
		}
		.filter-v3-active-filter-count {
			margin-left: auto;
			color: #ffffff;
			font-size: 12px;
			line-height: 28px;
		}
	}
}
