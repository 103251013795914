.focused-assigned-container {
	height: 100%;
	width: 100%;
	padding-bottom: 4px;
	padding-right: 8px;
	.assigned-dropdown-wrapper {
		padding-top: 4px;
		height: 100%;
		width: 100%;

		.subtask-assigned-dropdown {
			padding-left: 0;
		}
	}
}
.additional-assigned-persons {
	height: 26px;
	position: relative;
	.assigned-persons-count {
		font-family: $text-font-family;
		font-size: 11px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: -0.5px;
		text-align: center;
		color: #535353;
		position: relative;
		bottom: 24px;
	}
	.more-people-container {
		color: #535353;
		height: 26px;
		width: 26px;
		background-size: 22px;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url('images/v2/components/white-hexagon.svg');
		font-size: 11px;
		font-weight: 500;
		line-height: 26px;
		text-align: center;
		z-index: 10;
	}
}
.unfocused-assigned-container {
	height: 100%;
	width: 100%;
	padding: 4px;
	.assigned-list {
		position: relative;
		display: flex;
		flex-direction: row;
		border: 1px solid $v2-table-border-color;
		border-radius: 4px;
		height: 100%;
		width: 100%;
		align-items: center;
		padding-left: 14px;
		&.disabled {
			border: none;
		}
		.assigned-role-unfocused-wrapper {
			display: flex;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			.assigned-role-text {
				margin-right: 20px;
				padding: 4px;
				width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
		.role-icon {
			flex: 0 0 26px;
			min-width: 26px;
			width: 26px;
			height: 26px;
			background-color: $v2-person-dropdown-role-background-color;
			border-radius: 50%;
			text-transform: uppercase;
			font-size: 15px;
			line-height: 26px;
			font-weight: bold;
			letter-spacing: -0.5px;
			text-align: center;
			color: $color-white;
		}
		.person-info {
			padding-right: 18px;
		}
	}

	.empty-assigned-container {
		position: relative;
		width: 96px;
		height: 31px;
		border-radius: 4px;
		display: flex;
		flex-direction: row;
		align-items: center;
		font-size: 11px;
		letter-spacing: 1px;
		color: transparent;
		border: 1px solid;
		padding: 2px 8px 1px 8px;
		margin: 4px 0 3px 0;
		&:hover {
			&.enabled {
				color: $v2-text-light-gray;
				border-color: $v2-button-very-light-grey-hovered;
				.arrow {
					width: 16px;
					height: 100%;
					background: url('images/v2/components/dropdown/arrow-down.svg') no-repeat center;
					position: absolute;
					right: 12px;
					outline: none;
				}
			}
		}
		.arrow {
			background-image: none;
		}
	}

	.arrow {
		background: url('images/v2/components/dropdown/arrow-down.svg') no-repeat center;
		position: absolute;
		right: 12px;
		height: 100%;
		width: 16px;
		&.disabled {
			background-image: none;
		}
	}
}
