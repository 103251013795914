.task-modal-v3-options-section {
	margin: 10px 58px 0 58px;
	display: flex;
	align-items: center;

	.left-section,
	.right-section {
		display: flex;
	}
	.left-section {
		flex-grow: 1;
		span:not(.day) {
			margin-right: 8px;
			display: flex;
			align-self: center;

			&:hover {
				// opacity: 1;
				cursor: pointer;
				svg {
					path {
						fill: $v2-text-gray;
					}

					&.active {
						path {
							fill: #c50018;
						}
					}
					&.active-state {
						path {
							fill: $v2-branding-purple;
						}
					}

					&.disabled {
						path {
							fill: $v2-backdrop-gray;
						}

						&.active {
							path {
								fill: $v2-border-invalid;
							}
						}
					}
				}
			}
		}
	}

	.right-section {
		justify-content: flex-end;
	}

	&.disabled {
		span {
			&:hover {
				cursor: default;
			}
		}
	}

	.recurring-days {
		display: flex;
		align-items: center;
		font-size: 13px;
		margin-left: 16px;
		cursor: pointer;

		&.disabled {
			cursor: default;
		}

		&:hover:not(.disabled) {
			color: $v2-branding-purple;
		}

		svg {
			margin-right: 5px;
		}

		.repeat-text {
			margin-right: 5px;
			font-weight: 600;
		}

		span:not(:last-child) {
			margin-right: 5px;
			&::after {
				content: ',';
			}
		}
	}

	.integration-link {
		margin-left: 16px;
		a {
			color: $link-text-color;
			font-size: 13px;
			cursor: pointer;
			text-decoration: underline;
		}
	}
	.send-to-jira-link {
		margin-left: 16px;
		color: $link-text-color;
		font-size: 13px;
		cursor: pointer;
		text-decoration: underline;
	}

	.timer-component {
		margin-left: auto;
		margin-right: 0;
	}
}
