.upcoming-loader {
	height: 100%;
	background-color: $color-light-grey;
	.loader {
		content: '';
		display: block;
		z-index: 99999;
		font-size: 10px;
		width: 1em;
		height: 1em;
		top: 50%;
		left: 50%;
		position: absolute;
		margin-top: -0.5em;
		-webkit-animation: spinner 1500ms infinite linear;
		-moz-animation: spinner 1500ms infinite linear;
		-ms-animation: spinner 1500ms infinite linear;
		-o-animation: spinner 1500ms infinite linear;
		animation: spinner 1500ms infinite linear;
		border-radius: 0.5em;
		-webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0,
			rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
		box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0,
			rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
	}

	@keyframes spinner {
		0% {
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-ms-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			-moz-transform: rotate(360deg);
			-ms-transform: rotate(360deg);
			-o-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
}

.upcoming-section {
	display: flex;
	padding: 1.6875rem 2rem 0px 2rem;
	flex-direction: column;
	overflow: hidden;
	&.v2 {
		background-color: $color-white;
		padding: 0 66px 16px 66px;
		@include standard-table-v2;

		.assigned-list {
			padding-left: 0;
		}
		.use-max-width {
			.project-name-td,
			.phase-td,
			.sprint-td {
				a {
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
			.project-name-td {
				a {
					max-width: 300px;
				}
			}
			.task-name-td {
				.warning-name-wrapper {
					max-width: 450px;
					overflow: hidden;
					span {
						max-width: 450px;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
			}
			.phase-td,
			.sprint-td {
				a {
					max-width: 400px;
				}
			}
		}
	}
	&.v1 {
		@include branding-table;

		.upcoming-list-table {
			width: 100%;
			padding-left: 0.5em;
			padding-right: 0.5em;
			.project-column {
				width: 6.2em;
			}
			@include branding-table-first-child;
			.id-link {
				a {
					color: $color-white;
				}
			}
			&.not-overflowing {
				td {
					max-width: none;
				}
			}
			.upcoming-id-column {
				.task-warning {
					@include warning-icon;
					display: inline-block;
				}
			}
		}
	}
	.upcoming {
		display: flex;
		flex-direction: column;
		flex: 1;
		overflow: hidden;
		.table-wrapper {
			flex: 1;
			display: flex;
			flex-direction: column;
		}

		.menu {
			flex: 0 0 auto;
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			.categories {
				@include master-tab;
			}
			.forecast-actions {
				.search {
					margin-right: 3px;
				}
				@include list-card-switcher;
				.people-work-select {
					margin-right: 21px;
					display: flex;
					flex-direction: row;
					justify-content: center;

					.work-label {
						font-family: $heading-font-family;
						font-weight: 700;
						font-size: 1em;
						padding: 10px 15px 0 0;
					}
					.input-container {
						min-width: 240px;
					}
				}
			}
		}
		.no-tasks {
			padding-top: 10px;
			flex-basis: 100%;
		}
	}
}
