$spacing-top: 30vh;
$logo-top: -100px;
$form-margin-spacing: 1.5rem;
$spacing-bottom: 75px;
$spacing-top-mobile: 10vh;
$space-between-side-panel: 15rem;

.password-help-text-inline {
  .password-help-text {
    line-height: 1.6em;
    .line {
      display: flex;
      flex-direction: row;
      align-items: center;
      // margin: 4px 0 4px 0;
      &.valid {
        color: $color-martin-tertiary-green;
      }
    }
    .check,
    .cross {
      width: 12px;
      height: 12px;
      flex: 0 0 12px;
      margin-right: 4px;
    }
    .check {
      background-image: url('images/sections/profile/security/check.svg');
    }
    .cross {
      background-image: url('images/sections/profile/security/error.svg');
    }
  }
}


.login-wrapper {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .description-panel {
    height: 100vh;
    width: 25vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #0004DB;
    color: white;

    .description {
      position: relative;
      height: auto;
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-top: $spacing-top;
      margin-left: 20%;

      h2 {
        margin: 0 0 20px 0;
        font-weight: 600;
      }

      a {
        position: absolute;
        top: $logo-top;
        left: 0;
        color: #ffffff;
        margin-bottom: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        text-decoration: none;
        font-weight: 600;
        font-size: 20px;

        .logo {
          flex: 0 0 20px;
          background-size: contain;
          min-width: 162px;
          min-height: 27px;
          margin-right: 10px;

          @supports (-webkit-mask: url($app-header-2023-logo-white-url) no-repeat center) or (mask: url($app-header-2023-logo-white-url) no-repeat center) {
            -webkit-mask: url($app-header-2023-logo-white-url) no-repeat center;
            mask: url($app-header-2023-logo-white-url) no-repeat center;
            background-color: #ffffff;
          }
        }
      }
    }
  }

  .content-container {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100vh;
    width: 75vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .content {
      width: 100%;
      max-width: 400px;
      margin-top: $spacing-top;
      margin-bottom: $spacing-bottom;
      margin-left: $space-between-side-panel;
      text-align: left;
      font-size: 0.875rem;

      .signup-wrapper {
        margin-bottom: $form-margin-spacing;
        color: $v2-text-light-gray;
        font-size: 15px;
      }

      .input-bubble-wrapper {
        position: relative;
        @include password-bubble;
      }

      > * {
        display: block;
      }

      h1 {
        text-align: left;
        font-size: 28px;
        margin-bottom: $form-margin-spacing;
      }

      .confirmation-text {
        margin-bottom: $form-margin-spacing;
      }

      a {
        font-weight: $base-font-medium-weight;
        text-decoration: none;
        color: $color-branding-purple-light;

        &:hover {
          color: $color-purple-hover-active;
        }
      }

      label {
        display: block;
        margin-left: 4px;
        margin-bottom: 7.5px;
        width: 100%;
        text-align: left;
        text-transform: uppercase;
        color: $input-label-color;
        font-size: 10px;
        font-weight: 100;
        letter-spacing: 1.4px;
      }

      div.error {
        padding: 10px 16px;
        background: $error-background-color;
        border: 1px solid $error-border-color;
        color: $error-text-color;
        border-radius: 4px;
        outline: none;
        box-sizing: border-box;
        margin-bottom: $form-margin-spacing;
        line-height: 1.4rem;
      }

      input[type='text'],
      input[type='email'],
      input[type='password'] {
        width: 100%;
        padding: 10px;
        border: 1px solid $input-border-color;
        border-radius: 4px;
        outline: none;
        box-sizing: border-box;
        color: $input-color;
        margin-bottom: $form-margin-spacing;

        &::placeholder {
          color: $input-placeholder-color;
        }

        &:focus {
          border-color: $input-border-color-focus;
        }
      }

      .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0;

        a {
          font-size: 11px;
          color: $color-dark-gray;

          &:hover {
            color: $color-purple-hover-active;
          }
        }

        button {
          height: 40px;
          padding: 10px 16px;

          * > {
            font-size: 20px !important;
          }
        }

        &.signin-button {
          justify-content: right;
          padding-top: 20px;
        }
      }

      .join-company-actions {
        justify-content: flex-start;

        > * {
          margin-right: 10px;
        }
      }

      .single-button {
        justify-content: flex-end;
      }

      .uploading-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .auto-join-question {
        color: $v2-text-light-gray;
      }

      .domain-name {
        color: $v2-text-gray;
      }
    }

    .forgot-password-animation {
      position: relative;
      width: 100%;
    }

    .signup-animation-container {
      position: relative;
      width: 100%;
    }

    .signup-already-have-account {
      position: absolute;
      bottom: 5rem;
    }

    .signup-options-container {
      position: absolute;
      bottom: 5rem;
      width: 100%;
      max-width: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .signup-options-title {
        width: 100%;
        text-align: left;
        color: $v2-button-grey;
        margin-bottom: $form-margin-spacing;
      }

      .signup-options {
        width: 100%;
        max-width: 570px;
        height: 70px;
        display: flex;
        justify-content: space-between;

        button:first-child {
          font-size: 20px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.1;
          letter-spacing: 1px;
          text-align: center;
          color: #a1a1a1;
          outline: none;
          background-color: #fff;
        }
        .signup-option-active {
          background-color: #f6f4f8 !important;
        }
        button {
          width: 25%;
          border: none;
          outline: none;

          .xero-label {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-weight: $base-font-medium-weight;
            font-family: $text-font-family;
          }

          .new-onelogin-logo {
            height: 34px;
            width: 34px;
          }
          .okta-logo {
            height: 20px;
            width: 61px;
          }

          .microsoft-entra-id-logo {
            width: 34px;
            height: 34px;
          }

          button {
            background-color: transparent !important;
            width: auto !important;
          }
          .new-google-button {
            height: 28px;
            background: none;
            width: auto;
          }
          img {
            margin: auto;
          }
        }
        .signup-option {
          background-color: #fff;
          &:hover {
            background-color: #f6f4f8 !important;
          }

          &:focus {
            background-color: #f6f4f8 !important;
          }
        }
      }
    }
  }

}

/*
 * breakpoint set to tablet size
 */
@media screen and (max-width: 768px) {
  .login-wrapper {
    flex-direction: column;

    .description-panel {
      height: 80px;
      width: 100vw;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      .description {
        height: 100%;
        width: 90%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin: 0;

        h2 {
          display: none;
        }

        a {
          position: initial;
          margin: 0;
        }
      }
    }

    .content-container {
      height: 100%;
      width: 100vw;

      .content {
        margin-top: $spacing-top-mobile;
        margin-bottom: 0;

        .buttons {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          button {
            width: 100%;
          }

          > * {
            margin-bottom: $form-margin-spacing;
          }
        }
      }

      .animation-container {
        display: none;
      }
    }
  }
}

/*
 * breakpoint for signup options
 */
@media screen and (max-height: 760px) {
  .login-wrapper {

    .content-container {

      .content {

        .signup-options-container {
          position: initial;
          bottom: initial;
          margin-top: $form-margin-spacing;
        }
      }
    }
  }
}

/*
 * breakpoint for 'already have an account?' on sign up page
 */
@media screen and (max-height: 500px) {
  .login-wrapper {

    .content-container {

      .content {

        .signup-already-have-account {
          position: initial;
          bottom: initial;
          margin-top: $form-margin-spacing;
        }
      }
    }
  }
}

/*
 * breakpoint for content placement
 */
@media screen and (max-width: 1400px) {
  .login-wrapper {

    .content-container {
      align-items: center;

      .content {
        margin-left: 0;
      }
    }
  }
}

/*
 * breakpoint for password requirements bubble
 */
@media screen and (max-width: 1400px) {
  .login-wrapper {

    .content-container {

      .content {

        .input-bubble-wrapper {

          .bubble {
            display: none;
          }
        }
      }
    }
  }
}
