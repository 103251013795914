.info-component-icon-wrapper {
	margin-left: 3px;
	background: url('images/cross-section-icons/info.svg') no-repeat;
	cursor: pointer;
	position: relative;
	outline: none;
	&.small {
		height: 14px;
		width: 14px;
		background-size: 14px !important;
		font-size: 0.85em;
		span {
			font-size: 0.85em;
		}
		.tooltip-right {
			left: -5px;
		}
		.tooltip-left {
			right: -5px;
		}
		.triangle-left {
			left: -3px;
		}
		.triangle-right {
			right: -3px;
		}
	}

	&.warning {
		background: url('images/cross-section-icons/info-warning.svg') no-repeat;
	}

	&.new-icon {
		background: url('images/v2/new_info_icon_grey.svg') no-repeat;
	}

	&.medium {
		height: 16px;
		width: 16px;
		background-size: 16px;
		.tooltip-right {
			left: -25px;
		}
		.tooltip-left {
			right: -25px;
		}
		.triangle-left {
			left: 5px;
		}
		.triangle-right {
			right: 2.5px;
		}
	}

	&.large {
		height: 21px;
		width: 21px;
		background-size: 21px;
		.tooltip-right {
			left: -25px;
		}
		.tooltip-left {
			right: -25px;
		}
		.triangle-left {
			left: 5px;
		}
		.triangle-right {
			right: 2.5px;
		}
	}
	.tooltip-left,
	.tooltip-right {
		white-space: normal;
		position: absolute;
		width: 250px;
		z-index: 99;
		background-color: $v2-tooltip-grey;
		padding: 16px;
		z-index: 99;
		color: $v2-text-gray;
		font-weight: 600;
		border-radius: 4px;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
		border: solid 1px $v2-grey;
		line-height: 16px;
		.multiline {
			font-size: 11px;
			margin-bottom: 16px;
			line-height: 1.36;
			.title {
				font-weight: 600;
				margin-bottom: 5px;
			}
			.text {
				font-weight: 400;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
		&.tooltip-wide {
			width: 500px
		}
	}

	.tooltip-top {
		top: -45px;
	}
	.tooltip-bottom {
		top: 35px;
	}
	.triangle-left,
	.triangle-right {
		content: '';
		position: absolute;
		width: 17px;
		height: 17px;
		border-bottom: none;
		border-right: none;
		background-color: $v2-tooltip-grey;
		box-shadow: -1px -1px 1px -1px rgba(0, 0, 0, 0.5);
		top: 27px;
		transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
		-o-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		z-index: 100;
	}
}
