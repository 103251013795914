.auto-scheduling-modal {
	&.ai-loader-active {
		min-height: 80px;
	}

	.warning {
		color: $v2-workflow-task-warning-red;
		font-size: 12px;
		max-width: 466px;

		span {
			text-transform: uppercase;
		}
	}

	.nested-dropdown-v2 {
		margin-bottom: 15px;
		.input-arrow-wrapper {
			border-radius: 4px;
			border: 1px solid $v2-backdrop-gray;
			&.expanded {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				border-bottom: 0;
				border-color: $v2-branding-purple;
			}
			.nested-dropdown-input {
				padding-bottom: 14px;
			}
		}
	}

	.checkbox-row {
		display: flex;
		align-items: center;
		margin-bottom: 8px;
		&.checkbox-row-first {
			margin-bottom: 16px;
		}
		.checkbox-label {
			margin-left: 8px;
			font-size: 13px;

			&.locked {
				opacity: 0.5;
			}
		}
		.phase-order-label {
			margin-bottom: 0;
		}
	}

	&.similar-project-modal {
		// margin-bottom: 30px;
		.warning-description {
			width: 340px;
			margin-bottom: 15px;
		}
		.similar-project-modal-content {
			.separation-line {
				height: 1px;
				background-color: $v2-button-very-light-grey;
			}
			.more-settings-button {
				display: flex;
				justify-content: flex-end;
				margin: 10px 0;
				font-size: 13px;
				color: $v2-text-gray;
				letter-spacing: 1px;
				span {
					margin-right: 5px;
				}
				span,
				div {
					cursor: pointer;
				}
			}
			.extra-settings {
				max-width: 340px;
				height: 0; // animate the height
				overflow: hidden; // prevent content from flowing while animation is taking place
				opacity: 1;
				transition: all 0.5s ease-in-out;

				&.expand-enter-done {
					height: 460px;
				}

				.category {
					font-size: 9px;
					letter-spacing: 1px;
					color: $v2-text-light-gray;
					text-transform: uppercase;
					margin: 20px 0 10px 0;
				}
			}
		}
	}

	.phase-order-label {
		font-size: 9px;
		font-weight: 500;
		letter-spacing: 1px;
		color: $v2-text-light-gray;
		text-transform: uppercase;
		margin-bottom: 4px;
	}
	.phase-order-container {
		border: 1px solid $app-border-color;
		padding: 8px;
		border-radius: 4px;
		.phase-order-item {
			width: 448px;
			position: relative;
			height: 30px;
			display: flex;
			align-items: center;
			font-size: 12px;

			.check-box {
				width: 22px;
				margin-right: 15px;
			}
			.drag-handle {
				position: absolute;
				left: 43px;
				top: 7px;
			}

			&.unselected:hover {
				.drag-handle-container {
					display: none;
				}
			}
		}
	}

	.warning-description {
		max-width: 570px;
		font-size: 12px;
		margin-bottom: 4px;
	}

	.warning-description-large {
		color: #535353;
		width: 804px;
		font-size: 13px;
		margin-bottom: 16px;
		display: flex;
		flex-direction: column;
	}
	.table-heading-row {
		padding: 4px 12px;
		.tooltip-container {
			margin-left: 5px;
		}
		span {
			display: inline-block;
			font-size: 9px;
			text-transform: uppercase;
			letter-spacing: 1px;
			color: #a1a1a1;
		}
		// keep the same width as the column in the table;
		.id {
			width: 50px;
		}
		.name {
			width: 310px;
		}
		.role {
			width: 180px;
		}
		.low,
		.high {
			margin-left: 8px;
			width: 86px;
		}
		.forecast {
			width: 50px;
		}
		&.remove-low-high {
			.name {
				width: 470px;
			}
		}
	}

	&.unestimated-tasks-modal {
		.estimates-project-container {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			.nested-dropdown-v2 {
				width: 340px;
			}
		}

		.checkbox-row {
			margin-top: 16px;
		}

		.table-heading-row {
			display: flex;
			span {
				align-items: center;
				display: flex;
			}
		}
	}

	.task-items-container {
		display: flex;
		.task-item-container {
			width: 804px;
			position: relative;
			overflow: hidden;
			cursor: pointer;

			&:not(:first-of-type) {
				.task-info-container {
					border-top: 0;
				}
			}

			&:hover,
			&:focus-within {
				background: $color-medium-gray;
			}

			.task-info-container {
				display: flex;
				align-items: center;
				padding: 4px 12px;
				border: 1px solid $app-border-color;
				justify-content: space-between;
				.estimate-input {
					margin-right: 8px;
				}
				.task {
					&-name,
					&-id {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						font-size: 13px;
					}
					&-id {
						width: 50px;
					}
					&-name {
						width: 310px;
					}
				}
				.task-name {
					&.remove-low-high {
						width: 470px;
					}
				}

				.role-placeholder {
					width: 180px;
				}

				.estimate-placeholder {
					width: 86px;
					margin-left: 8px;
				}

				.assigned-dropdown-wrapper {
					flex: 0 0 180px;
				}
				.input-container-v2 {
					width: 86px;
					margin-left: 8px;
					flex-shrink: 0;
				}
				.task-estimate {
					width: 86px;
					margin-left: 8px;
					flex-shrink: 0;
				}
				.forecast-text {
					white-space: nowrap;
					font-size: 13px;
					width: 50px;
					flex-shrink: 0;
					text-align: right;
				}
			}
			.corner-cover {
				position: absolute;
				top: -30px;
				right: -20px;
				width: 40px;
				height: 40px;
				background-color: white;
				border: 1px solid $app-border-color;
				transform: rotate(30deg);
			}
		}
	}

	.unapproved-phase-list {
		font-size: 13px;
	}
}
