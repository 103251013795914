.input-points {
	&.grouped-values {
		input {
			padding-right: 16px !important;
		}
		&:before {
			content: 'p*';
		}
	}
	&:before {
		content: 'p';
	}
}

.input-hours {
	&.grouped-values {
		input {
			padding-right: 16px !important;
		}
		&:before {
			content: 'h*';
		}
	}
	&:before {
		content: 'h';
	}
}

.input-hours-DAN {
	&.grouped-values {
		&:before {
			content: 't*';
		}
	}
	&:before {
		content: 't';
	}
}

.input-hours,
.input-hours-DAN,
.input-points {
	&.right {
		margin-left: auto;
		&::before {
			top: 7px;
			right: 10px;
			line-height: 17px;
		}
	}
	&.always-bordered {
		input {
			outline: 0;
			border: 1px solid $color-purple-hover-active;
			border-radius: 4px;
		}
	}
	&.grouped-values {
		input {
			padding-right: 23px;
		}
	}
	input {
		//transition: all 0.2s linear;
		border-radius: 4px;
		font-size: 13px;
		width: 60px;
		padding-right: 18px;
		padding: 6px 0 6px 0;
		border: 1px solid transparent;
		&:hover {
			border: 1px solid $v2-text-light-gray;
		}
		&:focus {
			outline: 0;
			border: 1px solid $color-purple-hover-active;
		}
		&.invalid {
			border: 1px solid red;
		}
		&:not(output):-moz-ui-invalid {
			box-shadow: none;
		}
		&.highlighted {
			color: $color-purple-hover-active;
			font-weight: $base-font-medium-weight;
			font-size: 14px;
		}
	}
	&.disabled {
		input,
		&:hover input {
			border: none;
			//border-color: $v2-grey;
		}
		&:before {
			color: $v2-text-light-gray;
		}
	}

	.input-title {
		position: absolute;
		top: -8px;
		font-size: 10px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 0.9;
		letter-spacing: 1px;
		color: $v2-button-very-light-grey-hovered;
		text-transform: uppercase;
	}
}
//old ui
.input-cell {
	.input-hours {
		&::before {
			top: 0;
		}
		input {
			font-size: 14.4px;
		}
	}
}
