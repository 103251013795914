.drop-down-v2 {
	//width: 224px;
	display: flex;
	flex-direction: column;
	position: relative;
	label {
		height: 11px;
		font-family: $v2-site-header-font-family;
		font-size: 9px;
		font-weight: 500;
		line-height: 1.22;
		letter-spacing: 1px;
		color: $v2-text-light-gray;
		text-transform: uppercase;
		margin-bottom: 5px;
		.required-extension {
			color: $negative-value;
		}
	}
	&:hover {
		input {
			border-color: $v2-date-picker-button-border-color;
			&:disabled {
				border-color: $app-border-color;
			}
		}
	}
	&.invalid {
		input {
			border-color: $v2-border-invalid;
		}
	}
	.error-message {
		color: red;
		font-size: 0.75rem;
	}
	::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		font-size: 12px;
	}
	::-moz-placeholder {
		/* Firefox 19+ */
		font-size: 12px;
	}
	:-ms-input-placeholder {
		/* IE 10+ */
		font-size: 12px;
	}
	:-moz-placeholder {
		/* Firefox 18- */
		font-size: 12px;
	}
	.create-new-text {
		position: absolute;
		white-space: nowrap;
		right: 12px;
		bottom: 8px;
		font-family: $text-font-family;
		font-size: 10px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: 0.5px;
		text-align: right;
		text-transform: uppercase;
		cursor: pointer;
		color: $v2-create-button-green;
		&:hover,
		&:focus {
			opacity: 0.85;
		}
	}
	input {
		&.create-new {
			padding-right: 130px;
		}
		width: 100%;
		max-width: 100%;
		cursor: pointer;
		height: 40px;
		//padding: 8px 30px 8px 8px;
		padding: 10px 30px 10px 16px;
		border: solid 1px $app-border-color;
		border-radius: 4px;
		font-family: $v2-site-header-font-family;
		font-size: 12px;
		//font-weight: 500;
		color: $v2-text-gray;
		background-color: white;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		transition: 0.1s border-color;
		&.expanded {
			border-bottom-left-radius: 0px;
			border-bottom-right-radius: 0px;
			border: solid 1px $v2-branding-purple !important;
			&.on-top {
				border-radius: 0px 0px 4px 4px;
				&.label-hidden {
					border-top: none;
				}
				// border-bottom: 2px solid $v2-border-gray !important;
			}
		}
		&.transparent-background {
			background-color: transparent;
		}
		&:focus {
			cursor: auto;
			outline: none;
		}
		&:disabled {
			background-color: $color-medium-gray;
			color: $v2-text-light-gray;
		}
	}
	.arrow {
		cursor: pointer;
		background: url('images/v2/components/dropdown/arrow-down.svg') no-repeat center;
		position: absolute;
		width: 16px;
		height: 40px;
		top: 17px;
		right: 0px;
		margin-right: 12px;
		outline: none;
		&.label-hidden {
			top: 0px;
		}
		&.expanded {
			background-image: url('images/v2/components/dropdown/arrow-up.svg');
		}
		&.disabled {
			background-image: url('images/v2/components/dropdown/arrow-disabled.svg');
			cursor: default;
		}
	}

	&.black {
		input {
			border: 2px solid $v2-negative-dropdown-grey;
			color: $color-white;
			background-color: $v2-negative-dropdown-grey;
			&.transparent-background {
				background-color: transparent;
			}
			&.expanded {
				&.on-top {
					border-bottom: 2px solid $v2-negative-dropdown-grey !important;
				}
			}
			&:focus {
				border-color: $v2-negative-dropdown-grey;
				background-color: $v2-negative-dropdown-grey;
			}
			&:disabled {
				background-color: $color-white !important;
				color: rgba(167, 167, 167, 0.5);
			}
		}
		&:hover {
			input {
				border: 2px solid $v2-negative-dropdown-grey;
				background-color: $v2-negative-dropdown-grey;
			}
		}
		.arrow {
			background: url('images/v2/components/dropdown/arrow-down-white.svg') no-repeat center;
			&.expanded {
				background-image: url('images/v2/components/dropdown/arrow-up-white.svg');
			}
			&.disabled {
				background-image: url('images/v2/components/dropdown/arrow-disabled.svg');
			}
		}
	}
	
	&.no-border {
		input {
			&.expanded {
				border-radius: 4px !important;
				border: solid 1px $app-border-color !important;
			}
		}
	}
}
.root-portal-container {
	.base-list {
		position: absolute;
		z-index: 999;
		background-color: white;
		border: 1px solid $v2-branding-purple;
		border-top: none;
		padding: 0;
		margin: 0;
		border-bottom-right-radius: 4px;
		border-bottom-left-radius: 4px;

		&.on-top {
			border-bottom-right-radius: 0px;
			border-bottom-left-radius: 0px;
			border-top-right-radius: 4px;
			border-top-left-radius: 4px;
			border-top: 1px solid $v2-branding-purple;
			border-bottom: 1px solid $v2-branding-purple;
			.purple-border {
				border-top: none;
			}
			&.no-on-top-border {
				border-bottom: none;
			}
		}
		&.two-level-list {
			background-color: $color-white;
			border-top: 1px solid $v2-border-gray;
			border-radius: 4px;
			top: 75px;
			padding: 0px;
			.nested-list-wrapper {
				width: 400px;
			}
			.top-level-option {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				display: flex;
				flex-direction: column;
				padding-top: 24px;
				padding-bottom: 24px;
				cursor: pointer;
				width: 100%;
				outline: 0;
				li {
					width: 100%;
				}
				&.expanded {
					.collapse-nested-options-icon {
						background: url('images/v2/components/dropdown/arrow-up.svg') no-repeat center;
					}
					.option-label-wrapper {
						border-bottom: solid 1px $app-border-color;
						color: $v2-branding-purple;
					}
					.option-label-wrapper {
						padding-bottom: 8px;
					}
				}
				&.collapsed {
					border-bottom: solid 1px $app-border-color;
					.collapse-nested-options-icon {
						background: url('images/v2/components/dropdown/arrow-down.svg') no-repeat center;
						transform: rotate(-90deg);
					}
				}
				&.no-border-bottom {
					border-bottom: none;
				}
				.option-label-wrapper {
					overflow: hidden;
					text-overflow: ellipsis;
					display: flex;
					justify-content: space-between;
					align-items: center;
					font-size: 13px;
					margin-left: 24px;
					margin-right: 24px;
					cursor: pointer;
					.collapse-nested-options-icon {
						padding-top: 2px;
						padding-left: 2px;
						border: none;
						width: 18px;
						height: 18px;
						min-width: 18px;
						min-height: 18px;
						outline: none;
						&:hover {
							opacity: 0.8;
						}
					}
					.option-count {
						margin-left: 4px;
						margin-right: 4px;
					}
					.option-label {
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
				.nested-list {
					list-style: none;
					padding: 0;
					.nested-option {
						cursor: pointer;
						&.padding-top {
							padding-top: 8px;
							padding-bottom: 8px;
						}
						&.selected,
						&:hover {
							background-color: $v2-workflow-task-selected-color;
						}
						.person-option {
							padding-left: 32px;
							height: 54px;
							display: flex;
							align-items: center;
							img {
								margin-right: 18px;
							}
							/* &:hover {
								background-color: $bulk-update-selected-color;
								cursor: pointer;
								border-top: solid 1px $v2-branding-purple;
								border-bottom: solid 1px $v2-branding-purple;
							} */
							.person-details {
								display: flex;
								flex-direction: column;
								.name {
									font-size: 14px;
									font-weight: 500;
									color: $v2-text-gray;
								}
								.email {
									font-size: 13px;
									color: $v2-text-light-gray;
								}
							}
						}
						.nested-option-label {
							font-size: 14px;
							font-weight: 500;
							color: $v2-text-gray;
							padding-left: 40px;
						}
					}
				}
			}
		}
		&.one-level-list {
			&.integration-loading {
				.loading-box {
					display: flex;
					flex-direction: column;
					height: 80px;
					&:hover,
					&.selected {
						background-color: $color-white;
					}
				}
				.loading-text {
					color: $v2-text-light-gray;
					text-align: center;
					font-style: italic;
				}
				.loading-item {
					background-color: $color-white;
					background-image: url('images/loaders/loader-32x32-brown.svg');
					background-repeat: no-repeat;
					background-size: 25px;
					background-position: center;
					height: 100%;
					width: 100%;
					display: block;
					margin: 0 auto;
				}
			}
			li {
				min-width: 150px;
				cursor: pointer;
				padding: 8px 29px 8px 15px;
				margin-left: 1px;
				min-height: 38px;
				font-size: 12px;
				line-height: 1.6;
				color: $v2-text-gray;
				// display flex no li is needed to display project option which uses <div> correctly in Edge and IE
				display: flex;
				flex: 0 0 auto;
				&:hover,
				&.selected {
					background-color: $v2-workflow-task-selected-color;
					//color: $v2-branding-purple;
					&.disabled {
						background-color: white;
						color: $v2-text-light-gray;
						cursor: auto;
					}
				}
				&.disabled {
					background-color: white;
					color: $v2-text-light-gray;
					cursor: auto;
				}
				&.retry-box {
					color: $v2-text-light-gray;
					font-style: italic;
					.retry-text {
						white-space: pre;
						font-weight: 500;
						color: $v2-text-gray;
						font-style: normal;
					}
				}
				.selected-value-indicator {
					height: 14px;
					width: 14px;
					background-image: url('images/components/scheduling/pink-checkmark.svg');
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
					align-self: center;
				}
				.option-project-wrapper {
					display: flex;
					flex: 1 1 auto;
					align-items: center;
					.option-project-id {
						font-size: 10px;
						flex: 0 0 30px;
						text-overflow: ellipsis;
						overflow: hidden;
						margin: 0;
					}
					.option-project-spacer {
						height: 2px;
						width: 10px;
						background-color: $color-white;
						margin-right: 4px;
					}
					&:hover {
						.option-project-spacer {
							background-color: $v2-branding-purple;
						}
					}
				}
				.person-container {
					display: flex;
					flex-direction: row;
					align-items: center;
					img {
						flex-shrink: 0;
					}
					.initials {
						margin-top: -1px;
					}
					.person-description {
						display: flex;
						flex-direction: column;
						justify-content: center;
						margin-left: 12px;
						padding-right: 8px;
						.person-name {
							font-size: 12px;
							font-weight: 500;
							line-height: 16px;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
							max-width: 100px;
						}
						.person-role {
							font-size: 10px;
							line-height: 14px;
							font-weight: normal;
						}
					}
				}
				&.nowrap {
					span {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}
		}
		.purple-border {
			.custom-scrollbar-div {
				> div:first-child {
					//width 110% needed to hide default scroll bar in mozilla firefox
					width: 110%;
					@include scroll-y;
				}
				@supports not (-moz-appearance: none) {
					/* non-firefox browsers */
					&.view-vertical > div {
						margin-bottom: 0px !important;
					}
				}
				.option {
					.jira-logo {
						display: inline-block;
						width: 30px;
						height: 12px;
						margin-right: 8px;
						margin-top: 2px;
						background: url('images/integrations/jira.svg') center center no-repeat;
						background-size: 30px 12px;
					}
					.jira-logo-new {
						display: inline-block;
						min-width: 18px;
						height: 18px;
						margin-right: 8px;
						margin-top: 2px;
						background: url('images/integrations/jira-logo.png') center center no-repeat;
						background-size: 18px 18px;
					}
					.unit4-logo {
						display: inline-block;
						height: 18px;
						min-width: 18px;
						margin-right: 8px;
						margin-top: 2px;
						background: url('images/integrations/unit4-small-logo.svg') center center no-repeat;
						background-size: 18px 18px;
					}
					.empty-logo {
						display: inline-block;
						height: 18px;
						min-width: 18px;
						margin-right: 8px;
						margin-top: 2px;
					}
				}
				.option-label {
					width: 100%;
					overflow-wrap: break-word;
				}
				.option-sublabel {
					font-size: 10px;
					font-weight: 400;
				}
			}
		}
		&.black {
			background-color: $v2-negative-dropdown-grey;
			border: 2px solid $v2-negative-dropdown-grey;
			&.on-top {
				border-top: 2px solid $v2-negative-dropdown-grey;
			}
			li {
				color: $color-white;
				&:hover,
				&.selected {
					background-color: $v2-background-dark-gray;
					color: $color-white;
				}
			}
		}
		&.header-project-select-dropdown {
			background-color: $v2-dark-purple;
			border: none;
			border-top: 2px solid $color-white;
			.purple-border {
				border-top: none;
			}
			li {
				color: $color-white;
				&:hover,
				&.selected {
					background-color: $color-white;
					color: $v2-dark-purple;
				}
			}
		}
		&.no-border {
			border: none !important;
			border-radius: 4px !important;
		}
		&.shadow {
			box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
		}
	}
}
