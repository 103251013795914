.notification {
	width: 100%;
	max-height: 77px;
	display: flex;
	flex-direction: row;
	user-select: none;
	text-decoration: none;
	border-bottom: 1px solid $app-border-color;
	&.wrapper {
		border-bottom: none;
	}
	.indicators {
		display: flex;
		flex-direction: row;
		.comment-icon,
		.blocked-icon,
		.bug-icon,
		.sub-task-icon,
		.file-icon,
		.high-priority-icon {
			margin-left: 8px;
			width: 15px;
			min-width: 15px;
			max-width: 15px;
			height: 15px;
			margin-top: 4px;
			background-size: 12px;
			background-position: center;
			background-repeat: no-repeat;
		}

		.high-priority-icon {
			background-image: url('images/v2/components/input-field/ico-priority.svg');
		}
		.blocked-icon {
			background-image: url('images/v2/components/workflow-task/v3/ico-blocked.svg');
		}
		.bug-icon {
			background-image: url('images/v2/components/workflow-task/v3/ico-bug.svg');
		}
		.sub-task-icon {
			margin-top: 5px;
			background-image: url('images/v2/components/workflow-task/v3/ico-subtask.svg');
		}
		.file-icon {
			background-image: url('images/v2/components/workflow-task/v3/ico-attachment.svg');
		}
		.comment-icon {
			margin-top: 5px;
			background-image: url('images/v2/components/workflow-task/v3/ico-comments.svg');
		}
	}
	.deadline-icon {
		height: 34px;
		width: 34px;
		background: url('images/header/profile/notifications.svg');
		background-size: 24px;
		background-position: center;
		background-repeat: no-repeat;
	}
	.bottom-row {
		display: flex;
		flex-direction: row;
	}
	.project-indicator {
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 42px;
		overflow: hidden;
		height: 20px;
		border-radius: 3px;
		font-size: 10px;
		font-weight: 500;
		margin-top: 3px;
		margin-right: 0;
		width: auto;
		float: left;
		.project-indicator-text {
			margin-right: 2px;
			margin-left: 2px;
		}
	}
	&.unread {
		background-color: #f3f7fd;
	}
	&:hover {
		background-color: $v2-list-hover-purple;
		.notification-dot-menu {
			.actions {
				opacity: 1;
			}
		}
	}
	.notification-icon {
		flex-grow: 0;
		width: 70px;
		padding: 7.5px 16px 26px 23px;
	}
	.notification-text-wrapper {
		flex-grow: 1;
		width: auto;
		padding-top: 10px;
		.notification-text {
			height: 32px;
			max-width: 300px;
			font-size: 12px;
			line-height: 1.33;
			color: $v2-text-gray;
			word-wrap: break-word;
			white-space: normal;
			overflow: hidden;
			text-overflow: ellipsis;
			&.full {
				max-width: 100%;
			}
		}
		.timestamp {
			margin-left: 8px;
			height: 14px;
			padding-top: 5px;
			font-size: 11px;
			color: $v2-text-light-gray;
		}
	}
	.notification-dot-menu {
		flex-grow: 0;
		padding: 19.5px 9px 0 28px;
		.actions {
			opacity: 0;
			&:focus,
			&.expanded {
				opacity: 1;
			}
		}
	}
}
