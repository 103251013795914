.date-span-picker-container {
	position: relative;
	display: flex;
	justify-content: flex-end;
	outline: none;
	width: 240px;
	.input-icon-collapsed,
	.input-icon-expanded {
		right: 5px;
		position: absolute;
		height: 16px;
		width: 16px;
		padding-right: 10px;
		background-size: 21px;
		background-repeat: no-repeat;
		cursor: pointer;
	}
	.input-icon-collapsed {
		background-image: url('images/components/select-input/collapsed.svg');
	}
	.input-icon-expanded {
		background-image: url('images/components/select-input/expanded.svg');
	}
	.input-clear-icon {
		top: 8px;
		right: 25px;
		position: absolute;
		height: 8px;
		width: 8px;
		padding-right: 10px;
		background-size: 8px;
		background-repeat: no-repeat;
		background-image: url('images/cross-section-icons/actions/remove-3.svg');
		cursor: pointer;
	}
	.input,
	.input-focused {
		border: 0;
		outline: 0;
		padding: 8px 0;
		text-overflow: ellipsis;
		display: inline-block;
		transition: background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
		background: transparent no-repeat;
		background-image: linear-gradient(to bottom, $material-focused-color, $material-focused-color), linear-gradient(to bottom, silver, silver);
		background-size: 0 2px, 100% 1px;
		background-position: 50% 100%, 50% 100%;
		width: 100%;
	}
	.input-focused {
		background-size: 100% 2px, 100% 1px;
		outline: none;
	}
	.select,
	.select-range {
		border: 1px solid $app-border-color;
		position: absolute;
		z-index: 9999;
		top: 38px;
		right: 0;
		border-radius: 2px;
		background-color: $color-white;
		padding: 16px 0;
		font-size: 15px;
		box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
		transition: transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
		transform: scaleY(1);
		transform-origin: left top 0px;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		.option:hover,
		.option-selected:hover {
			cursor: pointer;
			background-color: #e8e8e8;
		}
		.option,
		.option-selected {
			padding: 8px 5px 8px 24px;
		}
		.option-selected {
			color: $material-focused-color;
		}
		.date-pickers {
			display: flex;
			flex: 1;
			flex-direction: row;
			justify-content: center;
			.date-picker-wrapper {
				margin-right: 10px;
				.calendar-wrapper {
					width: 15em;
					.year-text {
						font-size: 14px;
					}
					.date-text {
						font-size: 26px;
					}
				}
			}
		}
	}
	.select {
		width: 100%;
		.options {
			width: 100%;
		}
	}
	.select-range {
		width: 600px;
	}
}
