@import 'components';
.new-insight {
	z-index: 99;
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: $color-white;
	display: flex;
	flex-direction: column;
	align-items: center;

	.container {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.new-insight-step1 {
		display: flex;
		flex-direction: column;
		.content {
			width: 830px;
			align-self: center;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			.card {
				position: relative;
				overflow: hidden;
				width: 400px;
				height: 225px;
				border-radius: 5px;
				box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
				cursor: pointer;
				transition: transform 0.25s;
				margin-bottom: 30px;
				background-size: auto 225px;
				background-position: right center;
				background-repeat: no-repeat;
				&.projects {
					background-image: url('images/sections/insights/categories/categories_purple.png');
				}
				&.people {
					background-image: url('images/sections/insights/categories/categories_red.png');
				}
				&.business {
					background-image: url('images/sections/insights/categories/categories_blue.png');
				}
				&.portfolio {
					background-image: url('images/sections/insights/categories/categories_pink.png');
				}
				&.timeRegistered,
				&.utilizationReport,
				&.taskReport,
				&.projectPortfolioReport {
					background-image: url('images/sections/insights/categories/categories_pink.png');
				}
				&.financialPortfolioReport {
					background-image: url('images/sections/insights/categories/categories_red.png');
				}
				.text-container {
					width: 258px;
					height: 225px;
					padding: 20px;
					.title {
						font-size: 24px;
						font-weight: bold;
						letter-spacing: 0.8px;
						margin: 0 0 0.5em 0;
					}
				}
				.beta {
					float: right;
					margin-top: 12px;
					margin-right: 4px;
				}

				.svg {
					margin-top: -100px;
				}
				&:hover {
					transition: transform 0.25s;
					-webkit-transform: scale(1.02);
					-ms-transform: scale(1.02);
					transform: scale(1.02);
				}
			}
		}
	}
	.new-insight-step2 {
		display: flex;
		flex-direction: column;
		align-items: center;
		.step {
			text-align: center;
		}
		.description {
			text-align: center;
		}
		.components {
			max-width: 100%;
			flex: 1 1 auto;
			display: flex;
			flex-wrap: wrap;
			.card {
				margin: 5px;
				width: 200px;
				height: 200px;
				border: 1px solid $app-border-color;
				border-radius: 3px;
				box-shadow: 0 1px 2px 0 #e6e6e6;
				cursor: pointer;
				display: flex;
				flex-direction: column;
				transition: transform 0.25s;
				position: relative;
				&:hover {
					transition: transform 0.25s;
					-webkit-transform: scale(1.02);
					-ms-transform: scale(1.02);
					transform: scale(1.02);
				}
				&.selected {
					.title {
						background-color: #3e5cf2;
						color: $color-white;
					}
				}
				&.disabled {
					cursor: default;
					.title {
						background-color: #bdbdbd;
						color: $color-white;
					}
				}
				&.no-finance {
					cursor: default;
					&:hover {
						transition: none;
						-webkit-transform: none;
						-ms-transform: none;
						transform: none;
					}
				}
				.title {
					flex: 0 0 50px;
					text-align: center;
					font-size: 18px;
					font-weight: 700;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					border-radius: 0 0 3px 3px;
				}
				.picture {
					flex: 1;
					margin: 8px;
					background-position: center;
					background-repeat: no-repeat;
					background-size: contain;
				}
				.coming-soon {
					font-weight: 700;
					position: relative;
					top: 50px;
					color: white;
					width: 100%;
					text-align: center;
					transform: rotate(-20deg);
					background: rgba(0, 0, 0, 0.7);
					padding: 0.5rem;
				}
				.ai-frame {
					overflow: hidden;
					position: absolute;
					height: 100px;
					width: 100px;
					.ai-powered {
						position: absolute;
						width: 150px;
						top: 15px;
						right: 20px;
						text-align: center;
						transform: rotate(-35deg);
						background: #bdbdbd;
						padding: 0.25rem;
						.ai-label {
							font-weight: 700;
							position: relative;
							color: white;
							width: 100%;
							font-size: 13px;
							margin-left: 40px;
						}
					}
				}
				.top-wrapper {
					display: flex;
					flex-direction: row;
					justify-content: flex-end;
					position: relative;
					z-index: 10;

					.info {
						position: relative;
						width: 24px;
						height: 24px;
						top: 10px;
						margin-right: 10px;
						background-color: transparent;
						border: none;
						background-repeat: no-repeat;
						background-image: url('images/sections/insights/builder/info.svg');
						&:hover {
							background-image: url('images/sections/insights/builder/info-active.svg');
						}
						.text {
							text-align: left;
							padding: 10px;
							position: relative;
							height: auto;
							width: 300px;
							background-color: $color-white;
							top: 25px;
							left: -276px;
							z-index: 999;
							border: 1px solid #e6e6e6;
						}
					}
				}
			}
		}
	}
	.footer {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		width: 100%;
		flex: 0 0 auto;
		padding-top: 24px;
		padding-bottom: 50px;
		button {
			@include primary-button;
			margin-left: 20px;
		}
	}

	.new-insight-step1,
	.new-insight-step2,
	.new-insight-step3,
	.footer {
		width: 100%;
		max-width: 1260px;
	}

	@media only screen and (max-width: 1350px) {
		.new-insight-step1,
		.new-insight-step2,
		.new-insight-step3,
		.footer {
			max-width: 840px;
		}
	}
	@media only screen and (max-width: 930px) {
		.new-insight-step1,
		.new-insight-step2,
		.new-insight-step3,
		.footer {
			max-width: 420px;
		}
	}
}

.new-insight-header {
	padding-top: 2rem;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	align-self: center;
	button {
		background-color: transparent;
		border: none;
		background-position: center;
		background-repeat: no-repeat;
	}
	.back-button {
		width: 24px;
		height: 24px;
		background-size: 24px;
		background-image: url('images/cross-section-icons/actions/previous.svg');
		&:hover {
			background-image: url('images/cross-section-icons/actions/previous-active.svg');
		}
	}
	.cancel-button {
		width: 25px;
		height: 25px;
		background-size: 25px;
		background-image: url('images/components/overlay/close.svg');
		&:hover {
			background-image: url('images/components/overlay/close-active.svg');
		}
	}
}
.new-insight-name {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 18px;
	font-weight: $base-font-medium-weight;

	input {
		border: 0;
		outline: 0;
		background: transparent;
		border-bottom: 1px solid #979797;
		width: 350px;
		font-size: 18px;
		margin-top: 15px;
		&::placeholder {
			color: #b1b1b1;
			font-size: 18px;
			text-align: center;
		}
	}
}

.new-insight-step {
	text-align: center;
}
.new-insight-description {
	text-align: center;
}
