.settings-notifications {
	.slider-container {
		margin-left: 10px;
		display: flex;
		flex-direction: row;
		align-items: center;
		.description {
			margin: 0 0 0 40px;
		}
	}
	.master,
	.secondary {
		border-bottom: 1px solid $app-border-color;
		padding-bottom: 40px;
		margin-bottom: 40px;
		.title {
			margin: 0 0 32px 0;
		}
	}
	.secondary {
		padding-bottom: 16px;
	}
	.secondary,
	.task-updates-container {
		.slider-container {
			margin-bottom: 24px;
			.slider-description-input {
				width: 22px;
				outline: 0;
				border: 1px solid $app-border-color;
				padding: 2px 0 2px 0;
				margin: 0 4px 0 4px;
				text-align: center;
				transition: 0.4s;
				&:disabled {
					background-color: $app-border-color;
				}
			}
		}
	}
	.task-updates-container {
		padding-bottom: 128px;
		.frequency-container {
			margin-left: 85px;
			margin-top: 40px;
			h4 {
				margin-top: 24px;
				margin-bottom: 8px;
			}
		}
		.show-advanced-settings-button {
			background-size: 30px;
			background-position: right bottom -6px;
			background-repeat: no-repeat;
			margin-left: 20px;
			padding-right: 30px;
			color: #7479e8;
			border: none;
			outline: 0;
			&.expanded {
				background-image: url('images/sections/profile/notifications/collapse.svg');
			}
			&.collapsed {
				background-image: url('images/sections/profile/notifications/expand.svg');
			}
		}
		.advanced-settings-container {
			margin-left: 75px;
			margin-right: 75px;
			.advanced-settings-description {
				margin-bottom: 16px;
				margin-left: 10px;
				font-style: italic;
			}
			.column-container {
				display: flex;
				flex-direction: row;
				.column {
					flex: 1 0 0;
					&.first {
						flex-grow: 0;
						white-space: nowrap;
						margin-right: 20px;
					}
				}
			}
		}
		.select-wrapper {
			//Disabled select is 1px higher so the container is jumping a bit without this
			margin-bottom: 1px;
		}
		.select-wrapper,
		.select-wrapper-locked {
			width: 240px;
			.label,
			.label-focused {
				padding: 0;
			}
			.select {
				top: 34px;
			}
		}
	}
	&.v2 {
		font-size: 13px;
		margin: 16px 24px;
		border: 1px solid $app-border-color;
		border-radius: 4px;
		padding: 0 16px;
		color: $v2-text-gray;
		.slider-container {
			margin: 0;
			padding-top: 10px;
			padding-bottom: 10px;
			border-top: 1px solid $app-border-color;
		}
		.master-section {
			.description {
				margin: 0;
				width: 232px;
			}
			.section-header {
				border-bottom: 1px solid $app-border-color;
			}
			.slider-container {
				margin: 0;
				padding-top: 10px;
				border-top: none;
			}
		}

		.advanced-section {
			margin-left: 0px;
			margin-right: 0px;
			.first-advanced-section {
				padding-top: 10px;
				padding-bottom: 10px;
				border-top: 1px solid $app-border-color;
				.slider-section {
					display: flex;
					flex-direction: row;
				}
			}
			.show-advanced-settings-button {
				padding-left: 0;
				padding-top: 6px;
				margin-bottom: 10px;
				text-align: left;
				font-weight: 600;
				font-size: 13px;
				background-size: 30px;
				background-position: right bottom -6px;
				background-repeat: no-repeat;
				padding-right: 36px;
				border: none;
				outline: 0;
				&.expanded {
					background-image: url('images/sections/profile/notifications/collapse_gray.svg');
				}
				&.collapsed {
					background-image: url('images/sections/profile/notifications/expand_gray.svg');
				}
			}
			.second-advanced-section {
				padding-left: 58px;
				border-top: 1px solid $app-border-color;
				.advanced-settings-description {
					padding-top: 24px;

					color: $v2-text-light-gray;
					font-style: italic;
				}
				.column-description {
					flex: 0 0 412px;
				}
				div:nth-child(2) {
					border: none;
				}
			}
		}

		.section-header {
			min-height: 20px;
			display: flex;
			padding-top: 24px;
			padding-bottom: 10px;
			font-weight: 500;
			.column-description {
				font-size: 16px;
			}
		}
		.column-description {
			line-height: normal;
			flex: 0 0 470px;
			margin: 0 15px 0 0;
			.input-section {
				display: flex;
				align-items: center;
				.second-part {
					text-transform: lowercase;
					margin-left: 10px;
				}
				.first-part {
					margin-right: 10px;
				}
				.input-value-container {
					height: 30px;
					width: 58px;
				}
			}
		}
		.column-app,
		.column-email {
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 0 0 115px;
		}
		.column-assigned-projects,
		.column-owned-projects {
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 0 0 150px;
		}
	}
}
