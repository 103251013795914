.sprint-unfinished-work-modal {
	margin-bottom: 32px;
	.warning-message-component {
		display: flex;
		flex-direction: column;
		margin-bottom: 22px;
		.message {
			padding-right: 17px;
		}
	}
	.unfinished-tasks {
		margin-left: 36px;
		display: flex;
		flex-direction: column;
		width: 400px;
		.unfinished-tasks-title {
			font-size: 9px;
			font-weight: 500;
			line-height: 1.22;
			letter-spacing: 1px;
			color: $v2-text-light-gray;
			text-transform: uppercase;
			margin-bottom: 5px;
			font-family: $v2-site-header-font-family;
		}
		.task-entry {
			width: 400px;
			padding: 4px 0;
			border-bottom: 1px solid $v2-table-border-color;
			&:last-child {
				border: none;
			}
			.task-info {
				width: 380px;
				word-break: break-word;
				color: $v2-text-gray;
			}
		}
	}
	.text-and-radio-button {
		margin-left: 36px;
		display: flex;
		flex-direction: row;
		.unfinished-work-modal-text {
			display: flex;
			flex-direction: row;
			white-space: nowrap;
			flex-grow: 1;
		}
		.unfinished-work-modal-option-text {
			margin-right: 4px;
			font-size: 16px;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: $v2-text-gray;
		}
		.option-text-bold {
			font-weight: bold;
		}
		.radio-button {
			margin-top: 16px;
			margin-left: 102px;
		}
	}
	.unfinished-work-modal-description {
		padding: 0 17px 0 17px;
		margin-top: 40px;
		font-family: $text-font-family;
		font-size: 14px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.5;
		letter-spacing: 0.5px;
		text-align: center;
		color: $v2-text-gray;
	}
}
