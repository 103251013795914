.project-retainer-periods {
	height: 100%;
	display: flex;
    flex-direction: column;
	flex: auto;
	.retainer-periods-selector,
	.retainer-period-column-header,
	.retainer-periods-container,
	.retainer-totals-wrapper {
		margin: 0 32px;
	}
	.header-bar {
		margin: 0;
	}
	.retainer-periods-container {
		padding-bottom: 125px;
	}
	.header-bar {
		padding: 15px 0;
	}
	.empty-periods-text {
		font-size: 24px;
		margin: 16px;
		color: $v2-text-light-gray;
	}
	.suffixed-text {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.retainer-totals-wrapper {
		min-height: 295px;
		margin-bottom: 30px;
		&.empty {
			position: relative;
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			.avatar-and-text {
				position: absolute;
				top: 5%;
				left: 50%;
				transform: translateX(-50%);
			}
			.avatar-wrapper {
				position: relative;
				width: 100%;
				height: 400px;
				.avatar {
					background: url('images/avatars/empty_state_retainer_avatar.svg');
					position: absolute;
					width: 400px;
					height: 400px;
					left: 50%;
					transform: translateX(-50%);
				}
			}
			.text-and-btn-wrapper {
				position: relative;
				.empty-text {
					.empty-header {
						font-family: $heading-font-family;
						font-size: 26px;
						font-weight: 600;
						font-style: normal;
						font-stretch: normal;
						letter-spacing: normal;
						text-align: center;
						color: $v2-text-gray;
						margin-bottom: 4px;
					}
					.empty-tagline {
						font-family: $heading-font-family;
						font-size: 18px;
						font-weight: 400;
						font-style: normal;
						font-stretch: normal;
						line-height: 1.63;
						letter-spacing: normal;
						text-align: center;
						color: $v2-text-light-gray;
						margin-bottom: 21px;
					}
				}
				.empty-state-btn {
					position: absolute;
					//bottom: 0;
					left: 50%;
					transform: translateX(-50%);
					text-align: center;
					width: 180px;
					height: 38px;
					.text {
						font-size: 14px;
					}
				}
			}
		}
		.suffixed-text {
			&.price {
				font-size: 18px;
			}
		}
		.retainer-dashboard-wrapper {
			margin-left: 8px;
			overflow: hidden;
			width: 300px;
			.retainer-dashboard-header-text {
				font-size: 16px;
				font-weight: 600;
				font-style: normal;
				font-stretch: normal;
				line-height: 1;
				letter-spacing: normal;
				color: $v2-text-gray;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
		.data-visuals {
			min-height: 520px;
			display: flex;
			flex-direction: row;
			.chart-and-title {
				display: flex;
				flex-direction: column;
				max-height: 520px;
				min-height: 520px;
				flex-grow: 1;
				// border: solid 1px $app-border-color;
				// border-top: none;
				.chart-sizer {
					min-width: 100%;
					margin: 0;
					max-height: 495px;
					min-height: 495px;
					position: relative;
					canvas {
						position: absolute;
						left: 0;
						top: 0;
					}
				}
				.retainer-status-header {
					margin-left: 16px;
					transform: translateY(-4px);
					padding: 4px;
					font-size: 16px;
					font-weight: 600;
					font-style: normal;
					font-stretch: normal;
					line-height: 1;
					letter-spacing: normal;
					color: $v2-text-gray;
				}
			}
			.retainer-type,
			.retainer-total-dates,
			.retainer-total-periods-and-progress,
			.retainer-total-period-length,
			.retainer-average-period-work,
			.retainer-total-period-work {
				background: $v2-input-disabled-grey;
			}
			.retainer-dashboard-wrapper {
				display: flex;
				flex-direction: column;
				.retainer-dashboard-contents {
					margin-top: 14px;
					border: 1px solid $app-border-color;
					display: flex;
					flex-direction: column;
					height: 389px;
					> div {
						flex-grow: 1;
					}
					.totals-box-column {
						margin: auto 0;
					}
					.half-text-wrapper {
						flex-grow: 1;
						flex-basis: 50%;
					}
					.text-wrapper {
						flex-grow: 0;
					}
					.retainer-total-data {
						margin-right: 20px;
						font-size: 18px;
						font-weight: normal;
						font-style: normal;
						font-stretch: normal;
						line-height: 1;
						letter-spacing: normal;
						text-align: right;
						color: $v2-text-gray;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
					.retainer-total-text {
						margin-left: 20px;
						font-size: 13px;
						font-weight: normal;
						font-style: normal;
						font-stretch: normal;
						letter-spacing: normal;
						color: $v2-text-gray;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
					.retainer-type {
						display: flex;
						flex-direction: row;
						.retainer-total-data {
							text-align: right;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							font-size: 12px;
						}
					}
					.retainer-total-dates {
						display: flex;
						flex-direction: row;
						.retainer-total-data {
							text-align: right;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							font-size: 16px;
						}
					}
					.retainer-total-periods-and-progress {
						display: flex;
						flex-direction: row;
						.retainer-total-periods,
						.retainer-total-progress {
							display: flex;
							flex-direction: row;
							flex-grow: 1;
							.retainer-total-text,
							.retainer-total-data {
								flex-grow: 1;
							}
						}
						.retainer-total-periods {
							border-right: 1px solid $app-border-color;
						}
					}
					.retainer-type,
					.retainer-total-dates,
					.retainer-total-periods-and-progress,
					.retainer-total-period-length {
						border-bottom: 1px solid $app-border-color;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
					.retainer-total-period-length,
					.retainer-time-entry-conflicts,
					.retainer-average-period-work,
					.retainer-total-period-work {
						display: flex;
						flex-direction: row;
					}
					.retainer-total-period-work {
						border-bottom: 1px solid $app-border-color;
					}
					.retainer-time-entry-conflicts {
						background: $v2-retainer-conflict-red;
						.retainer-total-text,
						.retainer-total-data {
							color: $color-white;
						}
						&.none {
							background: $v2-input-disabled-grey;
							border-top: solid 1px $app-border-color;
							.retainer-total-text,
							.retainer-total-data {
								color: $v2-text-gray;
							}
						}
					}
				}
				.grow {
					flex-grow: 1;
				}
			}
		}
	}
	.retainer-periods-selector {
		margin-bottom: 14px;
	}
	.retainer-period-column-header {
		display: flex;
		flex-direction: row;
		width: calc(100% - 64px); // 32px margin on each side
		position: sticky;
		background: white;
		height: 30px;
		color: $v2-text-gray;

		.period-select-checkbox {
			padding: 0 12px;
			width: 46px;
		 }
		.retainer-period-column-name {
			font-family: $text-font-family;
			font-size: 9px;
			font-weight: 500;
			text-transform: uppercase;
			font-style: normal;
			font-stretch: normal;
			line-height: 1;
			letter-spacing: 1px;
			flex: 1 1 0;
			text-align: left;
			color: $v2-text-gray;
			margin-left: 10px;
			&:first-child {
				.period-name {
					padding-left: 16px;
				}
			}
			&:last-child {
				margin-right: 56px;
			}
			&.dates {
				min-width: 201px;
			}
			&.right {
				text-align: right;
			}
			&.center {
				text-align: center;
			}
		}
	}
	.retainer-periods-selector {
		display: flex;
		flex-direction: row;
		.retainer-period-selector-btn {
			height: 30px;
			width: 172px;
			text-align: center;
			border-right: solid 1px $v2-grey;
			&:last-child {
				border-right: none;
			}
		}
	}
	.retainer-periods-container {
		display: flex;
		flex-direction: column;
		.retainer-period-wrapper {
			margin-bottom: 16px;
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			&.period-locked {
				border: 2px solid red;
			}
		}
	}
}
