.task-modal-v3-github-section {
	min-height: 57px;
	border-top: 1px solid $app-border-color;
	display: flex;
	padding-top: 15px;
	padding-bottom: 15px;
	flex-direction: column;
	margin: 0 58px;

	&:focus {
		outline: none;
		.expand-icon {
			path {
				stroke: $color-martin-tertiary-light-purple;
			}
		}
	}

	.link-blue {
		color: #4a90e2;
	}

	.purple {
		color: #7011eb;
	}

	.bold {
		font-weight: 500;
	}

	.dark-grey {
		color: $v2-text-gray;
	}

	.light-grey {
		color: $v2-text-light-gray;
	}

	.github-object {
		.object-description {
			font-size: 13px;
		}
	}

	.github-header-container {
		display: flex;
		align-items: center;
		align-self: flex-start;
		width: 100%;

		.expand-icon {
			margin-left: 20px;
		}
		.github-icon {
			flex-shrink: 0;
			margin-right: 8px;
		}
		.github-text {
			font-size: 13px;
			flex-shrink: 0;
			flex-grow: 0;
			margin-right: 44px;
		}
		.event-count {
			font-size: 14px;
			letter-spacing: 0.5px;
		}
		.github-header-left-section {
			display: flex;
			flex-direction: row;
			align-items: center;
			.github-link-message {
				cursor: pointer;
			}
		}
		.github-header-right-section {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-left: auto;
			text-transform: uppercase;
			font-size: 10px;
			letter-spacing: 0.5px;
			cursor: pointer;

			&.disabled {
				cursor: default;

				.pull-request:hover,
				.issues:hover {
					color: $v2-text-gray !important;
				}
			}

			.pull-request {
				margin-left: auto;
			}

			.issues {
				margin-left: 51px;
			}

			.branch {
				margin-left: 51px;
			}

			.pull-request:hover,
			.issues:hover,
			.branch:hover {
				color: #7011eb;
			}
		}
	}

	.github-expanded {
		padding: 20px 0 20px 58px;
	}
}
