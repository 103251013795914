input[type='number'] {
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
	}
	-moz-appearance: textfield;
	-ms-appearance: textfield;
	padding: 6px 0 6px 0;
	width: 100%;
	border: 1px solid transparent;
}

.input-hours,
.input-hours-DAN,
.input-points,
.input-EUR,
.input-AUD,
.input-CAD,
.input-SGD,
.input-HKD,
.input-MXN,
.input-NZD,
.input-USD,
.input-BRL,
.input-CNY,
.input-DKK,
.input-NOK,
.input-SEK,
.input-INR,
.input-JPY,
.input-TTD,
.input-GBP,
.input-BGP,
.input-RUB,
.input-ZAR,
.input-KRW,
.input-CHF,
.input-TRY,
.input-CZK,
.input-PLN,
.input-THB {
	position: relative;
}

.input-hours.left,
.input-hours-DAN.left,
.input-points.left input,
.input-EUR.left input,
.input-AUD.left input,
.input-CAD.left input,
.input-SGD.left input,
.input-HKD.left input,
.input-MXN.left input,
.input-NZD.left input,
.input-USD.left input,
.input-CNY.left input,
.input-DKK.left input,
.input-NOK.left input,
.input-SEK.left input,
.input-INR.left input,
.input-JPY.left input,
.input-TTD.left input,
.input-GBP.left input,
.input-BGP.left input,
.input-RUB.left input,
.input-ZAR.left input,
.input-KRW.left input,
.input-TRY.left input,
.input-PLN.left input,
.input-THB.left input {
	padding-left: 18px;
	text-align: left !important;
}

.input-CZK.left input,
.input-CHF.left input,
.input-BRL.left input {
	padding-left: 24px;
	text-align: left;
}

.input-hours.right input,
.input-hours-DAN.right input,
.input-points.right input,
.input-EUR.right input,
.input-AUD.right input,
.input-CAD.right input,
.input-SGD.right input,
.input-HKD.right input,
.input-MXN.right input,
.input-NZD.right input,
.input-USD.right input,
.input-CNY.right input,
.input-DKK.right input,
.input-NOK.right input,
.input-SEK.right input,
.input-INR.right input,
.input-JPY.right input,
.input-TTD.right input,
.input-GBP.right input,
.input-BGP.right input,
.input-RUB.right input,
.input-ZAR.right input,
.input-KRW.right input,
.input-TRY.right input,
.input-PLN.right input,
.input-THB.right input {
	padding-right: 18px;
	text-align: right;
}

.input-CZK.right input,
.input-CHF.right input,
.input-BRL.right input {
	padding-right: 24px;
	text-align: right;
}

.input-hours:before,
.input-hours-DAN:before,
.input-points:before,
.input-EUR:before,
.input-AUD:before,
.input-CAD:before,
.input-SGD:before,
.input-HKD:before,
.input-MXN:before,
.input-NZD:before,
.input-USD:before,
.input-BRL:before,
.input-CNY:before,
.input-DKK:before,
.input-NOK:before,
.input-SEK:before,
.input-INR:before,
.input-JPY:before,
.input-TTD:before,
.input-GBP:before,
.input-BGP:before,
.input-RUB:before,
.input-ZAR:before,
.input-KRW:before,
.input-CHF:before,
.input-TRY:before,
.input-CZK:before,
.input-PLN:before,
.input-THB:before {
	position: absolute;
	top: 0;
}

.input-points:before {
	content: 'p';
}

.input-hours:before {
	content: 'h';
}

.input-hours-DAN:before {
	content: 't';
}

.input-EUR:before {
	content: '€';
}

.input-AUD:before,
.input-CAD:before,
.input-SGD:before,
.input-HKD:before,
.input-MXN:before,
.input-NZD:before,
.input-USD:before {
	content: '$';
}

.input-BRL:before {
	content: 'R$';
}

.input-DKK:before,
.input-NOK:before,
.input-SEK:before {
	content: 'kr';
}

.input-INR:before {
	content: '₹';
}

.input-JPY:before,
.input-CNY:before {
	content: '¥';
}

.input-TTD:before {
	content: '$';
}

.input-GBP:before {
	content: '£';
}

.input-BGP:before {
	content: '£';
}

.input-RUB:before {
	content: '₽';
}

.input-ZAR:before {
	content: 'R';
}

.input-KRW:before {
	content: '₩';
}

.input-CHF:before {
	content: 'Fr.';
}

.input-TRY:before {
	content: '₺';
}

.input-CZK:before {
	content: 'Kč';
}
.input-PLN:before {
	content: 'zł';
}
.input-THB:before {
	content: '฿';
}

.input-hours.left:before,
.input-hours-DAN.left:before,
.input-points.left:before,
.input-EUR.left:before,
.input-AUD.left:before,
.input-CAD.left:before,
.input-SGD.left:before,
.input-HKD.left:before,
.input-MXN.left:before,
.input-NZD.left:before,
.input-USD.left:before,
.input-BRL.left:before,
.input-CNY.left:before,
.input-DKK.left:before,
.input-NOK.left:before,
.input-SEK.left:before,
.input-INR.left:before,
.input-JPY.left:before,
.input-TTD.left:before,
.input-GBP.left:before,
.input-BGP.left:before,
.input-RUB.left:before,
.input-ZAR.left:before,
.input-KRW.left:before,
.input-CHF.left:before,
.input-TRY.left:before,
.input-CZK.left:before,
.input-PLN.left:before,
.input-THB.left:before {
	left: 5px;
}

.input-hours.right:before,
.input-hours-DAN.right:before,
.input-points.right:before,
.input-EUR.right:before,
.input-AUD.right:before,
.input-CAD.right:before,
.input-SGD.right:before,
.input-HKD.right:before,
.input-MXN.right:before,
.input-NZD.right:before,
.input-USD.right:before,
.input-BRL.right:before,
.input-CNY.right:before,
.input-DKK.right:before,
.input-NOK.right:before,
.input-SEK.right:before,
.input-INR.right:before,
.input-JPY.right:before,
.input-TTD.right:before,
.input-GBP.right:before,
.input-BGP.right:before,
.input-RUB.right:before,
.input-ZAR.right:before,
.input-KRW.right:before,
.input-CHF.right:before,
.input-TRY.right:before,
.input-CZK.right:before,
.input-PLN.right:before,
.input-THB.right:before {
	right: 5px;
}
