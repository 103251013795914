.project-third-info {
	transition: all 0.5s ease-in-out;
	max-height: 544px;
	min-height: 110px;
	opacity: 1;
	&.empty {
		min-height: 0px;
		max-height: 0px;
		opacity: 0;
	}
	.added-labels-wrapper {
		label {
			color: $color-white;
		}
	}
	display: flex;
	flex-direction: column;
	.project-labels-controls-wrapper {
		margin-bottom: 25px;
		min-height: 50px;
		max-height: 200px;
		.dropdown-container {
			width: 282px;
		}
	}
	.project-type-buttons,
	.budget-type-buttons {
		button {
			height: 34px;
		}
	}
	.radio-options {
		display: flex;
		align-items: baseline;
		margin-bottom: 23px;
		margin-right: 8px;
		.duplicate-toggle,
		.sprints-radio,
		.drop-down-v2,
		.duplicate-options,
		.pre-create-radio {
			margin-right: 64px;
			margin-top: 4px;
			top: -6px;
			position: relative;
		}
		.duplicate-options {
			margin-right: 0px;
		}
		.radio-button-controls-wrapper,
		.checkbox-controls-wrapper {
			margin-top: 6px;
			display: flex;
			align-items: center;
			span {
				cursor: pointer;
			}
		}
		.sprints {
			display: flex;
			flex-direction: row;
			.input-container-v2 {
				width: 150px;
				.input-title {
					margin-top: 5px;
				}
			}
		}
		.checkbox-controls-wrapper {
			margin-top: 4px;
			margin-bottom: 5px;
		}
		.duplicate-project-use-sprints {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			.input-container-v2 {
				width: 90px !important;
			}
			.duplicate-options {
				margin-left: 24px;
				display: flex;
				flex-direction: column;
				.control-label {
					font-size: 12px;
				}
			}

			.project-labels-controls-wrapper {
				display: flex;
				flex-direction: column;
			}
		}
	}
	.type-label {
		display: flex;
		flex-direction: row;
		.required-extension {
			//color: $negative-value;
			width: 10px;
			height: 10px;
			background-size: 10px;
			background-image: url('images/v2/components/information_icon.svg');
			margin-left: 3px;
			margin-bottom: 5px;
		}
	}
	.project-types {
		min-height: 87px;
		max-height: 353px;
		transition: all 0.5s ease-in-out;
		&.empty {
			min-height: 0px;
			max-height: 0px;
		}
		.project-type {
			.project-type-buttons {
				display: flex;
				flex-direction: row;
				margin-bottom: 32px;
				.custom-button-container {
					flex: 1;
				}
			}
		}
		.budget-type {
			min-height: 70px;
			max-height: 270px;
			&.empty {
				min-height: 0;
				max-height: 0px;
			}

			.budget-type-buttons {
				display: flex;
				flex-direction: row;
				margin-bottom: 18px;
				.tooltip-container,
				.custom-button-container {
					flex: 1;
				}
			}
			.budget-type-specific-values {
				.price-section {
					display: flex;
					flex-direction: row;
					min-height: 64px;
					max-height: 194px;
					transition: all 0.5s ease-in-out;
					&.fixed_price,
					&.time_and_materials {
						&:not(.edit-mode) {
							opacity: 1;
							min-height: 64px;
							max-height: 64px;
						}
					}
					&.time_and_materials_lite, &.time_and_materials.edit-mode {
						opacity: 1;
						min-height: 0px;
						max-height: 0px;
					}
					&.non_billable {
						opacity: 0;
						min-height: 0px;
						max-height: 0px;
					}
					&.retainer {
						opacity: 1;
						//min-height: 114px;
						max-height: 194px;
					}
					.fixed-price {
						width: 108px;
						margin-right: 66px;
					}
					.rate-card {
						width: 224px;
						margin-bottom: 19px;
						margin-right: 8px;
					}
					.pre-create-periods {
						display: flex;
						.spacer {
							width: 224px;
							margin-right: 8px;
						}
						.inputs-section {
							display: flex;
							flex-direction: row;
							margin-bottom: 8px;
							padding-right: 66px;
							.checkbox-controls-wrapper {
								display: flex;
								align-items: center;
								width: 224px;
								margin-right: 8px;

								.check-box {
									width: 22px;
								}
								span {
									cursor: pointer;
								}
							}
							.radio-options {
								width: 166px;
								margin-right: 8px;
								margin-bottom: 3px;
								top: -6px;
								position: relative;
							}
							.pre-create-input {
								width: 50px;
							}
							.info-message {
								font-size: 13px;
								color: $v2-text-light-gray;
								padding-top: 10px;
								font-style: italic;
							}
						}
						.retainer-totals {
							display: flex;
							justify-content: space-between;
							width: 282px;
							height: 51px;
							.retainer-totals-container {
								display: flex;
								flex-direction: column;
								&.value-section {
									flex-grow: 1;
									text-align: right;
								}
								label {
									font-size: 9px;
									color: $v2-text-light-gray;
								}
								.retainer-bloc {
									display: flex;
									height: 30px;
									font-size: 13px;
									color: $v2-text-gray;
									background: $color-medium-gray;
									align-items: center;
									justify-content: space-between;
									&.dates {
										position: relative;
										padding-left: 10px;
										.start-period-date {
											position: absolute;
											height: 30px;
											width: 30px;
											left: -30px;
											top: 4px;
										}
									}
									&.value {
										text-align: right;
										padding-right: 10px;

										.inner-text {
											width: 100%;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.info-label {
		font-size: 13px;
		line-height: 1.5;
		letter-spacing: 0.5px;
		color: $v2-text-gray;
		&.disabled {
			color: $v2-text-light-gray;
		}
		&.control-label {
			margin-left: 8px;
		}
		&.margin-right {
			margin-right: 24px;
		}
	}
	label {
		height: 11px;
		font-size: 9px;
		font-weight: 500;
		letter-spacing: 1px;
		color: $v2-text-light-gray;
		text-transform: uppercase;
		white-space: nowrap;
		margin-bottom: 4px;
		&.tooltip {
			position: relative;
			top: -3px;
		}
	}
}
