.add-team-modal {
	.content {
		display: flex;
		flex: 1 1 auto;
		flex-direction: column;
		height: calc(100vh - 300px);
		width: 340px;
		position: relative;
		> div {
			position: absolute;
			height: calc(100vh - 200px);
			top: 0;
			left: -58px;
			width: 456px !important;
		}
		.body {
			display: flex;
			flex-direction: column;
			padding: 0 58px;
			&.editTeam {
				padding-bottom: 1.85em;
				border-bottom: 1px solid $app-border-color;
			}
		}
		.select-all {
			display: flex;
			flex-direction: row;
			border-top: 1px solid $app-border-color;
			padding: 16px 0;
			margin-top: 16px;
			justify-content: space-between;
			.checkbox-wrapper {
				display: flex;
				flex-direction: row;
				align-items: center;
				cursor: pointer;
				.text {
					font-size: 12px;
					margin-right: 8px;
				}
			}
			.search-V2 {
				height: 40px;
				input {
					height: 38px;
					width: 196px;
					padding: 0 16px;
				}
				&:focus-within {
					border-color: $v2-branding-purple;
				}
			}
			.input-container {
				display: flex;
				flex-direction: row;
				align-items: center;
				border: 1px solid $app-border-color;
				.clear-button {
					width: 22px;
					height: 22px;
					border: none;
					background-size: 12px;
					background-repeat: no-repeat;
					background-position: center;
					background-image: url('images/components/overlay/close.svg');
					margin-left: 0;
				}
				input {
					border: none;
					padding: 10px;
					height: 38px;
					&:focus {
						outline: 0;
					}
				}
			}
		}
		.person-row {
			position: relative;
			height: 42px;
			.person-content-container {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				cursor: pointer;
				align-items: center;
				z-index: 2;
				user-select: none;
				.name {
					font-size: 13px;
				}
				.role {
					font-size: 12px;
					color: $v2-text-light-gray;
					font-weight: normal;
				}
				&:hover {
					color: $color-purple-hover-active;
					.role {
						color: $color-purple-hover-active;
					}
				}
			}
			.background-highlight {
				position: absolute;
				top: 0;
				left: -58px;
				height: 100%;
				width: calc(100% + 58px + 58px);
				background-color: $bulk-update-selected-color;
			}
		}
		.person-selector-container {
			margin: 1.6em 1.25em 0.5em 1.25em;
		}
		.buttons {
			display: flex;
			justify-content: flex-end;
			margin: 1.25em;
			button {
				@include primary-button;
			}
		}
	}
}
