.wrapper,
.wrapper-locked,
.wrapper-error {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	::-webkit-input-placeholder {
		/* WebKit, Blink, Edge, Chrome */
		opacity: 0.7;
	}
	:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		opacity: 0.7;
	}
	::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		opacity: 0.7;
	}
	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		opacity: 0.7;
	}
	::-ms-input-placeholder {
		/* Microsoft Edge */
		opacity: 0.7;
	}
	.input-wrapper,
	.input-wrapper-focused {
		display: flex;
		flex-direction: row;
		align-items: center;
		background: transparent;
		transition: background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
		background: white no-repeat;
		/* 2 imgs : 1px gray line (normal state) AND 2px green line (focus state) */
		background-image: linear-gradient(to bottom, $material-focused-color, $material-focused-color), linear-gradient(to bottom, silver, silver);
		/* sizes for the 2 images (default state) */
		background-size: 0 2px, 100% 1px;
		/* positions for the 2 images. Change both "50%" to "0%" or "100%" */
		background-position: 50% 100%, 50% 100%;
		.suffix {
			padding-left: 2px;
			padding-right: 4px;
			color: $material-label-color;
		}
		input {
			border: 0;
			outline: 0;
			width: 100%;
		}
		input:focus {
			outline: 0;
		}
	}
	.input,
	.input-right,
	.input-focused,
	.input-right-focused {
		border: 0;
		outline: 0;
		padding: 8px 0;
	}
	.input-right,
	.input-right-focused {
		text-align: right;
	}
}
.wrapper {
	.label,
	.label-right {
		color: $material-label-color;
	}
	.input-wrapper-focused {
		//border-bottom: 2px solid  $material-focused-color;
		background-size: 100% 2px, 100% 1px;
		outline: none;
	}
	.label-focused,
	.label-right-focused {
		color: $material-focused-color;
	}
}
.wrapper:hover {
	.input-wrapper {
		// border-bottom: 2px solid;
		//margin-bottom: 0;
		background-image: linear-gradient(to bottom, $material-focused-color, $material-focused-color), linear-gradient(to bottom, black, black);
	}
}
.wrapper-error {
	.input-wrapper {
		background-size: 100% 2px, 100% 1px;
		//border-bottom: 2px solid red;
		background-image: linear-gradient(to bottom, red, red), linear-gradient(to bottom, red, red);
	}
	.label,
	.label-right {
		color: red;
	}
	.input-wrapper-focused {
		//border-bottom: 2px solid  $material-focused-color;
		background-size: 100% 2px, 100% 1px;
		outline: none;
	}
	.label-focused,
	.label-right-focused {
		color: red;
	}
}
.wrapper,
.wrapper-error {
	.label,
	.label-right,
	.label-focused,
	.label-right-focused {
		padding-top: 16px;
		font-size: 0.75rem; //12px;
	}
	.label-right,
	.label-right-focused {
		text-align: right;
	}
	.error-message {
		color: red;
		font-size: 0.75rem;
		padding-top: 8px;
	}
}
.wrapper-locked {
	opacity: 0.5;
	.label,
	.label-right,
	.label-focused,
	.label-right-focused {
		padding-top: 16px;
		font-size: 0.75rem;
	}
	.label-right,
	.label-right-focused {
		text-align: right;
	}
	opacity: 0.5;
	.input-wrapper {
		border-bottom: 0.8px dotted $material-locked-color;
	}
}
