.task-modal-v3-comments-footer-section {
	cursor: pointer;
	padding: 8px 58px;
	border-top: 1px solid $app-border-color;
	position: absolute;
	left: 0;
	bottom: 30px;
	width: 100%;
	background-color: $v2-fixed-element-grey;
	z-index: 100;
	outline: 0;

	&.comments-footer-section-hidden {
		bottom: 0;
	}

	.comment-lower {
		display: flex;

		.person-profile-picture {
			margin-top: 3px;
		}

		.new-comment-box {
			padding-left: 16px;
			flex: 1 1 418px;
			position: relative;
			width: 0;

			.rich-text-field-container .rich-text-field-editor-wrapper {
				min-height: 40px;
			}

			.rich-text-field-input-container {
				min-height: 0px;
				height: 30px;
				overflow: hidden;
				transition: all 0.2s;

				&.has-focus {
					height: unset;
					overflow: initial;

					.rich-text-field-emoji .draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
						display: initial;
						bottom: 15%;
						right: 25%;
					}
				}

				.rich-text-field-editor-wrapper {
					margin-top: 5px;
				}

				.rich-text-field-emoji .draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
					display: none;
				}
			}

			.add-comment-button {
				color: white;
				background-color: $v2-create-button-green;
				position: absolute;
				position: absolute;
				top: 0;
				right: 8px;
				top: calc(100% - 30px - 6px);
				//resize the button
				width: 30px;
				height: 30px;
				font-size: 19px;
			}
		}

		.editor {
			padding: 0px;
		}
	}
}
