
.github-attach-modal {
	width: 300px;
	height: 500px;
	display: flex;
	flex-direction: column;
	padding-bottom: 15px;
	.search-input-wrapper {
		.search-V2 {
			width: 100%;
			margin-right: 0;
			margin-bottom: 8px;
			input {
				width: 100%;
			}
		}
	}
	.search-results {
		position: relative;
		flex-grow: 1;
		display: flex;
		.github-list {
            width: 300px;
			list-style: none;
			margin: 0;
			padding: 0;
			.github-option {
				font-size: 13px;
				padding: 6px 10px;
				cursor: pointer;
				color: $color-dark-gray;
				.repository {
					font-weight: bold;
				}
				&:hover {
					background-color: $color-medium-gray;
				}
				&.fetching {
					filter: blur(1px);
					-webkit-filter: blur(1px);
					-moz-filter: blur(1px);
					-o-filter: blur(1px);
					-ms-filter: blur(1px);
				}
			}
            .loading {
				font-size: 13px;
				padding: 6px 10px;
            }
		}
	}
}