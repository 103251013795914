.move-phase-modal {
	.drop-down-v2 {
		margin-top: 16px;
		width: 340px;
	}
	.date-picker-wrapper-new {
		margin-left: 8px;
	}
	.hour-date-wrapper {
		display: flex;
		flex-direction: row;
	}
	.project-select-label {
		height: 11px;
		font-family: $v2-site-header-font-family;
		font-size: 9px;
		font-weight: 500;
		line-height: 1.22;
		letter-spacing: 1px;
		color: $v2-text-light-gray;
		text-transform: uppercase;
		margin-bottom: 5px;
	}
	::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		font-size: 12px;
	}
	::-moz-placeholder {
		/* Firefox 19+ */
		font-size: 12px;
	}
	:-ms-input-placeholder {
		/* IE 10+ */
		font-size: 12px;
	}
	:-moz-placeholder {
		/* Firefox 18- */
		font-size: 12px;
	}
	.nested-dropdown-v2 {
		width: 340px;
		height: 42px;
		.input-arrow-wrapper {
			border: 1px solid $app-border-color;
			border-radius: 4px;
			&:hover {
				border: 1px solid $v2-date-picker-button-border-color;
			}
			.nested-dropdown-input {
				width: 310px;
				height: 38px;
				padding: 16px 0 16px 16px;
				font-family: $v2-site-header-font-family;
				font-size: 12px;
				font-weight: 500;
				color: $v2-text-gray;
				background-color: white;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.arrow {
				margin-right: 17px;
			}
		}
		.nested-dropdown-options-wrapper {
			width: 340px;
			border-radius: 4px;
			.custom-scrollbar-div > div:first-of-type {
				width: 353px;
			}
		}
	}
	.project-dropdown-wrapper {
		width: 372px;
	}
}
