.colors-picker-container {
	position: relative;
	width: 50px;
	outline: none;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	padding: 5px;

	.color-picker,
	.color-option {
		cursor: pointer;
		&.rectangle {
			width: 50px;
			height: 30px;
			border-radius: 6px;
		}
		&.locked {
			cursor: default;
		}
	}
	.color-option:not(.locked):hover,
	.color-picker:not(.locked):hover {
		-webkit-transform: scale(1.1);
		-ms-transform: scale(1.1);
		transform: scale(1.1);
	}
	.options-window {
		top: 2px;
		position: absolute;
		z-index: 99;
		@include content-frame;
		&.project-status {
			height: 38px;
			width: 86px;
		}
		&.right {
			left: 45px;
		}
		&.left {
			right: 45px;
		}
		&.github-picker {
			right: 0;
			top: 40px;
		}
		&.twitter-picker {
			right: 0;
			top: 44px;
		}
	}
	.cover {
		position: fixed;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
	}
}
