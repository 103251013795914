.suggested-time-container {
	position: absolute;
	background-color: #fff;
	border: 1px solid $v2-branding-purple;
	border-radius: 4px;
	padding: 8px;
	width: 150px;
	display: flex;
	flex-direction: column;
	min-height: 82px;
	text-align: center;
	top: 0;
	left: 0;
	z-index: 99999;

	&:after,
	&:before {
		bottom: 100%;
		left: 50%;
		border: solid transparent;
		content: ' ';
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	&:after {
		border-color: rgba(255, 255, 255, 0);
		border-bottom-color: #fff;
		border-width: 10px;
		margin-left: -10px;
	}
	&:before {
		border-color: rgba(245, 0, 0, 0);
		border-bottom-color: $v2-branding-purple;
		border-width: 11px;
		margin-left: -11px;
	}

	.header {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		.title {
			font-weight: $base-font-bold-weight;
			font-size: 12px;
		}
	}

	.options {
		display: flex;
		flex-direction: row;

		.suggested-time-option {
			margin-top: 10px;
			width: 100%;
			border: 1px solid $app-border-color;
			padding: 8px 0;
			color: $v2-text-gray;
			font-weight: $base-font-bold-weight;
			font-size: 12px;
			cursor: pointer;

			&:hover {
				color: $v2-branding-purple;
				background-color: lighten($v2-branding-purple, 40%);
			}

			&:first-child {
				border-right: 0;
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
			}

			&:last-child {
				border-left: 0;
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
			}

			&.disabled {
				pointer-events: none;
				color: #e2e2e2;
			}
		}
	}
}

.nopopup-options {
	display: flex;
	font-size: 13px;
	margin-left: 8px;
	border: 1px solid $v2_button_light_grey;
	border-radius: 4px;
	height: 30px;

	.suggested-time-option {
		padding: 5px;
		width: 98px;
		border: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		// color: ${props => (!props.isSelected ? CSS_CONSTANTS.v2_text_light_gray : 'inherit')};

		&:hover,
		&:focus,
		&.selected {
			outline: 0;
			cursor: pointer;
			background: $v2-list-hover-purple;
		}

		&.disabled {
			pointer-events: none;
			color: #e2e2e2;
		}
		&:not(:first-child) {
			border-left: 1px solid $v2-button-light-grey;
		}
	}
}
