.settings-holiday-calendars {
	&.section-content {
		margin: 0;
		.section-body {
			margin: 16px 24px;
		}
	}
	.top-container {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
	}
	.add-holiday-calendar-button {
		@include primary-button;
		margin-left: 12px;
	}
	.holiday-calendars {
		@include standard-table;
		table {
			width: 100%;
		}
		tbody {
			tr {
				@include gear-menu;
				.gear-menu {
					.gear-icon {
						height: 26px;
					}
				}
			}
		}
	}
	.holiday-calendars-empty-state-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}
