.task-modal-v3-dependency-section {
	border-top: 1px solid $app-border-color;
	margin: 0 58px;

	.dependency-section-header {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0;
		border: none;
		width: 100%;
		height: 57px;

		&:hover {
			cursor: pointer;
		}

		&:focus {
			outline: none;
			.expand-icon {
				path {
					stroke: $color-martin-tertiary-light-purple;
				}
			}
		}
		.dependency-icon {
			transform: translateY(2px);
		}
		.title {
			color: $v2-text-gray;
			font-size: 13px;
			font-weight: 500;
			padding-left: 8px;
		}

		.task-dependecies-line-up {
			margin: 0 40px;
			position: relative;
			width: 100%;

			.linked-tasks-info {
				position: absolute;
				top: 0;
				transform: translateY(-50%);
				width: 153px;

				&.before {
					right: 401px;

					&.faded {
						opacity: 0.6;
					}

					.text-container {
						text-align: end;
					}
				}

				&.after {
					left: 316px;
					.text-container {
						text-align: start;
					}
				}

				.text-container {
					display: flex;
					align-items: center;
					text-transform: uppercase;
					font-size: 9px;
					color: $v2-text-light-gray;

					span {
						margin-left: 10px;

						&.tasks-count {
							color: $v2-text-gray;
							font-size: 12px;
							font-weight: 500;
						}
					}
				}
			}

			.before-dependency-bond {
				position: absolute;
				left: 161px;
				top: 0;

				.bond {
					width: 1px;
					height: 30px;
					background-color: $v2-text-light-gray;

					&::after {
						content: '';
						height: 1px;
						width: 20px;
						background-color: $v2-text-light-gray;
						position: absolute;
						top: 15px;
						left: 1px;
						display: inline-block;
					}
				}

				.dependencies-right-arrow {
					position: absolute;
					top: 11px;
					left: 21px;
				}
			}

			.task-icon {
				position: absolute;
				top: -2px;
				left: 239px;
			}

			.after-dependency-bond {
				position: absolute;
				top: 15px;
				left: -62px;
				.dependencies-right-arrow {
					position: absolute;
					top: -4px;
					left: 16px;
				}
				.bond {
					height: 1px;
					width: 20px;
					margin-left: 20px;
					background-color: $v2-text-light-gray;

					&::after {
						content: '';
						height: 30px;
						width: 1px;
						background-color: $v2-text-light-gray;
						position: absolute;
						top: -15px;
						left: 40px;
						display: inline-block;
					}
				}
			}
		}

		.expand-icon {
			margin-right: 6px;
			margin-left: auto;
		}
	}

	.section-top {
		position: relative;
		padding: 4px 0px;
		display: flex;
		justify-content: space-between;
		height: 48px;

		.add-button-component {
			width: 30px;
			height: 30px;
			font-size: 19px;
		}

		//internet explorer fix
		.add-button {
			position: absolute;
			&:last-child {
				right: 54px;
			}
		}
	}

	.dependencies-info {
		height: 20px;
		position: relative;
		font-size: 9px;
		text-transform: uppercase;
		color: $v2-text-light-gray;
		letter-spacing: 1px;
		display: flex;
		align-items: center;

		& > div {
			position: absolute;
			display: flex;
			align-items: center;

			.information-icon {
				margin-left: 5px;
			}

			&.current-task {
				left: 50%;
				transform: translateX(-50%);
			}

			&.successor-task {
				right: 340px;
				transform: translateX(100%);
			}
		}
	}

	.dependencies-list-container {
		display: flex;
		justify-content: space-evenly;
		flex-direction: column;
		margin-bottom: 16px;

		.add-dependency-toggle {
			border: 0;
			display: flex;
			align-self: flex-start;
			font-size: 13px;
			font-weight: 500;
			margin: 8px 0;
			color: $v2-text-gray;

			.expand-icon {
				margin: 0 8px;
			}
		}

		.forms-container {
			position: relative;
			margin: 0;
			height: 50px;

			.bond-right,
			.bond-left {
				width: 1px;
				height: 10px;
				background-color: $v2-dark-grey;
				position: absolute;
				top: 0;
				height: 50%;

				&::before {
					content: '';
					height: 1px;
					width: 14px;
					background-color: inherit;
					top: 100%;
					position: absolute;
					display: inline-block;
				}
			}

			.bond-right {
				left: 353px;

				&::before {
					left: -13px;
				}
			}

			.bond-left {
				left: 451px;
			}
		}

		.dependencies {
			width: 100%;
			margin: 12px 0;
			display: flex;

			.tasks-before-container {
				display: inline-block;
				margin-right: 62px;

				&.faded {
					opacity: 0.6;
				}
			}

			.tasks-after-container {
				margin-left: 62px;
				display: inline-block;

				&.extra-margin {
					margin-left: 402px;
				}
			}

			.tasks-icons-container {
				display: flex;
				flex-direction: column;
				justify-content: space-around;
			}
		}

		.task-graphic-container {
			position: absolute;
			left: 50.25%;
			transform: translate(-50%, -50%);
			display: flex;
			align-items: center;
			width: 84px;

			&.form {
				top: 25px;
			}

			.info-box-component {
				transition: all 0.2s;
				transform: translate(-39%, -96%);
			}

			.dependencies-right-arrow {
				position: absolute;

				&.right {
					left: 79px;
					top: -5px;
				}

				&.left {
					left: 0;
					top: -5px;
				}
			}

			.line {
				top: -1px;
				left: -9px;
				width: 11px;
				height: 1px;
				position: absolute;
				display: inline-block;

				&.after {
					left: 79px;
				}
			}

			.task-graphic {
				position: absolute;
				left: 9.5px;
				top: -11px;
				overflow: hidden;

				.corner {
					position: absolute;
					width: 40px;
					height: 20px;
					background-color: white;
					top: -16px;
					right: -22px;
					transform: rotate(30deg);
				}

				.task {
					padding: 4px 0;
					width: 64px;
					height: 22px;
					color: white;
					text-transform: uppercase;
					font-weight: 700;
					text-align: center;
					font-size: 12px;

					svg {
						margin: 0 2px;
					}
				}
			}
		}
	}
}
