.seat-management-container {
	width: 500px;
	.height-30 {
		.input-value-container {
			height: 30px;
		}
	}
	.seat-disclaimer {
		font-family: $text-font-family;
		color: $v2-text-gray;
		margin-bottom: 16px;
		font-size: 12px;
	}
	table {
		width: 100%;
		tr {
			height: 35px;
		}
		td {
			width: 35%;
		}
		td:first-child {
			width: 15%;
		}
		td:last-child {
			width: 15%;
		}
	}
	.input-container-v2 {
		width: 108px;
	}
	.modal-header {
		font-family: $text-font-family;
		font-size: 20px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: $v2-text-gray;
	}
	.table-header {
		font-family: $text-font-family;
		font-size: 9px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: 1px;
		color: $v2-text-light-gray;
		text-transform: uppercase;
		&.left {
			text-align: left;
		}
	}
	.table-cell {
		position: relative;
		font-family: $text-font-family;
		font-size: 13px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: $v2-text-gray;
		white-space: nowrap;
		&.center {
			text-align: center;
		}
	}
	.card-section {
		margin-top: 16px;
		padding-top: 20px;
		border-top: 1px solid $app-border-color;
		text-align: right;
		font-family: $text-font-family;
		font-size: 12px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: right;
		color: $v2-text-gray;
	}
	.remove-text {
		min-height: 26px;
		margin-top: 30px;
		padding-top: 10px;
		border-top: solid 1px $app-border-color;
		font-family: $text-font-family;
		font-size: 12px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: $v2-text-light-gray;
	}
	.seat-reduction-reason-container {
		margin-top:10px;
		border-top: solid 1px #e6e6e6;
		.header-text {
			font-size: 17px;
			color: #535353;
			font-family: "urbane", sans-serif;
			margin:20px 0;
		}
		.radio-button-wrapper {
			margin: 5px 0;
			.text {
				font-size: 13px;
				color: #535353;
				font-family: "neue-haas-grotesk-text", sans-serif;
				line-height: 1.6;
			}
		}
		textarea {
			width:100%;
			border-color: #e6e6e6;
			margin-top:5px;
			font-size:12px
		}
	}
}
