/**
 * Full screen pages.
 */
.fullscreen {
	width: 100%;
	height: 100%;
	min-height: 100%;
}

/**
 * Full screen with centered content.
 */
.fullscreen-centered-outer {
	display: table;
	position: absolute;
}

.fullscreen-centered-inner {
	display: table-cell;
	vertical-align: middle;
}

.fullscreen-centered-content {
	margin-left: auto;
	margin-right: auto;
}

/**
 * Full screen loading indicator.
 */
.fullscreen-loading-indicator {
	background: $brand-brown-light url('images/loaders/loader-32x32-brown.svg') no-repeat center center;
}
