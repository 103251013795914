.gratification-animation-wrapper {
	width: 100vw;
	height: 100vh;
	z-index: 99;
	animation-name: bounceInUp;
	animation-duration: 1.5s;
	animation-timing-function: ease-in-out;
	position: fixed;
	left: 0;
	top: 0;
	&.leave {
		animation-name: bounceOutDown;
		animation-duration: 1s;
	}
	.animation-elems {
		width: 150px;
		height: 500px;
		position: absolute;
		.icon-text {
			position: absolute;
			font-family: $text-font-family;
			text-align: center;
			font-size: 42px;
			font-weight: 500;
			bottom: 190px;
			left: -115px;
			text-align: left;
			color: $v2-branding-purple;
			text-transform: uppercase;
			white-space: nowrap;
			animation-name: slideInDiagonal;
			animation-duration: 0.8s;
		}
		.trex-body {
			position: absolute;
			bottom: 0;
			width: 150px;
			left: calc(50% - 75px);
		}
		.trex-jaw {
			position: absolute;
			bottom: 0;
			left: calc(50% - 75px);
			width: 150px;
			&.bite {
				animation-name: tRexBite;
				animation-duration: 1s;
			}
		}
	}
}
@keyframes slideInDiagonal {
	from {
		opacity: 0.2;
		transform: translate(50px, 50px) scale(0.8);
	}
	to {
		opacity: 1;
		transform: translate(0, 0) scale(1);
	}
}
@keyframes tRexBite {
	0% {
		transform: rotate(0deg);
	}

	50% {
		transform: rotate(20deg) translateX(-10px);
	}

	100% {
		transform: rotate(0deg);
	}
}
