.expenses-page-wrapper {
	display: flex;
	flex-direction: column;
	flex: 1;

	.section-body {
		margin: 0 66px;
	}

	.totals {
		display: flex;
		margin: 0 66px;
		margin-bottom: 32px;

		.total {
			border: 1px solid $app-border-color;
			color: $v2-text-gray;
			text-align: center;
			flex: 1 1 auto;
			margin: 0 5px;

			.title {
				font-size: 14px;
				font-weight: 500;
				margin: 22px 0;
			}

			.value {
				font-size: 24px;
				margin-bottom: 22px;
			}
		}
	}

	.controls {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		.expand-control {
			width: 24px;
			height: 16px;
			background-size: 24px;
			background-repeat: no-repeat;
			background-position: center;
			cursor: pointer;
			&.expand {
				background-image: url('images/up-arrow.svg');
			}

			&.collapse {
				background-image: url('images/header/expanded.svg');
			}
		}
	}

	fieldset {
		border: none;
		box-shadow: none;
		padding: 0;
		padding-left: 6px;
		font-size: 0.875em;
		margin: 0 0 15px 0;
	}

	.expenses {
		border: none;
		box-shadow: none;
		padding: 0;
		padding-left: 6px;

		.name-width {
			min-width: 215px;
			max-width: 215px;
		}

		.date-width {
			min-width: 85px;
			max-width: 85px;
		}

		.person-width {
			min-width: 181px;
			max-width: 181px;

			.name {
				display: block;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				width: 141px;
			}
		}

		.unit-cost-width,
		.unit-price-width {
			max-width: 85px;
			min-width: 85px;
		}

		.quantity-width {
			max-width: 65px;
			min-width: 65px;
		}

		.markup-width {
			max-width: 80px;
			min-width: 80px;
		}

		.top-container {
			width: max-content;
			cursor: pointer;
			@include data-tile;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;

			&.collapsed {
				border-bottom: 1px solid #eee;
			}

			h2 {
				font-size: 16px;
				color: $v2-text-gray;
				font-family: inherit;
				font-weight: 500;

				span {
					font-weight: 400;
				}
			}
			h3 {
				margin-bottom: 0;
			}
		}
		@include standard-table;
		table {
			thead {
				th {
					color: $v2-text-light-gray;
					font-size: 9px;
					letter-spacing: 1px;
					font-weight: 500;
					text-transform: uppercase;
					cursor: default;

					&.column-date {
						padding-left: 20px;
					}

					&.column-quantity {
						text-align: center;
					}

					&.column-unit-cost,
					&.column-unit-price {
						padding-right: 16px;
					}

					&.column-unit-cost,
					&.column-total-cost,
					&.column-unit-price,
					&.column-total-price {
						text-align: left;
					}

					&.column-markup {
						text-align: right;
					}

					&.column-planned,
					&.column-approved {
						text-align: center;
					}
				}
			}

			tbody {
				tr {
					border-top: 1px solid $light-border-color;
					border-bottom: none;

					td {
						position: relative;
						overflow: visible;
						color: $v2-text-gray;
						font-size: 13px;
						padding: 0 0.625rem 0 0.625rem;
					}
				}
			}
		}
		tbody {
			table-layout: fixed;
			word-wrap: break-word;
			tr {
				.controls {
					span {
						background-size: 16px;
						background-repeat: no-repeat;
						background-position: left center;
						padding: 0 10px 0 21px;
						cursor: pointer;
						&:hover {
							text-decoration: underline;
						}
						&.edit {
							background-image: url('images/cross-section-icons/actions/edit.svg');
						}
						&.remove {
							background-image: url('images/cross-section-icons/actions/remove.svg');
						}
						&.enable {
							background-image: url('images/cross-section-icons/actions/enable.svg');
						}
					}
				}
			}
		}
	}
}
