.info-box-component {
	position: absolute;
	z-index: 10002;
	max-width: 360px;

	&.fixed {
		position: fixed;
	}
	&.flex {
		display: flex;
		flex-direction: row;
	}
	.box-content-relative-wrapper {
		position: relative;
	}
	.hidden {
		opacity: 0;
		width: 0;
		height: 0;
	}
	.info-box-content {
		min-height: 46px;
		background-color: $v2-background-dark-gray;
	}
	.info-box-text-container {
		display: flex;
		flex-direction: column;
		padding: 10px 12px;
		color: $v2-text-gray;
		font-size: 11px;
		font-weight: 500;

		.wrapper {
			display: flex;

			&:not(:last-child) {
				margin-bottom: 16px;
			}

			.header {
				margin-bottom: 5px;
				font-size: 12px;

				&:not(.first) {
					border-top: 1px solid $v2-text-gray;
					padding-top: 16px;
				}
			}

			.title {
				margin-bottom: 5px;
				display: flex;
				.title-text {
					margin-left: 8px;
				}
			}

			.details {
				display: flex;
				align-items: center;
				font-weight: 400;
				margin-left: 8px;

				.detail {
					padding: 5px 8px;
					&.boxed {
						border: 1px solid $app-border-color;
						background-color: #fff;
					}
				}
			}

			.description {
				font-weight: normal;
				.description-text {
					margin-left: 8px;
				}
			}
		}
	}
	.info-box-text {
		padding: 13px;
		font-family: $text-font-family;
		font-size: 11px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		color: $v2-text-gray;
		white-space: pre-line;
		.do-not-show-again {
			.link {
				margin-top: 16px;
				cursor: pointer;
				text-decoration: underline;
				border: none;
				padding: 0;
				color: $link-text-color;
				&:hover {
					opacity: 0.8;
				}
			}
		}
	}
	.triangleBorder {
		z-index: 1099;
		width: 0;
		position: absolute;
		height: 0;
		border-style: solid;
		&.left,
		&.right,
		&.topMiddle {
			top: 0px;
		}
		&.lowerLeft,
		&.lowerRight,
		&.bottomMiddle {
			bottom: 0;
		}
		&.left,
		&.lowerLeft {
			border-width: 15px 16px 15px 0;
			border-color: transparent $v2-grey transparent transparent;
			left: -15px;
		}
		&.right,
		&.lowerRight {
			right: -15px;
			border-width: 15px 0 15px 16px;
			border-color: transparent transparent transparent $v2-grey;
		}
		&.topMiddle {
			left: 50%;
			border-width: 16px 15px 0 15px;
			border-color: $v2-grey transparent transparent transparent;
		}
		&.bottomMiddle {
			left: 50%;
			border-width: 0 15px 16px 15px;
			border-color: transparent transparent $v2-grey transparent;
		}
	}
	.triangle {
		z-index: 1100;
		position: absolute;

		&.right,
		&.lowerRight {
			transform: rotate(90deg);
		}

		&.left,
		&.lowerLeft {
			transform: rotate(-90deg);
		}
		&.bottomMiddle {
			transform: rotate(180deg);
		}
		&.topMiddle {
			top: -8px;
			left: 50%;
			transform: translate(-50%, 0%);
		}

		&.left,
		&.right {
			top: 8px;
		}

		&.lowerLeft,
		&.lowerRight,
		&.bottomMiddle {
			bottom: 5px;
		}
		&.left,
		&.lowerLeft {
			left: -14px;
		}
		&.right,
		&.lowerRight {
			right: -14px;
		}

		/* z-index: 1100;
		position: absolute;
		width: 0;
		height: 0;
		border-style: solid;
		&.left,
		&.right,
		&.topMiddle {
			top: 1px;
		}
		&.lowerLeft,
		&.lowerRight,
		&.bottomMiddle {
			bottom: 1px;
		}
		&.left,
		&.lowerLeft {
			left: -12px;
			border-width: 14px 14px 14px 0;
			border-color: transparent $v2-tooltip-grey transparent transparent;
		}
		&.right,
		&.lowerRight {
			right: -12px;
			border-width: 14px 0 14px 14px;
			border-color: transparent transparent transparent $v2-tooltip-grey;
		}
		&.topMiddle {
			left: 50%;
			border-width: 14px 14px 0 14px;
			border-color: $v2-tooltip-grey transparent transparent transparent;
		}
		&.bottomMiddle {
			left: 50%;
			border-width: 0 14px 14px 14px;
			border-color: transparent transparent $v2-tooltip-grey transparent;
		} */
	}
	&.grey {
		.info-box-content {
			border-radius: 4px;
			box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05);
			background-color: $v2-tooltip-grey;
			border: solid 1px $v2-grey;
		}
		.info-box-content {
			min-height: 30px;
		}
		.info-box-text {
			padding: 8px 16px;
			color: $v2-text-gray;
		}

		/* .topMiddle {
			left: 50%;
			transform: translate(-50%, -89%) rotate(180deg);
		}
		.bottomMiddle {
			left: 50%;
			transform: translate(-50%, 89%) rotate(180deg);
		}
		.topRight {
			right: -12px;
			transform: translate(-50%, -89%) rotate(180deg);
		} */
	}
	&.bordered {
		.info-box-content {
			border: solid 1px $v2-grey;
		}
	}
	.bold-font-weight {
		font-weight: bold;
	}
}
