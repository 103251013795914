.project-dropdown-container {
	position: relative;
	display: flex;
	flex-direction: row;
	margin-right: 0;
	outline: none;
	.project-dropdown-button {
		outline: none;
		position: relative;
		border: 1px solid $v2-button-very-light-grey;
		box-shadow: none;
		outline: none;
		min-width: 62px;
		height: 31px;
		border-radius: 3px;
		background-color: $color-white;
		font-family: $text-font-family;
		font-size: 12px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: $v2-text-gray;
		&:focus,
		&:hover,
		&.expanded {
			border: 1px solid $v2-button-very-light-grey-hovered;
			outline: none;
		}
	}
	.line-container {
		margin-left: 1px;
		margin-right: 1px;
		position: relative;
		height: 31px;
		width: 6px;
		background-color: rgba(0, 0, 0, 0);
		.line {
			position: absolute;
			top: 50%;
			width: 100%;
			height: 2px;
			background-color: #d8d8d8;
		}
	}
	.project-options-container {
		top: 36px;
		position: absolute;
		z-index: 100;
		min-width: 191px;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
		background-color: $color-white;
		padding: 0;
		border-radius: 4px;
	}
	.project-options {
		list-style: none;
		min-width: 191px;
		display: flex;
		flex-direction: column;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
		background-color: $color-white;
		margin: 0;
		padding: 4px 0;
		border-radius: 4px;
		.project-option {
			cursor: pointer;
			position: relative;
			flex-grow: 1;
			padding: 4px 9px;
			height: 30px;
			display: flex;
			flex-direction: row;

			&:focus {
				outline: none;
			}
			&.halted,
			&.done {
				.project-name {
					text-overflow: ellipsis;
					//text-decoration: line-through;
					color: $v2-text-light-gray;
					font-size: 12px;
					font-weight: 500;
					font-style: normal;
					font-stretch: normal;
					line-height: 0.92;
					letter-spacing: normal;
				}
			}
			.strikethrough {
				font-size: 4em;
				line-height: 1em;
				position: relative;
			}
			.strikethrough::after {
				border-bottom: 1px solid $v2-text-light-gray;
				content: '';
				left: 0;
				position: absolute;
				right: 0;
				top: 50%;
			}
			.halted-or-done-section {
				display: flex;
				flex-direction: row;
				.project-status-text {
					margin-left: 5px;
					text-decoration: none;
					margin-top: 3px;
					font-family: $text-font-family;
					font-size: 12px;
					font-weight: 500;
					font-style: italic;
					font-stretch: normal;
					line-height: 0.92;
					letter-spacing: normal;
					color: $v2-text-light-gray;
				}
				.locked-icon {
					margin-left: 16px;
					width: 14px;
					height: 26px;
					background-image: url('images/v2/overview-projects/lock_lightgrey.svg');
					background-repeat: no-repeat;
					background-position: center;
				}
			}
			.project-name {
				margin-left: 8px;
				text-overflow: ellipsis;
				overflow: hidden;
				font-family: $text-font-family;
				white-space: nowrap;
				font-size: 12px;
				font-weight: 500;
				font-style: normal;
				font-stretch: normal;
				line-height: 0.92;
				letter-spacing: normal;
				align-self: center;
				padding: 2px 0 2px 0;
				&.selected {
					color: $v2-branding-purple;
				}
			}
			&.preselected,
			&:hover {
				background-color: $v2-list-hover-purple;
			}
		}
	}
}
