.set-to-running-reminder-modal {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	z-index: 99;
	background: rgba(0, 0, 0, 0.25);
	display: flex;
	justify-content: center;
	align-items: center;
	.content-container {
		background-color: $color-white;
		padding: 16px;
		box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
		display: flex;
		flex-direction: column;
		.close-button {
			width: 16px;
			height: 16px;
			background-size: 16px;
			margin-left: auto;
			border: none;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url('images/components/overlay/close.svg');
		}
		.inner-container {
			padding: 0 16px 16px 16px;
			display: flex;
			flex-direction: column;
			text-align: center;
			max-width: 22em;
			.no-date {
				font-style: italic;
				color: $v2-text-light-gray;
			}
			.person-name {
				font-weight: bold;
				color: $brand-color;
			}
			.description {
				padding-bottom: 16px;
				margin-bottom: 16px;
				border-bottom: 1px solid $app-border-color;
			}
			.project-information {
				.project-name {
					font-weight: bold;
					color: $brand-color;
				}
				padding-bottom: 16px;
				margin-bottom: 16px;
				border-bottom: 1px solid $app-border-color;
			}
			.set-to-running-button {
				@include julie-green-button;
			}
		}
	}
}
