.layer {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 800;
	&.top-level {
		z-index: 1000;
	}
	background: rgba(255, 255, 255, 0);
}
