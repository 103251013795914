.applied-filter {
	overflow: hidden;
	max-width: 230px;
	.applied-filter-inner-container {
		height: 34px;
		border-radius: 4px;
		font-size: 11px;
		padding: 4px 8px;
		margin-right: 4px;
		margin-bottom: 4px;
		background-color: #e1e1e1;
		display: flex;
		flex-direction: row;
		animation: slide-in 0.5s;
		&.no-animation {
			animation: none;
		}
		.text-container {
			display: block;
			.group-type-text {
				font-size: 9px;
				letter-spacing: 0.5px;
			}
			.value-text {
				max-width: 190px;
				font-size: 11px;
				letter-spacing: 0.5px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
		.remove-filter-button {
			margin-left: 6px;
			border: none;
			width: 14px;
			height: 100%;
			background-size: 11px;
			background-image: url('images/components/close-modal.svg');
			background-repeat: no-repeat;
			background-position: center;
		}
	}
}

@keyframes slide-in {
	0% {
		transform: translateX(-100px);
	}
	100% {
		transform: translateX(0);
	}
}
