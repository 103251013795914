.nested-dropdown-v2 {
	position: relative;
	font-size: 13px;
	border: 1px solid transparent;
	height: 40px;
	font-family: $text-font-family;
	&:hover {
		.input-arrow-wrapper {
			.placeholder {
				width: calc(100% - 65px);
			}
		}
	}
	&.small {
		height: 30px;
	}
	.harvest-icon {
		position: relative;
		right: 6px;
		z-index: 9;
		width: 18px;
		min-width: 18px;
		height: 18px;
		background-size: 18px;
		border-radius: 3px;
		background-image: url('images/integrations/harvest-logo.png');
	}
	&.expanded &.locked {
		.input-arrow-wrapper {
			cursor: default;
			input {
				cursor: default;
			}
		}
	}
	.nested-dropdown-v2-label {
		text-transform: uppercase;
		color: $v2-text-light-gray;
		font-size: 9px;
		letter-spacing: 0.9px;
		position: absolute;
		top: 3px;
		left: 15px;
		width: 100%;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		cursor: pointer;
	}
	.custom-scrollbar-div.view-vertical > div:not(.track) {
		//needed to fix visual bug on collapsed outer option caused by customscrolldiv component
		padding-bottom: 0;
		width: calc(100% + 17px);
	}
	.input-arrow-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		height: 100%;

		&.show-border {
			border-radius: 4px;
			border: 1px solid $v2-backdrop-gray;
		}
		cursor: pointer;

		&.hidden {
			display: none;
		}

		input {
			cursor: pointer;
		}

		&.expanded {
			border: 1px $v2-branding-purple solid;
			border-bottom: 1px $color-white solid;
			.arrow {
				background: url('images/v2/components/dropdown/arrow-up.svg') no-repeat center;
				width: 16px;
				height: 100%;
				outline: none;
			}

			.placeholder {
				color: $v2-text-light-gray;
				width: calc(100% - 65px);
				.id-label-wrapper {
					opacity: 0.5;
				}
			}
		}

		.placeholder {
			position: absolute;
			margin-left: 14px;
			margin-right: 35px;
			display: flex;
			align-items: center;
			width: calc(100% - 25px);
			height: 100%;
			// project id label
			.id-label-wrapper {
				margin-right: 7px;
			}

			&-label {
				flex: 1;
				white-space: nowrap;
				overflow: hidden;
				// width: calc(100% - 55px);
				text-overflow: ellipsis;

				&.faded {
					color: $v2-text-light-gray;
				}
			}
		}

		.deselect-icon {
			&:hover {
				.input-clear-icon {
					g {
						stroke: $v2-branding-purple;
					}
				}
			}
		}

		.nested-dropdown-input {
			border: none;
			font-size: 13px;
			color: $v2-nested-dropdown-input-color;
			outline: none;
			padding: 14px;
			padding-bottom: 0px;
			padding-right: 0;
			width: calc(100% - 35px);
			text-overflow: ellipsis;
			&.expanded {
				padding-bottom: 14px;
			}
			&.white-color {
				color: $color-white;
			}
			&.black-color {
				color: $color-black;
			}
			&.with-id-label {
				padding-left: 0;
				&.expanded {
					padding-left: 14px;
				}
			}
		}
		.arrow-icon-wrapper {
			width: 35px;
			height: 14px;
			background-color: transparent;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			outline: none;
		}
		.arrow {
			background: url('images/v2/components/dropdown/arrow-up.svg') no-repeat center;
			width: 16px;
			height: 8px;
			margin-right: 14px;
			outline: none;
			margin-left: 6px;
			&.white-collapsed {
				background: url('images/v2/components/dropdown/arrow-down-white.svg') no-repeat center;
			}
			&.collapsed {
				background: url('images/v2/components/dropdown/arrow-down.svg') no-repeat center;
			}
			&.disabled {
				background: url('images/v2/components/dropdown/arrow-disabled.svg') no-repeat center;
			}
		}
	}
	.nested-dropdown-options-wrapper {
		background-color: $color-white;
		position: absolute;
		width: 100%;
		z-index: 103;
		border: 1px solid $v2-branding-purple;
		border-bottom-right-radius: 4px;
		border-bottom-left-radius: 4px;
		display: flex;
		flex: 1 1 auto;
		cursor: pointer;
		.content-loading {
			height: auto;
		}
		.clear-option {
			padding: 4px 8px;
			min-height: 27px;
			.clear-option-name {
				color: $v2-text-gray;
			}
			&.selected {
				background-color: $v2-list-hover-purple;
			}
			&.option-pre-selected {
				background: $v2-list-hover-purple;
			}
		}

		.outer-option:not(:first-child),
		.clear-option {
			border-top: 1px solid $app-border-color;
			.jira-logo {
				display: inline-block;
				width: 30px;
				height: 12px;
				margin-right: 8px;
				background: url('images/integrations/jira.svg') center center no-repeat;
				background-size: 30px 12px;
			}
			.ado-logo {
				display: inline-block;
				width: 30px;
				height: 14px;
				margin-right: 8px;
				background: url('images/integrations/azure-dev-ops.svg') center center no-repeat;
			}
		}

		.outer-option {
			.outer-option-header {
				padding: 4px 8px;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				&.selected {
					background-color: $v2-list-hover-purple;
				}
				.outer-option-name,
				.outer-option-nested-options-counter {
					font-weight: 400;
				}
				.outer-option-name {
					color: $v2-branding-purple;
				}
				.outer-option-nested-options-counter {
					color: $v2-text-gray;
				}
				.outer-option-arrow {
					width: 16px;
					height: 8px;
					background: url('images/v2/components/dropdown/arrow-down.svg') no-repeat center;
					&.expanded {
						background-image: url('images/v2/components/dropdown/arrow-up.svg');
					}
				}
			}
			.nested-option {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 4px 8px;
				padding-left: 12px;

				svg {
					margin-right: 8px;
				}
				.project-indicator-component {
					margin-right: 8px;
				}
				.nested-option-name {
					flex-grow: 1;
					color: $v2-text-gray;
				}
				&.selected {
					background-color: $v2-list-hover-purple;
				}
				&.option-pre-selected {
					background: $v2-list-hover-purple;
				}
				&.disabled {
					cursor: context-menu;
					.nested-option-name {
						color: $v2-text-light-gray;
					}
				}
				.project-option-wrapper {
					display: flex;
					flex-direction: row;
					width: 100%;
					gap: 2px;
					//align-items: center;
					position: relative;
					.sub-project-group-indicator {
						width: 7px;
						height: 9px;
						border-left: solid 2px $v2-text-light-gray;
						border-bottom: solid 2px $v2-text-light-gray;
						margin: 3px 3px 0 7px;
					}
					.nested-option-name {
						color: $v2-text-gray;
						align-self: center;
						word-break: break-all;
						&.isBlocked {
							color: $v2-projects-dropdown-blocked;
						}
						&.isDone {
							color: $v2-completed-green;
						}
					}
					.blocked-icon {
						width: 12px;
						height: 12px;
						margin-right: 4px;
						background-repeat: no-repeat;
						background-size: contain;
						background-image: url('images/v2/components/dropdown/blocked-icon.svg');
					}
				}
				.deselect-icon {
					display: flex;
					svg {
						margin-right: 0; // override some margin on svg set above
					}
				}
			}
		}
	}
}

// Ugly duplicated CSS but this is just a quick fix to allow nested dropdown to be in a portal, and it is not clear which part of the CSS belongs to the expanded dropdown.
.root-portal-container {
	font-size: 13px;
	.harvest-icon {
		position: relative;
		right: 6px;
		z-index: 9;
		width: 18px;
		min-width: 18px;
		height: 18px;
		background-size: 18px;
		border-radius: 3px;
		background-image: url('images/integrations/harvest-logo.png');
	}
	&.expanded &.locked {
		.input-arrow-wrapper {
			cursor: default;
			input {
				cursor: default;
			}
		}
	}
	.nested-dropdown-v2-label {
		text-transform: uppercase;
		color: $v2-text-light-gray;
		font-size: 9px;
		letter-spacing: 0.9px;
		position: absolute;
		top: 3px;
		left: 15px;
	}
	.custom-scrollbar-div.view-vertical > div:not(.track) {
		//needed to fix visual bug on collapsed outer option caused by customscrolldiv component
		padding-bottom: 0;
		width: calc(100% + 17px);
	}
	.input-arrow-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		height: 40px;
		cursor: pointer;

		&.hidden {
			display: none;
		}

		input {
			cursor: pointer;
		}

		&.expanded {
			border: 1px $v2-branding-purple solid;
			border-bottom: 1px $color-white solid;
			.arrow {
				background: url('images/v2/components/dropdown/arrow-up.svg') no-repeat center;
				width: 16px;
				height: 100%;
				outline: none;
			}

			.placeholder {
				color: $v2-text-light-gray;
				.id-label-wrapper {
					opacity: 0.5;
				}
			}
		}

		.placeholder {
			position: absolute;
			margin-left: 14px;
			margin-right: 35px;
			display: flex;
			align-items: center;
			width: calc(100% - 65px);
			height: 100%;
			// project id label
			.id-label-wrapper {
				margin-right: 7px;
			}

			&-label {
				flex: 1;
				white-space: nowrap;
				overflow: hidden;
				// width: calc(100% - 55px);
				text-overflow: ellipsis;

				&.faded {
					color: $v2-text-light-gray;
				}
			}
		}

		.deselect-icon {
			&:hover {
				.input-clear-icon {
					g {
						stroke: $v2-branding-purple;
					}
				}
			}
		}

		.nested-dropdown-input {
			border: none;
			font-size: 13px;
			color: $v2-nested-dropdown-input-color;
			outline: none;
			padding: 14px;
			padding-bottom: 0px;
			padding-right: 0;
			width: calc(100% - 35px);
			text-overflow: ellipsis;
			&.expanded {
				padding-bottom: 14px;
			}
			&.white-color {
				color: $color-white;
			}
			&.black-color {
				color: $color-black;
			}
			&.with-id-label {
				padding-left: 0;
				&.expanded {
					padding-left: 14px;
				}
			}
		}
		.arrow-icon-wrapper {
			width: 35px;
			height: 14px;
			background-color: transparent;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			outline: none;
		}
		.arrow {
			background: url('images/v2/components/dropdown/arrow-up.svg') no-repeat center;
			width: 16px;
			height: 8px;
			margin-right: 14px;
			outline: none;
			margin-left: 6px;
			&.white-collapsed {
				background: url('images/v2/components/dropdown/arrow-down-white.svg') no-repeat center;
			}
			&.collapsed {
				background: url('images/v2/components/dropdown/arrow-down.svg') no-repeat center;
			}
		}
	}
	.nested-dropdown-options-wrapper {
		background-color: $color-white;
		position: absolute;
		width: 100%;
		z-index: 103;
		border: 1px solid $v2-branding-purple;
		border-bottom-right-radius: 4px;
		border-bottom-left-radius: 4px;
		display: flex;
		flex: 1 1 auto;
		cursor: pointer;
		.content-loading {
			height: auto;
		}
		.clear-option {
			padding: 4px 8px;
			min-height: 27px;
			.clear-option-name {
				color: $v2-text-gray;
			}
			&.selected {
				background-color: $v2-list-hover-purple;
			}
			&.option-pre-selected {
				background: $v2-list-hover-purple;
			}
		}

		.outer-option:not(:first-child),
		.clear-option {
			border-top: 1px solid $app-border-color;
			.jira-logo {
				display: inline-block;
				width: 30px;
				height: 12px;
				margin-right: 8px;
				background: url('images/integrations/jira.svg') center center no-repeat;
				background-size: 30px 12px;
			}

			.ado-logo {
				display: inline-block;
				width: 30px;
				height: 12px;
				margin-right: 8px;
				background: url('images/integrations/azure-dev-ops.svg') center center no-repeat;
				background-size: contain;
			}
		}

		.outer-option {
			.outer-option-header {
				padding: 4px 8px;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				&.selected {
					background-color: $v2-list-hover-purple;
				}
				.outer-option-name,
				.outer-option-nested-options-counter {
					font-weight: 400;
				}
				.outer-option-name {
					color: $v2-branding-purple;
				}
				.outer-option-nested-options-counter {
					color: $v2-text-gray;
				}
				.outer-option-arrow {
					width: 16px;
					height: 8px;
					background: url('images/v2/components/dropdown/arrow-down.svg') no-repeat center;
					&.expanded {
						background-image: url('images/v2/components/dropdown/arrow-up.svg');
					}
				}
			}
			.nested-option {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 4px 8px;
				padding-left: 12px;

				svg {
					margin-right: 8px;
				}
				.project-indicator-component {
					margin-right: 8px;
				}
				.nested-option-name {
					flex-grow: 1;
					color: $v2-text-gray;
				}
				&.selected {
					background-color: $v2-list-hover-purple;
				}
				&.option-pre-selected {
					background: $v2-list-hover-purple;
				}
				&.disabled {
					cursor: context-menu;
					.nested-option-name {
						color: $v2-text-light-gray;
					}
				}
				.project-option-wrapper {
					display: flex;
					flex-direction: row;
					width: 100%;
					gap: 2px;
					//align-items: center;
					position: relative;
					.sub-project-group-indicator {
						width: 7px;
						height: 9px;
						border-left: solid 2px $v2-text-light-gray;
						border-bottom: solid 2px $v2-text-light-gray;
						margin: 3px 3px 0 7px;
					}
					.nested-option-name {
						color: $v2-text-gray;
						align-self: center;
						word-break: break-all;
						&.isBlocked {
							color: $v2-projects-dropdown-blocked;
						}
						&.isDone {
							color: $v2-completed-green;
						}
					}
					.blocked-icon {
						width: 12px;
						height: 12px;
						margin-right: 4px;
						background-repeat: no-repeat;
						background-size: contain;
						background-image: url('images/v2/components/dropdown/blocked-icon.svg');
					}
				}
				.deselect-icon {
					display: flex;
					svg {
						margin-right: 0; // override some margin on svg set above
					}
				}
			}
		}
	}
}
