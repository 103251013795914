.warning-message-component {
	width: 100%;
	.icon-container {
		height: 20px;
		width: 20px;
		background-size: contain;
		background-image: url('images/components/warning.svg');
		background-position: center;
		background-repeat: no-repeat;
		margin-bottom: 10px;
		&.info-icon {
			background-image: url('images/components/info-icon-blue.svg');
		}
	}
	.message {
		font-size: 14px;
		font-weight: bold;
		&.red {
			color: $negative-value;
		}
	}
}
