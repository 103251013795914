$staffing-mode-padding-bottom: 64.5px;
$bar-thickness: 8px;

.canvas-placeholders-scheduling {
  position: relative;

  .hover-area {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    position: relative;
  }

  &.staffing-mode {
    width: calc(100% - (#{$bar-thickness} * 2));
    margin-left: $bar-thickness;
    margin-bottom: $bar-thickness;

    .hover-area {
      margin-bottom: $staffing-mode-padding-bottom;
    }

    .staffing-mode-bar {
      background-color: $v2-branding-purple;
      position: fixed;
      width: 8px;
      height: 100%;
      z-index: 5;

      &.left {
        left: 0;
        top: 0;
      }

      &.right {
        top: 0;
        left: auto;
        right: 0;
      }

      &.bottom {
        bottom: 0;
        width: 100%;
        height: 8px;
        left: 0;
        right: auto;
      }
    }

    .staffing-mode-header {
      background-color: $v2-branding-purple;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: fixed;
      top: 0;
      left: 0;
      padding: 0 calc(#{$bar-thickness} + 20px);
      width: 100%;
      height: 70px;
      color: #fff;
      z-index: 100;

      .title {
        font-size: 15px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.225px;
        text-align: left;
        align-self: center;
      }

      .exit-staffing-mode {
        cursor: pointer;
        background-image: url('images/components/overlay/close-white.svg');
        border: 0;
        height: 16px;
        width: 16px;
        background-size: 16px;
      }
    }
  }

  .placeholders-scheduling-top-left {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: white;
    font-weight: bold;
    color: $v2-text-light-gray;
    border-right: 1px solid $color-martin-timeline-border;
    border-bottom: 1px solid $color-martin-timeline-border;

    .staffing-mode-top-left-container {
      font-size: 15px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.225px;
      text-align: left;
      color: $text-color-secondary;
      height: 100%;
      width: 100%;
      padding-left: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .hover-effects-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    overflow: hidden;
    pointer-events: none;

    &.staffing-mode {
      padding-bottom: $staffing-mode-padding-bottom;
    }

    * {
      pointer-events: all;
    }

    .context-menu-v2 {
      position: fixed;
    }

    .placeholders-scheduling-actions-menu {
      position: absolute;
    }

    .placeholders-scheduling-single-action-button {
      position: absolute;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;

      &:hover {
        background-color: #F7F7FE;
      }

      &:active {
        background-color: #F1F1FD;
      }
    }

    .placeholders-scheduling-detail-box {
      position: fixed;
      z-index: 999;
      background-color: white;
      padding: 17px 14px;
      pointer-events: none;
      border: solid 1px $v2-grey;
      border-radius: 4px;

      .detail-box-inner-container {
        background-color: white;
        position: relative;
        left: 0;
        top: 0;
        width: 180px;
        border: none;

        .detail-box-assigned-person-container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }
      }
    }

    .white-triangle {
      position: absolute;
      width: 10px;
      height: 10px;
      overflow: hidden;
      border-left: solid 1px $v2-grey;
      border-bottom: solid 1px $v2-grey;
      background-color: white;
      &.right {
        left: -6px;
        transform: rotate(45deg);
      }
      &.left {
        right: -6px;
        transform: rotate(225deg);
      }
      &.top {
        top: 16px;
      }
      &.bottom {
        bottom: 16px;
      }
    }

    .placeholder-information-tooltip {
      position: fixed;
      z-index: 999;
      pointer-events: none;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      border-radius: 8px;
      padding: 16px;
      box-shadow: 5px 5px 15px 0 rgba(0,0,0,0.1);
      -webkit-box-shadow: 5px 5px 15px 0 rgba(0,0,0,0.1);
      -moz-box-shadow: 5px 5px 15px 0 rgba(0,0,0,0.1);

      .placeholder-information-content {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .distribution-box-container {
      position: fixed;
      z-index: 999;
      background-color: white;
      padding: 17px 14px;
      pointer-events: none;
      border: solid 1px $v2-grey;
      border-radius: 4px;

      .inner-container {
        position: relative;
        min-width: 180px;
        .inner-headline {
          font-weight: 600;
          font-size: 12px;
          margin-bottom: 7px;
        }
        .progress-percentage {
          font-weight: 600;
        }
        .capacity-container {
          font-weight: 600;
        }
        .inner-container-item {
          &:not(:last-child) {
            margin-bottom: 10px;
          }
          .label {
            font-size: 8px;
            height: 12px;
            text-transform: uppercase;
            font-weight: 500;
            letter-spacing: 0.5px;
            color: #b4b9c0;
          }

          .content {
            font-size: 11px;
            display: flex;
            letter-spacing: 0.5px;
            &.capacity-content {
              flex-direction: row;
              .capacity-content-container {
                display: flex;
                flex-direction: column;
                &.rectangle-container {
                  width: 12px;
                  margin-right: 5px;
                  .rectangle {
                    &.positive {
                      background-color: #b2efd1;
                    }
                    &.negative {
                      background-color: #ffcaca;
                    }
                  }
                }
                &.capacity-container {
                  text-align: right;
                  margin-right: 9px;
                }
                div {
                  height: 12px;
                  &:not(:last-child) {
                    margin-bottom: 3px;
                  }
                }
              }
            }
          }
        }
        /*
        .top-line {
            display: flex;
            flex-direction: row;
            font-size: 12px;
            margin-bottom: 16px;
            &.empty {
                margin-bottom: 2px;
            }
            .name {
                font-weight: bold;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin-right: 4px;
            }
            .remaining {
                flex-grow: 1;
                flex-shrink: 0;
                margin-left: 4px;
            }
        }
        .role-container {
            .role-row {
                display: flex;
                flex-direction: row;
                margin-bottom: 2px;
                height: 14px;
                .percentage-container {
                    width: 60px;
                    margin-right: 8px;
                    flex-shrink: 0;
                    text-align: right;
                    font-size: 12px;
                    line-height: 14px;
                    font-weight: bold;
                    color: white;
                    padding: 0 4px;
                    &.grey {
                        background-color: #d1d3d8;
                    }
                    &.orange {
                        background-color: #faa434;
                    }
                    &.red {
                        background-color: #e21e5b;
                    }
                    &.green {
                        background-color: #00cc66;
                    }
                }
                .name-container {
                    font-size: 12px;
                    line-height: 14px;
                    font-weight: 500;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }*/
      }
    }

    .heatmap-cell-detail-box {
      position: fixed;
      z-index: 999;
      pointer-events: none;
      display: flex;
      align-items: center;
      justify-content: center;

      .detail-box-inner-container {
        position: absolute;
        height: 100%;
        width: auto;
        min-width: 80px;
        max-width: 100%;
        padding: 0 6px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        background-color: #F7F7FE;
        border: solid 1px #E7E7F3;
        border-radius: 4px;
        box-shadow: 0 0 5px 0 rgba(181,181,181,0.25);
        -webkit-box-shadow: 0 0 5px 0 rgba(181,181,181,0.25);
        -moz-box-shadow: 0 0 5px 0 rgba(181,181,181,0.25);
        font-size: 13px;
        font-weight: 400;

        span {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .light-triangle {
        position: absolute;
        z-index: 1000;
        width: 10px;
        height: 10px;
        overflow: hidden;
        border-left: solid 1px #E7E7F3;
        border-bottom: solid 1px #E7E7F3;
        background-color: #F7F7FE;

        &.center {
          left: calc(50% - 5px);
        }

        &.right {
          left: -5px;
          top: calc(50% - 5px);
          transform: rotate(45deg);
        }

        &.left {
          right: -5px;
          top: calc(50% - 5px);
          transform: rotate(225deg);
        }

        &.top {
          top: -5px;
          transform: rotate(135deg);
        }

        &.bottom {
          bottom: -5px;
          transform: rotate(-45deg);
        }
      }
    }
  }

  .staffing-mode-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    bottom: $bar-thickness;
    background-color: white;
    color: black;
    width: calc(100% - (#{$bar-thickness} * 2));
    height: auto;
    z-index: 100;
    padding: 16px 0;
    box-shadow: 0 8px 24px 2px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 8px 24px 2px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 8px 24px 2px rgba(0, 0, 0, 0.12);

    .footer-warnings {
      height: 100%;
      p {
        color: $v2-workflow-task-warning-red;
      }
    }

    .actions {
      display: flex;
      margin-right: 85px;

      .checkbox-wrapper {
        margin: 0 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .delete-placeholder-text {
          margin-left: 8px;
          font-size: 13px;
          font-weight: 400;
        }
      }

      button {
        padding: 9px 18px 9px 20px;
        margin: 0 5px;
      }
    }
  }

}