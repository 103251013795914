.cost-periods-modal-container {
	h1 {
		color: $v2-text-gray;
		font-size: 20px;
		font-weight: 500;
	}

	.person {
		margin: 24px 0;
		display: flex;
		align-content: center;

		img {
			margin-right: 6px;
		}

		.name {
			font-size: 13px;
			color: $v2-text-gray;
			align-self: center;
		}
	}

	.cost-periods-container {
		.start-date {
			width: 136px;

			&.header,
			.no-start-date {
				padding-left: 16px;
			}
		}

		.line {
			width: 43px;
			text-align: center;
		}

		.end-date {
			width: 125px;

			&.header,
			.no-end-date {
				padding-left: 16px;
			}
		}

		.internal-cost:not(.header) {
			font-size: 13px;
			width: 142px;
			margin-left: 75px;

			&:not(.header) {
				input {
					border-radius: 4px;
					&:hover {
						border: 1px solid $v2-date-picker-button-border-color;
					}

					&:focus {
						outline: 0;
						border: 1px solid $v2-branding-purple;
					}
				}
			}
		}

		.internal-cost.header {
			margin-left: 81px;
		}

		.headers {
			display: flex;
			align-items: center;
			border-bottom: 1px solid $app-border-color;
			color: $v2-text-light-gray;
			font-size: 9px;
			letter-spacing: 1px;
			font-weight: 400;
			text-transform: uppercase;
			padding-bottom: 4.5px;
		}

		.date-picker-button {
			&.text-version {
				font-size: 11px;
				padding: 8px 16px;
				height: auto;
				text-transform: uppercase;
				border: 1px solid transparent;

				&:hover {
					border: 1px solid $app-border-color;
				}

				&.locked:hover {
					border: 1px solid transparent;
				}

				&.expanded {
					border: 1px solid $v2-branding-purple;
				}
			}
		}
	}
}
