.forecast-person {
	display: flex;
	align-items: center;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	.image-container {
		position: relative;
		top: 0;
		left: 0;
		width: 24px;
		height: 24px;
		.img-wrapper {
			.image-container,
			.svg-container {
				display: flex;
				justify-content: center;
				align-items: center;
				position: absolute;
			}

			.svg-container {
				pointer-events: none;
			}
		}
		.disabled-overlay {
			@include disabled-overlay;
		}
		.disabled-overlay-hidden {
			display: none;
		}
		.person-component-initials-container {
			padding-left: 2px;
		}
	}

	&.large {
		.image-container {
			width: 24px;
			height: 24px;
		}
		img {
			flex: 0 0 24px;
			width: 24px;
			height: 24px;
			border-radius: 5px;
			margin-top: 2px;
		}
	}

	&.medium {
		.image-container {
			width: 20px;
			height: 20px;
		}
		img {
			flex: 0 0 20px;
			width: 20px;
			height: 20px;
			border-radius: 5px;
			margin-top: 2px;
		}
	}

	&.small {
		.image-container {
			width: 16px;
			height: 16px;
		}
		img {
			flex: 0 0 16px;
			width: 16px;
			height: 16px;
			border-radius: 5px;
			margin-top: 2px;
		}
	}

	.person-info {
		overflow: hidden;
		text-overflow: ellipsis;
		width: calc(100% - 24px);
		padding: 0 3px 0 0px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		span {
			white-space: nowrap;
			text-overflow: ellipsis;
			display: block;
			overflow: hidden;
		}
		.hide {
			display: none;
		}
		.name {
			padding-left: 7px;
			&.deactivated {
				color: $v2-text-light-gray;
			}
		}
		.role {
			font-weight: $base-font-medium-weight;
			font-size: 11px;
			padding-left: 7px;
		}
		.role, .name-inner {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			&.personSelected {
				color: $v2-branding-purple;
			}
		}
	}
	.hide {
		display: none;
	}
}
