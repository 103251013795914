.project-settings {
	display: flex;
	flex-direction: column;

	input[type='checkbox'] + span,
	input[type='radio'] + span {
		cursor: pointer;
	}

	label {
		display: block;
		width: 100%;
		padding: 5px 0;
		input[type='checkbox'] {
			width: 16px;
			height: 16px;
		}
		span {
			padding: 0 0 0 8px;
		}
	}

	.section-body {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		@include scroll-y;
	}

	.select-wrapper {
		width: 240px;
	}
	.select-wrapper-label {
		width: 240px;
	}

	.section-title-wrapper,
	.sub-section-title-wrapper {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		&.col {
			display: flex;
			flex-direction: column;
		}
		h3,
		h4 {
			margin-bottom: 15px;
		}
	}

	.sub-section-title-wrapper {
		.info-component-icon-wrapper {
			margin-top: 17px;
		}
	}

	.filters {
		.filter {
			background-position: 20px center;
			background-repeat: no-repeat;
			padding: 13px 20px 0px 55px;
			cursor: pointer;
			font-weight: $base-font-medium-weight;

			color: $color-light-black;

			&:hover {
				background-color: $menu-hover-color;
				color: $input-color;
			}

			&:active {
				padding-top: 14px;
			}

			&.load-settings {
				background-image: url('images/sections/project/phases/load.svg');
				border-left: 1px solid $app-border-color;
				border-right: 1px solid $app-border-color;
			}

			&.save-settings {
				background-image: url('images/sections/project/phases/save.svg');
				border-right: 1px solid $app-border-color;
			}
		}
	}
	.catalogue-link {
		text-decoration: none;
	}
	.delete-project-button,
	.app-catalogue-link-button {
		@include primary-button;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-left: 10px;
		width: auto;
		font-size: 13.5px;
		.locked-icon {
			padding-left: 10px;
		}
	}
	.delete-project {
		display: flex;
		flex-flow: row wrap;
		bottom: 0;
		padding: 15px;
		background: #fff;
		border: 1px solid $app-border-color;
		border-bottom: none;
	}

	fieldset {
		@include content-frame;
		margin: 0 0 15px 0;
		padding: 20px;
		font-size: 0.875rem;

		.description {
			line-height: 21px;
			margin-bottom: 21px;
			margin-top: 0;
		}

		h3 {
			margin-top: 0;
		}
	}

	.general {
		.general-header {
			display: flex;
			flex-direction: row;
			@include gear-menu;
			.gear-menu-drop-down {
				width: 140px !important;
				left: -120px !important;
				.locked-delete-button {
					display: flex;
					flex-direction: row;
					color: $v2-text-light-gray;
					align-items: center;
				}
			}
		}

		.prefix {
			font-weight: $base-font-medium-weight;
			padding: 25px 5px 0 5px;
			display: flex;
			align-items: center;
		}
		.titles {
			flex: 1;
			display: flex;
			flex-direction: column;
			.title-id-inputs {
				display: flex;
				flex-direction: row;
				.wrapper-locked {
					.input-wrapper {
						padding: 0;
					}
				}
				.id-wrapper {
					input {
						width: 50px;
					}
				}
			}
		}
		.status-label {
			margin-top: 20px;
			margin-bottom: 4px;
			font-size: 0.75rem;
			color: $material-label-color;
		}
		.status {
			display: flex;
			flex-direction: row;
			width: 687px;
			.editor {
				flex: 1 1 auto;
			}
			.colors-picker-container {
				margin-top: 8px;
			}
		}
		.project-description {
			width: 687px;
		}
		.budget-type-button-text {
			.header-tooltip-wrapper {
				display: flex;
				margin-top: 16px;
				h3 {
					margin-right: 8px;
				}
			}
			.budget-button-text-wrapper {
				display: flex;
				.budget-type {
					width: 200px;
					margin-right: 125px;
					font-size: 16px;
					align-self: center;
				}
				.budget-type-change-button {
					@include primary-button;
				}
			}
		}
		.status,
		.management {
			.forecast-person-list {
				.Select--multi {
					.Select-control {
						border: 1px solid $app-border-color;
						.Select-placeholder {
							padding-left: 10px;
							font-size: 14px;
						}
					}
				}
			}
		}
		.color-list-option-container {
			display: flex;
			align-items: center;
			.color-list-option {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				display: inline-block;
				margin-right: 5px;
			}
		}
	}

	.retainer-settings {
		.period-length {
			max-width: 240px;
		}
		.units-wrapper {
			label {
				display: block;
				width: 100%;
				padding: 5px 0;

				span {
					padding: 0 0 0 8px;
				}
			}
		}
	}

	.planning-settings {
		.group-label,
		.hours-pr-point {
			display: inline-block;
			span {
				display: block;
				padding: 0 0 6px 2px;
			}
			.label {
				font-size: 0.75rem;
				color: #858585;
			}
			.hours-input-wrapper {
				width: 90px;
			}
		}

		.units-wrapper {
			label {
				display: block;
				width: 100%;
				padding: 5px 0;

				span {
					padding: 0 0 0 8px;
				}
			}
		}
		.use-billable {
			width: auto;
			padding: 5px 0;
			cursor: pointer;
			display: flex;
			align-items: center;

			> span {
				padding: 0 0 0 8px;
			}
		}

		/* .hours-pr-point {
			margin-top: 21px;
		} */
	}

	.task-levels {
		.levels-wrapper {
			label {
				display: block;
				width: 100%;
				padding: 5px 0;

				span {
					padding: 0 0 0 8px;
				}
			}
		}

		.task-label-wrapper {
			display: flex;
			flex-direction: row;

			label {
				display: flex;
				flex-direction: column;
				margin-bottom: 20px;

				> span {
					padding: 0 0 5px 0;
				}

				input[type='text'] {
					@include generic-text-input;
					margin: 0 0 15px 10px;
				}
			}

			.example {
				margin-left: 30px;
				font-weight: $base-font-medium-weight;

				> span {
					margin-bottom: 8px;
					font-weight: 400;
				}
				.prefix {
					padding: 3px 10px 3px 28px;
					background-position: left top;
					background-repeat: no-repeat;
					background-size: 20px;
					&.task {
						background-image: url('images/sections/project/settings/task.svg');
					}
					&.sub-task {
						background-image: url('images/sections/project/settings/sub-task.svg');
					}
				}
			}
		}
	}
	.public-create-task-form {
		.use-public-create-task-form-label {
			margin-bottom: 11px;
		}
		.input {
			cursor: pointer;
		}
		.copy-to-clipboard-button {
			height: 13px;
			width: 13px;
			margin-left: 8px;
			border: none;
			background-position: left top;
			background-repeat: no-repeat;
			background-size: contain;
			background-image: url('images/v2/copy.svg');
		}
	}

	.task-workflow {
		input[type='text'] {
			@include generic-text-input;
			margin-right: 0px;
			border: 1px solid transparent;
			&:hover {
				border: 1px solid $app-border-color;
			}
		}

		.status-category {
			h3 {
				margin: 0, 0, 10px, 0;
				padding: 0;
			}
		}

		.workflow-option {
			margin-bottom: 10px;
			cursor: move;
			.drag-handle {
				display: inline-block;
				width: 20px;
				background: url('images/cross-section-icons/table/drag-handle.svg') no-repeat left center;
			}
			&.locked {
				.drag-handle {
					background-image: none;
				}
				cursor: default;
			}
			.workflow-name {
				font-size: 16px;
				font-weight: $base-font-medium-weight;
			}

			.remove {
				border: none;
				display: none;
				cursor: pointer;
				font-size: 12px;
				text-decoration: none;
				margin-left: 10px;
				background: url('images/sections/project/client/remove.svg') no-repeat left center;
				background-size: 16px;
				padding: 0 10px 0 21px;

				&:hover {
					color: $color-purple-hover-active;
				}
			}
			.info {
				font-size: 12px;
			}

			&:hover {
				background-color: $light-hover-color;
				.remove {
					display: inline;
				}
			}
		}

		.new-workflow {
			margin: 10px 15px 10px 0px;
			input[type='text'],
			input[type='number'] {
				margin: 0 10px 0 0;
				padding: 9px 10px;
				font-weight: $base-font-medium-weight;

				border: 1px solid $app-border-color;
				border-radius: 3px;
			}

			.add-button {
				@include primary-button;
			}
		}
	}

	.time-settings {
		.use-sprints {
			width: auto;
			padding: 5px 0;
			cursor: pointer;

			> span {
				padding: 0 0 0 8px;
			}
		}

		.sprint-options {
			padding-top: 15px;
			.wrapper,
			.wrapper-locked {
				max-width: 200px;
			}
		}

		.dates-wrapper {
			display: flex;
			flex-direction: row;
			.date-input,
			.date-input-focused,
			.date-input-locked {
				margin-right: 40px;
				width: 240px;
			}
		}
	}
	.client-view {
		.insight-options {
			padding-top: 15px;
			display: flex;
			label {
				margin-right: 15px;
				height: 59px;
				> span {
					display: block;
					padding: 0 0 6px 2px;
				}

				input[type='text'],
				input[type='number'] {
					display: block;
					@include generic-text-input;
					margin-right: 0;
				}

				select {
					height: 35px;
				}
			}
			.select-wrapper {
				width: 450px;
			}
		}
		.links {
			li {
				margin-bottom: 5px;
			}
		}
		.client-password {
			margin: 10px 15px 10px 0px;
			input[type='text'],
			input[type='number'] {
				margin: 0 10px 0 0;
				padding: 9px 10px;
				font-weight: $base-font-medium-weight;

				border: 1px solid $app-border-color;
				border-radius: 3px;
			}
		}
	}
	.fixed-rate-row-container {
		display: flex;
		align-items: center;
		label {
			width: auto;
		}
		.wrapper,
		.wrapper-locked {
			margin-left: 12px;
			label {
				padding: 0;
			}
		}
	}
	.integrations {
		> div {
			padding-bottom: 1.5em;
		}
		.harvest-wrapper {
			display: flex;
			flex-flow: wrap;
			h3 {
				width: 100%;
			}

			.harvest-show-archived {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				input {
					margin-top: 10px;
				}
			}
			.remove-harvest-project-linking {
				@include primary-button;
				margin-left: 2em;
				margin-top: 35px;
			}
		}
		.economic-wrapper {
			.sub-economic-wrapper {
				.input-container {
					max-width: 500px;
					width: 500px;
				}
				.select {
					max-width: 500px;
					width: 500px;
				}
				display: flex;
				align-items: flex-end;
			}
		}
		.gitlab-wrapper {
			.sub-gitlab-wrapper {
				display: flex;
				align-items: flex-end;
				.input-container {
					max-width: 300px;
				}
				.select {
					max-width: 300px;
				}
				> :first-child {
					width: 300px;
					//flex: 1 1 auto;
				}
			}
		}
		.github-wrapper {
			.sub-github-wrapper {
				display: flex;
				flex-direction: column;

				.input-container {
					max-width: 300px;
				}
				.select {
					max-width: 300px;
				}
				> :first-child {
					width: 300px;
					//flex: 1 1 auto;
				}
				.github-flex-container {
					display: flex;
					flex-direction: row;

					.github-sync-warning {
						display: flex;
						flex-direction: row;
						align-items: center;

						img {
							margin-left: 20px;
							width: 30px;
							height: 30px;
						}

						span {
							padding-left: 10px;
							color: $v2-workflow-task-warning-red;
						}
					}

					.github-repository-table {
						margin-right: 10px;
						margin-top: 20px;

						.github-repository-header {
							width: 300px;
						}

						.github-repository-header,
						.github-sync-header {
							font-weight: bold;
						}
					}
				}
			}

			.link-github-account {
				@include primary-button;
			}
		}

		.ado-wrapper {
			.ado-current-link-container {
				display: flex;
				flex-direction: column;

				.ado-current-link-line {
					display: flex;
					flex-direction: row;

					input[type='checkbox'] {
						float: center;
						margin-top: 2px;
						margin-left: 4px;
						width: 16px;
						height: 16px;
					}

					.ado-current-link {
						margin-right: 4px;
					}
				}
			}

			.ado-button-wrapper {
				margin-left: 8px;
				margin-top: 10px;
				display: flex;

				.ado-button {
					@include primary-button;
					margin-right: 4px;
				}
			}
		}

		.unit4-wrapper {
			.unit4-description {
				margin-bottom: 20px;
				.unit4-sync-button {
					margin-left: 8px;
				}
			}
		}

		.exclude-description {
			margin-top: 3em;
		}
		.gitlab-project-linking {
			@include secondary-button;
			margin-left: 2em;
		}
		.jira-wrapper {
			.jira-project-name {
				margin-bottom: 8px;
			}
			.sub-jira-wrapper {
				button {
					margin-right: 8px;
				}
			}
		}
	}

	.save {
		.buttons {
			display: flex;
		}
		.cancel-button {
			@include secondary-button;
			margin-left: auto;
		}
		.submit-button {
			@include primary-button;
			margin-left: 15px;
		}
	}
	.custom-button-container {
		height: 30px;
	}
}
