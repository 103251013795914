// enlarged file window
.flex-modal {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	z-index: 99;
	background: rgba(0, 0, 0, 0.75);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	cursor: default;
	tr {
		&:hover {
			color: inherit !important;
		}
	}
	.flex-container {
		min-width: 300px;
		max-height: 95%;
		box-shadow: 0 10px 20px 10px rgba(0, 0, 0, 0.2);
		border-radius: 2px;
		background: $color-white;
		display: flex;
		flex-direction: column;
		&.full-height {
			height: 95%;
		}
		.content {
			flex: 1 1 auto;
			padding-left: 15px;
			padding-right: 15px;
			max-height: 100%;
			display: flex;
			flex-direction: column;
			.header {
				flex: 0 0 auto;
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				margin-bottom: 10px;
				&.headerBorder {
					border-bottom: 1px solid $app-border-color;
				}
				.close-button {
					@include close-button;
				}
				h2 {
					margin-top: 15px;
					margin-bottom: 10px;
				}
			}
			.content-body {
				flex: 1 1 auto;
				display: flex;
				flex-direction: column;

				.shortcut-table {
					flex: 1 1 auto;
					width: 550px;
					display: flex;
				}
				.new-line {
					display: block;
				}
			}
			.input-container,
			.select {
				max-width: none;
			}
			.select {
				top: 55px;
			}
		}
		.controls {
			padding-top: 15px;
			padding-left: 15px;
			margin-bottom: 20px;
			text-align: right;
			.button-primary,
			.button-secondary,
			.button-delete {
				margin-right: 15px;
			}
			.button-primary {
				@include primary-button;
			}
			.button-secondary {
				@include secondary-button;
			}
			.button-delete {
				@include delete-button;
			}
			span {
				cursor: pointer;
			}
			button:disabled {
				span {
					cursor: default;
				}
			}
		}
	}

	@include standard-table;
	table {
		.shortcut-modal-sub-title {
			font-weight: 700;
		}
	}
}
