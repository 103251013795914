/**
 * Header.
 */
header.app-header-v2 {
	min-height: 36px;
	overflow: visible;
	&.insights-section {
		height: 68px;
		min-height: 68px;
		overflow: visible;
	}
	&.include-separator {
		border-bottom: 2px solid $v2-branding-purple;
	}
	display: flex;
	flex-direction: column;
	.content {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		background: $color-white;
		overflow: visible;
		a.logo {
			flex: 0 0 20px;
			background-size: contain;
			min-width: 18px;
			min-height: 20px;
			transition: all 0.2s ease;
			margin: 7px 23px 7px 23px;

			@supports (-webkit-mask: url($app-header-logo-white-url) no-repeat center) or (mask: url($app-header-logo-white-url) no-repeat center) {
				-webkit-mask: url($app-header-logo-white-url) no-repeat center;
				mask: url($app-header-logo-white-url) no-repeat center;
				background-color: #dddddf;
				&:hover,
				&:focus {
					background-color: $v2-branding-purple;
				}
			}
			@supports (not (-webkit-mask: url($app-header-logo-white-url) no-repeat center)) and (not (mask: url($app-header-logo-white-url) no-repeat center)) {
				background: url($app-header-logo-gray-url) no-repeat center;
				&:hover,
				&:focus {
					background: url($app-header-logo-v2-url) no-repeat center;
				}
			}
			// IE11
			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				background: url($app-header-logo-gray-url) no-repeat center;
				&:hover,
				&:focus {
					background: url($app-header-logo-v2-url) no-repeat center;
				}
			}
		}
		.left,
		.right {
			display: flex;
			height: 34px;
		}
		.left {
			flex: 1 1 auto;
		}
		.right {
			flex: 1 0 0px;
			overflow: visible;
		}
		ul.main-menu {
			padding: 0;
			margin: 0;
			li {
				list-style-type: none;
				height: 100%;
				min-width: 108px;
				text-align: center;
				border: 1px solid white;
				border-top-width: 0px;
				border-bottom-width: 0px;
				a.section-link {
					white-space: nowrap;
					display: block;
					font-size: 13px;
					font-weight: 500;
					letter-spacing: 0.5px;
					text-align: center;
					color: $v2-text-gray;
					text-decoration: none;
					padding: 9px 16px;
					width: auto;
					height: 100%;
					&.active {
						color: $color-white;
						background-color: $v2-branding-purple;
					}
					&:hover {
						opacity: 0.9;
						color: $color-white;
						background-color: $v2-branding-purple;
					}
				}
				&.onboarding {
					padding: 4px 16px;
				}
			}
		}
		.right {
			justify-content: flex-end;
			align-items: center;
			button {
				border: none;
			}
			.custom-button-container {
				height: 26px;
				border: solid 2px $v2-branding-purple;
				margin-right: 20px;
				white-space: nowrap;
			}

			.profile-menu-wrapper {
				position: relative;
				flex: 0 0 auto;
				margin: 16px 24px 16px 16px;
				.profile {
					display: flex;
					height: 100%;
					width: 100%;
					align-items: center;
					outline: none;
					// 	padding: 2px 2px;
					&:hover,
					&:focus {
						.hexagon {
							border-left: solid 3px $v2-branding-purple;
							border-right: solid 3px $v2-branding-purple;
						}
						.hexagon:before {
							border-top: solid 4.2426px $v2-branding-purple;
							border-right: solid 4.2426px $v2-branding-purple;
						}

						.hexagon:after {
							border-bottom: solid 4.2426px $v2-branding-purple;
							border-left: solid 4.2426px $v2-branding-purple;
						}
					}
					.person-initials-hexagon-shape {
						margin-top: 0px;
						margin-left: -2px;
					}
					img {
						height: 26px;
						width: 24px;
						position: absolute;
						top: -6px;
						left: -2px;
						//z-index: -1;
					}
					.hexagon {
						position: relative;
						width: 26px;
						height: 13px;
						background-color: transparent;
						margin: 7.51px 0;
						border-left: solid 3px transparent; //$v2-branding-purple;
						border-right: solid 3px transparent; // $v2-branding-purple;
						z-index: 2;
					}

					.hexagon:before,
					.hexagon:after {
						content: '';
						position: absolute;
						z-index: 1;
						width: 18.38px;
						height: 18.38px;
						-webkit-transform: scaleY(0.5774) rotate(-45deg);
						-ms-transform: scaleY(0.5774) rotate(-45deg);
						transform: scaleY(0.5774) rotate(-45deg);
						background-color: inherit;
						left: 0.8076px;
					}

					.hexagon:before {
						top: -9.1924px;
						border-top: solid 4.2426px transparent; // $v2-branding-purple;
						border-right: solid 4.2426px transparent; // $v2-branding-purple;
					}

					.hexagon:after {
						bottom: -9.1924px;
						border-bottom: solid 4.2426px transparent; // $v2-branding-purple;
						border-left: solid 4.2426px transparent; // $v2-branding-purple;
					}
				}
				ul {
					background-color: $color-white;
					border: 1px solid $app-border-color;
					border-radius: 4px;
					box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
					top: 33px;
					min-width: 155px;
					position: absolute;
					li {
						color: $v2-text-gray;
						padding: 8px 16px 8px 12px;
						&:focus,
						&:hover {
							background-color: $v2-list-hover-purple;
						}
					}
				}
				.profile-dropdown {
					a {
						text-decoration: none;
					}
				}
			}

			.create-new-wrapper {
				position: relative;
				margin: 4px 0 4px 4px;
				height: 26px;
				flex: 0 0 26px;
				.create-new {
					width: 100%;
					height: 100%;
					outline: none;
					padding: 0 16px 0 32px;
					align-items: center;
					background: url('images/v2/components/header/plus.svg') no-repeat 10px 7px;
					background-size: 12px auto;
					background-color: $v2-create-button-green;
					&:focus,
					&:hover {
						background-color: $v2-create-button-green-active;
					}
					&.expanded {
						background-color: $v2-create-button-green-active;
						background-image: url('images/v2/components/header/x.svg');
					}
					.create-new-btn-text {
						font-size: 10px;
						color: white;
						font-weight: 500;
						letter-spacing: 0.5px;
					}
				}
				ul {
					background-color: $color-white;
					top: 30px;
					border: solid 1px #e6e6e6;
					border-radius: 4px;
					box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
					position: absolute;
					min-width: 180px;
					li {
						padding: 8px 16px 8px 12px;
						color: $v2-create-menu-text-color;
						&:focus,
						&:hover {
							color: $v2-branding-purple;
							background-color: $v2-list-hover-purple;
						}
					}
				}
			}
			ul {
				right: 0;
				padding: 0;
				margin: 0;
				z-index: 999;
				display: none;
				list-style-type: none;
				&.expanded {
					display: inline;
				}
				li {
					font-size: 13px;
					text-align: left;
					outline: none;
					cursor: pointer;
					&.create-new-option {
						text-transform: capitalize;
					}
					&.profile-menu-option,
					&.create-new-option {
						background-position: right 10px center;
						background-repeat: no-repeat;
						background-size: 18px;
						display: flex;
						justify-content: space-between;
						max-height: 36.25px;
						align-items: center;
						&.disabled {
							color: $v2-text-light-gray;
						}
						&.my-profile {
							&:hover,
							&:focus {
								color: $v2-branding-purple;
							}
						}
						&.account {
							&:hover,
							&:focus {
								color: $v2-branding-purple;
							}
						}
						&.help {
							&:hover,
							&:focus {
								color: $v2-branding-purple;
							}
						}
						&.show-timer {
							&:hover,
							&:focus {
								color: $v2-branding-purple;
							}
						}
						&.shortcuts {
							&:hover,
							&:focus {
								color: $v2-branding-purple;
							}
						}
						&.logout {
							&:hover,
							&:focus {
								color: $v2-branding-purple;
							}
						}
						&.what-is-new {
							&:hover,
							&:focus {
								color: $v2-branding-purple;
							}
						}
					}
				}
			}
		}
	}
	.separator {
		margin-top: 0;
		flex: 0 0 2px;
		background-color: $v2-branding-purple;
	}
	.hide-header {
		display: none;
	}
	.insights-section-header {
		background-color: $color-white;
		padding-left: 2px;
		.insights-section-select-wrapper {
			width: 224px;
			border-radius: 4px;
			height: 34px;
			background-color: $v2-branding-purple;
			.nested-dropdown-v2 {
				width: 224px;
				border: none;
				&.expanded {
					background-color: $color-white;
					.input-arrow-wrapper {
						border: 1px solid $v2-branding-purple;
						border-bottom: none;
					}
				}
				.arrow-icon-wrapper {
					min-height: none;
				}
				.input-arrow-wrapper {
					border: none;
					height: 34px;
					&.collapsed {
						&:hover {
							background-color: $color-white;
							border: 1px $v2-branding-purple solid;
							border-radius: 4px;
							.nested-dropdown-input {
								color: $v2-text-gray;
							}
							.arrow {
								background: url('images/v2/components/dropdown/arrow-down.svg') no-repeat center;
							}
						}
					}
					input,
					.arrow-icon-wrapper,
					.arrow {
						height: 34px;
						min-height: 34px;
					}
					input {
						text-transform: none;
						border: none;
						letter-spacing: 0.5px;
						font-weight: 700;
						font-size: 10px;
						background-color: transparent;
						padding-top: 0;
						&.expanded {
							background: rgba(0, 0, 0, 0);
							background-color: $color-white;
							color: $v2-text-gray;
							padding-bottom: 0;
							font-size: 10px;
							font-weight: 400;
						}
					}
					::-webkit-input-placeholder {
						/* Chrome/Opera/Safari */
						font-size: 10px;
					}
					::-moz-placeholder {
						/* Firefox 19+ */
						font-size: 10px;
					}
					:-ms-input-placeholder {
						/* IE 10+ */
						font-size: 10px;
					}
					:-moz-placeholder {
						/* Firefox 18- */
						font-size: 10px;
					}
				}
				.nested-dropdown-options-wrapper {
					width: 266px;
					border-radius: 4px;
					.custom-scrollbar-div > div:first-of-type {
						width: 273px;
					}
				}
			}
		}
	}
}
