.add-cost-period-container {
	display: flex;
	padding: 4.5px 0;
	align-items: center;

	.calendar-wrapper {
		top: 46px;
	}

	.add-start-date {
		border-radius: 4px;
		text-transform: none;

		.date-picker-button {
			&.text-version {
				border: 1px solid $app-border-color;
			}
		}
	}

	.add-internal-cost {
		input {
			border: 1px solid $app-border-color;
		}
	}

	.add-button {
		button {
			width: 30px;
			height: 30px;
			color: #fff;
			border: 0;
			background-color: $v2-create-button-green;
			font-size: 18px;
			margin-left: 10px;

			&.disabled {
				background-color: $v2-grey;
			}
		}
	}
}
