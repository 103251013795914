.owner-menu-person {
	display: flex;
	padding: 4px 6px;
	width: 100%;
	cursor: pointer;

	&:hover {
		background-color: $v2-workflow-task-selected-color;
		.name-role {
			color: $v2-branding-purple;
		}
	}

	.owner-picture {
		width: 21px;
	}

	.name-role {
		color: $v2-text-gray;
		padding-left: 7px;

		width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;

		.name,
		.role {
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.name {
			font-size: 12px;
		}

		.role {
			font-size: 10px;
		}
	}
}
