.csv-wrapper {
	height: 30px;
	width: 30px;

	.csv-button {
		width: 30px;
		height: 30px;
		border: 1px solid $v2-button-very-light-grey;
		font-family: $text-font-family;
		&:hover {
			border: 1px solid $v2-date-picker-button-border-color;
		}
		background: url('images/action-icons/download.svg');
		//background-size: 24px;
		background-position: center;
		background-repeat: no-repeat;
		&:disabled {
			opacity: 0.5;
			&:hover {
				border: 1px solid $v2-button-very-light-grey;
			}
		}
	}
}
