.header-bar {
	display: flex;
	flex-direction: row;
	min-height: 30px;
	padding: 16px 32px 16px 32px;

	&.tln-padding {
		padding: 16px 24px 24px 24px;
	}
	&.scheduling {
		width: 100%;
	}

	&.no-padding {
		padding-left: 0;
		padding-right: 0;
	}

	//background: $color-white;
	&.exclude-left-padding {
		padding-left: 0;
	}
	&.exclude-bottom-padding {
		padding-bottom: 0;
	}
	button {
		outline: none;
	}
	.csv-button,
	.nested-dropdown-v2,
	.drop-down-v2,
	.search-V2,
	.toggle-view-type-button,
	.outline {
		background-color: $color-white;
	}

	.header-bar-left {
		min-height: 30px;
		height: 100%;
		justify-content: flex-start;
		flex-grow: 1;
		flex-shrink: 0;
		display: flex;
		flex-direction: row;
		align-items: center;
		> * {
			margin-right: 8px;
		}
	}
	.header-bar-center {
		min-height: 30px;
		height: 100%;
		justify-content: center;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.header-bar-right {
		min-height: 30px;
		height: 100%;
		justify-content: flex-end;
		flex-grow: 1;
		display: flex;
		flex-direction: row;
		align-items: center;
		> * {
			margin-left: 8px;
		}
	}
	.header-bar-left,
	.header-bar-right {
		align-items: center;
	}

	&.month {
		margin-bottom: 4px;
	}
	.warning {
		padding-top: 0;
		.new-ui-actions-menu {
			&.expanded {
				.actions {
					border-top-right-radius: 0px;
					border-bottom-right-radius: 0px;
					border-right: 1px solid transparent;
				}
			}
			.actions {
				border: 1px solid #e6e6e6;
				border-radius: 0;
				background: url('images/sections/scheduling-canvas/warning-icon-red.svg') 10px center no-repeat;
				width: auto !important;
				height: 28px;
				position: relative;
				background-color: #fff;
				padding: 7px;
				padding-left: 34px;
				padding-right: 10px;
				color: $negative-value;
				text-transform: uppercase;
				font-size: 10px;
				font-weight: 500;
			}
		}
	}
	.beta-tooltip-v2 {
		top: -1px;
	}
	.feedback-button {
		padding: 0 16px;
		.symbol {
			margin: 4px 8px 0 0;
		}
	}
	.search-V2 {
		height: 100%;
		min-height: 30px;
		min-width: 30px;
		//margin-left: 8px;
		margin-right: 0;
	}
	.nested-dropdown-v2 {
		border: solid 1px $app-border-color;
		border-radius: 4px;
		//width: 200px;
		&.collapsed {
			&:hover {
				border-color: $v2-button-very-light-grey-hovered;
			}
		}
		.input-arrow-wrapper {
			width: 200px;
			height: 30px;
			&.expanded {
				border-bottom: 1px $color-white solid;
			}
			::-webkit-input-placeholder {
				/* Chrome/Opera/Safari */
				font-size: 12px;
			}
			::-moz-placeholder {
				/* Firefox 19+ */
				font-size: 12px;
			}
			:-ms-input-placeholder {
				/* IE 10+ */
				font-size: 12px;
			}
			:-moz-placeholder {
				/* Firefox 18- */
				font-size: 12px;
			}
			input {
				//nested dropdown input styling to match regular dropdown
				height: 30px;
				padding: 8px 30px 8px 8px;
				font-family: $v2-site-header-font-family;
				font-size: 12px;
				color: $v2-text-gray;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				&.expanded {
					padding: 8px 30px 8px 8px;
				}
			}
		}
		.nested-dropdown-options-wrapper {
			width: 200px;
			.nested-option,
			.outer-option {
				width: 200px;
			}
		}
	}
	.drop-down-v2,
	.nested-dropdown-v2 {
		width: auto;
		height: 30px;
		&.day-week-month-dropdown {
			input {
				// To fit the design
				//width: 100px;
			}
			.custom-scrollbar-div > div {
				overflow-y: hidden !important;
				li {
					font-size: 12px;
					min-height: 15px;
					font-weight: normal;
					line-height: normal;
				}
			}
		}
		.search-V2 {
			height: 100%;
			min-width: 30px;
			min-height: 30px;
			margin-left: 8px;
			margin-right: 0;
		}

		input {
			height: 30px;
			margin: 0;
		}
		.arrow {
			height: 30px;
			max-height: 30px;
			z-index: 9;
			top: 0px;
		}
		label {
			margin: 0;
			height: 0;
		}
	}

	.date-change-section {
		display: flex;
		flex-direction: row;
		.date-picker-button.text-version {
			display: flex;
			flex-direction: row;
			padding: 16px;
			margin-left: 8px;
			height: 30px;
			border: 1px solid $v2-button-very-light-grey;
			border-radius: 0;
			background-position: left 16px top 6px;
			text-align: center;
			align-items: center;
			&:hover {
				border: 1px solid $v2-date-picker-button-border-color;
			}
			.week-number-text,
			.day-month {
				white-space: nowrap;
				margin: auto;
			}
		}
		.custom-button-container {
			height: 30px;
			padding: 0 18px;
			border: solid 1px $v2-button-very-light-grey;
			&:hover {
				border: solid 1px $v2-date-picker-button-border-color;
			}
		}
	}
	input {
		height: 30px;
	}

	.day-changer {
		height: 30px;
		display: flex;
		flex: 0 0 auto;
		flex-direction: row;
		align-items: center;
		.change-date-button {
			display: flex;
			justify-content: center;
			height: 30px;
			width: 22px;
			background-size: 22px;
			border: none;
			background-repeat: no-repeat;
			background-position: center;
			padding: 0;
			border: 1px solid $app-border-color;
			&:hover {
				border: 1px solid $v2-button-very-light-grey-hovered;
			}
			&.previous-date {
				margin-left: 0;
				&:active,
				&:focus {
					border-color: $v2-button-very-light-grey-hovered;
				}
			}
			&.next-date {
				&:active,
				&:focus {
					border-color: $v2-button-very-light-grey-hovered;
				}
			}
		}
		button {
			&:focus {
				outline: none;
			}
		}

		.custom-button-container {
			min-width: 56px;
			height: 30px;
		}
		&.day {
			.date-range-picker-wrapper-new,
			.fixed-range-picker-wrapper-new {
				&.compact {
					.buttons-wrapper {
						.date-picker-button-container {
							min-width: 225px;
						}
					}
				}
			}
		}
		&.week {
			.date-range-picker-wrapper-new,
			.fixed-range-picker-wrapper-new {
				&.compact {
					.buttons-wrapper {
						.date-picker-button-container {
							min-width: 170px;
						}
					}
				}
			}
		}
		&.month {
			.date-range-picker-wrapper-new,
			.fixed-range-picker-wrapper-new {
				&.compact {
					.buttons-wrapper {
						.date-picker-button-container {
							min-width: 135px;
						}
					}
				}
			}
		}
		&.week,
		&.day,
		&.month {
			.change-date-button {
				background-size: 100%;
				background-position: center;
			}
			.date-range-picker-wrapper-new,
			.fixed-range-picker-wrapper-new {
				&.compact {
					.date-picker-button {
						padding-top: 3px;
						padding-bottom: 3px;
						margin-right: 8px;
					}
					.date-picker-button-main {
						height: 100%;
						span {
							color: $v2-text-gray;
						}
					}
				}
			}
			.fixed-range-picker-wrapper-new {
				.calendar-day {
					&.in-range {
						&:hover {
							color: $v2-text-gray;
							background-color: $v2-date-picker-day-in-range-gray;
						}
					}
					&.outside {
						&.in-range {
							color: $v2-text-gray;
							background-color: $v2-date-picker-day-in-range-gray-alpha;
							&:hover {
								background-color: $v2-date-picker-day-in-range-gray-alpha;
							}
						}
						&:hover {
							background-color: $v2-create-button-green-alpha;
						}
					}
				}
			}
		}
	}
	.date-container {
		justify-content: center;
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 30px;
		.date-text {
			line-height: 14px;
			letter-spacing: 1px;
			margin: 0;
			text-align: center;
		}
		.weekday,
		.week-number {
			font-size: 10px;
		}
		.month-year {
			font-size: 12px;
		}
	}
	.actions-placeholder {
		width: 120px;
		height: 55px;
	}
	.tab-container {
		margin-left: 8px;
		flex-grow: 0;
		display: flex;
		flex-direction: row;
		button {
			width: 74px;
			height: 30px;
			&:focus {
				outline: none;
			}
		}

		.tab {
			background-color: $color-white;
			padding: 8px;
			border: 1px solid $app-border-color;
			width: 100px;
			text-align: center;
			cursor: pointer;
			//transition: background-color 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
			outline: 0;
			&:hover,
			&:focus {
				background-color: $btn-white-active;
			}
			&.active {
				color: $color-white;
				background-color: $brand-color;
				cursor: default;
			}
			&.tab-left {
				border-top-left-radius: 5px;
				border-bottom-left-radius: 5px;
			}
			&.tab-center {
				border-left: none;
				border-right: none;
			}
			&.tab-right {
				border-top-right-radius: 5px;
				border-bottom-right-radius: 5px;
			}
		}
	}

	.custom-button-container {
		min-width: 108px;
		height: 30px;
	}
	.today-button {
		min-width: 0;
		padding: 0 16px;
		&:hover {
			background-color: $color-white;
		}
		&:active,
		&:focus {
			border-color: #a1a1a1;
			&:hover {
				border-color: #a1a1a1;
			}
		}
		.input-container {
			.text {
				font-size: 10px;
				letter-spacing: 0.5px;
			}
		}
	}

	.filter-section-v2 {
		margin-left: 8px;

		.filter-open-section {
			.top-border-container {
				//width: 304px;
			}
		}
	}

	.collapse-button {
		width: 30px;
		height: 30px;
		border: solid 1px $v2-button-very-light-grey;
		background-size: 12px;
		background-position: center;
		background-repeat: no-repeat;
		cursor: pointer;
		&.expanded {
			background-image: url('images/components/scheduling/collapse-all.svg');
		}
		&.collapsed {
			background-image: url('images/components/scheduling/expand-all.svg');

			background-position-y: calc(1px + 50%);
		}
		&:hover {
			border-color: $v2-button-very-light-grey-hovered;
		}
	}

	.burger-menu {
		width: 40px;
		height: 30px;
		position: relative;

		button {
			width: 100%;
			height: 100%;
			border: solid 1px $v2-button-very-light-grey;
			background-size: 12px;
			background: url('images/components/overlay/navigation-show-more-horizontal.svg');
			background-position: center;
			background-repeat: no-repeat;
			&:hover {
				border-color: $v2-button-very-light-grey-hovered;
			}
		}
		.burger-menu-options {
			width: 249px;
			position: absolute;
			top: 35px;
			right: -1px;
			margin: 0;
			padding: 13px 16px 13px 16px;
			z-index: 3;
			background-color: #fff;
			list-style: none;
			border: 1px solid $v2-button-very-light-grey;
			border-radius: 4px;
			box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
			font-size: 12px;
			font-weight: 500;
			font-stretch: normal;
			letter-spacing: normal;
			color: #535353;
			.burger-menu-option {
				display: flex;
				flex-direction: row;
				.burger-menu-option-name {
					margin-bottom: 14px;
					flex: 1;
				}
				&:last-child .burger-menu-option-name {
					margin-bottom: 0;
				}
				.switch-container {
					justify-content: flex-end;
				}
			}
		}
	}
	.search-V2 {
		margin-right: 0;
		height: 30px;
		max-height: 30px;
		min-width: 30px;
		border: 1px solid $color-martin-timeline-border;
		cursor: pointer;
		outline: 0;
		padding: 0 30px 0 0;
		::-webkit-input-placeholder {
			/* Chrome/Opera/Safari */
			font-size: 12px;
		}
		::-moz-placeholder {
			/* Firefox 19+ */
			font-size: 12px;
		}
		:-ms-input-placeholder {
			/* IE 10+ */
			font-size: 12px;
		}
		:-moz-placeholder {
			/* Firefox 18- */
			font-size: 12px;
		}
		&:hover {
			border: 1px solid $v2-date-picker-button-border-color;
		}
		&.expanded {
			box-shadow: none;
			border: 1px solid $v2-branding-purple;
			&:hover {
				border: 1px solid $v2-branding-purple;
			}
		}
		input {
			height: 30px;
			border: none;
			outline: 0;
			padding: 8px 8px 8px 8px;
			font-size: 12px;
		}
	}
	.toggle-view-type-button {
		outline: none;
		width: 30px;
		height: 30px;
		border: solid 1px $v2-button-very-light-grey;
		&.grid {
			background-image: url('images/components/overlay/grid-view-icon.svg');
			background-size: 12px;
			background-position: center;
			background-repeat: no-repeat;
		}
		&.list {
			background-image: url('images/components/overlay/list-view-icon.svg');
			background-size: 12px;
			background-position: center;
			background-repeat: no-repeat;
		}

		&:hover {
			border-color: $v2-button-very-light-grey-hovered;
		}
	}

	.header-bar-back-button {
		@include back-button;
	}

	.header-bar-info-text {
		color: $v2-text-gray;
	}

	.expand-control {
		transition: background-color ease 0.2s;
	}
	.auto-scheduling-button {
		height: 30px;
		padding: 9px 18px 9px 20px;
		margin-left: 8px;
		.text {
			font-family: Urbane;
			font-size: 10px;
			font-weight: bold;
			letter-spacing: 0.45px;
			text-align: center;
			color: $color_white;
		}
	}
}

.economic-button {
	@include primary-button;
	margin-right: 4px;
}
