/**
 * Login.
 */
.login {
	text-align: center;
	font-size: 0.875rem;
	&.login-bg {
		background-image: url('images/sections/login/loginbg.png');
		background-size: 100% 100%;
	}
	&.new-login-bg {
		background-color: #c5e0eb;
		background-size: 100% 100%;
	}
	&.first-login-bg {
		background-image: url('images/sections/login/firstloginbg.png');
		background-size: 100% 100%;
	}
	.login {
		font-size: 30px;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.33;
		letter-spacing: -0.8px;
		text-align: center;
		color: #a1a1a1;
	}
	.e-mail,
	.password {
		font-size: 14px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.29;
		letter-spacing: normal;
		color: #a1a1a1;
	}
	.logo {
		width: 85px;
		height: 85px;
		background-image: url('images/logo.svg');
		background-size: 85px 85px;
		position: absolute;
		top: 25px;
		left: 50px;
	}
	.fullscreen-centered-content {
		padding: 30px;
		max-width: 460px;
	}

	h1 {
		margin: 0px 0px 2rem 0px;
	}

	@media screen and (max-width: 400px) {
		.fullscreen-centered-content {
			padding: 15px;
		}
	}

	input {
		margin-bottom: 20px;
	}

	.remember-me {
		p {
			width: 90%;
			float: left;
			margin: 0 0 0 2px;
		}
		input {
			float: left;
			height: 16px;
			width: 16px;
		}
	}

	form {
		padding-bottom: 100px;
		width: 400px;
		.fieldset {
			padding-left: 10px;
			padding-right: 10px;
			width: 400px;
			position: relative;
			input {
				text-align: center;
				border-radius: 0;
				background-color: #fffde0;
			}
			.error {
				font-size: 18px;
				font-weight: bold;
				a {
					font-weight: bold;
					color: #a82d2d;
					text-decoration: underline;
				}
			}
		}
		.buttons {
			padding: 10px;
			input {
				@include primary-button;
				width: 100%;
				font-size: 24px;
				height: 60px;
			}
		}
	}
	a {
		font-weight: $base-font-medium-weight;
		text-decoration: none;
		color: $color-white;
		&:hover {
			color: $color-purple-hover-active;
		}
	}
	.v1-login-link {
		display: flex;
		justify-content: center;
		position: absolute;
		bottom: 20px;
		width: 100%;
		a {
			font-size: 1.5rem;
			color: #48b8ff;
			&:hover {
				color: $color-purple-hover-active;
			}
		}
	}
	@include password-bubble;

	.external-login {
		padding-top: 1rem;
		display: flex;
		flex-direction: column;
		margin-left: auto;
		margin-right: auto;

		.okta-login-button {
			@include secondary-button;

			&:hover,
			&:focus {
				box-shadow: none;
				background: rgba(224, 224, 224, 0.5);
			}
			&:active {
				transform: none;
				background: rgba(117, 117, 117, 0.5);
			}
		}

		.onelogin-login-button {
			@include secondary-button;

			img {
				transform: translateY(4px);
			}

			&:hover,
			&:focus {
				box-shadow: none;
				background: rgba(224, 224, 224, 0.5);
			}
			&:active {
				transform: none;
				background: rgba(117, 117, 117, 0.5);
			}
		}
		.google-button {
			@include secondary-button;
			position: relative;

			&:hover,
			&:focus {
				box-shadow: none;
				background: rgba(224, 224, 224, 0.5);
			}

			&:active {
				transform: none;
				background: rgba(117, 117, 117, 0.5);
			}

			span {
				padding-right: 54px;
			}

			img {
				position: absolute;
				height: 20px;
				padding-left: 5px;
				right: 121px;
			}
		}
	}
	.login-topnav {
		position: absolute;
		width: 100%;
		height: 48px;
		ul {
			padding: 0;
			display: flex;
			list-style-type: none;
			li {
				padding-right: 30px;
				font-size: 13px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: normal;
				letter-spacing: 0.5px;
				color: #ffffff;
			}
			li:first-child {
				width: 70px;
				padding: 0;
			}
			li:nth-child(2) {
				margin-left: 150px;
			}
		}
	}
	.join-modal {
		padding-bottom: 20px;
		button {
			padding: 10px 20px;
			min-width: 80px;
			margin: 0 5px;
		}
	}
	.modal {
		width: 570px;
		//height: 363px;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin: 0 auto;
		.modal-title {
			font-size: 30px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.33;
			letter-spacing: -0.8px;
			text-align: center;
			color: #a1a1a1;
		}
		.form-wrapper {
			flex-grow: 1;
			form {
				margin: 0 auto;
				padding-bottom: 50px;
				input {
					background-color: #fff;
				}
				.form-row-buttons-new {
					display: flex;
					justify-content: space-between;
					height: 48px;
					a {
						font-size: 14px;
						font-weight: bold;
						font-style: normal;
						font-stretch: normal;
						line-height: 48px;
						letter-spacing: 0.5px;
						color: #6e0fea;
						&:hover {
							color: #38285d;
						}
					}
					button {
						display: flex;
						margin-left: auto;
						min-width: 50%;
						border: none;
						padding: 0;
						span {
							height: 48px;
							display: flex;
							flex-direction: column;
							justify-content: center;
						}
						span:first-child {
							flex: 1;
							background-color: #05e298;
							font-weight: bold;
							color: #fff;
						}
						span:last-child {
							background-color: #0ab47b;
							width: 48px;
							height: 48px;
							text-align: initial;
							position: relative;
							display: flex;
							flex-direction: column;
							justify-content: center;
							img {
								position: absolute;
								left: calc(50% - 7px);
							}
						}
						&:hover {
							color: #fff;
							img {
								-webkit-animation: arrow-move 0.2s ease-out;
								animation: arrow-move 0.2s ease-out;
							}
						}
					}
				}
				/*
				.form-row-buttons {
					display: flex;
					justify-content: space-between;
					height: 48px;
					a:first-child {
						font-size: 14px;
						font-weight: bold;
						font-style: normal;
						font-stretch: normal;
						line-height: 48px;
						letter-spacing: 0.5px;
					}
					.login-forgot-password {
						color: #6e0fea;
						&:hover {
							color: #38285d;
						}
					}
					a:last-child {
						line-height: 48px;
						display: flex;
						margin-left: auto;
						min-width: 50%;
						cursor: pointer;
						&:hover {
							color: #fff;
							img {
								-webkit-animation: arrow-move 0.2s ease-out;
								animation: arrow-move 0.2s ease-out;
							}
						}
						div:first-child {
							flex: 1;
							background-color: #05e298;
							font-weight: bold;
						}
						div:last-child {
							background-color: #0ab47b;
							width: 48px;
							text-align: initial;
							position: relative;
							display: flex;
							flex-direction: column;
							justify-content: center;
							img {
								position: absolute;
								left: calc(50% - 7px);
							}
						}
					}
				}
				*/
				.signup {
					justify-content: center;
					a {
						margin: 0 auto;
					}
				}
			}
			.signup-options {
				height: 70px;
				display: flex;
				justify-content: space-between;

				button:first-child {
					font-size: 20px;
					font-weight: bold;
					font-style: normal;
					font-stretch: normal;
					line-height: 1.1;
					letter-spacing: 1px;
					text-align: center;
					color: #a1a1a1;
					outline: none;
					background-color: #f6f4f8;
				}
				.signup-option-active {
					background-color: #fff !important;
				}
				button {
					width: 25%;
					border: none;
					outline: none;

					.xero-label {
						display: flex;
						flex-direction: column;
						align-items: center;
						font-weight: $base-font-medium-weight;
						font-family: $text-font-family;
					}

					.new-onelogin-logo {
						height: 34px;
						width: 34px;
					}
					.okta-logo {
						height: 20px;
						width: 61px;
					}

					.microsoft-entra-id-logo {
						width: 34px;
						height: 34px;
					}

					button {
						background-color: transparent !important;
						width: auto !important;
					}
					.new-google-button {
						height: 28px;
						background: none;
						width: auto;
					}
					img {
						margin: auto;
					}
				}
				.signup-option {
					background-color: #f6f4f8;
					&:hover {
						background-color: #fff !important;
					}

					&:focus {
						background-color: #fff !important;
					}
				}
			}
			.signup-wrapper {
				background-color: #6e0fea;
				height: 40px;
				line-height: 40px;
				span {
					font-size: 14px;
					font-weight: normal;
					font-style: normal;
					font-stretch: normal;
					line-height: 1.57;
					letter-spacing: 0.5px;
					text-align: center;
					color: #ffffff;
					a {
						font-weight: bold;
					}
					:last-child {
						font-weight: bold;
					}
					:hover {
						color: #38285d;
					}
				}
			}
		}
	}
}
/* Safari 4.0 - 8.0 */
@-webkit-keyframes arrow-move {
	0% {
		left: calc(50% - 7px);
	}
	50% {
		left: 100%;
	}
	51% {
		left: calc(0% - 7px);
	}
	100% {
		left: calc(50% - 7px);
	}
}

/* Standard syntax */
@keyframes arrow-move {
	0% {
		left: calc(50% - 7px);
	}
	50% {
		left: 100%;
	}
	51% {
		left: calc(0% - 7px);
	}
	100% {
		left: calc(50% - 7px);
	}
}
