.select-wrapper,
.select-wrapper-locked {
	display: flex;
	flex-direction: column;
	position: relative;
	outline: 0;
	::-webkit-input-placeholder {
		/* WebKit, Blink, Edge, Chrome */
		opacity: 0.7;
	}
	:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		opacity: 0.7;
	}
	::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		opacity: 0.7;
	}
	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		opacity: 0.7;
	}
	:-ms-input-placeholder {
		/* Microsoft Edge */
		opacity: 0.7;
	}
	.dropdown-empty-text {
		font-size: 14px;
		font-style: italic;
		padding: 4px 8px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		color: $v2-text-light-gray;
	}
	.input-container {
		position: relative;
		padding: 0;
		margin: 0;
		overflow: hidden;
		max-width: 240px;
		text-overflow: ellipsis;
	}
	.input-icon-collapsed,
	.input-icon-expanded {
		top: 8px;
		right: 5px;
		position: absolute;
		height: 16px;
		width: 16px;
		padding-right: 10px;
		background-size: 21px;
		background-repeat: no-repeat;
		cursor: pointer;
	}
	.input-icon-collapsed {
		background-image: url('images/components/select-input/collapsed.svg');
	}
	.input-icon-expanded {
		background-image: url('images/components/select-input/expanded.svg');
	}
	.input-clear-icon {
		top: 16px;
		right: 25px;
		position: absolute;
		height: 8px;
		width: 8px;
		padding-right: 10px;
		background-size: 8px;
		background-repeat: no-repeat;
		background-image: url('images/cross-section-icons/actions/remove-3.svg');
		cursor: pointer;
	}
	.label,
	.label-focused {
		padding-top: 16px;
		font-size: 0.75rem;
	}
	.label {
		color: $material-label-color;
	}
	.label-focused {
		color: #6f49c5;
	}
	.input,
	.input-focused,
	.input-table-cell {
		border: 0;
		outline: 0;
		padding: 8px 0;
		text-overflow: ellipsis;
		position: relative;
		display: inline-block;
		width: 100%;
		padding-right: 15px;
	}
	.input,
	.input-focused {
		transition: background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
		background: transparent no-repeat;
		background-image: linear-gradient(to bottom, $material-focused-color, $material-focused-color), linear-gradient(to bottom, silver, silver);
		background-size: 0 2px, 100% 1px;
		background-position: 50% 100%, 50% 100%;
	}
	.input-focused {
		background-size: 100% 2px, 100% 1px;
		outline: none;
	}
	.select {
		top: 68px;
		&.label-hidden {
			top: 38px;
		}
	}
	.select,
	.select-table-cell {
		max-width: 240px;
		border: 1px solid $app-border-color;
		position: absolute;
		z-index: 99;
		left: 0;
		overflow: auto;
		width: 100%;
		border-radius: 2px;
		background-color: $color-white;
		padding: 16px 0;
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		max-height: 400px;
		overflow: hidden;
		//font-size: 15px;
		box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
		transition: transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
		transform: scaleY(1);
		transform-origin: left top 0px;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		.option:hover,
		.option-multi:hover,
		.option-selected:hover,
		.option-selected-multi:hover {
			cursor: pointer;
			//background-color: #E8E8E8;
		}
		.option,
		.option-selected,
		.option-multi {
			background-color: $color-white;
			overflow: hidden;
			// width: 90%;
		}
		.option,
		.option-selected {
			display: flex;
			flex-direction: row;
			padding: 8px 5px 8px 24px;
		}
		.option-selected {
			color: $brand-color-accent; //$material-focused-color;
		}
		.option-multi,
		.option-selected-multi {
			display: flex;
			flex-direction: row;
			padding: 8px 28px 8px 45px;
			word-break: break-word;
			white-space: no-wrap;
		}
		.option-selected-multi {
			color: $brand-color-accent; //$material-focused-color;
			background: url('images/components/select-input/selected.svg') no-repeat scroll 10px 2px;
		}
		.marked {
			//background-color: #E8E8E8;
			color: $color-purple-hover-active;
		}
		.disabled {
			color: lighten($color-dark-gray, 30);
		}
		.custom-scrollbar-div {
			> div:first-child {
				//width 110% needed to hide default scroll bar in mozilla firefox
				width: 110%;
				@include scroll-y;
			}
			> div > div:last-child {
				padding-bottom: 16px;
			}
		}
		&.initial-render {
			opacity: 0;
		}
	}
	.select {
		top: 68px;
		white-space: pre-wrap;
	}
	.select-table-cell {
		top: 35px;
		color: $color-black;
	}
	.multi-values {
		margin-top: 8px;
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
	}
	.status-color {
		border-radius: 50%;
		width: 19px;
		height: 19px;
		margin-right: 5px;
	}
	.project-color {
		display: block;
		width: 5px;
		height: 16px;
		margin-right: 8px;
	}
	.profile-picture {
		display: block;
		width: 36px;
		height: 36px;
		margin-right: 8px;
		margin-top: -8px;
	}
}

.select-wrapper:hover {
	.input,
	.input-table-cell {
		cursor: pointer;
	}
	.input {
		background-image: linear-gradient(to bottom, $material-focused-color, $material-focused-color), linear-gradient(to bottom, black, black);
	}
}

.select-wrapper-locked {
	.label,
	.label-right,
	.label-focused,
	.label-right-focused {
		padding-top: 16px;
		font-size: 0.75rem; //font-size: 12px;
	}
	opacity: 0.5;
	.input,
	.input-right {
		padding: 8px 0 8px 0;
		border-bottom: 1px dotted $material-locked-color;
	}
}
