.settings-app-catalog {
	&.section-content {
		margin: 0;
		.section-body {
			margin: 16px 24px;
		}
	}
	.new-integration,
	.apps-description {
		padding-left: 20px;
		margin-bottom: 30px;
	}
	position: relative;
	.unavailable-label {
		position: absolute;
		top: 100px;
		left: 0px;
		z-index: 2;
		color: white;
		width: 100%;
		text-align: center;
		background: rgba(0, 0, 0, 0.7);
		padding: 2rem;
		a {
			color: $color-white;
		}
	}
	.integrations {
		display: flex;
		list-style-type: none;
		padding: 0px;
		margin: 0;
		flex-wrap: wrap;
		a {
			text-decoration: none;
		}
		.integration {
			position: relative;
			display: flex;
			flex-direction: column;
			width: 200px;
			align-items: center;
			padding: 10px;
			margin: 10px;

			.title {
				text-align: center;
			}

			.description {
				width: 100%;
			}
			&.available {
				cursor: pointer;
				&:hover {
					cursor: pointer;
					-webkit-transform: scale(1.008);
					-ms-transform: scale(1.008);
					transform: scale(1.008);
					transition: all 0.2s ease;
					color: #6f49c5;
				}
			}

			.integration-description {
				text-align: center;
			}

			.under-construction {
				position: absolute;
				left: 0px;
				top: 50px;
				color: white;
				width: 100%;
				text-align: center;
				transform: rotate(-20deg);
				background: rgba(0, 0, 0, 0.7);
				padding: 0.5rem;
			}
			.beta {
				text-align: center;
			}
		}
	}
}
