.add-rate-card-modal {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	z-index: 99;
	background: rgba(0, 0, 0, 0.75);
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	.inner-container {
		background-color: white;
		border-radius: 5px;
		padding: 2em;
		width: 24em;
		.header-container {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 20px;
			.header-text {
				margin: 0;
			}
			.close-button {
				width: 25px;
				height: 25px;
				background-size: 25px;
				background-image: url('images/components/overlay/close.svg');
				cursor: pointer;
			}
		}
		.body-container {
			.select-wrapper {
				.input-container {
					max-width: 22em;
				}
			}
			.button-container {
				display: flex;
				flex-direction: row;
				margin-top: 20px;
				.button-cancel {
					@include secondary-button;
					margin-right: 5px;
					flex: 1 0 0px;
				}
				.button-send {
					@include primary-button;
					margin-left: 5px;
					flex: 1 0 0px;
				}
			}
		}
	}
}
