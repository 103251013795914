.person-selector-container {
	position: relative;
	&:focus {
		outline: 0;
	}
	.input-container {
		padding-bottom: 4px;
		display: flex;
		flex-direction: row;
		width: 100%;
		transition: background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
		background: transparent no-repeat;
		background-image: linear-gradient(to bottom, $material-focused-color, $material-focused-color), linear-gradient(to bottom, silver, silver);
		background-size: 0 2px, 100% 1px;
		background-position: 50% 100%, 50% 100%;
		input {
			border: none;
			flex-grow: 1;
			cursor: pointer;
			&:focus {
				outline: 0;
				cursor: auto;
			}
		}
		.arrow {
			flex-shrink: 0;
			height: 21px;
			width: 21px;
			background-size: 21px;
			background-repeat: no-repeat;
			cursor: pointer;
			&.collapsed {
				background-image: url('images/components/select-input/collapsed.svg');
			}
			&.expanded {
				background-image: url('images/components/select-input/expanded.svg');
			}
		}
		&:hover {
			background-image: linear-gradient(to bottom, $material-focused-color, $material-focused-color), linear-gradient(to bottom, black, black);
		}
		&.focused {
			background-size: 100% 2px, 100% 1px;
		}
	}
	.dropdown {
		position: absolute;

		width: 100%;
		box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
		background-color: white;
		z-index: 999;
		max-height: 250px;
		overflow: hidden;
		&.collapsed {
			display: none;
		}
		&.showOnTop {
			bottom: 32px;
		}
		&.showOnBottom {
			top: 32px;
		}
		.dropdown-inner {
			padding: 8px 0 24px 0;
			flex: 1 1 auto;
			display: flex;
			flex-direction: column;

			.person {
				padding: 4px 24px 4px 12px;
				cursor: pointer;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				.image-container {
					flex: 0 0 36px;
				}
				.text-container {
					flex: 1 1 auto;
					display: flex;
					height: 36px;
					flex-direction: column;
					flex-wrap: nowrap;
					margin-left: 8px;
					overflow: hidden;
					justify-content: center;
					.name {
						height: auto;
						font-size: 14px;
						font-weight: bold;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
					.email {
						margin-top: 5px;
						font-size: 11px;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
				}
				&.marked {
					color: $color-purple-hover-active;
				}
				&:hover {
					color: $color-purple-hover-active;
				}
			}
			.button-container {
				padding: 8px 33px 8px 16px;
				.invite-button {
					@include primary-button;
				}
			}
		}
		.custom-scrollbar-div {
			> div:first-of-type {
				@include scroll-y;
				width: calc(100% + 17px);
			}
		}
	}
}
