.bulk-task-modal-content {
	display: flex;
	flex-direction: column;
	overflow: auto;
	.drop-down-v2 {
		&.disabled:hover input {
			border-color: #e6e6e6;
		}

		.labels-icon {
			width: 16px;
			height: 16px;
			background: url('images/v2/task-modal/label.svg');
			background-size: 16px;
			background-position: center;
			background-repeat: no-repeat;
			position: absolute;
			left: 10px;
			top: 8px;
		}

		input {
			&.has-icon {
				padding-left: 32px;
			}

			&.expanded {
				&.on-top {
					border-radius: 4px;
					border: solid 1px #e6e6e6;
					border-bottom: solid 1px #e6e6e6 !important;
				}
			}
		}
	}

	.date-picker-wrapper {
		position: inherit;
	}
	.sub-section {
		margin-bottom: 15px;
		margin-right: 10px;
		border-bottom: 1px solid $app-border-color;
		padding-bottom: 16px;
		width: 380px;
		.inputs-wrapper {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			.wrapper,
			.wrapper-error,
			.date-picker-wrapper {
				display: flex;
				flex-direction: column;
				margin-right: 10px;
				width: 130px;
				height: 30px;
			}
		}
		.input-container,
		.select {
			width: 270px;
			max-width: none;
		}
		.date-picker-button-container-v3 {
			&:first-of-type {
				margin-right: 0;
			}
			.date-picker-button {
				height: 30px;
				padding: 0;
				width: 118px;
			}
		}
		.date-picker-label,
		.sub-section-title {
			height: 11px;
			font-family: $v2-site-header-font-family;
			font-size: 9px;
			font-weight: 500;
			line-height: 1.22;
			letter-spacing: 1px;
			color: $v2-text-light-gray;
			text-transform: uppercase;
			margin-bottom: 5px;
		}
		.invalid-estimates {
			border: 1px solid $error-border-color;
		}
		.drop-down-v2 {
			width: 100%;
		}
		.assigned-dropdown-wrapper {
			.arrow {
				right: 4px;
				bottom: 0px;
			}
			.subtask-assigned-input {
				height: 30px;
				display: flex;
			}
		}
		&.last {
			border-bottom: none;
		}

		.assigned-icons {
			top: 2px;
		}
	}
}
