.notification-menu {
	display: flex;
	position: relative;
	margin-left: 16px;
	.notification-menu-button {
		height: 24px;
		width: 24px;
		background-image: url('images/header/profile/notifications.svg');

		cursor: pointer;

		&.active {
			background-image: url('images/header/profile/notifications-active.svg');
		}
	}
	.notification-number {
		position: absolute;
		left: 12px;
		top: -3px;
		font-size: 10px;
		font-weight: bold;
		letter-spacing: 0.5px;
		min-width: 15px;
		max-width: 24px;
		height: 15px;
		border-radius: 3px;
		margin-right: 10px;
		color: white;
		background-color: $v2-branding-purple;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}
}
