.settings-subscription-v2 {
	&.section-content {
		margin: 0;
		.section-body {
			margin: 16px 24px;
		}
	}
	margin-right: 0;
	hr {
		border-top: 1px solid #eeeeee;
		border-bottom: none;
		border-left: 1px solid #eeeeee;
		width: calc(100% - 48px);
		margin: 0 24px;
	}

	.custom-button-container {
		&.upgrade-now {
			font-size: 13px;
			height: 30px;
		}
	}

	.card {
		//margin-bottom: 17px;
		padding: 24px 0;
		border: solid 1px $app-border-color;
		position: relative;
		background-color: #ffffff;
	}

	.plan {
		display: flex;
		justify-content: space-between;
		.plan-type-wrapper {
			padding-left: 24px;
		}
		.your-plan {
			font-size: 12px;
			color: $v2-text-light-gray;
		}
		.contact-csm-info {
			max-width: 320px;
			font-size: 13px;
			margin-right: 20px;
		}
	}
	.seats-dash {
		margin: 0 10px;
		color: $v2-text-light-gray;
	}
	.seats {
		display: flex;
		padding-top: 10px;
		.buttons {
			margin-left: auto;
			display: flex;
			height: 25px;
			margin-top: 32px;
			button {
				//width: 115px;
				border-radius: 4px;
			}
		}
		.licensing {
			width: 100%;
			.header-wrapper {
				display: flex;
			}
			table {
				//margin-bottom: 24px;
				text-align: left;
				thead {
					border: none;
					th {
						text-transform: uppercase;
						font-size: 9px;
						color: $v2-text-light-gray;
						font-weight: normal;
						letter-spacing: 1px;
						padding: 10px 0 10px 0;
						&:first-child {
							padding-left: 24px;
						}
					}
				}
				tbody {
					border: solid $app-border-color;
					border-width: 1px;
					background-color: $color-white;
					tr {
						border-bottom: none;
						&:hover {
							color: black;
						}
						&:last-child {
							border: none;
						}
						td {
							font-size: 13px;
							color: $v2-text-gray;
							padding: 10px 0 10px 0;
							&:first-child {
								padding-left: 24px;
							}
						}
					}
				}
			}
			.seats-button {
				background-color: transparent;
			}
		}
	}
	.updating-wrapper {
		position: relative;
		width: 25px;
		height: 25px;
		margin-right: 8px;
		.uploading-overlay-container {
			position: absolute;
			top: 0;
			left: 0;
			width: 26px;
			height: 26px;
			&.loading::after {
				height: 26px;
				width: 26px;
				background-size: 25px;
				top: 55%;
			}
		}
	}
	.billing {
		.header-wrapper {
			display: flex;
			margin-bottom: 12px;
			.edit-billing-button {
				position: absolute;
				top: 16px;
				right: 16px;
			}
			.invoice-email {
				padding-left: 24px;
			}
		}
		.tile-container {
			display: flex;
			.data-tile {
				padding-top: 16px;
				//flex-grow: 1;
				width: 25%;
				&:first-child {
					padding-left: 24px;
				}
			}
		}
	}

	.billing-history-row {
		border-bottom: none;
		td {
			width: 25%;
			border-bottom: 1px solid $app-border-color;
		}
	}
	.white-space {
		min-width: 24px;
		max-width: 24px;
		width: 24px !important;
		border-bottom: none;
		padding-left: 0 !important;
	}
	.payment {
		display: flex;
		.payment-tile-wrapper {
			display: flex;
			justify-content: space-between;
			width: 100%;
			padding-top: 16px;
			.data-tile {
				flex-grow: 0.5;
				width: 25%;
				&:first-child {
					//padding-left: 24px;
				}
				&:nth-child(4) {
					//width: 50%;
					flex-grow: 1;
				}
				.expand-control {
					height: 16px;
					width: 24px;
					cursor: pointer;
					display: inline-block;
					&.expand {
						background: url('images/header/expanded.svg') no-repeat right center;
					}

					&.collapse {
						background: url('images/up-arrow.svg') no-repeat right center;
					}
					&:hover,
					&:focus {
						background-color: #f3f3f3;
					}
				}
			}
		}
		.upgrade-annual-button {
			margin-left: auto;
			button {
				width: 160px;
				height: 40px;
			}
		}
	}

	.license-table {
		th,
		td {
			width: 25%;
			border-bottom: solid 1px $app-border-color;
			&:first-child {
				padding-left: 0 !important;
				width: 24px;
				max-width: 24px;
				min-width: 24px;
				border-bottom: none;
			}
			&:nth-child(2) {
				//width: calc(25% - 24px);
			}
			&:last-child {
				width: 24px;
				max-width: 24px;
				min-width: 24px;
				border-bottom: none;
				padding: 0;
			}
		}
	}
	.invoice {
		display: flex;
		.invoice-tile-wrapper {
			display: flex;
			width: 60%;
			justify-content: space-between;
		}
		table {
			//margin-bottom: 24px;
			text-align: left;
			thead {
				border: none;
				th {
					text-transform: uppercase;
					font-size: 9px;
					color: $v2-text-light-gray;
					font-weight: normal;
					letter-spacing: 1px;
					padding: 10px 0 10px 0;
					&:first-child {
						padding-left: 24px;
					}
				}
			}
			tbody {
				border: solid $app-border-color;
				border-width: 1px;
				background-color: $color-white;
				tr {
					border-bottom: none;
					&:hover {
						color: black;
					}
					&:last-child {
						border: none;
					}
					td {
						font-size: 13px;
						color: $v2-text-gray;
						padding: 10px 0 10px 0;
						&:first-child {
							padding-left: 24px;
						}
					}
				}
			}
		}
	}

	.invoice-header-wrapper {
		display: flex;
		.expand-control {
			height: 24px;
			width: 24px;
			margin: 34px 0 0 16px;
			cursor: pointer;
			&.expand {
				background: url('images/header/expanded.svg') no-repeat right center;
			}

			&.collapse {
				background: url('images/up-arrow.svg') no-repeat right center;
			}
			&:hover,
			&:focus {
				background-color: #f3f3f3;
			}
		}
		.unpaid-wrapper {
			display: flex;
			align-items: flex-end;
			.unpaid-text {
				height: 24px;
				margin: 34px 0 8px 16px;
			}
			.unpaid-button {
				margin: 0 0 8px 16px;
				height: 40px;
			}
		}
	}
	.full-width-hr {
		margin: 0;
		width: 100%;
	}
	.red-border {
		border-color: $negative-value;
		color: $negative-value;
	}
	.edit-billing {
		height: 18px;
		width: 18px;
		padding-right: 5px;
		border-width: 0;
		background-size: 18px !important;
		background-position: center;
		background-repeat: no-repeat;
		background: url('images/cross-section-icons/editable/edit.svg');
		&:hover {
			background: url('images/cross-section-icons/editable/edit-active.svg');
		}
	}
	.seats-button {
		.text {
			white-space: nowrap;
		}
	}
	.seats-button:hover {
		background: white;
		color: $v2-branding-purple;
	}
	.data-tile {
		justify-content: left;
		color: $v2-text-gray;
		label {
			padding: 0 0 5px 0;
			display: block;
			text-transform: uppercase;
			font-size: 9px;
			color: $v2-text-light-gray;
			letter-spacing: 1px;
		}
		.tile-value {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			margin-top: 3px;
			font-size: 13px;
			color: $v2-text-gray;
		}
		&.right {
			text-align: right;
		}
	}

	.subscription-header {
		font-size: 20px;
		font-weight: 500;
		color: $v2-text-gray;
		font-family: $heading-font-family;
		margin: 32px 0 8px 0;
		&.small-top-margin {
			margin-top: 12px;
		}
	}

	.back-wrapper {
		display: flex;
		margin-left: 90px;
		padding: 2px 10px 2px 2px;
		width: fit-content;
		cursor: pointer;
		&:hover,
		&:focus {
			background-color: #f3f3f3;
		}
		.back-arrow-control {
			background: url('images/v2/components/date-controls/arrow-left.svg') no-repeat right center;
			width: 24px;
			height: 24px;
		}
		.back-text {
			color: $v2-text-light-gray;
			padding-top: 3px;
			font-size: 13px;
		}
	}

	.partial-payment-lines {
		td {
			font-size: 13px;

			&.amount {
				white-space: nowrap;
				text-align: right;
			}
		}
	}
}
