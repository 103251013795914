.project-sprints {
	&.useNewNavigation {
		margin-top: 0;
	}
	display: flex;
	flex-direction: column;
	overflow: hidden;
	.forecast-actions {
		.add-sprint-button {
			@include primary-button;
			width: auto;
		}
	}
	.filters {
		display: flex;
		flex-direction: row;
		background-color: $color-white;
		border-bottom: 1px solid $app-border-color;
		.filters-title {
			font-size: 1.2rem;
			font-weight: $base-font-medium-weight;
			padding-left: 10px;
			padding-top: 8px;
			margin: 0;
			flex-grow: 1;
		}
		.dropdown-filter {
			width: 200px;
			border-left: 1px solid $app-border-color;
			.Select-control {
				border: none;
				border-radius: 0;
			}
		}
		.search-filter {
			input {
				height: 36px;
			}
		}
	}

	.content {
		flex: 1 1 auto;
		overflow: auto;
		display: flex;
		flex-direction: row;
		&.empty {
			flex-direction: column;
		}
		.main-content {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			.new-sprint {
				@include content-frame;
				padding: 10px;
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-bottom: 15px;
				min-height: 70px;
				.title {
					margin: 0 12px 0 0;
				}
				input {
					border: 1px solid $app-border-color;
					border-radius: 3px;
					padding: 10px;
					margin: 0 12px 0 12px;
				}
				.remaining-sprints {
					margin-left: auto;
				}
				button {
					@include primary-button;
				}
			}
			.sprints-container {
				display: flex;
				flex-grow: 1;
				flex-direction: column;
			}
		}
	}
}
