/**
 * Colors.
 */
$brand-color: #392664;
$brand-color-dark: darken($brand-color, 12.5%);
$brand-color-light: lighten($brand-color, 15%); //#3a2c82;
$brand-color-disabled: #eee;
$brand-color-accent: #e500d2;
$brand-brown-light: #eee;

$error-background-color: #eabcbc;
$error-border-color: #cb5c5c;
$error-text-color: #a82d2d;
$alert-color: #cc0000;

$negative-value: #d0021b;

$light-border-color: #eee;
$light-hover-color: #fefefe;
$menu-hover-color: #fefefe;

/**
  * Colors.
  */
$color-white: #fff;
$color-dark-blue: #1d3557;
$color-green: #5ccb66;
$color-red: #e63946;
$color-light-black: #625c58;
$color-black: #000;
$color-light-gray: #fafafa;
$color-light-grey: #f2f2f2;
$color-light-blue: #4ed6f8;
$color-medium-gray: #f3f3f3;
$color-dark-gray: #7d7d7d;
$color-silver: silver;

$color-branding-purple: #2b1951;
$color-branding-purple-light: #6e0feb;
$color-purple-hover-active: #6f49c5;

$color-martin-primary-salmon: #ff7c75;
$color-martin-primary-purple: #392664;
$color-martin-primary-blue: #44b4ff;

$color-martin-secondary-purple: #742bd6;

$color-martin-tertiary-pink: #e93a66;
$color-martin-tertiary-green: #62d87b;
$color-martin-tertiary-light-purple: #a56dfd;

$color-martin-timeline-border: #ebebee;
$color-martin-timeline-background: #f7f7f7;
$color-martin-dependency-gray: #d8d8d8;

$color-brand-50: #F0E7FE;

$delete-button-red: #ff4e5a;

$favoured-task-yellow: #fefdf3;

/**
  * New UI colors
  */
$v2-tooltip-grey: #ebebeb;
$v2-receipt-green: #00cc66;
$v2-input-disabled-grey: #f3f3f3;
$disabled-grey: #dadadf;
$v2-list-hover-purple: rgba(110, 15, 234, 0.1);
$v2-list-background-purple: rgba(110, 15, 234, 0.05);
$v2-backdrop-gray: #cecece;
$v2-retainer-conflict-red: #ec4b4b;
$v2-column-header-grey: #dfdfdf;
$v2-section-main-background: #ffffff;
$v2-dark-green: #00300c;
$v2-branding-purple: #6e0fea;
$v2-dark-purple: #560eb3;
$v2-text-light-gray: #a1a1a1;
$v2-text-gray: #535353;
$v2-header-text-dark-gray: #2e2541;
$v2-background-dark-gray: #4a5968;
$v2-light-gray: #f6f6f6;
$v2-border-gray: #e9e9e9;
$v2-border-invalid: #e4253b;
$v2-create-button-green: #05e298;
$v2-create-button-green-active: #0ab47b;
$v2-create-button-green-alpha: #05e298b3;
$v2-create-menu-green-border: #00e891;
$v2-dependency-green: #07e064;
$v2-negative-dropdown-grey: #3f4b57;
$v2-date-picker-button-gray: #f6f6f6;
$v2-date-picker-button-border-color: #a1a1a1;
$v2-date-picker-day-in-range-gray: #a4bac4;
$v2-date-picker-day-in-range-gray-alpha: #a4bac4b3;
$v2-dark-grey: #3f4b57;
$v2-grey: #dbdbdb;
$v2-fixed-element-grey: #f5f5f8;
$v2-row-highlight-color: #dbdbdb;
$v2-row-highlight-border-color: #f3f3f3;
$v2-red: #e23f3f;
$v2-workflow-task-done-state-color: #19e042;
$v2-workflow-task-selected-color: #f0e7fc;
$v2-workflow-halted-task-selected-color: #c1c1c1;
$v2-workflow-task-warning-red: #e21e5b;
$v2-workflow-task-warning-yellow: #ffd200;
$v2-workflow-column-category-option-grey: #a4b9c4;
$v2-completed-green: #19e042;
$v2-files-selected-row: #f8f8f8;
$v2-files-upload-hover-message-color: #efefef;
$v2-table-border-color: #eaeaea;
$v2-button-grey: #BBBBC8;
$v2-button-light-grey: #dcdcdc;
$v2-button-very-light-grey: #e6e6e6;
$v2-button-very-light-grey-hovered: #a1a1a1;
$v2-button-medium-grey: #f5f5f5;
$v2-button-pink: #FF0094;
$v2-button-light: #F1F1FD;
$v2-button-green-approve: #49B153;
$v2-nested-dropdown-input-color: #545454;
$v2-create-menu-text-color: #133429;
$v2-projects-dropdown-project-group-indicator: #5a18b6;
$v2-projects-dropdown-blocked: #e01212;
$v2-pricing-list-grey: #f5f6f8;
$v2-urban-black: #2d095c;
$v2-pricing-border: #c3c3c3;
$v2-disabled-purple: #dccaf5;
$link-text-color: #4a90e2;
$v2-person-dropdown-role-background-color: #4a90e2;
$v2-global-search-criteria-background-color: #e1e1e1;
$v2-role-shorthand-color: #470c93;
$v2-starred-background-color: rgba(246, 218, 26, 0.05);
$ai-blue: #32baff;
$text-color-secondary: #8E8F9F;
$v2-menu-dropdown-active-purple: #4A0A9E;

/**
  * Fonts.
  */
$base-font-size: 16px;
$app-header-title-size: 2.42rem;
$base-font-regular-weight: 400;
$base-font-medium-weight: 500;
$base-font-semibold-weight: 600;
$base-font-bold-weight: 700;

$heading-font-family: 'urbane', sans-serif;
$text-font-family: 'neue-haas-grotesk-text', sans-serif;
$v2-site-header-font-family: 'neue-haas-grotesk-text', sans-serif;
$text-font-family-v2: 'neue-haas-grotesk-text', sans-serif;
$text-base-color: rgba(0, 0, 0, 0.87);

/**
  * Labels.
 */
$input-label-color: #A1A1A1;

/**
  * Fields.
  */
$input-background: #fbfaf8;
$input-border-color: #e4e2db;
$input-border-color-focus: #c8c6c1;
$input-color: #333;
$input-placeholder-color: #c9bdb5;
$input-placeholder-color-focus: lighten($input-placeholder-color, 10%);
$input-placeholder-color-disabled: #c0c0c0;

/**
  * Application layout.
  */
$app-border-color: #e6e6e6;
$app-hard-border-color: #b3b3b3;
$section-main-background: $color-light-grey;
$app-branding-table-border-color: #b3b3b3;
$app-loader-url: 'images/loaders/loader-16x16-white.svg';
/**
 * Section lists
 */
$nav-section-header: #2d2d2d;

/**
  * Application header.
  */
$section-header-height: 55px;
$app-header-logo-url: 'images/logo.svg';
$app-header-logo-white-url: 'images/logo-white.svg';
$app-header-2023-logo-white-url: 'images/forecast-white-nomargins.svg';
$app-header-logo-gray-url: 'images/logo-gray.svg';
$app-header-logo-v2-url: 'images/logo-v2.svg';
$app-header-logo-width: 66px;

/**
  * Buttons.
  */
$btn-second-pressed: #757575;
$btn-second-active: #e0e0e0;
$btn-white-pressed: #c7c7c7;
$btn-white-active: #f7f7f7;

/**
  * Dropzone.
  */
$drop-color: #f3f3f3;

$material-label-color: #858585;
$material-focused-color: $color-purple-hover-active;
$material-locked-color: #979797;

/*
  * Bulk update selected color
  */
$bulk-update-selected-color: #f1e9fa;

/*
 * portfolio report insight component
 */
$insight-component-portfolio-report-positive-value: #19a042;
