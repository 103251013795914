.github-object-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	.github-object-link-wrapper {
		text-decoration: none;
		.github-object {
			margin-bottom: 20px;
			font-size: 14px;
			letter-spacing: 0.5px;

			.object-header {
				display: flex;
			}

			.object-line3 {
				margin-top: 4px;
			}

			.object-icon {
				fill: currentColor;
				.state-merged {
					color: #6f42c1;
				}
				.state-closed {
					color: #cb2431;
				}
			}

			.issue-status-icon {
				width: 16px;
				&.open {
					background: url('images/integrations/github-issue-opened.svg') no-repeat;
				}
				&.closed {
					background: url('images/integrations/github-issue-closed.svg') no-repeat;
				}
			}

			.object-title {
				margin-left: 7px;
			}
		}
	}
}
