.settings-details {
	&.section-content {
		margin: 0;
		.section-body {
			margin: 16px 24px;
		}
	}
	.submit-button {
		@include primary-button;
	}
	input[type='number'] {
		//text-align: left;
		width: 100%;
	}

	input[type='text'] {
		width: 100%;
	}
	.details {
		display: flex;
		flex: 1;
	}
	.company-logo {
		.logo {
			margin-left: 2em;
			display: inline-block;
			position: relative;
			.upload-overlay,
			.upload-overlay-hidden {
				height: 140px;
				width: 140px;
				background: rgba(0, 0, 0, 0.75);
				border-radius: 50%;
				position: absolute;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.upload-overlay-hidden {
				display: none;
			}
			.uploading-gif,
			.uploading-gif-hidden {
				height: 48px;
				width: 48px;
				background-image: url($app-loader-url);
				background-repeat: no-repeat;
				background-size: 48px 48px;
			}
			img {
				border-radius: 50%;
			}
			.controls {
				padding-top: 10px;
				.controls-update {
					cursor: pointer;
					height: 21px;
					::-webkit-file-upload-button {
						cursor: pointer;
					}
					&:hover {
						text-decoration: underline;
					}
					input {
						width: 140px;
						margin-top: -5px;
						cursor: pointer;
						opacity: 0;
						position: absolute;
					}
				}
				.control {
					font-size: 14px;
					background-size: 16px;
					background-repeat: no-repeat;
					background-position: left center;
					padding: 5px 10px 0 21px;
					font-weight: $base-font-medium-weight;

					cursor: pointer;
					visibility: hidden;
					&:hover {
						cursor: pointer;
						text-decoration: underline;
					}
					&.update {
						background-image: url('images/sections/settings/settings-overview/update-photo.svg');
					}
					&.remove {
						background-image: url('images/sections/settings/settings-overview/remove.svg');
					}
				}
			}
			&:hover .control {
				visibility: visible;
			}
		}

		.form {
			display: inline-block;
			vertical-align: top;
			label input[type='text'],
			span.postfix {
				display: inline-block;
				margin-right: 0;
			}
		}
	}

	.scheduling-article-link {
		text-decoration: none;
		&:hover {
			color: $v2-branding-purple;
		}
	}

	.timezone {
		.select-wrapper {
			width: 400px;
		}
	}

	.demo-data {
		button {
			@include primary-button;
			margin: 5px 0;
		}
		.delete-project-button {
			display: flex;
			flex-direction: row;
			align-items: center;
			.locked-icon {
				padding-left: 10px;
			}
		}
	}

	.company-default-hours {
		width: 600px !important;

		thead,
		tbody {
			tr {
				th,
				td {
					text-align: right;
					min-width: 100px;
					max-width: 100px;
				}
			}
		}
	}

	.radio-container {
		display: flex;
		flex-direction: column;
		label {
			display: flex;
			align-items: center;
			margin-top: 8px;
		}
		input {
			margin-right: 16px;
		}
	}
	table {
		width: auto !important;
		tbody {
			tr {
				text-align: right;
				.controls {
					min-width: 150px;
					white-space: nowrap;
					padding-right: 20px;
					span {
						background-size: 16px;
						background-repeat: no-repeat;
						background-position: left center;
						padding: 0 10px 0 21px;
						cursor: pointer;
						&.remove {
							background-image: url('images/cross-section-icons/actions/remove.svg');
							&:hover {
								color: $color-purple-hover-active;
							}
						}
						&.prompt {
							cursor: default;
							padding: 0 5px 0 0;
							span {
								padding: 0 5px 0 0;
								&.confirm {
									cursor: pointer;
									&:hover {
										color: $color-purple-hover-active;
									}
								}
							}
						}
					}
				}
				.controls span {
					visibility: hidden;
				}
				&:hover {
					.controls span {
						visibility: visible;
					}
					.assigned {
						color: $text-base-color;
					}
				}
			}
		}
	}
}
