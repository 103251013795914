.new-project-content-wrapper-v2 {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-right: 58px;
	width: 862px;
	.duplication-info-text {
		font-style: italic;
		font-weight: normal;
		font-size: 12px;
		color: $v2-text-light-gray;
		padding-top: 8px;
		padding-left: 8px;
		max-width: 220px; // 2 buttons width
	}
	.input-container-v2 {
		//justify-content: space-between;
		.input-value-container {
			height: 30px;
			font-weight: normal;
			.input-value-unit {
				line-height: 28px;
			}
		}
		.currency-placeholder {
			&::before {
				top: 6px;
			}
		}
	}

	.custom-button-container {
		height: 30px;
		.input-container .text {
			font-weight: 700;
			font-size: 11px;
			letter-spacing: 0.5px;
		}
	}

	.project-main-info {
		display: flex;
		flex-direction: row;
		align-content: center;
		.project-name {
			width: 456px; //398 + 50 +8 (for the id input)
			margin-right: 8px;
		}
		.project-dates {
			display: flex;
			flex-direction: column;
			.date-picker-button-container-v3 {
				.date-picker-button.date-picker-button-standard {
					height: 30px;
				}
			}
			label {
				height: 11px;
				font-size: 9px;
				font-weight: 500;
				line-height: 1.22;
				letter-spacing: 1px;
				color: $v2-text-light-gray;
				text-transform: uppercase;
				margin-bottom: 5px;
				white-space: nowrap;
			}
		}
		.color-picker {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			text-align: right;
			.colors-picker-container {
				align-self: flex-end;
			}
			label {
				height: 11px;
				font-size: 9px;
				font-weight: 500;
				line-height: 1.22;
				letter-spacing: 1px;
				color: $v2-text-light-gray;
				text-transform: uppercase;
				margin-bottom: 5px;
				white-space: nowrap;
			}
		}
	}
	.project-second-info {
		margin-top: 20px;
		display: flex;
		flex-direction: row;
	}
	.separation {
		/*position: relative;
		left: -58px;
		width: 920px;*/
		.separation-line {
			margin-top: 30px;
			border-top: solid $app-border-color 1px;
		}
	}

	.more-settings {
		display: flex;
		align-items: center;
		font-size: 13px;
		text-align: left;
		cursor: pointer;
		height: 100%;
		color: $v2-text-gray;
		letter-spacing: 1px;
		margin-left: auto;
		margin-top: 8px;
		margin-bottom: 17px;

		&:hover {
			color: $v2-branding-purple;
		}
		.arrow {
			width: 10px;
			height: 5px;
			margin-left: 5px;
			background-size: 10px;
			background-position: center;
			background-image: url('images/v2/components/dropdown/arrow-down.svg');
			&.expanded {
				background-image: url('images/v2/components/dropdown/arrow-up.svg');
			}
		}
	}
	.information-icon {
		margin-left: 2px;
		height: 12px;
		bottom: -2px;
		position: relative;
	}
	.extra-margin {
		margin: 16px 0;
	}
	.add-client-controls {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		.drop-down-v2 {
			width: 100%;
			input {
				padding-left: 16px;
			}
		}
		&.new-client {
			.drop-down-v2 {
				width: 48px;
				.arrow {
					margin: 0;
					width: 100%;
				}
			}
		}
		.custom-button-container {
			width: 40px;
			height: 40px;
			span {
				font-size: 24px;
				font-weight: 400;
			}
			padding: 0;
			&.filled {
				background: $v2-create-button-green url('images/v2/components/overview_time/plus-add-white.svg') no-repeat center;
				&:disabled {
					background: $v2-create-button-green url('images/v2/components/overview_time/plus-add-green.svg') no-repeat center;
				}
			}
		}
		.add-new-client-controls-wrapper {
			display: flex;
			align-items: flex-end;
			width: 40px;
			&.expanded {
				width: 400px;
				.custom-button-container {
					span {
						font-size: 10px;
						font-weight: bold;
					}
				}
			}
			.input-container-v2 {
				width: 360px;
				.input-value-container {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
					border-right: none;
				}
			}
			.custom-button-container {
				height: 40px;
				width: 40px;
			}
		}
	}
}
