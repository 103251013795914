.settings-teams {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	.generic-empty-state-v2 {
		width: 100%;
	}
	.header-bar {
		padding-bottom: 16px;
		padding-top: 16px;
	}
	.settings-teams-content {
		padding: 0 24px 0 24px;
		flex-grow: 1;
		display: flex;
		&.empty-state {
			padding-bottom: 66px;
		}
		.insights-empty-state-container {
			width: 100%;
		}
		.settings-team {
			border-radius: 4px;
			border: solid 1px $app-border-color;
			margin-bottom: 10px;
			margin-right: 1px;
			.settings-team-table {
				width: 100%;
				th {
					font-size: 9px;
					letter-spacing: 1px;
					font-weight: 400;
					text-transform: uppercase;
					text-align: left;
					color: $v2-text-light-gray;
					padding-bottom: 6px;
				}
				.name-column {
					width: 266px;
				}
				.role-column {
					width: 232px;
				}
				.email-column {
					width: 240px;
				}

				.person-link-cell {
					cursor: pointer;
				}

				th,
				td {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					padding-right: 16px;
				}
			}
			.settings-team-head-row {
				display: flex;
				padding: 0 4px 0 8px;
				cursor: pointer;
				align-items: center;
				height: 40px;
				.input-container-v2,
				.team-name-container {
					width: 451px;
				}
				.team-name-container {
					height: 30px;
					display: flex;
					align-items: center;
					&:hover {
						background-color: #f3f3f3;
						border-radius: 3px;
					}
					.team-name {
						padding-left: 16px;
						font-size: 14px;
						font-weight: 500;
						user-select: none;
						span {
							font-weight: normal;
						}
					}
				}
				.input-container-v2 {
					height: 30px;
					.input-value-container {
						border: none;
						background-color: #f3f3f3;
						input {
							font-size: 14px;
							font-weight: 500;
						}
					}
				}
				.expansion-button {
					margin-left: auto;
					border: none;
					background-image: url('images/v2/components/dropdown/arrow-down.svg');
					background-repeat: no-repeat;
					background-position: center;
					width: 16px;
					height: 16px;
					margin-right: 12px;
					outline: 0;
					&.expanded {
						background-image: url('images/v2/components/dropdown/arrow-up.svg');
					}
				}
			}
			.settings-team-team-members {
				padding: 0 24px;
				.settings-team-team-member {
					border-top: 1px solid $app-border-color;
					height: 40px;
					user-select: none;
					font-size: 13px;
					color: $v2-text-gray;
					.forecast-person {
						width: 266px;
						.name {
							font-size: 13px;
						}
						.role {
							font-size: 12px;
							color: $v2-text-light-gray;
							font-weight: normal;
						}
					}
					.delete-person-button {
						width: 12px;
						height: 12px;
						background-size: 12px;
						background-image: url('images/components/close-modal.svg');
						background-repeat: no-repeat;
						border: none;
						opacity: 0;
						margin-left: 32px;
					}
					&:hover {
						.delete-person-button {
							opacity: 1;
						}
						color: $v2-branding-purple;
						.forecast-person {
							.name {
								color: $v2-branding-purple;
							}
							.role {
								color: $v2-branding-purple;
							}
						}
					}
				}
				.settings-team-add-team-member-row {
					border-top: 1px solid $app-border-color;
					height: 48px;
					display: flex;
					align-items: center;
					.assigned-dropdown-wrapper {
						width: 224px;
						height: 30px;
						flex: 0 0 224px;
					}
				}
			}
		}
	}
}
