/**
 * Vertical form.
 */
.form-vertical {
	label {
		display: block;
		margin: 15px 0px 5px 0px;
	}

	div.error {
		padding: 14px 15px;
		background: $error-background-color;
		border: 1px solid $error-border-color;
		color: $error-text-color;
		border-radius: 3px;
		outline: none;
		box-sizing: border-box;
		margin: 1rem 0;
		line-height: 1.4rem;
	}

	input[type='text'],
	input[type='email'],
	input[type='password'] {
		display: block;
		width: 100%;
		padding: 10px;
		background: $input-background;
		border: 1px solid $input-border-color;
		color: $input-color;
		border-radius: 3px;
		outline: none;
		box-sizing: border-box;

		&::placeholder {
			color: $input-placeholder-color;
		}

		&:focus {
			border-color: $input-border-color-focus;
		}
	}
}
