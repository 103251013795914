.task-overview-container {
	display: flex;
	flex-direction: column;
	min-width: 30%;
	flex-grow: 1;
	background-color: white;
	border-radius: 4px;
	border: 1px solid $v2-grey;
	.task-overview-title {
		padding: 0 8px 0 13px;
		font-family: $text-font-family;
		margin: 10px 0 0px 0;
		font-size: 24px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: $v2-text-gray;
	}
	.task-over-inner-scroll-container {
		width: 100%;
		height: 100%;
		padding: 0 8px 0 16px;
	}
	.info-icon-and-tooltip {
		margin-left: 4px;
		margin-top: 2px;
		position: relative;
		.info-tooltip {
			width: 220px;
			z-index: 1000;
			font-weight: normal;
			padding: 8px;
			font-size: 14px;
			position: absolute;
			box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
			background-color: white;
			top: -12px;
			right: 25px;
			color: $v2-text-gray;
			//transform: translateX(-50%);
			.info-tooltip-item {
				font-style: italic;
				margin: 5px 0 8px 0;
				padding: 0;
			}
		}
	}
	.task-overview-section-title {
		position: relative;
		margin: 10px 0;
		font-family: $text-font-family;
		font-size: 16px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: $v2-text-gray;
	}
	.task-title-and-info-icon {
		display: flex;
		flex-direction: row;
		margin: 10px 0 30px 0;
		.task-overview-section-title {
			margin: 0;
		}
	}
	.tasks-done-section {
		width: 100%;
		.progress-bar {
			position: relative;
			width: 100%;
			height: 10px;
			margin-top: 25px;
			margin-bottom: 5px;
			&:hover {
				.progress-bar-inner-text {
					opacity: 0.95;
				}
			}
			.progress-bar-inner-text {
				transition: all 0.2s ease-in;
				opacity: 0;
				white-space: nowrap;
				position: absolute;
				transform: translateY(-50%);
				top: 50%;
				font-size: 12px;
			}
			.progress {
				position: absolute;
				top: 0;
				left: 0;
				background-color: #e7abab;
				height: 100%;
				&.bottom {
					top: 100%;
					background-color: #e7abab;
				}
				&.done {
					background-color: $v2-completed-green;
				}
			}
			.remaining {
				position: absolute;
				overflow: hidden;
				top: 0;
				left: 0;
				height: 100%;
				background-color: rgba(231, 171, 171, 0.5);
				&.grey {
					background-color: $v2-text-light-gray;
				}
			}
			.progress-indicator-and-text {
				position: absolute;
				top: 0;
				height: 100%;
				.progress-indicator-text {
					font-family: $text-font-family;
					font-size: 12px;
					font-weight: 500;
					font-style: normal;
					font-stretch: normal;
					line-height: normal;
					letter-spacing: normal;
					color: $v2-text-gray;
					position: absolute;
					top: -16px;
					left: 0;
					transform: translateX(-50%);
					font-size: 12px;
				}
				.progress-indicator {
					position: absolute;
					width: 1px;
					opacity: 0.5;
					background-color: $v2-negative-dropdown-grey;
					height: 100%;
					top: 0;
					left: 0px;
				}
				&.bottom {
					top: 50%;
					.progress-indicator-text {
						left: 2px;
						top: 7px;
					}
				}
			}
		}
	}

	.task-overview-list {
		display: flex;
		flex-direction: column;
		padding-right: 8px;
		.chart-wrapper {
			position: relative;
			margin: 10px;
		}
		.num-tasks-done {
			margin-left: 4px;
			font-size: 16px;
			font-weight: 300;
		}
		.task-overview-item:last-child {
			padding-bottom: 4px;
		}
		.task-overview-item {
			padding: 14px 0;
			border-top: 1px solid $v2-grey;
			font-size: 14px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			.task-overview-item-text-and-unit {
				color: $v2-text-gray;
				display: flex;
				flex-direction: row;
				.task-overview-item-text {
					font-weight: 500;
					font-size: 14px;
					margin-right: 1px;
					margin-left: 4px;
				}
				.task-overview-item-unit {
					font-size: 10px;
					margin-top: 4px;
					font-weight: 500;
					line-height: 1.14;
					color: $v2-text-gray;
				}
			}
		}
		.task-overview-items {
			.task-overview-item:first-child {
				border-top: none;
			}
		}
		.tasks-graph-container {
			position: relative;
			display: flex;
			flex-direction: column;
			.forecast-person {
				font-size: 14px;
				flex-grow: 1;
			}
			.person-and-count-container {
				display: flex;
				flex-direction: row;
				.person-task-count {
					font-weight: 500;
					font-size: 14px;
					color: $v2-text-gray;
					flex-grow: 0;
				}
			}
		}
	}
}
