.add-project-group-modal {
	.body {
		display: flex;
		flex: 1 1 auto;
		flex-direction: column;
		width: 600px;
		label {
			font-weight: 700;
		}
		.wrapper {
			width: 95%;
		}
		.project-select-container {
			margin-top: 15px;
			.select-label {
				font-size: 0.75rem;
				color: $material-label-color;
			}
			.drop-down-v2 {
				width: 100%;
			}
		}
		.more-projects-button {
			border: none;
			margin-top: 15px;
			width: 150px;
			font-size: 0.75rem;
			color: $material-label-color;
			text-align: left;
			padding: 0;
		}
		.info-wrapper {
			margin-top: 20px;
			display: flex;
			flex: 0 0 220px;
			flex-direction: column;
			span {
				font-size: 18px;
				margin-top: 10px;
			}
		}
		.buttons {
			display: flex;
			flex: 0 0 auto;
			flex-direction: row;
			justify-content: flex-end;
			margin-top: 2em;
			.cancel-button {
				@include secondary-button;
				margin-right: 10px;
			}
			.add-button {
				@include primary-button;
			}
		}
	}
}
