.owner-menu {
	border-radius: 4px;
	border: solid 1px $v2-branding-purple;
	position: absolute;
	bottom: 36px;
	right: 8px;
	background-color: $v2-section-main-background;
	z-index: 103;
	width: 225px;

	.search {
		.input-container-v2 {
			.input-value-container {
				outline: none;
				border: none;
			}
		}
	}

	.owner {
		display: flex;
		border-top: solid 1px $v2-button-very-light-grey;
		padding-top: 7px;
		padding-bottom: 7px;

		.owner-menu-person {
			cursor: auto;

			.name-role {
				width: 80%;
			}
		}

		.remove-owner-button {
			width: 10px;
			height: 10px;
			right: 16px;
			align-self: center;
			position: absolute;
			border: none;
			outline: none;
			margin: 0;
			display: inline-block;
			overflow: hidden;
			cursor: pointer;

			&::before,
			&::after {
				background: $v2-text-light-gray;
				content: '';
				position: absolute;
				height: 1px;
				width: 100%;
				top: 50%;
				left: 0;
				margin-top: -1px;
			}
			&::before {
				transform: rotate(45deg);
			}
			&::after {
				transform: rotate(-45deg);
			}
		}
	}

	.candidates {
		.people {
			cursor: pointer;
			border-top: solid 1px $v2-button-very-light-grey;
			padding-left: 15px;
			padding-top: 8px;
			padding-bottom: 8px;
			font-size: 12px;

			.people-text {
				color: $v2-branding-purple;
			}

			.people-count {
				color: $v2-text-gray;
			}

			.expansion-button {
				flex-shrink: 0;
				margin-left: auto;
				margin-top: 12px;
				cursor: pointer;

				.icon-chevron {
					font-size: 10px;
					letter-spacing: 0.5px;
					position: relative;
					margin-right: 27px;

					&::after {
						content: '';
						width: 12px;
						height: 12px;
						background-position: center center;
						background-repeat: no-repeat;
						background-size: 12px;
						top: -12px;
						right: -12px;
						position: absolute;
						display: inline-block;
					}

					&.expanded::after {
						background-image: url('images/v2/components/dropdown/arrow-up.svg');
					}

					&.not-expanded::after {
						background-image: url('images/v2/components/dropdown/arrow-down.svg');
					}
				}
			}
		}

		.candidates-dropdown {
			border-top: solid 1px $v2-button-very-light-grey;
		}
	}

	@import './owner_menu_person.scss';
}
