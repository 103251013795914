.project-page-wrapper {
	display: flex;
	flex-direction: column;
	flex: 1;
	.projects-page-body {
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		.scrollable {
			margin: 0 66px;
			margin-bottom: 66px;
		}
		.header-container {
			//z-index: 20; //place on top of the totals table
			&.stuck {
				position: fixed;
				background: white;
				width: 100%;
				top: 70px;
			}
		}
	}

	.section-title {
		color: $v2-text-gray;
		font-size: 16px;
		font-weight: 500;
		display: inline-block;
		cursor: pointer;

		.expand-icon {
			display: inline-block;
			margin-left: 10px;
		}
	}

	.top-sections {
		display: flex;
		margin: 30px 0;
		margin-top: 4px;

		.budget-data {
			flex-grow: 1;
			flex-shrink: 1;
			min-width: 500px;
			&.full-size {
				width: 100%;
			}
			.chart {
				width: 100%;
				height: 360px;
				border: 1px solid #e6e6e6;
				padding: 16px 20px;
				margin-top: 15px;

				&.empty {
					.generic-empty-state-v2 {
						.empty-state-elements {
							.empty-state-image {
								height: 150px;
								background-size: 161px 150px;
							}
						}
					}
				}

				&.full-size {
					width: 100%;
				}

				canvas {
					max-height: 95%; // prevents chart from rendering outside the container
					min-width: 100%;
				}

				.chart-options {
					display: flex;

					.chart-option {
						&:not(:last-child) {
							margin-right: 16px;
						}
						font-size: 10px;
						letter-spacing: 0.5px;
						text-transform: uppercase;
						font-weight: 500;
						color: #a1a1a1;
						cursor: pointer;

						&.active,
						&:hover {
							color: $v2-branding-purple;
						}
					}
				}
			}

			.empty-state-image {
				height: 150px;
				background-size: 100px;
			}
			.empty-state-image,
			.custom-button-container {
				margin-top: 0;
			}
		}

		.graph-side-container {
			display: flex;
			flex-direction: column;
			width: 263px;
			margin-left: 16px;

			.fixed-price-totals {
				.fixed-price-numbers {
					display: flex;
					margin-top: 15px;
					margin-bottom: 24px;

					.fixed-price-number {
						border: 1px solid #e6e6e6;
						flex-grow: 1;
						padding: 10px;

						&:not(:last-child) {
							border-right: none;
						}

						.title {
							font-size: 9px;
							text-transform: uppercase;
							letter-spacing: 1px;
							color: #a1a1a1;
							font-weight: 500;
							text-align: left;
						}

						.number {
							font-size: 13px;
							font-weight: 500;
							text-align: right;
							color: #535353;
							margin-top: 13px;
						}
					}
				}
			}

			.project-billability {
				display: flex;
				flex-direction: column;

				.section-title {
					color: $v2-branding-purple;
					display: flex;
					align-items: center;
					height: 20px;
					span {
						display: flex;
						margin-left: 5px;
					}
				}

				&.compact {
					.billability-list,
					.invoices-list {
						.billability-item,
						.invoice-item {
							padding: 18px 24px;
						}
					}
				}

				.billability-list,
				.invoices-list {
					display: flex;
					flex-direction: column;
					width: 100%;
					border: 1px solid #e6e6e6;
					margin-top: 15px;
					background-color: $v2-list-background-purple;
					height: 360px;

					.billability-item,
					.invoice-item {
						flex: 1;
						display: flex;
						flex-direction: column;
						justify-content: center;
						padding: 0 28px;
						font-size: 13px;
						font-weight: 500;

						&:not(:last-child) {
							border-bottom: 1px solid #e2e2e2;
						}

						.name {
							color: $v2-text-light-gray;
							font-size: 9px;
							letter-spacing: 1px;
							text-transform: uppercase;
							padding-bottom: 5px;
						}

						.total {
							color: $v2-text-gray;
							font-size: 15px;
						}
					}
				}
			}
		}
	}

	.project-totals {
		padding-bottom: 30px;
		transition: box-shadow 0.3s;

		&.stuck {
			top: 130px;
			left: 0;
			position: fixed;
			background-color: #fff;
			width: 100%;
			z-index: 1;
			padding: 0 66px;
			padding-bottom: 24px;
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
		}

		.totals-list {
			display: grid;
			grid-gap: 20px;
			grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));

			margin-top: 15px;
		}
	}

	.tables-section {
		&.extra-margin {
			margin-top: 170px;
		}

		.tables-wrapper {
			&.empty {
				height: 400px;
			}
		}

		.tables-tabs {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(10px, max-content));
			margin-top: 15px;
			font-weight: 500;

			.tab {
				padding: 8px 25px;
				border: 1px solid $app-border-color;
				border-bottom: 0;
				text-transform: uppercase;
				font-size: 10px;
				color: $v2-text-light-gray;
				letter-spacing: 0.5px;
				position: relative;
				cursor: pointer;

				&:hover {
					color: $v2-branding-purple;
				}

				&.selected {
					color: $v2-branding-purple;
					&::after {
						content: '';
						display: block;
						width: 100%;
						height: 1px;
						background: white;
						position: absolute;
						left: 0;
						bottom: -1px;
					}
				}
			}
		}
		.table-scroll-container {
			border: 1px solid $app-border-color;
			.tables-wrapper {
				min-width: min-content;

				.loader {
					will-change: background;
					height: 400px;
					width: calc(100% - 48px);
					margin: 28px 24px;

					animation-duration: 1s;
					animation-fill-mode: forwards;
					animation-iteration-count: infinite;
					animation-timing-function: linear;
					animation-name: placeholderAnimate;
					background: $v2-fixed-element-grey; // Fallback
					background: linear-gradient(to right, #eee 2%, #f9f9f9 18%, #eee 33%);
					background-size: 1300px;
				}

				&.empty {
					height: 340px;
					padding: 16px 20px;
					margin: 16px 0;

					.generic-empty-state-v2 {
						.empty-state-elements {
							.empty-state-image {
								height: 150px;
								background-size: 161px 150px;
							}
						}
					}
				}

				.table-container {
					padding: 28px 24px;
					// min-width: min-content;

					&:not(:last-of-type) {
						border-bottom: 1px solid $v2-button-very-light-grey;
					}

					&.out-of-screen {
						padding-right: 24px;
					}

					.table-name {
						display: flex;
						font-size: 13px;
						font-weight: 500;
						color: $v2-text-gray;
						margin-bottom: 10px;
						span {
							margin-right: 5px;
						}
					}
				}
			}
		}
	}
}

@keyframes placeholderAnimate {
	0% {
		background-position: -650px 0;
	}
	100% {
		background-position: 650px 0;
	}
}
