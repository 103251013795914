.error-modal {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	z-index: 99;
	background: rgba(0, 0, 0, 0.75);
	.error {
		height: 100%;
		padding: 30px 0;
		.inner {
			height: 100%;
			box-shadow: 0 10px 20px 10px rgba(0, 0, 0, 0.2);
			border-radius: 5px;
			background: $color-white;
			display: flex;
			flex-direction: column;
			@include scroll-y;

			.header {
				padding-left: 10px;
				padding-right: 10px;
				background-color: $color-red;

				h1 {
					font-size: 1.8rem;
					font-weight: 700;
				}
			}

			.message {
				padding: 10px;
			}

			.controls {
				padding: 10px;
				display: flex;
				justify-content: center;
				.error-ok-button {
					@include secondary-button;
					height: 40px;
				}
			}
		}
	}
}
