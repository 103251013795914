.dependency-form {
	position: absolute;

	&.before-form {
		left: 0;
	}

	&.after-form {
		right: 0;
	}

	.task-container-wrapper {
		position: relative;
		overflow: hidden;
		margin: 10px 0;

		.task-container {
			width: 340px;
			font-size: 13px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-direction: row;

			.dependencies-dropdown {
				width: 124px;

				&.after {
					margin-left: 5px;
				}
			}

			.conditions-dropdown {
				width: 80px;
			}

			.form-helper-text {
				color: $v2-text-gray;
				font-weight: bold;
				text-transform: uppercase;
				font-size: 10px;
				margin: 0 5px;
				width: min-content;

				&:first-of-type {
					margin-left: 0;
				}
			}

			.dependencies-dropdown {
				flex: 1 1;
				max-width: 160px; // firefox fix
			}

			.buttons-row {
				display: flex;
				justify-content: flex-end;

				.custom-button-container {
					width: 75px;
					height: 30px;
				}

				.add-button-component {
					width: 30px;
					height: 30px;
					margin-left: 5px;
				}
			}
		}
		.corner {
			position: absolute;
			left: 308px;
			top: -15px;
			width: 40px;
			height: 20px;
			border: 1px solid $v2-dark-grey;
			background-color: white;
			transform: rotate(30deg);
		}
	}
}
