.project-overview {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	margin: 1.5625rem 2rem 0 1rem;
	overflow: hidden;
	.section-body {
		display: flex;
		flex: 1 1 auto;
		flex-direction: column;
		margin-bottom: 15px;
		&.new {
			margin-top: 10px;
		}
		.task-allocation-data-container {
			margin-bottom: 10px;
			margin-right: 10px;
			//transition: all 0.5s ease-in;
			position: relative;
			overflow: hidden;
			&.expanded {
				transition: all 0.5s ease-in;
			}
			.role-allocation-data {
				display: flex;
				flex-direction: row;
				.role-allocation-data-item {
					display: flex;
					flex-direction: row;
				}
			}
		}
		.tiles {
			font-family: $text-font-family;
			color: $v2-text-gray;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			@include data-tile;
			.tile {
				width: 100%;
				font-family: $text-font-family;
				position: relative;
				display: flex;
				flex-grow: 0;
				flex-wrap: no-wrap;
				background-color: $color-white;
				margin: 0 1em 1em 2px;
				padding: 1em;
				border-radius: 3px;
				border: 1px solid $v2-grey;
				.task-title-and-info-icon {
					display: flex;
					flex-direction: row;
				}
				.info-icon-and-tooltip {
					margin-left: 4px;
					margin-top: -2px;
					position: relative;
					.info-icon {
						position: absolute;
						top: 0;
						left: 0;
					}
					.info-tooltip {
						width: 220px;
						z-index: 1000;
						font-weight: normal;
						padding: 8px;
						font-size: 14px;
						position: absolute;
						box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
						background-color: white;
						top: -12px;
						left: 25px;
						//transform: translateX(-50%);
						.info-tooltip-item {
							font-style: italic;
							margin: 5px 0 8px 0;
							padding: 0;
						}
					}
				}
				&.chart-tile {
					padding: 1em 1em 0 1em;
				}
				.project-description-pop-out {
					cursor: pointer;
					width: 100%;
					position: absolute;
					padding-right: 6px;
					right: 0px;
					bottom: 4px;
					border: none;
					.project-description-pop-out-btn {
						border: none;
						outline: none;
						position: absolute;
						right: 6px;
						bottom: 2px;
						background: url('images/components/overlay/description.svg');
						background-repeat: no-repeat;
						background-position: right;
						height: 20px;
						width: 24px;
					}
					.project-description-pop-out-container {
						width: 100%;
						cursor: text;
						overflow: hidden;
						display: flex;
						flex-direction: column;
						flex: 1 1 auto;
						outline: none;
						text-transform: none;
						font-family: $text-font-family;
						font-size: 12px;
						font-weight: 500;
						line-height: 1.58;
						text-decoration: none;
						color: $v2-text-gray;
						z-index: 9;
						top: 10px;
						left: 0;
						padding: 10px 10px 20px 10px;
						text-align: left;
						position: absolute;
						background-color: white;
						box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
						.project-description-pop-out-header {
							margin-bottom: 10px;
							font-family: $text-font-family;
							font-size: 16px;
							font-weight: 500;
							color: $v2-text-gray;
						}
					}
				}
				.tile-column {
					max-width: 100%;
					display: flex;
					flex-direction: column;
					flex: 1 1 auto;
					.expand-icon {
						outline: none;
						position: absolute;
						right: 2px;
						bottom: 2px;
						cursor: pointer;
						z-index: 9;
						background: url('images/v2/components/dropdown/arrow-down.svg');
						background-repeat: no-repeat;
						background-position: center;
						width: 14px;
						height: 14px;
						&.expanded {
							background: url('images/v2/components/dropdown/arrow-up.svg');
							background-repeat: no-repeat;
							background-position: center;
						}
					}
					.data-tile {
						padding-bottom: 0.6em;
						padding-right: 0.6em;
						.project-status-color:not([disabled]) {
							cursor: pointer;
							&:hover {
								-webkit-transform: scale(1.1);
								-ms-transform: scale(1.1);
								transform: scale(1.1);
							}
						}
						.data-tile-value,
						.date-input {
							font-weight: normal;
							font-size: 14px;
						}
					}
					.negative {
						color: $negative-value;
					}
					.progress-data-tile-container {
						.data-tile {
							display: flex;
							flex-direction: column;
							align-items: center;
							padding: 0;
							.data-tile-value {
								padding: 0;
								.progress-radial {
									position: relative;
									width: 94px;
									height: 94px;
									border-radius: 50%;
									flex-shrink: 0;
									.progress-center {
										position: absolute;
										width: 74px;
										height: 74px;
										background-color: $color-white;
										border-radius: 50%;
										margin-left: 10px;
										margin-top: 10px;
										text-align: center;
										font-size: 16px;
										display: flex;
										flex-direction: column;
										justify-content: center;
										font-weight: bold;
									}
								}
							}
						}
					}
				}
				&:first-child {
					margin-left: 0.2em;
				}
				&:last-child {
					margin-right: 0.2em;
				}
				.dates-wrapper {
					display: flex;
					//flex-direction: column;
					//align-items: center;
					cursor: pointer;
					.value-wrapper {
						&:hover {
							text-decoration: underline;
						}
					}
					.info-dates {
						margin-bottom: 100px !important;
					}
				}
			}
		}
		.timeline-container {
			flex: 1 0 0;
			margin: 0 2px 0 2px;
			border-radius: 3px;
			box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
			background-color: $color-white;
			padding-left: 22px;
			position: relative;
			&.project-in-group {
				padding-left: 40%;
				padding-top: 50px;
			}
			&.task-overview {
				box-shadow: none;
				background-color: $drop-color;
				padding-left: 0;
				display: flex;
				flex-direction: row;
				&.white {
					background-color: $color-white;
				}
			}
			.unavailable-label {
				position: absolute;
				top: 60px;
				left: 0px;
				z-index: 2;
				color: white;
				width: 100%;
				text-align: center;
				background: rgba(0, 0, 0, 0.7);
				padding: 2rem;
				a {
					color: $color-white;
				}
			}

			.unavailable {
				filter: blur(4px);
				pointer-events: none;
			}

			.menu {
				margin-bottom: 6px;
			}
			.scheduling-timeline-wrapper {
				.scheduling-container {
					height: calc(100vh - 401px);
					.vis-right {
						background-color: $color-white;
					}
					.vis-nesting-group {
						display: flex;
						flex-direction: row-reverse;
						align-items: center;
						padding-right: 14px;
					}
				}
			}
		}
	}
}
