.list-section {
	flex: 1;

	@include scroll-y;
	height: 100% !important;
	padding: 15px;
	background: #fbfaf8;
	.table-group {
		@include standard-table;
		border: 1px solid #e6e6e6;
		box-shadow: 0 1px 2px 0px #e6e6e6;
		padding: 15px;
		margin-bottom: 10px;
		background: $color-white;
		table {
			width: 100%;
		}

		@include data-tile;

		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;

		h2 {
			margin: 0;
			padding: 6px 0 0 0;
		}
		.color {
			padding-right: 0px;
			.project-color {
				display: block;
				height: 24px;
				border-radius: 5px;
				background-color: orange;
				margin: 0px;
				width: 5px;
			}
		}
	}
}
.insight-virtualized-list {
	outline: none;
	margin: 10px 0 15px 0;
	overflow: visible !important;
	.ReactVirtualized__Grid__innerScrollContainer {
		font-weight: $base-font-regular-weight;
		font-size: 0.9375rem; //0.875rem;
		letter-spacing: 0.3px;
		margin-bottom: 1em;
		border-collapse: separate;
		border-spacing: 0 2px;
		transition: all 0.2s ease;
		display: flex;
		flex: 1 1 auto;
		align-items: flex-start;
		overflow: visible !important;
	}
	.header-row {
		text-align: left;
		font-weight: $base-font-bold-weight;
		user-select: none;
		margin: 0 3px;
		.list-header {
			flex: 1 1 auto;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			&.sortable {
				cursor: pointer;
				span {
					margin-left: 0.5em;
					background-size: 12px;
				}
				&.ascending {
					span {
						background-image: url('images/cross-section-icons/table/sort-ascending.svg');
						background-size: 18px;
						background-position: top center;
					}
					color: $brand-color-accent;
				}
				&.descending {
					span {
						background-image: url('images/cross-section-icons/table/sort-descending.svg');
						background-size: 18px;
						background-position: bottom center;
					}
					color: $brand-color-accent;
				}
			}
			&:hover {
				color: $color-purple-hover-active;
			}
			.header-container {
				display: flex;
				flex-wrap: no-wrap;
				font-size: 0.875rem; //0.8125rem;
				padding: 0 10px 0 10px;
				align-items: flex-start;
			}
			.header-title-wrapepr div,
			.header-title-wrapepr,
			.header-totals {
				//display: block;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				width: auto;
			}
			.header-totals {
				font-size: 13px;
				height: 15px;
				font-weight: 500;
				color: #7d7d7d;
				padding: 0 10px 0 10px;
			}
		}
	}
	.line-item-row {
		border: 2px solid rgba(0, 0, 0, 0.3);
		border-top: none;
		height: 51px;
		border-radius: 5px;
		background-color: $color-white;
		cursor: pointer;
		&:hover {
			transition: transform 0.25s;
			background-color: $color-white;
			-webkit-transform: scale(1.009);
			-ms-transform: scale(1.009);
			transform: scale(1.009);
			color: $color-purple-hover-active;
		}
		.table-cell {
			padding: 0 10px 0 10px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			overflow: hidden;
			.cell-content-span {
				display: block;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}
			&.align-center {
				justify-content: center;
			}
		}
		.progress-container {
			@include completion-bar;
		}
		.assigned {
			display: flex;
			flex: 1;
			overflow: hidden;
			height: 51px;
			.person-elipsis-view {
				display: flex;
				flex-direction: row;
				align-items: center;
				cursor: default;
				flex-wrap: no-wrap;
			}
			.forecast-person {
				margin: 10px 5px 10px 0;
			}
		}
		.skills {
			flex: 1;
			display: flex;
			flex-direction: row;
			overflow: hidden;
			align-items: center;

			height: 51px;
			.skill-label {
				display: flex;
				flex-direction: row;
				margin-right: 8px;
				border-radius: 5px;
				justify-content: center;
				align-items: center;
				height: 12px;
				width: 24px;
				margin-top: 5px;
				flex-shrink: 0;
				overflow: hidden;
			}
		}
		.status-color-cell {
			display: flex;
			align-items: center;
		}
		.person-skill-cell {
			display: block;

			.person-skill-cell-tooltip {
				height: 100%;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;

				.person-skill-cell-content {
					display: block;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}
		}
	}
	.no-hover {
		cursor: default;
		&:hover {
			transition: none;
			-webkit-transform: none;
			-ms-transform: none;
			transform: none;
			color: $text-base-color;
		}
	}
	.nested-list-line-wrapper {
		display: flex;
		flex-direction: row;
		.space {
			flex: 0 0 20px;
		}
		.line-item-row {
			flex: 1 1 auto;
		}
	}
}
