.project-scheduling,
.project-overview {
	.vis-nesting-group {
		&::before {
			//Make expand/collapse arrow width consistent
			width: 16px;
		}
	}

	.vis-label {
		background-color: white;
		.vis-inner {
			padding: 0 !important;
		}
	}
	.project-group {
		&.expanded {
			border-bottom: 2px solid transparent;
		}
	}
	.project-group,
	.phases-group {
		.vis-item {
			cursor: pointer;
		}
		.vis-drag-center,
		.vis-drag-right,
		.vis-drag-left {
			cursor: pointer !important;
		}
	}
	.phases-group {
		min-height: 40px;
		position: relative;
	}

	.info-dropdown {
		display: flex;
		flex-direction: column;
		padding: 8px;
		border-radius: 3px;
		background-color: $color-white;
		border: 1px solid $app-border-color;
		position: fixed;
		z-index: 2;
		-webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
		-moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
		box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
		width: 480px;
		.item-name {
			font-weight: bold;
			width: 460px;
			white-space: normal;
			word-break: break-word;
		}
		.item-data-container {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			padding-top: 8px;
			justify-content: center;
			.item-data-left {
				width: 100px;
				display: flex;
				flex-direction: column;
				margin-right: 10px;
				padding-top: 4px;
				align-items: center;
				.total-text {
					font-weight: bold;
					margin-bottom: 4px;
				}
			}
			.item-data-right {
				display: flex;
				flex-direction: column;
				.role-row {
					margin-bottom: 2px;
					.text-container {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						margin-bottom: 2px;
					}
					.row-bar {
						flex: 1 0 auto;
						height: 18px;
						background-color: grey;
						margin-bottom: 2px;
						position: relative;
						.row-bar-progress {
							position: absolute;
							top: 0;
							left: 0;
							height: 18px;
							background-color: $brand-color;
						}
						.row-bar-text {
							position: absolute;
							padding-left: 4px;
							font-size: 14px;
							line-height: 18px;
							max-width: 130px;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
						}
					}
					.row-overallocated-icon {
						width: 0;
						height: 0;
						border-top: 9px solid transparent;
						border-bottom: 9px solid transparent;
						border-left: 10px solid $brand-color;
					}
				}
				table {
					width: 100%;
					thead {
						text-align: center;
					}
					tbody {
						text-align: right;
					}
					.th-allocated,
					.td-allocated {
						padding-right: 10px;
					}
					.td-diff {
						padding-left: 10px;
					}
					.td-progress {
						width: 142px;
						text-align: left;
						font-weight: bold;
						color: $color-white;
						padding-right: 4px;
						padding-left: 4px;
						.td-progress-inner {
							display: flex;
							flex-direction: row;
						}
					}
				}
			}
		}
		.progress-radial {
			position: relative;
			width: 100px;
			height: 100px;
			border-radius: 50%;
			flex-shrink: 0;
			.progress-center {
				position: absolute;
				width: 80px;
				height: 80px;
				background-color: $color-white;
				border-radius: 50%;
				margin-left: 10px;
				margin-top: 10px;
				text-align: center;
				font-size: 16px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				.allocated-text,
				.remaining-text,
				.out-of-text {
					font-weight: bold;
				}
				.out-of-text {
					color: $color-silver;
				}
			}
		}
	}
}