.comment-section-container {
	margin: 0 58px;
	display: flex;
	flex-direction: column;
	.comment-section-header {
		height: 57px;
		width: 100%;
		border: none; //get rid of default button borders
		border-top: 1px solid $app-border-color;
		outline: none;

		display: flex;
		align-items: center;
		padding-left: 0;

		& > *:not(:last-child) {
			margin-right: 8px;
		}

		&:focus {
			outline: none;
			.expand-icon {
				path {
					stroke: $color-martin-tertiary-light-purple;
				}
			}
		}

		.comments-icon {
			transform: translateY(4px);
		}

		.comments-text {
			color: $v2-text-gray;
			font-size: 13px;
			font-weight: 500;
		}

		.comments-count {
			font-size: 14px;
		}

		.expand-icon {
			margin-left: auto;
		}
	}

	.comments-toggle {
		font-size: 12px;
		color: $v2-text-light-gray;
		cursor: pointer;
		margin-right: auto;
	}

	.task-modal-v3-comments {
		padding-top: 2px;
		&.scroll-space {
			margin-bottom: 125px;
		}
		.comment {
			display: flex;
			margin: 8px 0;

			.rich-text-field-editor-wrapper {
				font-size: 14px;
			}

			.new-ui-actions-menu {
				margin-left: auto;
				margin-right: 2px;
			}
			.comment-section {
				padding-left: 16px;
				flex: 1 1;
				width: 0;

				.custom-button-container {
					height: 30px;
				}

				.rich-text-field-input-container.has-focus {
					overflow: unset;
				}
			}

			.comment-header {
				display: flex;
				align-items: flex-end;
			}

			.person-name {
				font-size: 12px;
				font-weight: 600;
				color: $v2-text-gray;
			}

			.time-ago {
				padding-left: 10px;
				font-size: 10px;
				color: $v2-text-light-gray;
			}

			// .editor {
			// 	padding: 0px;
			// }

			.rich-text-field-editor-wrapper {
				font-size: 13px;
			}

			.edit-mode-buttons {
				display: flex;
				flex-direction: row;
				height: 40px;
				width: 100%;
				justify-content: flex-end;
				button {
					padding: 0 32px;
				}
			}
		}
	}
}
