.add-team-modal-v3 {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	font-family: $text-font-family-v2;
	min-height: 385px;
	max-width: 456px;
	&.tall {
		min-height: 458px;
	}
	&.wide {
		max-width: unset;
	}
	.contact-dropdown-wrapper {
		margin-bottom: 16px;
		.assigned-icons {
			top: 8px;
		}
		input {
			border-color: #e6e6e6;
			&:hover {
				border-color: #e6e6e6;
			}
			&:focus {
				border-color: $v2-branding-purple;
			}
		}
	}
	.nested-dropdown-options-wrapper {
		border-bottom-right-radius: 4px;
		border-bottom-left-radius: 4px;
	}
	.input-arrow-wrapper {
		position: relative;
		input {
			width: 100%;
			min-width: 100%;
		}
		.arrow-icon-wrapper {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: -3px;
		}
	}
	.dropdown-header-text {
		font-family: $text-font-family;
		font-size: 9px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: 1;
		letter-spacing: 1px;
		color: $v2-text-light-gray;
		text-transform: uppercase;
		margin-bottom: 8px;
	}
	.nested-dropdown-v2 {
		height: 40px;
		border: none;
		.outer-option-header {
			min-width: 456px;
		}
		.nested-dropdown-options-wrapper {
			display: flex;
		}
		.nested-dropdown-input {
			padding: 10px 10px !important;
			border: 1px solid #e6e6e6 !important;
			width: 100% !important;
			border-radius: 4px;
		}
		.nested-dropdown-v2-label {
			left: 0;
		}
		&.expanded {
			.nested-dropdown-input {
				border: none !important;
			}
		}
	}
	.team-indicator,
	.team-indicator-placeholder {
		position: relative;
		height: 20px;
		min-width: 100px;
		padding: 4px 10px;
		font-family: $text-font-family;
		font-size: 11px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.09;
		letter-spacing: normal;
		text-align: center;
		color: $v2-text-gray;
		margin-top: 2px;
		margin-right: 28px;
		max-width: 170px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.team-indicator {
		border-radius: 13px;
		border: 1px solid $v2-backdrop-gray;
	}
	.invite-header-text,
	.assign-header-text {
		font-size: 20px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: $v2-text-gray;
		font-family: $heading-font-family;
		margin-bottom: 16px;
	}
	.invite-section {
		width: 900px;
		.invite-header {
			display: flex;
			flex-direction: row;
			.back-arrow {
				cursor: pointer;
				outline: none;
				width: 16px;
				//height: 16px;
				background: url('images/v2/components/dropdown/arrow-down.svg');
				background-size: 16px;
				background-position: center;
				background-repeat: no-repeat;
				transform: rotate(90deg);
				margin-right: 12px;
				margin-bottom: 15px;
			}
		}
		.seats-count {
			width: 100%;
			background-color: $color-medium-gray;
			font-size: 12px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.33;
			letter-spacing: normal;
			font-family: $text-font-family;
			color: $v2-text-gray;
		}
		.seats-text {
			padding: 10px 16px;
		}
	}

	.team-select {
		display: flex;
		//align-items: flex-end;
		flex-direction: column;
		.invite-link-section {
			width: 456px;
			height: 34px;
			cursor: pointer;
			border-bottom: solid 1px $v2-row-highlight-border-color;
			.invite-text {
				margin-top: 10px;
				font-size: 12px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.17;
				letter-spacing: normal;
				font-family: $text-font-family;
				color: $v2-text-gray;
			}
			.green {
				color: $v2-create-button-green;
			}
		}
		.drop-down-v2 {
			width: 456px;
			margin-right: 8px;
			.nested-list-wrapper {
				width: 399px;
			}
		}
		.custom-button-container {
			width: 40px;
			height: 40px;
			span {
				font-size: 24px;
				font-weight: 400;
			}
			padding: 0;
		}
	}
	.invite-person-form {
		display: flex;
		flex: 0 0 auto;
		flex-direction: column;
		.invite-person-title {
			font-size: 12px;
			font-weight: 600;
			margin-top: 24px;
		}
		.inputs-row {
			display: flex;
			flex: 0 0 auto;
			flex-direction: row;
			justify-content: space-between;
			margin-top: 24px;
			width: 100%;
			&.first-row {
				margin-top: 16px;
			}
			.input-container-v2 {
				width: 224px;
			}
		}
		.buttons-wrapper {
			display: flex;
			justify-content: flex-end;
			width: 100%;
			max-width: 456px;
			margin-top: 10px;
			.seats-info {
				flex: 1 1 0px;
				font-size: 12px;
				font-weight: bold;
				color: $v2-text-gray;
				margin-right: 15px;
				margin-top: 10px;
			}
			.buttons {
				flex: 0 0 auto;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				margin-top: 10px;
			}
		}
		.custom-button-container {
			height: 32px;
			width: 71px;
			margin-left: 5px;
		}
	}
	.selected-count {
		font-size: 10px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.1;
		letter-spacing: 1.1px;
		color: $v2-text-light-gray;
		text-transform: uppercase;
		margin-top: 24px;
	}
	.team-members-select {
		display: flex;
		flex-direction: column;
		//height: auto;
		//width: 456px;
		ul {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			padding: 0;
		}
		li {
			list-style: none;
			//width: 456px;
		}
		.invited-person-list-option,
		.person-list-option {
			display: flex;
			flex-direction: row;
			//flex: 0 0 456px;
			justify-content: space-between;
			padding: 6px;
			//border-top: solid 1px $app-border-color;
			//border-left: solid 1px $app-border-color;
			//border-right: solid 1px $app-border-color;
			&.last-option {
				//border-bottom: solid 1px $app-border-color;
				margin-bottom: 16px;
				border-bottom: solid 1px $drop-color;
			}
			.remove-selected-button {
				border: none;
				width: 11px;
				height: 11px;
				background-size: 11px;
				background-image: url('images/components/close-modal.svg');
				background-repeat: no-repeat;
				visibility: hidden;
				margin-bottom: 4px;
				margin-top: 6px;
				margin-right: 4px;
			}
			&:hover {
				.remove-selected-button {
					visibility: visible;
				}
			}
		}
		.invited-person-list-option {
			.inviteed-person-details-wrapper {
				font-size: 13px;
				letter-spacing: 0.5px;
				color: $v2-text-gray;
				display: flex;
				flex: 1 1 auto;
				align-items: center;
				.email {
					font-weight: 500;
				}
				.oval {
					width: 6px;
					height: 6px;
					background-color: $v2-text-gray;
					border-radius: 50%;
					margin-left: 8px;
				}
				.pending {
					margin-left: 12px;
				}
			}
		}
		.person-list-option {
			.remove-selected-button {
				margin-top: 6px;
			}
			.person-details-wrapper {
				display: flex;
				flex: 1 1 auto;
				max-width: 250px;
				align-items: center;

				.team-member-name-email {
					display: flex;
					flex-direction: column;
					margin-left: 16px;
					.team-member-name {
						font-size: 12px;
						color: $v2-text-gray;
						font-weight: normal;
						font-style: normal;
						font-stretch: normal;
						line-height: normal;
						letter-spacing: 0.4px;
						font-weight: 500;
					}
					.team-member-email {
						font-size: 11px;
						letter-spacing: 0.5px;
						color: $v2-text-light-gray;
					}
				}
			}
		}
	}
}
