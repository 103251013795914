.task-outside-project-period-modal {
	.tasks_description {
		font-weight: 500;
		font-style: italic;
	}

	@include scrollable-table;
	.scrollable-table {
		margin-top: 32px;

		.column-task {
			width: 336px;
			padding-left: 16px;
			padding-right: 16px;
		}

		.column-date {
			width: 236px;
			padding-left: 16px;
			padding-right: 16px;
		}

		.rows {
			border: 0;

			.row {
				border-bottom: 0;
				height: 52px;
			}

			.column-task,
			.column-date {
				border-bottom: 1px solid #eaeaea;
				height: 52px;
				padding-top: 5px;
				padding-bottom: 5px;
			}

			.row:first-child {
				.column-task,
				.column-date {
					border-top: 1px solid #eaeaea;
				}
			}

			.column-task {
				border-left: 1px solid #eaeaea;
				padding-top: 16px;
				overflow-x: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}

			.column-last {
				height: 52px;

				.chip-container {
					height: 100%;
					float: right;
					overflow: hidden;
					position: relative;
					.chip-div {
						width: 18px;
						height: 100%;
						border-right: solid 1px $v2-table-border-color;
						border-bottom: solid 1px $v2-table-border-color;
					}
					.chip {
						border-bottom: solid 1px $v2-table-border-color;
						content: '';
						position: absolute;
						height: 20px;
						width: 22px;
						display: block;
						top: -13px;
						right: -6px;
						background-color: white;
						transform: rotate(30deg);
						&.has-phase {
							background-color: #f3f3f3;
						}
					}
				}
			}
		}
	}
}
