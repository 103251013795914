.overview-time {
	padding: 40px;
	display: flex;
	flex-direction: column;
	height: 100%;
	.top-menu {
		.main-menu {
			display: flex;
			flex-direction: row;
			align-items: center;
			.select-person-header {
				margin-right: 8px;
			}
			.date {
				margin-left: auto;
			}
			.actions-placeholder {
				width: 120px;
				height: 55px;
			}
			.forecast-actions {
				flex: 0 0 auto;
				.action {
					&.search {
						> button {
							margin-left: 8px;
						}
					}
					&.new-time-reg {
						> button {
							background-image: url('images/sections/overview/add-time-reg.svg');
						}
						.new-time-row {
							display: flex;
							flex-direction: row;
							align-items: center;
							.multi-level-select {
								margin-right: 24px;
								width: 300px;
								z-index: 5;
							}
							.add-input {
								border: 1px solid $app-border-color;
								border-radius: 3px;
								background-color: white;
								height: 37px;
								margin-right: 24px;
								width: 60px;
								input {
									margin-top: 2px;
									outline: 0;
								}
							}
							.add-button {
								@include primary-button;
								width: inherit;
								margin-left: 0;
							}
						}
					}
				}
			}
			.change-date-button {
				height: 40px;
				width: 40px;
				background-size: 40px;
				border: none;
				&.previous-date {
					background-image: url('images/chevrons/chevron_left_grey.svg');
					margin-left: 16px;
					&:hover {
						background-image: url('images/chevrons/chevron_left_black.svg');
					}
					&:active {
						background-image: url('images/chevrons/chevron_left_purple.svg');
					}
				}
				&.next-date {
					background-image: url('images/chevrons/chevron_right_grey.svg');
					&:hover {
						background-image: url('images/chevrons/chevron_right_black.svg');
					}
					&:active {
						background-image: url('images/chevrons/chevron_right_purple.svg');
					}
				}
			}
			.today-button {
				@include white-button;
				margin-right: 40px;
				margin-left: 40px;
			}
			.tab-container {
				display: flex;
				flex-direction: row;
				.tab {
					background-color: $color-white;
					padding: 8px;
					border: 1px solid $app-border-color;
					width: 100px;
					text-align: center;
					cursor: pointer;
					//transition: background-color 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
					outline: 0;
					&:hover,
					&:focus {
						background-color: $btn-white-active;
					}
					&.active {
						color: $color-white;
						background-color: $brand-color;
						cursor: default;
					}
					&.tab-left {
						border-top-left-radius: 5px;
						border-bottom-left-radius: 5px;
					}
					&.tab-center {
						border-left: none;
						border-right: none;
					}
					&.tab-right {
						border-top-right-radius: 5px;
						border-bottom-right-radius: 5px;
					}
				}
			}
		}
		.sub-menu {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			margin-top: 2px;
			height: 38px;
			.economic-button {
				@include primary-button;
				margin-right: 1px;
			}
		}
	}

	.view-container {
		flex-grow: 1;
		display: flex;
	}
}
