.phases-header-v2 {
	position: relative;
	cursor: pointer;
	//max-width: 100%;
	.overlay {
		width: 100%;
		height: 100%;
		position: absolute;
		background-color: transparent;
	}
	&:hover > .overlay {
		background-color: #000;
		opacity: 0.1;
	}
	.elem-wrapper {
		position: relative;
		.done-percentage-tile {
			font-size: 14px;
		}
		.phase-header-data-entry {
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			//border-right: 1px solid red;
			//border-left: 1px solid red;
			margin: 0;
			padding: 0;
		}
		input {
			font-weight: 700;
			font-size: 14px;
			padding: 5px;
			width: 100%;
			max-width: 500px;
			padding-left: 0;
			border: solid 1px transparent;
			border-radius: 4px;
			cursor: text;
			&:hover {
				background-color: rgba(0, 0, 0, 0.1);
			}
			&:focus {
				outline: none;
				background-color: rgba(0, 0, 0, 0.2);
			}
			&:disabled {
				cursor: default;
				&:hover {
					background: none;
					background-color: none !important;
				}
			}
		}
		.disabled:hover {
			background-color: transparent !important;
		}
		.phase-deadline-select {
			width: 100%;
		}
	}
	.phase-header-controls {
		display: flex;
		flex-direction: row;
		height: 100%;
		width: 100%;
	}
	.phase-header-data-entry {
		font-size: 14px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: right;
	}
	.project-phases-header {
		position: relative;
		.filters {
			margin-left: auto;
			.filter {
				background-position: 20px center;
				background-repeat: no-repeat;
				cursor: pointer;
				font-weight: $base-font-medium-weight;

				color: $color-light-black;

				&:hover {
					color: $color-purple-hover-active;
				}

				&.load-breakdown {
					background-image: url('images/sections/project/phases/load.svg');
					border-left: 1px solid $app-border-color;
					border-right: 1px solid $app-border-color;
				}

				&.save-breakdown {
					background-image: url('images/sections/project/phases/save.svg');
					border-right: 1px solid $app-border-color;
				}

				&.add-phase {
					//margin-left: auto;
					padding: 13px 20px 0px 55px;
					border-left: 1px solid $app-border-color;
					background-image: url('images/sections/project/phases/phase.svg');
				}
			}
		}
	}

	display: flex;
	flex-direction: row;
	align-items: center;
	height: 40px;
	background-color: rgb(var(--project-color-r), var(--project-color-g), var(--project-color-b));
	background: linear-gradient(
		to right,
		rgb(var(--project-color-r), var(--project-color-g), var(--project-color-b)) var(--progress),
		rgba(var(--project-color-r), var(--project-color-g), var(--project-color-b), 0.7) var(--progress)
	);
	color: $color-white;
	border-top: 10px;
	border: 10px;

	&.no-phase {
		background-color: $color-white;
		color: $color-black;
		border-bottom: solid 1px $v2-grey;
		.phase-header-data-entry {
			span {
				color: $color-black;
			}
		}
		.chip-container,
		.chip-div {
			opacity: 0;
			border: none;
		}
	}

	.progress {
		height: 100%;
		&.completed {
			background-color: $color-black;
		}
		&.remaining {
			background-color: $color-white;
		}
	}
	.title {
		flex-grow: 1;
		.editable {
			display: flex;
			align-items: center;
			height: 40px;

			.phase-none {
				font-weight: 700;
				font-size: 14px;
				padding: 5px;
				margin: 0;
			}
			input {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			.backlog-action-menu {
				display: flex;
				position: relative;
				.new-ui-actions-menu {
					position: relative;
					transform: none;
					left: 0;
				}
			}
		}
	}
	.date-picker {
		flex-grow: 1;
		display: flex;
		width: 100%;
		height: 100%;
		span {
			font-size: 14px;
		}
		.calendar-wrapper {
			top: 45px;
		}
		.input-locked {
			border: 1px solid transparent;
		}
		.input {
			&:hover {
				border: 1px solid transparent;
				border-color: #cdcfd2;
				transition: border-color 150ms;
			}
		}
		.date-input,
		.date-input-locked,
		.date-input-focused {
			font-weight: 700;
			border-radius: 3px;
			background: none;
		}
		.date-input-focused {
			border: 1px solid #cdcfd2;
			transition: border-color 150ms;
		}
	}
	.chip-container {
		height: 100%;
		float: right;
		overflow: hidden;
		position: relative;
		min-width: 26px;
		width: 26px;
		max-width: 26px;
		.chip-div {
			width: 18px;
			height: 100%;
		}
		.chip {
			border-bottom: solid 1px $v2-table-border-color;
			content: '';
			position: absolute;
			height: 20px;
			width: 22px;
			display: block;
			top: -13px;
			background-color: white;
			&.right {
				right: -6px;
				transform: rotate(30deg);
			}
			&.left {
				left: -6px;
				transform: rotate(330deg);
			}
		}
	}
	h3 {
		font-size: 18px;
		padding-top: 10px;
		margin: 0;
	}
	.new-ui-actions-menu {
		position: absolute;
		top: 50%;
		left: 70%;
		transform: translate(-50%, -50%);
		button.actions.white-inner {
			&:hover,
			&.expanded,
			&:focus {
				background-color: rgba(0, 0, 0, 0.2);
				background-image: url('images/components/overlay/navigation-show-more-vertical-white.svg');
			}
		}
	}
	.sprint-goals-wrapper {
		display: flex;
		position: absolute;
		top: 0;
		left: 90%;
		border-radius: 4px;
		padding: 2px;
		margin-top: 6px;
		z-index: 1;
		border: none;
		&:hover, &:focus {
			background-color: rgba(0, 0, 0, 0.2);
			&.disabled {
				background-color: transparent;
			}
		}
	}
	.description-button-wrapper {
		position: absolute;
		left: 90%;
	}
	.progress-controls-wrapper {
		display: flex;
		flex-direction: row;
		position: absolute;
		top: 50%;
		left: 35%;
		transform: translate(-50%, -50%);
		.prompt {
			display: flex;
			flex-direction: row;
			margin-left: 15px;
			cursor: pointer;
		}
		.expand-control {
			height: 24px;
			width: 24px;
			cursor: pointer;
			&.expand {
				background: url('images/v2/components/dropdown/arrow-down-white.svg') no-repeat center;
			}

			&.collapse {
				background: url('images/v2/components/dropdown/arrow-up-white.svg') no-repeat center;
			}
		}
		.action-menu {
			cursor: pointer;
			margin: 8px 0 0 20px;
			height: 24px;
			width: 24px;
			background: url('images/components/overlay/navigation-show-more-vertical.svg') no-repeat;
			background-size: contain;
			flex-shrink: 0;
			&:focus {
				outline: 0;
			}
			.action-dropdown {
				@include actions-dropdown;
			}
		}
	}
	.data-tile {
		position: relative;
		flex-grow: 1;
		span {
			white-space: nowrap;
			width: 100%;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	&.black {
		div,
		input,
		span {
			color: $color-black;
		}
		.expand-control {
			&.expand {
				background: url('images/v2/components/dropdown/arrow-down.svg') no-repeat center;
			}

			&.collapse {
				background: url('images/v2/components/dropdown/arrow-up.svg') no-repeat center;
			}
		}
	}
	&.white {
		div,
		input,
		span {
			color: $color-white;
		}
		.phase-none {
			color: $color-black;
		}
		&.done {
			div,
			input,
			span {
				color: rgba(255, 255, 255, 0.6);
			}
		}
	}
	&.done {
		div,
		input,
		span {
			color: rgba(0, 0, 0, 0.6);
		}
	}
	.phases-header-integration-logo {
		width: 24px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: white;
		border-radius: 50%;
		margin-right: 4px;
		padding: 2px;
	}
}
