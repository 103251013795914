@import '../scheduling/people_scheduling';
@import '../scheduling/project_scheduling_v2';

.root-portal-container {
	ul.scheduling-actions-dropdown {
		position: absolute;
		z-index: 999;
		border-radius: 4px;
		background-color: white;
		margin: 0;
		padding: 0;
		list-style: none;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
		li {
			cursor: pointer;
			font-size: 12px;
			font-weight: 500;
			padding: 6px 10px 6px 10px;
			white-space: nowrap;
			outline: none;
			color: $v2-text-gray;

			&.locked-option {
				display: flex;
				flex-direction: row;
				align-items: center;
				color: $v2-text-light-gray;
				&:hover,
				&:focus {
					color: $v2-text-light-gray;
					background-color: white;
				}
			}

			&:hover,
			&:focus {
				color: $v2-branding-purple;
				background-color: rgba(110, 15, 234, 0.1);
			}
		}
	}
}

.scheduling-create-new-modal {
	width: 632px;
	.scheduling-create-new-modal-content {
		margin-top: 24px;
		.drop-down-v2,
		.dropdown-container {
			margin-bottom: 24px;
			width: 100%;
		}
		.tabs-container {
			margin-bottom: 24px;
			display: flex;
			flex-direction: row;
			letter-spacing: 0.5px;
			text-align: center;
			color: #a1a1a1;
			font-size: 11px;
			line-height: 40px;
			font-weight: bold;
			cursor: pointer;
			.tab {
				height: 40px;
				border-radius: 3px;
				background-color: #f3f3f3;
				flex: 1 0 0px;
				&.selected {
					color: white;
					background-color: $v2-branding-purple;
				}
			}
		}
		.task-data-row {
			display: flex;
			flex-direction: row;
			margin-top: 24px;
			height: 66px;
			.input-container-v2 {
				margin-left: 8px;
				flex-shrink: 0;
				width: 120px;
			}
			.person-dropdown-container {
				flex-grow: 1;
			}
			> div:not(:first-child) {
				margin-left: 8px;
			}
		}
		.dates-phase-row {
			display: flex;
			flex-direction: row;
			margin-bottom: 24px;
			.date-range-picker-wrapper-new {
				width: 248px;
			}
			.drop-down-v2 {
				margin-bottom: 0;
				width: 260px;
				margin-right: 8px;
			}
		}
	}
}
