.demo-data-wrapper {
    padding-top: 20px;
    padding-bottom: 40px;
}

.demo-table-wrapper table {
    width:100% !important;
    tbody tr:hover {
        color: inherit !important;
    }
    tbody tr {
        .name:hover {
            color:#6f49c5;
        }
        .delete:hover {
            color:$color-red
        }
        .trial,.shared {
            font-weight: bold;;
        }
    }
    tbody tr td {
        padding: 0.625rem;
        text-align: left;
        width:33%;  
    }

    .name, .delete {
        cursor: pointer;
    }
}