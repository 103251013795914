.settings-app-slack {
	a {
		cursor: pointer;
	}
	.section-body {
		button {
			@include primary-button;
		}
	}
}
