.copy-time-modal {
	width: 688px;
	min-height: 339px;
	max-height: 628px;

	border-spacing: 0 1px;
	border-collapse: separate;
	font-size: 12px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.58;
	letter-spacing: normal;
	.copy-time-registrations {
		width: 572px;
		min-height: 88px;
	}
	.custom-scrollbar-div {
		width: 110% !important;
		div {
			scrollbar-width: none;
			-ms-overflow-style: none;
		}
		div::-webkit-scrollbar {
			width: 0 !important;
		}
	}

	.table-container {
		width: 572px;
	}
	table {
		color: #535353;
		min-width: 100%;
		margin-bottom: 10px;
		th {
			text-align: left;
			position: sticky;
			color: $v2-text-light-gray;
			top: 0px;
			background: white;
			padding-bottom: 8px;
			z-index: 1;
		}
		tr {
			height: 35px;
		}
		tr.alternating:nth-child(odd) {
			background-color: #f3f3f3;
		}
		tr.idle {
			height: 31px;
			background-color: #f3f3f3;
			border-bottom: 5px solid #ffffff;
		}
		td {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			padding-bottom: 5px;
		}
		.copy-time-project-header {
			padding-left: 16px;
		}
		.copy-time-hours-header {
			text-align: right;
			padding-right: 16px;
		}
		.copy-time-project-cell {
			padding-top: 8px;
			padding-right: 16px;
			padding-left: 16px;
			display: flex;
			max-width: 180px;

			.project-indicator {
				display: flex;
				justify-content: center;
				align-items: center;
				min-width: 42px;
				max-width: 50px;
				overflow: hidden;
				height: 20px;
				border-radius: 3px;
				margin-right: 8px;
				.project-indicator-text {
					margin-right: 2px;
					margin-left: 2px;
					font-size: 10px;
				}
			}
			.project-name {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
		.copy-time-task-cell {
			padding-top: 8px;
			padding-right: 16px;
			max-width: 295px;
		}
		.copy-time-hours-cell {
			padding-top: 8px;
			padding-right: 16px;
			text-align: right;
			font-weight: bold;
			max-width: 42px;
		}
	}
	.spacer {
		background-color: #f3f3f3;
		height: 1px;
		min-width: 100%;
		margin-bottom: 10px;
	}
	.date-picker-title {
		color: $v2-text-light-gray;
		margin-top: 20px;
		font-weight: bold;
	}
	.no-time-to-copy {
		color: $v2-text-gray;
		font-weight: bold;
		font-size: 14px;
		font-style: italic;
	}
}
