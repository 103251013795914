.date-picker-wrapper-new {
	position: relative;
	.calendar-wrapper {
		position: absolute;
		top: 66px;
		z-index: 10;
		display: flex;
		flex-direction: column;
		background-color: white;
		padding: 32px;
		box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
		border: 1px solid $app-border-color;
		&.clearable {
			padding-bottom: 68px;
		}
		.triangle-up {
			position: absolute;
			top: -14px;
			left: 46px;
			.border-cover {
				background-color: white;
				height: 1px;
				width: 22px;
				position: absolute;
				bottom: 4px;
				left: 1px;
				z-index: 2;
			}
		}
		.clear-button {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 36px;
			border: none;
			border-top: 1px solid $app-border-color;
			line-height: 36px;
			text-align: center;
			font-size: 12px;
			font-weight: 500;
		}
	}
}
