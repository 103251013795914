.canvas-timeline-component {
	flex-grow: 1;
	display: flex;
	flex-direction: row;
	background-color: white;
	position: relative;
	overflow: hidden;

	#timeline-loading-overlay {
		position: absolute;
		z-index: 3;
	}

	.scroll-click-icon-container {
		position: fixed;
		background-color: white;
		border: 1px solid $app-border-color;
		border-radius: 50%;
		width: 32px;
		height: 32px;
		margin-left: -16px;
		margin-top: -16px;
		z-index: 3;
		.scroll-click-icon {
			width: 100%;
			height: 100%;
			background-size: 22px;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url('images/components/timeline/scroll-click-icon.png');
		}
	}
	.canvas-timeline-section-splitter {
		flex-shrink: 0;
		background-color: $color-martin-timeline-background;
		cursor: ns-resize;
		border-top: 1px solid $color-martin-timeline-border;
		border-bottom: 1px solid $color-martin-timeline-border;
		z-index: 3;
		width: 100%;
		position: absolute;

		&::before,
		&::after {
			content: '';
			display: block;
			border: 1px solid #cecbd3;
			width: 14px;
			margin: 2px auto;
		}
	}
	.group-section {
		.top-left-container {
			height: 56px;
			overflow: hidden;
		}
	}
	.main-section {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		position: relative;
		.canvas-timeline-horizontal-scrollbar-container {
			flex: 0 0 24px;
			position: relative;
			.scroll-thumb {
				position: absolute;
				top: 6px;
				left: 0;
				height: 12px;
				background-color: #808080;
				cursor: pointer;
				transition: background-color 0.2s;
				border-radius: 2px;
				&.scrolling {
					background-color: #595959;
				}
				&.not-scrolling {
					transition: all 0.2s;
				}
				&:hover {
					background-color: #595959;
				}
			}
		}
		.canvas-section {
			display: flex;
			flex-grow: 1;
			position: relative;
			#background-canvas {
				position: absolute;
				z-index: 1;
			}
			#foreground-canvas {
				position: absolute;
				z-index: 2;
			}
		}
	}
	.canvas-timeline-horizontal-scrollbar-container {
		flex-shrink: 0;
		width: 24px;
		position: relative;
		.scroll-thumb {
			position: absolute;
			left: 6px;
			width: 12px;
			background-color: #808080;
			cursor: pointer;
			transition: background-color 0.2s;
			border-radius: 2px;
			&.scrolling {
				background-color: #595959;
			}
			&:hover {
				background-color: #595959;
			}
		}
	}
	#post-processing-canvas {
		position: absolute;
		left: 0;
		z-index: 3;
		pointer-events: none;
	}
}

.canvas-timeline-expand-all-button-container {
	.expansion-button {
		width: 50px;
		height: 100%;
		border: none;
		background-image: url('images/components/scheduling/expand-all.svg');
		background-size: 12px;
		background-position: center;
		background-repeat: no-repeat;
		border-left: 1px solid $color-martin-timeline-border;
		border-right: 1px solid $color-martin-timeline-border;
		&.collapse {
			background-image: url('images/components/scheduling/collapse-all.svg');
		}
	}
}

.canvas-timeline-zoom-menu {
	position: relative;
	display: flex;
	flex-direction: row;
	background-color: white;
	height: 30px;
	align-items: center;
	min-width: 100px;
	outline: 0;
	line-height: 30px;
	user-select: none;
	.zoom-button {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		height: 30px;
		width: 30px;
		position: relative;
		flex-shrink: 0;
		border: 1px solid $app-border-color;
		outline: 0;
		&:active,
		&:focus,
		&:hover {
			border: 1px solid $v2-button-very-light-grey-hovered;
		}
	}
}