.asana-projects-list-container {
	display: flex;
	flex-direction: column;
	padding-bottom: 20px;
	height: 300px;

	hr {
		width: 95%;
	}

	.custom-scrollbar-div {
		width: 100%;
	}

	.asana-project-row {
		display: flex;
		flex-direction: row;
		padding: 8px 8px 5px;
	}
}

.asana-button-container {
	display: flex;
	flex-direction: row;

	button {
		width: 100px;
		height: 40px;
	}
}

.asana-fieldset {
	.asana-projects-header {
		font-size: 14px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.29;
		letter-spacing: normal;
		color: $v2-text-gray;
	}
}

.asana-buttons {
	display: flex;
	flex-direction: row;
	align-items: center;
	button {
		margin-right: 16px;
	}
	.help-button {
		margin-left: 0;
	}
	.stopped-refresh-text {
		color: red;
		font-weight: bold;
	}
}
