.meeting-creator {
	position: relative;
	&.v1 {
		button {
			@include primary-button;
		}
	}

	.meeting-export-options {
		width: 160px;
		position: absolute;
		top: 40px;
		z-index: 2;
		left: 0px;
		background-color: white;
		box-shadow: 2px 2px 6px 2px $v2-text-light-gray;

		div {
			height: 30px;
			padding-left: 5px;
			padding-top: 5px;
			cursor: pointer;

			&:hover {
				color: $v2-branding-purple;
			}
		}
	}
}
