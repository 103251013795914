.editor {
	box-sizing: border-box;
	border: 1px solid $app-border-color;
	cursor: text;
	padding: 8px;
	border-radius: 2px;
	margin-bottom: 10px;
	.draftJsEmojiPlugin__emojiSelect__34S1B {
		position: relative;
		.draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
			position: absolute;
			left: -260px;
		}
		.draftJsEmojiPlugin__emojiSelectButton__3sPol,
		.draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu {
			width: 2em;
			height: 1.25em;
		}
	}

	.modifiers {
		display: none;
	}

	.public-DraftEditorPlaceholder-inner {
		position: absolute;
		color: $input-placeholder-color;
	}

	.public-DraftEditorPlaceholder-root {
		position: relative;
	}

	.public-DraftEditorPlaceholder-hasFocus {
		.public-DraftEditorPlaceholder-inner {
			color: $input-placeholder-color-focus;
		}
	}

	.controls {
		cursor: pointer;
		float: left;
		height: 19px;
		width: 19px;
		margin-left: 15px;
		&.ol {
			background-image: url('../src/images/components/draft/ol.svg');
		}
		&.ul {
			background-image: url('../src/images/components/draft/ul.svg');
		}
		&.bold {
			background-image: url('../src/images/components/draft/bold.svg');
		}
		&.italic {
			background-image: url('../src/images/components/draft/italic.svg');
		}
		&.strikethrough {
			background-image: url('../src/images/components/draft/strikethrough.svg');
		}
		&.underline {
			background-image: url('../src/images/components/draft/underline.svg');
		}
		//TODO change imgs for active controls
		&.ol-active {
			background-image: url('../src/images/components/draft/ol.svg');
		}
		&.ul-active {
			background-image: url('../src/images/components/draft/ul.svg');
		}
		&.bold-active {
			background-image: url('../src/images/components/draft/bold.svg');
		}
		&.italic-active {
			background-image: url('../src/images/components/draft/italic.svg');
		}
		&.strikethrough-active {
			background-image: url('../src/images/components/draft/strikethrough.svg');
		}
		&.underline-active {
			background-image: url('../src/images/components/draft/underline.svg');
		}
		background-size: 19px;
	}

	:global(.public-DraftEditor-content) {
		min-height: 80px;
	}

	&.client-notes-editor {
		.public-DraftEditor-content {
			min-height: 300px;
			max-height: 300px;
		}
	}

	.RichEditor-controls {
		display: inline-block;
		.active {
			background-color: $color-medium-gray;
		}
	}

	.editor-wrapper {
		overflow: auto;
		&.fixed-size {
			max-height: 80px;
		}
	}

	.editor-controls {
		padding-top: 5px;
		border-top: 1px solid #ababab;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.editor-controls {
		padding-top: 5px;
		border-top: 1px solid #ababab;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.RichEditor-activeButton {
		color: #9ec1e8;
	}

	.RichEditor-activeButton {
		color: #9ec1e8;
	}

	//Emoji plugin
	.draftJsEmojiPlugin__emoji__2oqBk {
		background-position: center;
		/* make sure the background the image is only shown once */
		background-repeat: no-repeat;
		background-size: contain;
		/* move it a bit further down to align it nicer with the text */
		vertical-align: middle;

		/*
    We need to limit the emoji width because it can be multiple characters
    long if it is a 32bit unicode. Since the proper width depends on the font and
    it's relationship between 0 and other characters it's not ideal. 1.95ch is not
    the best value, but hopefully a good enough approximation for most fonts.
    */
		display: inline-block;
		overflow: hidden;
		max-width: 1.95ch;
		/*
    Needed for iOS rendering to avoid some icons using a lot of height without
    actually needing it.
    */
		max-height: 1em;
		line-height: inherit;
	}

	.draftJsEmojiPlugin__emojiCharacter__osX-n {
		/*
    Hide the original Emoji icon no matter what system it is. color: transparent
    would have been nice to avoid the extra span, but it doesn't work on iOS.
    */
		opacity: 0;
	}
	.draftJsEmojiPlugin__emojiSuggestionsEntry__2-2p_ {
		padding: 5px 10px 1px 10px;
		-webkit-transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
		transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
	}

	.draftJsEmojiPlugin__emojiSuggestionsEntry__2-2p_:active {
		background-color: #cce7ff;
	}

	.draftJsEmojiPlugin__emojiSuggestionsEntryFocused__XDntY {
		background-color: #e6f3ff;
	}

	.draftJsEmojiPlugin__emojiSuggestionsEntryText__2sPjk {
		display: inline-block;
		margin-left: 8px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 368px;
		font-size: 0.9em;
	}

	.draftJsEmojiPlugin__emojiSuggestionsEntryIcon__1qC2V {
		width: 1em;
		height: 1em;
		margin-left: 0.25em;
		margin-right: 0.25em;
		display: inline-block;
	}
	.draftJsEmojiPlugin__emojiSuggestions__2ffcV {
		border: 1px solid #eee;
		margin-top: 1.75em;
		position: absolute;
		min-width: 220px;
		max-width: 440px;
		background: #fff;
		border-radius: 2px;
		box-shadow: 0px 4px 30px 0px rgba(220, 220, 220, 1);
		cursor: pointer;
		padding-top: 8px;
		padding-bottom: 8px;
		z-index: 2;
		display: -webkit-box;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		flex-direction: column;
		box-sizing: border-box;
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	//Hashtag plugin

	.draftJsHashtagPlugin__hashtag__1wMVC {
		color: #5e93c5;
	}

	//Linkify plugin

	.draftJsLinkifyPlugin__link__2ittM,
	.draftJsLinkifyPlugin__link__2ittM:visited {
		color: #5e93c5;
		text-decoration: none;
	}

	.draftJsLinkifyPlugin__link__2ittM:hover,
	.draftJsLinkifyPlugin__link__2ittM:focus {
		color: #7eadda;
		outline: 0; /* reset for :focus */
		cursor: pointer;
	}

	.draftJsLinkifyPlugin__link__2ittM:active {
		color: #4a7bab;
	}

	//Mention plugin

	.draftJsMentionPlugin__mention__29BEd,
	.draftJsMentionPlugin__mention__29BEd:visited {
		color: #575f67;
		cursor: pointer;
		display: inline-block;
		background: #e6f3ff;
		padding-left: 2px;
		padding-right: 2px;
		border-radius: 2px;
		text-decoration: none;
	}

	.draftJsMentionPlugin__mention__29BEd:hover,
	.draftJsMentionPlugin__mention__29BEd:focus {
		color: #677584;
		background: #edf5fd;
		outline: 0; /* reset for :focus */
	}

	.draftJsMentionPlugin__mention__29BEd:active {
		color: #222;
		background: #455261;
	}
	.draftJsMentionPlugin__mentionSuggestionsEntry__3mSwm {
		padding: 7px 10px 3px 10px;
		-webkit-transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
		transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
	}

	.draftJsMentionPlugin__mentionSuggestionsEntry__3mSwm:active {
		background-color: #cce7ff;
	}

	.draftJsMentionPlugin__mentionSuggestionsEntryFocused__3LcTd {
		background-color: #e6f3ff;
	}

	.draftJsMentionPlugin__mentionSuggestionsEntryText__3Jobq {
		display: inline-block;
		margin-left: 8px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 368px;
		font-size: 0.9em;
		margin-bottom: 0.2em;
	}

	.draftJsMentionPlugin__mentionSuggestionsEntryAvatar__1xgA9 {
		display: inline-block;
		width: 24px;
		height: 24px;
		border-radius: 12px;
	}
	.draftJsMentionPlugin__mentionSuggestions__2DWjA {
		border: 1px solid #eee;
		margin-top: 1.75em;
		position: absolute;
		min-width: 220px;
		max-width: 440px;
		background: #fff;
		border-radius: 2px;
		box-shadow: 0px 4px 30px 0px rgba(220, 220, 220, 1);
		cursor: pointer;
		padding-top: 8px;
		padding-bottom: 8px;
		z-index: 2;
		display: -webkit-box;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		flex-direction: column;
		box-sizing: border-box;
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	//styles for the emoji picker

	.emoji-picker-container {
		width: auto;
		height: auto;
		position: absolute;
		z-index: 1;
	}

	.emoji-button {
		cursor: pointer;
		float: right;
		width: 19px;
		height: 19px;
		background-image: url('images/components/draft/emoji.png');
		background-size: 19px;
	}

	.emoji-dialog.with-search {
		width: 276px;
		height: 220px;
		background: #fff;
		box-sizing: border-box;
		border-radius: 2px;
		overflow: hidden;
		position: absolute;
		z-index: 1;
		box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 15px rgba(0, 0, 0, 0.1);
		cursor: pointer;
	}

	.emoji-dialog .emoji-dialog-header {
		box-sizing: border-box;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 42px;
		padding: 0 10px;
		background-color: #f5f7f9;
	}

	.emoji-dialog .emoji-dialog-header ul {
		padding: 0;
		margin: 0;
		list-style: none;
	}

	.emoji-dialog .emoji-dialog-header li {
		display: inline-block;
		box-sizing: border-box;
		width: 32px;
		height: 42px;
		padding: 9px 4px;
	}

	.emoji-dialog .emoji-dialog-header img {
		width: 22px;
		height: 22px;
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
	}

	.emoji-dialog .emoji-dialog-header li.active {
		background: #fff;
	}

	.emoji-dialog .emoji-dialog-header li.active img {
		-webkit-filter: grayscale(0);
		filter: grayscale(0);
	}

	.emoji-dialog .emoji-grandlist {
		position: absolute;
		top: 42px;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 10px;
		box-sizing: border-box;
		width: 100%;
		height: calc(100% - 42px);
		overflow-x: hidden;
		overflow-y: auto;
	}

	.emoji-dialog .emoji-category-list {
		padding: 0;
		margin: 0;
		list-style: none;
	}

	.emoji-dialog .emoji-category-list li {
		display: inline-block;
		box-sizing: border-box;
		width: 32px;
		height: 32px;
		padding: 4px;
		border-radius: 4px;
	}

	.emoji-dialog .emoji-category-header {
		margin-top: 10px;
		margin-bottom: 8px;
		font-size: 18px;
		font-family: sans-serif;
		font-weight: normal;
		color: #444;
		padding: 0 4px;
	}

	.emoji-dialog .modifiers {
		list-style: none;
		padding: 0;
		margin: 0;
		position: absolute;
		top: 22px;
		right: 15px;
	}

	.emoji-dialog.with-search .modifiers {
		top: 52px;
	}

	.emoji-dialog .modifiers li {
		display: inline-block;
		padding: 0 2px;
	}

	.emoji-dialog .modifiers .modifier {
		display: inline-block;
		border-radius: 10px;
		width: 15px;
		height: 15px;
		position: relative;
	}

	.emoji-dialog .modifiers .modifier.active::after {
		content: '';
		display: block;
		position: absolute;
		width: 7px;
		height: 7px;
		border-radius: 10px;
		border: 2px solid #fff;
		top: 2px;
		left: 2px;
	}

	.emoji-dialog .modifiers li:hover {
		-moz-transition-duration: 200ms;
		-webkit-transition-duration: 200ms;
		transition-duration: 200ms;

		-webkit-transform: scale(1.2);
		-moz-transform: scale(1.2);
		transform: scale(1.2);
	}

	.emoji-dialog .emoji-search-wrapper {
		padding: 0 5px;
	}

	.emoji-dialog .emoji-search {
		font-size: 12px;
		padding: 6px 4px;
		width: 100%;
		border: 1px solid #ddd;
		border-radius: 4px;
	}

	.emoji-dialog .emoji-category-list li:hover {
		background: #f5f7f9;
	}

	.emoji-dialog .emoji-category-list .emoji img {
		width: 22px;
		height: 22px;
	}

	&.hide-controls {
		.controls {
			display: none;
		}
		.editor-controls {
			display: none;
		}
		.char-counter {
			display: none;
		}

		border: 1px solid transparent;
	}

	&:hover {
		border: 1px solid $app-border-color;
		transition: border-color 150ms;
		&.readOnly {
			border: 1px solid transparent;
		}
	}
	&.visible-border {
		border: 1px solid $app-border-color;
		&:hover {
			border: 1px solid $app-border-color;
		}
	}
	// Code block Styling
	.DraftEditor-editorContainer .public-DraftStyleDefault-pre {
		background-color: rgba(0, 0, 0, 0.05);
		font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
		font-size: 16px;
		padding: 20px;
	}
}
