.rich-text-field-container {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	.rich-text-field-label-wrapper {
		display: flex;
		height: 11px;
		font-family: $text-font-family;
		font-size: 10px;
		font-weight: 500;
		line-height: 1.1;
		letter-spacing: 1.1px;
		margin-bottom: 5px;
		text-transform: uppercase;
		.rich-text-field-label {
			flex: 1 1 0px;
			min-width: 0px;
			height: 11px;
			color: $v2-text-light-gray;
		}
		.rich-text-field-label-link {
			flex: 0 0 auto;
			height: 11px;
			color: $v2-branding-purple;
			text-decoration: none;
		}
	}

	.rich-text-field-input-container {
		border: solid 1px $app-border-color;
		background-color: $color-white;
		border-radius: 4px;
		background-repeat: no-repeat;
		background-position: center;
		position: relative;
		cursor: text;
		transition: all 0.1s linear 0.05s;
		overflow: hidden;
		&:hover {
			border-color: $v2-date-picker-button-border-color;
		}
		&:after {
			background-image: url('images/v2/components/rich-text-field/fileplus.svg');
			background-repeat: no-repeat;
			content: '';
			position: absolute;
			height: 53px;
			width: 44px;
			left: calc(50% - 22px);
			top: calc(50% - 26.5px);
			opacity: 0.4;

			transition: opacity 0.1s linear 0.05s;
		}
		&.file-upload-not-possible {
			&:after {
				content: none !important;
			}
		}
	}

	.rich-text-field-input-container-file {
		background-color: #05e298;
		border-radius: 4px;
		position: relative;

		.rich-text-field-editor-wrapper {
			color: transparent !important;
			a {
				color: transparent !important;
			}

			.DraftEditor-root .public-DraftEditorPlaceholder-root {
				color: transparent;
			}
			.draftJsMentionPlugin__mention__29BEd {
				color: transparent !important;
				background-color: transparent;
			}
		}

		&:before {
			content: '';
			position: absolute;
			left: 6px;
			bottom: 6px;
			width: calc(100% - 10px);
			height: calc(100% - 10px);
			border: dashed 4px #00ca88;
			background-image: url('images/v2/components/rich-text-field/fileplus.svg');
			background-repeat: no-repeat;
			background-position: center;
			content: '';
			opacity: 0.3;
		}
	}

	.rich-text-field-code-block {
		background-color: rgba(0, 0, 0, 0.05);
		font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
		font-size: 16px;
		padding: 20px;
	}

	.rich-text-field-input-not-empty {
		background-image: none;
		&:after {
			background-image: none;
		}
	}

	.rich-text-field-input-container.has-focus {
		border: solid 1px $v2-branding-purple;
		background-image: none;
		background-color: $color-white;
		.rich-text-field-editor-controls {
			opacity: 1;
			&.collapsed {
				display: block;
			}
		}
		.rich-text-field-editor-wrapper.collapsed {
			min-height: 80px;
		}

		&:after {
			opacity: 0;
		}
	}

	.rich-text-field-editor-wrapper {
		min-height: 80px;
		max-width: inherit;
		margin: 6px 16px 6px 10px;
		font-family: $text-font-family;
		font-size: 13px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.5;
		letter-spacing: 0.5px;
		color: $v2-text-gray;
		&.collapsed {
			min-height: 32px;
		}
		ul {
			/*Default values for the text field*/
			display: block;
			list-style-type: disc;
			margin-top: 1em;
			margin-bottom: 1em;
			margin-left: 0;
			margin-right: 0;
			padding-left: 40px;
		}
		.DraftEditor-root {
			position: relative;
			.public-DraftEditorPlaceholder-root {
				position: absolute;
				color: #a1a1a1;
			}
		}
	}

	.rich-text-field-editor-controls {
		height: 40px;
		border-top: 1px solid #ababab;
		width: 100%;
		opacity: 0;
		transition: opacity 0.1s linear 0.05s;
		&.collapsed {
			transition: none;
			display: none;
		}
		.custom-button-container {
			float: right;
			height: 40px;
			padding-left: 23px;
			padding-right: 23px;
		}
		.file-upload {
			display: inline-block;
			height: 18px;
			width: 18px;
			position: relative;
			background: url('images/v2/components/rich-text-field/attach.svg') center center no-repeat;
			overflow: hidden !important;
			cursor: pointer;
			margin-right: 9px;
			outline: none;
			input[type='file'] {
				width: 100%;
				height: 100%;
				position: absolute;
				cursor: pointer;
				opacity: 0;
				font-size: 0;
				top: 0;
				left: 0;
			}
		}
	}

	.rich-text-field-styling {
		float: left;
		height: 40px;
		width: 250px;
		padding: 10px 0px 10px 10px;

		.add-file-gdrive {
			position: absolute;
			left: 190px;
			z-index: 1000;
		}
	}

	.rich-text-field-emoji {
		display: inline-block;
		height: 18px;
		width: 18px;
		position: relative;
		.draftJsEmojiPlugin__emojiSelect__34S1B {
			.draftJsEmojiPlugin__emojiSelectButton__3sPol,
			.draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu {
				height: 18px;
				width: 18px;
				border: none;
				background-color: white;
				color: white;
				background-image: none;
				font-size: 0;
			}
		}

		.draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
			position: absolute !important;
			left: -60px;
			box-shadow: none;
		}
	}

	.rich-text-field-emoji:after {
		content: '';
		height: 18px;
		width: 18px;
		background-image: url('images/v2/components/rich-text-field/smiley.svg');
		background-position: center;
		background-repeat: no-repeat;
		position: absolute;
		right: 0px;
		pointer-events: none;
	}

	.rich-text-field-editor-control {
		cursor: pointer;
		float: left;
		margin-right: 9px;
		background-size: 18px;
		height: 18px;
		width: 18px;
		&.ol {
			background-image: url('images/v2/components/rich-text-field/ol.svg');
		}
		&.ul {
			background-image: url('images/v2/components/rich-text-field/ul.svg');
		}
		&.bold {
			background-image: url('images/v2/components/rich-text-field/bold.svg');
		}
		&.italic {
			background-image: url('images/v2/components/rich-text-field/italic.svg');
		}
		&.strikethrough {
			background-image: url('images/v2/components/rich-text-field/strikethrough.svg');
		}
		&.underline {
			background-image: url('images/v2/components/rich-text-field/underline.svg');
		}
		&.active {
			background-color: #f3f3f3;
		}
	}
	&.read-only {
		.rich-text-field-input-container,
		.rich-text-field-input-not-empty {
			border: none;
			.rich-text-field-editor-wrapper {
				margin: 0px;
				min-height: 0px;
			}
		}
	}
	.draftJsMentionPlugin__mention__29BEd,
	.draftJsMentionPlugin__mention__29BEd:visited {
		color: #575f67;
		cursor: pointer;
		display: inline-block;
		background: #e6f3ff;
		padding-left: 2px;
		padding-right: 2px;
		border-radius: 2px;
		text-decoration: none;
	}

	.draftJsMentionPlugin__mention__29BEd:hover,
	.draftJsMentionPlugin__mention__29BEd:focus {
		color: #677584;
		background: #edf5fd;
		outline: 0; /* reset for :focus */
	}

	.draftJsMentionPlugin__mention__29BEd:active {
		color: #222;
		background: #455261;
	}
	.draftJsMentionPlugin__mentionSuggestionsEntry__3mSwm {
		padding: 7px 10px 3px 10px;
		-webkit-transition: background-color 0.2s cubic-bezier(0.27, 1.27, 0.48, 0.56);
		transition: background-color 0.2s cubic-bezier(0.27, 1.27, 0.48, 0.56);
	}

	.draftJsMentionPlugin__mentionSuggestionsEntry__3mSwm:active {
		background-color: #cce7ff;
	}

	.draftJsMentionPlugin__mentionSuggestionsEntryFocused__3LcTd {
		background-color: #e6f3ff;
	}

	.draftJsMentionPlugin__mentionSuggestionsEntryText__3Jobq {
		display: inline-block;
		margin-left: 8px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 368px;
		font-size: 0.9em;
		margin-bottom: 0.2em;
	}

	.draftJsMentionPlugin__mentionSuggestionsEntryAvatar__1xgA9 {
		display: inline-block;
		width: 20px;
		height: 22px;
	}
	.draftJsMentionPlugin__mentionSuggestions__2DWjA {
		border: 1px solid #eee;
		position: absolute;
		min-width: 220px;
		max-width: 440px;
		background: #fff;
		border-radius: 2px;
		cursor: pointer;
		padding-top: 8px;
		padding-bottom: 8px;
		z-index: 2;
		display: -webkit-box;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		flex-direction: column;
		box-sizing: border-box;
		-webkit-transform: scale(0);
		transform: scale(0);
	}
}
