.budget-v2-wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	display: flex;
	flex-direction: column;

	.tabs {
		display: grid;
		grid-template-columns: repeat(4, max-content);
		grid-gap: 22px;
		font-size: 13px;
		color: $v2-text-gray;
		text-transform: capitalize;
		margin: 16px 66px;
		margin-bottom: 0;
		.tab {
			padding-top: 5px;
			cursor: pointer;
			&:hover {
				color: $v2-branding-purple;
			}
			&.active {
				color: $v2-branding-purple;
				border-bottom: 3px solid $v2-branding-purple;
				padding-bottom: 5px;
				font-weight: 500;
			}

			// Action menu
			&.warning {
				padding-top: 0;
				.new-ui-actions-menu {
					&.expanded {
						.actions {
							border-top-right-radius: 0px;
							border-bottom-right-radius: 0px;
							border-right: 1px solid transparent;
						}
					}
					.actions {
						border: 1px solid #e6e6e6;
						border-radius: 0;
						background: url('images/sections/scheduling-canvas/warning-icon-red.svg') 10px center no-repeat;
						width: auto !important;
						height: 28px;
						position: relative;
						background-color: #fff;
						padding: 7px;
						padding-left: 34px;
						padding-right: 10px;
						color: $negative-value;
						text-transform: uppercase;
						font-size: 10px;
						font-weight: 500;
					}
				}
			}
		}
	}

	.update-project-dates-wrapper {
		text-transform: none;
		p {
			font-size: 13px;
			color: $v2-text-gray;
			span {
				font-weight: 500;
				text-transform: uppercase;
			}
		}
	}

	// Styling of the warning button is a bit of a mess and tightly coupled with the tabs, duplicated style here for use with secondary navigation. Please clean up when convenient.
	.warning-button {
		cursor: pointer;
		// Action menu
		.warning {
			padding-top: 0;
			.new-ui-actions-menu {
				&.expanded {
					.actions {
						border-top-right-radius: 0px;
						border-bottom-right-radius: 0px;
						border-right: 1px solid transparent;
					}
				}
				.actions {
					border: 1px solid #e6e6e6;
					border-radius: 0;
					background: url('images/sections/scheduling-canvas/warning-icon-red.svg') 10px center no-repeat;
					width: auto !important;
					height: 28px;
					position: relative;
					background-color: #fff;
					padding: 7px;
					padding-left: 34px;
					padding-right: 10px;
					color: $negative-value;
					text-transform: uppercase;
					font-size: 10px;
					font-weight: 500;
				}
			}
		}
	}
}
