.column-selection-modal {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	z-index: 99;
	background: rgba(0, 0, 0, 0.75);
	display: flex;
	justify-content: center;
	align-items: center;
	.column-selection-container {
		max-width: 550px;
		height: 250px;
		background-color: $color-white;
		display: flex;
		flex-direction: column;
		padding: 1em;
		.content {
			display: flex;
			flex: 1 1 auto;
			flex-direction: column;

			.header {
				display: flex;
				flex: 0 0 40px;
				flex-direction: row;
				justify-content: space-between;
				margin-bottom: 20px;
				h2 {
					margin: 0;
				}
				button {
					width: 21px;
					height: 21px;
					border: none;
					margin-top: 3px;
					background-color: transparent;
					background-position: center;
					background-repeat: no-repeat;
					background-image: url('images/components/overlay/close.svg');
				}
			}
			.input-container,
			.select {
				max-width: 100%;
			}
		}
		.controls {
			display: flex;
			flex: 0 0 50px;
			flex-direction: row;
			justify-content: flex-end;

			.button-primary {
				@include primary-button;
			}
			.button-secondary {
				margin-right: 21px;
				@include secondary-button;
			}
		}
	}
}
