.assigned-dropdown-wrapper {
	position: relative;
	flex: 1 1 0px;
	display: flex;
	flex-direction: column;
	cursor: pointer;

	&.small {
		.arrow {
			bottom: 0;
		}
		.assigned-icons {
			top: 1px;
		}
	}
	&.disabled {
		cursor: default;
		input {
			background: $v2-input-disabled-grey;
		}
		.arrow {
			background-image: url('images/v2/components/dropdown/arrow-disabled.svg');
			cursor: default;
		}
	}

	&.add-icon {
		flex-direction: row;
		align-items: center;
		justify-content: center;
		label {
			margin: 0;
		}
	}

	&.short-input {
		input {
			height: 30px;
		}
		.assigned-icons {
			top: 4px;
			height: 22px;

			.assigned-role-icon {
				height: 22px;
				width: 22px;
				line-height: 23px;
			}
		}
		.arrow {
			height: 20px;
		}
	}

	label {
		height: 11px;
		font-size: 9px;
		font-weight: 500;
		line-height: 1.22;
		letter-spacing: 1px;
		color: $v2-text-light-gray;
		text-transform: uppercase;
		margin-bottom: 5px;
	}

	input {
		width: 100%;
		height: 40px;
		border: 1px solid $app-border-color;
		background-color: white;
		border-radius: 4px;
		padding: 0px 8px;
		font-size: 13px;
		outline: none;
		transition: 0.1s border-color;
		&::placeholder {
			opacity: 1;
		}
		&:hover {
			border-color: $v2-button-very-light-grey-hovered;
		}
		&:focus {
			border-color: $v2-branding-purple;
			outline: none;
		}
		&.expanded {
			border-color: $v2-branding-purple;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
		font-weight: normal;
		&.disabled {
			border: 1px solid $app-border-color;
			background-color: $v2-input-disabled-grey;
			cursor: default;
			color: #a1a1a1;
		}
	}

	.clear-input {
		border: 0;
		cursor: pointer;
		background: url('images/components/close-modal.svg') no-repeat center;
		position: absolute;
		z-index: 99;
		width: 29px;
		height: 29px;
		right: 30px;
		bottom: 5px;
		outline: none;
		&.small {
			bottom: 0px;
		}
	}

	.assigned-icons {
		position: absolute;
		top: 22px;
		left: 0;
		height: 26px;
		width: 100%;
		pointer-events: none;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-left: 10px;

		&.add-icon {
			position: relative;
			width: auto;
			padding-left: 5px;
			top: 0;
			pointer-events: initial;

			& > div {
				margin-left: 0;
			}

			.hex-add-icon:hover g {
				stroke: #6e0fea;
			}
		}

		> div {
			pointer-events: all;
			cursor: text;
		}

		.ai-icon-container {
			margin-right: 6px;
			display: flex;
			opacity: 0.4;
		}

		span {
			font-size: 13px;
			margin-left: 4px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}

		.assigned-role-icon {
			flex-shrink: 0;
			width: 26px;
			height: 26px;
			background-color: $v2-person-dropdown-role-background-color;
			border-radius: 50%;
			text-transform: uppercase;
			font-size: 12px;
			font-weight: 600;
			line-height: 26px;
			font-weight: bold;
			text-align: center;
			color: white;
			margin-right: 2px;
		}
		.more-people-container {
			height: 26px;
			width: 26px;
			background-size: 22px;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url('images/v2/components/white-hexagon.svg');
			font-size: 11px;
			font-weight: 500;
			line-height: 26px;
			text-align: center;
			z-index: 10;
			color: #535353;
		}
	}
	.arrow {
		background: url('images/v2/components/dropdown/arrow-down.svg') no-repeat center;
		position: absolute;
		z-index: 1;
		width: 16px;
		height: 30px;
		bottom: 5px;
		right: 0px;
		margin-right: 12px;
		outline: none;
		&.expanded {
			background-image: url('images/v2/components/dropdown/arrow-up.svg');
		}
	}
}
.root-portal-container {
	ul.subtask-assigned-dropdown {
		position: absolute;
		z-index: 999;
		background-color: $color-white;
		border: 1px solid $app-border-color;
		border-top: none;
		font-size: 12px;
		width: 159px;
		padding-left: 0;
		margin-top: 0;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);

		&.add-icon {
			border-radius: 4px;
		}

		&.new-scrollbar {
			li {
				.list-header {
					padding: 8px 0px 8px 10px;
				}
			}
			ul {
				li {
					&.role-option,
					&.person-option,
					&.team-option {
						height: 38px;
						padding: 3px 0px 3px 10px;
					}
				}
			}
		}

		input {
			width: 100%;
			height: 40px;
			border: 1px solid $app-border-color;
			background-color: white;
			border-radius: 4px;
			padding: 0px 16px;
			font-size: 12px;
			outline: none;
			transition: 0.1s border-color;
			&:hover {
				border-color: $v2-button-very-light-grey-hovered;
			}
			&:focus {
				border-color: $v2-branding-purple;
				outline: none;
			}
			&.expanded {
				border-color: $app-border-color;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
			}
			font-weight: normal;
			&.disabled {
				background-color: $v2-input-disabled-grey;
				cursor: default;
			}
		}
		li {
			font-weight: bold;
			.list-header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				//width: 159px;
				border-top: solid 1px $app-border-color;
				padding: 8px 27px 8px 14px;
				cursor: pointer;
				font-weight: 600;
				&:first-of-type {
					border-top: none;
				}

				&.selected {
					background-color: $v2-workflow-task-selected-color;
					color: $v2-branding-purple;
				}

				.expanded {
					width: 29px;
					height: 8px;
					background: url('images/v2/components/dropdown/arrow-up.svg') no-repeat center center;
				}

				.not-expanded {
					width: 29px;
					height: 8px;
					background: url('images/v2/components/dropdown/arrow-down.svg') no-repeat center center;
				}

				.info-component-icon-wrapper {
					flex-grow: 1;
				}
			}
			ul {
				padding: 0px;
				li {
					display: flex;
					flex-direction: row;
					align-items: center;
					font-weight: 500;
					text-transform: none;
					cursor: pointer;
					&.role-option,
					&.person-option,
					&.team-option {
						height: 38px;
						padding: 3px 27px 3px 14px;
					}
					.delete-icon {
						background: url('images/components/close-modal.svg') no-repeat center;
						width: 29px;
						height: 29px;
						align-self: flex-end;
						flex-grow: 0;
						flex-shrink: 0;
					}
					&:hover,
					&.selected {
						background-color: $v2-workflow-task-selected-color;
						color: $v2-branding-purple;
					}
					.role-icon {
						flex: 0 0 30px;
						min-width: 30px;
						width: 30px;
						height: 30px;
						background-color: #4a90e2;
						border-radius: 50%;
						text-transform: uppercase;
						font-size: 12px;
						font-weight: 600;
						line-height: 30px;
						font-weight: bold;
						margin-right: 12px;
						text-align: center;
						color: white;
					}
					.role-name {
						flex: 1 1 0px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						font-size: 12px;
					}
					.forecast-person {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						flex-grow: 1;
						.person-info {
							padding-left: 5px;
							.role {
								font-size: 10px;
								font-weight: normal;
							}
						}
					}
				}
			}
		}
		.custom-scrollbar-div {
			> div:first-child {
				//width 110% needed to hide default scroll bar in mozilla firefox
				width: 110%;
				@include scroll-y;
			}
			@supports not (-moz-appearance: none) {
				/* non-firefox browsers */
				&.view-vertical > div {
					margin-bottom: 0px !important;
				}
			}
		}

		.dropdown-search-input-add-icon {
			input {
				padding-right: 35px; //make room for the clear icon without removing the border around the input
			}
			.clear-input {
				border: 0;
				cursor: pointer;
				background: url('images/components/close-modal.svg') no-repeat center;
				position: absolute;
				z-index: 99;
				width: 29px;
				height: 29px;
				top: 5px;
				right: 11px;
				outline: none;
			}
		}
	}
}
