.settings-client {
	&.section-content {
		margin: 0;
		.section-body {
			margin: 0 24px;
		}
	}
	.clients {
		.top-container {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			.description {
				margin: 0;
			}
			.add-client-button {
				@include primary-button;
				margin-left: 8px;
			}
		}
		.new-file {
			width: 355px;
			height: auto;
			padding: 10px;
			border: 1px solid $app-border-color;
			color: $input-placeholder-color;
			border-radius: 5px;
			overflow: auto;
			.add-button {
				float: right;
				@include primary-button;
			}
		}
		@include standard-table;
		table {
			width: 100%;
			td {
				//max-width: 220px;
			}
		}
		tbody {
			.editor {
				max-height: 120px;
				@include scroll-y;
			}
			tr {
				.controls {
					width: 250px;
					white-space: nowrap;
					text-align: right;
					padding-right: 20px;
					.control {
						display: block;
						float: right;
						background-size: 16px;
						background-repeat: no-repeat;
						background-position: left center;
						padding: 0 10px 0 21px;
						cursor: pointer;
						&.edit {
							background-image: url('images/cross-section-icons/actions/edit.svg');
						}
						&.remove {
							background-image: url('images/cross-section-icons/actions/remove.svg');
							&.disabled {
								color: $v2-text-gray;
								text-decoration: none;
							}
						}
						&.integration-link {
							background-image: url('images/integrations/xero/link.svg');
						}
					}
				}
				&.row-client {
					.controls {
						.control {
							width: 0px;
							height: 0px;
							padding: 0px;
							visibility: hidden;
							a {
								color: #6f49c5;
								text-decoration: none;
							}
							&:hover {
								&:not(.disabled) {
									text-decoration: underline;
								}
							}
						}
						.integration-logo {
							display: block;
							float: right;
							visibility: visible;
							&.economic {
								width: 100px;
								height: 25px;
								background: url('images/integrations/e-conomic-logo.svg') center center no-repeat;
								background-size: 100px 25px;
							}
							&.xero {
								width: 50px;
								height: 50px;
								background: url('images/integrations/xero-logo.png') center center no-repeat;
								background-size: 50px 50px;
								margin-right: 20px;
							}
							&.sageIntacct {
								width: 100px;
								height: 50px;
								background: url('images/integrations/sage-logo.png') center center no-repeat;
								background-size: 100px 100px;
								margin-right: 20px;
							}
						}
					}
					&:hover {
						.controls {
							.control {
								width: auto;
								height: auto;
								padding: 0 10px 0 21px;
								visibility: visible;
							}
							.integration-logo {
								width: 0px;
								height: 0px;
								padding: 0px;
								margin: 0px;
								visibility: hidden;
							}
						}
						cursor: pointer;
					}
					.client {
						.wrapper {
							display: flex;
							flex: 1 1 auto;
							flex-direction: row;
							justify-content: flex-start;
							.image-container {
								//width: 50px;
								float: left;
								height: 120px;
								margin-bottom: 0px;
								.client-picture {
									margin: 0;
									height: 120px;
									width: 120px;
									img {
										border-radius: 50%;
									}
								}
							}
							.name-tile {
								display: flex;
								h3 {
									margin: 0;
									font-size: 14px;
									margin-left: 10px;
									align-self: center;
								}
							}
						}
					}
					.editor {
						margin-bottom: 0;
					}
					.editor .public-DraftEditor-content {
						min-height: 0;
					}
				}
			}
		}
		.client-table {
			margin-top: 25px;
		}
	}
}
