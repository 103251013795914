.economic-export-modal-content {
	max-width: 90vw;
	padding-right: 58px;
	.inputs-wrapper {
		width: 240px;
	}
	.economic-export-table {
		width: 100%;
		margin: 10px 0px;
		table-layout: fixed;

		.number-cell,
		.select-cell {
			padding-left: 12px;
		}

		th {
			font-family: $text-font-family;
			font-size: 11px;
			font-weight: 500;
			line-height: 1.22;
			letter-spacing: 1px;
			text-transform: uppercase;
			color: $v2-text-light-gray;
			border-bottom: 1px solid $color-light-grey;

			span {
				float: left;
			}

			&.header-name,
			&.header-desc {
				// width: 10%;
			}
			&.header-quantity,
			&.header-vat,
			&.header-discount {
				width: 5%;
			}
			&.header-unit,
			&.header-amount {
				width: 10%;
			}
			&.header-product {
				width: 240px;
			}
		}

		tbody {
			// max-width: 100%;
			.text-cell {
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}

			tr {
				padding-top: 5px;
			}
		}
	}
}
.insight-header-export-menu {
	width: 65px;
	background-color: $color-white;
	border-radius: 4px;
	border: 1px solid #e6e6e6;
	.export-menu-button {
		width: 100%;
		border: none;
		padding: 5px 13px 5px 13px;
		font-size: 13px;
		text-transform: capitalize;
		text-align: left;
		&:hover {
			color: #6e0fea;
			background-color: #f1e9fa;
			cursor: pointer;
		}
	}
}
