.custom-scrollbar-div {
	flex: 1 1 auto;
	display: flex;
	&.view-horizontal {
		> div {
			&:not(.track) {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				margin-right: -20px !important;
				padding-right: 3px;
				margin-left: -20px;
				padding-left: 20px;
			}
		}
		> .track-vertical {
			display: none;
		}
		> .thumb-vertical {
			display: none;
		}
	}
	&.view-vertical {
		> .track-horizontal {
			display: none;
		}
		> .thumb-horizontal {
			display: none;
		}
		> div {
			&:not(.track) {
				margin-bottom: -20px;
				padding-bottom: 3px;
				margin-top: -20px;
				padding-top: 20px;
			}
		}
	}
	&:hover {
		> .track {
			opacity: 1 !important;
		}
	}
	.track {
		border-radius: 5px;
		background-color: transparent;
		transition: all 0.4s !important;
		z-index: 5;
		cursor: pointer;
		&.track-vertical {
			right: 1px;
			bottom: 0;
			top: 0;
			width: 8px !important;
			padding: 0 1px 0 1px;
		}
		&.track-horizontal {
			bottom: 1px;
			right: 0;
			left: 0;
			height: 8px !important;
			padding: 1px 0 1px 0;
		}
		&:hover {
			background-color: #d9d9d9;
			&.track-vertical {
				width: 10px !important;
				right: 0;
			}
			&.track-horizontal {
				height: 10px !important;
				bottom: 0;
			}
		}
	}
	.thumb {
		background-color: #999999;
		border-radius: 4px;
	}
}
