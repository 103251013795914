.check-box {
	min-width: 22px;
	height: 22px;
	border-radius: 4px;
	.check-mark {
		width: 100%;
		height: 100%;
		border: 2px solid $v2-grey;
		background-color: white;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		border-radius: 4px;
		outline: none;
		cursor: pointer;
		&:hover {
			border: solid 2px $v2-branding-purple;
		}
		&:focus {
			border: solid 2px $v2-branding-purple;
		}
	}

	.check-mark:checked {
		background: $v2-branding-purple;
		display: inline-block;
		border: solid 2px $v2-branding-purple;

		&:after {
			content: '';
			display: block;

			width: 7px;
			height: 15px;

			border: solid white;
			border-width: 0 2px 2px 0;

			transform: translateX(-50%) rotate(40deg);

			position: relative;
			left: 55%;
			top: 0px;
		}
		&.group-has-entities-checked {
			&:after {
				transform: translateX(-60%) rotate(90deg);
				border-bottom-style: none;
				top: -1px;
			}
		}
	}

	&.green {
		.check-mark:checked {
			background: $v2-completed-green;
			border: solid 2px $v2-completed-green;
		}
	}

	&.green-inverted {
		.check-mark {
			background-color: white;
			border: solid 2px #dbdbdb;
			&:checked::after {
				height: 13px;
				border-width: 0 2.4px 2.4px 0;
				border-color: #00cc66;
				top: 2px;
			}
		}
	}

	&.large {
		width: 34px;
		height: 34px;
		.check-mark:checked:after {
			left: 15px;
			top: 6px;
		}
	}
	&.small {
		min-width: 18px;
		width: 18x;
		height: 18px;
		.check-mark:checked:after {
			width: 7px;
			height: 11px;
			left: 53%;
			top: 1px;
		}
	}
	&.very-small {
		min-width: 15px;
		width: 15px;
		height: 15px;
		.check-mark:checked:after {
			width: 6px;
			height: 9px;
			top: 0px;
			left: 6px;
		}
	}

	&.focusable {
		.check-mark:checked {
			&:focus {
				background-color: rgba($v2-branding-purple, 0.7);
				border-color: rgba($v2-branding-purple, 0.8);
			}
			&:hover {
				background-color: rgba($v2-branding-purple, 0.5);
				border-color: rgba($v2-branding-purple, 0.6);
			}
		}
	}

	&.subtask-done-status {
		.check-mark {
			display: inline-block;
			background: white;
			border: solid 2px transparent;
			&:after {
				content: '';
				display: block;

				width: 7px;
				height: 15px;

				border: solid $app-border-color;
				border-width: 0 2px 2px 0;

				transform: translateX(-50%) rotate(40deg);

				position: relative;
				left: 16px;
				top: 6px;
			}
			&:checked {
				background: white;
				border: solid 2px transparent;

				&:after {
					content: '';
					display: block;

					width: 7px;
					height: 15px;

					border: solid $v2-completed-green;
					border-width: 0 2px 2px 0;

					transform: translateX(-50%) rotate(40deg);

					position: relative;
					left: 16px;
					top: 6px;
				}
			}
			&:hover {
				border: solid 2px $app-border-color;
			}
		}
	}
	&.disabled {
		input {
			border-color: $v2-input-disabled-grey !important;
		}

		.check-mark:checked {
			background: $v2-input-disabled-grey;
			border-color: $v2-input-disabled-grey;
			&:hover {
				border-color: $v2-input-disabled-grey;
			}
		}
	}
}
