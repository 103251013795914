.bulk-edit-container {
	height: 30px;
	position: relative;
	.text {
		white-space: nowrap;
	}
	button {
		margin-right: 0;
		min-width: 108px;
		margin-left: 0;
	}

	.bulk-edit-selection {
		margin-right: 8px;
	}
	.bulk-edit-selection,
	.bulk-edit-options-list {
		&.has-toggle-mode {
			left: 0;
		}
		&.no-toggle-mode {
			right: 0;
		}
	}
	.custom-button-container {
		height: 30px;
		min-width: 108px;
	}
	.bulk-edit-options-list {
		font-size: 12px;
		line-height: 0.92;
		padding: 0;
		margin: 0;
		border: solid 1px #e6e6e6;
		display: flex;
		flex-direction: column;
		//max-width: 200px;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
		list-style: none;
		background-color: $color-white;
		z-index: 9;
		position: absolute;
		top: 35px;
		display: flex;
		flex-direction: column;
		.options-list-empty {
			padding-left: 15px;
			padding-top: 3px;
			padding-bottom: 3px;
			font-family: $text-font-family-v2;
			font-style: italic;
			color: $v2-text-light-gray;
		}
		li {
			font-family: $text-font-family;
			flex-grow: 1;
			cursor: pointer;
			text-align: left;
			//overflow: hidden;
			text-overflow: ellipsis;
			padding: 6px 16px;
			color: $v2-text-gray;
			margin-bottom: 4px;
			&.disabled {
				color: $v2-text-light-gray;
			}
			a {
				color: $v2-text-gray;
				text-decoration: none;
			}
			&:first-child {
				margin-top: 4px;
			}
			.bulk-edit-text {
				margin-top: 0px;
				margin-bottom: 0px;
				display: flex;
				flex-direction: row;
				align-items: center;
			}
		}
		.bulk-edit-option:hover,
		li:focus {
			color: $v2-branding-purple;
			background-color: rgba(110, 15, 234, 0.1);
			a {
				color: $v2-branding-purple;
			}
			&.disabled {
				color: $v2-text-light-gray;
			}
		}
	}
}
