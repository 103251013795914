.project-section-header-v2 {
	@include section-header;
	display: flex;
	flex-direction: row;
	flex: 0 0 auto;
	position: relative;
	border-bottom: 1px solid $color-white;
	background-color: $v2-branding-purple;
	// border-top: 2px solid $color-white;
	.connected-project {
		position: absolute;
		top: 34px;
		left: 0;
		height: 38px;
		width: 66px;
		max-width: 66px;
		z-index: 9;
		.triangle {
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 38px 66px 0 0;
		}
		.project-id {
			position: absolute;
			top: 4px;
			left: -2%;
			transform: rotate(-30deg);
			font-family: $text-font-family;
			font-size: 10px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 2.2;
			letter-spacing: normal;
			text-align: center;
			min-width: 45px;
			min-height: 20px;
			max-height: 20px;
		}
		&:hover {
			.project-id {
				text-decoration: underline;
			}
		}
	}
	.project-selection-and-nav {
		display: flex;
		flex-direction: row;
	}
	.section-navigation {
		padding-left: 4px !important;
	}
	.project-selection {
		background-color: $color-white;
		border-right: 2px solid $color-white;
		width: 283px;
		&:lang(fr) {
			width: 285px;
		}
		&:lang(da) {
			width: 301px;
		}
		.project-section-project-select {
			width: 100%;
			background-color: $color-white;
			.nested-dropdown-v2 {
				height: 34px;
				width: 100%;
				border: none;
				&.expanded {
					background-color: $color-white;
					.input-arrow-wrapper {
						border: 1px solid $v2-branding-purple;
						border-bottom: none;
					}
				}
				.input-arrow-wrapper {
					border: none;
					height: 33px;
					border-radius: 0px;
					font-weight: 700;
					.placeholder {
						font-size: 12px;
					}
					input,
					.arrow-icon-wrapper,
					.arrow {
						height: 32px;
						min-height: 32px;
					}
					&.collapsed {
						height: 34px;
						&:hover {
							background-color: $color-white;
							border: 1px $v2-branding-purple solid;
							color: $v2-text-gray;
							.arrow {
								background: url('images/v2/components/dropdown/arrow-down.svg') no-repeat center;
							}
						}
						.arrow-icon-wrapper,
						.arrow {
							height: 34px;
							min-height: 34px;
						}
					}
					input {
						text-transform: none;
						border: none;
						letter-spacing: 0.5px;
						font-size: 11px;
						background-color: transparent;

						padding: 0 14px;
						font-weight: 700;
						&.expanded {
							background: rgba(0, 0, 0, 0);
							background-color: $color-white;
							color: $v2-text-gray;
							height: 32px;
							min-height: 32px;
							//padding-bottom: 0;
						}
					}
					::-webkit-input-placeholder {
						/* Chrome/Opera/Safari */
						font-size: 11px;
					}
					::-moz-placeholder {
						/* Firefox 19+ */
						font-size: 11px;
					}
					:-ms-input-placeholder {
						/* IE 10+ */
						font-size: 11px;
					}
					:-moz-placeholder {
						/* Firefox 18- */
						font-size: 11px;
					}
					&.expanded {
						align-items: flex-start;
					}
				}

				.nested-dropdown-options-wrapper {
					width: 281px;
					&:lang(fr) {
						width: 283px;
					}
					&:lang(da) {
						width: 299px;
					}
				}
			}
		}
	}
}
