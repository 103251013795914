.task-modal-v3-subtasks-section {
	border-top: 1px solid $app-border-color;
	margin: 0 58px 0 58px;
	.subtask-section-header {
		display: flex;
		flex-direction: row;
		align-items: center;
		border: none;
		width: 100%;
		height: 57px;
		padding-left: 0;

		&:focus {
			outline: none;
			.expand-icon {
				path {
					stroke: $color-martin-tertiary-light-purple;
				}
			}
		}

		.subtask-icon {
			padding-top: 3px;
		}
		.subtask-section-header-item {
			margin-right: 40px;
			margin-left: 8px;

			&.deprecated {
				background-color: $bulk-update-selected-color;
				border-radius: 4px;
				text-transform: uppercase;
				font-size: 13px;
				padding: 8px;
				color: $v2-text-gray;
				font-weight: 700;
				letter-spacing: 1.3px;
			}

			.item-name {
				font-size: 13px;
				// color: $v2-text-light-gray;
				line-height: 1.1;
				letter-spacing: 0.5px;
				text-transform: uppercase;
				display: flex;
				align-items: center;
			}

			&.title {
				margin-right: 0;
				div {
					color: $v2-text-gray;
					font-size: 13px;
					font-weight: 500;
				}
			}

			&.status {
				flex-grow: 1;
				div {
					span.status-finished {
						svg {
							margin-right: 2px;
						}

						span {
							color: $v2-text-gray;

							&.finished-number {
								color: #5fc600;
							}
						}
					}
				}
			}

			&.estimations-info-text {
				display: flex;
				font-size: 12px;
				margin-right: 11px;
				.value {
					font-weight: 500;
					margin: 0 5px;
				}
				.warning-icon {
					margin-right: 5px;
				}
			}
		}
	}

	.subtask-section-content {
		margin-top: 1px;
		padding: 0 0 8px 0;

		.input-value-container {
			height: 30px;
			.input-value-unit {
				line-height: 30px;
			}
		}

		.subtasks-list {
			.name .task-modal-subtask-input .input-value-container {
				border-color: transparent;

				& input {
					font-weight: 600;
				}
			}

			.subtask {
				padding: 6px 20px 6px 0;
				position: relative;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				text-align: left;
				background-color: #fff;

				&.is-to-do {
					padding-right: 42px;
					justify-content: flex-start;

					.subtask-item.name {
						min-width: 100%;
					}
				}

				&.not-dragging:hover {
					.subtask-item.name {
						.drag-handle {
							opacity: 1;
						}
					}
					.name .task-modal-subtask-input .input-value-container {
						border-color: $v2-text-light-gray;
					}
				}

				.subtask-item {
					text-align: left;
					margin-right: 12px;

					&.no-margin-right {
						margin-right: 0;
					}

					&:nth-last-child(2),
					&:last-child {
						margin-right: 0;
					}

					&.done-toggle {
						position: absolute;
						top: 9px;
						left: -45px;
						.check-box {
							height: 30px;
						}
					}

					.check-mark {
						&:focus {
							border: 2px solid $v2-branding-purple;
						}
						&::after {
							top: 4px;
						}
					}

					.task-modal-subtask-input {
						&.completed {
							.input-value-container {
								input {
									background-color: #fff;
									font-weight: 600;
								}
							}
						}

						.input-value-container {
							input {
								font-size: 14px;
								line-height: 1.29;
							}
						}
					}

					&.notes {
						margin: 0 4px;
					}

					&.name {
						width: 80%;
						position: relative;

						.drag-handle {
							opacity: 0;
							background: url('images/cross-section-icons/table/drag-handle.svg') no-repeat;
							background-size: 14px;
							height: 30px;
							width: 16px;
							background-position: center;
							cursor: move;
							position: absolute;
							left: 0;
							z-index: 100;
							top: 0;
						}
					}

					&.hours-estimated {
						width: 100px;
					}

					&.to-from-date {
						height: 30px;
						border-radius: 4px;
						display: flex;
						padding: 0;
						.date-picker-button {
							height: 30px;
							width: 50px;

							&:focus {
								background-color: $app-border-color;
							}
							.date-text-bold {
								flex-direction: row;

								div:first-of-type {
									margin-right: 3px;
								}
							}
						}
					}

					.subtask-assigned-input {
						&:focus {
							border: 1px solid $v2-branding-purple;
						}
					}

					&.assign-person {
						position: relative;

						.subtask-assigned-wrapper {
							button {
								.forecast-person {
									width: 32px;
									height: 30px;
								}
							}
						}

						button {
							border: 0;
						}

						.role-icon {
							flex-shrink: 0;
							width: 30px;
							height: 30px;
							background-color: #4a90e2;
							border-radius: 50%;
							text-transform: uppercase;
							font-size: 12px;
							font-weight: 600;
							line-height: 30px;
							font-weight: bold;
							text-align: center;
							color: white;
						}

						.subtask-assigned-content {
							position: absolute;
							min-width: 175px;
							top: 0;
							left: -193px;
						}
					}

					&.context-menu {
						position: absolute;
						right: 0;
						display: none;
						top: 6px;

						.new-ui-actions-menu {
							margin-right: 2px;
						}

						&.expanded {
							display: block;
						}
					}
				}

				.notes-button {
					height: 30px;
					display: block;
					width: 36px;
					background-image: url('images/v2/components/notes/notes-empty.svg');
					background-size: 20px;
					background-position: center 4px;
					background-repeat: no-repeat;
					position: relative;
					cursor: pointer;
					border: none;
					outline: none;

					&.disabled {
						cursor: default;
					}

					&.has-notes {
						background-image: url('images/v2/components/notes/notes-available-icon.svg');
						background-size: 22px;
						margin-bottom: 3px;
					}

					&.expanded {
						background-image: url('images/v2/components/notes/notes-empty-active.svg');
						background-size: 19px;
						margin-bottom: 0;
					}
				}
			}

			.notes-description {
				display: none;
				.rich-text-field-container {
					&.read-only {
						.rich-text-field-input-container,
						.rich-text-field-input-not-empty {
							border: solid 1px $v2-branding-purple;
							.rich-text-field-editor-wrapper {
								margin: 10px 14px 0 14px;
							}
						}
					}
				}
				.rich-text-field-emoji .draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
					bottom: 200px;
				}
				&.expanded {
					display: block;
					width: calc(100% - 58px);
					margin-bottom: 10px;
				}
			}
		}

		.add-subtask {
			display: flex;
			margin-top: 6px;

			.subtask-name {
				width: 100%;
				margin-right: 5px;
			}

			.subtask-hours {
				width: 15%;
				margin-right: 5px;
			}

			.subtask-add-button {
				button {
					width: 30px;
					height: 30px;
					border: 0;
					background: $v2-create-button-green;
					border: 2px solid $v2-create-button-green;
					color: white;
					font-size: 24px;
					line-height: 21px;

					&.disabled {
						border-color: $app-border-color;
						background: $app-border-color;
					}
				}
			}
		}
	}
}
