.auto-scheduling-dropdown {
	position: relative;
	width: 224px;
	display: flex;
	flex-direction: column;
	cursor: pointer;
	&.disabled {
		input {
			background: $v2-input-disabled-grey;
		}
	}

	input {
		width: 100%;
		height: 30px;
		border: 1px solid $app-border-color;
		background-color: white;
		border-radius: 4px;
		padding: 0px 60px 0 16px;
		font-size: 12px;
		outline: none;
		transition: 0.1s border-color;
		&:hover {
			border-color: $v2-button-very-light-grey-hovered;
		}
		&:focus {
			border-color: $v2-branding-purple;
			outline: none;
		}
		&.expanded {
			border-color: $v2-branding-purple;
			&.opens-upwards {
				border-top-left-radius: 0;
				border-top-right-radius: 0;
			}
			&.opens-downwards {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
		font-weight: normal;
		&.disabled {
			background-color: $v2-input-disabled-grey;
			&:hover {
				border: 1px solid $app-border-color;
			}
		}
	}

	.clear-input {
		border: 0;
		cursor: pointer;
		background: url('images/components/close-modal.svg') no-repeat center;
		position: absolute;
		z-index: 99;
		width: 30px;
		height: 30px;
		top: 0;
		right: 30px;
		outline: none;
	}
	.arrow {
		cursor: pointer;
		background: url('images/v2/components/dropdown/arrow-down.svg') no-repeat center;
		position: absolute;
		z-index: 99;
		width: 16px;
		height: 30px;
		bottom: 0;
		right: 0px;
		margin-right: 10px;
		outline: none;
		&.expanded {
			background-image: url('images/v2/components/dropdown/arrow-up.svg');
		}
	}
}
.root-portal-container {
	ul.auto-scheduling-dropdown {
		position: absolute;
		z-index: 999;
		background-color: $color-white;
		border: 1px solid $app-border-color;
		border-top: none;
		font-size: 12px;
		width: 159px;
		padding-left: 0;
		margin-top: 0;
		&.opens-upwards {
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
		&.opens-downwards {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			border-bottom-left-radius: 4px;
			border-bottom-right-radius: 4px;
		}
		li {
			font-weight: bold;
			.list-header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				//width: 159px;
				border-top: solid 1px $app-border-color;
				padding: 8px 27px 8px 14px;
				cursor: pointer;
				font-weight: 600;
				&:first-of-type {
					border-top: none;
				}

				.expanded {
					width: 29px;
					height: 8px;
					background: url('images/v2/components/dropdown/arrow-down.svg') no-repeat center center;
				}

				.not-expanded {
					width: 29px;
					height: 8px;
					background: url('images/v2/components/dropdown/arrow-up.svg') no-repeat center center;
				}

				.info-component-icon-wrapper {
					flex-grow: 1;
				}
			}
			ul.auto-scheduling-inner-list {
				padding: 0px;
				li {
					display: flex;
					flex-direction: row;
					align-items: center;
					font-weight: 500;
					text-transform: none;
					cursor: pointer;
					user-select: none;
					.assigned {
						background: url('images/components/scheduling/pink-checkmark.svg') no-repeat center center;
						background-size: cover;
						width: 14px;
						height: 10px;
						align-self: center;
						flex-grow: 0;
						margin-right: 4px;
					}
					.assigned-placeholder {
						flex: 0 0 14px;
					}
					&.person-option {
						height: 38px;
						padding: 4px 27px 4px 14px;
						outline: 0;
						&.selected {
							background-color: $v2-list-hover-purple;
						}
					}
					.delete-icon {
						background: url('images/components/close-modal.svg') no-repeat center;
						width: 29px;
						height: 29px;
						align-self: flex-end;
						flex-grow: 0;
					}
					&:hover,
					&.selected {
						background-color: $v2-workflow-task-selected-color;
						color: $v2-branding-purple;
					}
					.forecast-person {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						flex-grow: 1;
						.person-info {
							padding-left: 5px;
							.role {
								font-size: 10px;
								font-weight: normal;
							}
						}
					}
				}
			}
		}
		.custom-scrollbar-div {
			> div:first-child {
				//width 110% needed to hide default scroll bar in mozilla firefox
				width: 110%;
				@include scroll-y;
			}
			@supports not (-moz-appearance: none) {
				/* non-firefox browsers */
				&.view-vertical > div {
					margin-bottom: 0px !important;
				}
			}
		}
	}
}
