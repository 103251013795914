.budget-internal-costs-container {
	flex-grow: 1;
	display: flex;
	margin: 0;

	.section-body {
		&:not(.no-margin) {
			margin: 0 66px;
		}
	}

	.controls {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		.expand-control {
			width: 24px;
			height: 16px;
			background-size: 24px;
			background-repeat: no-repeat;
			background-position: center;
			cursor: pointer;
			&.expand {
				background-image: url('images/up-arrow.svg');
			}

			&.collapse {
				background-image: url('images/header/expanded.svg');
			}
		}
	}

	fieldset.rates {
		.role-width-header,
		.cost-width-header {
			padding-left: 10px !important;
		}

		.row-member {
			&:not(.no-persons) {
				.inner-td {
					cursor: pointer;
				}
			}
			.inner-td {
				padding: 12px 0;
				font-weight: 500;
			}
		}

		.team-member {
			td:first-child {
				.inner-td {
					margin-left: 24px;
				}
			}
			.inner-td {
				cursor: default !important;
				font-weight: 400;
			}
		}
	}

	fieldset.rates {
		&.no-margin-top {
			margin-top: 0;
		}
	}

	fieldset.people,
	fieldset.rates {
		border: none;
		box-shadow: none;
		padding: 0;
		margin-bottom: 24px;

		.top-container {
			width: max-content;
			cursor: pointer;
			@include data-tile;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;

			&.collapsed {
				border-bottom: 1px solid #eee;
			}

			h2 {
				font-size: 16px;
				color: $v2-text-gray;
				font-family: inherit;

				span {
					font-weight: 400;
				}
			}
			h3 {
				margin-bottom: 0;
			}
		}

		@include standard-table;
		table {
			.column-name-info-wrapper {
				display: flex;
				align-items: center;
				span {
					margin-left: 5px;
				}
			}
			tbody {
				tr {
					td {
						input[type='number'] {
							&:hover {
								border: 1px solid $v2-text-light-gray;
							}

							&:focus {
								border: 1px solid $v2-branding-purple;
							}
						}
					}
					td.cost-modal-link {
						cursor: pointer;
						text-align: right;

						&.disabled {
							cursor: default;

							&:hover {
								color: $v2-text-gray;
							}
						}

						&:hover {
							color: $v2-branding-purple;
						}
					}
				}
			}

			.active {
				color: $v2-branding-purple;
				font-weight: 500;
			}

			.drop-down-v2 {
				&:hover .arrow {
					display: block;
				}

				.arrow {
					display: none;
				}
			}

			.person-width {
				// min-width: 180px;
				// max-width: 180px;
				width: 220px;
			}
			.role-width {
				// min-width: 170px;
				// max-width: 170px;
				width: 170px;

				&.deactivated {
					padding-left: 16px;
				}

				&.role-width-header {
					padding-left: 16px;
				}
				.icon-wrapper {
					margin-right: 5px;
					float: left;
					margin-top: 10px;
				}
			}

			.cost-width,
			.cost-modal-link {
				position: relative;

				.exchanged-rate-cost {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					color: $v2-text-light-gray;
					left: 127px;
					width: 127px;
				}
			}

			.cost-width {
				// min-width: 104px;
				// max-width: 104px;
				width: 175px;

				&.cost-width-header {
					padding-left: 10px;
				}

				input {
					width: 104px;

					&:focus,
					&:active {
						outline: 0;
					}
				}
			}

			.deactivated-controls-width {
				width: 50.5%;
			}

			thead {
				th {
					color: $v2-text-light-gray;
					font-size: 9px;
					letter-spacing: 1px;
					font-weight: 400;
					text-transform: uppercase;
					cursor: default;
					vertical-align: bottom;
					height: 48px;
				}
			}

			tbody {
				tr {
					border-top: 1px solid $light-border-color;
					border-bottom: none;

					td.person-action-menu {
						.new-ui-actions-menu {
							margin-left: calc(100% - 20px);
						}
					}

					td {
						color: $v2-text-gray;
						font-size: 13px;
						padding: 0 0.625rem 0 0.625rem;

						.task-table-input-field {
							width: 116px;
						}

						.drop-down-v2 {
							height: 30px;
							margin: 5px 0;

							input {
								&.expanded {
									border-bottom-left-radius: 0;
									border-bottom-right-radius: 0;
								}

								&:hover {
									border: 1px solid $v2-button-very-light-grey-hovered;
								}
							}

							.arrow {
								&.label-hidden {
									top: -5px;
								}
							}
						}
					}
				}
			}
		}
		tbody {
			table-layout: fixed;
			word-wrap: break-word;
			tr {
				.controls {
					span {
						background-size: 16px;
						background-repeat: no-repeat;
						background-position: left center;
						padding: 0 10px 0 21px;
						cursor: pointer;
						&:hover {
							text-decoration: underline;
						}
						&.edit {
							background-image: url('images/cross-section-icons/actions/edit.svg');
						}
						&.remove {
							background-image: url('images/cross-section-icons/actions/remove.svg');
						}
						&.enable {
							background-image: url('images/cross-section-icons/actions/enable.svg');
						}
					}
				}
				.team-name {
					padding-top: 0;
					margin: 0;
					font-size: 14px;
				}
				.column-action-menu {
					width: 50px;
				}
				.column-expand-control {
					width: 50px;
					.expanded,
					.collapsed {
						cursor: pointer;
						margin-top: 4px;
						display: inline-block;
						height: 15px;
						width: 30px;
					}
					.expanded {
						background: url('images/up-arrow.svg') no-repeat center center;
					}
					.collapsed {
						background: url('images/header/expanded.svg') no-repeat center center;
					}
				}
				&.row-member {
					td {
						cursor: default;
					}
					.controls span {
						visibility: hidden;
					}
					&:hover {
						background-color: #f3f3f3;
						td {
							.drop-down-v2 {
								input:not(.expanded) {
									background-color: #f3f3f3;
								}
							}
						}
						.controls span {
							visibility: visible;
						}
						cursor: pointer;
					}
					.team {
						padding-left: 50px;
					}
					.person {
						overflow: hidden;
						.wrapper {
							display: flex;
							flex: 1 1 40px;
							flex-direction: row;
							justify-content: flex-start;
							overflow: hidden;
							align-items: center;
							.image-container {
								width: 22px;
								float: left;
								height: 25px;
								margin-bottom: 0px;
								margin-right: 6px;
								.profile-picture {
									margin: 0;
									position: relative;

									img {
										width: 22px;
										height: 22px;
									}
								}
								.deactivated {
									height: 36px;
									width: 36px;
									position: absolute;
									z-index: 10;
									border-radius: 50%;
									background: rgba(0, 0, 0, 0.75);
								}
							}
							.name-tile {
								h3 {
									padding-top: 0;
									margin: 0;
									font-size: 13px;
									color: $v2-text-gray;
									font-weight: 400;
								}
								p {
									margin-top: 5px;
									font-size: 11px;
								}
							}
						}
					}
					.gear-menu {
						position: relative;
						margin-left: auto;
						padding-left: 100px;
						width: 5%;
						&:focus {
							outline: none;
						}
						> span {
							cursor: pointer;
							margin-top: 4px;
							display: inline-block;
							height: 15px;
							width: 30px;
							background: url('images/sections/project/settings/navigation-show-more-horizontal.svg') no-repeat;
							background-position: center;
						}
						.gear-menu-drop-down {
							position: absolute;
							right: 50px;
							border: 1px solid $app-border-color;
							border-bottom-left-radius: 3px;
							border-bottom-right-radius: 3px;
							background-color: $color-white;
							width: 120px;
							z-index: 10;
							ul {
								padding: 0;
								margin: 0;
								list-style: none;
							}

							&.collapsed {
								display: none;
							}
							&.expanded {
								display: block;
							}
							.settings-drop-down {
								color: $color-black;
								.option {
									background-color: $color-white;
									cursor: pointer;
									padding: 10px;
									&:hover {
										color: $color-purple-hover-active;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
