.context-menu-v2 {
	//@include context-menu;
	position: absolute;
	color: $v2-text-gray;
	border-radius: 4px;
	min-height: 36px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
	background-color: $color-white;
	border: solid 1px $v2-grey;
	z-index: 2;
	outline: none;
	margin: 0;
	padding: 8px 0;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
	list-style-type: none;
	text-align: left;

	font-size: 12px;
	font-style: normal;
	font-stretch: normal;
	line-height: 0.92;
	letter-spacing: normal;
	font-weight: 500;
	cursor: pointer;

	.context-menu-option {
		padding: 8px 24px;
		margin-bottom: 4px;

		&:hover {
			color: $v2-branding-purple;
			background: rgba(110, 15, 234, 0.1);
		}

		&.disabled {
			color: $v2-text-light-gray;
			cursor: default;

			&:hover {
				background: none;
			}
		}

		&.selected {
			background-color: $color-brand-50;

			&:hover {
				background-color: $color-brand-50;
			}
		}

		&.bottom-option {
			border-top: 1px solid #E7E7F3;
		}

		&.bottom-option ~ .bottom-option {
			border: none;
		}
	}

	&.new-style {
		font-size: 13px;
		font-weight: 400;
		box-shadow: 0px 2px 8px rgba(0,0,0,0.12);
		border-radius: 8px;

		.context-menu-option {
			padding: 8px 16px;
			margin: 0;
			&:hover, &:focus {
				color: #535353;
				background: #F7F7FE;
			}
		}
	}

	:focus {
		outline: none;
	}
}
