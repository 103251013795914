.not-found-modal {
	height: 100%;
	z-index: 99;
	background: rgba(0, 0, 0, 0.75);
	display: flex;
	justify-content: center;
	align-items: center;
	.content {
		width: 350px;
		height: auto;
		padding: 1rem;
		background-color: $color-white;
		border-radius: 5px;
		.header {
			display: flex;
			flex: 0 0 50px;
			flex-direction: row;
			justify-content: space-between;
			margin-bottom: 20px;
			h2 {
				margin: 0;
			}
			.close-button {
				width: 25px;
				height: 25px;
				border: none;
				vertical-align: center;
				background-color: transparent;
				background-position: center;
				background-repeat: no-repeat;
				outline: 0;
				cursor: pointer;
				background-image: url('images/components/overlay/close.svg');
				&:hover {
					background-image: url('images/components/overlay/close-active.svg');
				}
			}
		}
		.info-msg {
		}
	}
}
