.my-list {
	display: flex;
	flex-direction: row;
	flex-grow: 1;

	.half-page-container {
		flex-grow: 1;
		max-width: calc(100% - 670px);
	}
	.full-page-container {
		flex-grow: 1;
		max-width: calc(100% - 30px);
	}
	.my-list-alltasks-container {
		background-color: #f5f5f8;
		border-right: 1px solid $v2-button-very-light-grey;
		width: 670px;
		flex-grow: 0;
		@include standard-table-v2;
		&.collapsed {
			width: 30px;
			cursor: pointer;
			.title {
				color: $v2-text-gray;
				padding-right: 6px;
				left: 50%;
				top: 6px;
				font-size: 12px;
				font-weight: 500;
				writing-mode: vertical-lr;
				transform: rotate(180deg);
			}
			.header-bar {
				padding-left: 0;
				.header-bar-right {
					> * {
						margin-left: 0;
					}
				}
			}
		}

		.header-bar {
			padding-right: 2px;
			padding-left: 26px;
			.header-bar-left {
				> span {
					padding-top: 8px;
					font-family: $heading-font-family;
					font-weight: 600;
				}
			}
			.filter-v3 {
				background-color: white;
			}
			.expand-control {
				height: 30px;
				cursor: pointer;
				padding-top: 7px;
				padding-right: 15px;
				padding-left: 15px;
				&:hover {
					background-color: rgba(0, 0, 0, 0.2);
				}
				&.expand {
					background: url('images/v2/components/dropdown/arrow-right.svg') no-repeat center;
					&:hover {
						background: rgba(0, 0, 0, 0.2) url('images/v2/components/dropdown/arrow-right.svg') no-repeat center;
					}
				}
				&.collapse {
					background: url('images/v2/components/dropdown/arrow-left.svg') no-repeat center;
					&:hover {
						background: rgba(0, 0, 0, 0.2) url('images/v2/components/dropdown/arrow-left.svg') no-repeat center;
					}
				}
			}
		}

		.table-title {
			margin: 0 24px 16px 24px;
			//font-family: 'urbane', sans-serif;
			font-size: 16px;
			font-weight: 600;
			color: #535353;
		}
		.table-wrapper {
			margin: 0 24px;
			background-color: white;
			border: solid 1px $v2-button-very-light-grey;
			border-bottom: 0px;

			.task-table-header-v3 {
				padding-left: 10px;
				.row-project-id {
					padding-left: 10px;
				}
				.row-status {
					padding-left: 0;
				}
			}

			.task-table-v3 {
				.header-wrapper {
					cursor: default;
				}
				.table-row-v3 {
					.elem-wrapper {
						.project-indicator-component {
							margin-left: 6px;
						}
						.unfocused-assigned-container {
							.assigned-list.disabled {
								.name {
									color: $v2-text-gray;
								}
							}
						}
					}
					.star-option {
						right: 10px;
					}
				}
				.virtualized-scrollbar-inner,
				.no-scroll-container {
					padding: 0 10px;
				}
			}
		}
	}
}
