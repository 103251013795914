/**
 * Section header.
 */
.section-header {
	min-height: $section-header-height;
	flex: 0 0 auto;

	display: flex;
	flex-direction: row;

	.section-title {
		flex: 0 0 auto;
		margin: 0 15px 0 0;
		line-height: $section-header-height;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.section-project-group-link {
		flex: 0 0 auto;
		margin: 0 15px 0 50px;
		line-height: $section-header-height;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.quick-card-button {
		@include white-button;
		width: auto;
	}

	.filters {
		flex: 1;
		display: flex;
		flex-flow: row nowrap;
		.filter {
			&:first-child {
				margin-left: auto;
			}
		}

		.button-group {
			display: flex;
			flex-direction: row;
			margin: 6px 0;
			&:last-child {
				margin-left: 15px;
			}
			@include button-group;
		}
	}
}

/*
 * new navigation admin section header
 */
.settings-section-header,
.my-profile-section-header {
	@include section-header;
}

.settings-section-header {
	ul {
		flex-wrap: wrap;
		li {
			height: 34px;
		}
	}
}

/*
*Project color indicator
*/
.project-color-indicator {
	height: 2px;
}

.project-color-indicator-card {
	height: 0.5rem;
}

/**
 * Section layout.
 */
.section-main {
	flex: 1 1 auto;
	&.v2 {
		flex-direction: column;
		background-color: $v2-section-main-background;
		.drag-drop-context {
			height: 100%;
		}
	}
	//This seems wrong. First of all class has nothing to do with style changes and this class is also used in new workflow which now has no margin
	&.white {
		background-color: $v2-section-main-background;
	}
	background: $section-main-background;
	@include flex-parent(row);
	height: 100% !important;
	.section-nav {
		flex: 0 0 auto;
		//background: white;
	}

	.section-content {
		display: flex;
		flex: 1 1 auto;
		flex-direction: column;
		margin: 1.5625rem 2rem 0 1rem;
		&.useNewNavigation {
			margin-right: 0;
			header,
			.section-body {
				margin-right: 2rem;
			}
		}
		overflow: hidden;
		.section-body {
			height: 100%;
		}
		&.project-scoping {
			margin: 0;
		}
	}
}

/**
 * Section sidebar navigation.
 */
nav.section-sidebar {
	@include scroll-y;
	flex: 0 0 150px;
	margin-top: 1.5rem;
	background: $color-white;
	border-right: 1px solid $app-border-color;
	border-radius: 0 7px 0 0;
	box-shadow: 0 0 2px 2px #e6e6e6;
	border-left: none;
	display: flex;
	flex-direction: column;
	user-select: none;
	/* Show/hide logic */

	.active {
		color: $color-purple-hover-active;
	}

	&.expanded {
		.show-menu {
			display: none;
			height: 45px;
		}
	}

	&.collapsed {
		flex: 0 0 60px;
		.hide-menu {
			display: none;
		}

		a {
			span {
				display: none;
			}
		}
	}

	.hide-menu {
		border-bottom: 1px solid $app-border-color;
		font-size: 0.875rem;
		&:hover {
			cursor: pointer;
		}
	}

	.show-menu {
		border-bottom: 1px solid $app-border-color;
		&:hover {
			cursor: pointer;
		}
	}

	/* Nav divider */

	.nav-divider {
		padding: 10px 0;

		&:hover {
			cursor: default;
		}

		.expanded {
			font-weight: 700;
			font-size: 12px;
			padding: 15px;
			text-transform: uppercase;
			letter-spacing: 0.5px;
			color: $nav-section-header;
		}

		.collapsed {
			display: none;
		}
	}

	a {
		flex: 0 0 36px;
		background-position: 15px center;
		background-repeat: no-repeat;
		background-size: 24px;

		text-decoration: none;
		margin: 0px;
		line-height: 14px;
		box-sizing: border-box;
		padding: 0px 15px 0px 45px;
		transition: all 0.2s ease;
		border-top: 1px solid transparent;
		border-bottom: 1px solid transparent;

		font-family: $heading-font-family;
		font-weight: 600;
		font-size: 16px;
		display: flex;
		flex-direction: column;
		justify-content: center;

		&.hide-menu {
			padding: 0px 15px 0px 45px;
		}
		&.show-menu {
			padding: 0px 15px 0px 45px;
		}
	}
}
