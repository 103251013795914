.person-card {
	cursor: move;
	.card-header {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		@include data-tile;
		label {
			cursor: move;
		}
	}

	.allocated {
		display: none;
	}
}
