.date-range-picker-wrapper-new,
.fixed-range-picker-wrapper-new {
	position: relative;
	display: flex;
	flex-direction: column;
	&.compact {
		.buttons-wrapper {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
		.calendars-dropdown-wrapper {
			top: 55px;
		}
	}

	.buttons-wrapper {
		display: flex;
		flex-direction: row;
		.button-dash-separator {
			padding-top: 11px;
			margin: 0 3px;
		}
	}

	.date-picker-label {
		height: 11px;
		font-size: 9px;
		font-weight: 500;
		line-height: 1.22;
		letter-spacing: 1px;
		color: #a1a1a1;
		text-transform: uppercase;
		white-space: nowrap;
	}
}
.root-portal-container {
	.calendars-dropdown-wrapper {
		position: absolute;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: white;
		padding: 32px;
		padding-bottom: 0;
		box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
		border: 1px solid $app-border-color;
		z-index: 900;
		&.v3 {
			border: 1px solid $v2-branding-purple;
		}
		&.v3-clearable {
			padding: 0;
		}
		&.clearable {
			padding-bottom: 68px;
		}
		.dates-from {
			max-width: 500px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			span {
				color: $v2-text-gray;
			}
		}
		.clear-button {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 36px;
			border: none;
			border-top: 1px solid $app-border-color;
			line-height: 36px;
			text-align: center;
			font-size: 12px;
			font-weight: 500;
			&:hover,
			&:focus {
				color: $v2-branding-purple;
			}
		}
		.calendars-wrapper {
			display: flex;
			flex-direction: row;
			height: 283px;
			&.v3-clearable {
				margin: 32px;
				margin-bottom: 0;
			}
			&:focus {
				outline: none;
			}
			.triangle-up {
				position: absolute;
				top: -15px;
				left: 46px;
				// TODO: Should find a better way to calculate triangle left
				&.fixed-range-picker {
					left: 95px;
					&.wide {
						left: 162px;
						&.ignore-offset {
							left: 75px;
						}
					}
				}
				.border-cover {
					background-color: white;
					height: 1px;
					width: 22px;
					position: absolute;
					bottom: 4px;
					left: 1px;
					z-index: 2;
				}
			}
			.triangle-down {
				position: absolute;
				top: calc(100% - 4px);
				transform: rotate(180deg);
				left: 46px;
				&.fixed-range-picker {
					left: 95px;
					&.wide {
						left: 162px;
						&.ignore-offset {
							left: 75px;
						}
					}
				}
				.border-cover {
					background-color: white;
					height: 1px;
					width: 22px;
					position: absolute;
					top: 12px;
					left: 1px;
					z-index: 2;
				}
			}
			.spacer {
				width: 2px;
				display: flex;
				background-color: $app-border-color;
				margin: 0 31px 0 31px;
			}
		}
		.dates-from,
		.limite-date {
			font-size: 10px;
			letter-spacing: 1.1px;
			margin-top: 7px;
			.star {
				font-size: 12px;
				font-weight: bold;
				padding-right: 4px;
			}
		}
		.limite-date {
			max-width: 260px;
			text-align: center;
			span {
				color: $v2-text-gray;
			}
		}
		.footer-section-v3 {
			display: flex;
			flex-direction: row;
			width: 100%;
			justify-content: center;
			padding: 8px 32px;
			border-top: 1px solid $app-border-color;
			margin-top: 12px;
			&.extra-margin {
				margin-top: 25px;
			}
			&.clearable {
				justify-content: space-between;
			}
			.calender-icon {
				background-size: 20px;
				width: 20px;
				height: 20px;
				background-image: url('images/v2/calender.svg');
				margin-right: 8px;
			}
			.trash-icon {
				background-size: 20px;
				width: 20px;
				height: 20px;
				background-image: url('images/v2/trash.svg');
				margin-right: 8px;
			}
			.footer-button {
				display: flex;
				align-items: center;
				border: none;
				text-transform: uppercase;
				color: $v2-text-gray;
				font-weight: 700;
				font-size: 11px;
				letter-spacing: 0.5px;
				padding: 4px;
				&:hover {
					background-color: rgba(0, 0, 0, 0.1);
				}
			}
		}
	}
}
.fixed-range-picker-wrapper-new {
	&.compact {
		.calendars-dropdown-wrapper {
			width: auto;
			top: 48px;
		}
	}
}
