.task-table {
	border-collapse: separate !important;
	border-spacing: 0 3px;
	min-width: 100%;
	.sorting-icon {
		margin-left: 4px;
		width: 11px;
		height: 11px;
		&.asc,
		&.desc {
			background-repeat: no-repeat;
			background-position: center;
		}
		&.asc {
			background-image: url(images/v2/sort-ascending.svg);
		}
		&.desc {
			background-image: url(images/v2/sort-descending.svg);
		}
	}
	.th-multi-line-container {
		display: flex;
		flex-direction: row;
		.sorting-icon {
			margin-top: 10px;
		}
	}
	.task-table-header {
		cursor: pointer;
		position: relative;
		height: 10px;
		text-align: left;
		font-family: $text-font-family;
		font-size: 10px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.1;
		letter-spacing: 1.1px;
		color: $v2-text-light-gray;
		text-transform: uppercase;
		padding-right: 5px;
		padding-bottom: 6px;
		.th-text {
			display: flex;
			flex-direction: row;
		}
		span {
			float: left;
		}

		&.sortable {
			cursor: pointer;
		}

		&.sorting-column {
			font-family: $text-font-family;
			font-size: 10px;
			font-weight: 500;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.1;
			letter-spacing: 1.1px;
			color: $v2-branding-purple;
			padding-right: 20px !important;

			.sorting-column-text {
				text-transform: capitalize;
				font-family: $text-font-family;
				font-size: 10px;
				font-weight: 500;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.1;
				letter-spacing: 1.1px;
				color: $v2-branding-purple;
				float: left;
				padding-left: 5px;
			}

			&.sorted-ascending:after,
			&.sorted-descending:after {
				border-left: solid 5px transparent;
				border-right: solid 5px transparent;
				height: 0px;
				width: 0px;
				content: '';
				position: relative;
				left: 4px;
			}

			&.sorted-ascending:after {
				bottom: 8px;
				border-bottom: solid 4px $v2-branding-purple;
			}
			&.sorted-descending:after {
				top: 8px;
				border-top: solid 4px $v2-branding-purple;
			}
		}
	}

	.task-table-row {
		cursor: pointer;

		.last-cell {
			width: 18px;
			.chip-container {
				overflow: hidden;
				position: relative;
				.chip-div {
					width: 18px;
					height: 56px;
					border-right: solid 1px $v2-table-border-color;
					border-bottom: solid 1px $v2-table-border-color;
				}
				.chip {
					border-bottom: solid 1px $v2-table-border-color;
					content: '';
					position: absolute;
					height: 20px;
					width: 22px;
					display: block;
					top: -13px;
					right: -6px;
					background-color: white;
					transform: rotate(30deg);
				}
			}
		}

		.task-table-cell {
			min-height: 56px;
			height: 56px;
			max-height: 56px;
			position: relative;
			border-top: solid 1px $v2-table-border-color;
			border-bottom: solid 1px $v2-table-border-color;

			&.color-cell {
				padding-right: 14px;
				.color-thumb {
					width: 6px;
					height: 100%;
				}
			}

			&.descrip-cell {
				padding-right: 14px;
				.top-row,
				.bottom-row {
					display: flex;
					flex-direction: row;

					.flag-icon,
					.warning-icon {
						width: 14px;
						height: 16px;
						background-repeat: no-repeat;
						background-size: cover;
						margin-right: 5px;
					}

					.flag-icon {
						background-image: url(images/v2/components/upcoming-work/flagged.svg);
					}

					.warning-icon {
						width: 17px;
						&.yellow {
							background-image: url(images/v2/components/upcoming-work/warningicon-yellow.svg);
						}
						&.red {
							background-image: url(images/v2/components/upcoming-work/warningicon-red.svg);
						}
					}

					.task-id-status {
						font-family: $text-font-family;
						font-size: 10px;
						font-weight: 500;
						font-style: normal;
						font-stretch: normal;
						line-height: 1.3;
						letter-spacing: 0.7px;
						color: $v2-text-light-gray;
						text-transform: uppercase;
					}
					.task-name {
						font-family: $text-font-family;
						font-size: 14px;
						font-weight: bold;
						font-style: normal;
						font-stretch: normal;
						line-height: 1.29;
						letter-spacing: normal;
						color: $v2-text-gray;
					}
				}
			}
			&.owner-cell {
				padding-right: 14px;
				.task-client {
					font-family: $text-font-family;
					font-size: 12px;
					font-weight: 500;
					font-style: normal;
					font-stretch: normal;
					line-height: 1.25;
					letter-spacing: normal;
					color: $v2-text-gray;
				}
				.task-project {
					font-family: $text-font-family;
					font-size: 12px;
					font-weight: 500;
					font-style: normal;
					font-stretch: normal;
					line-height: 1.25;
					letter-spacing: normal;
					color: $v2-text-light-gray;
				}
			}
			&.details-cell {
				padding-right: 14px;
				.task-sprint {
					font-family: $text-font-family;
					font-size: 12px;
					font-weight: 500;
					font-style: normal;
					font-stretch: normal;
					line-height: 1.25;
					letter-spacing: normal;
					color: $v2-text-gray;
				}
				.task-phase {
					font-family: $text-font-family;
					font-size: 12px;
					font-weight: 500;
					font-style: normal;
					font-stretch: normal;
					line-height: 1.25;
					letter-spacing: normal;
					color: #a5a5a5;
				}
			}
			&.dates-cell {
				padding-right: 14px;
				.date-cell-container {
					display: flex;
					flex-direction: row;
					align-items: center;
					.date-container {
						width: 50px;
						.date-month-and-day {
							font-family: $text-font-family;
							font-size: 13px;
							font-weight: bold;
							font-style: normal;
							font-stretch: normal;
							line-height: 0.77;
							letter-spacing: normal;
							color: $v2-text-gray;
						}
						.date-year {
							position: relative;
							top: 4px;
							font-family: $text-font-family;
							font-size: 10px;
							font-weight: normal;
							font-style: normal;
							font-stretch: normal;
							line-height: 1.4;
							letter-spacing: 1.1px;
							color: $v2-text-gray;
						}
					}
					.date-arrow {
						height: 8px;
						width: 8.5px;
						position: relative;
						top: -6px;
						left: -4px;
						background-image: url(images/v2/components/table/date-arrow.svg);
						background-repeat: no-repeat;
						background-size: cover;
					}
				}
			}
			&.progress-pie {
				padding-right: 14px;
			}
			&.text-cell {
				padding-right: 14px;
				.text-cell-label {
					font-family: $text-font-family;
					text-transform: uppercase;
					font-size: 10px;
					font-weight: 500;
					font-style: normal;
					font-stretch: normal;
					line-height: 1.1;
					letter-spacing: 1.1px;
					color: $v2-text-light-gray;
					position: relative;
					top: 0px;
				}
				.text-cell-value-container {
					display: flex;
					flex-direction: row;
					position: relative;
					top: 5px;
					.text-cell-value {
						font-family: $text-font-family;
						font-size: 12px;
						font-weight: bold;
						font-style: normal;
						font-stretch: normal;
						line-height: 0.92;
						letter-spacing: 1px;
						color: $v2-text-gray;
						text-align: left;

						&.red-text {
							color: $v2-workflow-task-warning-red;
						}
					}
					.text-cell-unit {
						font-family: $text-font-family;
						font-size: 10px;
						font-weight: normal;
						line-height: 1.1;
						letter-spacing: normal;
						font-style: normal;
						font-stretch: normal;
						color: $v2-text-gray;
						text-align: left;

						&.red-text {
							color: $v2-workflow-task-warning-red;
						}
					}
				}
			}
			&.assigned-cell {
				padding-right: 14px;
				.assigned-persons-container {
					display: flex;
					flex-direction: row;
					.assigned-person-svg {
						width: 24px;
						height: 28px;
						.assigned-person {
							width: 24px;
							height: 28px;

							-webkit-clip-path: url(#hexagon-person-mask);
							clip-path: url(#hexagon-person-mask);
							-moz-clip-path: url(#hexagon-person-mask);
							-ms-clip-path: url(#hexagon-person-mask);

							transform: translate(1px);
							-webkit-transform: translate(1px);
							-moz-transform: translate(1px);
							-ms-transform: translate(1px);
						}
					}

					.additional-assigned-persons-container {
						height: 25px;
						position: relative;
						top: 2px;
						padding-left: 2px;
						.assigned-persons-count {
							font-family: $text-font-family;
							font-size: 11px;
							font-weight: 500;
							font-style: normal;
							font-stretch: normal;
							line-height: normal;
							letter-spacing: -0.5px;
							text-align: center;
							color: white;
							position: relative;
							bottom: 23px;
						}
					}
				}
			}
			&.appendix-cell {
				padding-right: 14px;
				.icon-value-container {
					display: flex;
					flex-direction: row;
					padding-top: 4px;

					.icon {
						width: 13.2px;
						height: 11px;
						background-repeat: no-repeat;
						background-size: cover;
						&.subtasks {
							background-image: url('images/v2/components/upcoming-work/subtaskicon.svg');
						}
						&.comments {
							background-image: url('images/v2/components/upcoming-work/commenticon.svg');
						}
					}
					.type-count {
						padding-left: 5px;
						font-family: $text-font-family;
						font-size: 10px;
						font-weight: 500;
						font-style: normal;
						font-stretch: normal;
						line-height: normal;
						letter-spacing: normal;
						color: $v2-text-gray;
					}
				}
			}
		}
	}
}
