.settings-api-keys {
	&.section-content {
		margin: 0;
		.section-body {
			margin: 0 24px;
		}
	}
	.top-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		.description {
			margin: 0;
		}
		.add-api-key-button {
			@include primary-button;
		}
	}

	@include standard-table;
	.api-key-table {
		margin-top: 25px;

		.controls {
			span {
				background-size: 16px;
				background-repeat: no-repeat;
				background-position: left center;
				padding: 0 10px 0 21px;
				cursor: pointer;
				&.remove {
					background-image: url('images/cross-section-icons/actions/remove.svg');
					&:hover {
						text-decoration: underline;
					}
				}
				&:hover {
					.controls span {
						visibility: visible;
					}
					cursor: pointer;
				}
			}
		}

		.api-key-row {
			.name-input {
				width: 100%;
			}
			.controls span {
				visibility: hidden;
			}
			&:hover {
				.controls span {
					visibility: visible;
				}
				cursor: pointer;
			}
		}
	}
}
