.custom-button-container {
	display: inline-block;
	position: relative;
	cursor: pointer;
	box-sizing: border-box;

	&.rounded-borders {
		border-radius: 4px;
	}

	&.on-hover-filled {
		&:hover,
		&:focus {
			opacity: 1;
			color: $color-white;

			&.purple {
				color: $color-white;
				background-color: $v2-branding-purple;
			}
			&.grey {
				color: $color-white;
				background-color: $v2-background-dark-gray;
			}
			&.green {
				color: $color-white;
				background-color: $v2-create-button-green;
			}
			&.fadedGrey {
				color: #989898;
				background-color: $v2-button-medium-grey;
			}
			&.white {
				color: $v2-branding-purple;
				background-color: $color-white;
			}
			&.white-active {
				color: $v2-branding-purple;
				background-color: $color-white;
			}
			&.red {
				color: $color-white;
				background-color: #e21e5b;
			}
			&.pink {
				background-color: $v2-button-pink;
				color: $color-white;
			}
			&.light {
				background-color: $v2-button-light;
				color: $v2-text-gray;
			}
			&.green-approve {
				background-color: $v2-button-green-approve;
				color: $color-white;
			}
			&.modern-blue { // Updated brand appearance 2023
				background-color: #0004DB;
				color: $color-white;
			}
		}
	}

	&.filled {
		border: 1px solid;
		color: white;
		&.ai-blue {
			background-color: $ai-blue;
			border-color: $ai-blue;
		}
		&.purple {
			background-color: $v2-branding-purple;
			border-color: $v2-branding-purple;
		}
		&.grey {
			background-color: $v2-background-dark-gray;
			border-color: $v2-background-dark-gray;
		}
		&.green {
			background-color: $v2-create-button-green;
			border-color: $v2-create-button-green;
		}
		&.fadedGrey {
			color: #989898;
			background-color: #f5f5f5;
			border-color: #f5f5f5;
		}
		&.white {
			color: $v2-text-gray;
			background-color: white;
			border-color: white;
		}
		&.white-active {
			color: $v2-branding-purple;
			background-color: white;
			border-color: white;
		}
		&.red {
			color: $color-white;
			background-color: $negative-value;
			border-color: $negative-value;
		}
		&.mediumgrey {
			background-color: $v2-button-medium-grey;
			color: $v2-text-gray;
			border-color: $v2-button-medium-grey;
		}
		&.pink {
			background-color: $v2-button-pink;
			color: white;
			border-color: $v2-button-pink;
		}
		&.light {
			background-color: $v2-button-light;
			color: $v2-text-gray;
			border-color: $v2-button-light;
		}
		&.green-approve {
			background-color: $v2-button-green-approve;
			color: $color-white;
			border-color: $v2-button-green-approve;
		}
		&.modern-blue { // Updated brand appearance 2023
			background-color: #0004DB;
			color: $color-white;
			border-color: #0004DB;
		}
	}

	&.text {
		border: none;
		background: none;
		color: white;

		&.ai-blue {
			color: $ai-blue;
		}
		&.purple {
			color: $v2-branding-purple;
		}
		&.grey {
			color: $v2-background-dark-gray;
		}
		&.green {
			color: $v2-create-button-green;
		}
		&.fadedGrey {
			color: #f5f5f5;
		}
		&.white {
			color: white;
		}
		&.red {
			color: $negative-value;
		}
		&.mediumgrey {
			color: $v2-button-medium-grey;
		}
		&.pink {
			color: $v2-button-pink;
		}
		&.light {
			color: $v2-button-light;
		}
		&.green-approve {
			color: $v2-button-green-approve;
		}
	}

	&.outline {
		border-width: 2px;
		border-style: solid;
		&.thick {
			border-width: 1px;
		}
		&.ai-blue {
			color: $ai-blue;
			border-color: $ai-blue;
		}
		&.purple {
			color: $v2-branding-purple;
			border-color: $v2-branding-purple;
		}
		&.grey {
			color: $v2-background-dark-gray;
			border-color: $v2-background-dark-gray;
		}
		&.lightgrey {
			border-color: $v2-button-light-grey;
		}
		&.very-lightgrey {
			color: $v2-text-gray;
			border-color: $v2-button-very-light-grey;
		}
		&.green {
			color: $v2-create-button-green;
			border-color: $v2-create-button-green;
		}
		&.fadedGrey {
			color: #989898;
			border-color: $v2-button-medium-grey;
		}
		&.white {
			border-width: 1px;
			color: $v2-text-gray;
			border-color: $v2-date-picker-button-border-color;
		}
		&.mediumgrey {
			color: $v2-text-gray;
			border-color: $v2-button-medium-grey;
		}
		&.red {
			color: $negative-value;
			border-color: $negative-value;
		}
		&.pink {
			background-color: $v2-button-pink;
			color: white;
			border-color: $v2-button-pink;
		}
		&.light {
			background-color: $v2-button-light;
			color: $v2-text-gray;
			border-color: $v2-button-light;
		}
		&.green-approve {
			background-color: $v2-button-green-approve;
			color: $color-white;
			border-color: $v2-button-green-approve;
		}
		&.modern-blue { // Updated brand appearance 2023
			color: #0004DB;
			border-color: #0004DB;
		}
	}
	&:hover,
	&:focus {
		opacity: 0.8;
		outline: none;
		&.unit-toggle-button {
			opacity: 1;
		}
		&.has-tooltip-label {
			opacity: 1;
		}
		&.very-lightgrey {
			opacity: 1;
			border-color: $v2-button-very-light-grey-hovered;
		}
		&.disabled {
			&.very-lightgrey {
				border-color: $v2-button-very-light-grey;
			}
			opacity: 1;
		}
		&.white {
			background-color: $v2-button-medium-grey;
			opacity: 1;
		}
		&.transparent {
			background-color: transparent;
		}
	}
	&.focus-style {
		&:focus {
			outline: none;
			background-color: $v2-branding-purple;
			color: white;
		}
	}
	&.disabled {
		opacity: 0.5;
		&:hover {
			opacity: 0.5;
		}
	}
	.input-container {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-content: center;
		align-items: center;
		margin: 0;
		height: 100%;
		width: 100%;
		.symbol {
			background-size: contain;
			background-repeat: no-repeat;
			height: 10px;
			width: 10px;
			&.plus-grey {
				background-image: url('images/v2/components/header/plus-grey.svg');
			}
			&.plus-white {
				background-image: url('images/v2/components/header/plus.svg');
			}
			&.dependencies {
				background-image: url('images/v2/task-modal/dependencies.svg');
			}
			&.subtasks {
				background-image: url('images/v2/task-modal/subtasks-dark.svg');
			}
			&.file-upload {
				background-image: url('images/v2/components/project-files/upload-icon.svg');
			}
			&.email {
				background-image: url('images/email-icon.svg');
			}
			&.arrow-right {
				background-image: url('images/v2/components/button/arrow-right.svg');
			}
			&.check-circle {
				background-image: url('images/v2/components/button/check-circle.svg');
			}
			&.no-text {
				margin: 0;
			}
		}

		.symbol-first {
			margin-right: 7px;
		}

		.symbol-last {
			margin-left: 7px;
		}

		.text {
			outline: none;
			border: none;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			text-align: center;
		}

		.normal-font-weight {
			font-weight: normal;
		}

		.uppercase-text {
			text-transform: uppercase;
			font-size: 10px;
			letter-spacing: 1px;
		}

		.lowercase-text {
			font-size: 13px;
			letter-spacing: 0px;
		}
	}
	.tooltip-label {
		color: $v2-text-gray;
		display: flex;
		align-items: center;
		height: 100%;
		position: relative;
		.tooltip-button {
			text-transform: uppercase;
			height: 25px;
		}
	}

	.ai-icon {
		margin-right: 8px;
	}

	&.has-tooltip-label {
		align-items: center;
		display: flex;
		.input-container {
			justify-content: left;
			margin-left: 10px;
		}
	}
}
