.person-initials-hexagon-shape {
	position: relative;
	background-color: $v2-branding-purple;
	&:before,
	&:after {
		content: '';
		position: absolute;
		width: 0;
		left: 0px;
		border-left: 12.5px solid transparent;
		border-right: 12.5px solid transparent;
	}

	&:before {
		bottom: 100%;
		border-bottom: 7.22px solid $v2-branding-purple;
	}

	&:after {
		top: 100%;
		width: 0;
		border-top: 7.22px solid $v2-branding-purple;
	}
	&.EXTRA_SMALL {
		width: 21px;
		height: 12.12px;
		margin: 6.06px 0;
		&:before,
		&:after {
			border-left: 10.5px solid transparent;
			border-right: 10.5px solid transparent;
		}
		&:before {
			border-bottom: 6.06px solid $v2-branding-purple;
		}
		&:after {
			border-top: 6.06px solid $v2-branding-purple;
		}
		.initials {
			font-size: 9px;
		}
	}
	&.SMALL {
		width: 23px;
		height: 13.28px;
		margin: 6.64px 0;
		&:before,
		&:after {
			border-left: 11.5px solid transparent;
			border-right: 11.5px solid transparent;
		}
		&:before {
			border-bottom: 6.64px solid $v2-branding-purple;
		}
		&:after {
			border-top: 6.64px solid $v2-branding-purple;
		}
		.initials {
			font-size: 10px;
		}
	}
	&.MEDIUM {
		width: 25px;
		height: 14.43px;
		margin: 7.22px 0;
		&:before,
		&:after {
			border-left: 12.5px solid transparent;
			border-right: 12.5px solid transparent;
		}
		&:before {
			border-bottom: 7.22px solid $v2-branding-purple;
		}
		&:after {
			border-top: 7.22px solid $v2-branding-purple;
		}
		.initials {
			font-size: 11px;
			//margin-top: -2px;
		}
	}
	&.LARGE {
		width: 34px;
		height: 19.63px;
		margin: 9.81px 0;
		&:before,
		&:after {
			border-left: 17px solid transparent;
			border-right: 17px solid transparent;
		}
		&:before {
			border-bottom: 9.81px solid $v2-branding-purple;
		}
		&:after {
			border-top: 9.81px solid $v2-branding-purple;
		}
		.initials {
			font-size: 14px;
			margin-top: 1px;
		}
	}
	&.EXTRA_LARGE {
		width: 125px;
		height: 72.17px;
		margin: 36.08px 0;
		&:before,
		&:after {
			border-left: 62.5px solid transparent;
			border-right: 62.5px solid transparent;
		}
		&:before {
			border-bottom: 36.08px solid $v2-branding-purple;
		}
		&:after {
			border-top: 36.08px solid $v2-branding-purple;
		}
		.initials {
			font-size: 61px;
			margin-top: -5px;
		}
	}
	&.HUNDRED {
		width: 86px;
		height: 50px;
		margin: 25px 0;
		&:before,
		&:after {
			border-left: 43px solid transparent;
			border-right: 43px solid transparent;
		}
		&:before {
			border-bottom: 25px solid $v2-branding-purple;
		}
		&:after {
			border-top: 25px solid $v2-branding-purple;
		}
		.initials {
			line-height: 50px;
			font-size: 45px;
		}
	}

	.initials {
		position: absolute;
		color: $color-white;
		width: 100%;
		text-align: center;
		font-weight: 700;
		text-transform: uppercase;
	}
}
