.project-status-modal {
	.status-controls {
		display: flex;
		flex: 0 0 160px;
		flex-direction: row;
		border-bottom: $app-border-color 1px solid;
		padding: 1em;
		.project-status-color:not([disabled]) {
			cursor: pointer;
		}
		.editor {
			width: 550px;
			margin: 0 1em;
		}
		.save-button {
			height: 40px;
			padding: 0 32px;
		}
	}
	.table-wrapper {
		@include standard-table;
		display: flex;
		flex: 1 1 auto;
		padding: 1em;
		min-height: 350px;
		.status-table {
			width: 100%;
			padding-left: 0.5em;
			padding-right: 0.5em;
			height: 100%;
			thead {
				margin-bottom: 15px;
				position: sticky;
			}
			.th-status {
				text-align: center;
			}
			.th-description,
			.th-date,
			.th-person {
				text-align: left;
			}
			.th-status {
				width: 10%;
			}
			.th-description {
				width: 55%;
			}
			.th-date {
				width: 15%;
			}
			.th-person {
				width: 20%;
			}
			.status-color-cell {
				text-align: center;
			}
			.status-description {
				word-break: break-word;
			}
			td {
				vertical-align: top;
			}
		}
		.project-status-empty-state {
			display: flex;
			flex: 1 1 auto;
			justify-content: center;
			align-items: center;
		}
	}
}
