.loader-wrapper-v2 {
	position: relative;
	margin-left: -50px;
	.uploading-icon {
		position: absolute;
		z-index: 2;
		&.file-upload {
			top: 15px;
			left: -8px;
			width: 58px;
			height: 80px;
			background-size: contain;
			background-repeat: no-repeat;
			background-image: url('images/v2/components/project-files/uploading-files-icon.svg');
		}
		&.confirmation-icon {
			top: 25px;
			left: -8px;
			width: 58px;
			height: 80px;
			background-size: contain;
			background-repeat: no-repeat;
			background-image: url('images/v2/ok-green.svg');
		}
	}
	.hexagon-border {
		width: 69px;
		height: 4px;
		position: absolute;
		background: transparent;
		background-size: 0 4px, 100% 4px;
		border-radius: 5px;
		&.border-1 {
			transform: rotate(30deg);
			left: 14px;
			top: 1px;
			z-index: 2;
		}
		&.border-2 {
			top: 52px;
			left: 43px;
			transform: rotate(90deg);
		}
		&.border-3 {
			transform: rotate(150deg);
			top: 103px;
			left: 15px;
		}
		&.border-4 {
			transform: rotate(-150deg);
			top: 103px;
			right: -26px;
		}
		&.border-5 {
			transform: rotate(270deg);
			top: 52px;
			right: 3px;
		}
		&.border-6 {
			transform: rotate(-30deg);
			right: -26px;
			top: 1px;
		}
	}
	&.confirmation {
		.hexagon-border {
			background: $v2-create-button-green no-repeat;
			background-image: linear-gradient(to bottom, $v2-create-button-green, $v2-create-button-green), linear-gradient(to bottom, $v2-create-button-green, $v2-create-button-green);
		}
	}
	&.loader {
		.hexagon-border {
			background: transparent no-repeat;
			background-image: linear-gradient(to bottom, $v2-branding-purple, $v2-branding-purple), linear-gradient(to bottom, $v2-light-gray, $v2-light-gray);
			background-size: 0 4px, 100% 4px;
			@keyframes animate-border-1 {
				0% {
					background-size: 0 4px, 100% 4px;
				}
				17%,
				100% {
					background-size: 100% 4px, 100% 4px;
				}
			}

			@keyframes animate-border-2 {
				17% {
					background-size: 0 4px, 100% 4px;
				}
				33%,
				100% {
					background-size: 100% 4px, 100% 4px;
				}
			}

			@keyframes animate-border-3 {
				33% {
					background-size: 0 4px, 100% 4px;
				}
				50%,
				100% {
					background-size: 100% 4px, 100% 4px;
				}
			}

			@keyframes animate-border-4 {
				50% {
					background-size: 0 4px, 100% 4px;
				}
				74%,
				100% {
					background-size: 100% 4px, 100% 4px;
				}
			}
			@keyframes animate-border-5 {
				74% {
					background-size: 0 4px, 100% 4px;
				}
				85%,
				100% {
					background-size: 100% 4px, 100% 4px;
				}
			}
			@keyframes animate-border-6 {
				85% {
					background-size: 0 4px, 100% 4px;
				}
				100% {
					background-size: 100% 4px, 100% 4px;
				}
			}
			animation-duration: 6s;
			animation-iteration-count: infinite;
			animation-timing-function: ease-in-out;
			animation-fill-mode: forwards;
			-webkit-animation-duration: 6s;
			-webkit-animation-iteration-count: infinite;
			-webkit-animation-timing-function: ease-in-out;
			-webkit-animation-fill-mode: forwards;
			-moz-animation-duration: 6s;
			-moz-animation-iteration-count: infinite;
			-moz-animation-timing-function: ease-in-out;
			-moz-animation-fill-mode: forwards;
			-ms-animation-duration: 6s;
			-ms-animation-iteration-count: infinite;
			-ms-animation-timing-function: ease-in-out;
			-ms-animation-fill-mode: forwards;
			-o-animation-duration: 6s;
			-o-animation-iteration-count: infinite;
			-o-animation-timing-function: ease-in-out;
			-o-animation-fill-mode: forwards;
			&.border-1 {
				animation-name: animate-border-1;
				-webkit-animation-name: animate-border-1;
				-moz-animation-name: animate-border-1;
				-ms-animation-name: animate-border-1;
				-o-animation-name: animate-border-1;
			}
			&.border-2 {
				animation-name: animate-border-2;
				-webkit-animation-name: animate-border-2;
				-moz-animation-name: animate-border-2;
				-ms-animation-name: animate-border-2;
				-o-animation-name: animate-border-2;
			}
			&.border-3 {
				animation-name: animate-border-3;
				-webkit-animation-name: animate-border-3;
				-moz-animation-name: animate-border-3;
				-ms-animation-name: animate-border-3;
				-o-animation-name: animate-border-3;
			}
			&.border-4 {
				animation-name: animate-border-4;
				-webkit-animation-name: animate-border-4;
				-moz-animation-name: animate-border-4;
				-ms-animation-name: animate-border-4;
				-o-animation-name: animate-border-4;
			}
			&.border-5 {
				animation-name: animate-border-5;
				-webkit-animation-name: animate-border-5;
				-moz-animation-name: animate-border-5;
				-ms-animation-name: animate-border-5;
				-o-animation-name: animate-border-5;
			}
			&.border-6 {
				animation-name: animate-border-6;
				-webkit-animation-name: animate-border-6;
				-moz-animation-name: animate-border-6;
				-ms-animation-name: animate-border-6;
				-o-animation-name: animate-border-6;
			}
		}
	}
}
