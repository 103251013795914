.settings-app-jira-server {
	textarea {
		resize: none;
	}
	ul {
		list-style-type: none;
		margin: 0 0 10px 0;
		padding: 0;
		height: auto;
		overflow: auto;
		width: 100%;
		button {
			@include secondary-button;
			text-transform: none;
		}
	}
	.section-body {
		button {
			@include secondary-button;
		}
	}
}
.settings-app-jira-server {
	.options {
		display: flex;
		align-items: flex-end;
		width: 400px;
		button {
			margin-left: 16px;
			@include primary-button;
		}
	}
}
.jira-prefix-options {
	.controls {
		display: flex;
		justify-content: flex-end;
		button {
			height: 40px;
			padding: 0 32px;
		}
	}
}
.jira-unavailable {
	color: $error-text-color;
	margin-left: 50px;
}
.jira-sync-options {
	div {
		max-width: 600px;
	}
	.sync-sprints {
		margin-bottom: 8px;
		input {
			margin-left: 8px;
		}
	}
	.top-margin {
		margin-top: 24px;
	}
	.loading-message {
		padding-bottom: 15px;
		.loading {
			height: 50px;
		}
		.loading:not(:required):after {
			content: '';
			display: block;
			position: relative;
			left: 50%;
			top: 20px;
			font-size: 10px;
			width: 1em;
			height: 1em;
			-webkit-animation: spinner 1500ms infinite linear;
			-moz-animation: spinner 1500ms infinite linear;
			-ms-animation: spinner 1500ms infinite linear;
			-o-animation: spinner 1500ms infinite linear;
			animation: spinner 1500ms infinite linear;
			border-radius: 0.5em;
			-webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0,
				rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
			box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0,
				rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
		}
		@keyframes spinner {
			0% {
				-webkit-transform: rotate(0deg);
				-moz-transform: rotate(0deg);
				-ms-transform: rotate(0deg);
				-o-transform: rotate(0deg);
				transform: rotate(0deg);
			}
			100% {
				-webkit-transform: rotate(360deg);
				-moz-transform: rotate(360deg);
				-ms-transform: rotate(360deg);
				-o-transform: rotate(360deg);
				transform: rotate(360deg);
			}
		}
	}
	.deep-fetch {
		button {
			height: 40px;
			margin-left: 16px;
			padding: 0 32px;
		}
	}
	.drop-down-v2 {
		margin-top: 8px;
	}
	.controls {
		display: flex;
		justify-content: flex-end;
		button {
			height: 40px;
			padding: 0 32px;
		}
	}
}
.create-jira-issue-modal {
	.optional-dropdown {
		margin-top: 8px;
	}
	.field {
		margin-top: 10px;
	}
	.custom-field-header-title {
		height: 11px;
		font-size: 12px;
		font-weight: 500;
		line-height: 1.22;
		letter-spacing: 1px;
		//	color: $v2-text-light-gray;
		text-transform: uppercase;
		margin-top: 20px;
		// margin-bottom: 20px;
		white-space: nowrap;
	}
	.custom-field-input-title {
		height: 11px;
		font-size: 9px;
		font-weight: 500;
		line-height: 1.22;
		letter-spacing: 1px;
		color: $v2-text-light-gray;
		text-transform: uppercase;
		margin-bottom: 5px;
		white-space: nowrap;
		.input {
			font-size: 9px;
		}
	}
	.input-value-container {
		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		border: solid 1px $app-border-color;
		border-radius: 4px;
		background-color: white;
		overflow: hidden;
		white-space: nowrap;
		color: $v2-text-gray;
		//		height: 40px;
		font-weight: 500;
		line-height: normal;
		display: flex;
		flex-direction: row;
		transition: 0.1s border-color;
		.input-value {
			min-width: 0;
			flex: 1 1 auto;
		}
	}
	.jira-checkbox-container {
		margin-top: 12px;
		margin-bottom: 4px;

		input {
			float: right;
			margin-top: 4px;
		}

		.tooltip-container {
			width:100%;
		}
	}
}

.settings-app-jira {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	//align-items: center;
	.header {
		padding: 40px 140px 24px 140px;
	}
	.back-button {
		background-size: 18px;
		background-color: $color-white;
		background-repeat: no-repeat;
		background-position: left;
		background-image: url('images/v2/back.svg');
		font-size: 13px;
		font-weight: 600;
		color: $v2-text-light-gray;
		padding-left: 23px;
		border: none;
	}
	.content {
		display: flex;
		flex-direction: column;
		align-items: center;
		.custom-button-container {
			padding: 8px 16px;
		}
		.radio-button-wrapper {
			align-items: center;
			margin-top: 10px;
			.text {
				font-size: 12px;
				color: $v2-text-gray;
				height: 14px;
			}
		}
		.tablist {
			display: grid;
			grid-template-columns: repeat(4, max-content);
			grid-gap: 22px;
			font-size: 13px;
			color: $v2-text-gray;
			button {
				border: none;
				border-bottom: 3px solid;
				border-color: white;
				padding: 0px 0px 5px 0px;
				&:hover {
					color: $v2-branding-purple;
				}
				&:focus {
					outline: 0;
				}
				&.active {
					color: $v2-branding-purple;
					border-color: $v2-branding-purple;
					font-weight: 500;
				}
			}
		}
		.settings-app-jira-section {
			&.setup {
				width: 684px;
			}
			&.live {
				width: 100%;
			}
			&.inner {
				max-width: 100%;
			}
			display: flex;
			flex-direction: column;
			max-width: 50%;
			margin-top: 48px;
			&.center {
				margin-top: 0;
				span {
					text-align: center;
				}
			}
			&.last {
				margin-bottom: 79px;
			}

			.settings-app-jira-section-title {
				//TODO font family??
				font-weight: 700;
				color: $v2-text-gray;
				margin-bottom: 16px;
				&.large {
					font-size: 20px;
				}
				&.standard {
					font-size: 16px;
				}
				&.small {
					font-size: 14px;
				}
			}
			.settings-app-jira-section-body {
				padding: 16px 24px 12px 24px;
				border: solid 1px $app-border-color;
				.current-settings {
					padding-bottom: 12px;
					.settings-title-container {
						display: flex;
						.current-settings-title {
							margin: 4px 4px 8px 0px;
							font-size: 9px;
							font-weight: 500;
							text-transform: uppercase;
							line-height: 1.22;
							letter-spacing: 1px;
							color: $v2-text-light-gray;
						}
					}
					button.settings-button {
						margin-top: 4px;
					}
				}

				.jira-projects-sync-table {
					width: 100%;
					table-layout: fixed;
					th,
					td {
						text-align: left;
					}
					th {
						font-size: 9px;
						font-weight: 500;
						text-transform: uppercase;
						letter-spacing: 1px;
						color: $v2-text-light-gray;
						padding: 8px 0;
						.logo {
							height: 20px;
							background-size: 20px 20px;
							background-position: left;
							background-repeat: no-repeat;
							padding-left: 28px;
							display: flex;
							align-items: center;
							&.forecast {
								background-image: url($app-header-logo-v2-url);
							}
							&.jira {
								background-image: url('images/integrations/jira-software.png');
							}
						}
						&.expand-control-spacing {
							padding-left: 28px;
						}
					}
					td {
						font-size: 13px;
						color: $v2-text-gray;
						padding: 12px 0;
						vertical-align: top;
						.expand-control {
							width: 12px;
							height: 12px;
							border: none;
							background: url('images/v2/components/dropdown/arrow-down.svg') no-repeat center;
							margin-right: 16px;
							outline: none;
							&.expanded {
								transform: rotate(180deg);
							}
						}
						.project-info-count-wrapper {
							display: flex;
							flex-direction: column;
							padding: 16px 28px;
							.value {
								font-weight: 700;
							}
						}
						.forecast-project-wrapper {
							display: flex;
							.forecast-project-indicator {
								@include project-indicator;
							}
						}
						.project-row {
							display: flex;
							align-items: center;
						}
						input[type='checkbox'] {
							margin: 0px;
							padding: 0px;
						}
					}
					tbody {
						tr {
							border-top: solid 1px $app-border-color;
							&.epics-table {
								td {
									vertical-align: middle;
								}
							}
						}
					}
					.sync-controls-cell {
						width: 80px;
						.unsync-button,
						.sync-button {
							font-size: 13px;
							text-decoration: underline;
							border: none;
							padding: 0;
							&:hover {
								opacity: 0.8;
							}
						}
						.unsync-button {
							color: $negative-value;
						}
						.sync-button {
							color: $link-text-color;
						}
					}
					.check-column-cell {
						width: 18px;
					}
					.pre-spacing {
						padding-left: 16px;
					}
				}
			}
			.jira-cloud-verification-key-controls {
				margin-top: 30px;
				display: flex;
				flex: 0 0 auto;
				height: 30px;
				.copy-jira-verification-key-button {
					margin-left: 8px;
					text-transform: uppercase;
					background-color: $v2-create-button-green;
					color: $color-white;
					padding: 8px 16px;
					font-size: 10px;
					letter-spacing: 0.5px;
					border: none;
					font-weight: 700;
				}
				input {
					min-width: 351px;
					font-weight: 600;
				}
			}
			.info-text {
				display: flex;
				align-content: center;
				flex-wrap: wrap;
				font-size: 13px;
				color: $v2-text-gray;
				&.bold {
					font-weight: 700;
				}
				&.link {
					color: $link-text-color;
				}
			}
			.buttons {
				margin-top: 24px;
				margin-bottom: 65px;
			}
			.images-wrapper {
				display: flex;
				justify-content: space-between;
			}
			.jira-onboarding-image {
				width: 330px;
				height: 181px;
				margin-top: 24px;
				box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
				border-radius: 4px;
			}
			.logos-wrapper {
				display: flex;
				justify-content: center;
				align-items: center;
				background-size: 70px 61px;
				.forecast-logo,
				.jira-cloud-logo {
					height: 70px;
					width: 61px;
					background-size: contain;
					background-repeat: no-repeat;
				}
				.forecast-logo {
					background-image: url($app-header-logo-v2-url);
				}
				.jira-cloud-logo {
					background-image: url('images/integrations/jira-software.png');
				}
				.arrows-icon {
					margin: 0 16px;
					width: 45px;
					height: 25px;
					background: url('images/integrations/controls/arrows.svg') no-repeat center;
				}
			}
		}
		.jira-server-instructions {
			max-width: 800px;
			textarea {
				width: 800px;
			}
			.options {
				align-self: flex-start;
				display: flex;
				align-items: flex-end;
				button {
					height: 37px;
					margin-left: 8px;
				}
			}
		}
		.header-wrapper {
			display: flex;
			margin-bottom: 28px;
			.title-description-wrapper {
				padding-left: 20px;
				p {
					margin-top: 0;
					margin-bottom: 0.5em;
				}
				button {
					margin-right: 8px;
				}
				.description {
					margin-bottom: 8px;
				}
			}
		}
	}
}
