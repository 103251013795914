.settings-team {
	&.useNewNavigation {
		margin-top: 0;
	}
	.controls {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		.expand-control {
			width: 38px;
			height: 38px;
			background-size: 38px;
			background-repeat: no-repeat;
			background-position: center;
			cursor: pointer;
			&.expand {
				background-image: url('images/up-arrow.svg');
			}

			&.collapse {
				background-image: url('images/header/expanded.svg');
			}
		}
	}

	.team-members {
		&.deactivated {
			.top-container {
				margin-right: 50px; // Move arrow to the left so the Hubspot chat icon is not blocking it
			}
		}
		.top-container {
			@include data-tile;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			.add-button {
				@include primary-button;
				width: auto;
			}
			h3 {
				margin-bottom: 0;
			}
		}
		.description {
			margin-bottom: 0;
			display: flex;
			align-items: center;
			.data-tile {
				margin-left: 4em;
				label {
					// text-transform: none;
					color: $color-black;
					font-size: 0.875em;
				}
			}
		}
		@include standard-table;
		table {
			th {
				color: $color-black;
			}
		}
		tbody {
			table-layout: fixed;
			word-wrap: break-word;
			tr {
				.controls {
					span {
						background-size: 16px;
						background-repeat: no-repeat;
						background-position: left center;
						padding: 0 10px 0 21px;
						cursor: pointer;
						&:hover {
							text-decoration: underline;
						}
						&.edit {
							background-image: url('images/cross-section-icons/actions/edit.svg');
						}
						&.remove {
							background-image: url('images/cross-section-icons/actions/remove.svg');
						}
						&.enable {
							background-image: url('images/cross-section-icons/actions/enable.svg');
						}
					}
				}
				.team-name {
					padding-top: 0;
					margin: 0;
					font-size: 14px;
				}
				.column-action-menu {
					width: 50px;
				}
				.column-expand-control {
					width: 50px;
					.expanded,
					.collapsed {
						cursor: pointer;
						margin-top: 4px;
						display: inline-block;
						height: 15px;
						width: 30px;
					}
					.expanded {
						background: url('images/up-arrow.svg') no-repeat center center;
					}
					.collapsed {
						background: url('images/header/expanded.svg') no-repeat center center;
					}
				}
				&.row-member {
					.controls span {
						visibility: hidden;
					}
					&:hover {
						.controls span {
							visibility: visible;
						}
						cursor: pointer;
					}
					.team {
						padding-left: 50px;
					}
					.person {
						overflow: hidden;
						.wrapper {
							height: 40px;
							display: flex;
							flex: 1 1 40px;
							flex-direction: row;
							justify-content: flex-start;
							overflow: hidden;
							.image-container {
								width: 50px;
								float: left;
								height: 40px;
								margin-bottom: 0px;
								.profile-picture {
									margin: 0;
									position: relative;
								}
								.deactivated {
									height: 36px;
									width: 36px;
									position: absolute;
									z-index: 10;
									border-radius: 50%;
									background: rgba(0, 0, 0, 0.75);
								}
							}
							.name-tile {
								h3 {
									padding-top: 0;
									margin: 0;
									font-size: 14px;
								}
								p {
									margin-top: 5px;
									font-size: 11px;
								}
							}
						}
					}
					.gear-menu {
						position: relative;
						margin-left: auto;
						padding-right: 100px;
						width: 5%;
						&:focus {
							outline: none;
						}
						> span {
							cursor: pointer;
							margin-top: 4px;
							display: inline-block;
							height: 15px;
							width: 30px;
							background: url('images/sections/project/settings/navigation-show-more-horizontal.svg') no-repeat;
							background-position: center;
						}
						.gear-menu-drop-down {
							position: absolute;
							right: 50px;
							border: 1px solid $app-border-color;
							border-bottom-left-radius: 3px;
							border-bottom-right-radius: 3px;
							background-color: $color-white;
							width: 120px;
							z-index: 10;
							ul {
								padding: 0;
								margin: 0;
								list-style: none;
							}

							&.collapsed {
								display: none;
							}
							&.expanded {
								display: block;
							}
							.settings-drop-down {
								color: $color-black;
								.option {
									background-color: $color-white;
									cursor: pointer;
									padding: 10px;
									&:hover {
										color: $color-purple-hover-active;
									}
								}
							}
						}
					}
				}
			}
		}
		.member-table {
			margin-top: 40px;
		}
	}
}
