.tooltip-label {
	position: relative;
	z-index: 97;
	color: $v2-text-gray;
	&:focus {
		border: none;
	}
	a {
		color: $v2-text-gray;
	}
	button {
		font-size: 12px;
		padding: 5px 8px;
		font-weight: 700;
		border-radius: 4px;
		border: none;
		background-color: $bulk-update-selected-color;
		letter-spacing: 1.3px;
		outline: none;
		margin-right: 8px;
	}
	&.small {
		.tooltip-button {
			height: 15px;
		}
		button {
			padding: 0 8px;
			font-size: 9px;
			margin-right: 0;
		}
	}

}
.tooltip-portal {
	position: absolute;
	width: 250px;
	font-size: 11px;
	background-color: $v2-tooltip-grey;
	padding: 16px;
	color: $v2-text-gray;
	font-weight: 600;
	border-radius: 4px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
	border: solid 1px $v2-grey;
	line-height: 16px;
	z-index: 999;
	&:focus {
		outline: none;
	}
	&:after,
	&:before {
		top: -16px;
		left: 50%;
		border: solid $v2-tooltip-grey;
		content: ' ';
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		//transform: rotate(-90deg);
	}

	&:after {
		border-color: rgba(136, 183, 213, 0);
		border-bottom-color: $v2-tooltip-grey;
		border-width: 8px;
		margin-left: -8px;
	}
	&:before {
		border-color: rgba(194, 225, 245, 0);
		border-bottom-color: $v2-tooltip-grey;
		border-width: 9px;
		margin-left: -9px;
	}
	&.showOnRight {
		&:after,
		&:before {
			top: 13px;
			left: -8px;
			transform: rotate(270deg);
		}
	}
	&.showOnLeft {
		&:after,
		&:before {
			top: 51%;
			left: 257px;
			transform: rotate(90deg);
		}
	}
}
