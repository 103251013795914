.add-client-modal {
	.suggested-client-logo-wrapper {
		display: flex;
		flex-direction: column;
		margin-top: 16px;
		.logo-controls-wrapper {
			display: flex;
			flex-direction: row;
			align-items: center;
			.suggested-logo {
				width: 100px;
				height: 100px;
				margin-right: 8px;
			}
			.suggested-logo-controls {
				display: flex;
				flex-direction: column;
				font-size: 12px;
				color: $v2-text-gray;
				.approve-suggested-logo-controls {
					display: flex;
					flex-direction: red;
					margin-bottom: 6px;
					label {
						cursor: pointer;
					}
					.title-label {
						margin-left: 3px;
						margin-bottom: 3px;
					}
				}
				.controls-upload-local-file {
					cursor: pointer;
					height: 21px;
					::-webkit-file-upload-button {
						cursor: pointer;
					}
					input {
						width: 140px;
						margin-top: -5px;
						cursor: pointer;
						opacity: 0;
						position: absolute;
					}
					.file-upload-input-label-wrapper {
						&:hover {
							.control {
								&.update {
									cursor: pointer;
									text-decoration: underline;
									color: $color-purple-hover-active;
								}
							}
						}
					}
					.control {
						font-size: 12px;
						background-size: 16px;
						background-repeat: no-repeat;
						background-position: left center;
						padding: 3px 10px 0 21px;
						cursor: pointer;
						margin-top: 6px;
						&:hover {
							cursor: pointer;
							text-decoration: underline;
							color: $color-purple-hover-active;
						}
						&.update {
							background-image: url('images/sections/settings/settings-overview/update-photo.svg');
						}
						&.remove {
							background-image: url('images/sections/settings/settings-overview/remove.svg');
						}
					}
				}
			}
		}
		.clearbit-link {
			color: $v2-text-light-gray;
			font-size: 12px;
			margin-top: 8px;
		}
	}
}
