.project-phases-v2 {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	position: relative;
	flex: 1 1 0px;
	background-color: white;
	// Mirrors margin on the left menu in v1 - TODO, this creates a white border when side-scrolling
	// padding-right: 16px;
	width: 100%;
	/*.side-scroll {
		.drag-drop-context {
			margin-right: 0;
		}
	}*/
	.header-bar {
		position: relative;
		padding: 0;
		margin: 15px 66px;
		margin-bottom: 16px; //Header has 1px white border so margin top has to be 1px lesser for the space to appear the same both above and below the options
	}
	.date-picker-button-container-v3 {
		&.double {
			width: 100%;
		}
	}
	.generic-empty-state-v2 {
		margin: 0 66px 66px 66px;
	}
	.csv-button {
		&:hover {
			border: 1px solid $v2-date-picker-button-border-color;
		}
	}
	.assigned-dropdown-wrapper {
		.assigned-icons {
			top: 7px;
		}
	}
	.search-V2 {
		background-position: right 8px center;
	}
	.board-card-details-selector-list,
	.bulk-edit-options-list {
		z-index: 101 !important;
	}
	.section-project-group-link {
		z-index: 0;
	}
	.project-phases-header {
		.new-phase-button {
			@include primary-button;
			margin-left: 0;
			width: auto;
		}
		.filters {
			margin-left: auto;
			.filter {
				background-position: 20px center;
				background-repeat: no-repeat;
				cursor: pointer;
				font-weight: $base-font-medium-weight;

				color: $color-light-black;

				&:hover {
					color: $color-purple-hover-active;
				}

				&.load-breakdown {
					background-image: url('images/sections/project/phases/load.svg');
					border-left: 1px solid $app-border-color;
					border-right: 1px solid $app-border-color;
				}

				&.save-breakdown {
					background-image: url('images/sections/project/phases/save.svg');
					border-right: 1px solid $app-border-color;
				}

				&.add-phase {
					//margin-left: auto;
					padding: 13px 20px 0px 55px;
					border-left: 1px solid $app-border-color;
					background-image: url('images/sections/project/phases/phase.svg');
				}
			}
		}
	}
	// non-fixed width table elements
	.growable {
		flex-grow: 1;
	}

	.phase-wrapper {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		margin-bottom: 15px;
	}
	.filter-section-inner {
		z-index: 101;
	}
	.project-total-container {
		position: sticky;
		top: 0;
		background-color: #ffffff;
		z-index: 9;
		cursor: pointer;
		padding: 0 66px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.05);
		&.hide-shadow {
			box-shadow: none;
		}
		&.expanded {
			height: 50px;
			min-height: 50px;
			max-height: 50px;
			&.baseline {
				height: 110px;
				min-height: 110px;
				max-height: 110px;
			}
			&.large {
				height: 74px;
				min-height: 74px;
				max-height: 74px;
				&.baseline {
					height: 135px;
					min-height: 135px;
					max-height: 135px;
				}
			}
		}
		// &.extra-large {
		// 	height: 142 !important;
		// 	min-height: 142px !important;
		// 	max-height: 142px !important;
		// }
		&.collapsed {
			min-height: 35px;
			height: 35px;
			max-height: 35px;
			&.baseline {
				min-height: 95px;
				height: 95px;
				max-height: 95px;
			}
			.items,
			.elem-wrapper {
				height: 35px;
				max-height: 35px;
			}
		}
		&:hover {
			.icon-chevron::after {
				opacity: 1;
			}
		}
		&.side-backlog {
			margin-left: 10px;
		}
		.items {
			height: 50px;
			display: flex;
			flex-direction: row;
			background-color: #ffffff;
			position: relative;
			justify-content: space-between;
			margin: 0 0px;
			padding: 0 26px;

			.elem-wrapper {
				background: #ffffff;
				position: relative;
				height: 100%;
				div:first-child {
					flex-grow: 1;
				}
			}
			.project-total-header-item {
				margin-top: 10px;
				.multi-line-header {
					display: flex;
					flex-direction: column;
					position: relative;
					.line-1 {
						position: absolute;
						top: -10px;
						right: 0;
					}
				}
				.text {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					font-size: 9px;
					color: $v2-text-gray;
					letter-spacing: 1px;
					text-transform: uppercase;
					font-weight: 500;
					margin-bottom: 4px;
					text-align: right;
				}
				.value {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					font-size: 14px;
					text-align: right;
					color: $v2-text-gray;
				}
			}
		}

		.toggle {
			position: absolute;
			align-self: center;
			left: 183px;
			top: 7px;
			width: 20px;
			height: 20px;
			.baseline {
				top: 0px;
				.expanded {
					top: 5px;
				}
			}
		}

		.project-time-registrations {
			height: 25px;
			border-top: 1px solid $v2-column-header-grey;
			padding-top: 4px;
			.project-total-header-item {
				margin-top: 0px;
				&.on-project-name {
					.value {
						padding: 4px 0;
						font-size: 9px;
						font-weight: 500;
						letter-spacing: 1px;
					}
				}
			}
		}
	}

	.icon-chevron {
		font-size: 10px;
		letter-spacing: 0.5px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		cursor: pointer;
		&::after {
			content: '';
			width: 20px;
			height: 20px;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 14px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			display: inline-block;
		}

		&.expanded.baseline::after {
			top: 5px;
		}
		&.not-expanded.baseline::after {
			top: 5px;
		}
		&.expanded::after {
			opacity: 0;
			background-image: url('images/v2/components/dropdown/arrow-up.svg');
		}
		&.not-expanded::after {
			opacity: 0;
			top: -2px;
			background-image: url('images/v2/components/dropdown/arrow-down.svg');
		}
	}
	.section-body {
		display: flex;
		flex: 1 1 auto;
		flex-direction: column;
		@include scroll-y;

		.phase,
		.new-phase {
			@include content-frame;
			border: 0;
			box-shadow: 0 0px;
			//padding: 10px;
			overflow: visible;
			&.hide {
				display: none;
			}
			&.has-phase {
				background-color: white;
				.body {
					background-color: #f3f3f3;
				}
			}
			.body {
				flex-grow: 1;
				overflow: visible;
				background-color: white;
				margin: 0 66px;
				&.side-backlog {
					margin-left: 10px;
				}
				@include standard-table;
				.input-table-cell {
					padding-left: 4px;
				}
				.select-table-cell {
					.option {
						&:hover {
							color: $color-purple-hover-active;
						}
					}
					.option-selected {
						color: $brand-color-accent;
					}
				}
			}
		}

		.new-phase {
			display: flex;
			flex-flow: row wrap;
			border: 0;
			.controls-container {
				display: flex;
				flex-flow: row wrap;
				flex-direction: row;
			}
			.title {
				margin: 0;
				display: flex;
				justify-content: center;
				flex-direction: column;
			}
			.inputs {
				display: flex;
				flex-flow: row wrap;
				.name {
					input {
						@include generic-text-input;
						margin-left: 15px;
					}
				}
				.deadline {
					width: 190px;
					margin: 0 10px;

					background-color: white;
					padding: 0;
					.date-input,
					.date-input-focused {
						background: none;
						padding: 9px 10px;
						opacity: 0.7;
					}
					.date-input,
					.date-input-focused,
					.date-input-locked {
						border: none;
						@include generic-text-input;
						padding: 9px 10px;
					}
					.date-input-locked {
						background-color: #ebebe4;
					}
					.calendar-wrapper {
						top: 45px;
					}
				}
				.add-phase {
					@include primary-button;
				}
			}
		}

		.phase-bottom-section {
			margin-bottom: 5px;
			padding: 0 26px;
			&.grouped {
				padding: 0 16px;
			}
			.total {
				width: 38.5%;
				padding: 15px 0 0 0;
				margin: 0;
			}
			.data-tiles {
				width: 49%;
				padding: 5px 0 5px 0;
				display: flex;
				text-align: right;
				.data-tile {
					width: 25%;
					padding-right: 8px;
				}
			}
			.approved {
				width: 12.5%;
				text-align: center;
				padding: 5px;
				input {
					margin-left: 20px;
					height: 18px;
					width: 18px;
				}
			}
		}

		.table-container {
			padding: 0 16px;
			table {
				.text-input {
					padding: 6px 5px;
					width: 100%;
				}
				.text-input-group {
					padding: 6px 5px;
					width: 90%;
				}
				.time-group {
					padding-right: 4px;
				}
				.hours-group {
					padding-right: 6px;
				}
				.amount-group {
					padding-left: 5px;
					float: right;
				}
				.align-right {
					text-align: right;
				}

				.group-header {
					margin: 5px;
					cursor: pointer;
					.group-name {
						.expand-arrow {
							padding-left: 21px;
							background-size: 16px;
							background-repeat: no-repeat;
							background-position: left center;
						}
					}
					.select-placeholder {
						height: 36px;
						visibility: hidden;
					}
				}

				.bulk-update-checkbox {
					width: 30px;
					padding-left: 10px !important;
					.md-checkbox {
						width: 13px;
					}
				}
				.drag-handle {
					width: 30px;
					background: url('images/cross-section-icons/table/drag-handle.svg') no-repeat 10px center;
					cursor: move;
				}

				.drag-handle-light {
					min-width: 30px;
					background: url('images/cross-section-icons/table/drag-handle-light.svg') no-repeat 10px center;
					cursor: move;
				}
				.hours,
				.remaining {
					text-align: right;
				}
				.approved {
					text-align: center;
				}
				.assigned {
					.forecast-person {
						display: inline-block;
						margin: 5px 5px 5px 0;
						.image-container {
							display: inline-block;
						}
					}
				}
				.skills {
					.skill-label {
						margin-right: 4px;
						border-radius: 5px;
						height: 12px;
						width: 24px;
						display: inline-block;
					}
					span {
						display: inline-block;
					}
				}
				.input-row {
					background-color: #f3f3f3;
					.task-name-input {
						border: 1px solid #e6e6e6;
						background-color: #fff;
					}

					.forecast.right {
						text-align: right;
					}
					.approved {
						text-align: center;
						padding-right: 0;
					}
					.input-add-button {
						@include primary-button;
						margin: 0 5px;
					}
					.wrong {
						input {
							border-radius: 3px;
							border: 1px solid red;
						}
					}
				}
			}
		}
	}
	.no-margin {
		margin-bottom: 0;
		margin-top: 0;
	}
}
