.settings-app {
	display: flex;
	height: 100%;
	flex-direction: column;
	flex: 1 1 auto;
	flex-direction: column;
	&.useNewNavigation {
		margin-right: 0;
		header,
		.section-body {
			margin-right: 2rem;
		}
	}
	overflow: hidden;
	.section-body {
		height: 100%;
	}

	input[type='radio'] {
		cursor: pointer;
		height: 22px;
		width: 22px;
		margin-right: 8px;
	}
	input[type='radio'] + span {
		cursor: pointer;
	}
	input[type='checkbox'] {
		margin-right: 5px;
		vertical-align: middle;
		margin-bottom: 2px;
	}
	input[type='number'] {
		text-align: right;
	}
	input[type='number']::-webkit-inner-spin-button,
	input[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	.section-body {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		@include standard-table;
		.cell-center-aligned {
			text-align: center;
		}
		.my-profile-expenses-table-row {
			&.not-editable {
				cursor: auto;
				background-color: $color_medium_gray;
				&:hover {
					color: inherit;
				}
			}
			cursor: pointer;
			.lock-container {
				display: flex;
				.locked-icon {
					margin-left: 8px;
				}
			}
		}
	}

	.filters {
		margin-left: auto;
		.search-filter {
			margin-left: auto;
		}
		.delete {
			@include secondary-button;
			margin: 6px 15px 0 auto;
			height: 33px;
		}
		.filter {
			background-position: 20px center;
			background-repeat: no-repeat;
			cursor: pointer;
			font-weight: $base-font-medium-weight;

			color: $color-light-black;

			&:hover {
				color: $color-purple-hover-active;
			}

			&:active {
				padding-top: 14px;
			}
		}
	}

	fieldset {
		@include content-frame;
		margin: 0 0 15px 0;
		padding: 20px;
		font-size: 0.875em;
		.integration {
			.description {
				line-height: 21px;
				margin-top: 0;
				text-align: center;
			}
		}

		h3 {
			margin-top: 0;
		}
	}

	.input-fields,
	.titles {
		label {
			display: block;
			margin-bottom: 15px;
			> span {
				display: block;
				padding: 0 0 6px 0;
			}

			input[type='text'],
			input[type='password'],
			input[type='number'] {
				display: block;
				@include generic-text-input;
			}

			textarea {
				display: block;
				width: 100%;
				@include generic-text-input;
			}
		}
		input {
			width: 240px;
		}
		input[type='checkbox'] {
			width: 15px;
		}
	}

	.vsts-app-buttons {
		margin-right: 20px;
	}

	.titles-material {
		//needed to place password help bubble correclty in relation to title-material div
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.Select {
			padding: 8px 0 0 0;
		}
		.wrapper,
		.wrapper-error {
			width: 260px;
			.input-wrapper {
				.input {
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}

	.radio-button-container {
		display: flex;
		font-size: 14px;
		&:not(:last-child) {
			margin-bottom: 11px;
		}
		input,
		span {
			height: 18px;
		}
		.information-icon {
			margin-left: 7px;
			margin-top: 1px;
		}

		&.sub-option {
			&:not(:last-child) {
				margin-bottom: 5px;
			}
		}
	}

	.sub-option-container {
		margin-top: 13px;
		padding-left: 20px;
	}

	.save {
		padding-top: 15px;
		.buttons {
			display: flex;
		}
		.cancel-button {
			@include secondary-button;
			margin-left: auto;
			margin-right: 15px;
		}
		.submit-button {
			@include primary-button;
		}
	}

	.bold-font-weight {
		font-weight: bold;
	}

	&.settings-security {
		.section-body {
			margin: 16px 24px;
		}
	}
}
