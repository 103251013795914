.settings-app-page {
	&.section-content {
		margin: 0;
		.section-body {
			margin: 0 24px;
		}
	}
	input {
		@include generic-text-input;
	}

	.section-body {
		label .harvest-account-name input,
		label .okta-account-name input,
		label .onelogin-account-name input,
		label .azure-account-name input {
			margin: 0;
		}

		.header-wrapper {
			display: flex;

			.title-description-wrapper {
				padding-left: 20px;
				white-space: break-spaces;
				.zapier-templates-widget {
					white-space: normal;
				}
				p {
					margin-top: 0;
					margin-bottom: 0.5em;
				}
			}
			.zap-power-bar {
				white-space: normal;
			}

			margin-bottom: 28px;
		}

		.status {
			margin-bottom: 15px;
			width: fit-content;
		}

		.configuration-info {
			width: 559px;
			margin-bottom: 10px;
		}

		.settings-checkboxes {
			width: 520px;
		}
	}

	.submit-button {
		@include primary-button;

		&.save-button {
			margin-right: 15px;
		}
	}

	.content-wrapper {
		display: flex;
		flex-direction: row;
	}
	.section-body {
		.help-button {
			@include secondary-button;
			margin-left: 15px;
		}
		.heading-wrapper {
			margin: 24px 0;
		}
	}

	.ado-button {
		padding: 8px 10px 8px 10px;
		float: right;
	}

	.ado-field {
		margin-bottom: 20px;
		max-width: 300px;
	}

	.ado-error {
		color: red;
	}

	.checkbox-wrapper {
		display: flex;
		flex-direction: row;
		// .md-checkbox {
		// 	flex: 0 0 15px;
		// }
	}

	.flex-container {
		display: flex;
	}

	&.settings-app-unit4 {
		.authentication-description {
			margin-bottom: 20px;
		}
		.authenticate-button-container {
			display: flex;
			align-items: center;
			div {
				flex: 0 0 45px;
			}
		}
		.button-container {
			margin-top: 8px;
		}
		form {
			label {
				max-width: 240px;
			}
		}
		input[type='checkbox'] {
			padding: 0;
			margin: 0 0 0 4px;
			width: 18px;
		}
	}

	.breadcrumbs-wrapper {
		padding: 24px 24px 0 24px;
	}

	.tabs-wrapper {
		border-bottom: 1px solid #ccd6dbff;
	}
}
