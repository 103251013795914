.task-modal-v3-main-section {
	padding: 8px 58px;
	z-index: 102;
	position: relative;

	&.project-locked {
		.tile-container {
			opacity: 1;
			color: #a1a1a1;
			background-color: #f3f3f3;
		}
	}

	.assigned-dropdown-wrapper {
		border-radius: 4px;
		height: 50px;
		width: 216px;
		flex: 0 1 216px;
		background-color: white;
		padding: 0 10px;

		&.disabled {
			background-color: $color-medium-gray;
			color: $v2-text-light-gray;
			.arrow {
				opacity: 0.5;
			}
		}

		&:hover:not(.disabled) {
			input,
			.assigned-icons {
				cursor: pointer;
				background-color: $v2-workflow-task-selected-color;
			}
			.assigned-icons {
				background: transparent;
			}
		}

		label {
			position: absolute;
			top: 8px;
			z-index: 2;
			pointer-events: none;
		}

		input {
			border: 1px solid transparent;
			padding: 20px 10px 10px 10px;
			position: absolute;
			top: 0;
			left: 0;
			height: 50px;
			z-index: 1;
			background-color: transparent;

			&.expanded {
				border-color: $v2-branding-purple;
			}
		}

		.clear-input {
			bottom: 5px;
		}

		.assigned-icons {
			z-index: 3;
			padding-left: 7px;
			top: 20px;
			> div {
				margin-left: 0;
			}
		}

		.arrow {
			bottom: 8px;
			right: 18px;
			margin: 0;
		}
	}

	.top-row {
		display: flex;
		flex-direction: row;
		margin-bottom: 8px;

		.input-container-v2 {
			flex-grow: 1;
			margin-right: 8px;

			.input-value-container {
				height: 50px;

				&.locked {
					opacity: 1;
					background-color: $v2-input-disabled-grey;
					color: $v2-text-light-gray;
				}
			}

			.input-value {
				padding: 0 10px;
				&.disabled {
					color: $v2-text-light-gray;
					-webkit-text-fill-color: $v2-text-light-gray;
					opacity: 1;
				}
			}
		}
	}

	.bottom-row {
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: space-between;

		.date-range-picker-wrapper-new {
			margin: 0 8px;

			.date-picker-button-container-v3 {
				border-radius: 4px 0 0 4px;

				&:last-child {
					border-radius: 0 4px 4px 0;

					.date-picker-button {
						border-radius: 0 4px 4px 0;
					}
				}

				.date-picker-button {
					width: 116px;
					border-radius: 4px 0 0 4px;

					&.locked {
						opacity: 1;
						color: $v2-text-light-gray;
						background-color: $v2-input-disabled-grey;
					}

					&:not(.locked):hover {
						background-color: $v2-workflow-task-selected-color;
					}

					&.active,
					&:focus {
						background-color: unset;
						border: 1px solid $v2-branding-purple;
						border-radius: 4px;
					}

					.date-text-container {
						display: flex;
						align-items: center;
						height: 19px;

						span {
							margin-right: 2px;
						}
					}
				}
			}
		}

		.header-tile {
			border: 1px solid $app-border-color;
			background-color: white;
			width: 113.333px;
			position: relative;
			height: 50px;

			&:focus {
				outline: none;
				border: 1px solid $v2-branding-purple;
			}

			.tile-container {
				padding: 8px 10px;
				height: 100%;
				&:hover {
					background-color: $v2-workflow-task-selected-color;
				}
			}

			&.header-tile-forecast {
				border-radius: 4px 0 0 4px;
				.tile-container {
					border-radius: 4px 0 0 4px;
				}
				&.expanded {
					border: 1px solid $v2-branding-purple;
				}
			}

			&.time-entries-tile {
				&.expanded {
					border: 1px solid $v2-branding-purple;
				}
			}

			&.progress-tile {
				&.expanded {
					border: 1px solid $v2-branding-purple;
				}
			}

			&.remaining-tile {
				border-radius: 0 4px 4px 0;
				.remaing-update-notice {
					position: absolute;
					background: white;
					border-radius: 4px;
					box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
					border: solid 1px #e6e6e6;
					padding: 16px;
					top: 98%;
					left: 0;
					// transform: translateX(-50%);
					font-size: 12px;
					width: max-content;
					z-index: 20;
					.small-close-icon {
						position: absolute;
						top: 3px;
						right: 5px;
					}
					div:first-of-type {
						font-weight: 500;
					}
				}
				.tile-container {
					border-radius: 0 4px 4px 0;
				}
				&.expanded {
					border: 1px solid $v2-branding-purple;
				}
			}

			.border-mask {
				width: 100%;
				height: 2px;
				background-color: white;
				position: absolute;
				bottom: -2px;
				left: 0;
				z-index: 100;
			}

			.tile-wrapper {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				cursor: pointer;
				height: 100%;

				.tile-label {
					height: 11px;
					width: 110%; //let text appear in the padding area; workaround to make text fit in the container when zoomed out at 50%
					font-size: 9px;
					letter-spacing: 0.9px;
					color: $v2-text-light-gray;
					text-transform: uppercase;
					font-weight: 500;
					display: flex;

					svg {
						margin-right: 5px;
					}
				}
				.text-container {
					display: flex;
					flex-direction: row;
					justify-content: flex-end;
					align-content: flex-end;
					height: 100%;

					.warning {
						left: 6px;
						position: absolute;
						top: 21px;
					}

					.number-text {
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						font-size: 15px;
						margin-right: 4px;
						font-weight: 700;
						color: $v2-text-gray;
					}
					.unit-text {
						padding-top: 3px;
						font-size: 12px;
						color: $v2-text-gray;
						&.points {
							padding-top: 0;
							font-size: 15px;
							font-weight: 700;
						}
					}
				}
			}
			.header-tile-dropdown-container {
				position: absolute;
				top: 54px;
				left: -1px;
				z-index: 3;
				background-color: white;
				border: 1px solid $app-border-color;
				cursor: default;
				border-radius: 4px;
				box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);

				.input-value-unit {
					pointer-events: none;
				}
				.border-container {
					.tile-border {
						position: absolute;
						top: -54px;
						left: -1px;
						height: 54px;
						width: 116px;
						border: 1px solid $v2-branding-purple;
						border-bottom: none;
						cursor: pointer;
					}
					.content-forecast-left-border {
						position: absolute;
						top: 0;
						left: -1px;
						height: 57px;
						width: 115px;
						border-left: 1px solid $v2-branding-purple;
						border-bottom: 1px solid $v2-branding-purple;
					}
					.content-forecast-right-border {
						position: absolute;
						top: 0;
						left: 114px;
						height: 57px;
						width: 185px;
						border: 1px solid $v2-branding-purple;
						border-left: none;
					}
					.bottom-left-border {
						position: absolute;
						top: -1px;
						left: -86px;
						height: 0px;
						width: 86px;
						border-bottom: 1px solid $v2-branding-purple;
					}
					.bottom-right-border {
						position: absolute;
						top: -1px;
						left: 114px;
						height: 0px;
						width: 100px;
						border-bottom: 1px solid $v2-branding-purple;
					}
				}
				.content-container-forecast {
					display: flex;
					flex-direction: row;
					align-items: center;
					height: 57px;
					width: 299px;
					padding-right: 8px;
					.input-container-v2 {
						width: 100px;
					}
				}

				.content-container-time-entries {
					position: absolute;
					top: -1px;
					border: 1px solid $app-border-color;
					border-radius: 4px;
					box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
					background-color: white;
					z-index: 102;
					&.done {
						min-width: 313px;
						left: -86px;
					}
					.no-time-registered-text {
						padding: 12px 12px 10px 12px;
						font-size: 12px;
						color: $v2-text-light-gray;
					}
					.new-entry-row {
						padding: 8px 0;
						display: flex;
						flex-direction: row;

						.forecast-person {
							margin-right: 8px;
						}

						.date-range-picker-wrapper-new {
							margin: 0;
						}

						.input-container-v2 {
							width: 75px;
							.input-value-container {
								border-top-right-radius: 0;
								border-bottom-right-radius: 0;
								input {
									text-align: right;
									font-size: 14px;
								}
							}
						}

						.date-picker-button-standard {
							border-radius: 0;
						}
						.new-time-entry-button {
							width: 40px;
							height: 40px;
							border: 0;
							color: $v2-create-button-green;
							font-size: 24px;
							border: 1px solid $v2-create-button-green;

							&.disabled {
								border-color: $v2-text-light-gray;
								color: $v2-text-light-gray;
							}
						}
					}

					.integration-task-select {
						padding-bottom: 8px;
						.drop-down-v2 {
							margin-left: 56px;
							width: 188px;
						}
					}

					.time-registration-line {
						display: flex;
						justify-content: space-around;
						align-items: center;
						height: 48px;
						white-space: nowrap;
						font-size: 12px;
						margin-right: 30px;
						width: 100%;
						border-top: 1px solid $app-border-color;
						padding: 0 8px;

						.forecast-person {
							max-width: 135px;
							width: 135px;
						}
						.locked-icon {
							height: 30px;
							width: 28px;
							background: url('images/integrations/xero/locked.svg') center no-repeat;
							background-size: 28px;
						}
						.time-registration-line-content {
							display: flex;
							flex-direction: column;
							flex: 0 0 auto;
							min-width: 0px;
							width: 150px;
							padding-left: 8px;

							&.invoiced {
								color: $v2-text-light-gray;
							}

							.time-registration-line-values {
								display: flex;
								flex-flow: row nowrap;
								align-items: center;
								&.harvest-check,
								&.harvest-exclamation,
								&.harvest-x,
								&.padlock {
									padding-left: 18px;
								}
								&.harvest-check {
									background: url('images/v2/task-modal/harvest-check.svg') left center no-repeat;
									background-size: 14px 11px;
								}
								&.harvest-exclamation {
									background: url('images/v2/task-modal/harvest-exclamation.svg') left 4px center no-repeat;
									background-size: 3px 14px;
								}
								&.harvest-x {
									background: url('images/v2/task-modal/harvest-x.svg') left center no-repeat;
									background-size: 12px;
								}
								&.padlock {
									background: url('images/v2/task-modal/padlock.svg') left center no-repeat;
									background-size: 10px 12px;
								}
								.value {
									font-size: 14px;
									font-weight: bold;
									flex: 1 1 0;
								}
								.date {
									flex: 0 0 auto;
									margin: 0 8px;
								}
							}
							.integration-info {
								display: inline-flex;

								.harvest-sync-icon {
									margin-left: 4px;
									padding-top: 2px;
									svg {
										height: 12px;
										width: 12px;
									}
								}
								.harvest-retry {
									padding-left: 21px;
									color: #4a90e2;;
									cursor: pointer;
									text-decoration: underline;
								}
								.unit4-small-logo {
									margin-left: 4px;
									padding-top: 2px;
									height: 15px;
									width: 15px;
									background-image: url('images/integrations/unit4-small-logo.svg');
								}
								.integration-task-name {
									width: 100px;
									padding-left: 21px;
									overflow: hidden;
									white-space: nowrap;
									text-overflow: ellipsis;
								}
							}
						}
					}
				}

				.content-container-remaining {
					position: absolute;
					left: -89px;
					top: -2px;
					width: 201px;
					border: 1px solid $v2-branding-purple;
					border-radius: 4px 0 4px 4px;
					background-color: white;
					padding: 8px 0 5px 0;
					.remaining-factor-row {
						display: flex;
						flex-direction: row;
						padding: 0 11px;
						font-size: 12px;
						color: $v2-text-gray;
						align-items: center;
						justify-content: space-between;
						.factor-value {
							font-size: 14px;
							margin-left: auto;
							margin-right: 2px;
						}
					}
					.remaining-row {
						.factor-name,
						.factor-value {
							font-weight: 700;
						}
						.factor-name {
							margin-right: 20px;
						}
						&.manual-remaining {
							padding-right: 4px;
							.input-container-v2 {
								width: 65px;
								margin-left: auto;
								input {
									text-align: right;
									font-size: 14px;
								}
								.input-value-unit {
									margin-right: 4px;
									margin-top: 1px;
								}
							}
						}
					}
				}
			}
		}

		.hour-input-section {
			display: flex;
		}
	}
}
