.link-ado-project-modal {
	height: 100%;
	position: absolute;
	top: 0;
	z-index: 99;
	background: rgba(0, 0, 0, 0.75);
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	overflow: hidden;
	.inner {
		display: flex;
		.container {
			background-color: $color-white;
			display: flex;
			flex: 1;
			flex-direction: column;
			padding: 2em 2em 0 2em;
			border-radius: 2px;
			.header {
				flex: 0 0 auto;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				margin-bottom: 16px;
				.back-button,
				.close-button {
					height: 21px;
					width: 21px;
					background-color: transparent;
					background-position: center;
					background-repeat: no-repeat;
					cursor: pointer;
					border: none;
				}
				.back-button {
					background-image: url('images/cross-section-icons/actions/previous.svg');
				}
				.close-button {
					background-image: url('images/components/overlay/close.svg');
				}
				h3 {
					font-size: 20px;
					margin: 0;
					font-weight: 500;
					color: #535353;
				}
			}
			.body {
				display: flex;
				flex: 1;
				align-items: space-between;
				flex-direction: column;

				.input-header {
					font-family: 'neue-haas-grotesk-text', sans-serif;
					font-weight: 400;
					color: #858585;
					padding-top: 4px;
					padding-left: 4px;
					font-size: 0.75rem;
				}

				.ado-input-container {
					margin-top: 8px;

					display: flex;
					flex-direction: row;
				}

				.ado-checkbox {
					width: 22px;
					height: 22px;
				}
			}
			.bottom {
				display: flex;
				flex: 0 0 auto;
				align-items: center;
				justify-content: flex-end;
				padding: 1rem 0;

				.ado-button {
					margin-left: 4px;
					padding: 8px 10px 8px 10px;
				}
			}
		}
	}
}
