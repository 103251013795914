.expense-wrapper {
	height: 39px;
	letter-spacing: 0px !important;

	&.disabled {
		background-color: $color_medium_gray;
	}

	input {
		border: 1px solid transparent !important;

		&:hover {
			border: 1px solid $v2-text-light-gray !important;
		}

		&:focus,
		&:active {
			border: 1px solid $v2-branding-purple !important;
			outline: 0 !important;
		}
	}

	td {
		cursor: default;

		.input-container-v2 {
			.input-value-container {
				border: none;
			}
		}

		&.name {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			a {
				text-decoration: none;
			}
		}

		&.date {
			.date-picker-button-container {
				.date-picker-button.text-version {
					text-transform: uppercase;
					min-width: 65px;
					padding: 0;
					text-align: center;
					background-color: transparent;
					font-weight: 500;
					padding-top: 8px;
					padding-bottom: 8px;
					height: 100%;
					border: 1px solid transparent;

					&.expanded {
						border: 1px solid $v2-branding-purple;
					}

					&:hover {
						border: 1px solid $v2-text-light-gray;
					}
				}
			}
		}

		&.quantity {
			.input-container-v2 {
				.input-value-container {
					font-weight: 400;
					background-color: transparent;
					height: 100%;

					input {
						font-size: 13px;
						padding: 6px 16px 6px 16px;
						text-align: center !important;
					}
				}
			}
		}

		&.person {
			display: flex;
			align-items: center;
			height: 39px;

			a {
				div {
					div {
						display: flex;
						align-items: center;
						img {
							width: 22px;
							height: 22px;
							margin-right: 6px;
						}
						.person-initials-hexagon-shape,
						.initials {
							display: inline-block;
						}
					}
				}
			}
		}

		&.total-cost,
		&.total-price {
			&.left {
				text-align: left;
			}
			&.right {
				text-align: right;
			}
		}

		&.markup {
			label {
				display: none;
			}

			.input-value-container {
				font-weight: 400;
				background-color: transparent;
				height: 100%;

				input {
					font-size: 13px;
					padding: 6px 15px 6px 6px !important;
					text-align: right;
				}

				.input-value-unit {
					font-weight: 400;
					font-size: 13px;
					line-height: 30px;
					margin-right: 0 !important;
				}
			}
		}

		&.approved,
		&.planned {
			.check-box {
				display: flex;
				justify-content: center;

				input {
					width: 22px;
					height: 22px;
					border: 1px solid #e6e6e6 !important;
				}
			}
		}
	}
	.controls span {
		visibility: hidden;
	}
	&:hover {
		background-color: #f3f3f3;
		.controls span {
			visibility: visible;
		}
		cursor: pointer;
	}
	.locked-icon {
		position: absolute;
		bottom: -6px;
		left: -2px;
	}
}
