.permissions-info-modal {
	height: 100%;
	position: absolute;
	top: 0;
	z-index: 99;
	background: rgba(0, 0, 0, 0.75);
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	overflow: hidden;
	.inner {
		height: 100%;
		padding: 2rem 0;
		width: 65.25rem;
		display: flex;
		.container {
			background-color: $color-white;
			display: flex;
			flex: 1;
			flex-direction: column;
			padding: 2em 2em 0 2em;
			border-radius: 2px;
			.header {
				flex: 0 0 auto;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				margin-bottom: 16px;
				.back-button,
				.close-button {
					height: 21px;
					width: 21px;
					background-color: transparent;
					background-position: center;
					background-repeat: no-repeat;
					cursor: pointer;
					border: none;
				}
				.back-button {
					background-image: url('images/cross-section-icons/actions/previous.svg');
				}
				.close-button {
					background-image: url('images/components/overlay/close.svg');
				}
				h3 {
					font-size: 20px;
					margin: 0;
					font-weight: 500;
					color: #535353;
				}
			}
			.body {
				display: flex;
				flex: 1;
				align-items: space-between;
				flex-direction: column;
				.summary {
					display: flex;
					flex-direction: column;
					justify-content: center;
					h3 {
						font-size: 1em;
						margin: 0;
						padding-bottom: 1em;
					}
					.roles {
						display: flex;
						flex: 1;
						flex-direction: column;
						justify-content: center;
						.role-info {
							display: flex;
							flex-direction: row;
							justify-content: flex-start;
							padding-bottom: 0.2em;
							margin-top: 0.625rem;
							.role-name {
								font-weight: 500;
								font-size: 13px;
								color: #535353;
								min-width: 150px;
							}
							.role-description {
								font-size: 13px;
								color: #535353;
								font-weight: 400;
							}
						}
					}
				}
				.table-wrapper {
					display: flex;
					flex: 0.8;
					width: calc(100% - 2px);
					@include standard-table;

					th {
						&.col-action {
							span {
								margin-left: 10px;
							}
						}
					}

					td {
						border: 1px solid #eee;

						span {
							margin-left: 10px;
						}
					}

					table {
						border-collapse: collapse;
						thead {
							th {
								font-size: 9px;
								letter-spacing: 1px;
								color: #a1a1a1;
								text-transform: uppercase;
								font-weight: 400;

								&:last-child {
									padding-right: 0.625rem;
								}
							}
						}

						tbody {
							color: #535353;

							tr {
								td {
									&:last-child {
										padding-right: 0.625rem;
									}
								}
							}
						}
						font-size: 0.75em;
						.col-role {
							text-align: center;
							min-width: 100px;
						}
						.true,
						.false {
							width: 100%;
							height: 11px;
							background-color: transparent;
							background-position: center;
							background-repeat: no-repeat;
							background-size: 21px;
						}
						.true {
							background-image: url('images/cross-section-icons/ok-green.svg');
						}
						.false {
							background-image: url('images/components/overlay/close.svg');
						}
					}
				}
			}
			.bottom {
				display: flex;
				flex: 0 0 auto;
				align-items: center;
				justify-content: flex-end;
				padding: 1rem 0;
				button {
					@include primary-button;
				}
			}
		}
	}
}
