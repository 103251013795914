.canvas-people-scheduling {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: hidden;
	.onboarding-person-target {
		position: absolute;
		width: 270px;
		height: 49px;
		top: 103px;
		// z-index: 0;
	}
	.onboarding-person-expand-target {
		position: absolute;
		width: 20px;
		height: 22px;
		top: 116px;
		left: 239px;
		// z-index: 101;
	}
	.hover-effects-container {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 10;
		overflow: hidden;
		pointer-events: none;
		* {
			pointer-events: all;
		}
		.people-scheduling-actions-menu {
			position: absolute;
		}
		.canvas-project-scheduling-project-group-info-box {
			position: absolute;
			left: 50px;
			z-index: 2;
			.projects-popup {
				position: absolute;
				background-color: white;
				border: solid 1px #dbdbdb;
				padding: 2px 16px;
				.triangle-container {
					position: relative;
					.triangle {
						width: 0;
						height: 0;
						border-top: 10px solid transparent;
						border-bottom: 10px solid transparent;
						border-right: 10px solid white;
						position: absolute;
						left: -26px;
					}
				}
				.project-group-project-row {
					display: flex;
					flex-direction: row;
					font-size: 12px;
					margin: 8px 0;
					white-space: nowrap;
					overflow: hidden;
					.id-container {
						border-radius: 3px;
						width: 40px;
						text-align: center;
						color: white;
						margin-right: 8px;
					}
				}
			}
		}
		.people-scheduling-detail-box {
			position: fixed;
			z-index: 999;
			background-color: white;
			padding: 17px 14px;
			pointer-events: none;
			border: solid 1px $v2-grey;
			border-radius: 4px;

			.detail-box-inner-container {
				background-color: white;
				position: relative;
				left: 0;
				top: 0;
				width: 180px;
				border: none;
				.detail-box-assigned-person-container {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
				}
			}

			.white-triangle {
				position: absolute;
				z-index: 1000;
				width: 10px;
				height: 10px;
				overflow: hidden;
				border-left: solid 1px $v2-grey;
				border-bottom: solid 1px $v2-grey;
				background-color: white;
				&.right {
					left: -5px;
					transform: rotate(45deg);
				}
				&.left {
					right: -5px;
					transform: rotate(225deg);
				}
				&.top {
					top: 16px;
				}
				&.bottom {
					bottom: 16px;
				}
			}
		}

		.heatmap-cell-detail-box {
			position: fixed;
			z-index: 999;
			pointer-events: none;
			display: flex;
			align-items: center;
			justify-content: center;

			.detail-box-inner-container {
				position: absolute;
				height: 100%;
				width: auto;
				min-width: 80px;
				max-width: 100%;
				padding: 0 6px;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;

				background-color: #F7F7FE;
				border: solid 1px #E7E7F3;
				border-radius: 4px;
				box-shadow: 0 0 5px 0px rgba(181,181,181,0.25);
				-webkit-box-shadow: 0 0 5px 0px rgba(181,181,181,0.25);
				-moz-box-shadow: 0 0 5px 0px rgba(181,181,181,0.25);
				font-size: 13px;
				font-weight: 400;

				span {
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}
			}

			.light-triangle {
				position: absolute;
				z-index: 1000;
				width: 10px;
				height: 10px;
				overflow: hidden;
				border-left: solid 1px #E7E7F3;
				border-bottom: solid 1px #E7E7F3;
				background-color: #F7F7FE;

				&.center {
					left: calc(50% - 5px);
				}

				&.right {
					left: -5px;
					top: calc(50% - 5px);
					transform: rotate(45deg);
				}

				&.left {
					right: -5px;
					top: calc(50% - 5px);
					transform: rotate(225deg);
				}

				&.top {
					top: -5px;
					transform: rotate(135deg);
				}

				&.bottom {
					bottom: -5px;
					transform: rotate(-45deg);
				}
			}
		}
	}
	.unassigned-section-controller-restyled {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 14px;
		cursor: pointer;
		height: 100%;
		width: 100%;
		background-color: #E7E7F3;
		user-select: none;
		font-size: 12px;
		font-weight: 500;
		color: #393946;
		border-right: 1px solid #ebebee;
		border-bottom: 1px solid #ebebee;

		&.collaborator {
			background-color: white;
			cursor: default;
		}
	}
	.unassigned-section-controller {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 14px;
		cursor: pointer;
		height: 100%;
		width: 100%;
		background-color: #efefef;
		user-select: none;
		font-size: 12px;
		font-weight: bold;
		color: #3a475b;
		border-right: 1px solid #ebebee;
		border-bottom: 1px solid #ebebee;

		&.collaborator {
			background-color: white;
			cursor: default;
		}

		.group-expand-arrow {
			margin-left: 16px;
			width: 10px;
			height: 10px;
			background-size: 10px;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url('images/components/scheduling/expand.svg');
			&.expanded {
				background-image: url('images/components/scheduling/collapse.svg');
			}
		}
	}
	.people-scheduling-arrow-clickable-area {
		position: absolute;
		width: 11px;
		height: 28px;
		border-radius: 4px;
		cursor: pointer;
		&.previous {
			left: 238px;
		}
		&.next {
			left: 249px;
		}
		&:hover {
			background: rgba(0, 0, 0, 0.3);
		}
	}
}

.userpilot-target {
	position: absolute;
	pointer-events: none;
	background: none;
	&.unassingned-tasks {
		top: 130px;
		left: 0;
		height: 55px;
		width: 269px;
	}
	&.unassingned-tasks-expand-icon {
		top: 147px;
		left: 237px;
		height: 21px;
		width: 22px;
	}
	&.first-role {
		top: 185px;
		left: 0;
		height: 49px;
		width: 269px;
	}
	&.first-person {
		top: 232px;
		left: 0;
		height: 49px;
		width: 269px;
	}
	&.first-person-expand-icon {
		top: 247px;
		left: 238px;
		height: 21px;
		width: 22px;
	}
	&.first-person-expand-icon-project {
		top: 296px;
		left: 238px;
		height: 21px;
		width: 22px;
	}
	&.resource-utilization {
		width: 100%;
		height: 49px;
		left: 0;
		top: 187px;
	}
	&.heatmap-first-project {
		width: 100%;
		height: 46px;
		left: 0;
		top: 281px;
	}
}

.load-more-overlay {
	position: absolute;
	background: #ffffffcc;
	backdrop-filter: blur(5px);
	z-index: 9;
	display: grid;
	align-content: space-evenly;
	justify-items: center;

	.load-more-button {
		display: none;
	}

	&.show-button {
		.load-more-button {
			display: block;
		}
		.loading-indicator {
			display: block;
		}
	}

	.loading-indicator {
		display: none;
		width: 80px;
		height: 80px;
		background-size: 80px 80px;
		background-image: url('images/loaders/loader-animation.svg');
		background-repeat: no-repeat;
		background-position: center;
	}
}