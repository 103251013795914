.scoping-add-task-row {
	background-color: #f3f3f3;
	display: flex;
	flex-direction: row;
	height: 45px;
	width: 60%;
	font-size: 12px;
	padding-top: 5px;
	padding-bottom: 10px;
	&.white {
		background-color: white;
	}
	.task-name-input-wrapper {
		height: 100%;
		max-width: 25vw;
		min-width: 250px;
		flex-grow: 1;
		margin-right: 8px;
		.task-name-input {
			width: 100%;
			height: 100%;
			border: 1px solid #e6e6e6;
			background-color: #fff;
			border-radius: 4px;
			padding-left: 16px;
			&:hover {
				border-color: $v2-button-very-light-grey-hovered;
			}
			&:focus {
				border-color: $v2-branding-purple;
				outline: none;
			}
		}
	}
	.assigned-dropdown-wrapper {
		flex-grow: 0;
		min-width: 180px;
		max-width: 180px;
		margin-right: 8px;
	}
	.hours-input {
		margin-right: 8px;
		max-width: 90px;
		min-width: 90px;
	}
	.hours {
		margin-right: 8px;
		max-width: 60px;
		min-width: 60px;
		.input-hours,
		.input-hours-DAN,
		.input-points {
			&:before {
				right: 10px;
				top: 0px;
			}

			input {
				height: 100%;
				border-radius: 4px;
				padding-right: 18px;
			}
		}
	}
	.input-add-button {
		height: 100%; //28px
		width: 28px;
		background-size: 14px;
		background: $v2-create-button-green url('images/v2/components/overview_time/plus-add-white.svg') no-repeat center;
		&.lightgrey {
			background-color: $v2-grey;
			&:hover {
				opacity: 1;
				cursor: default;
			}
		}
		&.green {
			background-color: $v2-create-button-green;
			&:hover {
				background-color: $v2-create-button-green-active;
			}
		}
	}
	.forecast.right {
		text-align: right;
	}
	.approved {
		text-align: center;
		padding-right: 0;
	}
	.wrong {
		input {
			border-radius: 3px;
			border: 1px solid red;
		}
	}
	.project-dropdown-container {
		.project-dropdown-button {
			height: 28px;
		}
	}
	&.disabled {
		color: $v2-text-light-gray;
		.task-name-input-wrapper .task-name-input {
			background-color: $v2-input-disabled-grey;
			&:hover {
				border-color: $app-border-color;
			}
		}
	}
}
