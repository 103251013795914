.table-cell-description-wrapper {
	position: relative;
	outline: none;
	width: 100%;
	.inline-description {
		overflow: hidden;
		padding: 12px;
		height: 51px;
		.editor {
			cursor: pointer;
		}
	}
	.foldout-description {
		&.showToRight {
			left: 0;
		}
		&.showToLeft {
			right: 0;
		}
		position: absolute;
		top: -25px;
		min-width: 250px;
		background-color: $color-white;
		box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		resize: none;
		border-radius: 3px;
		border-color: $light-border-color;
		padding: 8px;
		outline: none;
		z-index: 99;
	}
}
