.project-group-table-row {
	.project-group-id-container {
		height: 14px;
		padding-left: 22px;
		background-size: 14px;
		background-repeat: no-repeat;
		background-image: url('images/sections/scheduling-canvas/project-group-icon.svg');
		&.white {
			background-image: url('images/sections/scheduling-canvas/project-group-icon-white.svg');
		}
	}
}
.project-group-nested-row {
	.nested-id {
		&.v2 {
			background-color: $color-white;
		}
		&:first-child {
			&::after {
				display: none;
			}
		}
	}
	.nested-project-id-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		height: 51px;
		background-color: $color-light-grey;
		.spacer {
			height: 51px;
			width: 45px;
			background-color: $color-light-grey;
			margin-left: -25px;
		}
		&.v2 {
			height: 40px;
			background-color: $color-white;
			.spacer {
				background-color: $color-white;
				border: none;
				width: 7px;
				height: 9px;
				border-left: solid 2px $v2-text-light-gray;
				border-bottom: solid 2px $v2-text-light-gray;
				margin: 0 3px 0 0px;
				margin-left: -7px;
			}
			.id-wrapper {
				height: 40px;
				border-bottom-left-radius: 12px;
				border-top-left-radius: 12px;
				padding-left: 16px;
				&::after {
					display: none;
				}
			}
		}
		.id-wrapper {
			background-color: white;
			height: 51px;
			width: 100%;
			display: flex;
			align-items: center;
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
			border-right: 0;
			position: relative;
			overflow: hidden;
			//	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
			&::after {
				height: 100%;
				width: 150%;
				-webkit-transform: rotate(30deg);
				-moz-transform: rotate(30deg);
				-ms-transform: rotate(30deg);
				transform: rotate(30deg);
				content: '';
				position: absolute;
				left: -55%;
				top: 38px;
				background-color: $color-white;
			}
		}
	}
}
