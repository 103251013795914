.task-table-input-field {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	background-color: white;
	overflow: hidden;
	white-space: nowrap;
	color: $v2-text-gray;
	height: 37px;
	line-height: normal;
	transition: 0.1s border-color;
	font-family: $text-font-family;
	font-size: 12px;
	font-style: normal;
	font-stretch: normal;
	font-weight: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
	margin-left: 4px;
	min-width: 0;
	box-sizing: border-box;
	border: solid 1px transparent;
	border-radius: 4px;
	input {
		&:not(output):-moz-ui-invalid {
			box-shadow: none;
		}
	}
	.input-value {
		padding-right: 8px;
		&.grouped-values {
			padding-right: 12px;
			cursor: help;
		}
		&:focus {
			outline: none;
		}
	}

	.input-value-unit {
		position: absolute;
		right: 0;
	}

	&:hover {
		border-color: $v2-date-picker-button-border-color;
	}
	&.disabled:hover {
		border-color: transparent;
	}

	&.align-center {
		input {
			text-align: center;
		}
	}

	&.align-right {
		input {
			text-align: right;
		}
	}

	&.focused {
		border-color: $v2-branding-purple;
	}

	&.invalid {
		border-color: $v2-border-invalid;
	}
	::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		font-size: 12px;
	}
	::-moz-placeholder {
		/* Firefox 19+ */
		font-size: 12px;
	}
	:-ms-input-placeholder {
		/* IE 10+ */
		font-size: 12px;
	}
	:-moz-placeholder {
		/* Firefox 18- */
		font-size: 12px;
	}
}
