.filter-type-options-content {
	padding: 0 12px 12px 12px;

	.filter-options-content-header {
		font-size: 11px;
		margin-bottom: 8px;
		color: $v2-text-light-gray;
		border-bottom: 1px solid #d8d8d8;
	}

	&.default-filter-options-content {
		.default-filter-label-container {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			.default-filter-label-row {
				display: flex;
				flex-direction: row;
				flex: 0 0 50%;
				overflow: hidden;
				.default-filter-label {
					background-color: $v2-tooltip-grey;
					border-radius: 13px;
					height: 26px;
					margin-bottom: 10px;
					font-size: 10px;
					letter-spacing: 1px;
					line-height: 22px;
					padding: 0 6px;
					border: 2px solid $v2-tooltip-grey;
					cursor: pointer;
					transition: border-color 0.2s;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					&.selected {
						color: $v2-branding-purple !important;
						.role {
							color: $v2-branding-purple !important;
						}
					}
					&:hover {
						background-color: $bulk-update-selected-color;
						border: 2px solid $bulk-update-selected-color;
					}
				}
				.label-filter-label {
					margin: 0 3px 9px 6px;
					height: 30px;
					border-radius: 4px;
					font-size: 12px;
					padding: 7px 10px;
					cursor: pointer;
					transition: border-color 0.2s;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					&.selected {
						color: $v2-branding-purple !important;
						.role {
							color: $v2-branding-purple !important;
						}
					}
				}
				.indicator-filter-label {
					height: 26px;
					margin-bottom: 10px;
					font-size: 10px;
					letter-spacing: 1px;
					line-height: 22px;
					padding: 0 6px;
					cursor: pointer;
					transition: border-color 0.2s;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					&.selected {
						color: $v2-branding-purple !important;
						.role {
							color: $v2-branding-purple !important;
						}
					}
					.indicator-option-filter,
					.warning-option-filter {
						display: flex;
						align-items: center;
					}
				}
				.person-filter-label {
					width: 100%;
					.filter-person {
						padding: 2px;
						&:hover {
							cursor: pointer;
							background-color: $bulk-update-selected-color;
						}
						.name {
							font-size: 13px;
							font-weight: normal;
							.name-inner {
								max-width: 146px;
								overflow: hidden;
								text-overflow: ellipsis;
							}
						}
						.role {
							font-size: 11px;
							color: $v2-text-light-gray;
							&.personSelected {
								color: $v2-branding-purple;
							}
						}
						.no-person {
							font-size: 12px;
							padding: 7px 10px;
							&.person-selected {
								color: $v2-branding-purple;
							}
						}
					}
				}
				.project-filter-label {
					display: flex;
					flex-wrap: nowrap;
					align-items: center;
					height: 26px;
					overflow: hidden;
					width: 190px;
					max-width: 190px;
					&:hover {
						background-color: $bulk-update-selected-color;
						cursor: pointer;
					}
					.project-indicator {
						@include project-indicator;
						margin-left: 2px;
					}
					.idle-time-name,
					.project-name {
						overflow: hidden;
						color: $v2-text-gray;
						font-size: 13px;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
					&.selected {
						.idle-time-name,
						.project-name {
							color: $v2-branding-purple;
						}
					}
				}
				.client-filter-label,
				.role-filter-label {
					display: flex;
					align-items: center;
					flex-wrap: nowrap;
					max-width: 190px;
					width: 190px;
					overflow: hidden;
					padding: 2px;
					.role-name,
					.client-name {
						font-size: 12px;
						color: $v2-text-gray;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
					&.selected {
						.role-name,
						.client-name {
							color: $v2-branding-purple;
						}
					}
					&:hover {
						cursor: pointer;
						background-color: $bulk-update-selected-color;
					}
				}
				.client-filter-label {
					.client-logo {
						min-width: 30px;
						width: 30px;
						height: 30px;
					}
				}
				.role-filter-label {
					.role-shorthand {
						width: 30px;
						min-width: 30px;
						height: 30px;
						border-radius: 50%;
						font-size: 14px;
						font-weight: 700;
						text-transform: uppercase;
						background-color: $v2-role-shorthand-color;
						color: $color-white;
						text-align: center;
						padding-top: 6px;
						margin-right: 5px;
					}
				}
				.project-status-color-indicator {
					width: 30px;
					height: 30px;
					border-radius: 50%;
					cursor: pointer;
					margin: 2px;
					&.selected {
						border: 1px solid $v2-branding-purple;
					}
				}
				&.selected {
					color: $v2-branding-purple !important;
					.role {
						color: $v2-branding-purple !important;
					}
				}
			}
		}
	}
}
