.project-group-settings {
	display: flex;
	flex-direction: column;
	margin: 1.5625rem 2rem 0 1rem;

	.section-body {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		@include scroll-y;
		fieldset {
			@include content-frame;
			margin: 0 0 15px 0;
			padding: 20px;
			font-size: 0.875rem;

			.header-section {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				&.center {
					align-items: center;
					margin-bottom: 15px;
					h4 {
						margin-bottom: 0;
					}
				}
				h3,
				h4 {
					margin-top: 0;
				}
			}
			.title-id-inputs {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-bottom: 20px;
				.wrapper-locked {
					.input-wrapper {
						padding: 0;
					}
				}
				.id-wrapper {
					input {
						width: 50px;
					}
				}
				.prefix {
					font-weight: $base-font-medium-weight;
					padding: 25px 5px 0 5px;
					display: flex;
					align-items: center;
				}
			}
			.sprint-options,
			.wrapper {
				width: 220px;
			}
			label {
				display: flex;
				width: 100%;
				padding: 5px 0;
				input[type='checkbox'],
				input[type='radio'] {
					width: 16px;
					height: 16px;
				}
				span {
					padding: 0 0 0 8px;
				}
			}
			.hours-pr-point {
				display: inline-block;
				span {
					display: block;
					padding: 0 0 6px 2px;
				}
				.label {
					font-size: 0.75rem;
					color: #858585;
				}
				.hours-input-wrapper {
					width: 90px;
				}
			}
		}
	}
}
