.period-data-visuals-wrapper {
	width: 100%;
	min-height: 128px;
	max-height: 128px;
	text-align: center;
	color: white;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: row;
	margin-top: 1px;
	.time-reg-pie-wrapper {
		flex-grow: 0;
		width: 174px;
		max-height: 128px;
		border: solid 1px $v2-backdrop-gray;
		border-left: none;
		.radial-progress-bar-wrapper {
			max-height: 128px;
		}
	}
	.combined-number {
		display: flex;
		flex-direction: row;
		cursor: pointer;
		.period-text {
			flex-grow: 1;
			white-space: nowrap;
			text-overflow: ellipsis;
			text-align: right;
			font-size: 24px;
			font-weight: 500;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.75;
			letter-spacing: normal;
			text-align: right;
			color: $v2-text-gray;
		}
		.info-icon {
			transform: translateY(-6px);
			margin: 0 0 20px 14px; //14 so it is aligned with the handle conflict one
			flex-grow: 0;
			width: 20px;
			height: 20px;
		}
	}
	.hover-container {
		position: fixed;
		width: 150px;
		display: flex;
		flex-direction: column;
		z-index: 9;
		background: white;
		border: solid 1px $app-border-color;
		.hover-line {
			margin-top: 15px;
			margin-bottom: 5px;
			.hover-line-header {
				padding: 0 15px;
				font-size: 9px;
				text-transform: uppercase;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1;
				letter-spacing: 1px;
				text-align: right;
				color: $v2-text-gray;
				margin-bottom: 6px;
			}
			.hover-line-number {
				white-space: nowrap;
				text-overflow: ellipsis;
				padding: 0 15px;
			}
			&.total {
				white-space: nowrap;
				text-overflow: ellipsis;
				padding-top: 15px;
				border-top: solid 1px $app-border-color;
				margin-bottom: 15px;
			}
		}
	}
	.time-reg-chart-container {
		width: calc(100% - 340px - 174px); // ChartJS kind of needs to be forced into sizing properly
		padding-top: 14px;
		height: 128px;
		overflow: visible;
		flex-grow: 1;
		border: solid 1px $v2-backdrop-gray;
		canvas {
			z-index: 9;
		}
	}
	.period-tiles {
		min-width: 340px;
		flex-grow: 0;
		display: grid;
		grid-template-columns: 1fr 1fr;
		.tile {
			position: relative;
			font-size: 24px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.75;
			letter-spacing: normal;
			text-align: right;
			height: 64px;
			width: 170px;
			color: $v2-text-gray;
			text-align: right;
			padding-right: 15px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			.tile-header {
				margin-bottom: 10px;
				font-size: 9px;
				text-transform: uppercase;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1;
				letter-spacing: 1px;
				text-align: right;
			}
			.button-value-wrapper {
				display: flex;
				justify-content: space-between;
				margin-left: 15px;
			}
			&.overdue {
				padding-top: 14px;
				border-bottom: solid 1px $v2-backdrop-gray;
			}
			&.white-border {
				border-bottom: solid 1px white;
			}
			&.white {
				color: $v2-text-gray;
				border: solid 1px $v2-backdrop-gray;
				border-left: none;
			}
			&.red {
				background-color: $v2-retainer-conflict-red;
				border: solid 1px $v2-backdrop-gray;
				border-left: none;
				border-top: none;
				color: $color-white;
			}
			&.click {
				cursor: pointer;
			}
			.lock-button {
				width: 140px;
				height: 36px;
			}
			.fix-button {
				.text {
					white-space: nowrap;
				}
				color: #ffff;
				border-color: #ffff;
			}
			
			.suffixed-text {
				&.price {
					font-size: 18px;
				}
			}
		}
	}
}
.conflicts-tile {  
	background-color: #ec4b4b;
	.button-value-wrapper{  	
		color: #ffff
	}
	.tile-header{  	
		color: #ffff
	}
}
