.md-checkbox {
	$md-checkbox-border-color-disabled: rgba(0, 0, 0, 0.26);
	$md-checkbox-checked-color-disabled: rgba(0, 0, 0, 0.26);
	$md-checkbox-size: 16px;
	$md-checkbox-padding: 4px;
	$md-checkmark-width: 2px;
	position: relative;
	display: flex;
	justify-content: center;
	width: 16px;
	height: 16px;
	&.md-checkbox-inline {
		display: inline-block;
	}
	label {
		cursor: pointer;
		&:before,
		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
		}
		&:before {
			// box
			width: $md-checkbox-size;
			height: $md-checkbox-size;
			background: #fff;
			border: 2px solid $app-border-color;
			border-radius: 2px;
			//cursor: pointer;
			transition: background 0.3s;
		}

		&:after {
			// checkmark
		}
		&.focused {
			&:before {
				border: 2px solid #a1a4aa;
			}
		}
		&.locked {
			cursor: default;
		}
	}

	input[type='checkbox'] {
		outline: 0;
		width: 16px;
		height: 16px;
		&:checked {
			+ label:before {
				background: $color-white;
			}
			+ label:after {
				$md-checkmark-size: $md-checkbox-size - 2 * $md-checkbox-padding;
				transform: rotate(-45deg);
				top: ($md-checkbox-size / 2) - ($md-checkmark-size / 4) - $md-checkbox-size/10;
				left: $md-checkbox-padding;
				width: $md-checkmark-size;
				height: $md-checkmark-size / 2;
				border: $md-checkmark-width solid #62c193;
				border-top-style: none;
				border-right-style: none;
			}
		}
		&.group-has-entities-checked {
			&:checked {
				+ label:after {
					transform: none;
					border-left-style: none;
				}
			}
		}

		&:disabled {
			+ label:before {
				border-color: $md-checkbox-border-color-disabled;
			}
			&:checked {
				+ label:before {
					background-color: $md-checkbox-checked-color-disabled;
				}
			}
		}
	}
	*,
	*:before,
	*:after {
		box-sizing: border-box;
	}
}
