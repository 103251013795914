.retainer-task-table-wrapper {
	border: 1px solid $v2-backdrop-gray;
	border-top: none;
	.table-expand-bar {
		width: 100%;
		background: $drop-color;
		border-bottom: 1px solid $v2-backdrop-gray;
		cursor: pointer;
		display: flex;
		flex-direction: row;
		height: 30px;
		padding: 0 16px;
		.bar-title {
			flex-grow: 1;
			padding-top: 6px;
			font-size: 13px;
			font-weight: 750;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.38;
			letter-spacing: normal;
			color: $v2-text-gray;
		}
		.expand-icon {
			height: 24px;
			width: 24px;
			cursor: pointer;
			background: url('images/v2/components/dropdown/arrow-up.svg') no-repeat center;
			&.collapsed {
				background: url('images/v2/components/dropdown/arrow-down.svg') no-repeat center;
			}
		}
	}
}
