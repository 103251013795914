.settings-exchange-rates {
	&.section-content {
		margin: 0;
		.section-body {
			margin: 0 66px;
		}
	}
	table {
		width: auto !important;
		tr {
			.exchange-rate-line-item-value-input {
				width: auto;
				text-align: left;
			}
		}
	}
	.exchange-rate-line-item-value-input {
		padding: 6px 0;
	}
	.add-exchange-rate-input-value {
		text-align: left !important;
	}
	.add-exchange-rate-button {
		@include primary-button;
	}
	.line-item-delete-control {
		background-size: 16px;
		background-repeat: no-repeat;
		background-position: left center;
		padding: 0 10px 0 21px;
		cursor: pointer;
		background-image: url('images/cross-section-icons/actions/remove.svg');
		&:hover {
			text-decoration: underline;
		}
	}
	.delete-locked-info {
	}
	tr {
		.line-item-delete-control,
		.currency-used-info {
			visibility: hidden;
		}
		&:hover {
			.line-item-delete-control,
			.currency-used-info {
				visibility: visible;
			}
		}
	}
}
