// REPLACES app_page.scss

.app-catalog-page {
	margin: 0;
	.section-body {
		margin: 0 66px;
		.header-wrapper {
			display: flex;

			.title-description-wrapper {
				padding-left: 20px;
				white-space: break-spaces;
				p {
					margin-top: 0;
					margin-bottom: 0.5em;
				}
			}

			margin-bottom: 28px;
		}
	}

	.help-center-button {
		@include secondary-button;
	}

	.action-button {
		@include primary-button;
	}

	.content-wrapper {
		display: flex;
		flex-direction: row;
	}

	.button-row {
		display: flex;
		flex-direction: row;

		margin-top: 8px;

		.action-button {
			margin-right: 8px;
		}
	}

	.new-button-row {
		display: flex;
		flex-direction: row;

		margin-top: 8px;

		button {
			margin-right: 8px;
		}
	}
}
