// enlarged file window
.file-modal {
	z-index: 99;
	background: rgba(0, 0, 0, 0.75);
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	.flex-wrapper {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		.inner {
			box-shadow: 0 10px 20px 10px rgba(0, 0, 0, 0.2);
			border-radius: 5px;
			background: $color-white;
			display: flex;
			flex-direction: column;
			@include scroll-y;
			img {
				max-width: 90vw;
				max-height: 90vh;
			}
		}
		.preview-left {
			background: transparent url('images/v2/components/project-files/chevron_left_white.svg') no-repeat center;
		}
		.preview-right {
			background: transparent url('images/v2/components/project-files/chevron_right_white.svg') no-repeat center;
		}
		.preview-left,
		.preview-right {
			border: none;
			width: 48px;
			background-size: 48px;
			outline: none;
		}
	}
}
