.insights-section {
	.projects-bar {
		.project_snippet {
			display: flex;
			padding: 5px 10px 5px 5px;
			background: $color-white;
			border-bottom: 1px solid transparent;
			border-top: 1px solid transparent;
			cursor: pointer;

			&:hover {
				border-top: 1px solid #ececec;
				border-bottom: 1px solid #ececec;
			}

			&.selected {
				background: yellow;
				border-top: 1px solid #ececec;
				border-bottom: 1px solid #ececec;
			}

			.project-color {
				width: 10px;
				border-radius: 5px;
			}

			.project-title {
				padding-left: 5px;
				font-size: 13px;
				line-height: 18px;
				-moz-user-select: none;
				-ms-user-select: none;
				-webkit-user-select: none;
				user-select: none;
			}
		}
	}
}
