.harvest-pop-out {
	color: $color-black;
	position: absolute;
	top: 3px;
	height: 40px;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	&:focus {
		outline: none;
	}
	.harvest-sync-icon {
		width: 16px;
		height: 16px;
		margin-left: 6px;
		margin-top: 1.5px;
		svg {
			height: 12px;
			width: 12px;
		}
	}
	.harvest-retry {
		color: #4a90e2;;
		cursor: pointer;
		text-decoration: underline;
	}
	.harvest-button {
		height: 40px;
		width: 36px;
		cursor: pointer;
		background-image: url('images/integrations/harvest-logo-grey.png');
		background-size: 16px;
		background-position: center 11px;
		background-repeat: no-repeat;
		border: 1px solid transparent;
		&.has-time,
		.has-notes {
			background-image: url('images/integrations/harvest-logo.png');
		}
		&.has-notes {
			background-image: url('images/integrations/harvest-logo.png');
			position: relative;
			.number {
				position: absolute;
				top: 5px;
				right: 5px;
				width: 13px;
				height: 13px;
				background-color: $v2-branding-purple;
				color: $color-white;
				border-radius: 50%;
				font-size: 10px;
				text-align: center;
			}
		}
	}
	.harvest-loading {
		.loading-box {
			display: flex;
			flex-direction: column;
			height: 80px;
			&:hover,
			&.selected {
				background-color: $color-white;
			}
		}
		.loading-text {
			color: $v2-text-light-gray;
			text-align: center;
			font-style: italic;
		}
		.loading-item {
			background-color: $color-white;
			background-image: url('images/loaders/loader-32x32-brown.svg');
			background-repeat: no-repeat;
			background-size: 25px;
			background-position: center;
			height: 100%;
			width: 100%;
			display: block;
			margin: 0 auto;
		}
	}

	.notes-pop-out {
		@include pop-out-v2;
		max-height: 350px;
		width: 304px;
		display: flex;
		flex-direction: column;
		.notes-header {
			margin: 3px 16px;
			white-space: normal;
			.notes-header-title {
				margin: 5px 0px;
				font-style: italic;
				color: $v2-text-light-gray;
			}
			.task-name {
				color: $v2-text-gray;
			}
		}
		.notes-container {
			margin: 5px 16px 0px 16px;
			position: relative;
			display: flex;
			flex-direction: column;
			//width: 304px;
			border-radius: 4px;

			.note-header {
				margin-top: 16px;
				margin-bottom: 7px;
				display: flex;
				flex-direction: row;
				.note-date {
					text-transform: capitalize;
					display: flex;
					text-align: center;
					margin: 0;
					font-weight: bold;
				}
				.note-hours {
					display: flex;
					padding-left: 2px;
				}
				.locked-time-reg {
					background-image: url('images/integrations/xero/locked.svg');
					background-repeat: no-repeat;
					background-size: 15px 15px;
					background-position-y: center;
					background-position-x: center;
					width: 20px;
					height: 15px;
				}
			}
			.note {
				&:last-child {
					margin-bottom: 12px;
				}
			}
			.note-content {
				position: relative;
				background-color: $color-white;
				text-align: left;
				width: 272px;
				border-radius: 4px 0 0 4px;
				border-left: none;
				textarea {
					resize: none;
					padding: 8px 10px 0 10px;
					border-radius: 0 4px 4px 0;
					border: solid 1px $v2-grey;
					border-radius: 4px;
					background-color: $color-white;
					height: 70px;
					width: 100%;
					font-size: 12px;
					line-height: normal;
					&::placeholder {
						color: $v2-text-light-gray;
						font-size: 12px;
						font-weight: normal;
						font-stretch: normal;
						line-height: normal;
						letter-spacing: normal;
					}
					&:hover {
						border-color: $v2-text-light-gray;
					}
					&:focus {
						outline: none;
						border-color: $v2-branding-purple;
					}
					&.disabled {
						background: $brand-color-disabled;
						&:hover {
							border-color: $v2-grey;
						}
					}
				}
			}
		}
	}
}
