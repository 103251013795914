.project-time-reports-old {
	display: flex;
	flex-direction: column;
	background: $color-light-grey;
	.section-header {
		h2 {
			margin: 10px 0 0 10px;
		}
		.filters {
			@include period-filter;
		}
	}

	.section-body {
		@include scroll-y;

		@include standard-table;

		.data-tiles {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			padding: 15px;
			border-bottom: 1px solid $app-border-color;
			background: $color-white;

			.data-tile {
				@include data-tile;
			}
		}

		.team-member-reports {
			.team-member-report {
				@include content-frame;
				margin: 0 15px 15px 15px;
				&:first-child {
					margin-top: 15px;
				}
				table {
					width: 100%;
					thead {
						th {
							&:first-child {
								padding-left: 15px;
								display: flex;
								justify-content: space-between;
							}

							&:last-child {
								padding-right: 15px;
							}
						}

						.forecast-person {
							width: 100%;
						}

						.toggle-project-stats {
							font-weight: 400;
							font-size: 13px;
							padding-top: 3px;
							cursor: pointer;
							&:hover {
								text-decoration: underline;
							}
						}
					}

					tbody {
						.hours {
							&::after {
								content: ' h';
							}
						}

						.task-cell {
							min-width: 300px;
							padding-left: 15px;
						}

						.input-cell {
							border-left: 1px solid $app-border-color;

							&:last-child {
								padding-right: 15px;
							}
							input {
								width: 100%;
							}
						}

						.total-cell {
							font-weight: $base-font-medium-weight;
						}

						.team-member-total {
							font-weight: $base-font-medium-weight;

							.tasks-total {
								padding-left: 15px;
							}
							.day-total {
								text-align: right;
								padding-right: 15px;
							}
						}
					}

					.project-stats {
						td {
							white-space: nowrap;
							font-weight: $base-font-medium-weight;

							border-top: 1px solid $app-hard-border-color;
							border-bottom: 1px solid $app-hard-border-color;
							&:first-child {
								padding-left: 15px;
							}

							&:last-child {
								padding-right: 15px;
							}
						}

						&.collapsed {
							display: none;
						}
					}
					.time-report-line-item {
						.task-cell {
							&:hover {
								cursor: pointer;
							}
						}
					}
				}
			}
		}
	}
}
