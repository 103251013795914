.expense-item-modal {
	position: fixed !important;

	.generic-modal-content {
		.content-container {
			.header-container {
				.header-text {
					font-family: $v2-site-header-font-family !important;
				}
			}
		}
	}
}
.expense-item-modal-content {
	display: flex;
	flex-direction: column;
	max-height: 80vh;
	width: 456px;
	color: #535353;

	.tabs {
		@include master-tab;

		.tab {
			text-transform: none;
			font-size: 13px;
			min-width: initial;
			margin-right: 1em;
			padding: 0;
			height: 1.6rem;
			color: $v2-text-gray;
			font-weight: 400;

			&.selected {
				font-weight: bold;
				color: $v2-branding-purple;
				border-bottom: 3px solid $v2-branding-purple;
			}
		}
	}
	.body {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		padding-right: 15px;

		.input-control {
			margin-top: 24px;
		}

		.expense-modal-row {
			display: flex;
			margin-top: 24px;
			.input-control-v2 {
				display: flex;
				flex-direction: column;
				&:not(:last-of-type) {
					margin-right: 8px;
				}
				&.date-picker {
					width: 112px;
				}
				&.grow {
					flex-grow: 1;
				}
				&.total {
					width: 33%;
					.total-title {
						height: 11px;
						font-size: 9px;
						line-height: 1.22;
						letter-spacing: 1px;
						color: $v2-text-light-gray;
						text-transform: uppercase;
						margin-bottom: 5px;
						white-space: nowrap;
						text-align: right;
					}
					.total-amount {
						font-size: 13px;
						text-align: right;
						height: 40px;
						line-height: 40px;
					}
				}
				&.selection-dropdown {
					width: 50%;

					// quick fix for weird alignment issue of different dropdowns. Should probably be replaced when switching to new dropdown component
					display: flex;
					flex-direction: column;
				}
			}
			&.options-dropdowns {
				.input-control-v2 {
					&.two-lines {
						&:not(.planned-revenue) {
							label {
								margin-bottom: -5px;
								margin-top: 10px;
							}
						}
						.drop-down-v2 {
							label {
								height: 22px;
							}
							.arrow {
								top: 27px;
							}
						}
					}
				}
			}
			&.notes {
				.rich-text-field-editor-wrapper {
					min-height: 35px;
				}
			}
		}

		&.files-wrapper {
			.new-file,
			.new-file-over {
				color: #535353;
				font-size: 13px;
				text-align: center;
				justify-content: center;
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 39px 0;
				border-radius: 4px;
				color: #535353;
				.file-link {
					color: #6e0fea;
					text-decoration: underline;
					cursor: pointer;

					input {
						display: none;
					}
				}
				&.locked {
					color: #c4c4cc;
					background: #f7f7fe;
					.file-link {
						color: #c4c4cc;
						cursor: default;
					}
				}
			}
		}

		.drop-down-v2,
		.input-container-v2 {
			width: 100%;
		}
		.date-picker-label {
			height: 11px;
			font-family: $v2-site-header-font-family;
			font-size: 9px;
			line-height: 1.22;
			letter-spacing: 1px;
			color: $v2-text-light-gray;
			text-transform: uppercase;
			margin-bottom: 5px;

			&.status-label {
				margin-bottom: 16px;
			}
		}
		h3 {
			margin: 15px 0 5px 0;
		}

		.placeholder {
			font-size: 14px;
		}

		.new-file,
		.new-file-over {
			margin: 16px 0;
			flex: 0 0 auto;
		}
		.files-preview-container {
			display: flex;
			flex-direction: row;
			width: 100%;
			flex-wrap: wrap;

			.file-preview {
				width: 146.5px;
				height: 120px;
				margin-right: 8px;
				flex-direction: column;

				&:hover {
					.file-name {
						color: $v2-branding-purple;
						.download .download-icon {
							opacity: 1;
							svg path {
								fill: $v2-branding-purple;
							}
						}
						.new-ui-actions-menu #actions-btn {
							opacity: 1;
						}
						p {
							transform: translateX(15px);
						}
					}
				}

				&:nth-child(3n) {
					margin-right: 0;
				}

				.image-preview {
					width: 100%;
					height: 100%;
					padding: 0;
					border: none;
					position: relative;

					&:hover {
						border-color: $v2-branding-purple;
						.file-options {
							opacity: 1;
						}
					}

					.file-options {
						position: absolute;
						top: 0px;
						left: 0px;
						width: 100%;
						height: 100%;
						background-color: rgba(255, 255, 255, 0.7);
						opacity: 0;
						transition: all 0.1s linear;
						display: flex;

						.file-option-container {
							flex: 1 0 0%;
							cursor: pointer;
							border: 1px solid $v2-branding-purple;
							display: flex;
							align-items: center;
							justify-content: center;
							border-radius: 4px;

							.full-screen-icon {
								background-image: url('images/v2/task-modal/expandpicicon-black.svg');
								width: 16px;
								height: 16px;
								background-size: 16px;
								background-repeat: no-repeat;
							}

							&:hover {
								.full-screen-icon {
									background-image: url('images/v2/task-modal/expandpicicon-purple.svg');
								}
							}
						}
					}

					img {
						border-radius: 4px;
						width: 100%;
						max-height: 100%;
						object-fit: cover;
						max-width: 100%;
						max-height: 100%;
					}
				}

				.file-name {
					font-size: 12px;
					line-height: 1.1;
					color: $v2-text-light-gray;
					width: 100%;
					height: 20px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-top: 2px;
					position: relative;

					input[type='text'] {
						width: 100%;
						color: initial;
						outline: none;
						padding: 0 4px;

						&:focus {
							border-radius: 4px;
							border: 1px solid $v2-branding-purple;
						}
					}

					.download {
						.download-icon {
							width: 9px;
							height: 18px;
							margin: 0 2px;
							opacity: 0;
							transition: opacity 0.2s;

							svg {
								width: 9px;
							}
						}
					}

					.new-ui-actions-menu {
						height: 20px;
						#actions-btn {
							height: inherit;
							width: 20px;
							background-size: contain;
							opacity: 0;
						}
					}

					input {
						border: none;
						width: 75px;
					}

					p {
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						margin: 0;
						position: absolute;
						width: 115px;
						transition: all 0.2s;
					}

					.delete-icon {
						width: 12px;
						height: 12px;
						position: absolute;
						right: 0;
						background-image: url('images/components/close-modal.svg');
						cursor: pointer;
					}
				}
			}
		}
		.generic-input-wrapper {
			.input-container,
			.select {
				max-width: none;
			}
		}
		.financial-inputs {
			display: flex;
			justify-content: space-between;
			margin: 0;
			margin-top: 16px;

			.input-container-v2 {
				width: 143.5px;

				.input-title {
					text-align: right;
				}

				input {
					color: #535353;
					font-size: 13px;
					font-weight: 400;
				}
			}
			.wrapper,
			.wrapper-locked {
				width: 85px;
				margin-right: 10px;
			}

			::placeholder {
				font-size: 16px;
			}
		}
		.date-picker-wrapper {
			padding-top: 12px;
		}
		.notes {
			margin-top: 24px;

			.editor {
				.rich-text-field-input-container {
					border: solid 1px #e6e6e6;
					background-color: #fff;
					border-radius: 4px;
				}
			}
		}

		.radio-button-row {
			display: flex;
			align-items: center;
			margin-bottom: 24px;

			.radio-button-label {
				font-size: 13px;
				color: $v2-text-gray;
				margin-left: 8px;
			}
		}

		.line {
			margin-top: 24px;
			border: 1px solid $color-medium-gray;
		}

		.checkboxes-section {
			margin-top: 24px;
			.checkbox-row {
				display: flex;
				font-size: 13px;
				line-height: 16px;
				&:not(:first-child) {
					margin-top: 20px;
				}
				input[type='checkbox'] {
					margin-right: 8px;
				}
				.checkbox-text {
					&.disabled {
						color: $v2_text_light_gray;
					}
				}
			}
		}

		.billable-section {
			padding: 24px;
			margin-top: 24px;
			background-color: #f7f7fe;
			.checkboxes-section {
				margin-top: 0;
			}
		}
	}
}
