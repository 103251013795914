.settings-rate-card {
	&.section-content {
		margin: 0;
		.section-body {
			margin: 0 66px;
		}
	}
	.flex-container {
		display: flex;
		flex-direction: row;
		.rate-card-left {
			padding-right: 20px;
			border-right: 1px solid gray;
			.submit-button {
				display: inline-block;
				@include primary-button;
				background-color: white;
				margin-bottom: 10px;
			}

			table {
				tbody {
					tr {
						cursor: pointer;
						.controls {
							span {
								background-size: 16px;
								background-repeat: no-repeat;
								background-position: left center;
								padding: 0 10px 0 21px;
								cursor: pointer;
								&:hover {
									color: $color-purple-hover-active;
								}
								&.edit {
									background-image: url('images/cross-section-icons/actions/edit.svg');
								}
								&.remove {
									background-image: url('images/cross-section-icons/actions/remove.svg');
								}
								&.enable {
									background-image: url('images/cross-section-icons/actions/enable.svg');
								}
							}
						}
					}
				}
			}
		}
		.rate-card-middle {
			padding-left: 20px;
			padding-right: 20px;
			flex-grow: 1;
			.top-controls {
				display: flex;
				flex: 1;
				justify-content: space-between;
				@include gear-menu;
				.inputs-wrapper {
					display: flex;
					flex: 1 1 auto;
					align-items: flex-end;
					padding-bottom: 5px;
					.select-wrapper,
					.select-wrapper-locked {
						margin-left: 35px;
						min-width: 240px;
					}
					.currency-locked-info {
						margin-left: 15px;
						margin-bottom: 10px;
					}
				}
			}
			.name-label {
				span {
					display: block;
					padding: 0 0 6px 2px;
				}
				.rate-card-name {
					margin: 0 10px 10px 0;
					padding: 9px 10px;
					font-weight: $base-font-medium-weight;

					border: 1px solid $app-border-color;
					border-radius: 3px;
				}
			}
			.effective-date-label {
				span {
					display: block;
					padding: 0 0 6px 2px;
				}
				.date-picker {
					margin: 0 10px 0 0;
					padding: 9px 10px;
					font-weight: $base-font-medium-weight;

					border: 1px solid $app-border-color;
					border-radius: 3px;
				}
			}
			table {
				width: 100%;
				max-width: 500px;
				.rate-header {
					text-align: right;
					padding-right: 10px;
					width: 30%;
				}
				.rate-card-row {
					input {
						border: none !important;
					}
					input:hover:not(:disabled) {
						box-shadow: 0 0 0 1pt #e6e6e6;
					}
				}

				.lbl-default {
					font-weight: bold;
				}
				.add-span {
					background-size: 16px;
					background-repeat: no-repeat;
					background-position: left center;
					padding: 0 10px 0 21px;
					cursor: pointer;
					background-image: url('images/cross-section-icons/actions/add.svg');
				}
				.add-span:hover {
					color: $color-purple-hover-active;
				}
			}
		}
		.rate-card-right {
			padding-left: 20px;
			border-left: 1px solid gray;

			tbody {
				tr {
					cursor: pointer;
				}
			}
			.client-specific-label {
				font-weight: bold;
				margin-left: 10px;
				padding-bottom: 5px;
			}
			.rate-card-client {
				display: flex;
				flex-direction: row;
				align-items: center;
			}
			.rate-card-client-name {
				font-weight: bold;
			}
			img {
				margin-left: 10px;
				margin-right: 10px;
			}
		}
	}
}
