.owner-section {
	// margin-right: 10px;
	outline: none;
	display: flex;
	justify-content: center;
	position: relative;

	.owner-click-container {
		display: flex;
		align-items: center;
		cursor: pointer;

		.owner-text {
			font-size: 9px;
			letter-spacing: 1px;
			color: $v2-text-light-gray;
			text-transform: uppercase;
			margin-right: 6px;
		}

		.owner-image {
			background-size: 24px 24px;
			height: 24px;
			width: 24px;
		}
	}

	@import './owner_menu.scss';
}
