.textarea-foldout {
	position: relative;
	input {
		text-overflow: ellipsis;
	}
	.foldout {
		position: absolute;
		top: 0;
		left: 0;

		display: flex;
		flex-direction: column;
		gap: 4px;

		background-color: $color-white;
		box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		border-radius: 3px;
		border: solid 1px $light-border-color;
		padding: 8px;
		z-index: 99;

		textarea {
			resize: none;
			outline: none;
			border: none;
		}
	}
	.label {
		height: 11px;
		font-family: $v2-site-header-font-family;
		font-size: 9px;
		font-weight: 500;
		line-height: 1.22;
		letter-spacing: 1px;
		color: $v2-text-light-gray;
		text-transform: uppercase;
		margin-bottom: 5px;
		.required-extension {
			color: $negative-value;
		}
	}
}
