.qb-buttons {
	display: flex;
	flex-direction: row;

	.connect-quickbooks {
		width: 100px;
		height: 36px;
		background-image: url('images/integrations/quickbooks/connect-button.png');
		background-size: 100px 36px;
		background-repeat: no-repeat;
		border: none;
		outline: none;

		&:hover,
		&:focus {
			background-image: url('images/integrations/quickbooks/connect-button-hover.png');
		}
	}
}
