.task-context-menu {
	position: fixed;
	color: $v2-text-gray;
	border-radius: 4px;
	min-height: 36px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
	background-color: $color-white;
	border: solid 1px $v2-grey;
	z-index: 999;
	outline: none;
	margin: 0;
	padding: 0;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
	list-style-type: none;
	text-align: left;
	font-size: 12px;
	font-style: normal;
	font-stretch: normal;
	line-height: 0.92;
	letter-spacing: normal;
	font-weight: 500;
	cursor: pointer;
	.selected-tasks-count-indicator {
		position: absolute;
		left: -10px;
		top: -10px;
		width: 20px;
		height: 20px;
		border-radius: 3px;
		background-color: $v2-branding-purple;
		.count-text {
			color: $color-white;
			font-size: 11px;
			font-weight: bold;
			font-family: $text-font-family;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	.context-menu-option {
		padding: 6px 16px;
		margin-bottom: 4px;
		font-weight: normal;
		&:hover {
			color: $v2-branding-purple;
			background: rgba(110, 15, 234, 0.1);
		}
		&.disabled {
			color: $v2-text-light-gray;
			&:hover {
				background: none;
			}
			//background: $input-placeholder-color-disabled;
		}
		&:first-child {
			margin-top: 4px;
		}
	}
	:focus {
		outline: none;
	}
}
