.upload-menu-wrapperV2,
.context-menu-optionV2 {
	position: relative;
	min-width: 108px;
	.input-fileV2 + label {
		display: flex;
		flex: 1 1 auto;
		cursor: pointer;

		padding-left: 15px;
		padding-right: 15px;
		padding-top: 15px;
		padding-bottom: 7px;
	}
	.input-fileV2 {
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
	}
	.option-icon {
		width: 16px;
		height: 14px;
		margin-right: 9px;
		background-size: contain;
		background-repeat: no-repeat;
		&.attach {
			background-image: url('images/v2/components/project-files/attach-computer.svg');
		}
		&.g-drive {
			background-image: url('images/v2/components/project-files/attach-google-drive.svg');
		}
	}
}
.upload-menu-wrapperV2 {
	position: relative;
	z-index: 2;
	.spacer {
		width: 108px;
		height: 6px;
		background-color: $color-white;
		z-index: 2;
		position: absolute;
		top: 28px;
		border-left: 2px solid $v2-branding-purple;
		border-right: 2px solid $v2-branding-purple;
	}
	.upload-menu {
		position: absolute;
		right: 0;
		top: 32px;
		min-width: 168px;
		border: 2px solid $v2-branding-purple;
		border-top-right-radius: -2px;
		list-style: none;
		background-color: $color-white;
		padding: 0;
		margin: 0;
		li {
			font-size: 13px;
			color: $v2-text-gray;
			outline: none;
			cursor: pointer;
			padding: 0;
			&.google-drive-option {
				padding-left: 15px;
				padding-right: 15px;
				padding-top: 7px;
				padding-bottom: 15px;
			}
			&:hover {
				color: $v2-branding-purple;
				.option-icon {
					&.attach {
						background-image: url('images/v2/components/project-files/attach-computer-active.svg');
					}
					&.g-drive {
						background-image: url('images/v2/components/project-files/attach-google-drive-active.svg');
					}
				}
			}
		}
	}
	&.expanded {
		/* .custom-button-container {
			&.outline.purple {
				z-index: 2;
				border-bottom-color: $color-white;
				height: 34px;
				position: absolute;
				top: 0;
				outline: none;
				&:hover {
					border-bottom-color: $color-white;
				}
			}
		} */
	}
}
