.favourite-tasks-list {
	display: flex;
	flex-direction: column;
	.header-bar {
		padding-left: 26px;
		.header-bar-left {
			> span {
				font-family: $heading-font-family;
				font-weight: 600;
			}
		}
	}
	.table-title {
		margin: 0 24px 16px 24px;
		//font-family: 'urbane', sans-serif;
		font-size: 16px;
		font-weight: 600;
		color: #535353;
	}

	.table-wrapper {
		margin-left: 24px;
		.table {
			margin-right: 24px;
			border: solid 1px $v2-button-very-light-grey;
			border-bottom: 0px;
		}
		.task-table-header-v3 {
			padding-left: 10px;
			.row-project-id {
				padding-left: 16px;
			}
		}

		.list-header {
			margin-left: 26px;
		}
		.table-row-v3,
		.elem-wrapper {
			.project-indicator-component {
				margin-left: 16px;
			}
		}
		.chip-container {
			.chip {
				background: white;
				background-color: white;
				&.has-phase {
					background-color: white !important;
				}
			}
		}
		.table {
			display: flex;
			flex-direction: column;
			.header-wrapper {
				cursor: default;
			}
			.virtualized-scrollbar-inner,
			.no-scroll-container {
				padding: 0 10px;
			}
		}
	}
}
