.project-status-indicator {
	height: 30px;
	.text {
		font-family: $text-font-family;
		font-size: 11px;
		font-weight: 700;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.09;
		letter-spacing: 0.5px;
		text-transform: uppercase;
		margin: 10px 12px 10px 0;
	}
	.indicator-icon {
		width: 16px;
		height: 16px;
		background-size: 14px !important;
		background-position: center !important;
		background-repeat: no-repeat !important;
		margin: 7px 5px 7px 12px;
	}
	.done-indicator {
		display: flex;
		flex-direction: row;
		background-color: rgba(0, 204, 102, 0.15);
		height: 30px;
		.indicator-icon {
			background: url('images/v2/components/checkmark_green.svg');
		}
		.text {
			color: $v2-receipt-green;
		}
	}
	.halted-indicator {
		display: flex;
		flex-direction: row;
		background-color: rgba(208, 2, 27, 0.15);
		height: 30px;
		.indicator-icon {
			background: url('images/v2/components/halted-project-indicator.svg');
		}
		.text {
			color: $negative-value;
		}
	}
}
