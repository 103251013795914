.department-container {
	.collapsed-splitter {
		border-top: 1px solid #e6e6e6;
		margin-bottom: 14px;
	}

	.department-person-table {
		border-collapse: collapse;
		width: 100%;

		.department-table-header {
			text-transform: uppercase;
			font-family: $text-font-family;
			font-size: 9px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: 1px;
			color: #a1a1a1;
			padding-bottom: 8px;
			width: 20%;
		}

		.department-person-row {
			border-top: 1px solid #e6e6e6;
			height: 40px;
			font-size: 13px;

			.person-cell {
				&:hover {
					color: $v2-branding-purple;
				}
				cursor: pointer;
			}

			.remove-person-cell {
				width: 40px;

				.remove-person-button {
					cursor: pointer;
					display: none;
				}
			}

			&:hover {
				.remove-person-button {
					display: block;
				}
			}
		}

		.department-add-people-footer {
			border-bottom: 1px solid #e6e6e6;
			border-top: 1px solid #e6e6e6;

			td {
				padding-top: 10px;
				padding-bottom: 16px;
			}
		}
	}

	.settings-department-head-row {
		display: flex;
		cursor: pointer;
		align-items: center;
		height: 40px;
		.input-container-v2,
		.department-name-container {
			width: 451px;
		}
		.department-name-container {
			height: 30px;
			display: flex;
			align-items: center;
			&:hover {
				background-color: #f3f3f3;
				border-radius: 3px;
			}
			.department-name {
				padding-left: 0;
				font-size: 14px;
				font-weight: 500;
				user-select: none;
				span {
					font-weight: normal;
				}
			}
			.department-integrations {
				margin-top: 4px;
				padding-left: 8px;
				height: 30px;
			}
		}
		.input-container-v2 {
			height: 30px;

			.input-value {
				padding-left: 0;
			}

			.input-value-container {
				border: none;
				background-color: #f3f3f3;
				input {
					font-size: 14px;
					font-weight: 500;
				}
			}
		}
		.expansion-button {
			margin-left: auto;
			border: none;
			background-image: url('images/v2/components/dropdown/arrow-down.svg');
			background-repeat: no-repeat;
			background-position: center;
			width: 16px;
			height: 16px;
			margin-right: 12px;
			outline: 0;
			&.expanded {
				background-image: url('images/v2/components/dropdown/arrow-up.svg');
			}
		}
	}
}
