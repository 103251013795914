*,
body,
button,
input,
select,
textarea {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
}

html,
body {
	font-size: $base-font-size;
	font-family: $text-font-family;
	font-weight: 400;
	color: $text-base-color;
}

h1,
h2 {
	font-family: $heading-font-family;
	line-height: 1.2;
	font-weight: inherit;
}

h3,
h4,
h5,
h6 {
	font-family: $text-font-family;
	line-height: 1.2;
	font-weight: inherit;
}

a {
	color: $text-base-color; //$brand-color;
	&:hover {
		color: $color-purple-hover-active;
	}
}

// Modular fluid scale

$mod_1: 1.125;
$mod_2: 1.333;

// Fluid Type Mixin

@function strip-unit($value) {
	@return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
	$u1: unit($min-vw);
	$u2: unit($max-vw);
	$u3: unit($min-font-size);
	$u4: unit($max-font-size);

	@if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
		& {
			font-size: $min-font-size;
			@media screen and (min-width: $min-vw) {
				font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
			}

			@media screen and (min-width: $max-vw) {
				font-size: $max-font-size;
			}
		}
	} @else {
		@error "Detected mixed units. Please use the same units for all parameters. " + $u1 +", " + $u2 + ", " + $u3 +", "+ $u4;
	}
}

// Calculate Modular Fluid Scale

$h1-min: $mod_1*$mod_1*$mod_1*$mod_1 * 0.8rem; //20.5px
$h1-max: $mod_2*$mod_2*$mod_2*$mod_2 * 0.7rem; //35.4px

$h2-min: $mod_1*$mod_1*$mod_1 * 0.8rem; //18.2px
$h2-max: $mod_2*$mod_2*$mod_2 * 0.7rem; //26.5px

$h3-min: $mod_1*$mod_1 * 0.8rem; //16.2px
$h3-max: $mod_2*$mod_2 * 0.6rem; //17.1px

$h4-min: $mod_1* 0.8rem; //14.4px
$h4-max: $mod_2 * 0.7rem; //14.9px

$h5-min: 0.8rem; //12.8px
$h5-max: 0.9rem; //14.4px

$h6-min: (0.9rem / $mod_2); //10.8
$h6-max: (0.9rem / $mod_1); //12.8

// Apply fluid type mixin

h1 {
	@include fluid-type(37.5rem, 80rem, $h1-min, $h1-max);
	margin: 0;
	font-weight: $base-font-semibold-weight;
}

h2 {
	@include fluid-type(37.5rem, 80rem, $h2-min, $h2-max);
	font-weight: $base-font-semibold-weight;
}

h3 {
	@include fluid-type(37.5rem, 80rem, $h3-min, $h3-max);
	font-weight: $base-font-semibold-weight;
}

h4 {
	@include fluid-type(37.5rem, 80rem, $h4-min, $h4-max);
	font-weight: $base-font-semibold-weight;
}

h5 {
	@include fluid-type(37.5rem, 80rem, $h5-min, $h5-max);
}

h6 {
	@include fluid-type(37.5rem, 80rem, $h6-min, $h6-max);
}

small,
.font_small {
	font-size: 0.75rem;
}