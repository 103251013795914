.cost-periods {
	.cost-period {
		display: flex;
		align-items: center;
		border-bottom: 1px solid $app-border-color;
		padding: 4.5px 0;

		&.active {
			color: $v2-branding-purple;

			.start-date {
				.no-start-date,
				.no-end-date {
					color: $v2-branding-purple;
				}
			}

			.line {
				color: $v2-branding-purple;
			}

			.date-picker-button:not(.locked) {
				color: $v2-branding-purple;
			}
		}

		.calendar-wrapper {
			top: 46px;
		}

		.line {
			color: #535353;
		}

		.no-start-date,
		.no-end-date {
			font-size: 13px;
			font-style: italic;
			color: $v2-date-picker-button-border-color;
		}

		.action-menu {
			width: 40px;
			text-align: right;

			.new-ui-actions-menu {
				display: flex;
				justify-content: flex-end;
			}
		}
	}
}
