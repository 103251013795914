.content {
	.budget-type {
		min-height: 70px;
		max-height: 170px;
		&.empty {
			min-height: 0;
			max-height: 0;
		}
		label {
			height: 11px;
			font-size: 9px;
			font-weight: 500;
			letter-spacing: 1px;
			color: $v2-text-light-gray;
			text-transform: uppercase;
			white-space: nowrap;
			margin-bottom: 4px;
			&.tooltip {
				position: relative;
				top: -3px;
			}
		}
		.info-label {
			font-size: 13px;
			line-height: 1.5;
			letter-spacing: 0.5px;
			color: $v2-text-gray;
			&.disabled {
				color: $v2-text-light-gray;
			}
			&.control-label {
				margin-left: 8px;
			}
			&.margin-right {
				margin-right: 24px;
			}
		}
		.information-icon {
			margin-left: 2px;
			height: 12px;
			bottom: -2px;
			position: relative;
		}
		.input-container-v2 {
			//justify-content: space-between;
			.input-value-container {
				height: 30px;
				font-weight: normal;
				.input-value-unit {
					line-height: 28px;
				}
			}
			.currency-placeholder {
				&::before {
					top: 6px;
				}
			}
		}
		.radio-options {
			display: flex;
			align-items: baseline;
			margin-bottom: 23px;
			margin-right: 8px;

			.radio-button-controls-wrapper{
				margin-top: 6px;
				display: flex;
				align-items: center;

				span {
					cursor: pointer;
				}
			}
		}
	}
}
