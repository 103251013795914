.add-team-member-modal {
	.seats-count-info {
		max-width: 90%;
	}
	.seats-text {
		padding: 10px 16px;

		background-color: $color-medium-gray;
		font-size: 12px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.33;
		letter-spacing: normal;
		font-family: $text-font-family;
		color: $v2-text-gray;
	}
}

.add-team-member-modal-content {
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	&.created {
		align-items: flex-end;
	}
	.input-container-v2 {
		margin-top: 12px;
		margin-right: 5px;
		flex: 0 0 190px;
	}
	.drop-down-v2 {
		margin-top: 12px;
		margin-right: 5px;
		flex: 0 0 190px;
	}
	.disclaimer {
		margin-top: 12px;
		font-size: 12px;
	}
	.invite-new {
		width: 100%;
		height: 40px;
		outline: none;
		padding: 0 16px 0 32px;
		margin-top: 12px;
		background: url('images/v2/components/header/plus.svg') no-repeat 22px 12px;
		background-size: 12px auto;
		background-color: $v2-create-button-green;
		&.header-margin {
			margin-top: 28px;
		}
		&:focus,
		&:hover {
			background-color: $v2-create-button-green-alpha;
		}
		.create-new-btn-text {
			font-size: 10px;
			color: white;
			font-weight: 500;
			letter-spacing: 0.5px;
		}
	}
	.invited-success {
		flex: 1 1 auto;
		height: 40px;
		outline: none;
		padding: 0 16px 0 32px;
		margin-top: 12px;
		background: url('images/v2/components/checkmark_green.svg') no-repeat 30px 14px;
		background-size: 12px auto;
		background-color: white;
		letter-spacing: normal;
		word-spacing: normal;
		text-transform: none;
		text-indent: 0px;
		text-shadow: none;
		text-align: center;
		&.header-margin {
			margin-top: 28px;
		}
		.success-text {
			font-size: 10px;
			color: black;
			font-weight: 500;
			letter-spacing: 0.5px;
			padding-top: 13px;
		}
	}
	&.created-vs-invited {
		justify-content: flex-start;
		.invite-new {
			flex: 1 1 auto;
		}
		.invited-success {
			display: flex;
			align-items: center;
			flex: 1 1 auto;
			background: none;
			padding: 0 8px 0 8px;
			margin-right: 8px;
			text-align: left;
			gap: 8px;
			.success-text {
				font-family: 'Inter';
				font-weight: 700;
				font-size: 12px;
				color: #3C9044;
				text-transform: uppercase;
				letter-spacing: normal;
				padding-top: 0px;
			}
		}
		.input-container-v2,
		.drop-down-v2 {
			flex: 0 0 200px;
			margin-right: 8px;
		}
	}
}
