.status-column-category-selector-wrapper-v2 {
	margin-right: 10px;
	position: relative;
	outline: none;
	display: flex;
	&.new {
		padding: 4px;
		margin-right: 6px;
		&:not(&.disabled) {
			&:focus,
			&:hover {
				background-color: $v2-backdrop-gray;
			}
		}
	}

	button {
		border: none;
	}
	.category-icon {
		width: 18px;
		height: 21px;
		cursor: pointer;
	}
	.category-icon {
		&.new {
			&:focus,
			&:hover {
				outline: none;
			}
			width: 14px;
			height: 16px;
			cursor: pointer;
			&.locked {
				cursor: auto;
			}
		}
	}

	.TODO-NEW {
		background: url('images/v2/components/col_to_do.svg') no-repeat;
	}
	.TODO {
		background: url('images/v2/components/workflow-column/todo-category-icon.svg') no-repeat;
	}
	.INPROGRESS-NEW {
		background: url('images/v2/components/col_in_progress.svg') no-repeat;
	}
	.INPROGRESS {
		background: url('images/v2/components/workflow-column/inprogress-category-icon.svg') no-repeat;
	}
	.DONE-NEW {
		background: url('images/v2/components/col_done.svg') no-repeat;
	}
	.DONE {
		background: url('images/v2/components/workflow-column/done-category-icon.svg') no-repeat;
	}
	.category-options-list,
	.category-options-list-new {
		position: absolute;
		z-index: 99;
		top: 22px;
		left: 0;
		background-color: $v2-negative-dropdown-grey;
		display: flex;
		flex: 1, 1, auto;
		flex-direction: column;
		width: 140px;
		padding: 16px 0 16px 8px;
		box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
		transition: transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
		transform: scaleY(1);
		transform-origin: left top 0px;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		.category-option,
		.category-option-new {
			display: flex;
			flex: 0, 0, auto;
			flex-direction: row;
			justify-content: flex-start;
			font-size: 14px;
			margin-top: 8px;
			cursor: pointer;
			font-weight: 700;
			align-items: center;
			color: $v2-workflow-column-category-option-grey;
			span {
				margin-left: 5px;
			}
			&.preselected,
			&:hover {
				color: $color-white;
				.TODO {
					background: url('images/v2/components/workflow-column/todo-category-icon-white.svg') no-repeat;
				}
				.INPROGRESS {
					background: url('images/v2/components/workflow-column/inprogress-category-icon-white.svg') no-repeat;
				}
				.DONE {
					background: url('images/v2/components/workflow-column/done-category-icon-white.svg') no-repeat;
				}
			}
		}
		.selected {
			color: $v2-create-button-green;
			.TODO {
				background: url('images/v2/components/workflow-column/todo-category-icon-green.svg') no-repeat;
			}
			.INPROGRESS {
				background: url('images/v2/components/workflow-column/inprogress-category-icon-green.svg') no-repeat;
			}
			.DONE {
				background: url('images/v2/components/workflow-column/done-category-icon-green.svg') no-repeat;
			}
		}
	}
	.category-options-list-new {
		background-color: white;
		padding: 0;
		display: flex;

		.category-option {
			outline: none;
			margin-top: 0;
			padding: 6px;
			flex-grow: 1;
			width: 100%;
			font-size: 12px;
			font-weight: 500;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: $v2-text-gray;
			border: none;
			&.preselected,
			&:hover {
				color: $v2-text-gray;
				border: none;
				background-color: $v2-list-hover-purple;
			}
			.category-icon {
				margin-left: 16px;
				margin-top: 4px;
			}
		}
		.selected {
			font-size: 12px;
			font-weight: 500;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: $v2-branding-purple;
			&.preselected,
			&:hover {
				color: $v2-branding-purple;
			}
		}
	}
}
