.settings-departments {
	.department-group {
		margin-left: 24px;
		margin-right: 24px;
		margin-top: 24px;
		margin-bottom: 24px;
		width: 97%;

		border: 1px solid #e6e6e6;
		border-radius: 4px;
		padding-left: 24px;
		padding-right: 24px;
		padding-top: 12px;
		padding-bottom: 18px;

		.department-container {
			margin-bottom: 14px;
			&:last-of-type {
				margin-bottom: 4px;

				.department-title-bar {
					padding-bottom: 0px;
				}

				hr {
					display: none;
				}
			}
		}
	}

	.no-department-group-collapsed-spacer {
		margin-bottom: 32px;
	}

	.department-group-title {
		margin-left: 24px;
		display: flex;
		flex-direction: row;
		font-family: $text-font-family;
		font-size: 16px;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #535353;

		.department-group-header {
			font-weight: 500;
			margin-right: 4px;
		}

		.department-group-button {
			padding-left: 24px;
			height: 20px;
			width: 20px;
			border: none;
			cursor: pointer;

			&.expanded {
				background: url('images/v2/components/dropdown/arrow-up.svg') no-repeat center;
			}

			&.collapsed {
				background: url('images/v2/components/dropdown/arrow-down.svg') no-repeat center;
			}
		}
	}
}
