.board-card-details-selector {
	width: 30px;
	height: 30px;
	position: relative;
	display: flex;
	.expand-button {
		width: 30px;
		height: 30px;
		margin-left: 0;
		border: none;
		background: url('images/v2/components/ico-infoshow.svg') no-repeat;
		background-color: $color-white;
		outline: none;
		cursor: pointer;
		border: 1px solid $v2-button-very-light-grey;
		&.collapsed,
		&.expanded {
			background-size: 20px 13px;
			background-position: center;
		}
		&:hover {
			border-color: $v2-date-picker-button-border-color;
		}
		&.expanded {
			border-color: $v2-branding-purple;
		}
	}
}
.board-card-details-selector-list {
	margin: 0;
	padding: 0;
	background-color: $color-white;
	position: absolute;
	z-index: 999;
	list-style: none;
	font-size: 13px;
	text-transform: capitalize;
	color: $v2-text-gray;
	border-radius: 4px;
	border: 1px solid $v2-button-very-light-grey;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
	width: 213px;
	&.has-nested-elements {
		width: 243px;
		// second-nested-option is the second layer of nested elements
		li:not(.nested-li):not(.nested-option):not(.second-nested-option) {
			.visible-state-icon {
				margin-right: 26px;
			}
		}
	}

	&.open-right {
		left: 0;
		right: auto;
	}

	.board-card-details-selector-nested-list {
		position: absolute;
		width: 213px;
		padding: 0;
		// -2px to account for the 2 borders of the divs
		left: 241px;
		top: -1px; //compensate for the border of the parent option container
		font-size: 13px;
		color: $v2-text-gray;
		border-radius: 4px;
		border: 1px solid $v2-button-very-light-grey;
		background-color: $color-white;
		outline: none;
		cursor: pointer;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
		&.has-nested-elements {
			width: 243px;
			// second-nested-option is the second layer of nested elements
			li:not(.nested-li):not(.second-nested-option) {
				.visible-state-icon {
					margin-right: 26px;
				}
			}
		}
	}

	&.expand-left {
		.board-card-details-selector-nested-list {
			left: -213px;
			&.has-nested-elements {
				left: -243px;
			}
		}
	}

	li {
		padding: 5px 13px 5px 13px;
		display: flex;
		justify-content: space-between;
		position: relative;
		outline: none;

		&.disabled {
			color: #a1a1a1;
			cursor: default;
		}

		&:not(.disabled):hover,
		&:not(.disabled):focus {
			color: $v2-branding-purple;
			background-color: $bulk-update-selected-color;
			cursor: pointer;
		}

		&.nested-option {
			align-items: center;
		}

		.expand-nested-options-button {
			width: 16px;
			height: 12px;
			margin-left: 7px;
			border: none;
			background: url('images/v2/components/date-controls/arrow-right.svg') no-repeat center center;
		}
		.expand-nested-options-button-left {
			width: 16px;
			height: 22px;
			margin-right: 7px;
			border: none;
			background: url('images/v2/components/date-controls/arrow-left.svg') no-repeat center center;
		}
		.expand-nested-options-placeholder {
			min-width: 16px;
			height: 22px;
			margin-right: 7px;
			border: none;
		}
		.name-expand-wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
		}
	}

	.name {
		flex-grow: 1;
		max-width: 159px;
	}

	.visible-state-icon {
		height: 22px;
		width: 26px;
		background-image: url('images/v2/components/hide-details-eye-icon.svg');
		background-repeat: no-repeat;
		background-position: top 2px center;
		background-size: 21px;
		&.selected {
			background-position: top 4px center;
			background-image: url('images/v2/components/show-details-eye-icon.svg');
		}
	}
}
