.overview-projects-card-container {
	width: 350px;
	height: 350px;
	background-color: $color-white;
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-top: 25px;
	&.extra-padding {
		padding-top: 62px;
	}
	&.last-element {
		margin-right: 15px;
	}

	.header {
		display: flex;
		flex: 0 0 34px;
		height: 30px;
		width: 300px;
		justify-content: flex-start;
		align-items: center;
		overflow: hidden;
		text-overflow: ellipsis;
		margin: 16px 0 16px 0;
		text-overflow: ellipsis;
		&.single-project-group {
			justify-content: space-between;
			flex-wrap: nowrap;
			h2 {
				width: 80%;
				overflow: hidden;
			}
			.link-wrapper {
				margin-left: 10px;
			}
		}
		h2 {
			margin: 0;
			font-weight: 700;
			white-space: nowrap;
		}

		.header-link {
			color: $brand-color-accent;
		}
	}
	.link-wrapper {
		display: flex;
		justify-content: flex-end;
		min-width: 133px;
		font-size: 0.95em;
	}
	.cards-wrapper {
		display: flex;
		flex: 1 1 auto;
		flex-direction: row;
		flex-wrap: wrap;
	}
}
