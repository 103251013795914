.settings-people {
	&.section-content {
		margin: 0;
	}

	.section-body {
		padding: 0 24px;
	}

	.controls {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		.expand-control {
			width: 24px;
			height: 16px;
			background-size: 24px;
			background-repeat: no-repeat;
			background-position: center;
			cursor: pointer;
			&.expand {
				background-image: url('images/up-arrow.svg');
			}

			&.collapse {
				background-image: url('images/header/expanded.svg');
			}
		}
	}

	.manage-subscription-link {
		font-size: 13px;
	}

	.people {
		border: none;
		box-shadow: none;
		padding: 0;
		padding-left: 6px;
		@include settings-section-fieldset-v2;
		&.deactivated {
			.row-member {
				height: 40px;
			}

			.top-container {
				margin-right: 50px; // Move arrow to the left so the Hubspot chat icon is not blocking it
			}
		}
		.description {
			margin-bottom: 0;
			display: flex;
			align-items: center;
			.data-tile {
				margin-left: 4em;
				label {
					// text-transform: none;
					color: $color-black;
					font-size: 0.875em;
				}
			}
		}
		@include standard-table;
		table {
			tbody {
				tr {
					td {
						input[type='number'] {
							&:hover {
								border: 1px solid $v2-text-light-gray;
							}

							&:focus {
								border: 1px solid $v2-branding-purple;
							}
						}
					}
					td.cost-modal-link {
						cursor: pointer;
						padding-left: 15px;
						text-align: right;

						&:hover {
							color: $v2-branding-purple;
						}
					}
					td.person-skill-link {
						min-width: 80px;
						max-width: 80px;
						cursor: pointer;

						&:hover {
							color: $v2-branding-purple;
						}
					}
					td.action-links {
						min-width: 30px;
						max-width: 30px;
					}
					td.person-seat, td.person-status {
						min-width: 90px;
						max-width: 130px;
						white-space: nowrap;
					}
				}
			}

			.drop-down-v2 {
				&:hover .arrow {
					display: block;
				}
			}

			.person-width {
				min-width: 180px;
				max-width: 180px;
			}

			.seat-width,
			.status-width{
				min-width: 90px;
				max-width: 130px;
			}

			.actions-width {
				min-width: 30px;
				max-width: 30px;
			}

			.role-width,
			.department-width,
			.permissions-width {
				min-width: 170px;
				max-width: 170px;

				.drop-down-v2 {
					input {
						// Fixes overlap when role/permission name is too long
						padding-right: 26px;
					}
				}

				&.deactivated {
					padding-left: 16px;
				}

				&.role-width-header,
				&.department-width-header,
				&.permissions-width-header {
					padding-left: 16px;
				}
			}

			.cost-width {
				min-width: 104px;
				max-width: 104px;
				text-align: right;

				&.cost-width-header {
					padding-left: 16px;
				}

				input {
					&:focus,
					&:active {
						outline: 0;
					}
				}
			}
			.day-width {
				min-width: 65px;
				max-width: 65px;
				text-align: right;
				width: 6%;

				&.day-width-header {
					transform: translateX(-5px);

					span.working-hours-text {
						position: absolute;
						top: -5px;
						right: -114px;
						width: 150px;
						text-align: left;
						color: $v2-text-gray;
					}
				}
			}
			.date-width {
				min-width: 120px;
				max-width: 120px;
				&.date-width-header {
					padding-left: 29px;
				}
				.date-picker-button {
					&:not(:hover) {
						border: none;
					}
					&:hover {
						border: 1px solid $v2-button-very-light-grey-hovered;
					}
				}
			}

			.deactivated-controls-width {
				width: 47%;

				&.harvest {
					width: 55.2%;
				}
			}

			thead {
				th {
					color: $v2-text-light-gray;
					font-size: 9px;
					letter-spacing: 1px;
					font-weight: 400;
					text-transform: uppercase;
					cursor: default;
				}
			}

			tbody {
				tr {
					border-top: 1px solid $light-border-color;
					border-bottom: none;

					&:hover {
						td.person-action-menu {
							opacity: 1;
						}
					}

					td.person-action-menu {
						width: 30px;
						margin-left: 5px;
						opacity: 0;
					}

					td.created-at-cell {
						min-width: 150px;
					}

					td {
						color: $v2-text-gray;
						font-size: 13px;
						padding: 0 0.625rem 0 0.625rem;
						&.harvest-icon {
							img {
								cursor: pointer;
							}
						}

						.task-table-input-field {
							width: 116px;
						}

						.drop-down-v2 {
							//width: 150px;
							height: 30px;
							margin: 5px 0;

							input {
								// border: 1px solid $v2-button-very-light-grey-hovered;

								&.expanded {
									border-bottom-left-radius: 0;
									border-bottom-right-radius: 0;
								}

								&:hover {
									border: 1px solid $v2-button-very-light-grey-hovered;
								}
							}

							.arrow {
								&.label-hidden {
									top: -5px;
								}
							}
						}
					}
				}
			}
		}
		tbody {
			table-layout: fixed;
			word-wrap: break-word;
			tr {
				.controls {
					span {
						background-size: 16px;
						background-repeat: no-repeat;
						background-position: left center;
						padding: 0 10px 0 21px;
						cursor: pointer;
						&:hover {
							text-decoration: underline;
						}
						&.edit {
							background-image: url('images/cross-section-icons/actions/edit.svg');
						}
						&.remove {
							background-image: url('images/cross-section-icons/actions/remove.svg');
						}
						&.enable {
							background-image: url('images/cross-section-icons/actions/enable.svg');
						}
					}
				}
				.team-name {
					padding-top: 0;
					margin: 0;
					font-size: 14px;
				}
				.column-action-menu {
					width: 50px;
				}
				.column-expand-control {
					width: 50px;
					.expanded,
					.collapsed {
						cursor: pointer;
						margin-top: 4px;
						display: inline-block;
						height: 15px;
						width: 30px;
					}
					.expanded {
						background: url('images/up-arrow.svg') no-repeat center center;
					}
					.collapsed {
						background: url('images/header/expanded.svg') no-repeat center center;
					}
				}
				&.row-member {
					.integrations-cell {
						img {
							margin-right: 4px;
						}
					}

					td {
						cursor: default;
					}
					.controls span {
						visibility: hidden;
					}
					&:hover {
						background-color: #f3f3f3;

						td {
							.drop-down-v2 {
								input:not(.expanded) {
									background-color: #f3f3f3;
								}
							}
							.date-picker-button {
								background-color: #f3f3f3;
							}
						}
						.controls span {
							visibility: visible;
						}
						cursor: pointer;
					}
					.team {
						padding-left: 50px;
					}
					.person {
						overflow: hidden;
						.wrapper {
							display: flex;
							flex: 1 1 40px;
							flex-direction: row;
							justify-content: flex-start;
							overflow: hidden;
							align-items: center;
							.image-container {
								float: left;
								margin-bottom: 0px;
								margin-right: 6px;
								.profile-picture {
									margin: 0;
									position: relative;

									img {
										width: 26px;
										height: 26px;
									}
								}
								.deactivated {
									height: 36px;
									width: 36px;
									position: absolute;
									z-index: 10;
									border-radius: 50%;
									background: rgba(0, 0, 0, 0.75);
								}
							}
							.name-tile {
								h3 {
									padding-top: 0;
									margin: 0;
									font-size: 13px;
									color: $v2-text-gray;
									font-weight: 400;
								}
								p {
									margin-top: 5px;
									font-size: 11px;
								}
							}
						}
					}
					.gear-menu {
						position: relative;
						margin-left: auto;
						padding-left: 100px;
						width: 5%;
						&:focus {
							outline: none;
						}
						> span {
							cursor: pointer;
							margin-top: 4px;
							display: inline-block;
							height: 15px;
							width: 30px;
							background: url('images/sections/project/settings/navigation-show-more-horizontal.svg') no-repeat;
							background-position: center;
						}
						.gear-menu-drop-down {
							position: absolute;
							right: 50px;
							border: 1px solid $app-border-color;
							border-bottom-left-radius: 3px;
							border-bottom-right-radius: 3px;
							background-color: $color-white;
							width: 120px;
							z-index: 10;
							ul {
								padding: 0;
								margin: 0;
								list-style: none;
							}

							&.collapsed {
								display: none;
							}
							&.expanded {
								display: block;
							}
							.settings-drop-down {
								color: $color-black;
								.option {
									background-color: $color-white;
									cursor: pointer;
									padding: 10px;
									&:hover {
										color: $color-purple-hover-active;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.group-header {
		display: flex;
	}

	.seats-info {
		padding: 0px 16px 0px 16px;
		font-size: 13px;
	}

	.controls-new {
		padding-top: 4px;
		padding-right: 16px;
	}

	.group-container {
		border: 1px solid $light-border-color;
		border-radius: 4px;
		padding: 0px 16px 0px 16px;
	}

	.selection {
		padding-left: 16px !important;
	}

	.action-links {
		.action-link-container {
			display: flex;
			justify-content: flex-start;
			column-gap: 16px;
			row-gap: 4px;
			align-items: center;
			flex-wrap: wrap;
			.action-link {
				white-space: nowrap;
				display: flex;
				text-decoration: underline;
				cursor: pointer;
				color: $color-branding-purple-light;

			}
		}
	}

	.subscription-link {
		color: $color-branding-purple-light;
	}
}
