.saved-filter {
	height: 30px;
	border-radius: 15px;
	border: 1px solid $v2-text-gray;
	font-size: 11px;
	padding: 0 7px 0 12px;
	margin-right: 4px;
	margin-bottom: 4px;
	line-height: 28px;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	transition: border-color 0.2s;
	&.appliable {
		&:hover {
			border: 2px solid $v2-text-gray;
			line-height: 26px;
			padding: 0 6px 0 11px;
		}
	}
	&.selected {
		border: 2px solid $v2-branding-purple;
		line-height: 26px;
		padding: 0 11px;
		&:hover {
			border: 2px solid $v2-branding-purple;
			line-height: 26px;
			padding: 0 11px;
		}
	}
	.name-input {
		height: 24px;
		border: 1px solid $v2-text-gray;
		background-color: white;
		border-radius: 3px;
		outline: 0;
		padding: 0 8px;
	}
	.new-ui-actions-menu {
		margin-left: 7px;
	}
}
