.scheduling-timeline-wrapper {
	overflow: auto;
	z-index: 1;

	&.hide {
		display: none;
	}

	.scroll-outer {
		margin-top: 10px;
		width: 100%;
		order: 1;
		flex-shrink: 0;
		height: 10px;
		position: relative;
		.scroll-thumb {
			height: 100%;
			width: 50px;
			background-color: grey;
			position: absolute;
		}
	}

	.scheduling-container {
		display: flex;
		flex-direction: column;

		.vis-timeline {
			border: none;
			border-color: #eeeeee;
			border-width: 2px;
			//border-right-style: solid;
			//border-bottom-style: solid;
			border-radius: 5px;
		}

		.vis-timeline * {
			border-color: #eeeeee;
			border-width: 2px;
		}

		.vis-vertical.vis-background {
			.vis-time-axis {
				.vis-grid.vis-minor {
					border-width: 1px;
				}
			}
		}

		.vis-vertical {
			background-color: $color-white;
		}

		.vis-item.vis-dot {
			position: absolute;
			padding: 0;
			border-width: 4px;
			border-style: solid;
			border-radius: 4px;
		}

		.vis-item.vis-line {
			padding: 0;
			position: absolute;
			width: 0;
			border-left-width: 1px;
			border-left-style: solid;
		}

		&.week-start-monday {
			.vis-panel {
				&.vis-background {
					&.vis-vertical {
						.vis-monday {
							border-left: 2px solid #bbb;
						}
					}
				}
			}
		}
		&.week-start-sunday {
			.vis-panel {
				&.vis-background {
					&.vis-vertical {
						.vis-sunday {
							border-left: 2px solid #bbb;
						}
					}
				}
			}
		}

		.vis-panel {
			box-sizing: content-box;

			&.vis-right {
				box-shadow: -13px 0px 10px -4px rgba(0, 0, 0, 0.5);
				z-index: 2;
				overflow: hidden;
				width: 22px;
				top: 0 !important;
				height: 100% !important;
				background: #f3f2f2;
				border: none;
			}

			&.vis-left {
				box-shadow: 11px 0px 10px -4px rgba(0, 0, 0, 0.5);
				border-top-left-radius: 5px;
				z-index: 2;
			}

			&.vis-top {
				border-top-left-radius: 5px;
				border-style: none;
				border-left-style: solid;
				border-color: #eeeeee;
			}

			&.vis-background {
				&.vis-vertical {
					.vis-today {
						background-color: #dfd7f2;
						pointer-events: none;
					}

					.vis-saturday,
					.vis-sunday {
						background-color: #f5f5f5;
						pointer-events: none;
					}

					.vis-major {
						border-color: lighten($color-branding-purple, 60%);
					}
				}
			}
		}

		.vis-top {
			.vis-minor {
				border: none;
				border-right: 1px solid #eeeeee;
				height: 24px;
				font-size: 12px;
				vertical-align: middle;
				line-height: 17px;
				font-weight: bold;
				letter-spacing: 0.2px;
				color: #6a6a6a;
				overflow: visible;
				padding-left: 5px;
				&.vis-today {
					font-weight: bold;
					color: #000000;
				}
			}

			.vis-major {
				padding: 8px 0 0 12px;
				height: 42px;
				> div {
					text-transform: uppercase;
					height: 100%;
					font-size: 16px;
					font-weight: bold;
					letter-spacing: 0.2px;
					color: #000000;
					text-align: center;
					vertical-align: middle;
					line-height: 20px;
				}
			}
		}

		.vis-item {
			padding: 0;
			border: none;
			cursor: pointer;
			background: transparent;
			.vis-item-content {
				padding: 0;
				display: block;
			}

			.background-selection {
				border: none;
				cursor: default;
				border-radius: 2px;
				line-height: 60px;
				padding-left: 30px;
				padding-right: 30px;
				vertical-align: middle;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				font-size: 16px;
				font-weight: $base-font-medium-weight;

				letter-spacing: 0.2px;
			}

			.background-selection-empty {
				cursor: default;
				height: 6px;
			}

			&.overallocated-item {
				color: $color-white;
				background-color: red;
				cursor: default;

				.title {
					height: 100%;
					width: 100%;
					line-height: 24px;
					padding-left: 10px;
					padding-right: 10px;
					vertical-align: middle;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;

					font-size: 16px;
					font-weight: bold;
					letter-spacing: 0.2px;
					color: $color-white;
					.percent {
						font-weight: $base-font-medium-weight;
					}
				}

				.vis-drag-center {
					cursor: default;
				}
			}

			&.unavailable-item {
				color: $color-white;
				background-color: #b7b7b7;
				cursor: default;
				height: 58px;

				.vis-drag-center {
					cursor: default;
				}
			}

			&.selected-item {
				color: $color-white;
				background-color: #cdc9d8;
				border: dashed 1px $brand-color;
				cursor: default;
				height: 58px;

				.vis-drag-center {
					cursor: default;
				}
			}

			&.adding-item {
				background-color: rgba(255, 0, 0, 0.2);
			}

			&.booking {
				height: 30px;
				line-height: 30px;
				font-size: 14px;
				font-weight: normal;
				letter-spacing: 0.2px;
				.booking-inner {
					margin-right: 1px;
					margin-left: 1px;
					padding-left: 10px;
					padding-right: 10px;
					.title {
						color: $color-white;
					}
				}
				vertical-align: middle;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;

				.vis-drag-left {
					background-repeat: no-repeat;
					background-position: center;
					background-image: url('images/sections/overview/h_resizer.png');
				}
				.vis-drag-right {
					background-repeat: no-repeat;
					background-position: center;
					background-image: url('images/sections/overview/h_resizer.png');
				}

				&.uneditable {
					.vis-drag-right,
					.vis-drag-left {
						background-image: none;
					}
					.vis-drag-center {
						cursor: default;
					}
				}
			}

			&.allocated {
				height: 30px;
				font-size: 16px;
				font-weight: bold;
				letter-spacing: 0.2px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				.vis-drag-left {
					background-repeat: no-repeat;
					background-position: center;
					background-image: url('images/sections/overview/h_resizer.png');
				}
				.vis-drag-right {
					background-repeat: no-repeat;
					background-position: center;
					background-image: url('images/sections/overview/h_resizer.png');
				}
				&.uneditable {
					.vis-drag-right,
					.vis-drag-left {
						background-image: none;
					}
					.vis-drag-center {
						cursor: default;
					}
				}
				.allocated-inner {
					margin-right: 1px;
					margin-left: 1px;
					height: 30px;
					display: flex;
					align-items: center;
					padding-left: 10px;
					padding-right: 10px;
					.bug {
						flex: 0 0 25px;
						height: 25px;
						margin-right: 8px;
						background-image: url('images/cross-section-icons/actions/bug.svg');
					}

					.title {
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						color: $color-white;
					}

					.project-title {
						font-weight: normal;
					}

					.image-container {
						display: flex;
						align-items: center;
					}

					.warning {
						@include warning-icon;
						//position: absolute;
						//right: 6px;
						//top: 0px;
						//z-index: 4;
						//cursor: move;
						//flex: 1;
						//width: 20px;
						//height: 20px;
						//vertical-align: middle;
						//display: inline-block;
						//background-size: contain;
						//background-position: right center;
						//background-repeat: no-repeat;
						//background-image: url('images/cross-section-icons/warning.svg');
					}
				}
			}

			&.available {
				padding-left: 0;
				padding-right: 0;
				cursor: default;
				.vis-drag-center {
					cursor: default;
				}
				.available-inner {
					font-size: 12px;
					font-weight: $base-font-bold-weight;
					margin-right: 2px;
					margin-left: 0;
					line-height: 40px;
					padding-left: 10px;
					padding-right: 10px;
					cursor: default;
					vertical-align: middle;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					height: 40px;
				}
			}

			&.full {
				.available-inner {
					background-color: rgba(32, 173, 32, 0.9);
					cursor: default;
				}
			}

			&.problem {
				color: $color-white;
				font-size: 11px;
				font-weight: 400;
				letter-spacing: 0.2px;
				.vis-drag-center {
					cursor: default;
				}
				.available-inner {
					margin-bottom: 4px;
					padding: 2px 2px 2px 12px;
					border-radius: 7px;
					background-color: rgba(255, 32, 32, 0.8);
					cursor: default;
				}
			}

			&.selection-confirmation-item {
				background-color: rgba(57, 38, 100, 0.25);
			}
		}
	}
}
