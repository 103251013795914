/* Used on most flexbox parent elements */
@mixin flex-parent($direction) {
	width: 100%;
	height: 100vh;

	display: flex;
	flex-direction: $direction;

	justify-content: flex-start; /* align items in Main Axis */
	align-items: stretch; /* align items in Cross Axis */
	align-content: stretch; /* Extra space in Cross Axis */
}

/* make a flexbox element scrollable vertically.
Used for scrollable columns and main sections that
have a fixed sidebar menu */
@mixin scroll-y {
	overflow-y: auto;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	&::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
	&:before {
		-webkit-width: 1px;
		-webkit-float: left;
		-webkit-height: calc(100% + 1px);
		-webkit-margin-left: -1px;
	}
	&:after {
		-webkit-content: ””;
		-webkit-width: 100%;
		-webkit-clear: both;
	}
}

/* Create nice frame around element placed on main section background */
@mixin content-frame {
	background: $color-white;
	border: 1px solid $app-border-color;
	border-radius: 3px;
	box-shadow: 0 1px 2px 0px #e6e6e6;
}

/* Standard table formatting */
@mixin standard-table {
	table {
		table-layout: auto;
		width: 100%;
		font-weight: $base-font-regular-weight;
		font-size: 0.9rem;
		thead {
			border-bottom: 1px solid $app-border-color;
			text-align: left;
			th {
				font-weight: $base-font-bold-weight;
				font-size: 0.875rem;
				color: $color-dark-gray;
				padding: 0.625rem;
				text-align: left;
				.tooltip-left,
				.tooltip-right {
					span {
						color: $text-base-color;
					}
				}
				&:first-child {
					padding-left: 0;
				}
				&:last-child {
					padding-right: 0;
				}
				&.sortable,
				&.sortable-grey {
					cursor: pointer;
					span {
						margin-left: 0.5em;
					}
					&.ascending {
						span {
							background-image: url('images/cross-section-icons/table/sort-ascending.svg');
							background-size: 18px;
							background-position: top center;
						}
						color: $brand-color-accent;
					}
					&.descending {
						span {
							background-image: url('images/cross-section-icons/table/sort-descending.svg');
							background-size: 18px;
							background-position: bottom center;
						}
						color: $brand-color-accent;
					}
				}
				&.sortable {
					span {
						background: url('images/cross-section-icons/table/sort.svg') no-repeat center center;
						background-size: 12px;
					}
				}
				&.sortable-grey {
					span {
						background: url('images/cross-section-icons/table/sort-grey.svg') no-repeat center center;
						background-size: 12px;
					}
				}
				&:hover .sortable {
					color: $color-purple-hover-active;
					.tooltip-left,
					.tooltip-right {
						span {
							color: $text-base-color;
						}
					}
				}
			}
		}

		tbody {
			tr {
				border-bottom: 1px solid $light-border-color;
				letter-spacing: 0.5px;
				&:hover {
					color: $color-purple-hover-active;
				}
				&.selected {
					font-weight: 700;
					background-color: $light-hover-color;
				}

				td {
					padding: 0.625rem;
					&.clickable-column {
						cursor: pointer;
					}
					&:first-child {
						padding-left: 0;
					}
					&:last-child {
						padding-right: 0;
					}
					input[type='text'],
					input[type='date'],
					input[type='number'] {
						&:not(.error) {
							border: 1px solid transparent;
						}
						border-radius: 3px;
						.input-icon-collapsed {
							&:hover {
								input {
									border: 1px solid $color-purple-hover-active;
									margin: 0;
								}
							}
						}
						&:hover {
							border: 1px solid $color-purple-hover-active;
							margin: 0;
						}
					}
					input[type='text'],
					input[type='date'] {
						padding: 6px 5px;
						//border: 1px solid $app-border-color;
					}

					input[type='date'] {
						padding: 3px 3px 4px 3px;
					}
				}
			}
		}
		tfoot {
			background-color: $color-white;
			font-weight: $base-font-bold-weight;
			font-size: 0.875rem;
			tr {
				&:hover {
					color: $text-base-color;
				}

				td {
					padding: 0.3125rem 0.625rem;
				}
			}
		}
	}
}

@mixin branding-table {
	table {
		font-weight: $base-font-regular-weight;
		font-size: 0.9375rem; //0.875rem;
		letter-spacing: 0.3px;
		margin-bottom: 1em;
		border-collapse: separate;
		border-spacing: 0 2px;
		transition: all 0.2s ease;
		thead {
			tr {
				th {
					text-align: left;
					font-weight: $base-font-bold-weight;
					font-size: 0.875rem; //0.8125rem;
					user-select: none;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					&:first-child {
						padding-left: 10px;
					}
					padding: 0 10px 20px 10px;
					&.sortable {
						cursor: pointer;
						span {
							margin-left: 0.5em;
							background-size: 12px;
						}
						&.ascending {
							span {
								background-image: url('images/cross-section-icons/table/sort-ascending.svg');
								background-size: 18px;
								background-position: top center;
							}
							color: $brand-color-accent;
						}
						&.descending {
							span {
								background-image: url('images/cross-section-icons/table/sort-descending.svg');
								background-size: 18px;
								background-position: bottom center;
							}
							color: $brand-color-accent;
						}
					}
					&:hover {
						color: $color-purple-hover-active;
					}
				}
			}
		}
		tbody {
			position: relative;
			tr {
				height: 51px;
				box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
				border-radius: 5px;
				transition: transform 0.25s;
				background-color: $color-white;
				cursor: pointer;
				&:hover {
					transition: transform 0.25s;
					background-color: $color-white;
					-webkit-transform: scale(1.009);
					-ms-transform: scale(1.009);
					transform: scale(1.009);
					color: $color-purple-hover-active;
				}
				td {
					padding: 0 10px 0 10px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					max-width: 250px;
					&:first-child {
						padding-left: 10px;
						border-top-left-radius: 5px;
						border-bottom-left-radius: 5px;
						border-right: 0;
					}
					&:last-child {
						border-top-right-radius: 5px;
						border-bottom-right-radius: 5px;
						border-left: 0;
					}
					input {
						border: 1px solid transparent;
						&:hover {
							border: 1px solid $app-border-color;
							margin: 0;
							border-radius: 3px;
						}
					}
					input[type='text'],
					input[type='date'] {
						padding: 6px 5px;
						border-radius: 3px;
						border: 1px solid $app-border-color;
					}

					input[type='date'] {
						padding: 3px 3px 4px 3px;
					}
				}
			}
		}
		tfoot {
			tr {
				td {
				}
			}
		}
	}
}

@mixin branding-table-first-child {
	tbody {
		tr {
			td {
				&:first-child {
					min-width: 74px;
					text-align: center;
					position: relative;
					overflow: hidden;
					color: $color-white;
					&::after {
						height: 150%;
						width: 100%;
						-webkit-transform: rotate(30deg);
						-moz-transform: rotate(30deg);
						-ms-transform: rotate(30deg);
						transform: rotate(30deg);
						content: '';
						position: absolute;
						left: -40%;
						top: 34px;
						background-color: $color-white;
					}
				}
			}
		}
	}
}

//DO NOT USE DIRECTLY!!! Used as base for all buttons
@mixin master-button {
	height: 2.25rem;
	border: none;
	font-weight: $base-font-bold-weight;
	border-radius: 2px;
	font-size: 0.9rem;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: 0.6px;
	padding: 0.5rem 1rem 0.5rem 1rem;
	-webkit-user-select: none;
	user-select: none;
	outline: none;
	text-transform: uppercase;
	text-align: center;
	white-space: nowrap;
	color: $color-white;
	background: $brand-color;
	cursor: pointer;
	transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
	&:hover,
	&:focus {
		background: $brand-color-dark;
		box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2);
	}
	&:active {
		transform: translateY(1px);
		background: $brand-color-light;
	}
	&:focus {
		outline: 0;
	}
	&:disabled {
		background-color: $brand-color-disabled;
		color: lighten($color-black, 75%);
		cursor: default;
		&:hover,
		&:focus {
			cursor: default;
		}
	}
}

@mixin primary-button {
	@include master-button;
	&:disabled {
		box-shadow: none;
	}
}

@mixin secondary-button {
	@include master-button;
	background: transparent;
	box-shadow: none;
	color: $text-base-color;
	&:hover,
	&:focus {
		box-shadow: none;
		background: $btn-second-active;
	}
	&:active {
		transform: none;
		background: $btn-second-pressed;
	}
	&:disabled {
		background: transparent;
		color: lighten($color-black, 75%);
	}
}

@mixin julie-green-button {
	border: 0;
	background-color: $color-martin-tertiary-green;
	color: white;
	font-weight: 500;
	font-size: 1rem;
	border-radius: 3px;
	padding: 12px 24px;
	outline: 0;
	letter-spacing: 0.6px;
	&:hover,
	&:focus {
		box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2);
	}
	&:active {
		background: lighten($color-martin-tertiary-green, 5%);
	}
}

@mixin delete-button {
	@include master-button;
	border: 0;
	background-color: $delete-button-red;
	color: white;
	outline: 0;
	&:hover,
	&:focus {
		background: lighten($delete-button-red, 5%);
		box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2);
	}
	&:active {
		background: lighten(#ff4e5a, 5%);
	}
}

@mixin blue-button {
	border: 0;
	background-color: $color-martin-primary-blue;
	color: white;
	font-weight: 500;
	font-size: 1.3rem;
	padding: 12px 16px;
	outline: 0;
	transition: all 0.2s ease;
	&:disabled {
		opacity: 0.5;
	}
	&:hover,
	&:focus {
		background-color: #392664;
	}
	&:active {
		background: lighten($color-martin-primary-blue, 5%);
	}
}

@mixin white-button {
	@include master-button;
	background: white;
	color: $text-base-color;
	font-weight: 500;
	&:hover,
	&:focus {
		background: $btn-white-active;
	}
	&:active {
		transform: none;
		background: $btn-white-pressed;
	}
	&:disabled {
		background: transparent;
		color: lighten($color-black, 75%);
	}
}

@mixin generic-text-input {
	margin: 0 10px 0 0;
	padding: 9px 10px;
	font-weight: $base-font-medium-weight;
	border: 1px solid $app-border-color;
	border-radius: 3px;
	&:hover {
		border-color: $color-purple-hover-active;
	}
}

/* Button groups */
@mixin button-group {
	.button {
		padding: 7px 10px 2px 10px;
		font-size: 13px;
		font-weight: $base-font-medium-weight;
		color: $color-dark-gray;
		text-decoration: none;
		border-top: 1px solid $app-border-color;
		border-bottom: 1px solid $app-border-color;
		border-left: 1px solid $app-border-color;

		&:first-child {
			border-radius: 3px 0 0 3px;
		}

		&:last-child {
			border-right: 1px solid $app-border-color;
			border-radius: 0 3px 3px 0;
		}
		&.active {
			background-color: $color-light-gray;
		}
		&:hover {
			background-color: $color-medium-gray;
		}
		&.has_icon {
			background-size: 16px;
			background-repeat: no-repeat;
			background-position: 10px 7px;
			padding-left: 31px;
		}
	}
}

//Used as base for all tabs
@mixin master-tab {
	.tab {
		font-size: 0.9rem;
		font-weight: $base-font-medium-weight;
		height: 2.6rem;
		padding: 0 0.75rem;
		min-width: 5rem;
		border: none;
		vertical-align: middle;
		text-transform: uppercase;
		text-align: center;
		background-color: transparent;
		border-bottom: 2px solid transparent;
		color: $color-branding-purple;
		transition: all 0.2s ease;
		border-radius: 0;
		//transition: all 0.2s cubic-bezier(.25, .8, .25, 1);
		&:focus {
			outline: 0;
		}
		&.attention {
			color: $alert-color;
		}
		&.selected {
			color: $brand-color-accent;
			border-bottom: 2px solid $brand-color-accent;
			&.attention {
				border-bottom: 2px solid $alert-color;
				color: $alert-color;
				&:hover {
					color: $color-purple-hover-active;
					border-bottom: 2px solid $color-purple-hover-active;
				}
			}
		}
		&:hover {
			color: $color-purple-hover-active;
			border-bottom: 2px solid $color-purple-hover-active;
		}
	}
}

@mixin list-card-switcher {
	.view-list {
		background-image: url('images/action-icons/view-list.svg');
		&:hover {
			background-image: url('images/action-icons/view-list-active.svg');
		}
	}
	.view-cards {
		background-image: url('images/action-icons/view-cards.svg');
		&:hover {
			background-image: url('images/action-icons/view-cards-active.svg');
		}
	}
}

@mixin file-format-icons {
	&.jpeg {
		background-image: url('images/cross-section-icons/file/jpg.svg');
	}
	&.jpg {
		background-image: url('images/cross-section-icons/file/jpg.svg');
	}
	&.ai {
		background-image: url('images/cross-section-icons/file/ai.svg');
	}
	&.avi {
		background-image: url('images/cross-section-icons/file/avi.svg');
	}
	&.doc {
		background-image: url('images/cross-section-icons/file/doc.svg');
	}
	&.eps {
		background-image: url('images/cross-section-icons/file/eps.svg');
	}
	&.gif {
		background-image: url('images/cross-section-icons/file/gif.svg');
	}
	&.indd {
		background-image: url('images/cross-section-icons/file/indd.svg');
	}
	&.m4v {
		background-image: url('images/cross-section-icons/file/m4v.svg');
	}
	&.mov {
		background-image: url('images/cross-section-icons/file/mov.svg');
	}
	&.mp4 {
		background-image: url('images/cross-section-icons/file/mp4.svg');
	}
	&.mpg {
		background-image: url('images/cross-section-icons/file/mpg.svg');
	}
	&.pdf {
		background-image: url('images/cross-section-icons/file/pdf.svg');
	}
	&.png {
		background-image: url('images/cross-section-icons/file/png.svg');
	}
	&.ppt {
		background-image: url('images/cross-section-icons/file/ppt.svg');
	}
	&.psd {
		background-image: url('images/cross-section-icons/file/psd.svg');
	}
	&.rar {
		background-image: url('images/cross-section-icons/file/rar.svg');
	}
	&.rtf {
		background-image: url('images/cross-section-icons/file/rtf.svg');
	}
	&.svg {
		background-image: url('images/cross-section-icons/file/svg.svg');
	}
	&.txt {
		background-image: url('images/cross-section-icons/file/txt.svg');
	}
	&.xls {
		background-image: url('images/cross-section-icons/file/xls.svg');
	}
	&.zip {
		background-image: url('images/cross-section-icons/file/zip.svg');
	}
	&.file {
		background-image: url('images/cross-section-icons/file/file.svg');
	}
	&.folder {
		background-image: url('images/cross-section-icons/file/folder.svg');
	}
}

@mixin input-field {
	padding: 5px;
	border: 1px solid transparent;
	border-radius: 3px;
	&:hover {
		border-color: $color-purple-hover-active;
		transition: border-color 150ms;
	}
	&:focus {
		-webkit-animation: input-outline-glow 0.5s ease-out 75ms;
		-moz-animation: input-outline-glow 0.5s ease-out 75ms;
		animation: input-outline-glow 0.5s ease-out 75ms;
		box-shadow: inset 0 1px #e1e2e4;
		-moz-box-shadow: inset 0 1px #e1e2e4;
		-webkit-box-shadow: inset 0 1px #e1e2e4;
		outline: none;
		border-color: #a1a4aa;
	}
}

//display grey div over person profile picture if this person is disabled
@mixin disabled-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 5px;
	z-index: 1;
	background: rgba(0, 0, 0, 0.65);
}

@mixin actions-dropdown-right {
	position: relative;
	width: 120px;
	top: 25px;
	right: 20px;
	z-index: 999;
	border: 1px solid $app-border-color;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
	background-color: $color-white;
	ul {
		padding: 0;
		margin: 0;
		list-style: none;

		&:focus {
			z-index: 999;
		}

		li {
			background-color: $color-white;
			cursor: pointer;
			padding: 10px;
			text-align: left;
			&:hover {
				color: $color-purple-hover-active;
			}
		}
	}

	&.collapsed {
		display: none;
	}
	&.expanded {
		display: block;
	}
}

@mixin actions-dropdown {
	position: relative;
	width: 180px;
	top: 25px;
	left: -160px;
	z-index: 999;
	border: 1px solid $app-border-color;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
	background-color: $color-white;
	color: $color-black;
	ul {
		padding: 0;
		margin: 0;
		list-style: none;

		&:focus {
			z-index: 999;
		}

		li {
			background-color: $color-white;
			cursor: pointer;
			padding: 10px;
			text-align: left;
			&:hover {
				color: $color-purple-hover-active;
			}
		}
	}

	&.collapsed {
		display: none;
	}
	&.expanded {
		display: block;
	}
}

@mixin gear-menu {
	.gear-menu {
		position: relative;
		margin-left: auto;
		&:focus {
			outline: none;
		}
		> span {
			cursor: pointer;
			display: inline-block;
			height: 30px;
			width: 30px;
			background: url('images/components/overlay/navigation-show-more-vertical.svg') no-repeat;
		}
		.gear-menu-drop-down {
			background-color: $color-white;
			position: absolute;
			top: 25px;
			left: -100px;
			border: 1px solid $app-border-color;
			border-bottom-left-radius: 3px;
			border-bottom-right-radius: 3px;
			width: 120px;
			z-index: 9999;
			ul {
				padding: 0;
				margin: 0;
				list-style: none;
			}

			&.collapsed {
				display: none;
			}
			&.expanded {
				display: block;
			}
			.settings-drop-down {
				color: $color-black;
				.option {
					background-color: $color-white;
					cursor: pointer;
					padding: 10px;
					&:hover {
						color: $color-purple-hover-active;
					}
					&.disabled {
						color: $input-placeholder-color-disabled;
						cursor: help;
					}
				}
			}
		}
	}
}

@mixin download-icon {
	cursor: pointer;
	height: 24px;
	width: 24px;
	margin-left: 10px;
	background: url('images/action-icons/download.svg') no-repeat;
	background-size: contain;
	&:hover {
		background-image: url('images/action-icons/download-active.svg');
	}
}

@mixin completion-bar {
	height: 8px;
	border-radius: 4px;
	width: 100%;
	position: relative;
	.progress-indicator {
		position: absolute;
		top: -1px;
		height: 8px;
		border-radius: 2px;
	}
}

@mixin warning-icon {
	flex-shrink: 0;
	font-size: 14px;
	color: white;
	width: 16px;
	height: 16px;
	line-height: 16px;
	border-radius: 50%;
	margin-left: 4px;
	text-align: center;
	&.red {
		background-color: red;
	}
	&.yellow {
		background-color: #ffd81e;
	}
}

@mixin password-bubble {
	.bubble {
		position: absolute;

		width: 300px;
		text-align: left;
		visibility: hidden;
		font-size: 12px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.75;
		letter-spacing: normal;
		background-color: #fff;
		border-radius: 5px;
		border: 1px solid $color-martin-tertiary-pink;
		display: block;
		padding: 14px 16px;
		margin: 1em (6px + 12px);
		vertical-align: top;
		.password-help-text {
			.title {
				font-size: 12px;
				font-weight: bold;
				line-height: normal;
				color: #36235c;
				margin-bottom: 9px;
			}
			.line {
				display: flex;
				flex-direction: row;
				align-items: center;
				// margin: 4px 0 4px 0;
				&.valid {
					color: $color-martin-tertiary-green;
				}
			}
			.check,
			.cross {
				width: 12px;
				height: 12px;
				flex: 0 0 12px;
				margin-right: 4px;
			}
			.check {
				background-image: url('images/sections/profile/security/check.svg');
			}
			.cross {
				background-image: url('images/sections/profile/security/error.svg');
			}
		}
		&.show {
			visibility: visible;
		}
		&.valid {
			border-color: $color-martin-tertiary-green;
			&:before {
				border-color: transparent $color-martin-tertiary-green !important;
			}
		}
		&:before {
			content: '';
			position: absolute;
			border-style: solid;
			/* reduce the damage in FF3.0 */
			display: block;
			width: 0;
		}
		&:after {
			content: '';
			position: absolute;
			border-style: solid;
			/* reduce the damage in FF3.0 */
			display: block;
			width: 0;
		}
	}
	.bubble.left {
		&:before {
			top: calc(50% - 10px); /* controls vertical position */
			bottom: auto;
			left: -12px; /* value = - border-left-width - border-right-width */
			border-width: 10px 12px 10px 0;
			border-color: transparent $color-martin-tertiary-pink;
		}
		&:after {
			top: calc(50% - 9px); /* value = (:before top) + (:before border-top) - (:after border-top) */
			bottom: auto;
			left: -11px; /* value = - border-left-width - border-right-width */
			border-width: 9px 11px 9px 0;
			border-color: transparent #fff;
		}
	}

	.bubble.right {
		&:before {
			top: calc(50% - 10px); /* controls vertical position */
			bottom: auto;
			left: auto;
			right: -12px; /* value = - border-left-width - border-right-width */
			border-width: 10px 0 10px 12px;
			border-color: transparent $color-martin-tertiary-pink;
		}
		&:after {
			top: calc(50% - 9px); /* value = (:before top) + (:before border-top) - (:after border-top) */
			bottom: auto;
			left: auto;
			right: -11px; /* value = - border-left-width - border-right-width */
			border-width: 9px 0 9px 11px;
			border-color: transparent #fff;
		}
	}
}

@mixin select-list {
	max-width: 240px;
	border: 1px solid $app-border-color;
	position: absolute;
	z-index: 99;
	left: 32px;
	top: 35px;
	overflow: auto;
	width: 250px;
	background-color: $color-white;
	padding: 16px 0;
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	max-height: 400px;
	overflow: hidden;
	padding: 10px;
	//font-size: 15px;
	//box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
	transition: transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
	transform: scaleY(1);
	transform-origin: left top 0px;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	.option {
		text-decoration: none;
		&.has-border {
			border-bottom: 1px solid $app-border-color;
		}
		&.add-padding {
			padding: 15px 0;
		}
		cursor: pointer;
		&:hover {
			color: $color-purple-hover-active;
		}
		&.locked-option {
			span {
				color: $v2-text-light-gray;
			}
			display: flex;
			flex-direction: row;
			align-items: center;
			&:hover {
				color: none;
			}
		}
	}
}

@mixin close-button {
	width: 14px;
	height: 14px;
	border: none;
	margin: 10px 0 0 10px;
	background-color: transparent;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url('images/components/overlay/close.svg');
	outline: none;
}

@mixin expand-control {
	width: 24px;
	height: 24px;
	cursor: pointer;
	transition: 0.5s;
	margin-right: 1.5em;
	margin-left: 1.5em;
	&.expanded {
		background: url('images/up-arrow.svg') no-repeat center center;
	}

	&.collapsed {
		background: url('images/header/expanded.svg') no-repeat center center;
	}
}

@mixin context-menu {
	position: fixed;
	background-color: $color-white;
	border: solid 1px $v2-grey;
	list-style: none;
	padding: 0;
	z-index: 999;
	/* this CS forms the triangle arrow on top of select list
	 * start
	 */
	&.has-arrow:after,
	&.has-arrow:before {
		bottom: 100%;
		left: 90%;
		border: solid $color-white;
		content: ' ';
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	&.has-arrow:after {
		border-color: rgba(136, 183, 213, 0);
		border-bottom-color: $color-white;
		border-width: 8px;
		margin-left: -8px;
	}
	&.has-arrow:before {
		border-color: rgba(194, 225, 245, 0);
		border-bottom-color: $v2-grey;
		border-width: 9px;
		margin-left: -9px;
	}
	/*
	 * end
	 */
	.context-menu-option {
		display: flex;
		padding: 10px 50px 10px 14px;
		cursor: pointer;
		outline: none;
		&.download-option {
			padding: 0;
		}
		.file-download {
			text-decoration: none;
			display: flex;
			width: 100%;
			padding: 10px 50px 10px 14px;
		}
		&:hover,
		&:focus {
			background-color: $v2-light-gray;
		}
		.option-icon {
			width: 18px;
			height: 18px;
			background-size: contain;
			background-repeat: no-repeat;
			&.download,
			&.open {
				background-image: url('images/v2/components/project-files/download-icon.svg');
			}
			&.rename {
				background-image: url('images/v2/components/project-files/rename-icon.svg');
			}
			&.moveToCard {
				background-image: url('images/v2/components/project-files/move-file-to-card-icon.svg');
			}
			&.delete,
			&.unlink {
				background-image: url('images/v2/components/project-files/trash-icon.svg');
			}
			&.attach {
				background-image: url('images/v2/components/project-files/attach-icon.svg');
			}
			&.g-drive {
				background-image: url('images/integrations/gdrive-logo.png');
			}
			&.folder {
				background-image: url('images/v2/components/project-files/folder-icon.svg');
			}
		}
		a {
			outline: none;
		}
		span {
			margin-left: 30px;
		}
		label {
			padding: 10px 50px 10px 14px;
		}
		span,
		label {
			font-size: 14px;
			color: #535353;
		}
		&.add-file-option {
			padding: 0;
		}
	}
}

@mixin help-center-icon {
	width: 22px;
	height: 22px;
	background-image: url('images/components/scheduling/help-center-icon.svg');
	background-size: 22px;
}

@mixin section-header {
	display: flex;
	flex: 0 0 34px;
	flex-direction: row;
	background-color: $v2-branding-purple;

	.section-navigation {
		display: flex;
		flex: 1 0 34px;
		flex-direction: row;
		flex-wrap: no-wrap;
		align-items: center;
		padding-left: 24px;
		ul {
			display: flex;
			list-style: none;
			padding: 0;
			margin: 0;
			height: 100%;
			li {
				text-decoration: none;
				font-size: 12px;
				letter-spacing: 0.5px;
			}
			.actions {
				margin-top: 2px;
			}
		}
		&.old {
			padding-left: 32px;
			ul {
				li {
					padding: 0 13px;
				}
			}
		}
		a,
		.fake-link {
			display: flex;
			flex: 0 0 auto;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-decoration: none;
			height: 100%;
			vertical-align: middle;
			cursor: pointer;
			padding: 0;
			position: relative;
			color: $color-white;
			//min-width: 60px;

			&.medium {
				min-width: 95px;
			}
			&.long {
				min-width: 119px;
			}
			span {
				white-space: nowrap;
				&::after {
					display: block;
					content: attr(title);
					font-weight: bold;
					height: 0;
					overflow: hidden;
					visibility: hidden;
				}
			}
			&.active,
			&:hover {
				span {
					font-weight: 700;
				}
				.active-indicator {
					position: absolute;
					top: 31px;
					left: 0;
					width: 100%;
					display: flex;
					flex-direction: column;
					align-items: center;
					.border {
						width: 100%;
						height: 3px;
						background-color: $color-white;
					}
				}
			}
			&.disabled {
				&.active,
				&:hover {
					span {
						font-weight: 400;
					}
				}
			}
		}
	}
}
@mixin project-indicator {
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 42px;
	max-width: 50px;
	overflow: hidden;
	height: 20px;
	border-radius: 6px;
	margin-right: 8px;
	width: auto;
	font-weight: 500;
	font-size: 10px;
	&.border {
		border: 1px solid $app-border-color;
	}
}

@mixin pop-out-v2 {
	position: absolute;
	top: 34px;
	right: 0px;
	z-index: 999;
	border: 1px solid $v2-grey;
	border-radius: 4px;
	background-color: $color-white;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
}

// should also be used in time and scoping because tables used there are very similar but each has its own styling. Fix that if there is time to update these pages
@mixin standard-table-v2 {
	table {
		width: 100%;
		td,
		th {
			padding-left: 7px;
		}
		tr th:first-child,
		tr td:first-child {
			padding-left: 16px;
		}
		thead {
			tr {
				th {
					text-align: left;
					font-size: 9px;
					font-weight: 500;
					font-style: normal;
					font-stretch: normal;
					text-transform: uppercase;
					line-height: 1.22;
					letter-spacing: 1px;
					color: $v2-text-light-gray;
					padding-top: 7px;
					padding-bottom: 8px;
					&.ascending,
					&.descending {
						color: $v2-branding-purple;
					}
					&.numeric {
						text-align: right;
					}
					&.sortable {
						cursor: pointer;
						span {
							margin-left: 0.5em;
							background-size: 14px;
						}
						&.ascending {
							span {
								background-image: url('images/v2/sort-ascending.svg');
								background-position: top center;
							}
							color: $v2-branding-purple;
						}
						&.descending {
							span {
								background-image: url('images/v2/sort-descending.svg');
								background-position: bottom center;
							}
							color: $v2-branding-purple;
						}
					}
					&:hover {
						color: $v2-branding-purple;
					}
				}
			}
		}
		tbody {
			tr td:first-child {
				border-left: 2px solid $app-border-color;
			}
			tr:first-child {
				td {
					border-top: 1px solid $app-border-color;
					&.last-cell {
						border-top: none;
					}
				}
			}
			tr {
				max-height: 40px;
				&.favoured {
					background: $favoured-task-yellow;
					.last-cell {
						background: $favoured-task-yellow;
					}
				}
				&:hover,
				&:focus {
					background: $drop-color;
					.last-cell {
						background: $drop-color;
					}
					td {
						border-color: $v2-row-highlight-color;
					}
				}

				td {
					overflow: hidden;
					text-overflow: ellipsis;
					font-size: 13px;
					font-weight: normal;
					font-style: normal;
					font-stretch: normal;
					line-height: normal;
					letter-spacing: normal;
					white-space: nowrap;
					height: 40px;
					border-bottom: solid 1px $app-border-color;
					max-height: 40px;
					&.numeric {
						a {
							text-align: right;
						}
					}
					a {
						.apply-max-width {
							max-width: 400px;
							overflow: hidden;
							text-overflow: ellipsis;
						}
						color: $v2-text-gray;
					}
					.labels-wrapper {
						flex-wrap: nowrap;
						height: auto;
					}
					.warning-name-wrapper {
						display: flex;
						.warning-icon {
							margin-right: 2px;
							width: 15px;
							min-width: 15px;
							max-width: 15px;
							height: 15px;
							background-size: 12px;
							background-position: center;
							background-repeat: no-repeat;
						}
					}
				}
				.meta {
					span {
						background-position: left center;
						background-size: 1.2em;
						background-repeat: no-repeat;
						padding: 5px 0 5px 26px;
					}
					.files {
						margin-left: 1em;
						background-image: url('images/components/board-card-list/file.svg');
					}
					.comments {
						background-image: url('images/components/board-card-list/comment.svg');
					}
				}
				.assigned-table-cell {
					max-height: 65px;
					min-width: 250px;
					display: flex;
					flex: 1;
					flex-direction: row;
					flex-wrap: wrap;
					.forecast-person {
						margin: 0 5px 5px 0;
					}
				}
				.last-cell {
					background: white;
					width: 18px;
					padding-left: 0;
					.chip-container {
						height: 100%;
						float: right;
						overflow: hidden;
						position: relative;
						.chip-div {
							width: 18px;
							height: 100%;
							border-right: solid 1px $v2-table-border-color;
						}
						.chip {
							border-bottom: solid 1px $v2-table-border-color;
							content: '';
							position: absolute;
							height: 20px;
							width: 22px;
							display: block;
							top: -13px;
							right: -6px;
							background-color: white;
							transform: rotate(30deg);
							&.has-phase {
								background-color: $color-medium-gray;
							}
						}
					}
				}
			}
		}
	}
}
@mixin settings-section-fieldset-v2 {
	border: none;
	box-shadow: none;
	padding: 0;
	padding-left: 6px;
	.top-container {
		@include data-tile;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;

		.info-component-icon-wrapper {
			margin: 0 5px 0 7px;
		}

		&.collapsed {
			border-bottom: 1px solid #eee;
		}

		.add-button {
			@include primary-button;
			width: auto;
		}
		h2 {
			font-size: 16px;
			color: $v2-text-gray;
			font-family: inherit;

			span {
				font-weight: 400;
			}
		}
		h3 {
			margin-bottom: 0;
		}
		.controls {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			.expand-control {
				width: 24px;
				height: 16px;
				background-size: 24px;
				background-repeat: no-repeat;
				background-position: center;
				cursor: pointer;
				&.expand {
					background-image: url('images/header/expanded.svg');
				}
				&.collapse {
					background-image: url('images/up-arrow.svg');
				}
			}
		}
	}
}

@mixin back-button {
	width: 26px;
	height: 26px;
	border: none;
	background-size: 18px;
	background-color: $color-white;
	background-repeat: no-repeat;
	background-position: center;
	background-image: url('images/v2/back.svg');
	padding: 0;
	margin: 0;
	border-radius: 50%;
	&:hover {
		background-color: rgba(0, 0, 0, 0.15);
	}
}

@import 'v2/components/scrollable-table.scss';
