.warning-radio-button-modal,
.delete-many-tasks-modal {
	width: 584px; //to make it 700px
	margin-bottom: 32px;
	color: $v2-text-gray;
	.warning-message-component {
		display: flex;
		flex-direction: column;
		.message {
			padding-right: 17px;
		}
	}
	.warning-part-2 {
		margin-top: 16px;
		font-size: 14px;
		color: $v2-text-gray;

		.info-text {
			margin-bottom: 24px;
		}
		.radio-row {
			display: flex;
			margin-bottom: 16px;
			font-size: 13px;
			.radio-button-wrapper {
				margin-right: 16px;
			}
			.radio-option-label {
				margin-top: 2px;
				&.disabled {
					color: $v2-text-light-gray;
				}
			}
		}
	}
	.warning-text-important {
		margin-top: 8px;
		color: $v2-workflow-task-warning-red;
		font-size: 12px;
	}
	.tasks-list {
		//margin-left: 36px;
		//display: flex;
		//flex-direction: column;
		display: grid;
		grid-template-columns: 1fr;
		font-size: 13px;
		margin-bottom: 24px;
		width: 584px;
		padding-right: 9px;
		.task-element {
			height: 40px;
			border-bottom: solid 1px $app_border_color;
			padding-left: 24px;
			padding-top: 12px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 584px;
			&.task-id {
				padding-left: 0;
			}
		}
	}
	.tasks-title {
		font-size: 9px;
		font-weight: 500;
		line-height: 1.22;
		letter-spacing: 1px;
		color: $v2-text-light-gray;
		text-transform: uppercase;
		margin-bottom: 5px;
		font-family: $v2-site-header-font-family;
	}
}
