@import 'components';
@import 'empty_states/insights_empty_state';

.insights-section {
	overflow: hidden;
	.area {
		fill: #4ca3bd;
	}

	.header-bar {
		min-height: 68px;
	}

	.section-main {
		overflow: hidden;
		.dashboard {
			@include branding-table;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			padding: 1.6875rem 2rem 0px 2rem;
			width: 100%;
			&.useNewNavigation {
				padding: 0;
				.cards-container,
				.table-container {
					padding: 1.6875rem 2rem 0px 2rem;
				}
			}
			.menu {
				flex: 0 0 auto;
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				.categories {
					@include master-tab;
				}
				.forecast-actions {
					@include list-card-switcher;
				}
			}
			.cards-container {
				@include scroll-y;
				.cards {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					justify-content: flex-start;
					padding: 2px 0 2px 0;
					.saved-report-card {
						width: 300px;
						height: 169px;
						background-color: white;
						border-radius: 5px;
						margin-bottom: 15px;
						box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
						cursor: pointer;
						margin-left: 10px;
						overflow: hidden;
						margin-right: 10px;
						position: relative;
						&:hover {
							transition: transform 0.25s;
							-webkit-transform: scale(1.02);
							-ms-transform: scale(1.02);
							transform: scale(1.02);
						}
						.actions {
							flex: 0 0 24px;
							width: 24px;
							height: 24px;
							top: 27px;
							right: 22px;
							z-index: 10;
							border: none;
							background-color: transparent;
							background-image: url('images/components/overlay/navigation-show-more-vertical-white.svg');
							background-position: center;
							background-repeat: no-repeat;
							position: absolute;
							&:focus {
								z-index: 999;
								outline: 0;
							}

							.actions-drop-down {
								@include actions-dropdown;
							}
						}
						.card-data {
							padding: 0px 22px 15px 30px;
							position: relative;
							margin-top: -250px;
							display: flex;
							flex-direction: column;
							height: 250px;
							.header {
								display: flex;
								align-items: top;
								.title {
									flex: 1;
									font-size: 24px;
									font-weight: bold;
									color: white;
									max-width: 230px;
									overflow: hidden;
									text-overflow: ellipsis;
									margin-bottom: 4px;
									margin-top: 20px;
									border: 0;
									resize: none;
								}
								.title:hover {
									cursor: pointer;
								}
							}
							.category-name {
								color: white;
							}
						}
					}
					.card {
						transition: transform 0.25s;
						width: 300px;
						height: 169px;
						background-color: white;
						border-radius: 5px;
						margin-bottom: 15px;
						box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
						cursor: pointer;
						margin-left: 10px;
						overflow: hidden;
						margin-right: 10px;
						position: relative;
						&:hover {
							transition: transform 0.25s;
							-webkit-transform: scale(1.02);
							-ms-transform: scale(1.02);
							transform: scale(1.02);
						}
						.actions {
							flex: 0 0 24px;
							width: 24px;
							height: 24px;
							top: 27px;
							right: 22px;
							z-index: 10;
							border: none;
							background-color: transparent;
							background-image: url('images/components/overlay/navigation-show-more-vertical-white.svg');
							background-position: center;
							background-repeat: no-repeat;
							position: absolute;
							&:focus {
								z-index: 999;
								outline: 0;
							}

							.actions-drop-down {
								@include actions-dropdown;
							}
						}
						.card-data {
							padding: 0px 22px 15px 30px;
							position: relative;
							margin-top: -250px;
							display: flex;
							flex-direction: column;
							height: 250px;
							.header {
								display: flex;
								align-items: top;
								.title {
									flex: 1;
									font-size: 24px;
									font-weight: bold;
									color: white;
									max-width: 240px;
									overflow: hidden;
									text-overflow: ellipsis;
									margin-bottom: 4px;
								}
							}
							.category-name {
								color: white;
							}
						}
					}
				}
			}
			.table-container {
				@include scroll-y;
				.insight-list-table {
					width: 100%;
					padding-left: 0.5em;
					padding-right: 0.5em;
					.insight-column {
						width: 6.2em;
					}
					@include branding-table-first-child;
				}
			}
			.no-insights-message {
				font-family: $heading-font-family;
				font-size: 2em;
				margin: 1em;
			}
		}
	}
}

.insights-viewer {
	// overflow: auto;
	display: flex;
	flex: 1 1 auto;
	width: 100%;
	height: auto;
	background-color: $color-light-grey;
	padding: 1.7rem 2rem 0px 2rem;
	@media print {
		background-color: $color-white;
		padding: 0;
		// content doesnt fit on print preview with 100% zoom.
		zoom: 75%;
	}

	.image-button {
		cursor: pointer;
		background-color: transparent;
		border: none !important;
		background-position: center !important;
		background-repeat: no-repeat !important;
		width: 24px;
		height: 24px;
	}
	.main-container {
		display: flex;
		flex: 1 1 auto;
		flex-direction: column;
		width: 100%;
		min-height: calc(100vh - 121px);
		&.shared-insight {
			min-height: calc(100vh - 90px);
		}
		@media print {
			border: none;
			box-shadow: none;
		}

		.warning-text {
			color: $color-red;
		}
		.insight-free-text-component {
			overflow: auto;
			z-index: -1;
		}

		.actions {
			@media print {
				display: none;
			}
			margin-left: 4px;
			background-image: url('images/components/overlay/navigation-show-more-vertical.svg');
			&:focus {
				z-index: 999;
			}
			.actions-drop-down {
				@include actions-dropdown;
			}
		}
		.top-container {
			display: flex;
			flex: 0 0 40px;
			align-items: center;
			justify-content: space-between;
			margin: 0 10px;
			.title-portfolio {
				padding-left: 4px;
				font-size: $app-header-title-size;
				font-weight: 400;
			}
			.choose-project {
				font-size: 18px;
				font-weight: 700;
				padding-right: 12px;
			}
			.controls {
				display: flex;
				flex: 0 0 auto;
				flex-direction: row;
				align-items: center;
				justify-content: flex-end;
				.full-screen {
					background-image: url('images/sections/insights/builder/fullscreen.svg');
					margin-left: 6px;
					&:hover {
						background-image: url('images/sections/insights/builder/fullscreen-active.svg');
					}
				}
				.share {
					width: 30px;
					height: 30px;
					margin-left: 10px;
					.share-button {
						position: relative;
						width: 100%;
						height: 100%;
						background-image: url('images/sections/insights/builder/share.svg');
						&:hover {
							background-image: url('images/sections/insights/builder/share-active.svg');
						}
						&.expanded {
							background-image: url('images/sections/insights/builder/share-active.svg');
							background-color: $color-white;
							border-top-left-radius: 50%;
							border-top-right-radius: 50%;
							z-index: 1000;
							box-shadow: 0px 4px 0px 0 rgba(255, 255, 255, 1), 0px 1px 4px 0 rgba(0, 0, 0, 0.5);
						}
					}

					&:focus {
						z-index: 999;
					}
					.share-drop-down {
						@include actions-dropdown;
						top: -2px;
						// top: 10px;
						width: 680px;
						left: -650px;
						background-color: $color-white;
						border-radius: 5px 0 5px 5px;
						padding: 2em;
						cursor: default;
						.create-share {
							@include primary-button;
						}
						h3 {
							margin-top: 0;
						}
						.title {
							text-align: left;
						}
						.flex-row {
							display: flex;
							flex-direction: rows;
							.wrapper {
								flex: 1;
								input {
									width: 100%;
								}
							}
							.copy-button {
								margin-left: 10px;
								align-self: flex-end;
								background-image: url('images/sections/insights/builder/copy.svg');
								&:hover {
									background-image: url('images/sections/insights/builder/copy-active.svg');
								}
							}
							.flex-space {
								margin-left: 10px;
								flex: 0 0 24px;
							}
						}
						.buttons {
							margin-top: 20px;
							.unshare-btn {
								@include secondary-button;
								margin-right: 10px;
							}
							.share-btn {
								@include primary-button;
							}
						}
					}
				}
			}
			.info {
				position: relative;
				padding-left: 4px;
				display: flex;
				flex-direction: row;
				cursor: pointer;
				user-select: none;
				outline: none;
				min-width: 400px;
				justify-content: space-between;
				.name {
					margin-right: 16px;
					max-width: 700px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					margin: 8px 0 0 0;
				}
				.expandArrow {
					@media print {
						display: none;
					}
					width: 40px;
					height: 40px;
					background-size: 28px 28px;
					padding: 0px 24px 0px 0px;
					background-position: top 10px center;
					background-repeat: no-repeat;
					border-top-left-radius: 10px;
					border-top-right-radius: 10px;
					&.expanded {
						background-image: url('images/header/expanded.svg');
						background-color: $color-white;
						border-top-left-radius: 50%;
						border-top-right-radius: 50%;
						z-index: 1000;
						box-shadow: 0px 4px 0px 0 rgba(255, 255, 255, 1), 0 1px 4px 0 rgba(0, 0, 0, 0.5);
					}
					&.collapsed {
						background-image: url('images/header/expanded.svg');
					}
				}
				.drop-down {
					z-index: -1;
					background-color: $color-white;
					width: 420px;
					box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5);
					right: 0px;
					padding-bottom: 8px;
					.dropdown-input-container {
						cursor: default;
						padding: 16px;
						input {
							@include generic-text-input;
							color: black;
							width: 100%;
						}
					}
					ul {
						padding: 0;
						margin: 0;
						list-style: none;
						max-height: 300px;
						@include scroll-y;
						li {
							padding: 10px 16px 10px 16px;
							background-position: 15px center;
							background-repeat: no-repeat;
							cursor: pointer;
						}
						.nested-element {
							padding: 10px 16px 10px 26px;
						}
						.dropdown-item {
							&:hover {
								//background-color: $color-medium-gray;
								color: $color-purple-hover-active;
							}
						}
					}

					&.collapsed {
						display: none;
					}

					&.expanded {
						display: block;
						position: absolute;
						top: 41px;
						z-index: 999;
					}
				}
			}
		}
		.insight-container {
			.react-grid-item {
				position: relative;
				z-index: 3;
			}

			.no-access-layout {
				@include content-frame;
				display: flex;
				flex: 1 1 auto;
				justify-content: center;
				align-items: center;
				background-color: $color-white;
				height: 100%;
			}
			.component {
				height: 100%;
				padding: 10px;
				display: flex;
				flex-direction: column;
				//overflow: auto;
				@include content-frame;
				position: relative;
				z-index: 3;
				.header {
					flex: 0 0 31px;
					align-items: center;
				}
				.header-large {
					flex: 0 0 50px;
					align-items: flex-start;
				}
				.header,
				.header-large {
					display: flex;
					justify-content: flex-end;
					z-index: 1;
					position: relative;
					.title {
						flex: 1;
						cursor: default;
						font-weight: 400;
						font-size: 18px;
						font-family: $heading-font-family;
					}
					.download-icon {
						@include download-icon;
					}
					.select-wrapper,
					.date-span-picker-container {
						display: flex;
						margin-left: 10px;
						.input,
						.input-focused {
							padding-top: 3px;
						}
						.input-icon-collapsed,
						.input-icon-expanded {
							top: 3px;
						}
						.input-clear-icon {
							top: 10px;
						}
						.select {
							top: 0;
						}
					}
					.info,
					.image-button {
						margin-left: 0.5em;
						position: relative;
						outline: none;
						cursor: pointer;
						.text {
							position: absolute;
						}
					}
					.share-info-text {
						font-size: 13px;
						margin-right: 5px;
					}
				}
				.component-freeTextField {
					margin-top: 8px;
				}
				.ai-empty-message {
					font-size: large;
					flex: 1 0 auto;
					align-self: center;
					vertical-align: middle;
				}
			}
			.react-grid-layout {
				flex: 1 1 auto;
			}
		}
	}
}

.insight-container {
	display: flex;
	flex: 1 1 auto;
	width: 100%;
}

.insight-component-table {
	@include branding-table;
	tbody {
		tr {
			height: 49px !important;
			td {
				&.main {
					width: 20%;
				}
				transition: none;
				box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.1);
				.expandable-cell-container {
					display: flex;
					justify-content: space-between;
					align-items: center;
					.forecast-person {
						overflow: hidden;
					}
					.expandable-cell-icon {
						@include expand-control;
					}
				}
			}
			&.no-hover {
				cursor: default;
				&:hover {
					transition: none;
					-webkit-transform: none;
					-ms-transform: none;
					transform: none;
					color: $text-base-color;
				}
			}
		}
	}
	th {
		&.main {
			width: 20%;
		}
		&.info {
			overflow: visible;
		}
		text-overflow: ellipsis;
		white-space: nowrap;
		vertical-align: top;
		.header-container {
			display: flex;
			align-items: center;
		}
	}
	table {
		table-layout: fixed;
		width: 100%;
		margin-top: 10px;
		.header-totals {
			font-size: 13px;
			font-weight: 500;
			color: $color-dark-gray;
		}
		.progress-container {
			@include completion-bar;
		}
		.status-color {
			height: 16px;
			width: 16px;
			border-radius: 50%;
		}
		.assigned {
			display: flex;
			flex: 1;
			overflow: hidden;
			height: 51px;
			.person-elipsis-view {
				display: flex;
				flex-direction: row;
				align-items: center;
				cursor: default;
			}
			.forecast-person {
				margin: 10px 5px 10px 0;
			}
		}
		.skills {
			flex: 1;
			display: flex;
			flex-direction: row;
			overflow: hidden;
			align-items: center;

			height: 51px;
			.skill-label {
				display: flex;
				flex-direction: row;
				margin-right: 8px;
				border-radius: 5px;
				justify-content: center;
				align-items: center;
				height: 12px;
				width: 24px;
				margin-top: 5px;
				flex-shrink: 0;
				overflow: hidden;
			}
		}
		.description-cell {
			overflow: visible;
		}
	}
}

.insight-list {
	display: flex;
	> div {
		flex: 1;
	}
}

.insights-builder {
	z-index: 99;
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: $color-white;
	display: flex;
	flex-direction: column;
	.main-container {
		display: flex;
		flex-direction: column;
		width: 100%;
		background-color: $color-white;
		padding: 0 45px;
	}
}

.new-insight-step3,
.insights-builder {
	.top-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 34px 4px 16px 4px;
		font-size: 18px;
		.name-input {
			flex: 0 0 300px;
			font-family: $heading-font-family;
			font-weight: $base-font-bold-weight;
			@include input-field;
		}
		.controls {
			button {
				@include primary-button;
				margin-left: 8px;
			}
		}
	}
	.insight-container {
		background-color: #f3f2f2;
		.react-grid-layout {
			flex: 1 1 auto;
		}
		.placeholder {
			width: 100%;
			height: 100%;
			.inner {
				@include content-frame;
				margin: 8px;
				height: calc(100% - 16px);
				padding: 10px;
				display: flex;
				flex-direction: column;
				cursor: move;
				.edit-header {
					flex: 0 0 24px;
					display: flex;
					.info-component-icon-wrapper {
						width: 24px;
						height: 24px;
						background-position: center;
						background-repeat: no-repeat;
					}
					.title {
						flex: 1;
						font-weight: 400;
						font-size: 18px;
						font-family: $heading-font-family;
					}
					.remove,
					.configure,
					.columns {
						flex: 0 0 24px;
						margin-left: 8px;
						background-size: 24px;
						background-position: center top;
						background-repeat: no-repeat;
						cursor: pointer;
					}
					.remove {
						background-image: url('images/cross-section-icons/editable/remove.svg');
						&:hover {
							background-image: url('images/cross-section-icons/editable/remove-active.svg');
						}
					}
					.configure {
						background-image: url('images/cross-section-icons/editable/edit.svg');
						&:hover {
							background-image: url('images/cross-section-icons/editable/edit-active.svg');
						}
					}
					.columns {
						background-image: url('images/sections/insights/builder/column-filter.svg');
						&:hover {
							background-image: url('images/sections/insights/builder/column-filter-active.svg');
						}
					}
					.dropdown-wrapper {
						flex: 0 0 24px;
						position: relative;
						.configure,
						.columns {
							background-color: white;
							width: 24px;
							height: 24px;
							border-top-left-radius: 50%;
							border-top-right-radius: 50%;
							&.expanded {
								z-index: 100;
								position: relative;
								box-shadow: 0px 4px 0px 0 rgba(255, 255, 255, 1), 0 1px 4px 0 rgba(0, 0, 0, 0.5);
							}
						}
						.drop-down {
							&.expanded {
								display: flex;
								flex-direction: column;
								z-index: 99;
								position: absolute;
								top: 24px;
								right: 0;
								background-color: white;
								box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
								width: auto;
								border-top-left-radius: 5px;
								border-bottom-left-radius: 5px;
								border-bottom-right-radius: 5px;
								padding: 8px 0 8px 0;
								// overflow: hidden;
							}
							&.collapsed {
								display: none;
							}
							.option {
								display: flex;
								flex-direction: row;
								width: 100%;
								justify-content: space-between;
								align-items: center;
								height: auto;
								padding: 0 8px 0 8px;
								user-select: none;
								cursor: pointer;
								white-space: nowrap;
								&:hover {
									//background-color: $brand-brown-light;
									color: $color-purple-hover-active;
								}
								input {
									pointer-events: none;
									margin-right: 10px;
								}
								h4 {
									margin: 2px 4px 2px 0;
									text-align: right;
								}
							}
							.utilization-color-config {
								padding: 8px;
								width: 200px;
								cursor: default;
								.line-container {
									cursor: default;
									display: flex;
									input {
										width: 30px;
										flex: 0 0 30px;
										padding: 0px;
										text-align: right;
									}
									.config-title {
										flex: 1;
									}
									.line-height24 {
										line-height: 24px;
									}
									.invalid {
										border: 1px solid $error-border-color;
									}
									.colors-picker-container {
										width: 20px;
									}
								}
							}
						}
					}
				}
				.body {
					flex: 1;
					background-position: center;
					background-repeat: no-repeat;
					background-size: contain;
					.no-access-layout {
						display: flex;
						flex: 1 1 auto;
						justify-content: center;
						align-items: center;
						height: 100%;
					}
					&.component-freeTextField {
						margin-top: 8px;
						.public-DraftEditor-content {
							max-height: 50px;
							overflow: auto;
						}
					}
				}
			}
		}
	}
}

.no-data,
.no-components {
	flex: 1;
	width: 300px;
	margin-left: auto;
	margin-right: auto;
	font-family: $heading-font-family;
	font-size: 28px;
	display: flex;
	align-items: center;
	text-align: center;
}

.full-screen-mode {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 99;
	background-color: $color-white;
	display: flex;
	flex-direction: column;
	align-items: center;
	.insights-viewer {
		padding: 0;
	}
	.page-header {
		display: flex;
		height: 90px;
		width: 100%;
		padding: 20px;
		align-items: center;
		.logo {
			flex: 0 0 auto;
			background: url($app-header-logo-url) no-repeat center;
			background-size: contain;
			width: $app-header-logo-width;
			height: 100%;
			vertical-align: middle;
		}
		.page-title {
			flex: 1;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			padding-left: 10px;
		}
		.powered-by {
			flex: 0 0 auto;
			white-space: nowrap;
			padding: 10px 0;
			a {
				text-decoration: none;
			}
		}
		.exit-full-screen {
			background: url('images/sections/insights/builder/fullscreen_exit.svg') no-repeat center;
			flex: 0 0 24px;
			height: 24px;
			background-color: transparent;
			border: none;
			&:hover {
				background-image: url('images/sections/insights/builder/fullscreen_exit-active.svg');
			}
		}
	}
}

.legend {
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
	.legend-item {
		display: flex;
		padding-right: 1em;
		align-items: center;
		white-space: nowrap;
		.color {
			margin-right: 0.3em;
			width: 0.7em;
			height: 0.7em;
		}
	}
}

.project-timeline-insight-component {
	//Prevent timeline from covering the info popup
	z-index: 0;
	flex: 1 1 auto;
	.zoom-menu-container {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		flex-shrink: 0;
		//margin so date picker fits within the screen when using jump to date
		margin-right: 10px;
		.zoom-out,
		.zoom-in {
			border: none;
			width: 40px;
			outline: 0;
		}
	}
	.vis-right {
		width: 0 !important;
	}
	.vis-center {
		pointer-events: none;
	}
	.section-body {
		.scheduling-timeline-wrapper {
			overflow: visible;
			.scheduling-container {
				height: auto;
			}
		}
	}
}
.insight-component-budget-totals {
	display: flex;
	flex: 1 1 auto;
	flex-direction: row;
	justify-content: space-between;
	.totals-title-wrapper {
		flex: 0 0 20%;
		display: flex;
		align-items: center;
		padding: 15px 25px 30px 25px;
	}
	.insight-component-tiles {
		display: flex;
		flex: 1 1 auto;
		flex-direction: row;
		justify-content: space-between;
		padding: 15px 25px 30px 25px;
		.data-tile-wrapper {
			min-width: 89px;
			.sub-tiles-wrapper {
				display: flex;
				flex-direction: column;
				justify-content: center;
				.sub-data-tile {
					margin-bottom: 5px;
					font-size: 0.75em;
				}
			}
			.data-tile {
				display: flex;
				flex-direction: column;
				align-self: stretch;
				align-items: center;
				justify-content: space-between;
				padding-bottom: 10px;
				.data-tile-info-wrapper {
					display: flex;
					flex: 1 1 auto;
					flex-direction: row;
					align-items: center;
					.info-component-icon-wrapper {
						height: 16px;
						width: 16px;
						background-size: 16px;
					}
					.tooltip-right,
					.tooltip-left {
						font-size: 12px;
						font-weight: 400px;
					}
					.triangle-right {
						right: 0px;
					}
					.triangle-left {
						left: 0px;
					}
				}
				label {
					font-size: 0.75em;
					font-weight: 700;
					color: $color-dark-gray;
				}
				span {
					font-size: 1.2em;
				}
				.data-tile-value {
					margin-top: 0.5em;
					font-weight: 700;
					text-align: center;
				}
			}
		}
	}
}
