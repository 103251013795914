@import 'my_task_activities';
.my-profile-section {
	@include password-bubble;
	.section-main {
		overflow: hidden;
	}
	.section-navigation {
		padding-left: 77px !important;
		&:lang(da) {
			padding-left: 71px !important;
		}
		&:lang(es) {
			padding-left: 73px !important;
		}
		&:lang(fr) {
			padding-left: 71px !important;
		}
	}
	.section-content {
		&.V2 {
			margin: 0;
		}
		&.no-margin-top {
			margin-top: 0;
		}
	}
	.section-body {
		&.V2 {
			margin-right: 66px;
			margin-left: 66px;
		}
	}
	.notify-me-header {
		text-align: center;
	}
	.cell-center-aligned {
		text-align: center;
		input {
			margin: 0;
		}
	}
}
