.settings-app-salesforce {
	a {
		cursor: pointer;
	}
	.section-body {
		button {
			@include primary-button;
		}
	}
	.stages-selector {
		.auto-create-selector {
			margin-bottom: 1em;
			.toggle-component {
				margin-right: 1em;
			}
		}

		.toggle-component {
			margin-left: 1em;
		}

		.stages-list {
			margin-left: 2em;
			.slider-container {
				margin-bottom: 1em;
				margin-left: 1em;
				display: flex;
				flex-direction: row;
				align-items: center;
				.description {
					margin: 0 0 0 40px;
				}
			}
		}
	}

	h1 {
		margin-bottom: 8px;
		font-family: $text-font-family;
	}

	.salesforce-opportunity-stage {
		display: flex;
		flex-direction: row;
		margin-bottom: 4px;
		justify-content: space-between;
		width: 500px;

		.dropdown-container {
			width: 200px;
			float: right;
		}
	}
}

.settings-app-salesforce-v2 {
	.opportunity-stages-table {
		width: auto !important;

		.order-column {
			width: 75px;
		}

		.name-column {
			width: 200px;
		}

		.percent-column {
			min-width: 100px;
			text-align: center;

			&.warning-color {
				color: red;
			}
		}

		.error-column {
			min-width: 300px;
		}

		.project-stage-column {
			width: 300px;
			min-width: 300px;
			max-width: 300px;
		}

		.create-project-column {
			width: 50px;

			&.number-header {
				text-align: center;
			}
		}
	}

	.duplicate-projects-settings {
		.dropdown-container {
			width: 500px;
		}
	}
}
