.utilization-modal {
	.top-section {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 16px;
		.header-text {
			font-size: 11px;
			letter-spacing: 0.5px;
			font-weight: bold;
		}
		button {
			margin-left: auto;
			padding: 10px 16px;
		}
	}
	.person-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 16px;
		.person-name {
			margin-left: 8px;
			font-size: 16px;
			font-weight: 500;
		}
	}
	.main-section {
		display: flex;
		flex-direction: row;
		.utilization-section-header {
			font-size: 10px;
			font-weight: 600;
			color: $v2-text-light-gray;
			letter-spacing: 1px;
			margin-bottom: 4px;
			margin-right: 40px;
		}
		.graph-section {
			margin-right: 32px;
			.graph {
				margin-top: 28px;
				margin-bottom: 22px;
				padding-left: 24px;
				position: relative;
				.graph-axis {
					width: 150px;
					height: 200px;
					border-left: solid 1px #e6e6e6;
					border-bottom: solid 1px #e6e6e6;
				}
				.graph-step {
					position: absolute;
					font-size: 10px;
					font-weight: 500;
					color: $v2-text-light-gray;
					text-align: right;
					width: 100px;
					left: -86px;
					.infinity-sign-container {
						position: relative;
						.infinity-sign {
							position: absolute;
							background-image: url('images/components/scheduling/infinity-icon.svg');
							right: -2px;
							top: -12px;
							width: 16px;
							height: 8px;
							background-size: 16px 8px;
							background-position: right top;
							background-repeat: no-repeat;
						}
					}
					.line-container {
						position: relative;
						.line {
							position: absolute;
							height: 1px;
							width: 9px;
							bottom: 8px;
							right: -15px;
							background-color: #e6e6e6;
						}
					}
				}
				.working-hours-line {
					position: absolute;
					height: 1px;
					width: 150px;
					background-color: #e6e6e6;
				}
				.graph-grey-block {
					position: absolute;
					bottom: 0;
					left: 69px;
					background-color: #f3f3f3;
					width: 60px;
				}
				.graph-green-block {
					position: absolute;
					bottom: 0;
					left: 69px;
					background-color: #00cc66;
					width: 60px;
				}
				.graph-red-block {
					position: absolute;
					bottom: 0;
					left: 69px;
					background-color: #e4253b;
					width: 60px;
				}
				.difference-text {
					position: absolute;
					font-size: 13px;
					width: 150px;
					text-align: center;
					span {
						font-size: 11px;
					}
				}
				.date-text {
					position: absolute;
					width: 150px;
					text-align: center;
					font-size: 11px;
					font-weight: 500;
					letter-spacing: 0.5px;
					color: $v2-text-light-gray;
					bottom: -24px;
					white-space: nowrap;
				}
			}
		}
		.data-section {
			.tooltip-container {
				position: fixed;
				z-index: 2;
				padding: 16px;
				background-color: $v2-background-dark-gray;
				color: white;
				font-size: 12px;
				font-weight: 500;
				max-width: 400px;
				.triangle-container {
					position: relative;
					.triangle {
						position: absolute;
						left: 170px;
						top: -26px;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 10px 15px 0 15px;
						border-color: $v2-background-dark-gray transparent transparent transparent;
						transform: rotate(180deg);
					}
				}
			}
			.total-section {
				display: flex;
				flex-direction: row;
				margin-bottom: 24px;
				.utilization-total-container {
					.utilization-value {
						font-size: 13px;
						.available {
							color: $v2-text-light-gray;
						}
					}
				}
			}
			.add-allocation-button {
				button {
					margin-left: auto;
					padding: 10px 16px;
				}
			}
			.flex-container {
				display: flex;
				flex-direction: column;
				width: 380px;
			}
		}
		.utilization-data-section {
			border: 1px solid #e6e6e6;
			width: 380px;
			.utilization-data-section-header {
				border-bottom: 1px solid #e6e6e6;
				padding: 10px 16px;
				font-size: 13px;
				font-weight: 500;
				.more-info-button {
					color: $v2-text-light-gray;
					letter-spacing: 1px;
					font-size: 10px;
					border: none;
					padding: 0;
					outline: 0;
					&:hover,
					&.active {
						color: $v2-branding-purple;
					}
				}
			}
			.utilization-data-section-row-container {
				padding: 14px 16px;
				padding-bottom: 4px;
				a {
					text-decoration: none;
				}
				.utilization-data-section-row {
					display: flex;
					flex-direction: row;
					margin-bottom: 10px;
					align-items: center;
					.id-chip {
						flex: 0 0 50px;
						padding: 2px 0;
						border-radius: 3px;
						font-size: 12px;
						font-weight: 500;
						width: 50px;
						text-align: center;
					}
					.name {
						flex: 1 0 120px;
						font-size: 12px;
						min-width: 120px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						margin-left: 16px;
					}
					.minutes-allocated {
						flex: 0 0 60px;
						font-size: 12px;
						width: 60px;
						text-align: right;
					}
				}
				&.idle,
				&.time-off {
					.name {
						flex: 1 0 186px;
						margin-left: 0;
						width: 186px;
					}
				}
			}
		}
	}
}