.input-container-v2 {
	display: flex;
	flex-direction: column;
	position: relative;
	height: unset;

	&.growable {
		flex-grow: 1;
	}

	&.short {
		.input-value-container {
			height: 30px;

			.input-value-unit {
				line-height: 27px;
			}
		}
	}

	.input-title,
	.input-value-container {
		font-style: normal;
		font-stretch: normal;
	}
	label {
		height: 11px;
		font-size: 9px;
		font-weight: 500;
		line-height: 1.22;
		letter-spacing: 1px;
		color: $v2-text-light-gray;
		text-transform: uppercase;
		margin-bottom: 5px;
		white-space: nowrap;
	}
	.required-extension {
		color: $negative-value;
	}

	&.internal {
		.input-title {
			position: absolute;
			left: 10px;
			top: 8px;
		}
		.input-value-container {
			.input-value {
				padding-top: 13px;
				padding-left: 7px;
			}
			.input-value-unit {
				padding-top: 6px;
				margin-right: 10px;
			}
		}
	}

	.input-value-container {
		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		border: solid 1px $app-border-color;
		border-radius: 4px;
		background-color: white;
		overflow: hidden;
		white-space: nowrap;
		color: $v2-text-gray;
		height: 40px;
		font-weight: 500;
		line-height: normal;
		display: flex;
		flex-direction: row;
		transition: 0.1s border-color;

		.task-id {
			display: flex;
			align-items: center;
			padding-left: 10px;
			color: $v2-text-light-gray;
			font-size: 12px;
			text-transform: uppercase;

			&::after {
				display: block;
				content: '';
				height: 1px;
				width: 5px;
				background-color: currentColor;
				margin-left: 4px;
			}
		}

		.input-icon {
			padding-left: 8px;
			display: flex;
			align-items: center;
			opacity: 0.4;
		}

		.input-value {
			min-width: 0;
			flex: 1 1 auto;
		}
		.input-value-unit {
			min-width: 0;
			flex: 0 0 auto;
		}

		&:hover {
			border-color: $v2-date-picker-button-border-color;
		}

		&.locked {
			color: $v2-text-light-gray;
			border-color: $app-border-color;
			background-color: $color-medium-gray;
		}

		&.align-right {
			input {
				text-align: right;
			}
		}

		&.focused {
			border-color: $v2-branding-purple;
			&:hover {
				border-color: $v2-branding-purple;
			}
		}
		&.invalid {
			border-color: $v2-border-invalid;
		}
		::-webkit-input-placeholder {
			/* Chrome/Opera/Safari */
			font-size: 12px;
		}
		::-moz-placeholder {
			/* Firefox 19+ */
			font-size: 12px;
		}
		:-ms-input-placeholder {
			/* IE 10+ */
			font-size: 12px;
		}
		:-moz-placeholder {
			/* Firefox 18- */
			font-size: 12px;
		}
	}

	.input-value {
		padding: 0 16px;
		border: none;
		font-size: 12px;
		flex: 1 1 auto;
		outline: none;
	}
	.search-icon-placeholder,
	.currency-placeholder {
		position: relative;
		input {
			margin-left: 28px;
		}
	}
	.search-icon-placeholder:before {
		content: '';
		width: 28px;
		height: 28px;
		position: absolute;
		left: 8px;
		top: 8px;
		background: url('images/v2/search.svg') center center no-repeat;
		background-size: 28px;
	}
	.currency-placeholder {
		&:before {
			position: absolute;
			left: 16px;
			top: 12px;
			font-size: 12px;
			letter-spacing: 1px;
			color: $v2-text-gray;
		}
		&.AUD:before,
		&.CAD:before,
		&.SGD:before,
		&.HKD:before,
		&.MXN:before,
		&.NZD:before,
		&.USD:before {
			content: '$';
		}
		&.BRL:before {
			content: 'R$';
		}
		&.DKK:before,
		&.NOK:before,
		&.SEK:before {
			content: 'kr';
		}
		&.INR:before {
			content: '₹';
		}

		&.JPY:before,
		&.CNY:before {
			content: '¥';
		}

		&.TTD:before {
			content: '$';
		}

		&.GBP:before {
			content: '£';
		}

		&.RUB:before {
			content: '₽';
		}

		&.ZAR:before {
			content: 'R';
		}

		&.KRW:before {
			content: '₩';
		}

		&.CHF:before {
			content: 'Fr.';
		}

		&.TRY:before {
			content: '₺';
		}

		&.CZK:before {
			content: 'Kč';
		}
		&.PLN:before {
			content: 'zł';
		}
		&.THB:before {
			content: '฿';
		}
		&.EUR:before {
			content: '€';
		}
	}

	.input-value-unit {
		font-size: 12px;
		line-height: 38px;
		letter-spacing: 1.2px;
		text-align: right;
		margin-right: 16px;
		margin: 0 16px 0 -13px;
		flex: 0 1 auto;
	}

	.error-message {
		color: red;
		font-size: 13px;
	}

	/*&.outline-style {
		.input-value-container {
			background-color: $color-white;
			border-color: $app-border-color;
		}
		.input-value-container,
		.input-title,
		.input-value-unit {
			color: $v2-text-light-gray;
		}
	}*/

	&.negative {
		.input-value-container {
			background-color: $v2-backdrop-gray;
			border-color: $v2-backdrop-gray;
		}
		.input-value-container,
		.input-title,
		.input-value,
		.input-value-unit {
			height: 28px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			font-size: 14px;
			color: $v2-text-gray;
		}
		.input-underline {
			background-color: $v2-create-button-green;
		}
	}
}
