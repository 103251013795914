.task-modal-v3-files-section {
	border-bottom: 1px solid $app-border-color;
	margin: 0 58px;
	.files-section-header {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0 12px 0 0;
		border: none;
		width: 100%;
		height: 57px;

		&:focus {
			outline: none;
			.expand-icon {
				path {
					stroke: $color-martin-tertiary-light-purple;
				}
			}
		}

		.files-section-header-item {
			margin-right: 48px;

			.item-name {
				font-size: 10px;
				color: $v2-text-light-gray;
				line-height: 1.1;
				letter-spacing: 0.5px;
				display: flex;
				align-items: center;
			}

			&.title {
				display: flex;
				align-items: center;
				div {
					margin-left: 8px;
					color: $v2-text-gray;
					font-size: 12px;
					text-transform: uppercase;
				}
			}

			&.nr-of-files {
				span {
					font-size: 16px;
					color: $v2-branding-purple;
					letter-spacing: 0.5px;
				}
			}

			&.types {
				display: flex;
				flex-grow: 1;

				.types-of-files {
					text-transform: uppercase;
					color: $v2-text-gray;
					margin-left: 4px;
					font-size: 12px;
				}
			}
		}
	}

	.files-section-content {
		padding: 2px 0;

		.files-list {
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
		}
	}
}
