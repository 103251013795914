.project-group-connected-projects {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	&.section-content {
		margin: 0;
	}
	.section-header {
		.select-wrapper {
			margin-right: 5px;
			.select {
				top: 42px;
				height: auto;
				font-size: 14px;
				font-weight: bold;
				overflow: hidden;
				white-space: nowrap;
				min-width: 400px;
			}
			.input-container {
				min-width: 400px;
			}
		}
	}
	.section-body {
		flex: 1 1 auto;
		display: flex;
		margin: 0 32px;

		.table-wrapper {
			@include standard-table;
			@include content-frame;
			padding: 20px;
			.id {
				width: 80px;
			}
			tr {
				cursor: pointer;
			}
		}
	}
}
