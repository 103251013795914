.retainer-time-entry-conflict-modal {
	min-width: 650px;
	.description {
		margin-bottom: 16px;
		color: $v2-text-gray;
		font-size: 14px;
	}
	.text-spacer {
		height: 8px;
		width: 100%;
	}
	.time-entries {
		display: flex;
		flex-direction: column;
		font-size: 13px;
		color: $v2-text-gray;
		border-bottom: 1px solid #eaeaea;
		.time-entry {
			display: flex;
			flex-direction: row;
			height: 40px;
			border: 1px solid #eaeaea;
			border-bottom: none;
			align-items: stretch;
			padding-right: 16px;
			padding-left: 16px;
			&.header-row {
				color: $v2-text-light-gray;
				font-size: 9px;
				letter-spacing: 1px;
				font-weight: 400;
				text-transform: uppercase;
				padding-bottom: 4.5px;
				height: auto;
				border: none;
			}
			.time-entry-details {
				flex-grow: 1;
				display: flex;
				flex-direction: row;
				overflow: hidden;
				.time-entry-detail {
					flex-grow: 0;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
				.person {
					width: 30%;
					max-width: 30%;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				.registration-object {
					width: 25%;
					max-width: 25%;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				.date {
					width: 20%;
					text-align: left;
				}
				.hours-registered {
					width: 80px;
					text-align: right;
				}
				&.rows {
					.time-entry-detail {
						padding-top: 10px;
						&.person {
							padding-top: 6px;
						}
					}
				}
				.checkbox-wrapper {
					flex-grow: 1;
					white-space: nowrap;
					display: flex;
					justify-content: flex-end;
					.check-box {
						width: 22px;
					}
				}
			}
		}
	}

	.radio-row {
		display: flex;
		margin: 16px 0;

		.radio-button-wrapper {
			margin-right: 16px;
		}
	}

	.summary-wrapper {
		margin-top: 16px;
		font-size: 13px;
	}

	.total-time-to-subtract-wrapper {
		position: relative;
		margin-top: 16px;
		.total-time-texts {
			display: flex;
			flex-direction: column;
		}
		.total-time-to-subtract,
		.new-target-text {
			font-size: 13px;
			color: $v2-text-gray;
			font-weight: normal;
			text-align: right;
		}
		.new-target-text {
			margin-top: 8px;
		}
	}
}
