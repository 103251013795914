.fixed-price-wrapper {
	position: absolute;
	z-index: 98;
	background-color: #fff;
	width: 100%;
	height: calc(100% - 70px);
	top: 70px;
	padding-top: 39px;
	padding-bottom: 16px;
	font-family: 'neue-haas-grotesk-text', sans-serif;

	.padder {
		padding-left: 182px;
		padding-right: 182px;
	}

	.back-wrapper {
		display: flex;
		padding: 2px 10px 2px 2px;
		width: fit-content;
		cursor: pointer;
		&:hover,
		&:focus {
			background-color: #f3f3f3;
		}
		.back-arrow-control {
			background: url('images/v2/components/date-controls/arrow-left.svg') no-repeat right center;
			width: 16px;
			height: 24px;
		}
		.back-text {
			color: $v2-text-light-gray;
			padding-top: 3px;
			font-size: 13px;
		}
	}

	.fixed-price-content {
		margin-top: 24px;
		display: flex;
		height: 100%;

		.fixed-price-container {
			width: 340px;

			h1 {
				font-size: 16px;
				font-weight: 500;
				color: $v2-text-gray;
				font-family: 'neue-haas-grotesk-text', sans-serif;
			}

			p {
				font-size: 12px;
				color: $v2-text-light-gray;
			}

			.buttons {
				margin-top: 24px;
				height: 40px;
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				width: 100%;
				button {
					padding: 0 32px;

					&:active,
					&:focus {
						outline: 0;
					}
				}
			}

			.form {
				margin-top: 24px;

				div.label {
					font-size: 9px;
					text-transform: uppercase;
					font-weight: 500;
					letter-spacing: 1px;
					color: #a1a1a1;

					&.label-rate-card-change {
						padding-bottom: 16px;
					}
				}

				.slider-container {
					display: flex;
					padding-bottom: 16px;
					align-items: flex-start;

					&:last-child {
						padding-top: 16px;
						border-top: 1px solid #e6e6e6;
						align-items: center;

						.column-label {
							font-weight: normal;
						}
					}
					.column-texts-label {
						cursor: pointer;
						&.disabled {
							cursor: default;
						}
					}
					.column-texts {
						display: flex;
						flex-direction: column;
					}

					.column-label {
						margin-left: 10px;
						font-size: 13px;
						font-weight: 500;
						color: #535353;

						&.disabled {
							color: #a1a1a1;
						}
					}

					.column-description {
						margin-left: 10px;
						font-size: 12px;
						font-style: italic;
						color: #535353;

						&.disabled {
							color: #a1a1a1;
						}
					}
				}

				input[type='number'] {
					margin-top: 8px;
					margin-bottom: 24px;
					border-radius: 4px;
					border: 1px solid $v2-text-light-gray;
					color: #535353;
					font-size: 13px;
					padding-right: 22px;

					&:focus {
						border: 1px solid $v2-branding-purple;
					}

					&:focus,
					&:active {
						outline: 0;
					}
				}

				.fixed-price-numbers {
					display: flex;
					justify-content: flex-end;

					.plan-revenue {
						width: 140px;
						font-size: 13px;
						color: $v2-text-gray;
						.label {
							display: flex;
							span {
								position: relative;
								top: -3px;
								right: -5px;
							}
						}
						.content {
							padding-top: 16px;
						}
					}
					.markup {
						width: 100px;
						.label {
							text-align: right;
							margin-bottom: 8px;
						}
					}
					.input-title {
						text-align: right;
						margin-bottom: 9px;
					}
					.input-value-container {
						height: 31px;
					}
					.input-value-unit {
						line-height: 30px;
					}
					input:-moz-placeholder,
					input:focus {
						box-shadow: none !important;
					}

					input:invalid {
						box-shadow: none;
					}
					.fixed-price {
						margin-left: 8px;
						max-width: 120px;
						.input-CZK.right input,
						.input-CHF.right input,
						.input-BRL.right input {
							padding-right: 30px;
						}
					}
				}
			}
		}

		.fixed-price-rate-card {
			flex-direction: column;
			margin-left: 66px;
			min-width: 510px;

			.preview {
				color: #a1a1a1;
				font-size: 13px;
				font-family: 'neue-haas-grotesk-text', sans-serif;
				font-weight: normal;
				font-style: italic;
			}

			h2 {
				font-size: 16px;
				color: $v2-text-gray;
				font-family: inherit;
				margin: 0;
			}

			.rates {
				margin-top: 8px;
				border: 1px solid #e6e6e6;
				padding: 16px;
			}

			.no-rate-card {
				height: 426px;
			}

			@include standard-table;
			table {
				.active {
					color: #6e0fea;
					font-weight: 500;
				}

				.column-role {
					width: 50%;
				}

				.column-rate {
					width: 25%;
				}

				.full {
					width: 50%;
				}

				thead {
					th {
						color: $v2-text-light-gray;
						font-size: 9px;
						letter-spacing: 1px;
						font-weight: 400;
						text-transform: uppercase;
						cursor: default;
						padding-top: 16px;
						vertical-align: bottom;
						height: 48px;
					}
				}

				tbody {
					tr {
						border-top: 1px solid $light-border-color;
						border-bottom: none;

						td {
							color: $v2-text-gray;
							font-size: 13px;
							padding-top: 12px;
							padding-bottom: 12px;
						}
					}
				}
			}
		}
	}
}
