.add-team-member-modal {
	&.modal {
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		z-index: 99;
		background: rgba(0, 0, 0, 0.75);
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		.inner-container {
			background-color: white;
			border-radius: 5px;
			padding: 2em;
			width: 350px;
		}
	}
	.inner-container {
		width: 260px;
		.header-container {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 20px;
			.header-text {
				margin: 0;
			}
			.close-button {
				width: 25px;
				height: 25px;
				background-size: 25px;
				background-image: url('images/components/overlay/close.svg');
				cursor: pointer;
			}
		}
		.body-container {
			input[type='text'],
			.select {
				width: 100%;
				max-width: 100%;
			}
			.select-wrapper {
				.input-container {
					max-width: 300px;
				}
			}
			.disclaimer {
				color: $brand-color-light;
				text-align: center;
				min-height: 50px;
				margin-top: 10px;
				margin-bottom: 10px;
				font-size: 0.875em;
			}
			.project-access-checkbox-wrapper {
				display: flex;
				font-size: 11px;
				margin: 10px 0;
				cursor: pointer;
				input {
					width: 16px;
				}
			}
			.button-container {
				display: flex;
				flex-direction: row;
				.button-cancel {
					@include secondary-button;
					margin-right: 5px;
					flex: 1 0 0px;
				}
				.button-send {
					@include primary-button;
					margin-left: 5px;
					flex: 1 0 0px;
				}
			}
		}
	}
}
